import * as Sentry from '@sentry/browser'
import { ReactComponent as LogoutSvg } from '@trinsly/common/src/assets/icons/logout.svg'
import Card from '@trinsly/common/src/components/atoms/card'
import Column from '@trinsly/common/src/components/atoms/column'
import Divider from '@trinsly/common/src/components/atoms/divider'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Link from '@trinsly/common/src/components/atoms/link'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import Modal from '@trinsly/common/src/components/molecules/modal'
import useQueryParams from '@trinsly/common/src/hooks/use-query-params'
import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'

import useGetUserSettings from 'api-hooks/use-get-user-settings'
import CustomMailAuth from 'components/custom-mail-auth'
import GoogleAuthButton from 'components/google-auth'
import MicrosoftAuthV1 from 'components/microsoft-auth'
import { useAuth } from 'providers/auth-provider'
import { canUseNylasInboxes } from 'validators/paywall'

/**
 * Global to Private Layout - renders on auth context props
 * @param param0
 * @returns
 */
const EmailAuthModal = ({}) => {
  const { logOut, loggingOut, user, refetchUserSession } = useAuth()
  const { params } = useQueryParams()
  const [showInboxConnectionModal, setShowInboxConnectionModal] =
    useState(false)

  const { data: settings, pending: settingsPending } = useGetUserSettings()
  const [isPaywalled, setIsPaywalled] = useState(true)
  const [gmailError, setGmailError] = useState<any>()

  useEffect(() => {
    if (!user) return
    if (!settingsPending && settings && !isEmpty(user)) {
      /**
       * Check settings for integrations allowed
       */
      const paywalled = !canUseNylasInboxes(
        user?.paywallInfo,
        settings.subscriptions ?? []
      )

      setIsPaywalled(paywalled)
      /**
       * If has integration, we don't have this component appear
       */
      setShowInboxConnectionModal(!Boolean(user?.emailAuth))
    }
  }, [user, settings, settingsPending])

  /**
   * Callback for Outlook auth
   */
  useEffect(() => {
    if (params['outlook_success']) {
      console.log(params['outlook_success'])
      //TODO: Refetch Session?
    }
  }, [params])
  //const params = new URLSearchParams(window.location.search)

  const hasErrorOutlook = params['email-auth-state'] === EmailAuthState.ERROR

  return (
    <Modal visible={showInboxConnectionModal} onRequestClose={() => {}}>
      <>
        {user && (
          <Card className="p-lg">
            <Row>
              <Heading size="xl" fontWeight="bold" className="uppercase">
                Connect your inbox to get started!
              </Heading>
              <Separator size="xl" />
              <Button
                onClick={() => logOut({})}
                intent="warning"
                variant="outline"
                size="sm"
                className="h-4"
                pending={loggingOut}
              >
                Log Out
                <Separator size="xxs" />
                <LogoutSvg className="w-4" />
              </Button>
            </Row>
            <Column className="flex justify-between items-center">
              <Separator />
              <Row>
                <div className="content">
                  {hasErrorOutlook && (
                    <Text color="danger-700" fontWeight="bold">
                      There was an error with your email provider authorization.
                      Please try again.
                    </Text>
                  )}
                  {Boolean(user?.emailAuth) && (
                    <Text color="success-900" fontWeight="bold" size="lg">
                      Great, you've successfully connected your account!
                    </Text>
                  )}
                  <Separator />
                  <Text>
                    We require email provider authorization to be able to send
                    and schedule e-mails for you.
                  </Text>
                  <Separator />
                  {isPaywalled && (
                    <>
                      <Text>
                        Our trial accounts only support Google Workspace (Gmail)
                        and Microsoft 365 (Outlook) accounts.
                      </Text>
                      <Separator />
                      <Text>
                        To enable custom server access such as Microsoft
                        Exchange (Hybrid 365/On-Premise or On-Premise only) or
                        custom IMAP/SMTP please contact us at{' '}
                        <a
                          type="button"
                          href="mailto:help@trinsly.com?subject=Interested in Enterprise Account - Custom Inbox Connection"
                          target="_blank"
                          style={{
                            color: 'blue',
                          }}
                        >
                          help@trinsly.com{' '}
                        </a>
                        .
                      </Text>
                    </>
                  )}
                  <Separator />
                  <div className="text-center">
                    <>
                      <GoogleAuthButton
                        email={user?.email || ''}
                        onSuccess={() => {
                          refetchUserSession()
                          setShowInboxConnectionModal(false)
                        }}
                        onError={(error) => {
                          setGmailError(error.toString())
                          Sentry.addBreadcrumb({
                            category: 'Gmail AuthZ',
                            message: `Authenticated user ${user?.email} failed to connect Gmail.`,
                            level: Sentry.Severity.Warning,
                          })
                        }}
                      />
                      <Separator />
                      <MicrosoftAuthV1 email={user?.email} />
                      <Separator />
                      <CustomMailAuth email={user?.email} />
                    </>
                    <Separator />
                    <Divider />
                  </div>
                  <Text size="xs" fontWeight="medium">
                    {`Please note your Talent CRM account email: ${user?.email} must match the one you connect above.`}
                  </Text>
                  <Separator size="xs" />
                  <Row className="justify-center">
                    <Link
                      size="xs"
                      intent="primary"
                      href="https://chatkick.com/privacy"
                    >
                      <Text size="xs" color="primary-800">
                        Privacy Policy
                      </Text>
                    </Link>
                    <Separator />
                    <Link
                      size="xs"
                      intent="primary"
                      href="https://chatkick.com/terms"
                    >
                      <Text size="xs" color="primary-800">
                        Terms of Service
                      </Text>
                    </Link>
                  </Row>
                </div>
              </Row>
            </Column>
          </Card>
        )}
      </>
    </Modal>
  )
}

export default EmailAuthModal

export enum EmailAuthState {
  ERROR = 'error',
  SUCCESS = 'success',
}
