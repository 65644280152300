import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'

import { useMutation, useQueryClient } from 'react-query'
import rescheduleThreadService from 'services/reschecule-thread-service'
import ThreadType from 'types/thread-type'

export type UseRescheduleThreadProps = {} & MutateApiHookType<ThreadType>

function useRescheduleThread({
  onMutate,
  onSuccess,
  onError,
}: UseRescheduleThreadProps) {
  const queryClient = useQueryClient()
  const { mutate: fetch, isLoading: pending } = useMutation(
    rescheduleThreadService,
    {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries('/threads')
        onSuccess?.(data)
      },
      onError,
      onMutate,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useRescheduleThread
