import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation, useQueryClient } from 'react-query'
import { Membership } from 'types/settings-type'

import updateMembershipService from 'services/settings/update-membership-service'

export type UseUpdateMembershipProps = {} & MutateApiHookType<Membership>

function useUpdateMembership({
  onSuccess,
  onError,
  onMutate,
}: UseUpdateMembershipProps) {
  const queryClient = useQueryClient()
  const { mutate: fetch, isLoading: pending } = useMutation(
    updateMembershipService,
    {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries('/user/me')
        await queryClient.invalidateQueries('/settings')
        onSuccess?.(data)
      },
      onError,
      onMutate,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useUpdateMembership
