import { ReactComponent as DeleteSvg } from '@trinsly/common/src/assets/icons/delete.svg'
import IconButton from '@trinsly/common/src/components/molecules/icon-button'
import useDeleteCampaignDraft from 'api-hooks/use-delete-campaign-draft'
import { toast } from 'react-toastify'
import DraftType from 'types/draft-type'

type DeleteDraftButtonProps = {
  draft: DraftType
}

function DeleteDraftButton({ draft }: DeleteDraftButtonProps) {
  const { fetch: deleteDraft, pending: pendingDelete } = useDeleteCampaignDraft(
    {
      onSuccess: (data) => {
        toast.success('Draft Deleted!')
      },
      onError: (error) => {
        toast.error('Error deleting draft')
      },
    }
  )

  return (
    <IconButton
      size="sm"
      intent="danger"
      variant="ghost"
      pending={pendingDelete}
      onClick={() => {
        deleteDraft({ draftId: draft?.id })
      }}
    >
      <DeleteSvg />
    </IconButton>
  )
}

export default DeleteDraftButton
