import Card from '@trinsly/common/src/components/atoms/card'
import Column from '@trinsly/common/src/components/atoms/column'
import Form from '@trinsly/common/src/components/atoms/form'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import Modal from '@trinsly/common/src/components/molecules/modal'
import useForm from '@trinsly/common/src/hooks/use-form'
import required from '@trinsly/common/src/validations/required'
import useLinkProspectToProject from 'api-hooks/use-link-prospect-to-project'
import MultiSelectProspects from 'components/multi-select-prospects'
import React from 'react'
import { toast } from 'react-toastify'
import ProjectType from 'types/project-type'
import ProspectType from 'types/prospect-type'

type LinkProspectsToProjectModalProps = {
  project: ProjectType | undefined
  visible: boolean
  onRequestClose: () => void
}

function LinkProspectsToProjectModal({
  project,
  visible,
  onRequestClose,
}: LinkProspectsToProjectModalProps) {
  const { fetch: linkProspectToProject, pending: pendingLink } =
    useLinkProspectToProject({
      onSuccess: () => {
        toast.success('Prospect added to Project!')
        onRequestClose?.()
      },
      onError: (error) => {
        toast.error('Error adding prospect to project')
      },
    })

  const { getValue, setValue, getError, getValues, setValues, submit, reset } =
    useForm({
      onSubmit: ({ prospects }: { prospects: ProspectType[] }) => {
        linkProspectToProject({
          projectId: project?.id || 0,
          prospectIds: prospects?.map((p) => p.id) || [],
        })
      },
      validations: {
        prospects: [required],
      },
    })

  React.useEffect(() => {
    if (visible) {
      reset()
    }
  }, [visible])

  return (
    <Modal visible={visible} onRequestClose={onRequestClose}>
      <Card>
        <Form onSubmit={submit}>
          <Column className="p-md gap-lg">
            <Heading size="xl" fontWeight="bold">
              Add Prospects to {project?.name}
            </Heading>
            <Column>
              <Text size="xs" fontWeight="bold" className="uppercase">
                Prospects
              </Text>
              <Separator size="xs" />
              <MultiSelectProspects
                placeholder="Select prospects"
                values={getValue('prospects')}
                onChange={(value) => setValue('prospects', value)}
                error={getError('prospects')}
              />
            </Column>
          </Column>
          <Modal.Footer>
            <Button className="min-w-28" onClick={onRequestClose}>
              Cancel
            </Button>
            <Separator size="sm" />
            <Button
              type="submit"
              className="min-w-52"
              intent="primary"
              pending={pendingLink}
            >
              Add Prospects
            </Button>
          </Modal.Footer>
        </Form>
      </Card>
    </Modal>
  )
}

export default LinkProspectsToProjectModal
