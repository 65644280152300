import api from '@trinsly/common/src/services/api'
import TrackerDomainType from 'types/tracker-domain-type'

export type GetTrackerDomainsServiceProps = {}

export async function getTrackerDomainsService({}: GetTrackerDomainsServiceProps) {
  const response = await api('/tracker_domains', {
    method: 'GET',
  })

  return response as any as TrackerDomainType[]
}
