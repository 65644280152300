import Column from '@trinsly/common/src/components/atoms/column'
import Row from '@trinsly/common/src/components/atoms/row'
import EmailTemplateInput from '@trinsly/common/src/components/molecules/quill-editor/email-template-input'
import useFormConcurrent from '@trinsly/common/src/hooks/use-form-concurrent'
import required from '@trinsly/common/src/validations/required'
import TinyMCEEditor from './tinymce-editor'

function EditorTestScreen() {
  const { getValue, setValue, getError, submit } = useFormConcurrent({
    validations: {
      subject: [required],
      body: [required],
    },
  })

  return (
    <Column className="p-xl h-screen gap-xl overflow-auto">
      <Row className="gap-md">
        <Column className="w-2/5">
          <EmailTemplateInput
            subject={{
              label: 'Subject test',
              value: getValue('subject'),
              error: getError('subject'),
              onChange: (value) => {
                setValue('subject', value)
              },
            }}
            body={{
              label: 'Body test',
              value: getValue('body'),
              error: getError('body'),
              onChange: ({ html }) => {
                setValue('body', html)
              },
            }}
          />
        </Column>
        <Column className="w-2/5">
          <Column style={{ minHeight: 120 }} />
          <TinyMCEEditor
            // label={'Body test'}
            value={getValue('old-body')}
            // error={getError('body')}
            onChange={(value) => {
              setValue('old-body', value)
            }}
          />
        </Column>
      </Row>
    </Column>
  )
}

export default EditorTestScreen
