import Column from '@trinsly/common/src/components/atoms/column'
import Row from '@trinsly/common/src/components/atoms/row'
import React from 'react'
import NewReachOutsStatsItem from './new-reach-outs-stats-item'
import StatsItem from './stats-item'

export type StatsSectionProps = {
  campaignReportStats: any
  pending: boolean
}

function StatsSection({ campaignReportStats, pending }: StatsSectionProps) {
  return (
    <React.Fragment>
      <Column className="border rounded-md p-md">
        <Row className="flex-1 justify-between items-center">
          <NewReachOutsStatsItem
            pending={pending}
            title="New Reach Outs"
            inProgress={
              campaignReportStats?.current?.aggregate?.inProgressCount
            }
            previousValue={
              campaignReportStats?.previous?.aggregate?.reachOutCount
            }
            currentValue={
              campaignReportStats?.current?.aggregate?.reachOutCount
            }
          />
          <StatsItem
            pending={pending}
            title="Response rate"
            currentValue={
              campaignReportStats?.current?.aggregate?.responsesCount
            }
            previousValue={
              campaignReportStats?.previous?.aggregate?.responsesCount
            }
          />
          <StatsItem
            pending={pending}
            title="Interested RATE"
            currentValue={
              campaignReportStats?.current?.aggregate?.interestedCount
            }
            previousValue={
              campaignReportStats?.previous?.aggregate?.interestedCount
            }
          />
          <StatsItem
            pending={pending}
            title="OPEN RATE"
            currentValue={campaignReportStats?.current?.aggregate?.openCount}
            previousValue={campaignReportStats?.previous?.aggregate?.openCount}
          />
        </Row>
      </Column>
    </React.Fragment>
  )
}

export default StatsSection
