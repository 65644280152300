import { EXTENSION_IMPERSONATE_USER_ID_KEY } from '@trinsly/common/src/services/api'
import PageableType from '@trinsly/common/src/types/pageable-type'
import { useQuery } from 'react-query'
import getUsersService from 'services/get-users-service'

type UseGetUsersProps = {} & PageableType

function useGetUsers({ ...pageable }: UseGetUsersProps) {
  const shouldFetch = !localStorage.getItem(EXTENSION_IMPERSONATE_USER_ID_KEY)
  const { data, error, status } = useQuery(
    ['/users', pageable],
    () => getUsersService(pageable),
    {
      enabled: shouldFetch,
      staleTime: shouldFetch ? undefined : 0,
    }
  )

  return {
    data: data || [],
    total: data?.length || 0,
    pending: status === 'loading',
    error,
  }
}

export default useGetUsers
