import api from '@trinsly/common/src/services/api'

export type UpdateSyncedUsersByIntegrationServiceProps = {
  integrationId: string
  values: any
}

async function updateSyncedUsersByIntegrationService({
  integrationId,
  values,
}: UpdateSyncedUsersByIntegrationServiceProps) {
  const response = await api(`/integrations/${integrationId}/update_users`, {
    method: 'POST',
    body: values,
  })
  return response
}

export default updateSyncedUsersByIntegrationService
