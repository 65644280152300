import Column from '@trinsly/common/src/components/atoms/column'
import Row from '@trinsly/common/src/components/atoms/row'
import useGetProjectReportStats from 'api-hooks/use-get-project-report-stats'
import { endOfDay, isBefore, parse, startOfDay, sub } from 'date-fns'
import React from 'react'
import { useFormContext } from '../../form-provider'
import colors from './colors'
import KPIListItem from './kpi-list-item'
import SelectPeriod from './select-period'

type KPIListProps = {
  period: 'week' | 'month'
  onPeriodChange: (period: 'week' | 'month') => void
}

/**
 * Big numbers are the total or aggregate for the project
 * little vs. are showing the previous periods aggregate + change% from that period
 * TODO: all projects have a startDate no matter what!!
 * @param param0
 * @returns
 */
function KPIList({ period, onPeriodChange }: KPIListProps) {
  const { project } = useFormContext()
  const endDate = React.useMemo(() => {
    if (!Boolean(project?.endDate)) {
      return new Date()
    }
    const projectEndDate = parse(project!.endDate!, `yyyy-MM-dd`, new Date())

    return isBefore(projectEndDate, new Date()) ? projectEndDate : new Date()
  }, [project?.endDate])

  const startDate = React.useMemo(() => {
    if (!Boolean(project?.startDate)) {
      return sub(new Date(), { days: period === `week` ? 7 : 30 })
    }
    return parse(project!.startDate!, 'yyyy-MM-dd', new Date())
  }, [project?.startDate])

  const { data: totalStats, pending: pendingTotalStats } =
    useGetProjectReportStats({
      projectId: project?.id || '',
      period,
      startDate: startOfDay(startDate).toISOString(),
      endDate: endOfDay(endDate).toISOString(),
    })

  const previousTotal = React.useMemo(() => {
    if (
      !Boolean(totalStats?.data.reachOuts) ||
      totalStats?.data.reachOuts.length <= 1
    ) {
      return 0
    }

    return (
      totalStats?.data.reachOuts.reduce((a: number, b: number) => a + b) -
      totalStats?.data.reachOuts.pop()
    )
  }, [totalStats?.data.reachOuts])
  const previousResponses = React.useMemo(() => {
    if (
      !Boolean(totalStats?.data.responded) ||
      totalStats?.data.responded.length <= 1
    ) {
      return 0
    }

    return (
      totalStats?.data.responded.reduce((a: number, b: number) => a + b) -
      totalStats?.data.responded.pop()
    )
  }, [totalStats?.data.responded])
  const previousOpens = React.useMemo(() => {
    if (
      !Boolean(totalStats?.data.opened) ||
      totalStats?.data.opened.length <= 1
    ) {
      return 0
    }

    return (
      totalStats?.data.opened.reduce((a: number, b: number) => a + b) -
      totalStats?.data.opened.pop()
    )
  }, [totalStats?.data.opened])
  const previousInterested = React.useMemo(() => {
    if (
      !Boolean(totalStats?.data.interested) ||
      totalStats?.data.interested.length <= 1
    ) {
      return 0
    }

    return (
      totalStats?.data.interested.reduce((a: number, b: number) => a + b) -
      totalStats?.data.interested.pop()
    )
  }, [totalStats?.data.interested])

  return (
    <Column className="lg:flex-row-reverse lg:justify-between px-xl gap-md">
      <Column className="gap-xs lg:items-end">
        {/* <DownloadButton /> */}
        <Column className="w-32">
          <SelectPeriod
            clearable={false}
            value={period}
            onChange={(value) => onPeriodChange(value || 'week')}
          />
        </Column>
      </Column>
      <Row className="flex-wrap gap-x-md gap-y-xl">
        <KPIListItem
          title="Total Reach Outs"
          kpiType="integer"
          valueOrPercent={totalStats?.aggregate.reachOutCount}
          gainOrLossPercentage={
            !totalStats?.aggregate.reachOutCount || !previousTotal
              ? 0
              : (totalStats.aggregate.reachOutCount / previousTotal) * 100 - 100
          }
          previousTitle={`Last ${period} total`}
          previousValueOrPercent={`${previousTotal}`}
          color={colors.TEAL}
        />
        <KPIListItem
          title="Response Rate"
          kpiType="percent"
          valueOrPercent={`${
            !totalStats?.aggregate?.reachOutCount ||
            !totalStats?.aggregate?.responsesCount
              ? 0
              : (totalStats?.aggregate?.responsesCount /
                  totalStats?.aggregate?.reachOutCount) *
                100
          }%`}
          gainOrLossPercentage={
            !previousResponses
              ? 0
              : (totalStats?.aggregate?.responsesCount /
                  totalStats?.aggregate?.reachOutCount /
                  (previousResponses / previousTotal)) *
                  100 -
                100
          }
          previousTitle={`vs previous ${period}`}
          previousValueOrPercent={`${
            !previousTotal || !previousResponses
              ? 0
              : (previousResponses / previousTotal) * 100
          }%`}
          color={colors.SALMON}
        />
        <KPIListItem
          title="Interested Rate"
          kpiType="percent"
          valueOrPercent={`${
            !totalStats?.aggregate?.reachOutCount ||
            !totalStats?.aggregate?.interestedCount
              ? 0
              : (totalStats?.aggregate?.interestedCount /
                  totalStats?.aggregate?.reachOutCount) *
                100
          }%`}
          gainOrLossPercentage={
            !previousInterested
              ? 0
              : (totalStats?.aggregate?.interestedCount /
                  totalStats?.aggregate?.reachOutCount /
                  (previousInterested / previousTotal)) *
                  100 -
                100
          }
          previousTitle={`vs previous ${period}`}
          previousValueOrPercent={`${
            !previousTotal || !previousInterested
              ? 0
              : (previousInterested / previousTotal) * 100
          }%`}
          color={colors.YELLOW}
        />
        <KPIListItem
          title="Open Rate"
          kpiType="percent"
          valueOrPercent={`${
            !totalStats?.aggregate?.reachOutCount ||
            !totalStats?.aggregate?.openCount
              ? 0
              : (totalStats?.aggregate?.openCount /
                  totalStats?.aggregate?.reachOutCount) *
                100
          }%`}
          gainOrLossPercentage={
            !previousOpens
              ? 0
              : (totalStats?.aggregate?.openCount /
                  totalStats?.aggregate?.reachOutCount /
                  (previousOpens / previousTotal)) *
                  100 -
                100
          }
          previousTitle={`vs previous ${period}`}
          previousValueOrPercent={`${
            !previousTotal || !previousOpens
              ? 0
              : (previousOpens / previousTotal) * 100
          }%`}
          color={colors.BLUE}
        />
      </Row>
    </Column>
  )
}

export default KPIList
