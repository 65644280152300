import moment from 'moment'

/**
 * These values represent the hours offset from midnight. For example, 18 would
 * correspond to 6pm.
 */
enum ScheduleTimeSelectEnum {
  AFTERNOON_VALUE = 12,
  EARLY_MORNING_VALUE = 7,
  EVENING_VALUE = 18,
  MORNING_VALUE = 9,
  UNMODIFIED_VALUE = -1,
}

export default ScheduleTimeSelectEnum

export function buildTimeSelectOptions() {
  return [
    {
      label: `Around ${moment().set('minutes', 0).format('ha')}`,
      value: ScheduleTimeSelectEnum.UNMODIFIED_VALUE,
    },
    {
      label: 'Early morning (before 9am)',
      value: ScheduleTimeSelectEnum.EARLY_MORNING_VALUE,
    },
    {
      label: 'Morning (before noon)',
      value: ScheduleTimeSelectEnum.MORNING_VALUE,
    },
    {
      label: 'Afternoon (after noon)',
      value: ScheduleTimeSelectEnum.AFTERNOON_VALUE,
    },
    {
      label: 'Evening (after 6pm)',
      value: ScheduleTimeSelectEnum.EVENING_VALUE,
    },
  ]
}
