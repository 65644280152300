import { ReactComponent as CopySvg } from '@trinsly/common/src/assets/icons/copy.svg'
import Badge from '@trinsly/common/src/components/atoms/badge'
import Column from '@trinsly/common/src/components/atoms/column'
import Label from '@trinsly/common/src/components/atoms/label'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import React, { DetailedHTMLProps, InputHTMLAttributes } from 'react'

type CopyInputProps = {
  label?: string
  value?: string
  onChange?: (
    value: string,
    event: React.ChangeEvent<HTMLInputElement> | undefined
  ) => void
  error?: any
  testId?: string
} & Omit<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'onChange' | 'type' | 'size'
>

function _CopyInput(
  {
    label,
    className = '',
    disabled = false,
    value = '',
    onChange,
    error,
    autoFocus,
    id,
    required,
    testId = id,
    ...rest
  }: CopyInputProps,
  ref: React.Ref<HTMLInputElement>
) {
  return (
    <Column className="flex-1">
      {label && (
        <React.Fragment>
          <Label htmlFor={id} color={error ? 'danger-500' : undefined}>
            {label}
            {required ? ' *' : undefined}
          </Label>
          <Separator size="xs" />
        </React.Fragment>
      )}
      <Row className="relative">
        <input
          className={`${baseClasses} ${disabledClasses} ${
            error ? errorClasses : defaultClasses
          } ${className}`}
          ref={ref as any}
          value={value}
          disabled={disabled}
          data-lpignore="true"
          onChange={(event) => onChange?.(event.target.value, event)}
          id={id}
          {...{ 'data-test-id': testId }}
          {...rest}
        />
        <CopyButton textToCopy={value as any} />
      </Row>
      <Column style={{ height: 0 }}>
        <Column className="min-h-1" />
        {error && (
          <span className="text-danger-500" style={{ fontSize: '0.65rem' }}>
            {error}
          </span>
        )}
      </Column>
    </Column>
  )
}

const CopyInput = React.forwardRef(_CopyInput)
export default CopyInput

const baseClasses = `border px-3 w-full min-h-xl py-0 text-sm text-gray-900 placeholder-gray-300 rounded-l-md shadow-sm transition outline-none focus:z-10`
const defaultClasses = `bg-gray-50 border-gray-300 focus:border-primary-300 focus:ring focus:ring-primary-200`
const errorClasses = `bg-danger-50 bg-opacity-33 border-danger-300 focus:border-danger-300 focus:ring focus:ring-danger-200`
const disabledClasses = `disabled:border-dashed disabled:border-gray-200 disabled:bg-white disabled:cursor-not-allowed disabled:text-gray-400`

function copyText(textToCopy: string): boolean {
  const textarea = document.createElement('textarea')

  // Set the value of the text
  textarea.value = textToCopy

  // Make sure we cant change the text of the textarea
  textarea.setAttribute('readonly', '')

  // Hide the textarea off the screnn
  textarea.style.position = 'absolute'
  textarea.style.left = '-9999px'

  // Add the textarea to the page
  if (document.body) {
    document.body.appendChild(textarea)
  }

  // Copy the textarea
  textarea.select()

  const isSuccess = document.execCommand('copy')

  textarea.remove()

  return isSuccess
}

let timeout: null | number = null

interface CopyButtonProps {
  textToCopy: string
}

function CopyButton({ textToCopy }: CopyButtonProps) {
  const [isSuccess, setIsSuccess] = React.useState(false)

  const handleClick = React.useCallback(() => {
    if (timeout) clearTimeout(timeout)

    const newIsSuccess = copyText(textToCopy)

    if (!newIsSuccess) return
    if (!isSuccess) setIsSuccess(true)

    timeout = window.setTimeout(() => {
      setIsSuccess(false)
    }, 3100)
  }, [isSuccess, textToCopy])

  return (
    <Column className="relative">
      {isSuccess && (
        <Column className="fade-out absolute z-10 left-1/2 -top-3 transform -translate-x-1/2 border border-success-500 rounded-full">
          <Badge size="sm" intent="success">
            Copied!
          </Badge>
        </Column>
      )}

      <button
        className="-ml-px min-h-xl inline-flex items-center gap-1 px-sm py-xs text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 transition hover:bg-gray-100 focus:outline-none border border-gray-300 focus:border-primary-300 focus:ring focus:ring-primary-200"
        onClick={handleClick}
        title="Copy to clipboard"
        type="button"
      >
        <CopySvg className="w-4 h-4" />

        <span className="ml-1">{'Copy'}</span>
      </button>
    </Column>
  )
}
