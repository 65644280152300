import PageableType from '@trinsly/common/src/types/pageable-type'
import { useQuery } from 'react-query'
import getThreadsService from 'services/get-threads-service'

type UseGetThreadsProps = { enabled?: boolean } & PageableType

function useGetThreads({ enabled, ...pageable }: UseGetThreadsProps) {
  const { data, error, status } = useQuery(
    ['/threads', pageable],
    () => getThreadsService(pageable),
    {
      staleTime: 60000,
      cacheTime: 60000,
      enabled,
    }
  )

  const threads = data?.emailThreads || []

  return {
    data: threads,
    total: data?.meta?.pagination?.totalObjects || 0,
    pending: status === 'loading',
    error,
  }
}

export default useGetThreads
