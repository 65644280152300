import api from '@trinsly/common/src/services/api'
import CampaignType from 'types/campaign-type'
import ProjectType from 'types/project-type'

type DeleteCampaignFromProjectServiceProps = {
  projectId: ProjectType['id']
  campaignId: CampaignType['id']
}

async function deleteCampaignFromProjectService({
  projectId,
  campaignId,
}: DeleteCampaignFromProjectServiceProps) {
  const response = await api(`/projects/${projectId}/campaigns/${campaignId}`, {
    method: 'DELETE',
  })
  return
}

export default deleteCampaignFromProjectService
