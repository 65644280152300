import Column from '@trinsly/common/src/components/atoms/column'
import Form from '@trinsly/common/src/components/atoms/form'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Switch from '@trinsly/common/src/components/atoms/switch'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import useForm from '@trinsly/common/src/hooks/use-form'
import useGetUserSession from 'api-hooks/use-get-user-session'
import useUpdateUser2Fa from 'api-hooks/use-update-user-2fa'
import TelInput from 'components/tel-input'
import React from 'react'
import { toast } from 'react-toastify'
import Deactivate2FaConfirmationModal from './deactivate-2fa-confirmation-modal'
import Verify2faOtpSetupForm from './verify-2fa-otp-setup-form'

export interface TwoFactorStatusCheck {
  email: string
  isTwoFactorEnabled: boolean
}
export enum TwoFactorAuthStatusEnum {
  DISABLED = 'disabled',
  ENABLED = 'enabled',
  PENDING = 'pending',
}

const translateTwoFactorAuthStatusEnum: Record<
  TwoFactorAuthStatusEnum,
  string
> = {
  [TwoFactorAuthStatusEnum.DISABLED]: 'Disabled',
  [TwoFactorAuthStatusEnum.ENABLED]: 'Enabled',
  [TwoFactorAuthStatusEnum.PENDING]: 'Pending',
}

export type TwoFactorAuthSectionProps = {}

const TwoFactorAuthSection = ({}: TwoFactorAuthSectionProps) => {
  const [numberValid, setNumberValid] = React.useState(false)
  const [showModal, setShowModal] = React.useState(false)
  const [toggle, setToggle] = React.useState(Boolean)
  const [hasOtpSent, setHasOtpSent] = React.useState(Boolean)

  const { data: userSession, pending: userSessionPending } = useGetUserSession(
    {}
  )
  const {
    start: start2FA,
    pending: start2FAPending,
    verifyOtp: verify,
    otpVerificationPending,
    deactivate2fa,
    deactivatePending,
  } = useUpdateUser2Fa({
    onSuccess: () => {
      if (userSession.twoFactorAuthStatus === TwoFactorAuthStatusEnum.ENABLED) {
        toast('Two-factor authentication enabled', { type: 'success' })
      }
    },
    onMutate: () => {},
  })
  const { submit, getValue, setValue } = useForm({
    onSubmit: ({ phoneNumber }) => {
      start2FA({ phoneNumber: phoneNumber })
      setHasOtpSent(true)
      toast('2FA code sent', { type: 'success' })
    },
  })
  React.useEffect(() => {
    if (userSession?.twoFactorAuthStatus === TwoFactorAuthStatusEnum.ENABLED) {
      setToggle(true)
      setHasOtpSent(true)
    }
    if (userSession?.twoFactorAuthStatus === TwoFactorAuthStatusEnum.PENDING) {
      setHasOtpSent(true)
    }
  }, [userSession])

  return (
    <>
      <Form onSubmit={submit}>
        <Column className="flex-1 justify-center items-start">
          <Column className="max-w-lg">
            <Heading
              size="sm"
              fontWeight="bold"
              color="gray-500"
              className="uppercase"
            >
              Two-Factor Authentication
            </Heading>
            <Text color="gray-500" size="sm">
              Set up two-factor authentication with SMS for increased account
              security.{' '}
              <Text color="danger-500">Only for US numbers at this time.</Text>
            </Text>
          </Column>
          <Separator />
          {userSessionPending ? (
            <Text>Pending Session</Text>
          ) : (
            <>
              <Row className="items-center">
                <Switch
                  checked={toggle || hasOtpSent}
                  onChange={() => {
                    if (hasOtpSent) {
                      setShowModal(true)
                    } else {
                      setToggle(!toggle)
                    }
                  }}
                />
                <Separator size="xs" />
                <Text
                  color={
                    {
                      [TwoFactorAuthStatusEnum.ENABLED as string]:
                        'success-500',
                      [TwoFactorAuthStatusEnum.PENDING as string]:
                        'warning-500',
                      [TwoFactorAuthStatusEnum.DISABLED as string]: 'gray-500',
                    }[userSession?.twoFactorAuthStatus] as any
                  }
                  fontWeight="bold"
                >
                  {
                    translateTwoFactorAuthStatusEnum[
                      userSession?.twoFactorAuthStatus as TwoFactorAuthStatusEnum
                    ]
                  }
                </Text>
              </Row>
              <Separator />
              {toggle && (
                <>
                  {(userSession.twoFactorAuthStatus ===
                    TwoFactorAuthStatusEnum.DISABLED ||
                    userSession.twoFactorAuthStatus ===
                      TwoFactorAuthStatusEnum.PENDING) && (
                    <>
                      <Form onSubmit={submit}>
                        <Row className="items-center">
                          <TelInput
                            disabled={
                              userSession.twoFactorAuthStatus ===
                              TwoFactorAuthStatusEnum.PENDING
                            }
                            onChange={(value) => setValue('phoneNumber', value)}
                            onSetNumber={(value) => {
                              setNumberValid(Boolean(value))
                            }}
                            value={getValue('phoneNumber')}
                          />
                          <Separator />
                          <Button
                            type="submit"
                            variant="outline"
                            intent="info"
                            disabled={!getValue('phoneNumber') || !numberValid}
                            pending={start2FAPending}
                          >
                            send
                          </Button>
                        </Row>
                      </Form>
                    </>
                  )}
                  {userSession.twoFactorAuthStatus ===
                    TwoFactorAuthStatusEnum.PENDING && (
                    <Verify2faOtpSetupForm
                      verify={verify}
                      restart={() => {
                        deactivate2fa({})
                      }}
                      disabled={
                        userSession.twoFactorAuthStatus !==
                        TwoFactorAuthStatusEnum.PENDING
                      }
                      pending={otpVerificationPending}
                    />
                  )}
                </>
              )}
            </>
          )}
        </Column>
      </Form>
      <Deactivate2FaConfirmationModal
        visible={showModal}
        onRequestClose={() => setShowModal(false)}
        pending={deactivatePending}
        onConfirm={() => {
          deactivate2fa({})
          setToggle(false)
          setShowModal(false)
          setHasOtpSent(false)
          toast('Two-factor authentication disabled', { type: 'success' })
        }}
      />
    </>
  )
}

export default TwoFactorAuthSection
