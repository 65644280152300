import api from '@trinsly/common/src/services/api'

export type CreateThrottleServiceProps = {
  includeSundays: boolean
  emailsCount: number
  isActive: boolean
  startHour: number
  endHour: number
  minimumInterval?: number
  userId?: number
  createdAt: string
  updatedAt: string
  id: number
}

export default async function updateThrottleSettingsService({
  includeSundays,
  emailsCount,
  isActive,
  startHour,
  endHour,
  minimumInterval,
  //not in form
  userId,
  createdAt,
  updatedAt,
  id,
}: CreateThrottleServiceProps) {
  const response = await api('/settings/throttle', {
    body: {
      is_active: isActive,
      include_sundays: includeSundays,
      emails_count: emailsCount,
      minimum_interval: minimumInterval,
      start_hour: startHour,
      end_hour: endHour,

      //not actually in form
      user_id: userId,
      created_at: createdAt,
      updatedAt: updatedAt,
      id: id,
    },
    method: 'PUT',
  })

  return response as any
}
