import usePageable from '@trinsly/common/src/hooks/use-pageable'
import useGetProspects from 'api-hooks/use-get-prospects'
import React from 'react'
import { useLocation } from 'react-router-dom'
import ProspectType from 'types/prospect-type'

const initialState = {
  allProspects: [],
  selectedProspects: [],
  toggleAll: () => {},
  toggleProspect: () => {},
  isAtLeastOneProspectSelected: false,
  areAllProspectsSelected: false,
  pending: false,
} as {
  pending: boolean
  allProspects: ProspectType[]
  selectedProspects: ProspectType[]
  toggleAll: () => void
  toggleProspect: (prospect: ProspectType) => void
  isAtLeastOneProspectSelected: boolean
  areAllProspectsSelected: boolean
}

export const BATCH_LIMIT = 1000

const WHITELISTED_ROUTES = ['/prospects', '/batches/new']
const Context = React.createContext(initialState)

export const ProspectsSelectionProvider = ({
  children,
}: {
  children: React.ReactNode | React.ReactNodeArray
}) => {
  const location = useLocation()
  const { pageable } = usePageable()
  const { data: allProspects, pending } = useGetProspects({
    ...pageable,
    page: 1,
    perPage: BATCH_LIMIT,
    enabled: WHITELISTED_ROUTES.includes(location?.pathname),
  })

  const [selectedProspects, setSelectedProspects] = React.useState<
    ProspectType[]
  >([])
  const selectedProspectsIds = selectedProspects.map((prospect) => prospect.id)
  const areAllProspectsSelected =
    (selectedProspects.length === allProspects.length ||
      selectedProspects.length === BATCH_LIMIT) &&
    allProspects.length > 0

  const isAtLeastOneProspectSelected = selectedProspects.length > 0

  function toggleProspect(prospect: ProspectType) {
    const canAdd = selectedProspects.length < BATCH_LIMIT
    const newSelectedProspect = selectedProspectsIds.includes(prospect.id)
      ? selectedProspects.filter(
          (selectedProspect: ProspectType) =>
            selectedProspect.id !== prospect.id
        )
      : canAdd
      ? [...selectedProspects, prospect]
      : [...selectedProspects]
    setSelectedProspects(newSelectedProspect)
  }

  function toggleAll() {
    const limitedAllProspects =
      allProspects.length > BATCH_LIMIT
        ? allProspects.slice(0, BATCH_LIMIT)
        : allProspects
    const newSelectedProspectIds = areAllProspectsSelected
      ? []
      : limitedAllProspects

    setSelectedProspects(newSelectedProspectIds)
  }

  //clears selection if pageable data changes (filters, page, perpage)
  React.useEffect(() => {
    if (location.pathname.includes('/prospects')) {
      setSelectedProspects([])
    }
  }, [JSON.stringify(pageable)])

  //clears selection on location change
  React.useEffect(() => {
    if (!WHITELISTED_ROUTES.includes(location?.pathname)) {
      setSelectedProspects([])
    }
  }, [location?.pathname])

  return (
    <Context.Provider
      value={{
        pending,
        allProspects,
        selectedProspects,
        toggleAll,
        toggleProspect,
        isAtLeastOneProspectSelected,
        areAllProspectsSelected,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useProspectsSelectionContext = () => React.useContext(Context)
