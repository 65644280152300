import { ReactComponent as DownloadSvg } from '@trinsly/common/src/assets/icons/download.svg'
import Column from '@trinsly/common/src/components/atoms/column'
import Divider from '@trinsly/common/src/components/atoms/divider'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Input from '@trinsly/common/src/components/atoms/input'
import Row from '@trinsly/common/src/components/atoms/row'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import useQueryParams from '@trinsly/common/src/hooks/use-query-params'
import Papa from 'papaparse'
import { useProspectsSelectionContext } from 'providers/prospects-selection-provider'
import React from 'react'
import { toast } from 'react-toastify'
import { useDraftContext } from '../draft-provider'
import { useFormContext } from '../form-provider'
import ProspectsCsvTable from './prospects-csv-table'

type UploadCsvSectionProps = {}

function UploadCsvSection({}: UploadCsvSectionProps) {
  const fileInputRef = React.useRef<HTMLInputElement>(null)
  const { params } = useQueryParams()
  const { selectedProspects } = useProspectsSelectionContext()
  const { getValue, getValues, setValue, setValues } = useFormContext()
  const { draft, pending: pendingDraft } = useDraftContext()
  const shouldDisableForm = pendingDraft

  //clears on first render
  React.useEffect(() => {
    setValue('csvFileName', undefined)
    setValue('csv', undefined)
  }, [])

  //autofill csv data with selected prospects if source is from prospect list screen
  React.useEffect(() => {
    const shouldFillCsv =
      !draft?.id &&
      params?.source === 'prospects' &&
      selectedProspects?.length > 0
    if (shouldFillCsv) {
      const csvData = [
        ['Email', 'FirstName', 'LastName', 'CompanyName', 'Role'],
        ...(selectedProspects?.map?.((prospect) => {
          return [
            prospect?.email,
            prospect?.firstName,
            prospect?.lastName,
            prospect?.employments?.[0]?.companyName,
            prospect?.employments?.[0]?.role,
          ]
        }) || []),
      ]
      setValue('csvFileName', 'Created from prospects')
      setValue('csv', csvData)
    }
  }, [draft?.id, pendingDraft, params?.source])

  const hasCsv = !pendingDraft && Boolean(getValue('csv'))

  return (
    <Column className="gap-md">
      <Column className="px-lg gap-md">
        <Row className="items-center gap-md">
          <Heading size="lg" fontWeight="bold">
            Upload a csv
          </Heading>
          <Column>
            <Button
              variant="ghost"
              shape="rounded"
              disabled={shouldDisableForm}
              onClick={() => {
                fileInputRef?.current?.click?.()
              }}
            >
              <Row className="items-center gap-xs">
                <DownloadSvg /> Upload a csv
              </Row>
            </Button>
            <input
              type="file"
              accept=".csv"
              ref={fileInputRef}
              className="hidden"
              onChange={(files) => {
                const file = fileInputRef?.current?.files?.[0]
                debugger
                if (file) {
                  Papa.parse<string[]>(file, {
                    skipEmptyLines: true,
                    preview: 1001,
                    complete: (result) => {
                      if (result?.errors?.[0]?.message) {
                        toast.error(
                          result?.errors?.[0]?.message ||
                            'Error parsing CSV file'
                        )
                        return
                      }
                      setValues({
                        ...(getValues() || {}),
                        csv: result?.data,
                        csvFileName: file?.name || 'file.csv',
                      })
                    },
                  })
                }
              }}
            />
          </Column>
          <Row className="items-center gap-1">
            <Text size="sm">Download</Text>
            <Button
              intent="info"
              variant="outline"
              size="xs"
              onClick={() => window.open('/batch_csv_template.csv', '_blank')}
            >
              template
            </Button>
            <Text size="sm">to get started.</Text>
          </Row>
        </Row>
        <Column>
          <Text size="sm">Batches are limited to less than 1000.</Text>
          <Text size="sm">
            Any candidate with email threads in progress will automatically be
            removed.
          </Text>
          <Row className="items-center gap-xs">
            <Text size="sm">
              Also remove candidates that anyone contacted within the last
            </Text>
            <Column>
              <Input
                id="exclude-contacted-within"
                type="number"
                min={0}
                step={1}
                disabled={shouldDisableForm}
                max={365}
                value={getValue('excludeContactedWithin')}
                onChange={(value) => {
                  setValue('excludeContactedWithin', value || 0)
                }}
              />
            </Column>
            <Text size="sm">days.</Text>
          </Row>
        </Column>
      </Column>
      {hasCsv && (
        <Column>
          <Divider />
          <Column className="px-lg py-xs">
            <Text size="sm" fontWeight="bold" color="primary-500">
              {getValue('csvFileName')}
            </Text>
          </Column>
          <Divider />
          <ProspectsCsvTable
            csv={getValue('csv')}
            onChange={(newCsv) => {
              setValue('csv', newCsv)
            }}
          />
        </Column>
      )}
    </Column>
  )
}

export default UploadCsvSection
