import Column from '@trinsly/common/src/components/atoms/column'
import { useNotificationsContext } from '.'
import NotificationListItem from './notification-list-item'

function NotificationsRenderer() {
  const { notifications } = useNotificationsContext()
  const hasNotification = notifications?.length > 0

  if (!hasNotification) {
    return null
  }

  return (
    <Column className="px-xl py-sm space-y-xs bg-primary-900">
      {notifications?.map((notification) => (
        <NotificationListItem
          key={notification.key}
          notification={notification}
        />
      ))}
    </Column>
  )
}

export default NotificationsRenderer
