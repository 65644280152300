// core
import './tinymce/tinymce.min.js'
import './tinymce/themes/silver/theme.min.js'
import './tinymce/skins/ui/oxide/content.min.css'
import './tinymce/skins/ui/oxide/skin.min.css'
import './tinymce/skins/ui/oxide/fonts/tinymce-mobile.woff'

// plugins
import './tinymce/plugins/advlist/plugin.min.js'
import './tinymce/plugins/autolink/plugin.min.js'
import './tinymce/plugins/charmap/plugin.min.js'
import './tinymce/plugins/code/plugin.min.js'
import './tinymce/plugins/fullscreen/plugin.min.js'
import './tinymce/plugins/image/plugin.min.js'
import './tinymce/plugins/imagetools/plugin.min.js'
import './tinymce/plugins/insertdatetime/plugin.min.js'
import './tinymce/plugins/legacyoutput/plugin.min.js'
import './tinymce/plugins/link/plugin.min.js'
import './tinymce/plugins/lists/plugin.min.js'
import './tinymce/plugins/media/plugin.min.js'
import './tinymce/plugins/preview/plugin.min.js'
import './tinymce/plugins/print/plugin.min.js'
import './tinymce/plugins/paste/plugin.min.js'
import './tinymce/plugins/table/plugin.min.js'
import './tinymce/plugins/wordcount/plugin.min.js'

import { Editor as TinyMceEditor } from '@tinymce/tinymce-react'
import { useEffect, useState } from 'react'
// @ts-ignore
import S3Upload from 'react-s3-uploader/s3upload'
import './index.css'

interface BlobInfo {
  base64: Function
  blob: Function
  blobUri: Function
  filename: Function
  id: Function
  name: Function
  uri: Function
}

interface File {
  lastModified: number
  lastModifiedDate: unknown
  name: string // "cat-dog.jpg"
  preview?: string // "blob:http://localhost:3000/c3f6768f-09c6-4be9-b8a3-4c20253e0098"
  size: number
  type: number // "image/jpeg"
  webkitRelativePath: string
}

interface S3FileInfo {
  file: {
    name: string
  }
  fileUrl: string
  filename: string
  signedUrl: string
}
interface Editor {
  editorContainer: HTMLDivElement
  getBody: Function
}

function buildImageLocation(s3Url: string, filename: string) {
  return `${s3Url.endsWith('/') ? s3Url.slice(0, -1) : s3Url}/${filename}`
}

function wrapSetup(
  callback: (editor: Editor) => void = () => {},
  externalCallback: (editor: Editor) => void = () => {}
) {
  return function (editor: Editor) {
    callback(editor)
    externalCallback(editor)
  }
}

function uploadToAwsS3({
  file,
  getAwsSignedUrl,
  onError,
  onFinishS3Put,
}: {
  file: File
  getAwsSignedUrl: (file: File, callback: Function) => void
  onError: (error: string) => void
  onFinishS3Put: (info: S3FileInfo, file: unknown) => void
}) {
  const uploadOptions = {
    contentDisposition: 'auto',
    files: [file],
    getSignedUrl: getAwsSignedUrl,
    onError,
    onFinishS3Put,
    s3path: '',
    signingUrl: '/s3/sign',
    uploadRequestHeaders: {},
  }

  const s3Upload = new S3Upload(uploadOptions)

  return s3Upload
}

function Editor({
  disabled = false,
  getAwsSignedUrl = () => {},
  id,
  init,
  initialValue,
  onChange,
  s3Url = '',
  value,
}: {
  disabled?: boolean
  getAwsSignedUrl?: (file: File, callback: Function) => void
  id?: string
  init: Record<string, any>
  initialValue?: string
  onChange: (value: string) => void
  s3Url?: string
  value?: string
}) {
  const [editor, setEditor] = useState<any>()

  init.formats = {
    removeformat: [
      {
        selector: 'h1,h2,h3,h4,h5,h6,ul,li,ol',
        remove: 'all',
        split: false,
        expand: false,
        block_expand: true,
        deep: true,
      },
      {
        selector:
          'a,b,strong,em,i,font,u,strike,sub,sup,dfn,code,samp,kbd,var,cite,mark,q,del,ins',
        remove: 'all',
        split: false,
        expand: false,
        block_expand: true,
        deep: true,
      },
      {
        selector: '*',
        attributes: ['style', 'class'],
        split: false,
        expand: false,
        deep: true,
      },
    ],
  }

  init.setup = wrapSetup((editorRef) => {
    // @ts-ignore
    setEditor(editorRef)

    // @ts-ignore
    editorRef.on('blur', function (e) {
      editorRef.editorContainer.classList.remove('focus')
    })

    // @ts-ignore
    editorRef.on('focus', function (e) {
      editorRef.editorContainer.classList.add('focus')
    })
  }, init.setup)

  // Enable user's default browser spell check
  init.browser_spellcheck = true

  init.toolbar =
    typeof init.toolbar !== 'undefined'
      ? init.toolbar
      : `undo redo | fontselect | bold italic forecolor backcolor |
  alignleft aligncenter alignright alignjustify |
  bullist numlist outdent indent | link image | styleselect | code removeformat`

  // Keeping consistent with Bulma
  // For link color, https://html.spec.whatwg.org/multipage/rendering.html#phrasing-content-3
  init.content_style = init.content_style
    ? init.content_style
    : `
    p, div, li {
      color: #363636;
      font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
      font-size: 14px;
      line-height: 18px;
      -webkit-font-smoothing: antialiased;
    }

    a, a:visited {
      color: #0000EE;
    }
  `

  // Default unwrapped blocks to <div>. Note that if there is a copy + paste, it
  // will still retain the <p> or any relevant HTML tags.
  init.forced_root_block = 'div'

  init.paste_preprocess = function (
    plugin: unknown,
    args: Record<string, any>
  ) {
    // Strips out newlines because Outlook strips it
    args.content = args.content.replace(/(?:\r\n|\r|\n)/g, '')
  }

  // Handle image upload
  init.images_upload_handler = (
    blobInfo: BlobInfo,
    success: (location: string) => void,
    failure: (error: string) => void
  ) => {
    uploadToAwsS3({
      file: blobInfo.blob(),
      getAwsSignedUrl,
      onError: (error: string) => failure(error),
      onFinishS3Put: (info: S3FileInfo, file) => {
        const location = buildImageLocation(s3Url, info.filename)
        success(location)
      },
    })
  }

  // Handle disabled
  useEffect(() => {
    if (!editor || !editor.getBody()) {
      return
    }

    if (disabled) {
      editor.getBody().setAttribute('contenteditable', false)
      editor.editorContainer.style.pointerEvents = 'none'
    } else {
      editor.getBody().setAttribute('contenteditable', true)
      editor.editorContainer.style.pointerEvents = 'all'
    }
  }, [disabled, editor])

  if (typeof initialValue !== 'undefined') {
    return (
      // @ts-ignore
      <TinyMceEditor
        id={id}
        init={init}
        initialValue={initialValue}
        onEditorChange={onChange}
      />
    )
  }

  // Not sure if this is ever being hit, because initialValue seems to be always defined
  return (
    // @ts-ignore
    <TinyMceEditor
      id={id}
      init={init}
      onEditorChange={onChange}
      value={value}
    />
  )
}

export default Editor
