import api from '@trinsly/common/src/services/api'
import ThreadType from 'types/thread-type'

type RescheduleAllUnsentThreadsServiceProps = {}

async function rescheduleAllUnsentThreadsService({}: RescheduleAllUnsentThreadsServiceProps) {
  const response = await api(`/email_threads/reschedule`, {
    method: 'POST',
  })
  return response as any as ThreadType[]
}

export default rescheduleAllUnsentThreadsService
