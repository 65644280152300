import { compact } from 'lodash'

type SlugifyOptions = { suffix?: string }
const allowedTypes = ['string', 'number', 'boolean']

function slugify(text: any, { suffix }: SlugifyOptions = {}) {
  if (!text) {
    return
  }

  if (!allowedTypes.includes(typeof text)) {
    return
  }

  const slugifiedText = text
    ?.toString?.()
    ?.toLowerCase?.()
    ?.replace?.(/\s+/g, '-') // Replace spaces with -
    ?.replace?.(/[^\u0100-\uFFFF\w\-]/g, '-') // Remove all non-word chars ( fix for UTF-8 chars )
    ?.replace?.(/\-\-+/g, '-') // Replace multiple - with single -
    ?.replace?.(/^-+/, '') // Trim - from start of text
    ?.replace?.(/-+$/, '') // Trim - from end of text

  return compact([slugifiedText, suffix]).join('-')
}

export default slugify
