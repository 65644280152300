import { useQuery } from 'react-query'
import getProjectReportStatsService, {
  GetProjectReportStatsServiceProps,
} from 'services/get-project-report-stats'

type UseGetProjectReportStatsProps = {} & GetProjectReportStatsServiceProps

function useGetProjectReportStats({
  projectId,
  startDate,
  endDate,
  period,
}: UseGetProjectReportStatsProps) {
  const { data, error, status } = useQuery(
    ['/projects/{id}/reports/stats', projectId, startDate, endDate, period],
    () =>
      getProjectReportStatsService({ projectId, startDate, endDate, period }),
    {
      enabled: Boolean(projectId) && Boolean(startDate) && Boolean(endDate),
    }
  )
  return {
    data,
    pending: status === 'loading' || !Boolean(projectId),
    error,
  }
}

export default useGetProjectReportStats
