import api from '@trinsly/common/src/services/api'
import ProspectNoteType from 'types/prospect-note-type'

export type GetProspectNotesServiceProps = {
  prospectId: Id
}

async function getProspectNotesService({
  prospectId,
}: GetProspectNotesServiceProps) {
  const response = await api(`/prospects/${prospectId}/notes`, {
    method: 'GET',
  })
  return response as ProspectNoteType[]
}

export default getProspectNotesService
