import Column from '@trinsly/common/src/components/atoms/column'
import Text from '@trinsly/common/src/components/atoms/text'
import Select, {
  SelectProps,
} from '@trinsly/common/src/components/molecules/select'

import useGetOrganization from 'api-hooks/use-get-organization'
import { useAuth } from 'providers/auth-provider'

import { OrganizationMember } from 'types/settings-type'

interface SelectMemberProps
  extends Omit<SelectProps<OrganizationMember>, 'itemRender' | 'options'> {}

function SelectMember(props: SelectMemberProps) {
  const { user } = useAuth()
  const { data: organization, pending: pendingGetMembers } = useGetOrganization(
    { organizationId: user?.organizationId ?? 0 }
  )

  return (
    <Select
      {...props}
      options={organization?.memberships}
      creatable={false}
      clearable={false}
      pending={pendingGetMembers}
      itemRenderer={(item) => <Text>{item.userName}</Text>}
      valueRenderer={(item) => item.userName}
      compareWith={(a, b) => a.userId === b.userId}
      filterWith={({
        inputValue,
        option,
      }: {
        inputValue: string
        option: OrganizationMember
      }) => {
        return option?.userName
          ?.toLowerCase?.()
          .includes?.(inputValue?.toLowerCase?.())
      }}
    />
  )
}

export default SelectMember
