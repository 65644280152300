import useSendEmail from '@trinsly/common/src/api-hooks/use-send-email'
import Button from '@trinsly/common/src/components/molecules/button'
import useGetUserSession from 'api-hooks/use-get-user-session'
import { toast } from 'react-toastify'

type SendTestEmailButtonProps = {
  subject: string
  body: string
}

function SendTestEmailButton({ subject, body }: SendTestEmailButtonProps) {
  const { data: userSession, pending: pendingUserSession } = useGetUserSession(
    {}
  )
  const { fetch: sendEmail, pending: pendingSendEmail } = useSendEmail({
    onSuccess: () => {
      toast.success(`Email sent to ${userSession?.email || ''}!`)
    },
    onError: (error) => {
      toast.error('Error sending test email')
    },
  })

  return (
    <Button
      size="sm"
      testId="send-test-email-button"
      pending={pendingSendEmail}
      onClick={() => {
        if (pendingUserSession || !userSession) {
          alert('Unable to send a test email')
        }
        sendEmail({
          to: userSession?.email ?? '',
          subject,
          body,
        })
      }}
    >
      Send Test Email
    </Button>
  )
}

export default SendTestEmailButton
