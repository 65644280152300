import Card from '@trinsly/common/src/components/atoms/card'
import Column from '@trinsly/common/src/components/atoms/column'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Label from '@trinsly/common/src/components/atoms/label'
import Row from '@trinsly/common/src/components/atoms/row'
import Spinner from '@trinsly/common/src/components/atoms/spinner'
import Text from '@trinsly/common/src/components/atoms/text'
import Modal from '@trinsly/common/src/components/molecules/modal'
import ThreadStatusTag from '@trinsly/common/src/components/molecules/thread-status-tag'
import useQueryParams from '@trinsly/common/src/hooks/use-query-params'
import useGetThread from 'api-hooks/use-get-thread'
import useUpdateEmailThreadState from 'api-hooks/use-update-email-thread-state'
import ResponseTypeTag from 'components/response-type-tag'
import React from 'react'
import { toast } from 'react-toastify'
import ThreadType from 'types/thread-type'
import RespondedTypeCell from './dashboard-section/reach-outs-section/responded-type-cell'

interface MarkEmailThreadResponseTypeModalProps {
  onRequestClose: () => void
  visible: boolean
  threadId: ThreadType['id']
}

function MarkEmailThreadResponseTypeModal({
  threadId,
  visible,
  onRequestClose,
}: MarkEmailThreadResponseTypeModalProps) {
  const { params } = useQueryParams()
  const { data: thread, pending: pendingThread } = useGetThread({
    threadId,
  })
  const { updateResponseType, pendingResponseTypeUpdate } =
    useUpdateEmailThreadState({
      onSuccess: () => {
        toast.success('Response Type updated!')
      },
      onError: (error) => {
        toast.error('Error updating Response Type')
      },
    })

  React.useEffect(() => {
    if (
      Boolean(thread?.id) &&
      Boolean(params?.responseType) &&
      thread?.responseType !== params?.responseType
    ) {
      updateResponseType({
        reachOutId: thread?.id!,
        responseType: params?.responseType,
      })
    }
  }, [thread?.id, params?.responseType])

  const hasData = Boolean(threadId) && Boolean(thread)

  return (
    <Modal onRequestClose={onRequestClose} visible={visible}>
      <Card className="p-md min-h-44">
        {pendingThread ? (
          <Column className="items-center justify-center flex-1">
            <Spinner size="xl" />
          </Column>
        ) : !hasData ? (
          <Column className="items-center justify-center flex-1">
            <Text>
              Either the email thread is not found or you do not have permission
              to edit.
            </Text>
          </Column>
        ) : (
          <Column className="gap-md">
            <Column className="gap-sm">
              <Row className="items-center gap-xs">
                <ThreadStatusTag status={thread?.state as any} />
                <ResponseTypeTag responseType={thread?.responseType as any} />
                {pendingResponseTypeUpdate && <Spinner size="xs" />}
              </Row>
              <Heading size="xl" fontWeight="bold">
                {thread?.email}
              </Heading>
            </Column>
            <Row className="justify-between">
              <Column className="gap-1">
                <Text fontWeight="bold">{thread?.campaignName}</Text>
                <Text>
                  To: <a href={`mailto:${thread?.email}`}>{thread?.email}</a>
                </Text>
                <Text>From {thread?.userName}</Text>
              </Column>
              {!pendingResponseTypeUpdate && (
                <Column className="gap-1">
                  <Label>Response Type</Label>
                  <RespondedTypeCell history={thread as any} />
                </Column>
              )}
            </Row>
          </Column>
        )}
      </Card>
    </Modal>
  )
}

export default MarkEmailThreadResponseTypeModal
