/* eslint-disable sort-imports */
import App from 'app'
import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import ReactModal from 'react-modal'

//external
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import LogRocket from 'logrocket'
import TagManager from 'react-gtm-module'

//styles
import '@trinsly/common/src/styles/index.css'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import 'react-toastify/dist/ReactToastify.css'
import './index.css'
import './styles/tailwind.css'

//providers
import ErrorBoundary from 'components/error-boundary'
import AuthContext from 'providers/auth-provider'
import NotificationsProvider from 'providers/notifications-provider'
import { ProspectsSelectionProvider } from 'providers/prospects-selection-provider'

//polyfills?
import 'url-search-params-polyfill'
// @ts-expect-error Could not find a declaration file for module 'object.values'
import objectValues from 'object.values'

if (!Object.values) objectValues.shim()

const { RecoilRoot } = require('recoil')

if (process.env.NODE_ENV !== 'development') {
  try {
    Sentry.init({
      dsn: 'https://7e93b6ca8ba44001881033745ecbc2b4@o1058365.ingest.sentry.io/6047985',
      integrations: [
        new Integrations.BrowserTracing({
          tracingOrigins: [
            process.env.REACT_APP_API_URL?.toString() || 'api.trinsly.com',
          ],
        }),
      ],
      tracesSampleRate: 0.2,
      environment: process.env.REACT_APP_SENTRY_ENVIRONMENT || 'development',

      beforeSend(event) {
        event.extra = event.extra || {}
        if (LogRocket.sessionURL !== null) {
          event.extra!['LogRocket'] = LogRocket.sessionURL
        }
        return event
      },
    })

    //Google tag manager
    TagManager.initialize({ gtmId: 'GTM-MRX7XVJ' })

    //Runs LogRocket in PROD only
    if (window.location.href.includes('https://app.trinsly.com')) {
      console.debug('Initializing LogRocket')
      LogRocket.init('trinsly/trinsly-app')
    }
  } catch (error) {
    console.warn('Unable to load 1 or more analytics providers')
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
})

ReactDOM.render(
  <ErrorBoundary>
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <BrowserRouter
          getUserConfirmation={() => {
            /* Empty callback to block the default browser prompt */
          }}
        >
          <NotificationsProvider>
            <AuthContext>
              <ProspectsSelectionProvider>
                <App />
              </ProspectsSelectionProvider>
            </AuthContext>
          </NotificationsProvider>
        </BrowserRouter>
      </RecoilRoot>
      <ToastContainer />
    </QueryClientProvider>
  </ErrorBoundary>,
  document.getElementById('root')
)

ReactModal.setAppElement('#root')
