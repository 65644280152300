import { ReactComponent as LineChartSvg } from '@trinsly/common/src/assets/icons/line-chart.svg'
import Column from '@trinsly/common/src/components/atoms/column'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Spinner from '@trinsly/common/src/components/atoms/spinner'
import Text from '@trinsly/common/src/components/atoms/text'
import LineChart, {
  LineChartProps,
} from '@trinsly/common/src/components/molecules/line-chart'
import useQueryParams from '@trinsly/common/src/hooks/use-query-params'
import { eachDayOfInterval, format, parseISO } from 'date-fns'
import { uniq } from 'lodash'
import React from 'react'

export type CampaignSummaryLineChartProps = {
  title: string
  currentData: number[]
  previousData: number[]
  period: 'last7Days' | 'last30Days' | 'last60Days'
  pending: boolean
  startDate: string
  endDate: string
}

function CampaignSummaryLineChart({
  title,
  currentData,
  previousData,
  pending,
  startDate,
  endDate,
  period,
}: CampaignSummaryLineChartProps) {
  const { params } = useQueryParams()

  const { labels, datasets } = React.useMemo(() => {
    if (pending || (!currentData?.length && !previousData?.length)) {
      return { labels: [], datasets: [] }
    }
    const parsedStartDate = parseISO(startDate)
    const parsedEndDate = parseISO(endDate)
    const labels: LineChartProps['labels'] = uniq(
      eachDayOfInterval({ start: parsedStartDate, end: parsedEndDate })
    ).map((date) => format(date, 'dd'))

    const datasets: LineChartProps['datasets'] = [
      {
        label: (
          {
            last7Days: 'This Week',
            last30Days: 'This Month',
            last60Days: 'Last 60 days',
          } as any
        )[period],
        data: labels.map((label, index) => currentData[index] || 0),
        borderColor: '#4734AE',
        backgroundColor: '#4734AE80',
      },
      {
        label: (
          {
            last7Days: 'Previous Week',
            last30Days: 'Previous Month',
            last60Days: 'Previous to last 60 days',
          } as any
        )[period],
        data: labels.map((label, index) => previousData[index] || 0),
        borderColor: '#4734AE',
        backgroundColor: '#4734AE80',
        borderDash: [3, 2],
      },
    ]
    return { labels, datasets }
  }, [params])
  const hasData = !(
    datasets[0]?.data?.every((data) => data === 0) &&
    datasets[1]?.data?.every((data) => data === 0)
  )

  return (
    <Column className="border px-sm pt-lg pb-xl rounded-md w-[31rem] h-96 items-center justify-between">
      <Row className="w-full">
        <Text fontWeight="bold" className="uppercase">
          {title}
        </Text>
      </Row>
      {pending ? (
        <Spinner />
      ) : hasData ? (
        <LineChart labels={labels} datasets={datasets} />
      ) : (
        <>
          <Column className="items-center justify-center">
            <LineChartSvg className="w-36 h-36 text-gray-300" />
            <Text size="xs">No data yet.</Text>
          </Column>
          <Separator />
        </>
      )}
    </Column>
  )
}

export default CampaignSummaryLineChart
