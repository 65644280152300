import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation, useQueryClient } from 'react-query'
import updateUserSignatureService from 'services/settings/create-user-signature-service'
import { Signature } from 'types/settings-type'
import useGetUserSettings from './use-get-user-settings'

export type UseUpdateEmailSignatureProps = {} & MutateApiHookType<Signature>

function useUpdateEmailSignature({
  onSuccess,
  onError,
}: UseUpdateEmailSignatureProps) {
  const queryClient = useQueryClient()
  const { refetch } = useGetUserSettings()

  const { mutate: fetch, isLoading: pending } = useMutation(
    updateUserSignatureService,
    {
      onSuccess: async (data) => {
        await refetch()
        queryClient.setQueryData('/signature', (cache) => data)
        onSuccess?.(data)
      },
      onError,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useUpdateEmailSignature
