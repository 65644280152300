import ReactQuill from 'react-quill'
import slugify from '../../../../utils/slugify'
import Row from '../../../atoms/row'
import Button from '../../button'
import EditorVariableEnum from './editor-variables-enum'

export type VariablesProps = {
  disabled?: boolean
  quillInstance: ReactQuill | null
  onClick: (variableId: string) => void
}

function Variables({ onClick, disabled }: VariablesProps) {
  return (
    <Row className="flex-wrap p-sm gap-xs bg-primary-50 bg-opacity-50 border-l border-r border-b border-gray-300 rounded-b">
      {Object.values(EditorVariableEnum).map((variable) => {
        const asLabel = variable.replace('{{', '').replace('}}', '')
        return (
          <Button
            key={variable}
            testId={slugify(asLabel, { suffix: 'button' })}
            size="xs"
            variant="outline"
            intent="primary"
            disabled={disabled}
            onClick={() => onClick?.(asLabel)}
          >
            {variable}
          </Button>
        )
      })}
      <Button
        testId="insert-signature-button"
        size="xs"
        variant="outline"
        intent="primary"
        disabled={disabled}
        onClick={() => onClick?.('Signature')}
      >
        Insert signature
      </Button>
    </Row>
  )
}

export default Variables

export const signatureTemplateVariable = `{{Signature}}`
