import moment from 'moment'
import { useCallback, useState } from 'react'
import { DateRangePicker } from 'react-dates'
import Column from '../column'
import './styles.css'

const isOutsideRange = (day: moment.Moment) => {
  return day.isAfter(moment())
}

interface DateRangeInputProps<T> {
  dateRange: T
  endDate: moment.Moment | null
  setDateRange: (dateRange: T) => void
  setStartDate: (date: moment.Moment) => void
  setEndDate: (date: moment.Moment) => void
  startDate: moment.Moment | null
}

const DateRangeInput = <T extends Object>({
  dateRange,
  endDate,
  setDateRange,
  setEndDate,
  setStartDate,
  startDate,
}: DateRangeInputProps<T>) => {
  const [focusedInput, setFocusedInput] = useState<
    'startDate' | 'endDate' | null
  >('startDate')

  const handleChange = useCallback(
    (e) => {
      setDateRange(e.id)
      if (e.id === 'CUSTOM') {
        setFocusedInput(startDate && !endDate ? 'endDate' : 'startDate')
      }
    },
    [endDate, setDateRange, startDate]
  )

  const handleDatePickerChange = useCallback(
    ({ endDate, startDate }) => {
      setStartDate(startDate)
      setEndDate(endDate)
      if (startDate == null && endDate == null) {
        handleChange('ALL')
      }
    },
    [handleChange, setEndDate, setStartDate]
  )

  return (
    <Column className={[baseClasses, defaultClasses].join(' ')}>
      <DateRangePicker
        endDate={endDate}
        endDateId="user-date-range-end-date"
        focusedInput={focusedInput}
        horizontalMargin={20}
        isOutsideRange={isOutsideRange}
        onDatesChange={handleDatePickerChange}
        onFocusChange={setFocusedInput}
        showClearDates
        small
        startDate={startDate}
        startDateId="user-date-range-start-date"
      />
    </Column>
  )
}

export default DateRangeInput

const baseClasses = `w-full min-h-xl border py-0 text-sm text-gray-900 placeholder-gray-300 rounded-md shadow-sm transition`
const defaultClasses = `bg-gray-50 border-gray-300 focus-within:border-primary-300 focus-within:ring focus-within:ring-primary-200`
