import api from '@trinsly/common/src/services/api'
import CampaignType from 'types/campaign-type'
import ProjectType from 'types/project-type'

type linkCampaignToProjectServiceProps = {
  projectId: ProjectType['id']
  campaignIds: CampaignType['id'][]
}

async function linkCampaignToProjectService({
  projectId,
  campaignIds,
}: linkCampaignToProjectServiceProps) {
  const response = (await api(`/projects/${projectId}/campaigns`, {
    method: 'POST',
    body: {
      campaign: {
        id: campaignIds,
      },
    },
  })) as CampaignType
  return response
}

export default linkCampaignToProjectService
