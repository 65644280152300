import { ReactComponent as EditSvg } from '@trinsly/common/src/assets/icons/edit.svg'
import Card from '@trinsly/common/src/components/atoms/card'
import Column from '@trinsly/common/src/components/atoms/column'
import Divider from '@trinsly/common/src/components/atoms/divider'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Row from '@trinsly/common/src/components/atoms/row'
import Table from '@trinsly/common/src/components/atoms/table'
import Text from '@trinsly/common/src/components/atoms/text'
import IconButton from '@trinsly/common/src/components/molecules/icon-button'
import Modal from '@trinsly/common/src/components/molecules/modal'
import Pagination from '@trinsly/common/src/components/molecules/pagination'
import usePageableState from '@trinsly/common/src/hooks/use-pageable-state'
import useGetBatchDrafts from 'api-hooks/use-get-batch-drafts'
import { compact } from 'lodash'
import moment from 'moment'
import { useHistory } from 'react-router'
import DeleteDraftButton from './delete-draft-button'

type DraftsModalProps = {
  visible: boolean
  onRequestClose: () => void
}

function DraftsModal({ visible, onRequestClose }: DraftsModalProps) {
  const history = useHistory()
  const { pageable, nextPage, prevPage } = usePageableState({
    page: 1,
    perPage: 7,
  })
  const {
    data: drafts,
    pending: pendingDrafts,
    total,
  } = useGetBatchDrafts(pageable)

  return (
    <Modal size="xl" visible={visible} onRequestClose={onRequestClose}>
      <Card>
        <Column className="p-md">
          <Heading size="xl" fontWeight="bold">
            Drafts
          </Heading>
        </Column>
        <Divider />
        <Table
          pending={pendingDrafts}
          pageSize={pageable?.perPage}
          data={drafts}
          columns={[
            {
              width: 160,
              header: <Column className="pl-xs">Last saved at</Column>,
              cell: (item) => (
                <Column className="pl-xs">
                  {moment(item.updatedAt || item?.createdAt).fromNow()}
                </Column>
              ),
            },
            {
              width: 180,
              header: 'Name',
              cell: (item) => item?.payload?.name || '--',
            },
            {
              header: 'Draft',
              cell: (item) => {
                const templatesSubject = compact(
                  item?.payload?.campaign?.templates?.map?.(
                    (template: any) => template?.subject
                  ) || []
                )
                const attachmentsCount = compact(
                  item?.payload?.campaign?.templates?.map?.(
                    (template: any) => template?.attachments || []
                  ) || []
                ).flat().length
                return (
                  <Column>
                    <Text size="xs">
                      Templates: {templatesSubject?.join(', ') || '--'}
                    </Text>
                    <Text size="xs">Attachments: {attachmentsCount}</Text>
                  </Column>
                )
              },
            },
            {
              width: 100,
              header: 'Actions',
              cell: (item) => {
                return (
                  <Row className="items-center gap-xs">
                    <IconButton
                      size="sm"
                      intent="primary"
                      variant="ghost"
                      onClick={() => {
                        history.push(`/batches/new?batch-draft-id=${item?.id}`)
                      }}
                    >
                      <EditSvg />
                    </IconButton>
                    <DeleteDraftButton draft={item} />
                  </Row>
                )
              },
            },
          ]}
        />
        <Row className="items-center py-sm px-md justify-end">
          <Pagination
            page={pageable?.page}
            total={total || 0}
            perPage={pageable?.perPage}
            onNext={nextPage}
            onPrev={prevPage}
          />
        </Row>
      </Card>
    </Modal>
  )
}

export default DraftsModal
