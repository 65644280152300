import { ReactComponent as DeleteSvg } from '@trinsly/common/src/assets/icons/delete.svg'
import Clickable from '@trinsly/common/src/components/atoms/clickable'
import Column from '@trinsly/common/src/components/atoms/column'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Spinner from '@trinsly/common/src/components/atoms/spinner'
import Table from '@trinsly/common/src/components/atoms/table'
import Text from '@trinsly/common/src/components/atoms/text'
import IconButton from '@trinsly/common/src/components/molecules/icon-button'
import NonIdealState from '@trinsly/common/src/components/organisms/non-ideal-state'
import useDeleteProspectEducation from 'api-hooks/use-delete-prospect-education'
import useGetProspectEducations from 'api-hooks/use-get-prospect-educations'
import { format, parseISO } from 'date-fns'
import React from 'react'
import { toast } from 'react-toastify'
import ProspectType from 'types/prospect-type'
import EducationFormModal from './education-form-modal'

type EducationsSectionProps = {
  prospectId: ProspectType['id'] | undefined
}

function EducationsSection({ prospectId }: EducationsSectionProps) {
  const [showFormModal, setShowFormModal] = React.useState(false)
  const [pendingDeleteMap, setPendingDeleteMap] = React.useState<{
    [index: string]: boolean
  }>({})
  const { data: educations, pending: pendingEducations } =
    useGetProspectEducations({ prospectId })

  const { fetch: deleteEducation } = useDeleteProspectEducation({
    onMutate: ({ educationId }) => {
      setPendingDeleteMap((state) => ({
        ...state,
        [educationId]: true,
      }))
    },
    onSuccess: (_, { educationId }) => {
      setPendingDeleteMap((state) => ({
        ...state,
        [educationId]: false,
      }))
      toast.success('Education deleted!')
    },
    onError: (_, { educationId }) => {
      setPendingDeleteMap((state) => ({
        ...state,
        [educationId]: false,
      }))
      toast.error('Error deleting Education')
    },
  })

  const pending = !prospectId || pendingEducations
  const isNonIdealState = !pending && !educations?.length

  return (
    <Column className="px-md">
      <Row className="items-center justify-between gap-sm">
        <Text size="xs">Education History</Text>
        <Clickable
          onClick={() => {
            setShowFormModal(true)
          }}
        >
          <Row className="items-center gap-xs">
            {pending && <Spinner size="xs" />}
            <Text size="xs" color="info-500" className="underline">
              + New Education ({educations?.length || 0})
            </Text>
          </Row>
        </Clickable>
      </Row>
      <Separator size="xs" />
      <Column className="border rounded overflow-hidden">
        {isNonIdealState ? (
          <Column className="p-md">
            <NonIdealState message="No Education Data Available" />
          </Column>
        ) : (
          <Table
            pending={pending}
            data={educations}
            key={educations?.length || 10}
            pageSize={educations?.length || 10}
            columns={[
              {
                width: 300,
                header: 'University',
                cell: (item) => item?.universityName || '--',
              },
              {
                width: 300,
                header: 'Degree',
                cell: (item) => item?.degreeType || '--',
              },
              {
                width: 300,
                header: 'Major',
                cell: (item) => item?.major || '--',
              },
              {
                width: 120,
                header: 'Start Date',
                cell: (item) =>
                  item?.startDate
                    ? format(parseISO(item!.startDate), 'MM/dd/yyyy')
                    : '--',
              },
              {
                width: 120,
                header: 'End Date',
                cell: (item) =>
                  item?.endDate
                    ? format(parseISO(item!.endDate), 'MM/dd/yyyy')
                    : '--',
              },
              {
                width: 100,
                cell: (item) => {
                  return (
                    <Row className="items-center justify-end">
                      <IconButton
                        size="sm"
                        variant="transparent"
                        intent="danger"
                        pending={pendingDeleteMap[item?.id || 0]}
                        onClick={() => {
                          deleteEducation({ educationId: item?.id || 0 })
                        }}
                      >
                        <DeleteSvg />
                      </IconButton>
                    </Row>
                  )
                },
              },
            ]}
          />
        )}
      </Column>
      <EducationFormModal
        prospectId={prospectId}
        visible={showFormModal}
        onRequestClose={() => setShowFormModal(false)}
      />
    </Column>
  )
}

export default EducationsSection
