import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation } from 'react-query'
import updateMemberStatusService from 'services/settings/deactivate-organization-member-service'
import useGetUserSettings from './use-get-user-settings'

export type UseDeactivateOrganizationMemberProps = {} & MutateApiHookType<User>

function useDeactivateOrganizationMember({
  onSuccess,
  onError,
  onMutate,
}: UseDeactivateOrganizationMemberProps) {
  const { refetch } = useGetUserSettings()

  const { mutate: fetch, isLoading: pending } = useMutation(
    updateMemberStatusService,
    {
      onSuccess: async (data) => {
        await refetch()
        onSuccess?.(data)
      },
      onError,
      onMutate,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useDeactivateOrganizationMember
