import { ReactComponent as RescheduleSvg } from '@trinsly/common/src/assets/icons/reschedule.svg'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Button from '@trinsly/common/src/components/molecules/button'
import React from 'react'
import RescheduleAllPausedReachOutsConfirmationModal from './reschedule-all-paused-reach-outs-confirmation-modal'

export type RescheduleAllPausedReachOutsButtonProps = {}

function RescheduleAllPausedReachOutsButton({}: RescheduleAllPausedReachOutsButtonProps) {
  const [visible, setVisible] = React.useState(false)

  return (
    <React.Fragment>
      <Button
        size="sm"
        variant="outline"
        intent="primary"
        onClick={() => {
          setVisible(true)
        }}
      >
        <Row className="items-center whitespace-nowrap">
          <RescheduleSvg />
          <Separator size="xs" />
          Reschedule All
        </Row>
      </Button>
      <RescheduleAllPausedReachOutsConfirmationModal
        visible={visible}
        onRequestClose={() => setVisible(false)}
      />
    </React.Fragment>
  )
}

export default RescheduleAllPausedReachOutsButton
