export type requiredProps = string | number | undefined

function minLength(value: requiredProps, size = 1) {
  return (
    String(value).length < size &&
    `This field is required to be at least ${size} characters long.`
  )
}

export default minLength
