import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation, useQueryClient } from 'react-query'
import deleteProspectNoteService from 'services/delete-prospect-note-service'

export type UseDeleteProspectNoteProps = {} & MutateApiHookType<void>

function useDeleteProspectNote({
  onMutate,
  onSuccess,
  onError,
}: UseDeleteProspectNoteProps) {
  const queryClient = useQueryClient()

  const { mutate: fetch, isLoading: pending } = useMutation(
    deleteProspectNoteService,
    {
      onSuccess: async (...args) => {
        await queryClient.invalidateQueries('/prospects/{prospectId}/notes')
        onSuccess?.(...args)
      },
      onError,
      onMutate,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useDeleteProspectNote
