import Card from '@trinsly/common/src/components/atoms/card'
import Column from '@trinsly/common/src/components/atoms/column'
import Divider from '@trinsly/common/src/components/atoms/divider'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import Modal from '@trinsly/common/src/components/molecules/modal'
import useRescheduleThread from 'api-hooks/use-reschedule-thread'
import React from 'react'
import { toast } from 'react-toastify'
import ThreadType from 'types/thread-type'
import DeliveredEmailListItem from './delivered-email-list-item'
import UnsentEmailListItem from './unsent-email-list-item'

export type UnsentThreadsRescheduleModalProps = {
  visible: boolean
  onRequestClose: () => void
  thread: ThreadType | undefined
}

function UnsentThreadsRescheduleModal({
  thread,
  visible,
  onRequestClose,
}: UnsentThreadsRescheduleModalProps) {
  const totalCount = thread?.emails?.length || 0
  const deliveredEmails =
    thread?.emails?.filter?.((email) => email.state === 'delivered') || []
  const unsentEmails =
    thread?.emails?.filter?.((email) => email.state !== 'delivered') || []
  const sentCount = totalCount - unsentEmails.length
  const hasDeliveredEmails = deliveredEmails?.length > 0

  const { fetch: rescheduleThread, pending: pendingRescheduleThread } =
    useRescheduleThread({
      onSuccess: (data) => {
        onRequestClose?.()
        toast.success('Campaign Rescheduled!')
      },
      onError: (error) => {
        toast.error('Error rescheduling')
      },
    })

  return (
    <Modal
      size="lg"
      visible={visible}
      onRequestClose={onRequestClose}
      unmount={false}
    >
      <Card>
        <Column className="p-md">
          <Text size="xl" fontWeight="bold" color="primary-500">
            You’re about to reschedule a reach out campaign
          </Text>
          <Separator size="lg" />
          <Row className="flex-wrap gap-md justify-between">
            <Column>
              <Text size="xs" fontWeight="bold">
                Email (Current Stage)
              </Text>
              <Text size="sm">{thread?.email || '--'}</Text>
            </Column>
            <Column>
              <Text size="xs" fontWeight="bold">
                Campaign
              </Text>
              <Text size="sm">{thread?.campaignName || '--'}</Text>
            </Column>
            <Column className="min-w-20">
              <Text size="xs" fontWeight="bold">
                Total Sent
              </Text>
              <Text size="sm">
                {totalCount > 0 ? `${sentCount}/${totalCount}` : '--'}
              </Text>
            </Column>
          </Row>
          <Separator size="lg" />
          {deliveredEmails?.map((email) => (
            <DeliveredEmailListItem key={email?.id} email={email} />
          ))}
          {hasDeliveredEmails && (
            <React.Fragment>
              <Separator />
              <Divider />
              <Separator />
            </React.Fragment>
          )}
          <Column className="space-y-xs">
            {unsentEmails?.map((email) => (
              <UnsentEmailListItem key={email?.id} email={email} />
            ))}
          </Column>
        </Column>
        <Separator size="xl" />
        <Modal.Footer>
          <Button onClick={onRequestClose}>Cancel</Button>
          <Separator size="sm" />
          <Column className="min-w-52">
            <Button
              intent="primary"
              pending={pendingRescheduleThread}
              onClick={() => {
                rescheduleThread({ threadId: thread?.id || 0 })
              }}
            >
              Reschedule
            </Button>
          </Column>
        </Modal.Footer>
      </Card>
    </Modal>
  )
}

export default UnsentThreadsRescheduleModal
