import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation, useQueryClient } from 'react-query'
import updateUserPasswordService from 'services/settings/update-user-password-service'
import Settings from 'types/settings-type'

export type UseUpdateUserPasswordProps = {} & MutateApiHookType<{
  data: Settings
  status: string
}>

function useUpdateUserPassword({
  onSuccess,
  onError,
}: UseUpdateUserPasswordProps) {
  const queryClient = useQueryClient()

  const { mutate: fetch, isLoading: pending } = useMutation(
    updateUserPasswordService,
    {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries('/settings')
        onSuccess?.(data)
      },
      onError,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useUpdateUserPassword
