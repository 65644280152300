import { TabProps, TabsProps } from '.'
import Provider, { useContext } from './provider'

function ButtonGroup({
  children,
  className = '',
  variant = 'query-string',
  ...props
}: TabsProps) {
  return (
    <div className={`${containerClasses} ${className}`}>
      <Provider {...props} variant={variant}>
        {children}
      </Provider>
    </div>
  )
}

export default ButtonGroup

function Button({ id, children, disabled = false }: TabProps) {
  const { id: containerId, onChange, getActiveTab } = useContext()
  const activeTab = getActiveTab()
  const active = activeTab === id
  return (
    <button
      tabIndex={active ? -1 : undefined}
      id={`${containerId}-${id}-button-group`.toLowerCase()}
      className={`${buttonClasses} ${
        disabled
          ? buttonDisabledClasses
          : active
          ? buttonActiveClasses
          : buttonDefaultClasses
      }`}
      onClick={() => {
        onChange?.(id)
      }}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

ButtonGroup.Button = Button
Button.displayName = 'ButtonGroup.Button'

const containerClasses = `inline-flex self-startrounded-md border-2 bg-white border-gray-100 rounded-tl-md rounded-bl-md rounded-tr-md rounded-br-md`
const buttonClasses = `min-h-10 min-w-16 px-sm text-sm uppercase font-bold transition focus:outline-none ring-inset focus:ring focus:ring-primary-200`
const buttonDefaultClasses = `text-gray-400 hover:bg-gray-100 focus:bg-gray-100 active:bg-gray-200`
const buttonActiveClasses = `text-primary-500 bg-primary-50 cursor-default`
const buttonDisabledClasses = `text-gray-300 cursor-not-allowed`
