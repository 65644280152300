import api from '@trinsly/common/src/services/api'
import { format, parseISO } from 'date-fns'

export type GetOverallStatsServiceProps = {
  startDate: string
  endDate: string
}
export type Stats = {
  completedReachOutsCount: number
  hardBouncedCount: number
  inProgressCount: number
  interestedCount: number
  openCount: number
  reachOutCount: number
  responsesCount: number
  sentCount: number
}

async function getOverallStatsService({
  startDate,
  endDate,
}: GetOverallStatsServiceProps): Promise<Stats> {
  try {
    const data = (await api(`/stats`, {
      method: 'GET',
      params: {
        start_date: startDate
          ? format(parseISO(startDate), 'yyyy-MM-dd')
          : undefined,
        end_date: endDate ? format(parseISO(endDate), 'yyyy-MM-dd') : undefined,
      },
    })) as any[]

    const stats = data?.reduce?.(
      (cache: Stats, stats: any) => {
        cache.hardBouncedCount += stats.hardBouncedCount || 0
        cache.inProgressCount += stats.inProgressCount || 0
        cache.interestedCount += stats.interestedCount || 0
        cache.openCount +=
          stats.responsesCount > stats.openCount
            ? stats.responsesCount
            : stats.openCount || 0
        cache.reachOutCount += stats.reachOutsCount || 0
        cache.responsesCount += stats.responsesCount || 0
        return cache
      },
      {
        completedReachOutsCount: 0,
        hardBouncedCount: 0,
        inProgressCount: 0,
        interestedCount: 0,
        openCount: 0,
        reachOutCount: 0,
        responsesCount: 0,
        sentCount: 0,
      }
    )

    return stats
  } catch (error) {
    throw error
  }
}

export default getOverallStatsService
