//verify
//otp_attempt: 123456
import api from '@trinsly/common/src/services/api'

export type VerifyUser2faServiceProps = {
  otp: string
}

export default async function verifyUser2faService({
  otp,
}: VerifyUser2faServiceProps) {
  const response = await api('/two_factor_auths/verify', {
    method: 'POST',
    body: {
      otp_attempt: otp,
    },
  })

  return response as any
}
