import api from './api'

export type SendEmailServiceProps = {
  subject: string
  body: string
  to: string
}

async function sendEmailService({ subject, body, to }: SendEmailServiceProps) {
  try {
    const response = await api(`/messages`, {
      method: 'POST',
      body: { message: { subject, body, to } },
    })
    return response as any
  } catch (error: any) {
    throw error
  }
}

export default sendEmailService
