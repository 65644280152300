import Column from '@trinsly/common/src/components/atoms/column'
import Form from '@trinsly/common/src/components/atoms/form'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Switch from '@trinsly/common/src/components/atoms/switch'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import TextInput from '@trinsly/common/src/components/molecules/text-input'
import useForm from '@trinsly/common/src/hooks/use-form'
import useUpdateUserPassword from 'api-hooks/use-update-user-password'
import PasswordStrength from 'components/password-strength'
import React from 'react'
import { toast } from 'react-toastify'

export type ChangePasswordSectionProps = {}

const ChangePasswordSection = ({}: ChangePasswordSectionProps) => {
  const [showPasswordForm, setShowPasswordForm] = React.useState(false)

  const { fetch: updatePassword, pending: pendingUpdate } =
    useUpdateUserPassword({
      onSuccess: () => {
        toast('Password updated successfully', { type: 'success' })
        setShowPasswordForm(false)
        reset()
      },
      onError: (error) => {
        toast('Wrong current password', { type: 'error' })
      },
    })
  const { submit, getValue, setValue, getError, reset } = useForm({
    onSubmit: ({ password, passwordConfirmation, currentPassword }) => {
      updatePassword({ password, passwordConfirmation, currentPassword })
    },
  })
  const passwordConfirmationError =
    getValue('passwordConfirmation') !== getValue('password')
      ? 'Password does not match'
      : undefined
  return (
    <Form onSubmit={submit}>
      <Column className="flex-1 justify-center items-start">
        <Column className="max-w-lg">
          <Heading
            size="sm"
            fontWeight="bold"
            color="gray-500"
            className="uppercase"
          >
            Update Password
          </Heading>
          <Text color="gray-500" size="sm">
            Passwords must be at least 8 characters long and contain 1 special
            character.
          </Text>
        </Column>
        <Separator />
        <Row className="items-center">
          <Switch
            checked={showPasswordForm}
            onChange={() => {
              setShowPasswordForm(!showPasswordForm)
            }}
          />
        </Row>
        <Separator />
        {showPasswordForm && (
          <Column className="py-4 rounded-lg" style={{ width: '48.5rem' }}>
            <Column className="gap-xs">
              <TextInput
                label="New Password"
                disabled={pendingUpdate}
                onChange={(value) => setValue('password', value)}
                required
                type="password"
                value={getValue('password')}
              />
              {getValue('password').length > 0 && (
                <PasswordStrength password={getValue('password')} />
              )}
            </Column>
            <Separator />
            <TextInput
              label="Confirm New Password"
              disabled={pendingUpdate}
              onChange={(value) => setValue('passwordConfirmation', value)}
              required
              type="password"
              value={getValue('passwordConfirmation')}
              error={
                getValue('passwordConfirmation') && passwordConfirmationError
              }
            />
            <Separator />
            <TextInput
              label="Current Password"
              disabled={pendingUpdate}
              onChange={(value) => setValue('currentPassword', value)}
              required
              type="password"
              value={getValue('currentPassword')}
              error={getError('currentPassword')}
            />
            <Separator />
            <Row>
              <Button type="submit" intent="success" pending={pendingUpdate}>
                SAVE NEW PASSWORD
              </Button>
            </Row>
          </Column>
        )}
      </Column>
    </Form>
  )
}

export default ChangePasswordSection
