import api from '@trinsly/common/src/services/api'
import CrmEnum from 'types/crm-enum'

type RequestIntegrationServiceProps = {
  crm: CrmEnum
}

async function requestIntegrationService({
  crm,
}: RequestIntegrationServiceProps) {
  const response = (await api(`/integrations/request`, {
    body: { crm_type: crm },
    method: 'POST',
  })) as any
  return response
}

export default requestIntegrationService
