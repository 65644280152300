import Clickable from '@trinsly/common/src/components/atoms/clickable'
import Spinner from '@trinsly/common/src/components/atoms/spinner'
import Text from '@trinsly/common/src/components/atoms/text'
import usePageableState from '@trinsly/common/src/hooks/use-pageable-state'
import useGetCampaignDrafts from 'api-hooks/use-get-campaign-drafts'
import React from 'react'
import DraftsModal from '.'

type SeeDraftsButtonProps = {}

function SeeDraftsButton({}: SeeDraftsButtonProps) {
  const [showDraftsModal, setShowDraftsModal] = React.useState(false)
  const { pageable } = usePageableState({
    page: 1,
    perPage: 7,
  })
  const {
    data: drafts,
    pending: pendingDrafts,
    total,
  } = useGetCampaignDrafts(pageable)

  const hasDrafts = pendingDrafts || drafts?.length > 0

  return (
    <React.Fragment>
      {pendingDrafts ? (
        <Spinner size="xs" />
      ) : (
        hasDrafts && (
          <Clickable
            onClick={() => {
              setShowDraftsModal(true)
            }}
          >
            <Text size="sm" color="info-500" className="underline">
              See Drafts ({total || 0})
            </Text>
          </Clickable>
        )
      )}
      <DraftsModal
        visible={showDraftsModal}
        onRequestClose={() => {
          setShowDraftsModal(false)
        }}
      />
    </React.Fragment>
  )
}

export default SeeDraftsButton
