import api from '@trinsly/common/src/services/api'
import { pickBy } from 'lodash'
import ActivityLogType from 'types/activity-log-type'
import UserType from 'types/user-type'
export type GetActivityLogsServiceProps = { userId?: UserType['id'] }

async function getActivityLogsService({ userId }: GetActivityLogsServiceProps) {
  const response = await api(`/activity_logs`, {
    method: 'GET',
    params: pickBy({ filter: pickBy({ user_id: userId }) }),
  })
  const { activityLogs } = response as any
  return activityLogs as ActivityLogType[]
}

export default getActivityLogsService
