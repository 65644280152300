import { useQuery } from 'react-query'
import getThreadEmailService from 'services/get-thread-email-service'
import { ThreadEmailType } from 'types/thread-type'

type UseGetThreadEmailProps = {
  threadEmailId: ThreadEmailType['id'] | undefined
}
function useGetThreadEmail({ threadEmailId }: UseGetThreadEmailProps) {
  const shouldFetch = Boolean(threadEmailId)
  const { data, status } = useQuery(
    ['/email_messages', threadEmailId],
    () => getThreadEmailService({ threadEmailId: threadEmailId || -1 }),
    {
      enabled: shouldFetch,
      staleTime: shouldFetch ? undefined : 0,
    }
  )

  return {
    data,
    pending: status === 'loading',
  }
}

export default useGetThreadEmail
