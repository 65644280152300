import Column from '@trinsly/common/src/components/atoms/column'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Text from '@trinsly/common/src/components/atoms/text'
import Breadcrumbs from '@trinsly/common/src/components/molecules/breadcrumbs'
import Button from '@trinsly/common/src/components/molecules/button'
import useGetIntegrations from 'api-hooks/use-get-integrations'
import useSaveProspectToMergeIntegration from 'api-hooks/use-save-prospect-to-merge-integration'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import HotlistButton from 'screens/prospects-screen/hotlists-section/hotlist-button'
import CrmEnum, { translateCrmEnum } from 'types/crm-enum'
import { useFormContext } from '../form-provider'
import TagsSection from './tags-section'

export type HeaderProps = {}

function Header({}: HeaderProps) {
  const history = useHistory()
  const { prospect } = useFormContext()

  return (
    <Row className="px-lg py-sm items-end bg-white shadow justify-between">
      <Row className="items-center gap-sm">
        <Breadcrumbs
          steps={['Prospects', prospect?.name || '--']}
          onBack={() => {
            history.push('/prospects')
          }}
        />
        <Column className="mb-1">
          <HotlistButton prospectIds={[prospect?.id as any]} />
        </Column>
        <TagsSection />
      </Row>
      <Separator />
      <Row className="items-center">
        {prospect?.mergeAts && prospect.mergeRemoteId && (
          <>
            <Text size="xs">
              Linked to {translateCrmEnum[prospect.mergeAts]} - ID #
              {prospect.mergeRemoteId}
            </Text>
            <Separator />
          </>
        )}
        <SaveProspectToIntegration prospectId={prospect?.id} />
      </Row>
    </Row>
  )
}

export default Header

/**
 * Currently only supports Merge integrations
 * @returns
 */
function SaveProspectToIntegration({ prospectId }: { prospectId?: number }) {
  const { data: integrations, pending: pendingIntegrations } =
    useGetIntegrations()
  const { fetch: startSync, pending: syncStartPending } =
    useSaveProspectToMergeIntegration({
      onSuccess: () => {},
      onError: () => {
        toast.error(
          'Unable to save prospect to your ATS - Please try again later.'
        )
      },
    })

  if (!prospectId) return null
  return (
    <>
      {integrations.map((i) => {
        const integrationEnum = (i?.crmType?.replace?.('merge_', '') ||
          '') as CrmEnum
        const integrationName = translateCrmEnum[integrationEnum] || 'ATS'

        return (
          <>
            {' '}
            <Button
              size="sm"
              variant="ghost"
              intent="info"
              shape="rounded"
              disabled={pendingIntegrations}
              pending={syncStartPending}
              onClick={() => {
                startSync({ prospectId, integrationId: i.id })
              }}
            >
              Save to {integrationName}
            </Button>
            {/* Need to expose last_sync from api (credentials) */}
          </>
        )
      })}
    </>
  )
}
