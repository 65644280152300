import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation, useQueryClient } from 'react-query'
import deleteHotlistService from 'services/delete-hotlist-service'
import HotlistType from 'types/hotlist-type'

type UseDeleteHotlistProps = {} & MutateApiHookType<HotlistType>

function useDeleteHotlist({ onSuccess, onError }: UseDeleteHotlistProps) {
  const queryClient = useQueryClient()
  const { mutate: fetch, isLoading: pending } = useMutation(
    deleteHotlistService,
    {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries('/hotlists')
        onSuccess?.(data)
      },
      onError,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useDeleteHotlist
