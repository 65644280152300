import api from '@trinsly/common/src/services/api'
import PageableType from '@trinsly/common/src/types/pageable-type'
import ProjectType from 'types/project-type'
import ProspectType from 'types/prospect-type'

export type GetProjectProspectsServiceProps = {
  projectId: ProjectType['id']
} & PageableType

async function getProjectProspectsService({
  projectId,
  ...pageable
}: GetProjectProspectsServiceProps) {
  const orderBy = Object.keys(pageable?.sort || {})?.[0]
  const orderDirection = Object.values(pageable?.sort || {})?.[0]
  const hasSort = orderBy && orderDirection
  const response = await api(`/prospects`, {
    method: 'GET',
    params: {
      project_id: projectId,
      query: pageable?.search,
      page: pageable?.page,
      per_page: pageable?.perPage,
      sort: hasSort ? `${orderBy} ${orderDirection}` : '',
    },
  })

  const { meta, prospects: remoteProspects } = response as any

  return {
    meta,
    prospects: remoteProspects || [],
  } as {
    prospects: ProspectType[]
    meta: { pagination: Pagination }
  }
}

export default getProjectProspectsService
