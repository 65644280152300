import { ReactComponent as CheckSvg } from '@trinsly/common/src/assets/icons/check.svg'
import { ReactComponent as EditSvg } from '@trinsly/common/src/assets/icons/edit.svg'
import Card from '@trinsly/common/src/components/atoms/card'
import Column from '@trinsly/common/src/components/atoms/column'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import IconButton from '@trinsly/common/src/components/molecules/icon-button'
import slugify from '@trinsly/common/src/utils/slugify'
import useDeleteIntegration from 'api-hooks/use-delete-integration'
import useRequestIntegration from 'api-hooks/use-request-integration'
import NotificationListItem from 'providers/notifications-provider/notification-list-item'
import React from 'react'
import { useHistory } from 'react-router'
import CrmEnum from 'types/crm-enum'

export type IntegrationCardProps = {
  id?: string
  loggedUser: User
  integrationType: CrmEnum
  logoSrc: string
  description: string
  onStartIntegration?: () => void
  integration?: Integration
  paywalled?: boolean
  disabled?: boolean
}

function IntegrationCard({
  integrationType,
  id = slugify(integrationType),
  loggedUser,
  integration,
  paywalled,
  logoSrc,
  description,
  disabled,
  onStartIntegration,
}: IntegrationCardProps) {
  const history = useHistory()
  const { fetch: requestIntegration, pending: requesting } =
    useRequestIntegration({
      onSuccess: () => {
        setIntegrationRequest(true)
      },
      onError: () => {
        alert('Unable to request integration - please contact support.')
      },
    })
  const [integrationRequested, setIntegrationRequest] = React.useState(false)
  const { fetch: deleteIntegration, pending: deleting } = useDeleteIntegration(
    {}
  )
  const connected = Boolean(integration)

  const shouldRequestIntegration = [
    CrmEnum.BULLHORN,
    CrmEnum.CATS,
    CrmEnum.LEVER,
    CrmEnum.PC_RECRUITER,
    CrmEnum.CHATKICK,
  ].includes(integrationType)

  const canSyncUsers = [
    CrmEnum.BULLHORN,
    CrmEnum.CATS,
    CrmEnum.LEVER,
    CrmEnum.PC_RECRUITER,
    CrmEnum.WORKABLE,
    CrmEnum.GREENHOUSE, //TODO: check if greenhouse has this feat
    CrmEnum.CLOCKWORK,
  ].includes(integrationType)

  const showBullhornRelogin =
    (loggedUser?.id === integration?.userId ||
      loggedUser?.organizationRole === 'admin') &&
    integrationType === CrmEnum.BULLHORN

  return (
    <Card
      className="p-md relative"
      style={{ border: `1px solid ${connected ? '#00C853' : 'transparent'}` }}
    >
      {integrationRequested && (
        <NotificationListItem
          notification={{
            key: 'integration-requested-success',
            message: 'Integration requested.',
            persist: -1,
            type: 'success',
          }}
        />
      )}

      {connected && (
        <Column className="absolute top-sm right-0">
          <Row className="items-center">
            <IconButton
              id={`${id}-edit-button`}
              size="sm"
              variant="ghost"
              shape="circle"
              intent="primary"
              onClick={() => onStartIntegration?.()}
            >
              <EditSvg />
            </IconButton>
            <Separator size="sm" />
            <Row
              className="items-center min-h-lg px-sm"
              style={{
                color: '#00C853',
                backgroundColor: '#00C8531A',
                borderTopLeftRadius: 50,
                borderBottomLeftRadius: 50,
              }}
            >
              <CheckSvg className="w-sm h-sm" />
              <Separator size="xs" />
              <Text
                size="xs"
                fontWeight="bold"
                color="current"
                className="uppercase"
              >
                Connected
              </Text>
            </Row>
          </Row>
          {canSyncUsers && (
            <Column className="items-end justify-center pr-xs">
              <Separator size="xs" />
              <Button
                id={`${id}-sync-users-button`}
                size="xs"
                variant="outline"
                intent="primary"
                className="text-indigo-600 hover:text-indigo-900"
                onClick={() => {
                  history.push(`/integrations/${integration?.id}/sync-users`)
                }}
              >
                Sync users
              </Button>
            </Column>
          )}
        </Column>
      )}
      <Column className="justify-center" style={{ width: 130, height: 53 }}>
        <img
          src={logoSrc}
          width="100%"
          height="100%"
          style={{ objectFit: 'contain' }}
        />
      </Column>
      <Separator />
      <Text>{description}</Text>
      <Separator />
      <Row className="flex-1 min-w-40 items-end">
        {connected ? (
          <Row className="flex-1 items-center justify-between">
            <Button
              id={`${id}-disconnect-button`}
              variant="ghost"
              shape="rounded"
              onClick={() => deleteIntegration(integration!.id)}
              pending={deleting}
            >
              Disconnect
            </Button>
            {showBullhornRelogin && (
              <React.Fragment>
                <Separator />
                <Button
                  id={`${id}-re-login-button`}
                  variant="outline"
                  intent="primary"
                  shape="rounded"
                  // disabled={isLoggingInBullhorn}
                  // onClick={handleBullhornLoginClick}
                >
                  Re-Login
                </Button>
              </React.Fragment>
            )}
          </Row>
        ) : shouldRequestIntegration ? (
          <Button
            id={`${id}-request-integration-button`}
            intent="primary"
            variant="outline"
            shape="rounded"
            pending={requesting}
            disabled={paywalled || integrationRequested || disabled}
            onClick={() => requestIntegration(integrationType)}
          >
            {integrationRequested
              ? 'Integration Requested'
              : 'Request Integration'}
          </Button>
        ) : (
          <Button
            id={`${id}-start-integration-button`}
            intent="primary"
            variant="outline"
            shape="rounded"
            disabled={paywalled || disabled}
            onClick={() => onStartIntegration?.()}
          >
            Start Integration
          </Button>
        )}
      </Row>
    </Card>
  )
}

export default IntegrationCard
