import api from '@trinsly/common/src/services/api'
import { Organization } from 'types/settings-type'

export type UpdateOrganizationDetailsServiceProps = {
  orgId: string | number
  name: string
  phone?: string
  size?: string
  website?: string
}

export default async function updateOrganizationDetailsService({
  orgId,
  name,
  phone,
  size,
  website,
}: UpdateOrganizationDetailsServiceProps): Promise<Organization> {
  const response = (await api(`/organizations/${orgId}`, {
    method: 'PUT',
    body: {
      organization: {
        id: orgId,
        name: name,
        phone: phone,
        size: size,
        website: website,
      },
    },
  })) as any as Organization

  return response
}
