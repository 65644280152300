import PageableType from '@trinsly/common/src/types/pageable-type'
import { useQuery } from 'react-query'
import getProjectsService from 'services/get-projects-service'

type UseGetProjectsProps = {} & PageableType

function useGetProjects({ ...pageable }: UseGetProjectsProps) {
  const { data, error, status } = useQuery(['/projects', pageable], () =>
    getProjectsService(pageable)
  )

  return {
    data: data?.projects || [],
    total: data?.meta?.pagination?.totalObjects,
    pending: status === 'loading',
    error,
  }
}

export default useGetProjects
