import { FunnelDatum, FunnelPart, ResponsiveFunnel } from '@nivo/funnel'
import Column from '@trinsly/common/src/components/atoms/column'
import Row from '@trinsly/common/src/components/atoms/row'
import Spinner from '@trinsly/common/src/components/atoms/spinner'
import { round } from 'lodash'
import { useEffect, useState } from 'react'
import { Stats } from 'services/get-overall-stats-service'

/**
 * Receives chart context object
 * @param parts
 * @returns
 */
function Labels(context: { parts: FunnelPart<FunnelDatum>[] }) {
  const parts = context.parts
  const totalReachOuts = parts.find((p) => p.data.id === 'total_reach_outs')
    ?.data.value
  const getStat = (value: number) => {
    const hasValue = value && value > 0
    const hasTotal = totalReachOuts && totalReachOuts > 0
    const percent = hasTotal && hasValue ? (value / totalReachOuts) * 100 : 0
    const hasPercent = percent > 0

    return {
      percent: hasPercent ? percent : 0,
    }
  }
  return parts.map((part) => (
    <svg>
      {' '}
      <g transform={`translate(${part.x},${part.y + 60})`}>
        <rect x={-14} y={-6} width={40} height={24} fill="rgba(0, 0, 0, .01)" />
        <rect x={-30} y={-12} width={64} height={36} fill="rgb(0, 0, 0, 0)" />
        <text
          textAnchor="middle"
          dominantBaseline="middle"
          style={{
            fontFamily: 'sans-serif',
            fill: '#333',
            fontSize: 16,
            fontWeight: 'bold',
            display: 'block',
          }}
        >
          {part.data.value} {part.data.label}
        </text>{' '}
      </g>
      {part.data.id !== 'total_reach_outs' && (
        <g transform={`translate(${part.x},${part.y + 45})`}>
          <text
            textAnchor="middle"
            style={{
              fontFamily: 'sans-serif',
              fill: '#333',
              fontSize: 12,
              display: 'block',
            }}
          >
            {round(getStat(part.data.value).percent, 2)}%
          </text>
        </g>
      )}
    </svg>
  ))
}

function StatsFunnelChart({ stats }: { stats: Stats }) {
  const [funnelData, setFunnelData] = useState<FunnelDatum[]>([
    {
      id: 'total_reach_outs',
      value: stats?.reachOutCount || 0,
      label: 'Reach Outs',
    },
    {
      id: 'total_opened',
      value: stats?.openCount || 0,
      label: 'Opened',
    },
    {
      id: 'total_replied',
      value: stats?.responsesCount || 0,
      label: 'Replied',
    },
    {
      id: 'total_interested',
      value: stats?.interestedCount || 0,
      label: 'Interested',
    },
  ])

  useEffect(() => {
    if (!!stats) {
      setFunnelData([
        {
          id: 'total_reach_outs',
          value: stats?.reachOutCount || 0,
          label: 'Reach Outs',
        },
        {
          id: 'total_opened',
          value: stats?.openCount || 0,
          label: 'Opened',
        },
        {
          id: 'total_replied',
          value: stats?.responsesCount || 0,
          label: 'Replied',
        },
        {
          id: 'total_interested',
          value: stats?.interestedCount || 0,
          label: 'Interested',
        },
      ])
    }
  }, [stats])

  return (
    <Column className="px-xs py-xs">
      {funnelData.length < 1 ? (
        <Spinner />
      ) : (
        <Row className="h-36 p-2">
          <ResponsiveFunnel
            //@ts-ignore
            layers={[Labels, 'separators', 'parts', 'annotations']}
            direction="horizontal"
            interpolation="linear"
            afterSeparatorLength={2}
            afterSeparatorOffset={2}
            margin={{ top: 25, right: 20, bottom: 30, left: 20 }}
            colors={{ scheme: 'purple_orange' }}
            spacing={10}
            data={funnelData}
            shapeBlending={0.3}
            fillOpacity={1}
            theme={{
              background: '#ffffff',
              fontSize: 18,
              fontFamily: 'sans-serif',
            }}
            valueFormat=" >-,"
            labelColor={{
              theme: 'background',
            }}
            borderWidth={1}
          />{' '}
        </Row>
      )}
    </Column>
  )
}

export default StatsFunnelChart
