import QueryApiHookType from '@trinsly/common/src/types/query-api-hook-type'
import moment from 'moment'
import { useQuery } from 'react-query'
import getFindCreditUsageService, {
  GetFindCreditUsageServiceProps,
} from 'services/billing/get-find-credit-usage-service'
import CreditUsageResponseType from 'types/billing-type'

export type UseGetFindCreditUsageProps = {} & GetFindCreditUsageServiceProps &
  QueryApiHookType<CreditUsageResponseType>

function useGetFindCreditUsage({
  billingCycle,
  onError,
  onSuccess,
}: UseGetFindCreditUsageProps) {
  //usage?billingCycle=2022-09
  const cycle = billingCycle ?? moment().format('YYYY-MM')
  const { data, error, status, isFetching } = useQuery(
    ['/billing/credit-usage', cycle],
    () => getFindCreditUsageService({ billingCycle: cycle }),
    {
      onError,
      onSuccess,
    }
  )

  return {
    data,
    pending: status === 'loading',
    error,
    isFetching,
  }
}

export default useGetFindCreditUsage
