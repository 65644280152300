import PageableType from '@trinsly/common/src/types/pageable-type'
import { useQuery } from 'react-query'
import getBatchesService from 'services/get-batches-service'

type UseGetBatchesProps = {} & PageableType

function useGetBatches(pageable: UseGetBatchesProps) {
  const { data, error, status } = useQuery(['/batches', pageable], () =>
    getBatchesService(pageable)
  )

  return {
    data: data?.batches || [],
    total: data?.meta?.pagination?.totalObjects || 0,
    pending: status === 'loading',
    error,
  }
}

export default useGetBatches
