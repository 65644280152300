import api from '@trinsly/common/src/services/api'
import DraftType from 'types/draft-type'

export type DeleteCampaignDraftServiceProps = {
  draftId: DraftType['id']
}

async function deleteCampaignDraftService({
  draftId,
}: DeleteCampaignDraftServiceProps) {
  const response = (await api(`/drafts/${draftId}`, {
    method: 'DELETE',
  })) as any

  return response as DraftType
}

export default deleteCampaignDraftService
