import useSendEmail from '@trinsly/common/src/api-hooks/use-send-email'
import { ReactComponent as CloseSvg } from '@trinsly/common/src/assets/icons/close.svg'

import { ReactComponent as MagicWand } from '@trinsly/common/src/assets/icons/auto_fix_high.svg'
import Column from '@trinsly/common/src/components/atoms/column'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Text from '@trinsly/common/src/components/atoms/text'
import Tooltip from '@trinsly/common/src/components/atoms/tooltip'
import Button from '@trinsly/common/src/components/molecules/button'
import IconButton from '@trinsly/common/src/components/molecules/icon-button'
import EmailTemplateInput from '@trinsly/common/src/components/molecules/quill-editor/email-template-input'
import { signatureTemplateVariable } from '@trinsly/common/src/components/molecules/quill-editor/variables'
import useGetUserSession from 'api-hooks/use-get-user-session'
import AttachmentsInput from 'components/attachments-input'
import React from 'react'
import { toast } from 'react-toastify'
import EmailTemplateType from 'types/email-template-type'
import { useDraftContext } from '../draft-provider'
import { useFormContext } from '../form-provider'
import EmailDurationInput from './email-duration-input'
import StagesLockedModalWrapper from './stages-locked-modal-wrapper'
import EmailMagicModal from './email-magic-modal'

export type EmailTemplateListItemProps = {
  index: number
  template: EmailTemplateType
}

function EmailTemplateListItem({
  index,
  template,
}: EmailTemplateListItemProps) {
  const [showEmailMagicModal, setShowEmailMagicModal] = React.useState(false)

  const {
    getValue,
    setValue,
    getError,
    removeFollowUp,
    pending,
    creating,
    updating,
    isEdit,
    campaign,
    toggleFollowUpInThread,
    signature,
  } = useFormContext()
  const { pending: pendingDraft } = useDraftContext()

  const templatePath = `campaign.templates[${index}]`
  const isFollowUp = index > 0
  const shouldDisableFormChange =
    pending || creating || updating || pendingDraft
  const isReadonly = !pending && Boolean(campaign?.isArchived)
  const hasAttachments =
    (getValue(`${templatePath}.attachments`) || []).length > 0
  const noAttachmentsAndDisabled = !hasAttachments && isReadonly

  const { fetch: sendEmail, pending: pendingSendEmail } = useSendEmail({
    onSuccess: () => {
      toast.success(`Email sent to ${userSession?.email || ''}!`)
    },
    onError: (error) => {
      toast.error('Error sending test email')
    },
  })

  const { data: userSession, pending: pendingUserSession } = useGetUserSession(
    {}
  )

  return (
    <Column className="px-lg py-sm border-l-2 border-primary-500">
      {!isReadonly && isFollowUp && (
        <>
          <Row className="justify-end">
            <React.Fragment>
              <Separator />
              <StagesLockedModalWrapper
                campaignId={campaign?.id as any}
                disabled={!isEdit}
              >
                <Column>
                  <IconButton
                    id={`remove-follow-up-${index + 1}-button`}
                    size="xs"
                    intent="danger"
                    shape="circle"
                    variant="ghost"
                    onClick={() => {
                      if (!isEdit) {
                        removeFollowUp(index)
                      }
                    }}
                  >
                    <CloseSvg />
                  </IconButton>
                </Column>
              </StagesLockedModalWrapper>
            </React.Fragment>
          </Row>
          <Separator size="md" />
        </>
      )}

      <Row className="items-center justify-between">
        <Text fontWeight="bold" color="primary-500">
          {`Stage #${index + 1} Template`}
        </Text>
        <Separator />
        <Row className="items-center gap-xs">
          <Tooltip content="*Variables will appear with {{ }}">
            <Button
              id={`send-test-email-${index + 1}-button`}
              size="sm"
              shape="rounded"
              variant="ghost"
              pending={pendingSendEmail}
              onClick={() => {
                if (pendingUserSession || !userSession) {
                  alert('Unable to send a test email')
                }
                sendEmail({
                  to: userSession?.email ?? '',
                  subject: getValue(`${templatePath}.subject`),
                  body: getValue(`${templatePath}.body`),
                })
              }}
            >
              Send Test Email
            </Button>
          </Tooltip>
          <Button
            size="sm"
            intent="info"
            variant="ghost"
            shape="rounded"
            onClick={() => {
              setShowEmailMagicModal(true)
            }}
          >
            <MagicWand />
          </Button>
        </Row>
      </Row>
      {isFollowUp && (
        <React.Fragment>
          <Separator />
          <EmailDurationInput
            id={`email-duration-${index + 1}-number-input`}
            disabled={shouldDisableFormChange || isReadonly}
            value={getValue(`${templatePath}.duration`)}
            onChange={(value) => {
              setValue(`${templatePath}.duration`, value)
            }}
            error={getError(`${templatePath}.duration`)}
          />
        </React.Fragment>
      )}
      <Separator />
      <EmailTemplateInput
        subject={{
          id: `subject-${index + 1}-text-input`,
          label: 'Subject',
          value: getValue(`${templatePath}.subject`),
          onChange: (value) => {
            setValue(`${templatePath}.subject`, value)
          },
          error: getError(`${templatePath}.subject`),
          disabled:
            getValue(`${templatePath}.isInThread`) ||
            shouldDisableFormChange ||
            isReadonly,
          required: true,
        }}
        isInThread={
          isFollowUp
            ? {
                id: `is-in-thread-${index + 1}-switch`,
                disabled: shouldDisableFormChange || isReadonly,
                checked: getValue(`${templatePath}.isInThread`),
                onChange: (value) => {
                  toggleFollowUpInThread(index, value)
                },
              }
            : undefined
        }
        body={{
          id: `body-${index + 1}-editor`,
          label: 'Body',
          disabled: shouldDisableFormChange || isReadonly,
          value: getValue(`${templatePath}.body`),
          onChange: ({ html, counter }) => {
            const htmlWithSignature =
              html?.replaceAll(
                signatureTemplateVariable,
                `<br>${
                  signature?.signature ||
                  '-- Define an email Signature at Settings Page --'
                }`
              ) || ''
            setValue(`${templatePath}.body`, htmlWithSignature)
          },
          error: getError(`${templatePath}.body`),
          required: true,
        }}
      />
      <Separator />
      <Column>
        <Text
          size="sm"
          fontWeight="bold"
          className="uppercase"
          color="gray-900"
        >
          Attachments
        </Text>
        {noAttachmentsAndDisabled ? (
          <React.Fragment>
            <Separator size="xxs" />
            <Text size="sm" color="gray-400">
              No attachments.
            </Text>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Separator size="sm" />
            <AttachmentsInput
              disabled={shouldDisableFormChange || isReadonly}
              value={getValue(`${templatePath}.attachments`) || []}
              onChange={(name, values) => {
                setValue(`${templatePath}.attachments`, values || [])
              }}
            />
          </React.Fragment>
        )}
      </Column>
      <EmailMagicModal
        onRequestClose={() => {
          setShowEmailMagicModal(false)
        }}
        visible={showEmailMagicModal}
      />
    </Column>
  )
}

export default EmailTemplateListItem
