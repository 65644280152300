import * as Sentry from '@sentry/browser'
import useFormConcurrent from '@trinsly/common/src/hooks/use-form-concurrent'
import useQueryParams from '@trinsly/common/src/hooks/use-query-params'
import required from '@trinsly/common/src/validations/required'
import useCreateCampaign from 'api-hooks/use-create-campaign'
import useDeleteCampaignDraft from 'api-hooks/use-delete-campaign-draft'
import useGetCampaign from 'api-hooks/use-get-campaign'
import useGetUserSignature from 'api-hooks/use-get-user-signature'
import useUpdateCampaign from 'api-hooks/use-update-campaign'
import React from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { toast } from 'react-toastify'

import CampaignType from 'types/campaign-type'
import EmailTemplateType from 'types/email-template-type'
import { Signature } from 'types/settings-type'

export type FormProviderProps = {
  children: React.ReactNode | React.ReactNode[]
}

type ContextProps = {
  setValue: (path: string, value: any) => void
  getValue: (path: string) => any
  setValues: (values: any) => void
  getValues: () => any
  getError: (path: string) => any
  submit: (values?: any) => any
  reset: () => void
  isSubmitted: boolean
  creating: boolean
  updating: boolean
  pending: boolean
  isEdit: boolean
  campaign: CampaignType | undefined
  signature: Signature | undefined
  addFollowUp: () => void
  removeFollowUp: (index: number) => void
  toggleFollowUpInThread: (index: number, toggle: boolean) => void
  isCampaignBeingDuplicated: boolean
}

const Context = React.createContext<ContextProps>({} as any)
export const useFormContext = () => React.useContext(Context)

function FormProvider({ children }: FormProviderProps) {
  const history = useHistory()
  const { params } = useRouteMatch() as any
  const { params: queryParams } = useQueryParams()
  const [validations, setValidations] = React.useState<any>({})

  const duplicatingCampaignId = queryParams?.['duplicating-campaign-id']
  const campaignId = params?.campaignId || ''
  const isEdit = Boolean(campaignId)
  const isCampaignBeingDuplicated = Boolean(duplicatingCampaignId)

  const { data: signature, pending: pendingSignature } = useGetUserSignature({})
  const { data: duplicatingCampaign, pending: findingDuplicatingCampaign } =
    useGetCampaign({
      campaignId: duplicatingCampaignId,
      onError: (error) => {
        toast.error('Error finding Campaign')
        Sentry.addBreadcrumb({
          category: 'Error Finding Campaign to Duplicate',
          message: `Failed to retrieve Campaign ${duplicatingCampaignId}`,
          level: Sentry.Severity.Error,
        })
      },
    })
  const { data: campaign, pending: findingCampaign } = useGetCampaign({
    campaignId: campaignId,
    onError: (error) => {
      toast.error('Error finding Campaign')
      Sentry.addBreadcrumb({
        category: 'Error Finding Campaign',
        message: `Failed to retrieve Campaign ${campaignId}`,
        level: Sentry.Severity.Error,
      })
    },
  })
  const { fetch: deleteDraft, pending: pendingDelete } = useDeleteCampaignDraft(
    {
      onSuccess: (data) => {},
      onError: (error) => {
        console.error('Error deleting draft')
      },
    }
  )
  const { fetch: createCampaign, pending: creating } = useCreateCampaign({
    onSuccess: () => {
      history.block(() => {})
      const draftId = queryParams?.['campaign-draft-id']
      if (draftId) {
        deleteDraft({ draftId: draftId })
      }
      toast.success('Campaign Created!')
      history.push(`/campaigns`)
    },
    onError: (error) => {
      toast.error(error?.message || 'Error creating Campaign')
      Sentry.addBreadcrumb({
        category: 'Error Creating Campaign',
        message: `Failed to retrieve Campaign`,
        level: Sentry.Severity.Error,
      })
    },
  })
  const { fetch: updateCampaign, pending: updating } = useUpdateCampaign({
    onSuccess: () => {
      toast.success('Campaign Updated!')
      history.push(`/campaigns`)
    },
    onError: (error) => {
      toast.error(error?.message || 'Error updating Campaign')
      Sentry.addBreadcrumb({
        category: 'Error Updating Campaign',
        message: `Failed to retrieve Campaign ${JSON.stringify(error)}`,
        level: Sentry.Severity.Error,
      })
    },
  })
  const {
    getValue,
    getError,
    setValue,
    getValues,
    setValues,
    submit,
    isSubmitted,
  } = useFormConcurrent({
    initialValues: {
      campaign: {
        isForCandidate: true,
        templates: [{}],
      },
    },
    onSubmit: ({ campaign }: { campaign: CampaignType }) => {
      const hasInvalidTemplateField = campaign?.templates?.some(
        (template) => !template?.subject || !template?.body
      )
      if (hasInvalidTemplateField) {
        return
      }

      const shouldUpdate = Boolean(campaign?.id)
      if (shouldUpdate) {
        updateCampaign({ campaign })
      } else {
        createCampaign({ campaign })
      }
    },
    validations,
  })

  const templates: EmailTemplateType[] = getValue('campaign.templates') || []
  const firstEmailSubject = templates?.[0]?.subject || ''
  const hasSignature = Boolean(signature?.signature)
  const pendingUpdate = pendingSignature && findingCampaign
  const pendingDuplicating = pendingSignature && findingDuplicatingCampaign
  const pendingCreate = pendingSignature
  const pending = isEdit
    ? pendingUpdate
    : isCampaignBeingDuplicated
    ? pendingDuplicating
    : pendingCreate

  function addFollowUp() {
    const hasSignature = Boolean(signature?.signature)
    const followUp = {
      isInThread: true,
      subject: `Re: ${firstEmailSubject}`,
      body: hasSignature ? `<br>${signature?.signature}` || '' : '',
    } as EmailTemplateType
    setValue('campaign.templates', [...(templates || []), followUp])
  }

  function removeFollowUp(index: number) {
    const newTemplates = templates?.filter((template, i) => i !== index)
    setValue('campaign.templates', newTemplates)
  }

  function toggleFollowUpInThread(index: number, toggle: boolean) {
    const followUp = getValue(
      `campaign.templates[${index}]`
    ) as EmailTemplateType
    setValue(`campaign.templates[${index}]`, {
      ...(followUp || {}),
      subject: `Re: ${firstEmailSubject}`,
      isInThread: toggle,
    })
  }

  function addDynamicValidationToTemplates() {
    setValidations({
      ['campaign.name']: [required],
      ...templates?.reduce((acc, template, index) => {
        return {
          ...acc,
          [`campaign.templates[${index}].subject`]: [required],
          [`campaign.templates[${index}].body`]: [required],
          [`campaign.templates[${index}].duration`]:
            index > 0 ? [required] : [],
        }
      }, {}),
    })
  }

  function updateAllFollowUpSubject() {
    const updateTemplatesInThread = templates?.map((template, index) => {
      const shouldUpdate = index > 0 && Boolean(template?.isInThread)
      if (shouldUpdate) {
        return {
          ...template,
          subject: `Re: ${firstEmailSubject}`,
        }
      }
      return template
    })
    setValue('campaign.templates', updateTemplatesInThread)
  }

  function copyValuesFrom(campaign: CampaignType | undefined) {
    const hasOwner = Boolean(campaign?.ownerId)
    const campaignToForm = {
      id: params?.campaignId || undefined,
      isForCandidate: campaign?.isForCandidate || undefined,
      bcc: campaign?.bcc || undefined,
      crmJobId: campaign?.crmJobId || undefined,
      name: campaign?.name || undefined,
      owner: hasOwner
        ? {
            id: campaign?.ownerId || undefined,
            name: campaign?.ownerName || undefined,
          }
        : undefined,
      tagList: campaign?.tagList,
      templates: campaign?.templates || [
        {
          body: hasSignature ? `<br>${signature?.signature}` : '',
        } as any,
      ],
    }
    setValue('campaign', campaignToForm)
  }

  React.useEffect(() => {
    addDynamicValidationToTemplates()
  }, [templates?.length])

  React.useEffect(() => {
    updateAllFollowUpSubject()
  }, [firstEmailSubject, templates?.length])

  //clears form state on route change
  React.useEffect(() => {
    copyValuesFrom(undefined)
  }, [campaignId, duplicatingCampaignId, pending])

  //updates form state on campaign id changes
  React.useEffect(() => {
    copyValuesFrom(campaign)
  }, [campaign?.id])

  //update form with copied campaign
  const extending = getValue('extending')
  React.useEffect(() => {
    copyValuesFrom(extending)
  }, [extending?.id])

  //update form with duplicated campaign
  React.useEffect(() => {
    setTimeout(() => {
      setValue('extending', duplicatingCampaign)
    }, 10)
    copyValuesFrom(duplicatingCampaign)
  }, [duplicatingCampaign?.id])

  return (
    <Context.Provider
      value={{
        getValue,
        getError,
        setValue,
        setValues,
        getValues,
        reset: () => copyValuesFrom(undefined),
        isSubmitted: isSubmitted?.(),
        submit,
        creating,
        updating,
        pending,
        isEdit,
        campaign,
        addFollowUp,
        removeFollowUp,
        toggleFollowUpInThread,
        isCampaignBeingDuplicated,
        signature,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default FormProvider
