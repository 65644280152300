import { retrieveCredentials } from '@trinsly/common/src/services/api'
import axios from 'axios'

export type SyncMergeIntegrationServiceProps = {
  integrationId: Integration['id']
}

async function syncMergeIntegrationService({
  integrationId,
}: SyncMergeIntegrationServiceProps) {
  const { accessToken } = retrieveCredentials()
  const response = await axios.request({
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${process.env.REACT_APP_MERGE_API_URL}/integrations/${integrationId}/sync`,
  })

  return response?.data as any
}

export default syncMergeIntegrationService
