import { ReactComponent as CollapseSvg } from '@trinsly/common/src/assets/icons/collapse.svg'
import { ReactComponent as ExpandSvg } from '@trinsly/common/src/assets/icons/expand.svg'
import Clickable from '@trinsly/common/src/components/atoms/clickable'
import Column from '@trinsly/common/src/components/atoms/column'
import Divider from '@trinsly/common/src/components/atoms/divider'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Text from '@trinsly/common/src/components/atoms/text'
import EmailTemplateInput from '@trinsly/common/src/components/molecules/quill-editor/email-template-input'
import AttachmentsInput from 'components/attachments-input'
import { find } from 'lodash'
import moment from 'moment'
import ScheduleTimeSelectEnum, {
  buildTimeSelectOptions,
} from '../../../types/schedule-time-select-enum'

type EmailFormListItemProps = {
  prospectRow: string[]
  getValue: (path: string) => any
  getValues: () => any
  setValue: (path: string, value: any) => void
  setValues: (values: any) => void
  getError: (path: string) => any
}

function EmailFormListItem({
  prospectRow,
  getValue,
  setValue,
  getValues,
  setValues,
  getError,
}: EmailFormListItemProps) {
  const emailKeyIndex = getValue('variables[Email]')
  const prospectEmail = prospectRow?.[emailKeyIndex] || ''
  const prospectEmailWithoutDots = prospectEmail?.replaceAll('.', '@@')
  const threads = (getValue(`custom[${prospectEmailWithoutDots}].threads`) ||
    []) as any[]

  return (
    <Column className="gap-sm">
      <Row className="px-md gap-1">
        <Text size="sm">Currently finalizing</Text>
        <Text size="sm" fontWeight="bold">
          {prospectEmail?.trim() || '--'}
        </Text>
      </Row>
      {threads?.map?.((thread: any, index: number) => {
        const relativePath = `custom[${prospectEmailWithoutDots}].threads[${index}]`
        const { daysAfter, time } = getValue('schedules')?.[index] || {}
        const expanded = getValue(`${relativePath}.expanded`)

        return (
          <Column key={index} className="border border-gray-200">
            <Clickable
              className="px-md py-xs"
              onClick={() => {
                setValue(`${relativePath}.expanded`, !expanded)
              }}
            >
              <Row className="flex-1 items-center justify-between">
                <Row className="items-center">
                  <Column className="px-xl py-xs rounded-full bg-primary-50 min-w-40">
                    <Text
                      size="sm"
                      fontWeight="bold"
                      color="primary-500"
                      className="uppercase text-center"
                    >{`Email ${index + 1}`}</Text>
                  </Column>
                  <Separator size="xs" />
                  {expanded ? <CollapseSvg /> : <ExpandSvg />}
                </Row>
                <Separator />
                <Text
                  size="sm"
                  fontWeight="bold"
                  color="primary-500"
                >{`${getDateLabel(daysAfter)}, ${getTimeSelectLabel({
                  daysAfter,
                  emailPosition: index + 1,
                  value: time,
                })}`}</Text>
              </Row>
            </Clickable>
            <Column className={expanded ? '' : 'h-0 invisible'}>
              <Divider />
              <Column className="px-md py-md">
                <EmailTemplateInput
                  subject={{
                    id: `custom-subject-${index + 1}-text-input`,
                    label: 'Subject',
                    value: getValue(`${relativePath}.subject`),
                    onChange: (value) => {
                      setValue(`${relativePath}.subject`, value)
                    },
                    error: getError(`${relativePath}.subject`),
                    required: true,
                  }}
                  body={{
                    id: `custom-body-${index + 1}-editor`,
                    label: 'Body',
                    value: getValue(`${relativePath}.body`),
                    modules: ['image-resize', 's3-uploader'],
                    onChange: ({ html }) => {
                      setValue(`${relativePath}.body`, html)
                    },
                    error: getError(`${relativePath}.body`),
                    required: true,
                  }}
                />
                <Separator />
                <Column className="gap-xs">
                  <Text size="sm">
                    To modify attachments, do so on the campaign.
                  </Text>
                  {getValue(`${relativePath}.attachments`)?.length > 0 ? (
                    <AttachmentsInput
                      disabled={true}
                      value={getValue(`${relativePath}.attachments`)}
                      onChange={(name: any, attachments: any) => {
                        setValue(`${relativePath}.attachments`, attachments)
                      }}
                    />
                  ) : (
                    <Text size="sm" color="gray-400">
                      No attachments.
                    </Text>
                  )}
                </Column>
              </Column>
            </Column>
          </Column>
        )
      })}
    </Column>
  )
}

export default EmailFormListItem

function getTimeSelectLabel({
  daysAfter,
  emailPosition,
  value,
}: {
  daysAfter: number
  emailPosition: number
  value: ScheduleTimeSelectEnum
}): string | null {
  return emailPosition === 1 &&
    daysAfter <= 0 &&
    value === ScheduleTimeSelectEnum.UNMODIFIED_VALUE
    ? 'Immediately'
    : find(buildTimeSelectOptions(), { value })?.label || null
}

function getDateLabel(daysAfter: number) {
  return daysAfter === 0
    ? `Today, ${moment().format('dddd, M/D')}`
    : moment().add(daysAfter, 'd').format('dddd, M/D')
}
