import api from '@trinsly/common/src/services/api'
import { compact } from 'lodash'
import HotlistType from 'types/hotlist-type'
import ProspectType from 'types/prospect-type'

type LinkProspectsToHotlistServiceProps = {
  hotlistId: HotlistType['id']
  prospectIds: ProspectType['id'][]
}

async function LinkProspectsToHotlistService({
  hotlistId,
  prospectIds,
}: LinkProspectsToHotlistServiceProps) {
  const response = await api(`/hotlists/${hotlistId}/prospects`, {
    method: 'POST',
    body: {
      prospect: { id: compact(prospectIds) },
    },
  })
  return response as any
}

export default LinkProspectsToHotlistService
