import Badge from '@trinsly/common/src/components/atoms/badge'
import Emoji from 'react-emoji-render'
import { ResponseType } from 'types/prospect-history-type'

const {
  DO_NOT_CONTACT,
  INTERESTED,
  INTERESTED_LATER,
  NOT_AVAILABLE,
  NOT_INTERESTED,
} = ResponseType

function ResponseTypeLabel({ responseType }: { responseType: ResponseType }) {
  switch (responseType) {
    case DO_NOT_CONTACT:
      return <Emoji text="❌" />

    case INTERESTED:
      return <Emoji text="👍" />

    case INTERESTED_LATER:
      return <Emoji text="⏰" />

    case NOT_INTERESTED:
      return <Emoji text="👎" />

    default:
      return <Emoji text="❓" />
  }
}

function ResponseTypeTag({ responseType }: { responseType: ResponseType }) {
  return (
    <Badge size="sm">
      <ResponseTypeLabel responseType={responseType} />
    </Badge>
  )
}

export default ResponseTypeTag
