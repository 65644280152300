import Card from '@trinsly/common/src/components/atoms/card'
import Row from '@trinsly/common/src/components/atoms/row'

function LogoSection() {
  return (
    <Row className="gap-md flex-wrap">
      <Card className="px-md min-h-16 items-center justify-center">
        <img
          src="https://bynder-public.s3.amazonaws.com/media/6EF965C8-0E83-47D9-ABF1A5C7AC6D092A/0/8FABF216-E3AF-4BF0-87DC08F9477B0E5D/webimage-2F589668-30FB-486A-991D21637446BF36.png"
          className="h-xl object-contain"
        />
      </Card>
      <Card className="min-h-16 items-center justify-center">
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/f0/CME_Group_Logo.svg/1280px-CME_Group_Logo.svg.png"
          className="px-md h-lg object-contain"
        />
      </Card>
    </Row>
  )
}

export default LogoSection
