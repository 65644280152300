// Must match the BE
export enum ActivityLogEventEnum {
  EMAIL_DELIVERED = 'email_delivered',
  EMAIL_OPENED = 'email_opened',
  INVITED_ACCEPTED = 'invite_accepted',
  SESSION_CREATED = 'session_created',
  SESSION_DESTROYED = 'session_destroyed',
  THREAD_COMPLETED = 'thread_completed',
  THREAD_HARD_BOUNCED = 'thread_hard_bounced',
  THREAD_PAUSED = 'paused',
  THREAD_RESPONDED = 'thread_responded',
  THREAD_RESUMED = 'resumed',
  THREAD_SCHEDULED = 'thread_scheduled',
  USER_REGISTERED = 'user_registered',
}

export default ActivityLogEventEnum
