import { ReactComponent as RescheduleSvg } from '@trinsly/common/src/assets/icons/reschedule.svg'
import Badge from '@trinsly/common/src/components/atoms/badge'
import Column from '@trinsly/common/src/components/atoms/column'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Table from '@trinsly/common/src/components/atoms/table'
import Text from '@trinsly/common/src/components/atoms/text'
import Tooltip from '@trinsly/common/src/components/atoms/tooltip'
import Button from '@trinsly/common/src/components/molecules/button'
import Pagination from '@trinsly/common/src/components/molecules/pagination'
import ThreadStatusTag from '@trinsly/common/src/components/molecules/thread-status-tag'
import ThreadStateEnum from '@trinsly/common/src/types/thread-state-enum-type'
import LocalizedDate from 'components/localized-date'
import React from 'react'
import { Link } from 'react-router-dom'
import ThreadType, { ThreadEmailType } from 'types/thread-type'
import { getThreadStateCounts } from '..'
import UnsentThreadsRescheduleModal from '../../unsent-reach-outs-section/unsent-threads-reschedule-modal'
import CancelThreadButton from './cancel-thread-button'
import CancelThreadEmailButton from './cancel-thread-email-button'
import OpenedCell from './opened-cell'

import ThreadEmailFormModal from './thread-email-form-modal'

export type ReachOutsTableProps = {
  threads: ThreadType[]
  pending: boolean
  pagination: {
    total: number
    page: number
    perPage: number
  }
  onNextPage: () => void
  onPrevPage: () => void
} & { [index: string]: any }

function ReachOutsTable({
  threads,
  pending,
  pagination,
  onNextPage,
  onPrevPage,
}: ReachOutsTableProps) {
  const [showRescheduleModal, setShowRescheduleModal] = React.useState(false)
  const [rescheduling, setRescheduling] = React.useState<ThreadType>()

  const [showEditEmailModal, setShowEditEmailModal] = React.useState(false)
  const [editingEmail, setEditingEmail] = React.useState<ThreadEmailType>()

  return (
    <React.Fragment>
      <Table
        data={threads}
        pending={pending}
        columns={[
          {
            width: 280,
            header: 'E-mail (sent/total)',
            cell: (thread) => {
              const stateCount = getThreadStateCounts(thread)
              return (
                <Column>
                  {!!thread?.prospectId && (
                    <Link to={`/prospects/${thread?.prospectId}`}>
                      <Text fontWeight="semibold" className="hover:underline">
                        {thread?.prospectName || '-'}
                      </Text>
                    </Link>
                  )}

                  <Row>
                    <Text size="xs">{`${thread.email} (${stateCount.delivered}/${thread.emails.length})\u00A0`}</Text>
                    <Separator size="xs" />
                    {thread.sourceUrl && (
                      <Tooltip content={thread.sourceUrl}>
                        <a
                          href={thread.sourceUrl}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <i className="fa fa-link" />
                        </a>
                      </Tooltip>
                    )}
                  </Row>
                </Column>
              )
            },
          },
          {
            width: 120,
            header: 'Campaign',
            cell: (thread) => <Text size="xs">{thread.campaignName}</Text>,
          },
          /**TODO: Add created/started at */
          {
            width: 120,
            header: 'Status',
            cell: (thread) => (
              <>
                {thread.state && (
                  <ThreadStatusTag status={thread.state as any} />
                )}
              </>
            ),
          },
          {
            width: 60,
            header: 'Opened',
            cell: (thread) => <OpenedCell thread={thread} />,
          },
          {
            width: 200,
            //TODO: Once API catches up - reschedule should be available on canceled
            cell: (thread) => (
              <Row className="items-center justify-end">
                {(thread.state === ThreadStateEnum.PAUSED ||
                  thread.state === ThreadStateEnum.PAUSED_BY_AUTH_LOSS) && (
                  <React.Fragment>
                    <Button
                      size="xs"
                      shape="rounded"
                      variant="outline"
                      intent="primary"
                      onClick={() => {
                        setShowRescheduleModal(true)
                        setRescheduling(thread)
                      }}
                    >
                      <Row className="items-center">
                        <RescheduleSvg />
                        <Separator size="xxs" />
                        Reschedule
                      </Row>
                    </Button>
                  </React.Fragment>
                )}
                {(thread.state === ThreadStateEnum.IN_PROGRESS ||
                  thread.state === ThreadStateEnum.PAUSED ||
                  thread.state === ThreadStateEnum.PAUSED_BY_AUTH_LOSS) && (
                  <>
                    <Separator size="xs" />
                    <CancelThreadButton thread={thread} />{' '}
                  </>
                )}
              </Row>
            ),
          },
        ]}
        expandedRow={(thread) => (
          <Column className="w-5/6 pl-12">
            <Table
              data={thread.emails}
              columns={[
                {
                  width: 160,
                  header: 'Subject',
                  cell: (email) => email?.subject || '--',
                },
                {
                  width: 80,
                  header: 'Status',
                  cell: (email) => {
                    return (
                      <Row>
                        <Badge size="sm">{email.state}</Badge>
                      </Row>
                    )
                  },
                },
                /**TODO: Add individual Email Open Status */
                {
                  width: 120,
                  header: 'Scheduled At',
                  cell: (email) => {
                    return (
                      <Row>
                        {email.deliverAt ? (
                          <LocalizedDate utcDate={email.deliverAt} />
                        ) : (
                          '-'
                        )}
                      </Row>
                    )
                  },
                },
                {
                  width: 200,
                  cell: (email) => {
                    const isReadonly = ![
                      'scheduled',
                      'paused',
                      'paused_by_auth_loss',
                    ].includes(email.state)
                    return (
                      <Row className="items-center justify-end">
                        <Column className="min-w-28">
                          <Button
                            size="sm"
                            variant={isReadonly ? 'outline' : 'solid'}
                            onClick={() => {
                              setShowEditEmailModal(true)
                              setEditingEmail(email)
                            }}
                          >
                            {isReadonly ? 'See details' : 'Edit e-mail'}
                          </Button>
                        </Column>
                        {!isReadonly && (
                          <React.Fragment>
                            <Separator size="xs" />
                            <CancelThreadEmailButton email={email} />
                          </React.Fragment>
                        )}
                      </Row>
                    )
                  },
                },
              ]}
            />
          </Column>
        )}
      />
      <Row className="items-center py-sm px-md justify-end">
        <Pagination
          page={pagination?.page}
          total={pagination?.total}
          perPage={pagination?.perPage}
          onPrev={onPrevPage}
          onNext={onNextPage}
        />
      </Row>
      <ThreadEmailFormModal
        threadEmail={editingEmail}
        visible={showEditEmailModal}
        onRequestClose={() => {
          setShowEditEmailModal(false)
        }}
      />
      {/* <EditEmailModal
        editingEmailId={editingEmail?.id}
        isOpen={showEditEmailModal}
        closeModal={() => {
          setShowEditEmailModal(false)
        }}
      /> */}
      <UnsentThreadsRescheduleModal
        thread={rescheduling}
        visible={showRescheduleModal}
        onRequestClose={() => {
          setShowRescheduleModal(false)
        }}
      />
    </React.Fragment>
  )
}

export default ReachOutsTable
