import useQueryParams from '@trinsly/common/src/hooks/use-query-params'
import useDeleteBatchDraft from 'api-hooks/use-delete-batch-draft'
import useGetBatchDraft from 'api-hooks/use-get-batch-draft'
import useGetUserSignature from 'api-hooks/use-get-user-signature'
import useSaveBatchDraft from 'api-hooks/use-save-batch-draft'
import { set } from 'lodash'
import React from 'react'
import { toast } from 'react-toastify'
import DraftType from 'types/draft-type'
import { useDebouncedCallback } from 'use-debounce'
import { useFormContext } from '../form-provider'
import LeaveConfirmationModal from './leave-confirmation-modal'

type DraftProviderProps = {
  children: React.ReactNode | React.ReactNode[]
}

type Status = 'fetched' | 'not-needed' | 'needed'

type ContextProps = {
  pending: boolean
  saving: boolean
  draft: DraftType | undefined
  status: Status
}

const Context = React.createContext<ContextProps>({} as any)
export const useDraftContext = () => React.useContext(Context)

function DraftProvider({ children }: DraftProviderProps) {
  const [, forceUpdate] = React.useReducer((x) => x + 1, 0)
  const { getValues, setValues, reset } = useFormContext()
  const { params: queryParams, updateParams } = useQueryParams()
  const draftId = queryParams?.['batch-draft-id'] || ''

  const [fetchStatus, setFetchStatus] = React.useState<Status>(
    draftId ? 'needed' : 'not-needed'
  )

  const ignoreChangesRef = React.useRef<boolean>(true)
  const unsavedChangesRef = React.useRef<boolean>(false)
  const draftRef = React.useRef<DraftType>({} as any)

  const { pending: pendingSignature } = useGetUserSignature({})
  const { pending: pendingGetDraft } = useGetBatchDraft({
    enabled: !pendingSignature && fetchStatus === 'needed',
    draftId: draftId,
    onSuccess: (data) => {
      ignoreChangesForSomeSeconds()
      set(draftRef.current, 'id', data?.id)
      set(draftRef.current, 'payload', data?.payload)
      set(draftRef.current, 'updatedAt', data?.updatedAt)
      setFetchStatus('fetched')
      setValues(data?.payload || {})
    },
    onError: (error) => {
      toast.error('Error finding Batch Draft')
    },
  })

  const { fetch: deleteDraft } = useDeleteBatchDraft({
    onSuccess: (data) => {},
    onError: (error) => {
      toast.error('Error deleting Draft')
    },
  })

  const { fetch: saveDraft, pending: pendingSaveDraft } = useSaveBatchDraft({
    onSuccess: (data) => {
      //delete draft if user is not anymore in form screen
      if (!window?.location?.pathname?.includes?.('/batches/new')) {
        deleteDraft({ draftId: data?.id })
        return
      }

      set(draftRef.current, 'id', data?.id)
      set(draftRef.current, 'updatedAt', data?.updatedAt)
      forceUpdate()

      if (!draftId) {
        updateParams({ ['batch-draft-id']: data?.id })
      }
    },
    onError: (error) => {
      toast.error(error?.message || 'Error saving Batch Draft')
    },
  })

  //useful to avoid saving form updates not related to user changes
  const allowChangesAfterSomeSeconds = useDebouncedCallback(() => {
    ignoreChangesRef.current = false
  }, 5000)
  function ignoreChangesForSomeSeconds() {
    ignoreChangesRef.current = true
    allowChangesAfterSomeSeconds()
  }

  //passing refs to debounce callback
  const formData = getValues() || {}
  set(draftRef.current, 'payload', formData)
  const save = useDebouncedCallback(() => {
    if (window?.location?.pathname?.includes?.('/batches/new')) {
      saveDraft({
        draft: {
          id: draftRef?.current?.id,
          payload: draftRef?.current?.payload,
        },
      })
      unsavedChangesRef.current = false
    }
  }, 5000)

  React.useEffect(() => {
    if (!ignoreChangesRef.current) {
      save()
      unsavedChangesRef.current = true
    }
  }, [JSON.stringify(formData)])

  //any changes to draftId params clears data
  React.useEffect(() => {
    set(draftRef.current, 'id', draftId)

    if (Boolean(draftId) && String(draftId) === String(draftRef.current.id)) {
      return
    }

    set(draftRef.current, 'payload', undefined)
    set(draftRef.current, 'createdAt', undefined)
    set(draftRef.current, 'updatedAt', undefined)
    ignoreChangesForSomeSeconds()
    reset()
  }, [draftId])

  //allow draft to start running only after all pending stuff is resolved
  const pending = pendingSignature || pendingGetDraft
  React.useEffect(() => {
    if (!pending) {
      ignoreChangesForSomeSeconds()
    }
  }, [pending])

  return (
    <Context.Provider
      value={{
        status: fetchStatus,
        pending: Boolean(draftId) && pending,
        saving: pendingSaveDraft,
        draft: draftRef.current,
      }}
    >
      <React.Fragment>
        {children}
        <LeaveConfirmationModal
          draft={draftRef.current}
          saving={unsavedChangesRef?.current || pendingSaveDraft}
        />
      </React.Fragment>
    </Context.Provider>
  )
}

export default DraftProvider
