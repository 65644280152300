import React from 'react'
import { ReactComponent as SearchSvg } from '../../assets/icons/search.svg'
import useDebounce from '../../hooks/use-debounce'
import slugify from '../../utils/slugify'
import Column from '../atoms/column'
import Input, { InputProps } from '../atoms/input'

export type SearchInputProps = {} & Omit<InputProps, 'type'>

function _SearchInput(
  {
    placeholder,
    id = slugify(placeholder, { suffix: 'search-input' }),
    value,
    onChange,
    className,
    ...rest
  }: SearchInputProps,
  ref: React.Ref<HTMLInputElement>
) {
  const [internalValue, setInternalValue] = React.useState(
    (value || '') as string
  )
  useDebounce({
    value: internalValue,
    onDebounce: (debouncedValue) => {
      onChange?.(debouncedValue, { target: { value: debouncedValue } } as any)
    },
  })

  return (
    <Column className={`relative ${className || ''}`}>
      <Column className="absolute top-0 left-3 bottom-0 justify-center">
        <SearchSvg className="w-5 h-5 text-gray-400" />
      </Column>
      <Input
        {...rest}
        id={id}
        placeholder={placeholder}
        value={internalValue}
        onChange={setInternalValue}
        type="search"
        ref={ref as any}
        className="pl-xl"
      />
    </Column>
  )
}

const SearchInput = React.forwardRef(_SearchInput)
export default SearchInput
