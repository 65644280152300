import QueryApiHookType from '@trinsly/common/src/types/query-api-hook-type'
import { useQuery } from 'react-query'
import getBatchDraftService, {
  GetBatchDraftServiceProps,
} from 'services/get-batch-draft-service'
import DraftType from 'types/draft-type'

type UseGetBatchDraftProps = {
  enabled: boolean
} & GetBatchDraftServiceProps &
  QueryApiHookType<DraftType>

function useGetBatchDraft({
  draftId,
  enabled,
  onError,
  onSuccess,
}: UseGetBatchDraftProps) {
  const { data, error, status } = useQuery(
    ['/batches/drafts/{id}', draftId],
    () => getBatchDraftService({ draftId }),
    {
      enabled,
      staleTime: !enabled ? 0 : undefined,
      refetchOnWindowFocus: false,
      onError,
      onSuccess,
    }
  )

  return {
    data,
    pending: status === 'loading',
    error,
  }
}

export default useGetBatchDraft
