import SelectInput from '@trinsly/common/src/components/molecules/select-input'
import usePageableState from '@trinsly/common/src/hooks/use-pageable-state'
import useGetCampaigns from 'api-hooks/use-get-campaigns'
import { CampaignFilterScopeEnum } from 'services/get-campaigns-service'

interface CampaignFilterProps {
  onChange: (value: Id | null) => void
  ownerId: Id | null
  value: Id | null
}

function CampaignFilter({ onChange, ownerId, value }: CampaignFilterProps) {
  const { pageable } = usePageableState({
    page: 1,
    perPage: 999,
  })
  const { data: mineCampaigns, pending: pendingMineCampaigns } =
    useGetCampaigns({
      ...pageable,
      filters: {
        state: CampaignFilterScopeEnum.MINE,
      },
    })
  const { data: sharedCampaigns, pending: pendingSharedCampaigns } =
    useGetCampaigns({
      ...pageable,
      filters: {
        state: CampaignFilterScopeEnum.SHARED,
      },
    })

  const campaigns = [...mineCampaigns, ...sharedCampaigns]
  const pending = pendingSharedCampaigns || pendingMineCampaigns

  return (
    <SelectInput
      label="For"
      pending={pending}
      placeholder="Select a campaign"
      disabled={!ownerId}
      onChange={(item) => onChange(item?.id ?? null)}
      options={campaigns}
      value={campaigns.find(
        (campaign) => String(campaign.id) === String(value)
      )}
      itemRenderer={(item) => item.name}
      valueRenderer={(value) => `Campaign ${value.name}`}
    />
  )
}

export default CampaignFilter
