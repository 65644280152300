import { debounce } from 'lodash'
import React from 'react'
import PageableType from '../types/pageable-type'

type UsePageableStateProps = Partial<PageableType>

function usePageableState(initialValues: UsePageableStateProps = {}) {
  const [pageable, setPageable] = React.useState<PageableType>({
    search: initialValues?.search || '',
    page: initialValues?.page,
    perPage: initialValues?.perPage,
    sort: initialValues?.sort || {},
    filters: initialValues?.filters || {},
  } as PageableType)

  const setSearch = React.useCallback(
    debounce((value) => {
      setPageable((state) => ({ ...state, search: value || '', page: 1 }))
    }, 500),
    []
  )

  function setPageSize(value: number) {
    setPageable({ ...pageable, perPage: Number(value), page: 1 })
  }

  function nextPage() {
    const currentPage = Number(pageable?.page || 1)
    setPageable({ ...pageable, page: currentPage + 1 })
  }

  function prevPage() {
    const currentPage = Number(pageable?.page || 2)
    setPageable({ ...pageable, page: currentPage - 1 })
  }

  function setSort(
    orderBy: string = '',
    orderDirection: PageableType['sort'][string]
  ) {
    setPageable({
      ...pageable,
      sort: {
        [orderBy]: orderDirection,
      },
      page: 1,
    })
  }

  function setFilters(
    path:
      | string
      | ((filters: PageableType['filters']) => PageableType['filters']),
    value?: any
  ) {
    if (typeof path === 'function') {
      const newFilters = path(pageable?.filters || {})
      setPageable({
        ...pageable,
        filters: newFilters,
        page: 1,
      })
      return
    }

    setPageable({
      ...pageable,
      filters: {
        ...(pageable?.filters || {}),
        [path]: value,
      },
      page: 1,
    })
  }
  function resetPageable() {
    setPageable({
      search: initialValues?.search || '',
      page: initialValues?.page,
      perPage: initialValues?.perPage,
      sort: initialValues?.sort || {},
      filters: initialValues?.filters || {},
    } as PageableType)
  }
  return {
    resetPageable,
    pageable,
    nextPage,
    prevPage,
    setPageSize,
    setFilters,
    setSort,
    setSearch,
  }
}

export default usePageableState
