import Column from '@trinsly/common/src/components/atoms/column'
import Row from '@trinsly/common/src/components/atoms/row'
import Text from '@trinsly/common/src/components/atoms/text'
import zxcvbn from 'zxcvbn'

function PasswordStrength({ password }: { password: string }) {
  const strength = determinePasswordStrength(password)

  return (
    <Column className="gap-0.5">
      <Row className="items-center gap-1">
        <Text size="xs">Password Strength:</Text>
        <Text
          size="xs"
          fontWeight="bold"
          color={
            {
              0: 'gray-400',
              1: 'warning-400',
              2: 'info-400',
              3: 'info-500',
              4: 'success-500',
            }[strength] as any
          }
        >
          {
            {
              0: 'Very Weak',
              1: 'Weak',
              2: 'Okay',
              3: 'Good',
              4: 'Strong',
            }[strength]
          }
        </Text>
      </Row>

      <Column className="w-full bg-gray-200 rounded-full h-2.5">
        <Column
          className={`${
            {
              0: 'bg-gray-400',
              1: 'bg-warning-400',
              2: 'bg-info-400',
              3: 'bg-info-500',
              4: 'bg-success-500',
            }[strength]
          } h-2.5 rounded-full transition-all ease-in-out duration-500`}
          style={{
            width: `${
              {
                0: 2,
                1: 25,
                2: 50,
                3: 75,
                4: 100,
              }[strength]
            }%`,
          }}
        ></Column>
      </Column>
    </Column>
  )
}

export default PasswordStrength

// TYPES

type PasswordStrength = 0 | 1 | 2 | 3 | 4

// HELPERS

// https://github.com/dropbox/zxcvbn
// Returns number 0 - 4, inclusive

export function determinePasswordStrength(password: string): PasswordStrength {
  return zxcvbn(password).score
}
