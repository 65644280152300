import { ReactComponent as ReportStatsSvg } from '@trinsly/common/src/assets/icons/report-stats.svg'
import IconButton from '@trinsly/common/src/components/molecules/icon-button'
import { useHistory } from 'react-router'
import CampaignType from 'types/campaign-type'

type ReportingsCampaignButtonProps = {
  campaign: CampaignType
  disabled: boolean
}

function ReportingsCampaignButton({
  campaign,
  disabled,
}: ReportingsCampaignButtonProps) {
  const history = useHistory()

  return (
    <IconButton
      testId="reporting-button"
      size="sm"
      variant="ghost"
      intent="primary"
      shape="circle"
      disabled={disabled}
      onClick={() => history.push(`/campaigns/${campaign?.id}?tabs=reporting`)}
    >
      <ReportStatsSvg />
    </IconButton>
  )
}

export default ReportingsCampaignButton
