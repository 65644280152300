import api from '@trinsly/common/src/services/api'
import ProspectNoteType from 'types/prospect-note-type'
import ProspectType from 'types/prospect-type'

type CreateProspectNoteServiceProps = {
  prospectId: ProspectType['id']
  note: string
}

async function createProspectNoteService({
  prospectId,
  note,
}: CreateProspectNoteServiceProps) {
  const response = (await api(`/prospects/${prospectId}/notes`, {
    method: 'POST',
    body: {
      comment: { comment: note },
    },
  })) as ProspectNoteType
  return response
}

export default createProspectNoteService
