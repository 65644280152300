import api from '@trinsly/common/src/services/api'

export type VerifyUser2faServiceProps = {}

export default async function verifyUser2faService({}: VerifyUser2faServiceProps) {
  const response = await api('/two_factor_auths', {
    method: 'DELETE',
  })

  return response as any
}
