import { ReactComponent as ArchiveSvg } from '@trinsly/common/src/assets/icons/archive.svg'
import Card from '@trinsly/common/src/components/atoms/card'
import Column from '@trinsly/common/src/components/atoms/column'
import Divider from '@trinsly/common/src/components/atoms/divider'
import Form from '@trinsly/common/src/components/atoms/form'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Input from '@trinsly/common/src/components/atoms/input'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Table from '@trinsly/common/src/components/atoms/table'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import useForm from '@trinsly/common/src/hooks/use-form'
import required from '@trinsly/common/src/validations/required'
import validEmail from '@trinsly/common/src/validations/valid-email'
import useCreateOrganizationMemberInvite from 'api-hooks/use-create-organization-member-invite'
import useDeleteOrganizationMemberInvite from 'api-hooks/use-delete-organization-member-invite'
import useGetUserSession from 'api-hooks/use-get-user-session'
import useGetUserSettings from 'api-hooks/use-get-user-settings'
import CopyInput from 'components/copy-input'
import React from 'react'
import { toast } from 'react-toastify'
import { canAddUser } from 'validators/paywall'

export type InviteNewMembersSection = {}

const InviteNewMembersSection = ({}: InviteNewMembersSection) => {
  const [inviteLink, setInviteLink] = React.useState('')
  const [canAddUsers, setCanAddUsers] = React.useState(false)
  const [cancelPendingMap, setCancelPendingMap] = React.useState<any>({})

  const { data: userSession, pending: userSessionPending } = useGetUserSession(
    {}
  )
  const { data: settings, pending: settingsPending } = useGetUserSettings()
  const { fetch: sendInvite, pending: sendInvitePending } =
    useCreateOrganizationMemberInvite({
      onSuccess: (data) => {
        toast.success('Invite sent!')
      },
      onError: (error) => {
        toast.error('Error inviting user')
      },
    })
  const { fetch: cancelInvite } = useDeleteOrganizationMemberInvite({
    onMutate: (data) => {
      setCancelPendingMap({ ...cancelPendingMap, [data.inviteId]: true })
    },
    onSuccess: (invite) => {
      setCancelPendingMap({ ...cancelPendingMap, [invite.id]: false })
    },
  })
  const { submit, getValue, setValue, reset } = useForm({
    onSubmit: ({ email }) => {
      sendInvite({ email: email, organizationId: settings.organization.id })
      reset()
    },
    validations: {
      email: [validEmail, required],
    },
  })

  React.useEffect(() => {
    if (!settingsPending && settings) {
      setInviteLink(
        `${window.location.origin || ''}/register?inviteToken=${
          settings.organization.inviteToken
        }`
      )
    }
  }, [settings, settingsPending])

  React.useEffect(() => {
    if (userSession && settings && !settingsPending && !userSessionPending) {
      setCanAddUsers(
        canAddUser(
          userSession.paywallInfo,
          settings.organization.memberships.filter((m) => !m.disabledAt).length
        )
      )
    }
  }, [settings, userSession, settingsPending, userSessionPending])

  const hasInvitationActive =
    settings && settings.invites && settings.invites.length > 0

  return canAddUsers ? (
    <>
      <Heading
        size="sm"
        fontWeight="bold"
        color="gray-500"
        className="uppercase"
      >
        Invite New Members
      </Heading>
      <Separator size="sm" />
      <Column>
        <CopyInput
          label="Invite teammate by link"
          name="inviteLink"
          value={inviteLink}
        />
        <Separator size="sm" />
        <Text size="sm">
          Prefer sending an invite? Enter team member's email below and hit
          send.
        </Text>
        <Separator size="xs" />
        <Form onSubmit={submit}>
          <Input
            name="email"
            onChange={(value) => setValue('email', value)}
            placeholder="Enter email"
            required
            type="email"
            value={getValue('email')}
          />
          <Separator />
          <Button
            shape="rounded"
            disabled={
              userSession?.paywallInfo?.status === 'paywalled' ||
              !getValue('email')
            }
            type="submit"
            intent="primary"
            variant="ghost"
            pending={sendInvitePending}
          >
            Send Invitation
          </Button>
        </Form>
      </Column>
      <Separator />
      {hasInvitationActive && (
        <Card className="border">
          <Column className="p-sm">
            <Text size="xs" className="uppercase" fontWeight="bold">
              Pending Invites
            </Text>
          </Column>
          <Divider />
          <Table
            data={settings.invites as Invite[]}
            pending={settingsPending}
            columns={[
              {
                width: 120,
                header: 'Email',
                align: 'left',
                cell: (item) => <Text fontWeight="bold">{item.email}</Text>,
              },
              {
                width: 100,
                header: 'Status',
                cell: (item) => (
                  <Text>
                    {item.state === 'initial' ? 'Pending' : 'Expired'}
                  </Text>
                ),
              },
              {
                width: 100,
                cell: (item) => {
                  return (
                    <Row className="items-center space-x-sm">
                      <Separator />

                      <Column className="min-w-28">
                        <Button
                          size="xs"
                          shape="rounded"
                          variant="outline"
                          intent="danger"
                          pending={cancelPendingMap[item.id]}
                          onClick={() => {
                            cancelInvite({
                              inviteId: parseInt(item.id.toString()),
                            })
                          }}
                        >
                          <Row className="items-center">
                            Cancel Invite
                            <Separator size="xs" />
                            <ArchiveSvg className="w-4" />
                          </Row>
                        </Button>
                      </Column>
                    </Row>
                  )
                },
              },
            ]}
          />
        </Card>
      )}
    </>
  ) : (
    <Row className="items-center gap-1">
      <Text size="sm">Need more seats? Contact support at</Text>
      <Text size="sm">
        <a
          className="text-primary-500 hover:text-primary-300 font-bold"
          href="mailto:help@trinsly.com?subject=Add a new member"
        >
          support@trinsly.com
        </a>
        .
      </Text>
    </Row>
  )
}

export default InviteNewMembersSection
