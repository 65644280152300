import api from '@trinsly/common/src/services/api'
import ProspectNoteType from 'types/prospect-note-type'

type DeleteProspectNoteServiceProps = {
  noteId: ProspectNoteType['id']
}

async function deleteProspectNoteService({
  noteId,
}: DeleteProspectNoteServiceProps) {
  const response = (await api(`/notes/${noteId}`, {
    method: 'DELETE',
  })) as any
  return
}

export default deleteProspectNoteService
