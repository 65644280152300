import { ReactComponent as PlugSvg } from '@trinsly/common/src/assets/icons/plus.svg'
import React from 'react'
import ReactSelect, { components, InputActionMeta } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { Option } from 'react-select/src/filters'
import Column from '../atoms/column'
import Row from '../atoms/row'
const colors = require('tailwindcss/colors')

export interface SelectProps<T = any> {
  id?: string
  placeholder?: string
  value?: T | null
  options?: T[]
  error?: string
  pending?: boolean
  disabled?: boolean
  creatable?: boolean
  clearable?: boolean
  onBlur?: () => void
  onFocus?: () => void
  onChange?: (value: T | null) => void
  onCreate?: (value: string) => void
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void
  itemRenderer?: (value: T, selected?: boolean) => React.ReactNode
  valueRenderer?: (value: T) => React.ReactNode
  formatCreateLabel?: (value: string) => React.ReactNode
  compareWith?: (option0: T, option1: T) => boolean
  filterWith?: ({
    inputValue,
    option,
  }: {
    inputValue: string
    option: T
  }) => boolean
}

function Select<T>({
  id,
  value,
  error,
  placeholder = 'Select...',
  options = [],
  pending = false,
  disabled = false,
  creatable = false,
  clearable = true,
  onCreate,
  onChange,
  onInputChange,
  onBlur,
  onFocus,
  filterWith,
  itemRenderer = (value) => value,
  valueRenderer,
  compareWith = (option0, option1) => option0 === option1,
  formatCreateLabel = (inputValue) => (
    <Row className="items-center gap-xs">
      <PlugSvg className="w-5 h-5 text-success-500" />
      <Column>
        {inputValue}
        <span className="uppercase tracking-wide" style={{ fontSize: 9 }}>
          (Create new)
        </span>
      </Column>
    </Row>
  ),
}: SelectProps<T>) {
  const portal =
    typeof document !== 'undefined'
      ? document?.getElementById('portal')
      : undefined

  function handleCustomFilter(option: Option, rawInput: string) {
    const isCreatableOption = option?.data?.__isNew__
    return (
      isCreatableOption ||
      filterWith?.({ inputValue: rawInput, option: option?.data?.value })
    )
  }

  const selectProps = {
    id: id ? `${id}-container` : undefined,
    inputId: id ? `${id}-input` : undefined,
    instanceId: id ? `${id}-instance` : undefined,
    loadingMessage: () => 'Loading...',
    noOptionsMessage: () => 'No data found.',
    classNamePrefix: 'select',
    className: 'w-full text-sm shadow-sm',
    menuPlacement: 'bottom',
    blurInputOnSelect: true,
    allowCreateWhileLoading: false,
    menuPortalTarget: portal,
    isLoading: pending,
    isDisabled: disabled,
    isClearable: clearable,
    placeholder: placeholder,
    onInputChange,
    formatCreateLabel,
    onBlur,
    onFocus,
    styles: getSelectStyles({ error }),
    value:
      value !== null && value !== undefined && (value as any) !== ''
        ? {
            value,
            label: itemRenderer(value),
          }
        : null,
    filterOption: filterWith ? handleCustomFilter : undefined,
    onChange: (selection: any) => {
      const isNew = selection?.__isNew__
      if (isNew) {
        onCreate?.(selection.value)
      } else {
        onChange?.(selection?.value ?? null)
      }
    },
    options: options.map((option) => {
      const isSelected = value ? compareWith(value, option) : false
      const label = itemRenderer(option, isSelected)
      const isText = typeof label === 'string' || typeof label === 'number'
      return {
        value: option,
        label: isText ? (
          <span
            className={`text-sm ${isSelected ? 'text-white' : 'text-gray-500'}`}
          >
            {label}
          </span>
        ) : (
          label
        ),
      }
    }),
    components: {
      SingleValue: ({ children, ...props }: any) => (
        <components.SingleValue {...props}>
          {valueRenderer && value ? valueRenderer(value) : children}
        </components.SingleValue>
      ),
    },
    theme: (theme: any) => ({
      ...theme,
      colors: {
        ...theme.colors,
        primary25: '#e6f3fd', //accent-50
        primary50: '#c0e0fa', //accent-100
        primary75: '#6db6f3', //accent-300
        primary: '#2e97ee', //accent-500
      },
    }),
  }

  return (
    <Column
      className={`
        ${baseClasses} 
        ${disabled ? disabledClasses : ''} 
        ${error && !disabled ? errorClasses : ''}
        ${!error && !disabled ? defaultClasses : ''}
      `}
    >
      {creatable ? (
        <CreatableSelect {...(selectProps as any)} />
      ) : (
        <ReactSelect {...(selectProps as any)} />
      )}
    </Column>
  )
}

export default Select

const baseClasses = `fix-select-internal-input text-sm text-gray-900 placeholder-gray-300 rounded-md border focus-within:ring transition`
const defaultClasses = `bg-gray-50 border-gray-300 focus-within:border-primary-300 focus-within:ring-primary-200`
const errorClasses = `bg-danger-50 bg-opacity-33 border-danger-300 focus-within:border-danger-300 focus-within:ring-danger-200`
const disabledClasses = `border-dashed border-gray-300 bg-white`

export const getSelectStyles = ({
  error,
}: {
  error?: SelectProps<any>['error']
}) => ({
  placeholder: (provided: any) => ({
    ...provided,
    color: colors.coolGray[300], //tailwind.config.ts => colors.neutral-75
  }),
  control: (provided: any) => ({
    ...provided,
    display: 'flex',
    border: 'none',
    padding: '0 0.7rem',
    outline: 'none',
    boxShadow: 'none',
    minHeight: '2.25 rem',
    background: 'inherit',
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    padding: 0,
    color: `${
      error
        ? colors.rose[400] //tailwind.config.ts => colors.danger-400
        : state.isDisabled
        ? colors.coolGray[300] //tailwind.config.ts => colors.neutral-75
        : colors.coolGray[500] //tailwind.config.ts => colors.neutral
    } !important`,
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    width: '1.7rem',
    height: '1.7rem',
    margin: '0 0.5rem',
    padding: '0.25rem',
    borderRadius: 999,
    cursor: 'pointer',
    color: `${colors.coolGray[500]} !important`, //tailwind.config.ts => colors.neutral-75
    backgroundColor: `${colors.coolGray[200]} !important`, //tailwind.config.ts => colors.neutral-50
  }),
  loadingIndicator: (provided: any) => ({
    ...provided,
    color: `${
      error
        ? colors.rose[400] //tailwind.config.ts => colors.danger-400
        : colors.coolGray[500] //tailwind.config.ts => colors.neutral
    } !important`,
    margin: '0 0.5rem',
  }),
  menuPortal: (provided: any) => ({
    ...provided,
    zIndex: 9999,
  }),
  menuList: (provided: any) => ({
    ...provided,
    padding: 0,
    color: colors.coolGray[500], //tailwind.config.ts => colors.neutral
    background: 'transparent',
  }),
  input: noStyles,
  container: noStyles,
  singleValue: noStyles,
  valueContainer: (provided: any) => ({
    ...noStyles(provided),
    overflow: 'unset',
  }),
  indicatorSeparator: noStyles,
})

const noStyles = (provided: any) => ({
  ...provided,
  padding: 0,
  color: 'inherit',
  background: 'transparent',
  cursor: 'inherit',
  transition: 'none',
  outline: 'none',
  boxShadow: 'none !important',
  border: 'none !important',
})
