import { ReactComponent as ChevronRightSvg } from '@trinsly/common/src/assets/icons/chevron-right.svg'
import { ReactComponent as CloseIcon } from '@trinsly/common/src/assets/icons/close.svg'
import Card from '@trinsly/common/src/components/atoms/card'
import Clickable from '@trinsly/common/src/components/atoms/clickable'
import Column from '@trinsly/common/src/components/atoms/column'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import Modal from '@trinsly/common/src/components/molecules/modal'

export type AddNewUserModalProps = {
  visible: boolean
  onRequestClose: () => void
}

const AddNewUserModal = ({ visible, onRequestClose }: AddNewUserModalProps) => {
  return (
    <Modal visible={visible} onRequestClose={onRequestClose}>
      <Card className="px-sm py-md">
        <Row className="justify-between">
          <Heading
            size="sm"
            fontWeight="bold"
            color="gray-500"
            className="uppercase"
          >
            You’re about to add another user
          </Heading>
          <Clickable
            className="rounded-full bg-gray-200 h-xl w-xl items-center justify-center"
            onClick={onRequestClose}
          >
            <CloseIcon className="w-5 h-5 text-gray-500" />
          </Clickable>
        </Row>
        <Row>
          <Text size="sm">
            Adding another user will increase your next monthly billing cycle
            total.
          </Text>
        </Row>
        <Separator />
        <Row>
          <Column className="w-1/2">
            <Text fontWeight="bold" color="gray-400" size="lg">
              Current plan
            </Text>
            <Text color="gray-500">user.plan($x.xx/per user)</Text>
          </Column>
          <Column className="w-1/2">
            <Text fontWeight="bold" color="gray-400" size="lg">
              Users
            </Text>
            <Row>
              <Text color="gray-500" className="line-through">
                current seats const
              </Text>
              <ChevronRightSvg className="w-md" />
              <Text color="gray-500">updated seats const</Text>
            </Row>
          </Column>
        </Row>
        <Separator />
        <Column className="border-dashed border-2 border-gray-800 h-36 rounded-md bg-gray-100 items-center justify-center">
          <Column className="items-end">
            <Row className="items-center justify-center">
              <Text color="gray-400" className="line-through text-5xl">
                $299.99
              </Text>
              <ChevronRightSvg className="w-xl text-gray-500" />
              <Text fontWeight="bold" color="primary-500" className="text-6xl">
                <Column className="items-center justify-center">$399.99</Column>
              </Text>
            </Row>
            <Column className="w-2/4">
              <Text color="success-500" className="text-2xl">
                (+$plan diff)
              </Text>
            </Column>
          </Column>
        </Column>
        <Separator />
        <Row className="justify-end">
          <Button size="lg" intent="primary" variant="outline">
            Manage users
          </Button>
          <Separator />
          <Button size="lg" intent="primary">
            Invite user
          </Button>
        </Row>
      </Card>
    </Modal>
  )
}

export default AddNewUserModal
