import { ReactComponent as CloseSvg } from '@trinsly/common/src/assets/icons/close.svg'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Button from '@trinsly/common/src/components/molecules/button'
import useCancelBatch from 'api-hooks/use-cancel-batch-service'
import { toast } from 'react-toastify'
import BatchType from 'types/batch-type'

type CancelBatchButtonProps = {
  batch: BatchType
}

function CancelBatchButton({ batch }: CancelBatchButtonProps) {
  const { fetch: cancelBatch, pending: pendingCancel } = useCancelBatch({
    onSuccess: (batch) => {
      toast.success('Batch canceled!')
    },
    onError: () => {},
  })

  return (
    <Button
      size="sm"
      variant="outline"
      pending={pendingCancel}
      onClick={(e) => {
        e.stopPropagation()
        if (
          window.confirm(
            'Depending on the number of email threads in the batch, it may take up to 1 hour to cancel. Are you sure you want to cancel all emails for this batch?'
          )
        ) {
          cancelBatch({ batchId: batch?.id })
        }
      }}
    >
      <Row className="items-center">
        <CloseSvg className="w-5 h-5" />
        <Separator size="xs" />
        Cancel
      </Row>
    </Button>
  )
}

export default CancelBatchButton
