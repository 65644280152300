import { useQuery } from 'react-query'
import { GetProjectCampaignsServiceProps } from 'services/get-project-campaigns-service'
import getProjectProspectsService from 'services/get-project-prospects-service'

type UseGetCampaignsProps = {} & GetProjectCampaignsServiceProps

function useGetProjectProspects({
  projectId,
  ...pageable
}: UseGetCampaignsProps) {
  const { data, error, status } = useQuery(
    ['/prospects', projectId, pageable],
    () => getProjectProspectsService({ projectId, ...pageable }),
    {
      enabled: Boolean(projectId),
    }
  )

  const prospects = data?.prospects || []

  return {
    data: prospects,
    total: data?.meta?.pagination?.totalObjects || 0,
    pending: status === 'loading',
    error,
  }
}

export default useGetProjectProspects
