import api from '@trinsly/common/src/services/api'
import { pickBy } from 'lodash'
import LeaderboardMemberStatsType from 'types/leaderboard-member-stats-type'

export type GetLeaderboardStatsServiceProps = {
  startDate: string
  endDate: string
  tags: string[]
}

async function getLeaderboardStatsService({
  startDate,
  endDate,
  tags,
}: GetLeaderboardStatsServiceProps) {
  const response = (await api(`/stats/leaderboard_stats.json`, {
    method: 'GET',
    params: pickBy({
      start_date: startDate,
      end_date: endDate,
      tags,
    }),
  })) as any

  return response as LeaderboardMemberStatsType[]
}

export default getLeaderboardStatsService
