import api from '@trinsly/common/src/services/api'
import CampaignType from 'types/campaign-type'

type ShareCampaignServiceProps = {
  campaignId: CampaignType['id']
  shared: boolean
}

async function shareCampaignService({
  campaignId,
  shared,
}: ShareCampaignServiceProps) {
  const response = await api(`/campaigns/${campaignId}`, {
    method: 'PUT',
    body: {
      campaign: {
        shared,
      },
    },
  })
  return response as CampaignType
}

export default shareCampaignService
