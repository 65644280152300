import api from '@trinsly/common/src/services/api'
import moment from 'moment'
import ScheduleType from 'screens/create-batch-screen/schedule-section/schedule-type'
import CampaignType from 'types/campaign-type'
import EmailTemplateType from 'types/email-template-type'
import ProspectType from 'types/prospect-type'
import ScheduleTimeSelectEnum from 'types/schedule-time-select-enum'

type SendCampaignServiceProps = {
  prospect: ProspectType
  campaign: CampaignType
  emails: EmailTemplateType[]
  schedules: ScheduleType[]
}

async function sendCampaignService({
  prospect,
  campaign,
  emails,
  schedules,
}: SendCampaignServiceProps) {
  const response = await api(`/email_threads`, {
    body: {
      email_thread: {
        prospect_id: prospect?.id, //should always be present in app
        email: prospect?.email,
        campaign_id: campaign?.id || null,
        linkedin_name: prospect?.linkedinName,
        source_url: !!prospect?.linkedinName
          ? `https://www.linkedin.com/in/${prospect?.linkedinName}/`
          : `https://app.trinsly.com/prospects/${prospect?.id}`,

        //TODO: check if this is needed:
        // linkedin_member_id: prospect?.linkedinMemberId,
        // bullhorn_candidate_id: prospect?.bu,
        //   suggested_variables: suggestedVariables,
        // variables: variablesForApi,

        emails: emails?.map?.((email, index) => {
          const { time, daysAfter } = schedules?.[index] || {}
          const { id, attachments, body, subject, position } = email
          const deliverAt = constructDeliverAtForApi(position, time, daysAfter)
          const initial = (email as any)?.initial as {
            subject: string
            body: string
          }

          return {
            position,
            body,
            subject,
            attachments,
            deliver_at: deliverAt,
            modified: subject !== initial?.subject || body !== initial?.body,
            email_message_template_id: id || null, // need to account for adding follow-ups
          }
        }),
      },
    },
    method: 'POST',
  })

  return response as any
}

export default sendCampaignService

function constructDeliverAtForApi(
  emailPosition: number,
  time: number,
  daysAfter: number
): string {
  if (
    emailPosition === 1 &&
    time === ScheduleTimeSelectEnum.UNMODIFIED_VALUE &&
    +daysAfter === 0
  )
    return moment().add(30, 'seconds').toISOString()

  const hoursOffset =
    time === ScheduleTimeSelectEnum.UNMODIFIED_VALUE
      ? getRandomTimeWithinWindow(moment().get('hour'))
      : getRandomTimeWithinWindow(+time)

  return moment()
    .startOf('day')
    .add(daysAfter, 'd')
    .add(hoursOffset, 'h')
    .toISOString()
}

function getRandomTimeWithinWindow(startTime: number, hoursWindow = 2) {
  const endTime = startTime + hoursWindow
  return Math.random() * (endTime - startTime) + startTime
}
