import Tag from '@trinsly/common/src/components/atoms/tag'
import React from 'react'
import ProspectType, {
  ProspectStatusEnum,
  translateProspectStatusEnum,
} from 'types/prospect-type'

type StatusFieldProps = {
  status: ProspectType['status']
}

function StatusField({ status }: StatusFieldProps) {
  return (
    <React.Fragment>
      {
        {
          [ProspectStatusEnum.ACTIVE]: (
            <Tag textColor="white" backgroundColor="#00C853">
              {translateProspectStatusEnum[status]}
            </Tag>
          ),
          [ProspectStatusEnum.DO_NOT_CONTACT]: (
            <Tag textColor="white" backgroundColor="#F44336">
              {translateProspectStatusEnum[status]}
            </Tag>
          ),
          [ProspectStatusEnum.HARD_BOUNCED]: (
            <Tag textColor="white" backgroundColor="#F4B71A">
              {translateProspectStatusEnum[status]}
            </Tag>
          ),
          [ProspectStatusEnum.INACTIVE]: (
            <Tag textColor="white" backgroundColor="#696969">
              {translateProspectStatusEnum[status]}
            </Tag>
          ),
        }[status]
      }
    </React.Fragment>
  )
}

export default StatusField
