import api from '@trinsly/common/src/services/api'
import BatchType from 'types/batch-type'

type CancelBatchServiceProps = {
  batchId: number
}

async function cancelBatchService({ batchId }: CancelBatchServiceProps) {
  const response = (await api(`/batches/${batchId}/cancel`, {
    method: 'POST',
  })) as any
  return response as BatchType
}

export default cancelBatchService
