import React from 'react'
import { ReactComponent as ArrowLeftSvg } from '../../assets/icons/arrow-left.svg'
import Column from '../atoms/column'
import Heading from '../atoms/heading'
import Row from '../atoms/row'
import Separator from '../atoms/separator'
import Text from '../atoms/text'
import IconButton from './icon-button'

export type BreadcrumbsProps = {
  intent?: 'white'
  steps: React.ReactNodeArray
  onBack?: () => void
}

function Breadcrumbs({ intent, steps, onBack }: BreadcrumbsProps) {
  const [lastStep, ...stepsWithoutLast] = [...steps].reverse()
  const shouldShowBackButton = Boolean(onBack) && stepsWithoutLast.length > 0
  return (
    <Row className="items-center">
      {shouldShowBackButton && (
        <React.Fragment>
          <IconButton
            variant="outline"
            shape="circle"
            intent={intent}
            onClick={onBack}
          >
            <ArrowLeftSvg />
          </IconButton>
          <Separator size="sm" />
        </React.Fragment>
      )}
      <Column>
        <Row>
          {[...stepsWithoutLast].reverse().map((step, index) => (
            <React.Fragment key={index}>
              {index > 0 && (
                <React.Fragment>
                  <Separator size="xs" />
                  <Text
                    size="sm"
                    color={textIntentColorMap[intent || 'undefined']}
                    className="text-opacity-50"
                  >
                    /
                  </Text>
                  <Separator size="xs" />
                </React.Fragment>
              )}
              <Text
                size="sm"
                fontWeight="light"
                color={textIntentColorMap[intent || 'undefined']}
              >
                {step}
              </Text>
            </React.Fragment>
          ))}
        </Row>
        <Heading
          size="lg"
          fontWeight="bold"
          color={headingIntentColorMap[intent || 'undefined']}
        >
          {lastStep}
        </Heading>
      </Column>
    </Row>
  )
}

export default Breadcrumbs

const textIntentColorMap: any = {
  white: 'white',
  undefined: 'gray-500',
}
const headingIntentColorMap: any = {
  white: 'white',
  undefined: 'gray-900',
}
