import { ReactComponent as CheckSvg } from '@trinsly/common/src/assets/icons/check.svg'
import { ReactComponent as CloseSvg } from '@trinsly/common/src/assets/icons/close.svg'
import { ReactComponent as PlusSvg } from '@trinsly/common/src/assets/icons/plus.svg'
import Badge from '@trinsly/common/src/components/atoms/badge'
import Column from '@trinsly/common/src/components/atoms/column'
import Form from '@trinsly/common/src/components/atoms/form'
import Row from '@trinsly/common/src/components/atoms/row'
import IconButton from '@trinsly/common/src/components/molecules/icon-button'
import useForm from '@trinsly/common/src/hooks/use-form'
import required from '@trinsly/common/src/validations/required'
import useUpdateProspect from 'api-hooks/use-update-prospect'
import React from 'react'
import { toast } from 'react-toastify'
import MultiSelectTags from 'screens/projects-screen/project-form-modal/multi-select-tags'
import ProspectType from 'types/prospect-type'
import { useFormContext } from '../form-provider'

type TagsSectionProps = {}

function TagsSection({}: TagsSectionProps) {
  const { prospect } = useFormContext()
  const [viewState, setViewState] = React.useState<'detail' | 'form'>('detail')
  const { fetch: updateProspect, pending: pendingUpdate } = useUpdateProspect({
    onSuccess: () => {
      toast.success('Prospect updated!')
      setViewState('detail')
    },
    onError: (error) => {
      toast.error('Error updating project')
    },
  })
  const { setValue, getValue, submit, reset, setValues } = useForm({
    initialValues: {
      ...prospect,
    },
    onSubmit: (prospect: ProspectType) => {
      console.log(prospect)
      updateProspect({ prospect })
    },
    validations: {
      name: [required],
    },
  })
  React.useEffect(() => {
    if (viewState === 'form') {
      reset()
      setValues(prospect || {})
    }
  }, [viewState])

  React.useEffect(() => {
    setValues(prospect || {})
  }, [prospect?.id])

  return (
    <Form onSubmit={submit}>
      <Row className="gap-xs uppercase">
        {
          {
            form: (
              <Row className="items-center gap-xs flex-1">
                <Column className="min-w-48">
                  <MultiSelectTags
                    placeholder="Select Tags..."
                    creatable={true}
                    values={getValue('tagList') || []}
                    onChange={(tags) => setValue('tagList', tags)}
                    onCreate={(value) => {
                      const newTags = [...(getValue('tagList') || []), value]
                      setValue('tagList', newTags)
                    }}
                  />
                </Column>

                <Row className="gap-xs">
                  <IconButton
                    type="submit"
                    size="sm"
                    iconSize="md"
                    intent="success"
                    variant="transparent"
                    pending={pendingUpdate}
                  >
                    <CheckSvg />
                  </IconButton>
                  <IconButton
                    size="sm"
                    iconSize="md"
                    intent="danger"
                    variant="transparent"
                    onClick={() => setViewState('detail')}
                  >
                    <CloseSvg />
                  </IconButton>
                </Row>
              </Row>
            ),
            detail: (
              <Row className="flex-wrap gap-xs">
                {prospect?.tagList.map((tag) => (
                  <Badge size="xs" intent="primary">
                    {tag}
                  </Badge>
                ))}
              </Row>
            ),
          }[viewState]
        }
        <Row>
          {
            {
              form: <></>,
              detail: (
                <Row>
                  <IconButton
                    onClick={() => setViewState('form')}
                    size="xxs"
                    intent="primary"
                    variant="outline"
                    shape="circle"
                  >
                    <PlusSvg />
                  </IconButton>
                </Row>
              ),
            }[viewState]
          }
        </Row>
      </Row>
    </Form>
  )
}

export default TagsSection
