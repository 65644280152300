import Card from '@trinsly/common/src/components/atoms/card'
import Column from '@trinsly/common/src/components/atoms/column'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Row from '@trinsly/common/src/components/atoms/row'
import ButtonGroup from '@trinsly/common/src/components/atoms/tabs/button-group'
import SearchInput from '@trinsly/common/src/components/molecules/search-input'
import usePageableState from '@trinsly/common/src/hooks/use-pageable-state'
import ThreadStateEnum from '@trinsly/common/src/types/thread-state-enum-type'
import useGetThreads from 'api-hooks/use-get-threads'
import ThreadType from 'types/thread-type'
import ReachOutsTable from './reach-outs-table'

function ReachOutsSection() {
  const { pageable, setSearch, setFilters, nextPage, prevPage } =
    usePageableState({
      page: 1,
      perPage: 10,
    })
  const { data: threads, total, pending } = useGetThreads(pageable)

  return (
    <Column className="gap-sm">
      <Row className="items-center gap-sm justify-between">
        <Row className="flex-1 items-center gap-sm justify-between">
          <Heading size="xl" fontWeight="bold">
            Reach Outs
          </Heading>
          <Column className="flex-1" style={{ maxWidth: 360 }}>
            <SearchInput
              id="search"
              placeholder="Search prospect reach outs..."
              value={pageable?.search}
              onChange={(value) => setSearch(value)}
            />
          </Column>
        </Row>
        <ButtonGroup
          id="thread-state"
          variant="state"
          defaultTab="all"
          onChange={(tabId) => {
            setFilters('state', tabId === 'all' ? undefined : tabId)
          }}
        >
          <ButtonGroup.Button id="all">All</ButtonGroup.Button>
          <ButtonGroup.Button id={ThreadStateEnum.IN_PROGRESS}>
            In Progress
          </ButtonGroup.Button>
          <ButtonGroup.Button id={ThreadStateEnum.HARD_BOUNCED}>
            Hard Bounced
          </ButtonGroup.Button>
          <ButtonGroup.Button id={ThreadStateEnum.RESPONDED}>
            Responded
          </ButtonGroup.Button>
          <ButtonGroup.Button id={ThreadStateEnum.COMPLETED}>
            Unresponded
          </ButtonGroup.Button>
        </ButtonGroup>
      </Row>
      <Card className="overflow-hidden">
        <ReachOutsTable
          threads={threads}
          pending={pending}
          pagination={{
            page: pageable?.page,
            perPage: pageable?.perPage,
            total,
          }}
          onNextPage={nextPage}
          onPrevPage={prevPage}
        />
      </Card>
    </Column>
  )
}

export default ReachOutsSection

//TODO: this should not be here
export const getThreadStateCounts = (
  thread: ThreadType
): { total: number; delivered: number; scheduled: number } => {
  if (!thread) return { total: 0, delivered: 0, scheduled: 0 }

  const totalEmails = thread.emails.length

  //@ts-ignore
  const delivered = thread.emails.filter(
    (e) => e.state === 'delivered' || e.state === 'responded'
  )
  //@ts-ignore
  const scheduled = thread.emails.filter((e) => e.state === 'scheduled')

  return {
    total: totalEmails,
    delivered: delivered.length,
    scheduled: scheduled.length,
  }
}
