import api from '@trinsly/common/src/services/api'
import PageableType from '@trinsly/common/src/types/pageable-type'
import CampaignType from 'types/campaign-type'
import ProjectType from 'types/project-type'
import { remoteCampaignToCampaign } from './get-campaign-service'

export type GetProjectCampaignsServiceProps = {
  projectId: ProjectType['id']
} & PageableType

async function getProjectCampaignsService({
  projectId,
  ...pageable
}: GetProjectCampaignsServiceProps) {
  const orderBy = Object.keys(pageable?.sort || {})?.[0]
  const orderDirection = Object.values(pageable?.sort || {})?.[0]
  const hasSort = orderBy && orderDirection
  const response = await api(`/campaigns`, {
    method: 'GET',
    params: {
      project_id: projectId,
      query: pageable?.search,
      page: pageable?.page,
      per_page: pageable?.perPage,
      sort: hasSort ? `${orderBy} ${orderDirection}` : '',
    },
  })

  const { meta, campaigns: remoteCampaigns } = response as any

  return {
    meta,
    campaigns: remoteCampaigns?.map(remoteCampaignToCampaign) || [],
  } as {
    campaigns: CampaignType[]
    meta: { pagination: Pagination }
  }
}

export default getProjectCampaignsService
