import React from 'react'
import {
  Column,
  useExpanded,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'
import { ReactComponent as ChevronRightSvg } from '../../assets/icons/chevron-right.svg'
import { ReactComponent as SortAscSvg } from '../../assets/icons/sort-asc.svg'
import { ReactComponent as SortDescSvg } from '../../assets/icons/sort-desc.svg'
import Clickable from './clickable'
import Row from './row'
import Spinner from './spinner'

type ColumnType<T extends Object> = {
  id?: string
  header?: React.ReactNode
  sort?: string
  onSort?: (sortDirection: string) => void
  cell: (original: T, data: any, index: number) => React.ReactNode
  align?: 'left' | 'right'
  className?: string
  width?: number
}

export interface TableProps<T extends Object> {
  id?: string
  data?: T[]
  columns?: (ColumnType<T> | null)[]
  pending?: boolean
  expandedRow?: (original: T, data: any) => React.ReactNode
  onToggleExpand?: (expanded: boolean, original: T) => void
  rows?: (original: T, data: any) => Object | undefined
  filter?: string
  pageSize?: number
}

const Table = <T extends Object>({
  id,
  data = [],
  columns = [],
  pending,
  expandedRow,
  rows: rowsProps,
  onToggleExpand,
  filter,
  pageSize = 10,
}: TableProps<T>) => {
  const validColumns = columns.filter((column) =>
    Boolean(column)
  ) as ColumnType<T>[]
  const columnsWithId = validColumns.map<ColumnType<T>>((col, index) => ({
    ...col,
    id: col.id ?? `index-${index}`,
  }))
  const sizeMap = columnsWithId.reduce<Record<string, object>>(
    (acc: any, col: any) => ({
      ...acc,
      [col.id]: {
        style: Boolean(col.width)
          ? { minWidth: col.width, maxWidth: col.width }
          : { width: '100%' },
      },
    }),
    {}
  )

  const memoizedColumns = React.useMemo(() => {
    const expandableColumn = {
      id: EXPANDABLE_COLUMN_ID,
      Header: <></>,
      Cell: (data: any) => {
        const isExpanded = data?.row?.isExpanded || false

        React.useEffect(() => {
          onToggleExpand?.(isExpanded, data?.row?.original as T)
        }, [isExpanded])

        return (
          <div className="flex items-center justify-center w-xl">
            <ChevronRightSvg
              className={`w-md h-md text-gray-500 transition transform ${
                isExpanded ? 'rotate-90' : ''
              }`}
            />
          </div>
        )
      },
    }

    // @ts-ignore
    const columns = columnsWithId.map<Column<T>>((col) => ({
      id: col.id,
      className: col.className,
      accessor: (col.id || '') as any,
      Header: col?.header ?? '',
      Cell: (data: any) =>
        col.cell(data?.row?.original as T, data, Number(data?.cell?.row?.id)),
      sort: col.sort || '',
      onSort: col.onSort,
    }))

    const expandableTable = Boolean(expandedRow)
    if (expandableTable) {
      return [expandableColumn, ...columns]
    }

    return columns
  }, [columns])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    //@ts-ignore
    page,
    //@ts-ignore
    setPageSize,
    //@ts-ignore
    setGlobalFilter,
  } = useTable(
    {
      columns: memoizedColumns,
      data,
      //@ts-ignore
      initialState: {
        //@ts-ignore
        pageSize: pageSize,
      },
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination
  )

  React.useEffect(() => {
    setGlobalFilter(filter)
  }, [filter])

  return (
    <div className="w-full overflow-auto">
      <table id={id} {...getTableProps()} className="w-full">
        <thead>
          {headerGroups.map((headerGroup: any, index: number) => (
            <tr
              key={index}
              {...headerGroup.getHeaderGroupProps()}
              className="h-12"
            >
              {headerGroup.headers.map((column: any, index: number) => {
                return (
                  <th
                    key={index}
                    className="bg-white border-b border-gray-200 p-0"
                    {...column.getHeaderProps([{ ...sizeMap[column.id] }])}
                    onClick={() =>
                      column.onSort?.(
                        !column.sort
                          ? 'asc'
                          : column.sort === 'asc'
                          ? 'desc'
                          : ''
                      )
                    }
                  >
                    <Clickable
                      intent="transparent"
                      className="w-full ring-inset"
                      disabled={!Boolean(column.onSort)}
                    >
                      <Row className="items-center flex-1 px-sm py-xs min-h-12 space-x-xs text-xs font-bold uppercase text-gray-500">
                        {column.render('Header')}
                        {column.sort === 'asc' && (
                          <SortDescSvg className="w-md min-w-md h-md text-gray-500" />
                        )}
                        {column.sort === 'desc' && (
                          <SortAscSvg className="w-md min-w-md h-md text-gray-500" />
                        )}
                      </Row>
                    </Clickable>
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {pending && (
            <tr>
              <td colSpan={columns.length} className="px-sm py-xs">
                <Row className="justify-center">
                  <Spinner />
                </Row>
              </td>
            </tr>
          )}
          {page?.map((row: any, index: number) => {
            const shouldTint = index % 2 === 0
            const isExpanded = row.isExpanded || false
            const expandedRowNode = expandedRow?.(row.original, row)
            const canExpand = Boolean(expandedRowNode)
            prepareRow(row)
            const rowProps = rowsProps?.(row.original as T, row)
            return (
              <React.Fragment key={index}>
                <tr
                  {...row.getRowProps(rowProps)}
                  className={`h-16 ${
                    isExpanded ? 'border-b border-gray-200' : ''
                  } ${shouldTint ? 'bg-primary-25' : 'bg-white'}`}
                >
                  {row.cells.map((cell: any, index: number) => {
                    const isExpandableColumn =
                      cell.column.id === EXPANDABLE_COLUMN_ID
                    const cellClasses = cell.column.className
                      ? cell.column.className
                      : isExpandableColumn
                      ? 'p-0 m-0'
                      : 'px-sm py-xs justify-center'
                    return (
                      <td
                        key={index}
                        {...cell.getCellProps()}
                        {...(canExpand && isExpandableColumn
                          ? { ...row.getToggleRowExpandedProps() }
                          : {})}
                        className={`text-sm ${cellClasses}`}
                      >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
                {isExpanded && (
                  <tr
                    {...row.getRowProps(rowsProps?.(row.original as T, row))}
                    className={`${
                      isExpanded ? 'border-b border-gray-200' : ''
                    }`}
                  >
                    <td colSpan={row.cells.length} className="p-0 m-0 bg-white">
                      {expandedRow?.(row.original as T, row)}
                    </td>
                  </tr>
                )}
              </React.Fragment>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default Table

const EXPANDABLE_COLUMN_ID = 'expander'
