import api from '@trinsly/common/src/services/api'
import Editor from '@trinsly/editor'
import { pickBy } from 'lodash'
import React from 'react'

type File = {
  lastModified: number
  lastModifiedDate: any
  name: string // "cat-dog.jpg"
  preview?: string // "blob:http://localhost:3000/c3f6768f-09c6-4be9-b8a3-4c20253e0098"
  size: number
  type: number // "image/jpeg"
  webkitRelativePath: string
}

const s3BucketName = process.env.REACT_APP_S3_BUCKET_NAME || ''
const s3Url = `https://${s3BucketName}.s3.amazonaws.com`
var qs = require('qs')

function TinyMCEEditor({
  disabled,
  onChange,
  value,
}: {
  onChange?: (value: string) => void
  value: string
  disabled?: boolean
}) {
  const getAwsSignedUrl = React.useCallback(
    (file: File, callback: Function) => {
      const searchParams = qs.stringify(
        pickBy({
          content_type: file.type,
          object_name: file.name,
        }) as any
      )
      api(`${process.env.REACT_APP_API_URL}/api/v1/s3/sign?${searchParams}`, {
        method: 'GET',
      }).then(callback as (value: any) => any)
    },
    []
  )

  return (
    <Editor
      disabled={disabled}
      getAwsSignedUrl={getAwsSignedUrl}
      init={{
        content_css: false,
        contextmenu: false,
        height: 360,
        images_reuse_filename: true,
        menubar: false,
        paste_data_images: true,
        paste_remove_styles_if_webkit: false,
        paste_retain_style_properties: 'all',
        paste_webkit_styles: 'all',
        plugins: [
          'advlist autolink lists link image charmap print preview code',
          'insertdatetime media table paste imagetools wordcount',
        ],
        resize: false,
        visualblocks_default_state: true,
      }}
      onChange={(value) => onChange?.(value)}
      s3Url={s3Url}
      value={value}
    />
  )
}

export default TinyMCEEditor
