import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js'
import React from 'react'
import { Line } from 'react-chartjs-2'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)
export interface LineChartProps {
  labels: string[]
  datasets: {
    label: string
    data: number[]
    borderColor: string
    backgroundColor: string
    borderDash?: number[]
  }[]
}

function LineChart({ labels, datasets }: LineChartProps) {
  const structure = {
    labels,
    datasets,
  }
  return <Line options={options} data={structure} />
}

export default LineChart

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'bottom' as const,
      display: true,
    },
    title: {
      display: false,
    },
  },
  scales: {
    y: {
      ticks: {
        min: 0,
        stepSize: 1,
      },
      beginAtZero: true,
    },
  },
}
