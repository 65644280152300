import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js'
import React from 'react'
import { Bar } from 'react-chartjs-2'
import Spinner from '../atoms/spinner'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

interface BarChartProps {
  labels: string[]
  datasets: {
    label: string
    data: number[]
    borderColor: string
    backgroundColor: string
    borderDash?: number[]
  }[]
  pending?: boolean
}

function BarChart({ labels, datasets, pending }: BarChartProps) {
  const structure = {
    labels,
    datasets,
  }
  return (
    <div className="relative">
      {pending && (
        <div
          className="absolute"
          style={{ top: 'calc(50% - 24px)', left: 'calc(50% - 24px)' }}
        >
          <Spinner />
        </div>
      )}
      <Bar options={options} data={structure} />
    </div>
  )
}

export default BarChart

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'right' as const,
      display: true,
    },
    title: {
      display: false,
    },
  },
  interaction: {
    mode: 'index' as const,
    intersect: false,
  },
  scales: {
    y: {
      ticks: {
        min: 0,
        stepSize: 1,
      },
      beginAtZero: true,
    },
  },
}
