import QueryApiHookType from '@trinsly/common/src/types/query-api-hook-type'
import { useQuery } from 'react-query'
import getCampaignService, {
  GetCampaignServiceProps,
} from 'services/get-campaign-service'
import CampaignType from 'types/campaign-type'

export type UseGetCampaignProps = {} & GetCampaignServiceProps &
  QueryApiHookType<CampaignType>

function useGetCampaign({
  campaignId,
  onError,
  onSuccess,
}: UseGetCampaignProps) {
  const { data, error, status, isFetching } = useQuery(
    ['/campaigns/{id}', campaignId],
    () => getCampaignService({ campaignId }),
    {
      enabled: !!campaignId,
      staleTime: !campaignId ? 0 : undefined,
      onError,
      onSuccess,
    }
  )

  return {
    data,
    pending: status === 'loading',
    error,
    isFetching,
  }
}

export default useGetCampaign
