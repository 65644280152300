import { ReactComponent as CheckSvg } from '@trinsly/common/src/assets/icons/check.svg'
import { ReactComponent as MinusSvg } from '@trinsly/common/src/assets/icons/minus.svg'
import { ReactComponent as PlusSvg } from '@trinsly/common/src/assets/icons/plus.svg'
import Card from '@trinsly/common/src/components/atoms/card'
import Column from '@trinsly/common/src/components/atoms/column'
import Divider from '@trinsly/common/src/components/atoms/divider'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Label from '@trinsly/common/src/components/atoms/label'
import Link from '@trinsly/common/src/components/atoms/link'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Spinner from '@trinsly/common/src/components/atoms/spinner'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import IconButton from '@trinsly/common/src/components/molecules/icon-button'
import TextInput from '@trinsly/common/src/components/molecules/text-input'
import useGetCurrentSubscriptions from 'api-hooks/use-get-current-subscriptions'
import useGetUserSession from 'api-hooks/use-get-user-session'
import useUpdateSubscriptionSeats from 'api-hooks/use-update-subscription-seats'
import { ReactComponent as TrinslyLogoSvg } from 'assets/logo.svg'
import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

/**
 *
 *
 * This will be done through the server
 *
 * Existing account add seats -> prorate and reset billing cycle (may have multiple subccriptions to calc seat count)
 *  - prorate a bill today + charge
 *  - next invoice will be 1st of next month
 *
 *
 * Annual plans with no stripe sub -> must contact us for time being
 *
 * Downgrades -> must contact us
 */

export const PRICE_MAP = {
  monthly: 9900,
  annual: 95000,
}
const perUserCost = () => {
  return (PRICE_MAP['monthly'] / 100).toFixed(2)
}
//plan.usageType = 'licensed' vs 'metered' ()
//licensed 'quantity' vs. user plan.seat_count_limit (precedent)
//if plan.id === our growth tier already then we increase quantity
//if no plan.id is our growth tier, then we add new item to subscription
function AddUserForm() {
  const [seatCount, setSeatCount] = useState(0)

  const [contactSupport, setContactSupport] = useState(false)
  //TODO:
  const [currentTab, setCurrentTab] = useState<'monthly' | 'annual'>('monthly')
  const [costEstimate, setCostEstimate] = useState('')
  const [totalEstimate, setTotalEstimate] = useState('')
  const [subscriptionId, setSubscriptionId] = useState('')

  const { data: currentSubscriptions, pending: pendingCurrentSubscriptions } =
    useGetCurrentSubscriptions({})
  const { data: userSession, pending: userPending } = useGetUserSession({})

  //added cost
  useEffect(() => {
    const price = PRICE_MAP[currentTab]

    if (price) {
      const totalInCents = price * seatCount

      const cost = (totalInCents / 100).toFixed(2)
      setCostEstimate(cost)
    }
  }, [currentTab, seatCount])

  //set monthly subscriptionId if exists
  useEffect(() => {
    if (!!currentSubscriptions) {
      const monthlySub = currentSubscriptions.filter(
        (s) => s.interval === 'month'
      )
      if (monthlySub.length === 1) {
        setSubscriptionId(monthlySub[0].id)
      }
    }
  }, [currentSubscriptions])

  //current cost
  const currentPayments = useCallback(() => {
    if (currentSubscriptions.length === 0) return 0
    const monthlySub = currentSubscriptions.filter(
      (s) => s.interval === 'month'
    )

    if (monthlySub.length > 1) {
      //more than 1 monthly sub - have them contact support
      setContactSupport(true)
      return
    }

    const seatBased = monthlySub[0].items.filter(
      (i) => i.usageType === 'licensed'
    )

    //some legacy customers have more than 1 kind of seatbased item on sub
    const totalCurrentSeatBasedPayments = seatBased
      .map((i) => i.quantity * i.price.unitAmount)
      .reduce((prev, current) => prev + current, 0)

    const monthlyPayment = (totalCurrentSeatBasedPayments / 100).toFixed(2)

    return monthlyPayment
  }, [userSession, currentSubscriptions])

  //total cost
  useEffect(() => {
    if (!!userSession && !!currentSubscriptions) {
      if (currentSubscriptions.length > 0) {
        if (seatCount === 0) return
        const totalCost = parseInt(costEstimate) + parseInt(currentPayments())
        setTotalEstimate(totalCost.toFixed(2).toString())
      } else {
        setTotalEstimate(costEstimate)
      }
    }
  }, [costEstimate, currentPayments, userSession, currentSubscriptions])

  const { fetch: updateSeats, pending: pendingUpdate } =
    useUpdateSubscriptionSeats({
      onError: (error) => {
        console.debug(error)
        toast.error('Unable to purchase additional seats at this time')
      },
      onSuccess: (data) => {
        console.debug(data)
        toast.success('Successfully added new seats')
      },
    })

  return (
    <Column>
      <Row className="justify-center flex">
        <PricingReviewCard />
        <Separator />
        <Card className="p-8 drop-shadow-lg">
          <Column className="gap-y-sm">
            {true ? (
              <Row className="text-center">
                <Label>
                  Get in touch -{' '}
                  <a href="mailto:hello@chatkick.com" target={'_blank'}>
                    <Text
                      fontWeight="semibold"
                      color="primary-700"
                      className="underline"
                    >
                      hello@chatkick.com
                    </Text>
                  </a>
                </Label>
              </Row>
            ) : (
              <>
                <Heading size="2xl" fontWeight="bold">
                  {!totalEstimate
                    ? 'Need Additional Seats?'
                    : `Your cost today: $${totalEstimate}`}
                </Heading>
                {totalEstimate && (
                  <Text className="pl-8 italic -m-4" size="xs">
                    Billed {currentTab}
                  </Text>
                )}

                {pendingCurrentSubscriptions || userPending ? (
                  <Spinner />
                ) : (
                  <>
                    <Separator size="sm" />
                    <Text className="pl-4">
                      Current Monthly Cost: ${currentPayments()}
                    </Text>

                    <Separator size="xs" />
                    <Text className="pl-4" fontWeight="semibold">
                      Additional Seats: {seatCount} x ${perUserCost()} = $
                      {costEstimate}
                    </Text>

                    <Separator />
                    <Row className="max-w-17">
                      <IconButton
                        children={<MinusSvg />}
                        disabled={pendingUpdate}
                        onClick={() => {
                          const seats = seatCount - 1
                          const noNegatives = seats > -1 ? seats : 0
                          setSeatCount(noNegatives)
                        }}
                      />{' '}
                      <Separator />
                      <TextInput
                        id="seat-count"
                        type="number"
                        value={seatCount}
                        disabled
                        color="black"
                        style={{
                          fontWeight: 'bold',
                          color: 'black',
                          fontSize: '14px',
                          textAlign: 'center',
                        }}
                      ></TextInput>
                      <Separator />
                      <IconButton
                        children={<PlusSvg />}
                        disabled={pendingUpdate}
                        onClick={() => {
                          setSeatCount(seatCount + 1)
                        }}
                      />
                    </Row>
                    <Separator />
                  </>
                )}

                <Button
                  onClick={async () =>
                    await updateSeats({ quantity: seatCount, subscriptionId })
                  }
                  shape="rounded"
                  intent="primary"
                  size="lg"
                  className="w-full"
                  pending={pendingUpdate}
                  disabled={!costEstimate}
                >
                  Checkout
                </Button>
              </>
            )}
          </Column>
        </Card>
      </Row>
    </Column>
  )
}

export default AddUserForm

export function PricingReviewCard() {
  return (
    <Card className="p-8 drop-shadow-lg">
      <Column>
        <TrinslyLogoSvg className="w-44 h-12" />
        <Separator size="sm" />
        <Heading>${perUserCost()} per user/month</Heading>
        <Separator size="sm" />
        <Divider />
        <Separator size="sm" />
        <Row>
          <CheckSvg className="fill-success-500 h-6" />
          <Separator size="xxs" />
          <Text fontWeight="semibold">300 Contact Find Credits</Text>
        </Row>
        <Separator size="sm" />
        <Row>
          <CheckSvg className="fill-success-500 h-6" />
          <Separator size="xxs" />
          <Text fontWeight="semibold">{'GMail & Outlook Integrations'}</Text>
        </Row>
        <Separator size="sm" />
        <Row>
          <CheckSvg className="fill-success-500 h-6" />
          <Separator size="xxs" />
          <Text fontWeight="semibold">Unlimited Outreach</Text>
        </Row>

        <Separator size="sm" />
        <Row>
          <CheckSvg className="fill-success-500 h-6" />
          <Separator size="xxs" />
          <Text fontWeight="semibold">ATS Integrations</Text>
        </Row>
        <Separator size="sm" />
        <Row>
          <CheckSvg className="fill-success-500 h-6" />
          <Separator size="xxs" />
          <Text fontWeight="semibold">{'Standard Analytics & Reporting'}</Text>
        </Row>
        <Separator size="sm" />
        <Row>
          <CheckSvg className="fill-success-500 h-6" />
          <Separator size="xxs" />
          <Text fontWeight="semibold">Email Support</Text>
        </Row>
      </Column>
      <Separator />
      <Row className="justify-center">
        <Button
          intent="info"
          variant="ghost"
          onClick={() => {
            window.open('https://chatkick.com/pricing', '_blank')
          }}
        >
          View More Pricing Details
        </Button>
      </Row>
      <Separator />
      <Row className="justify-center">
        <Link intent="info" href="mailto:hello@chatkick.com" target={'_blank'}>
          Need a custom plan? Get in touch
        </Link>
      </Row>
    </Card>
  )
}
