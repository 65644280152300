import { ReactComponent as CloseSvg } from '@trinsly/common/src/assets/icons/close.svg'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Button from '@trinsly/common/src/components/molecules/button'
import React from 'react'
import CampaignType from 'types/campaign-type'
import StopAllReachOutsConfirmationModal from './stop-all-reach-outs-confirmation-modal'

type StopAllReachOutsButtonProps = {
  campaignId: CampaignType[`id`]
  disabled: boolean
}

function StopAllReachOutsButton({
  campaignId,
  disabled,
}: StopAllReachOutsButtonProps) {
  const [visible, setVisible] = React.useState(false)

  return (
    <React.Fragment>
      <Button
        size="sm"
        variant="outline"
        intent="danger"
        disabled={disabled}
        onClick={() => {
          setVisible(true)
        }}
      >
        <Row className="items-center whitespace-nowrap">
          <CloseSvg className="w-4 h-4" />
          <Separator size="xs" />
          Stop All
        </Row>
      </Button>
      <StopAllReachOutsConfirmationModal
        campaignId={campaignId}
        visible={visible}
        onRequestClose={() => setVisible(false)}
      />
    </React.Fragment>
  )
}

export default StopAllReachOutsButton
