import PageableType from '@trinsly/common/src/types/pageable-type'
import { useQuery } from 'react-query'
import getCompaniesService from 'services/get-companies-service'

type UseGetCompaniesProps = {} & PageableType

function useGetCompanies(pageable: UseGetCompaniesProps) {
  const { data, error, status } = useQuery(
    ['/companies', pageable?.search],
    () => getCompaniesService(pageable)
  )

  return {
    data: data?.companies || [],
    total: data?.total || 0,
    pending: status === 'loading',
    error,
  }
}

export default useGetCompanies
