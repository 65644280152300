import Tag from '@trinsly/common/src/components/atoms/tag'
import React from 'react'
import BatchType, {
  BatchStatusEnum,
  translateBatchStatusEnum,
} from 'types/batch-type'

type StatusCellProps = {
  batch: BatchType
}

function StatusCell({ batch }: StatusCellProps) {
  const status = batch?.status
  const { textColor, backgroundColor } = React.useMemo(() => {
    const { textColor, backgroundColor } = intentStyleMap[status] || {
      textColor: '#334155',
      backgroundColor: '#e2e8f0',
    }

    return { textColor, backgroundColor }
  }, [status])
  return (
    <Tag textColor={textColor} backgroundColor={backgroundColor}>
      {translateBatchStatusEnum[status] || 'Unknown'}
    </Tag>
  )
}

export default StatusCell

const intentStyleMap: Record<
  BatchStatusEnum,
  { textColor: string; backgroundColor: string }
> = {
  [BatchStatusEnum.CANCELED]: {
    textColor: '#334155',
    backgroundColor: '#fde047',
  },
  [BatchStatusEnum.COMPLETED]: {
    textColor: '#334155',
    backgroundColor: '#e2e8f0',
  },
  [BatchStatusEnum.IN_PROGRESS]: {
    textColor: 'white',
    backgroundColor: '#3b82f6',
  },
  [BatchStatusEnum.CANCELING]: {
    textColor: '#334155',
    backgroundColor: '#e2e8f0',
  },
}
