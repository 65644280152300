import { ReactComponent as CloseSvg } from '@trinsly/common/src/assets/icons/close.svg'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Button from '@trinsly/common/src/components/molecules/button'
import React from 'react'
import ThreadType from 'types/thread-type'
import CancelThreadConfirmationModal from './cancel-thread-confirmation-modal'

export type CancelThreadButtonProps = {
  thread: ThreadType | undefined
}

function CancelThreadButton({ thread }: CancelThreadButtonProps) {
  const [visible, setVisible] = React.useState(false)

  return (
    <React.Fragment>
      <Button
        size="xs"
        shape="rounded"
        intent="danger"
        onClick={() => {
          setVisible(true)
        }}
      >
        <Row className="items-center">
          <CloseSvg className="w-4 h-4" />
          <Separator size="xxs" />
          Cancel
        </Row>
      </Button>
      <CancelThreadConfirmationModal
        thread={thread}
        visible={visible}
        onRequestClose={() => setVisible(false)}
      />
    </React.Fragment>
  )
}

export default CancelThreadButton
