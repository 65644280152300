import api from '@trinsly/common/src/services/api'

export type UpdateUserSettingsServiceProps = {
  timezone?: any
  bcc?: string
  unsubscribeLinkActive?: boolean
  sendAs?: string
}
/**
 *
 * - timezone: string
 * - bcc: email
 * - unsubscribe_link_enabled: boolean
 * - send_as: send as email?
 */
async function updateUserSettingsService({
  timezone,
  bcc,
  unsubscribeLinkActive,
  sendAs,
}: UpdateUserSettingsServiceProps) {
  const response = await api('/users/me', {
    body: {
      time_zone: timezone,
      bcc: bcc,
      unsubscribe_link_enabled: unsubscribeLinkActive,
      send_ass: sendAs,
    },
    method: 'PUT',
  })

  return response
}

export default updateUserSettingsService
