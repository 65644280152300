import { parseCredentialsHeaders } from '@trinsly/common/src/services/api'

async function exportCampaignTableService() {
  try {
    const headers = parseCredentialsHeaders()
    const actualHeaders = { ...headers, 'Content-Type': 'text/csv' }
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/reports/campaign_summary.csv`,
      {
        method: 'GET',
        headers,
      }
    )

    const blob = await response.blob()

    const fileObjectURL = URL.createObjectURL(blob)

    let link = document.createElement('a')
    link.href = fileObjectURL
    link.download = 'campaigns-stats.csv'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  } catch (error) {
    console.error('Failed to download', error)
  }
}

export default exportCampaignTableService
