import api from '@trinsly/common/src/services/api'
import PageableType from '@trinsly/common/src/types/pageable-type'
import { pickBy } from 'lodash'
import HotlistType from 'types/hotlist-type'

type GetHotlistsServiceProps = {} & PageableType

async function getHotlistsService(pageable: GetHotlistsServiceProps) {
  const response = await api(`/hotlists`, {
    method: 'GET',
    params: pickBy({
      page: pageable?.page,
      per_page: pageable?.perPage,
    }),
  })
  return response as {
    hotlists: HotlistType[]
    meta: { pagination: Pagination }
  }
}

export default getHotlistsService
