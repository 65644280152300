import api from '@trinsly/common/src/services/api'
import { pickBy } from 'lodash'
import ProspectType from 'types/prospect-type'

type CreateProspectEducationServiceProps = {
  prospectId: ProspectType['id']
  education: ProspectType['educations'][number]
}

async function createProspectEducationService({
  prospectId,
  education,
}: CreateProspectEducationServiceProps) {
  const response = (await api(`/prospects/${prospectId}/educations`, {
    method: 'POST',
    body: {
      education: pickBy({
        degree_type: education?.degreeType,
        major: education?.major,
        start_date: education?.startDate,
        end_date: education?.endDate,
        university_attributes: education?.universityAttributes,
      }),
    },
  })) as ProspectType['educations'][number]
  return response
}

export default createProspectEducationService
