import api from '@trinsly/common/src/services/api'
import PageableType from '@trinsly/common/src/types/pageable-type'
import { pickBy } from 'lodash'
import ProspectType from 'types/prospect-type'

export type GetCompaniesServiceProps = {} & PageableType

async function getCompaniesService(pageable: GetCompaniesServiceProps) {
  const params = pickBy({
    q: pageable?.search,
    page: pageable?.page,
    per_page: pageable?.perPage,
  }) as any

  const response = await api(`/companies`, {
    method: 'GET',
    params,
  })

  const { meta, companies } = response as any

  return {
    total: meta?.pagination?.total_objects ?? 0,
    companies,
  } as {
    companies: ProspectType['employments'][number]['companyAttributes'][]
    total: number
  }
}

export default getCompaniesService
