import Column from '@trinsly/common/src/components/atoms/column'
import Divider from '@trinsly/common/src/components/atoms/divider'
import Form from '@trinsly/common/src/components/atoms/form'
import Label from '@trinsly/common/src/components/atoms/label'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Button from '@trinsly/common/src/components/molecules/button'
import TextInput from '@trinsly/common/src/components/molecules/text-input'
import MultiSelectTags from 'components/multi-select-tags'
import React from 'react'
import { useHistory } from 'react-router-dom'
import SelectCampaign from '../../../components/select-campaign'
import { useDraftContext } from '../draft-provider'
import { useFormContext } from '../form-provider'
import AddFollowUpButton from './add-follow-up-button'
import DuplicateCampaignWithAttachmentsConfirmationModalWrapper from './duplicate-campaign-with-attachments-confirmation-modal-wrapper'
import EmailTemplateListItem from './email-template-list-item'
import StagesLockedModalWrapper from './stages-locked-modal-wrapper'

export type GeneralTabProps = {}

function GeneralTab({}: GeneralTabProps) {
  const history = useHistory()
  const {
    getValue,
    setValue,
    isCampaignBeingDuplicated,
    getError,
    submit,
    creating,
    updating,
    pending,
    isEdit,
    addFollowUp,
    campaign,
  } = useFormContext()
  const { pending: pendingDraft } = useDraftContext()

  const templates = (getValue('campaign.templates') || []) as any[]
  const shouldDisableFormChange =
    pending || creating || updating || pendingDraft
  const isReadonly = !pending && Boolean(campaign?.isArchived)
  const attachmentsCount =
    campaign?.templates?.reduce((acc, template) => {
      const count = template?.attachments?.length || 0
      return acc + count
    }, 0) || 0

  return (
    <Form onSubmit={submit}>
      <Column>
        <Column className="px-xl py-md">
          <Row className="items-end flex-wrap-reverse justify-between gap-xs">
            <Column className="min-w-64 w-1/4">
              <TextInput
                label="Name of Campaign"
                value={getValue('campaign.name')}
                onChange={(value) => setValue('campaign.name', value)}
                error={getError('campaign.name')}
                required
                disabled={shouldDisableFormChange || isReadonly}
              />
            </Column>
            {isEdit ? (
              <React.Fragment>
                <Separator />
                <DuplicateCampaignWithAttachmentsConfirmationModalWrapper
                  campaign={campaign}
                  disabled={attachmentsCount === 0}
                >
                  <Button
                    size="sm"
                    onClick={() => {
                      if (attachmentsCount === 0) {
                        history.push(
                          `/campaigns/new?duplicating-campaign-id=${
                            campaign?.id || ''
                          }`
                        )
                      }
                    }}
                  >
                    Duplicate Campaign
                  </Button>
                </DuplicateCampaignWithAttachmentsConfirmationModalWrapper>
              </React.Fragment>
            ) : (
              !isReadonly && (
                <React.Fragment>
                  <Separator />
                  <Row className="items-center whitespace-nowrap">
                    <Label>
                      Copy values from:
                      <Separator size="xs" />
                      <Column className="min-w-80 w-1/3">
                        <SelectCampaign
                          id="copy-values-from-select"
                          disabled={
                            isCampaignBeingDuplicated || shouldDisableFormChange
                          }
                          value={getValue('extending')}
                          onChange={(value) => {
                            setValue('extending', value || null)
                          }}
                        />
                      </Column>
                    </Label>
                  </Row>
                </React.Fragment>
              )
            )}
          </Row>
        </Column>
        <Divider />
        <Column>
          {templates?.map((template, index) => {
            return (
              <React.Fragment key={index}>
                <EmailTemplateListItem index={index} template={template} />
                <Divider />
              </React.Fragment>
            )
          })}
        </Column>
        <Column className="py-md">
          <StagesLockedModalWrapper
            campaignId={campaign?.id as any}
            disabled={!isEdit || isReadonly}
          >
            <AddFollowUpButton
              templateCounter={templates?.length || 0}
              disabled={isEdit || shouldDisableFormChange || isReadonly}
              onClick={() => {
                addFollowUp()
              }}
            />
          </StagesLockedModalWrapper>
        </Column>
        <Column className="px-xl py-md">
          <MultiSelectTags
            label="Tags"
            creatable={true}
            disabled={shouldDisableFormChange || isReadonly}
            values={getValue('campaign.tagList') || []}
            onChange={(tags) => setValue('campaign.tagList', tags)}
            onCreate={(value) => {
              const newTags = [...(getValue('campaign.tagList') || []), value]
              setValue('campaign.tagList', newTags)
            }}
          />
        </Column>
        <Divider />
        <Column className="px-xl py-md items-end">
          <Column className="min-w-48">
            <Button
              type="submit"
              intent="success"
              pending={creating || updating}
              disabled={isReadonly}
            >
              {isEdit ? 'Update campaign' : 'Create campaign'}
            </Button>
          </Column>
        </Column>
      </Column>
    </Form>
  )
}

export default GeneralTab
