import Checkbox from '@trinsly/common/src/components/atoms/checkbox'
import Label from '@trinsly/common/src/components/atoms/label'
import Row from '@trinsly/common/src/components/atoms/row'
import Spinner from '@trinsly/common/src/components/atoms/spinner'
import Text from '@trinsly/common/src/components/atoms/text'
import Animated from '@trinsly/common/src/components/molecules/animated'
import usePageableState from '@trinsly/common/src/hooks/use-pageable-state'
import useGetTags from 'api-hooks/use-get-tags'
import { compact } from 'lodash'

interface TagsFilterProps {
  values: string[]
  onChange: (values: string[] | undefined) => void
}

function TagsFilter({ onChange, values }: TagsFilterProps) {
  const { pageable } = usePageableState({
    page: 1,
    perPage: 999,
  })
  const { data: tags, pending: pendingTags } = useGetTags(pageable)
  const isNonIdealState = !pendingTags && !tags?.length

  return (
    <Animated className="gap-xs">
      {pendingTags && <Spinner size="xs" />}
      {isNonIdealState && (
        <Text size="xs" color="gray-400">
          No Tags yet.
        </Text>
      )}
      {tags?.map?.((tag) => {
        const checked = values?.includes?.(tag)
        return (
          <Row className="items-center gap-xs">
            <Checkbox
              checked={checked}
              onChange={() => {
                if (checked) {
                  onChange?.(values?.filter?.((value) => value !== tag))
                  return
                }
                onChange?.(compact([...values, tag]))
              }}
            />
            <Label>{tag || '--'}</Label>
          </Row>
        )
      })}
    </Animated>
  )
}

export default TagsFilter
