import { useQuery } from 'react-query'
import getProspectNotesService, {
  GetProspectNotesServiceProps,
} from 'services/get-prospect-notes-service'

export type UseGetProspectNotesProps = {} & GetProspectNotesServiceProps

function useGetProspectNotes({ prospectId }: UseGetProspectNotesProps) {
  const { data, error, status } = useQuery(
    ['/prospects/{prospectId}/notes', prospectId],
    () => getProspectNotesService({ prospectId }),
    {
      enabled: Boolean(prospectId),
    }
  )

  return {
    data: data || [],
    pending: status === 'loading',
    error,
  }
}

export default useGetProspectNotes
