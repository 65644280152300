import Column from '@trinsly/common/src/components/atoms/column'
import Form from '@trinsly/common/src/components/atoms/form'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Link from '@trinsly/common/src/components/atoms/link'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Text from '@trinsly/common/src/components/atoms/text'
import Alert from '@trinsly/common/src/components/molecules/alert'
import Button from '@trinsly/common/src/components/molecules/button'
import TextInput from '@trinsly/common/src/components/molecules/text-input'
import useForm from '@trinsly/common/src/hooks/use-form'
import useQueryParams from '@trinsly/common/src/hooks/use-query-params'
import noPersonalEmail from '@trinsly/common/src/validations/no-personal-email'
import required from '@trinsly/common/src/validations/required'
import validEmail from '@trinsly/common/src/validations/valid-email'
import useFetchSignUp from 'api-hooks/use-fetch-sign-up'
import { ReactComponent as TrinslyLogoSvg } from 'assets/logo.svg'
import { ReactComponent as PublicBackgroundSvg } from 'assets/public-background.svg'
import React, { useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useHistory } from 'react-router'
import SoftwareContent from './software-content'
import SourcingContent from './sourcing-content'

export type RegistrationScreenProps = {}

//covered - inviteToken - used to generally share a link to join an org
//not covered - invite via email appends a token to api request which should return validation
function RegistrationScreen({}: RegistrationScreenProps) {
  const history = useHistory()
  const { params } = useQueryParams()
  const recaptchaRef: any = React.createRef()
  const [error, setError] = useState<any>()
  const [hasInviteToken, setHasInvitetoken] = useState(false)
  const [showReferralCodeInput, setShowReferralCodeInput] = useState(false)

  React.useEffect(() => {
    //see if invite token exists
    if (params.inviteToken) {
      setValue('inviteToken', params.inviteToken)
      setHasInvitetoken(true)
    }

    //set referral code if exists
    if (params.referralCode) {
      setValue('referralCode', params.referralCode)
      setShowReferralCodeInput(true)
    }

    //human predictions referral
    if (params['hp_id']) {
      setValue('hpId', params['hp_id'])
    }
  }, [])

  const { fetch: signUp, pending: createAccountPending } = useFetchSignUp({
    onError: (error: any) => {
      setError(JSON.stringify(error))
    },
    onSuccess: (user: User) => {
      //TODO: need GA conversion event here
      // try {
      //   ReactGA.event('start_free_trial')
      // } catch (error) {
      //   console.warn('Failed to track conversion')
      // }
      history.push('/', 'New Account')
    },
  })

  const { getValue, setValue, getError, submit, isValid } = useForm({
    onSubmit: async ({
      firstName,
      lastName,
      email,
      password,
      referralCode,
      inviteToken,
      hpId,
    }) => {
      const recaptchaValue = await recaptchaRef.current.executeAsync()
      const formValues = {
        firstName,
        lastName,
        email,
        password,
        recaptcha: recaptchaValue,
        referralCode,
        inviteToken,
        hpId,
      }

      signUp(formValues)

      if (!params['inviteToken']) {
      }
    },

    validations: {
      firstName: [required],
      lastName: [required],
      email: [required, validEmail, noPersonalEmail],
      password: [required],
    },
  })

  return (
    <Row
      className="relative flex-1 bg-primary-500 overflow-auto"
      style={{ minHeight: '100vh', width: '100vw' }}
    >
      <Column
        className="bg-white pl-20 pr-20 pt-8 pb-8"
        style={{ minWidth: 580, flex: 0.3 }}
      >
        <TrinslyLogoSvg
          height={100}
          width={200}
          style={{ alignSelf: 'left' }}
        />
        <Column className="flex">
          <Form onSubmit={submit}>
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY || ''}
            />

            <Heading size="xl" fontWeight="bold">
              {hasInviteToken
                ? 'Register to join your team!'
                : 'Start your 7-day trial.'}
            </Heading>
            <Separator size="lg" />
            {Boolean(error) && (
              <React.Fragment>
                <Alert intent="danger">
                  <Column className="py-xs">
                    <Text size="sm">{'Unable to register at this time.'}</Text>
                  </Column>
                </Alert>
                <Separator />
              </React.Fragment>
            )}
            <TextInput
              label="First name"
              placeholder="What's your first name?"
              value={getValue('firstName')}
              onChange={(value) => setValue('firstName', value)}
              error={getError('firstName')}
            />
            <Separator />
            <TextInput
              label="Last name"
              placeholder="What's your last name?"
              value={getValue('lastName')}
              onChange={(value) => setValue('lastName', value)}
              error={getError('lastName')}
            />
            <Separator />
            <TextInput
              type="email"
              label="Work E-mail"
              placeholder="you@example.com"
              value={getValue('email')}
              onChange={(value) => setValue('email', value.toLocaleLowerCase())}
              error={getError('email')}
            />
            <Separator />
            <TextInput
              type="password"
              label="Password"
              placeholder="Please enter your password"
              value={getValue('password')}
              onChange={(value) => setValue('password', value)}
              error={getError('password')}
            />
            <Separator size="md" />
            {showReferralCodeInput ? (
              <>
                <TextInput
                  label="Referral Code"
                  disabled={params.referralCode}
                  placeholder="Have a referral code?"
                  value={getValue('referralCode')}
                  onChange={(value) => setValue('referralCode', value)}
                  error={getError('referralCode')}
                ></TextInput>
                <Separator size="md" />
              </>
            ) : hasInviteToken ? (
              <></>
            ) : (
              <>
                <Link
                  onClick={() =>
                    setShowReferralCodeInput(!showReferralCodeInput)
                  }
                >
                  <Text size="xs">Have a referral code?</Text>
                </Link>
                <Separator size="md" />
              </>
            )}
            <Button
              type="submit"
              intent="success"
              className="w-full"
              pending={createAccountPending}
              disabled={
                !isValid('email') ||
                !isValid('password') ||
                !isValid('firstName') ||
                !isValid('lastName')
              }
            >
              {!params['inviteToken'] ? 'Register Now' : 'Join Your Team'}
            </Button>
            <Separator />
            <Row>
              <Text size="sm">Already have an account?</Text>
              <Column style={{ minWidth: 4 }} />
              <Link href="/log-in" tabIndex={1}>
                <Text size="sm" color="current" fontWeight="bold">
                  Sign In
                </Text>
              </Link>
            </Row>
            <Separator size="lg" />
            <Row>
              <Text size="xs">By registering, you agree to our</Text>
              <Column style={{ minWidth: 4 }} />
              <Link
                target="_blank"
                href="https://chatkick.com/terms"
                tabIndex={1}
              >
                <Text size="xs" color="current" fontWeight="bold">
                  terms of service
                </Text>
              </Link>
              <Column style={{ minWidth: 4 }} />
              <Text size="xs">and</Text>
              <Column style={{ minWidth: 4 }} />
              <Link
                target="_blank"
                href="https://chatkick.com/privacy"
                tabIndex={1}
              >
                <Text size="xs" color="current" fontWeight="bold">
                  privacy policy.
                </Text>
              </Link>
            </Row>
          </Form>
        </Column>
      </Column>
      <Column className="relative" style={{ flex: 0.7 }}>
        <Column className="absolute inset-0">
          <PublicBackgroundSvg className="w-full h-full" />
        </Column>
        {Boolean(params.landing) && (
          <Column className="p-xl z-10">
            {params.landing === 'sourcing' && <SourcingContent />}
            {params.landing === 'software' && <SoftwareContent />}
          </Column>
        )}
      </Column>
    </Row>
  )
}

export default RegistrationScreen
