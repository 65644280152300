import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation, useQueryClient } from 'react-query'
import createUser2faService from 'services/settings/create-user-2fa-service'
import deactivateUser2faService from 'services/settings/deactivate-user-2fa-service'
import verifyUser2faService from 'services/settings/verify-user-2fa-service'
import useGetUserSession from './use-get-user-session'

export type UseUpdateUser2faProps = {
  phoneNumber?: string
  otp?: string | number
  email?: string
} & MutateApiHookType<void>

function useUpdateUser2Fa({ onSuccess, onError }: UseUpdateUser2faProps) {
  const { refetch } = useGetUserSession({})
  const queryClient = useQueryClient()

  const { mutate: start, isLoading: pending } = useMutation(
    createUser2faService,
    {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries('/user/me')
        onSuccess?.(data)
      },
      onError,
    }
  )

  const { mutate: verifyOtp, isLoading: otpVerificationPending } = useMutation(
    verifyUser2faService,
    {
      onSuccess: async (data) => {
        await refetch()
        onSuccess?.(data)
      },
      onError,
    }
  )

  const { mutate: deactivate2fa, isLoading: deactivatePending } = useMutation(
    deactivateUser2faService,
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('/user/me')
        onSuccess?.()
      },
      onError,
    }
  )

  return {
    start,
    pending,
    verifyOtp,
    otpVerificationPending,
    deactivate2fa,
    deactivatePending,
  }
}

export default useUpdateUser2Fa
