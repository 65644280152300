import SelectInput from '@trinsly/common/src/components/molecules/select-input'

type EmailThreadOpenedFilterProps = {
  disabled: boolean
  onChange: (value: StateEnum | null) => void
  value: StateEnum | null
}

function EmailThreadOpenedFilter({
  disabled,
  onChange,
  value,
}: EmailThreadOpenedFilterProps) {
  return (
    <SelectInput
      label="With"
      placeholder="Select an opened status"
      disabled={disabled}
      onChange={(item) => onChange(item ?? null)}
      options={OPTIONS}
      value={value}
      itemRenderer={(item) => (item ? translate[item] : '')}
      valueRenderer={(value) =>
        value ? `Opened Status of ${translate[value]}` : ''
      }
    />
  )
}

export default EmailThreadOpenedFilter

enum StateEnum {
  OPENED = 'OPENED',
  UNOPENED = 'UNOPENED',
}

export const translate: Record<StateEnum, string> = {
  [StateEnum.OPENED]: 'Opened',
  [StateEnum.UNOPENED]: 'Unopened',
}

const OPTIONS = Object.values(StateEnum) as StateEnum[]
