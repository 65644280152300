import { cloneDeep, debounce, get, set } from 'lodash'
import React from 'react'
import useForm, { useFormProps } from './use-form'

type useFormConcurrentProps = {} & useFormProps

function useFormConcurrent({
  initialValues = {},
  validations = {},
  onSubmit = () => null,
  onReset = () => null,
}: useFormConcurrentProps) {
  const stateRef = React.useRef(initialValues)
  const [updateCount, forceUpdate] = React.useReducer((x) => x + 1, 0)

  const useFormObject = useForm({
    initialValues,
    validations,
    onSubmit,
    onReset,
  })

  const syncWithUseForm = React.useCallback(
    debounce(() => {
      useFormObject?.setValues?.(cloneDeep(stateRef?.current || {}))
    }, 300),
    []
  )

  React.useEffect(() => {
    syncWithUseForm()
  }, [updateCount])

  return {
    ...useFormObject,
    getValue: (path: string) => cloneDeep(get(stateRef?.current, path)),
    setValue: (path: string, value: any) => {
      const newCurrent = set(cloneDeep(stateRef?.current), path, value)
      stateRef.current = newCurrent
      forceUpdate()
    },
    getValues: () => cloneDeep(stateRef?.current),
    setValues: (values: any) => {
      stateRef.current = values
      forceUpdate()
    },
    reset: () => {
      stateRef.current = initialValues
      forceUpdate()
    },
    submit: () => useFormObject?.submit?.(cloneDeep(stateRef?.current)),
  }
}
export default useFormConcurrent
