import { get } from 'lodash'
import ProspectType from './prospect-type'

type EmailTemplateType = {
  id: string
  duration: string
  body: string
  subject: string
  attachments: string[]
  isInThread: boolean
  position: number
}

export default EmailTemplateType

const variableToProspectProp: Record<string, string> = {
  Email: 'email',
  FirstName: 'firstName',
  LastName: 'lastName',
  City: 'city',
  CurrentJobTitle: 'employments[0].role',
  CompanyName: 'employments[0].company.name',
  School: 'educations[0].university.name',
}

export function fillEmailVariablesWithProspectData({
  emails,
  variables,
  prospect,
}: {
  emails: EmailTemplateType[] | undefined
  variables: string[] | undefined
  prospect: ProspectType | undefined
}) {
  const emailsFilled = emails?.map?.((template, index) => {
    const filledSubject: string =
      variables?.reduce?.((acc, variable) => {
        const prop = variableToProspectProp?.[variable] || ''
        const newAcc = acc?.replaceAll?.(
          `{{${variable}}}`,
          get(prospect, prop) || ''
        )
        return newAcc
      }, template?.subject || '') || ''

    const filledBody =
      variables?.reduce?.((acc, variable) => {
        const prop = variableToProspectProp?.[variable] || ''
        const newAcc = acc?.replaceAll?.(
          `{{${variable}}}`,
          get(prospect, prop) || ''
        )
        return newAcc
      }, template?.body || '') || ''

    return {
      ...template,
      initial: {
        subject: filledSubject,
        body: filledBody,
      },
      subject: filledSubject,
      body: filledBody,
    }
  })

  return emailsFilled as EmailTemplateType[]
}
