import Card from '@trinsly/common/src/components/atoms/card'
import Column from '@trinsly/common/src/components/atoms/column'
import Form from '@trinsly/common/src/components/atoms/form'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Input from '@trinsly/common/src/components/atoms/input'
import Label from '@trinsly/common/src/components/atoms/label'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Button from '@trinsly/common/src/components/molecules/button'
import Modal from '@trinsly/common/src/components/molecules/modal'
import useForm from '@trinsly/common/src/hooks/use-form'
import required from '@trinsly/common/src/validations/required'
import useUpdateOrganizationDetails from 'api-hooks/use-update-organization-details'
import React from 'react'
import { toast } from 'react-toastify'

import TelInput from 'components/tel-input'
import { Organization } from 'types/settings-type'

export type SetupOrganizationFormModal = {
  visible: boolean
  onRequestClose: () => void
  isEdit: boolean
  organization: Organization
}

const SetupOrganizationFormModal = ({
  visible,
  onRequestClose,
  isEdit = false,
  organization,
}: SetupOrganizationFormModal) => {
  const { fetch: updateOrg, pending: pendingUpdate } =
    useUpdateOrganizationDetails({
      onSuccess: () => {
        toast.success('Organization updated!')
        onRequestClose()
      },
    })
  const { submit, getValue, setValue, setValues } = useForm({
    onSubmit: ({ organizationName, contactNumber }) => {
      updateOrg({
        orgId: organization.id,
        name: organizationName,
        phone: contactNumber,
      })
    },
    validations: {
      organizationName: [required],
    },
  })

  React.useEffect(() => {
    if (visible) {
      setValues({
        organizationName: organization?.name || '',
        contactNumber: organization?.phone || '',
      })
    }
  }, [visible])

  return (
    <Modal visible={visible} onRequestClose={onRequestClose}>
      <Card className="p-lg h-96">
        <Form onSubmit={submit} className="flex-1 justify-between">
          <Column>
            <Heading
              size="sm"
              fontWeight="bold"
              color="gray-500"
              className="uppercase"
            >
              {isEdit ? 'Edit Organization Details' : 'Setup Organization'}
            </Heading>
            <Separator />
            <Label>Organization Name*</Label>
            <Separator size="xs" />
            <Input
              value={getValue('organizationName')}
              onChange={(value) => setValue('organizationName', value)}
              placeholder="Your organizations name"
            />
            <Separator />
            <Label>Contact Number (US Numbers Only)</Label>
            <Separator size="xs" />
            <TelInput
              type="tel"
              value={getValue('contactNumber')}
              onChange={(value) => setValue('contactNumber', value)}
              onSetNumber={() => {}}
            />
            <Separator />
          </Column>
          <Row className="justify-end items-baseline">
            <Button variant="outline" onClick={onRequestClose}>
              cancel
            </Button>
            <Separator />
            <Button
              pending={pendingUpdate}
              intent="success"
              variant="solid"
              type="submit"
            >
              {isEdit
                ? 'Update Organization Details'
                : 'Create My Organization'}
            </Button>
          </Row>
        </Form>
      </Card>
    </Modal>
  )
}

export default SetupOrganizationFormModal
