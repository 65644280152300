import { useQuery } from 'react-query'
import getCurrentSubscriptionsService from 'services/billing/get-current-subscriptions-service'
import CurrentStripeSubscription from 'types/current-stripe-subscription-type'

export type UseGetCurrentSubscriptionsProps = {}

function useGetCurrentSubscriptions({}: UseGetCurrentSubscriptionsProps) {
  const {
    data,
    error,
    status,
    refetch,
    isRefetching: refetching,
  } = useQuery(
    '/billing/current-subscriptions',
    getCurrentSubscriptionsService,
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  )

  return {
    data: (data || []) as CurrentStripeSubscription[],
    pending: status === 'loading',
    error,
    refetch,
    refetching,
  }
}

export default useGetCurrentSubscriptions
