import { compact } from 'lodash'
import React from 'react'
import { ReactComponent as ChevronLeftSvg } from '../../assets/icons/chevron-left.svg'
import { ReactComponent as ChevronRightSvg } from '../../assets/icons/chevron-right.svg'
import Row from '../atoms/row'
import Separator from '../atoms/separator'
import Text from '../atoms/text'
import IconButton from './icon-button'

export type PaginationProps = {
  id?: string
  page: number
  total: number
  perPage: number
  onPrev: () => void
  onNext: () => void
}

function Pagination({
  id,
  page,
  perPage,
  total,
  onPrev,
  onNext,
}: PaginationProps) {
  const start = (page - 1) * perPage + 1
  let end = start + perPage - 1
  end = end > total ? total : end

  const previousButtonId = React.useMemo(
    () => compact([id, 'previous-button']).join('-'),
    [id]
  )
  const nextButtonId = React.useMemo(
    () => compact([id, 'next-button']).join('-'),
    [id]
  )

  return (
    <Row className="items-center">
      <Text
        size="sm"
        fontWeight="bold"
        className="uppercase"
        color="gray-500"
      >{`${start} to ${end} of ${total}`}</Text>
      <Separator size="sm" />
      <IconButton
        id={previousButtonId}
        size="sm"
        shape="circle"
        variant="ghost"
        intent="primary"
        onClick={() => onPrev()}
        disabled={start === 1}
      >
        <ChevronLeftSvg />
      </IconButton>
      <Separator size="xs" />
      <IconButton
        id={nextButtonId}
        size="sm"
        shape="circle"
        variant="ghost"
        intent="primary"
        onClick={() => onNext()}
        disabled={end === total}
      >
        <ChevronRightSvg />
      </IconButton>
    </Row>
  )
}
export default Pagination
