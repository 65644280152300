import Card from '@trinsly/common/src/components/atoms/card'
import Form from '@trinsly/common/src/components/atoms/form'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import PrivateLayout from 'components/private-layout'
import CampaignFormSection from './campaign-form-section'
import CustomizeEmailsSection from './customize-emails-section'
import DraftProvider from './draft-provider'
import DraftSection from './draft-provider/draft-section'
import FooterSection from './footer-section'
import FormProvider, { useFormContext } from './form-provider'
import ScheduleSection from './schedule-section'
import UploadCsvSection from './upload-csv-section'
import VariablesMapSection from './variables-map-section'

function ScreenWrapper() {
  const { getValue, getValues, setValue, setValues, getError, submit } =
    useFormContext()

  return (
    <PrivateLayout>
      <Row className="px-lg py-sm gap-md bg-white items-center justify-between shadow">
        <Heading size="xl" fontWeight="bold">
          New Batch
        </Heading>
        <DraftSection />
      </Row>
      <Separator />
      <Form onSubmit={submit}>
        <Card className="mx-lg mb-xl py-md gap-lg">
          <UploadCsvSection />
          <CampaignFormSection />
          <VariablesMapSection />
          <ScheduleSection />
          <CustomizeEmailsSection />
          <FooterSection />
        </Card>
      </Form>
    </PrivateLayout>
  )
}

function CreateBatchScreen() {
  return (
    <FormProvider>
      <DraftProvider>
        <ScreenWrapper />
      </DraftProvider>
    </FormProvider>
  )
}

export default CreateBatchScreen
