import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation, useQueryClient } from 'react-query'
import deleteCampaignDraftService from 'services/delete-campaign-draft-service'
import DraftType from 'types/draft-type'

type UseDeleteCampaignDraftProps = {} & MutateApiHookType<DraftType>

function useDeleteCampaignDraft({
  onError,
  onSuccess,
}: UseDeleteCampaignDraftProps) {
  const queryClient = useQueryClient()
  const { mutate: fetch, status } = useMutation(deleteCampaignDraftService, {
    onSuccess: async (data) => {
      await queryClient.invalidateQueries('/campaigns/drafts')
      onSuccess?.(data)
    },
    onError,
  })

  return {
    fetch,
    pending: status === 'loading',
  }
}

export default useDeleteCampaignDraft
