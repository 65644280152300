import { ReactComponent as DeleteSvg } from '@trinsly/common/src/assets/icons/delete.svg'
import { ReactComponent as FileSvg } from '@trinsly/common/src/assets/icons/file.svg'
import Column from '@trinsly/common/src/components/atoms/column'
import Row from '@trinsly/common/src/components/atoms/row'
import Text from '@trinsly/common/src/components/atoms/text'
import IconButton from '@trinsly/common/src/components/molecules/icon-button'

type FileListItemProps = {
  name: string
  pending?: boolean
  onDelete?: () => void
}

function FileListItem({ name, onDelete, pending }: FileListItemProps) {
  return (
    <Row className="items-center px-sm py-xs gap-xs bg-gray-100 rounded">
      <FileSvg className="w-5 h-5 text-primary-500" />
      <Column className="flex-1">
        <Text size="xs">{name || ''}</Text>
      </Column>
      <IconButton
        size="xs"
        iconSize="sm"
        intent="danger"
        variant="transparent"
        pending={pending}
        onClick={onDelete}
      >
        <DeleteSvg />
      </IconButton>
    </Row>
  )
}

export default FileListItem
