import Card from '@trinsly/common/src/components/atoms/card'
import Column from '@trinsly/common/src/components/atoms/column'
import DateRangeInput from '@trinsly/common/src/components/atoms/date-range-input'
import DualBackground from '@trinsly/common/src/components/atoms/dual-background'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Select from '@trinsly/common/src/components/molecules/select'
import useGetOverallStats from 'api-hooks/use-get-overall-stats'
import useGetUserSettings from 'api-hooks/use-get-user-settings'
import React, { useState } from 'react'
import DateRangeEnum, {
  dateToTimeZone,
  getDatesByDateRangeEnum,
  translateDateRangeEnum,
} from './date-range-enum'
import StatsFunnelChart from './stats-funnel-chart'

import ProgressBar from '@trinsly/common/src/components/atoms/progress-bar'

import Text from '@trinsly/common/src/components/atoms/text'
import { round } from 'lodash'
// const calcDates = (dateRange: DateRangeEnum, offset: number | null) => {
//   const localizedDate = dateToTimeZone({ timezoneOffset: offset as number })
//   return translateDateRange({
//     dateRange,
//     localizedDate,
//   })
// }

type OverallStatsSectionProps = {}

function OverallStatsSection({}: OverallStatsSectionProps) {
  const [dateRangeEnum, setDateRangeEnum] = useState<DateRangeEnum>(
    DateRangeEnum.ALL
  )
  const [startDate, setStartDate] = useState<moment.Moment | null>()
  const [endDate, setEndDate] = useState<moment.Moment | null>()

  const { data: stats, pending } = useGetOverallStats({
    endDate: endDate ? endDate.format('YYYY-MM-DD') : '',
    startDate: startDate ? startDate.format('YYYY-MM-DD') : '',
  })

  const { data: settings } = useGetUserSettings()
  const timezoneOffset = settings?.timeZone?.utcTotalOffset

  React.useEffect(() => {
    if (dateRangeEnum !== DateRangeEnum.CUSTOM) {
      const { startDate, endDate } = getDatesByDateRangeEnum({
        dateRange: dateRangeEnum,
        localizedDate: dateToTimeZone({ timezoneOffset }),
      })
      setStartDate(startDate)
      setEndDate(endDate)
    }
  }, [dateRangeEnum, timezoneOffset])

  return (
    <DualBackground>
      <Column className="px-lg py-md">
        <Row className="items-center justify-between">
          <Heading size="xl" fontWeight="bold" color="white">
            Overall Stats
          </Heading>
          <Separator />
          <Row className="items-center">
            {dateRangeEnum === DateRangeEnum.CUSTOM && (
              <React.Fragment>
                <DateRangeInput
                  dateRange={dateRangeEnum}
                  setDateRange={(value) => {
                    setDateRangeEnum(value)
                  }}
                  endDate={endDate || null}
                  setEndDate={setEndDate}
                  startDate={startDate || null}
                  setStartDate={setStartDate}
                />
                <Separator />
              </React.Fragment>
            )}
            <Column className="min-w-52">
              <Select
                pending={pending}
                clearable={false}
                value={dateRangeEnum}
                onChange={(value) =>
                  setDateRangeEnum(value || DateRangeEnum.ALL)
                }
                options={Object.keys(DateRangeEnum) as DateRangeEnum[]}
                itemRenderer={(item) => translateDateRangeEnum[item]}
              />
            </Column>
          </Row>
        </Row>
        <Separator size="sm" />

        <Card>
          <StatsFunnelChart stats={stats} />
        </Card>
        <Separator size="xxs" />

        <Card>
          <Row className="px-xl py-xs">
            <Column className="px-sm text-center align-center rounded-lg bg-success-100 py-xs">
              <Separator size="xs" />
              <ProgressBar
                size="sm"
                intent="success"
                total={stats?.reachOutCount || 0}
                value={stats?.inProgressCount || 0}
              />
              <Separator size="xs" />
              <Text
                size="xs"
                fontWeight="semibold"
                className="capitalize"
                color="gray-700"
              >{`${stats?.inProgressCount || 0} in progress`}</Text>
            </Column>
            <Column style={{ width: 40 }} />
            <Column className="px-sm text-center align-center bg-danger-50 rounded-lg py-xs">
              <Text
                size="xs"
                className="capitalize"
                color="gray-700"
                fontWeight="semibold"
              >{`${round(
                (stats?.hardBouncedCount || 0) / (stats?.reachOutCount || 1),
                2
              )}%`}</Text>
              <Separator size="xxs" />
              <Text
                size="xs"
                fontWeight="semibold"
                className="capitalize"
                color="gray-700"
              >{`${stats?.hardBouncedCount || 0} Hard Bounced`}</Text>
            </Column>
            <Column style={{ width: 120 }} />
            <Column className="align-center text-center self-center flex-1 ">
              <Text size="xs" fontWeight="bold" color="black">
                {startDate?.format('MM-DD-YYYY') || '-'} to{' '}
                {endDate?.format('MM-DD-YYYY') || '-'}
              </Text>
            </Column>
          </Row>
        </Card>
      </Column>
    </DualBackground>
  )
}

export default OverallStatsSection
