import Card from '@trinsly/common/src/components/atoms/card'
import Column from '@trinsly/common/src/components/atoms/column'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Spinner from '@trinsly/common/src/components/atoms/spinner'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import Modal from '@trinsly/common/src/components/molecules/modal'
import React, { useState } from 'react'

import useGetUserSession from 'api-hooks/use-get-user-session'
import useGetUserSettings from 'api-hooks/use-get-user-settings'
import NotificationListItem, {
  ERROR_TYPE,
  WARNING_TYPE,
} from 'providers/notifications-provider/notification-list-item'
import createBillingSessionService from 'services/billing/create-billing-session-service'
import { getAccountBillingState } from 'validators/paywall'
import AddUserForm from './add-user-form'
import CreditUsage from './credit-usage'
import NewSubscriptionCheckout from './new-subscription-checkout'

export type BillingTabProps = {}
export enum AccountBillingState {
  ActiveTrial,
  ExpiredTrial,
  ActiveEnterprise,
  ActiveSubscriptionMonthly,
  ActiveSubscriptionAnnual,
  Inactive,
}
const BillingTab = ({}: BillingTabProps) => {
  const [showForm, setShowForm] = useState(false)
  const { data: userSession, pending: userPending } = useGetUserSession({})
  const { data: settings, pending: settingsPending } = useGetUserSettings()
  const [accountBillingState, setAccountBillingState] =
    useState<AccountBillingState>(AccountBillingState.Inactive)

  React.useEffect(() => {
    if (!userPending && userSession) {
      setAccountBillingState(getAccountBillingState(userSession.paywallInfo))
    }
  }, [userSession, userPending])

  return userPending || settingsPending ? (
    <Spinner />
  ) : (
    <Column>
      <Separator />
      <Row>
        <Column className="px-sm w-full">
          {/* No Subscription Active & Trial Ended */}
          {accountBillingState === AccountBillingState.ExpiredTrial && (
            <NotificationListItem
              notification={{
                key: 'subscription-settings-notification',
                message:
                  'Your trial has expired. Please sign up for a plan to continue use.',
                type: WARNING_TYPE,
                persist: 0,
                link: undefined,
                _shown: undefined,
              }}
            />
          )}

          {/* No Subscription - On Free Trial */}
          {(accountBillingState === AccountBillingState.ActiveTrial ||
            accountBillingState === AccountBillingState.ExpiredTrial) && (
            <>
              <Separator size="sm" />
              <NewSubscriptionCheckout />
            </>
          )}

          {/* Prior Subscription - Delinquent Payments */}
          {accountBillingState === AccountBillingState.Inactive && (
            <>
              <NotificationListItem
                notification={{
                  key: 'subscription-settings-notification',
                  message:
                    'Your payment is past due and your account may be frozen. Please contact support@trinsly.com',
                  type: ERROR_TYPE,
                  persist: 0,
                  link: undefined,
                  _shown: undefined,
                }}
              />
              <Separator size="sm" />
            </>
          )}

          {/* Subscription is active */}
          {(accountBillingState ===
            AccountBillingState.ActiveSubscriptionAnnual ||
            accountBillingState ===
              AccountBillingState.ActiveSubscriptionMonthly) && (
            <Row className="pl-6 gap-xl justify-between">
              <Column>
                <Heading size="xl" fontWeight="semibold">
                  Your Current Plan:
                </Heading>

                <Text fontWeight="semibold">
                  {
                    settings.organization.memberships.filter(
                      (m) => !m.disabledAt
                    ).length
                  }{' '}
                  of {userSession.paywallInfo.seatLimit} Active Licenses in Use
                </Text>
              </Column>
              <Separator size="xl" />
              <Column className="float-right">
                <Button
                  type="button"
                  intent="primary"
                  shape="rounded"
                  variant="ghost"
                  size="sm"
                  onClick={async () => {
                    window.location.href = await createBillingSessionService()
                  }}
                >
                  Manage Payment Information
                </Button>
                <Separator />
                <Button
                  shape="rounded"
                  intent="success"
                  variant="outline"
                  size="sm"
                  onClick={() => setShowForm(!showForm)}
                >
                  Add User Licenses
                </Button>
              </Column>
            </Row>
          )}

          {/*Enterprise Account */}
          {accountBillingState === AccountBillingState.ActiveEnterprise && (
            <Text fontWeight="bold">
              You are currently on an Enterprise plan. To modify your current
              contract please contact support at{' '}
              <a
                href="mailto:hello@chatkick.com"
                className="text-primary-500 hover:text-primary-300"
              >
                hello@chatkick.com
              </a>
              .
            </Text>
          )}
          <Separator />
        </Column>
      </Row>

      <Row className="pl-4">
        <CreditUsage />
      </Row>
      <Modal
        size="lg"
        onRequestClose={() => {
          setShowForm(false)
        }}
        visible={showForm}
      >
        <Card className="p-8 h-full">
          <AddUserForm />
        </Card>
      </Modal>
    </Column>
  )
}

export default BillingTab
