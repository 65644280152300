import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation, useQueryClient } from 'react-query'
import cancelThreadService from 'services/cancel-thread-service'
import ThreadType from 'types/thread-type'

export type UseCancelThreadProps = {} & MutateApiHookType<ThreadType>
function useCancelThread({
  onMutate,
  onSuccess,
  onError,
}: UseCancelThreadProps) {
  const queryClient = useQueryClient()

  const { mutate: fetch, isLoading: pending } = useMutation(
    cancelThreadService,
    {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries('/threads')
        onSuccess?.(data)
      },
      onError,
      onMutate,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useCancelThread
