import React from 'react'
import { Theme } from '../../types/theme-type'

export interface TextProps {
  children?: React.ReactText | React.ReactNode | React.ReactNodeArray
  className?: string
  size?: Theme['size'] | '2xl' | '3xl'
  color?: Theme['color']
  fontWeight?: Theme['fontWeight']
}

function Text({
  size = 'md',
  className = '',
  fontWeight = 'normal',
  color = 'gray-500',
  children,
}: TextProps) {
  const classNames = `inline-block text-${size === 'md' ? 'base' : size} ${
    fontWeightMap[fontWeight]
  } text-${color} ${className}`
  return <span className={classNames}>{children}</span>
}

export default Text

const fontWeightMap = {
  thin: 'font-thin',
  extralight: 'font-extralight',
  light: 'font-light',
  normal: 'font-normal',
  medium: 'font-medium',
  semibold: 'font-semibold',
  bold: 'font-bold',
  extrabold: 'font-extrabold',
  black: 'font-black',
  undefined: '',
}
