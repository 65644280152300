import Column from '@trinsly/common/src/components/atoms/column'
import Link from '@trinsly/common/src/components/atoms/link'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Alert from '@trinsly/common/src/components/molecules/alert'
import Button from '@trinsly/common/src/components/molecules/button'
import React from 'react'
import { useHistory } from 'react-router'
import NotificationType from './notification-type'

type NotificationListItemProps = {
  notification: NotificationType
}

function NotificationListItem({ notification }: NotificationListItemProps) {
  const history = useHistory()
  const { key, link, message, type, variant = 'solid' } = notification
  let intent

  switch (type) {
    case ERROR_TYPE:
      intent = 'danger'
      break

    case INFO_TYPE:
      intent = 'info'
      break

    case SUCCESS_TYPE:
      intent = 'success'
      break

    case WARNING_TYPE:
      intent = 'warning'
      break

    default:
      intent = 'success'
      break
  }

  const hasAction =
    (link && link !== history.location.pathname) ||
    type === SERVICE_WORKER_UPDATE_TYPE

  return (
    <Column id={`notification-${key}`}>
      <Alert intent={intent as any} variant={variant}>
        {message}
        {hasAction && (
          <React.Fragment>
            <Separator size="sm" />
            <Row className="items-center">
              {link && link !== history.location.pathname && (
                <Link href={link} className="min-w-16">
                  Go
                </Link>
              )}
              {type === SERVICE_WORKER_UPDATE_TYPE && (
                <React.Fragment>
                  <Separator size="sm" />
                  <Button
                    size="sm"
                    onClick={() => {
                      window.location.reload()
                    }}
                  >
                    Reload now
                  </Button>
                </React.Fragment>
              )}
            </Row>
          </React.Fragment>
        )}
      </Alert>
    </Column>
  )
}

export default NotificationListItem

export const ERROR_TYPE = 'notifications-ERROR_TYPE'
export const INFO_TYPE = 'notifications-INFO_TYPE'
export const SERVICE_WORKER_UPDATE_TYPE =
  'notifications-SERVICE_WORKER_UPDATE_TYPE'
export const SUCCESS_TYPE = 'notifications-SUCCESS_TYPE'
export const WARNING_TYPE = 'notifications-WARNING_TYPE'
