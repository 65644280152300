import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation } from 'react-query'
import { verifyGoogleAuthCodeService } from 'services/gmail/verify-google-auth-code-service'
import useGetUserSession from './use-get-user-session'

export type UseUpdateGoogleAuthCodeProps = {} & MutateApiHookType<void>

function useVerifyGoogleAuthCode({
  onSuccess,
  onError,
}: UseUpdateGoogleAuthCodeProps) {
  const { refetch } = useGetUserSession({})

  const { mutate: fetch, isLoading: pending } = useMutation(
    verifyGoogleAuthCodeService,
    {
      onSuccess: async (data) => {
        await refetch()
        onSuccess?.(data)
      },
      onError,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useVerifyGoogleAuthCode
