import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation, useQueryClient } from 'react-query'
import updateReachOutResponseTypeService from 'services/update-reach-out-response-type-service'
import ProspectHistoryType from 'types/prospect-history-type'
import updateReachOutStatusService from '../services/update-reach-out-status-service'

export type UseUpdateEmailThreadStateProps =
  {} & MutateApiHookType<ProspectHistoryType>

function useUpdateEmailThreadState({
  onSuccess,
  onError,
}: UseUpdateEmailThreadStateProps) {
  const queryClient = useQueryClient()

  //update status/via first
  const { mutate: updateStatus, isLoading: pendingStatusUpdate } = useMutation(
    updateReachOutStatusService,
    {
      onSuccess: async (data, params) => {
        await Promise.all([
          queryClient.invalidateQueries('/threads'),
          queryClient.invalidateQueries('/threads/{id}'),
          queryClient.invalidateQueries('/prospects/{prospectId}'),
        ])
        onSuccess?.(data)
      },
      onError,
    }
  )

  //update response type second
  const { mutate: updateResponseType, isLoading: pendingResponseTypeUpdate } =
    useMutation(updateReachOutResponseTypeService, {
      onSuccess: async (data) => {
        await Promise.all([
          queryClient.invalidateQueries('/threads'),
          queryClient.invalidateQueries('/threads/{id}'),
          queryClient.invalidateQueries('/prospects/{prospectId}'),
        ])
        onSuccess?.(data)
      },
      onError,
    })

  return {
    updateStatus,
    pendingStatusUpdate,
    updateResponseType,
    pendingResponseTypeUpdate,
  }
}

export default useUpdateEmailThreadState
