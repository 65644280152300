import SelectInput from '@trinsly/common/src/components/molecules/select-input'
import ThreadStateEnum from '@trinsly/common/src/types/thread-state-enum-type'

type EmailThreadStateFilterProps = {
  disabled: boolean
  value: ThreadStateEnum | null
  onChange: (value: ThreadStateEnum | null) => void
}

function EmailThreadStateFilter({
  disabled,
  onChange,
  value,
}: EmailThreadStateFilterProps) {
  return (
    <SelectInput
      label="With"
      placeholder="Select a reach-out status"
      disabled={disabled}
      onChange={(item) => onChange(item ?? null)}
      options={OPTIONS}
      value={value}
      itemRenderer={(item) => (item ? translate[item] : '')}
      valueRenderer={(value) =>
        value ? `Reach-out Status ${translate[value]}` : ''
      }
    />
  )
}

export default EmailThreadStateFilter

const OPTIONS = [
  ThreadStateEnum.COMPLETED,
  ThreadStateEnum.HARD_BOUNCED,
  ThreadStateEnum.IN_PROGRESS,
  ThreadStateEnum.RESPONDED,
]

const translate: Partial<Record<ThreadStateEnum, string>> = {
  [ThreadStateEnum.COMPLETED]: 'Completed',
  [ThreadStateEnum.HARD_BOUNCED]: 'Hard-bounced',
  [ThreadStateEnum.IN_PROGRESS]: 'In progress',
  [ThreadStateEnum.RESPONDED]: 'Responded',
}
