import { pickBy } from 'lodash'
import queryString from 'query-string'
import React from 'react'
import { useHistory } from 'react-router'
import { TabsProps } from '.'
import useForceUpdate from '../../../hooks/use-force-update'

export type TabsProviderProps = {} & Omit<TabsProps, 'className'>

type ContextProps = {
  params: object
  hashs: string[]
  getActiveTab: () => string
} & Omit<TabsProps, 'children' | 'className' | 'variant'>

const initialState: ContextProps = {
  id: '',
  defaultTab: '',
  params: {},
  hashs: [],
  onChange: () => {},
  getActiveTab: () => '',
}

export const Context = React.createContext<ContextProps>(initialState)
export const useContext = () => React.useContext(Context)

function Provider({
  children,
  variant,
  id: containerId,
  onChange,
  defaultTab,
}: TabsProviderProps) {
  const [tabState, setTabState] = React.useState({ activeTab: defaultTab })
  const history = useHistory()
  const { rerender } = useForceUpdate()
  const isState = variant === 'state'
  const isQueryString = variant === 'query-string'
  const isHashAnchor = variant === 'hash-anchor'
  const hashs = Object.keys(
    queryString.parse(history.location.hash)
  ) as string[]
  const params = queryString.parse(history.location.search)

  function handleChange(tabId: string) {
    if (isState) {
      setTabState((state) => ({ ...state, activeTab: tabId }))
      onChange?.(tabId)
      return
    }

    const hash = isHashAnchor ? `#${tabId}` : undefined
    const search = queryString.stringify(
      pickBy({ ...params, [containerId]: isQueryString ? tabId : undefined })
    )
    history.replace(
      pickBy({
        pathname: history.location.pathname,
        hash: hash,
        search: search,
      })
    )
    rerender()
    onChange?.(tabId)
  }

  function getActiveTab() {
    if (isState) {
      return tabState?.activeTab || ''
    }

    const selectedTab = (params[containerId] ||
      hashs[0] ||
      defaultTab ||
      '') as string
    return selectedTab
  }

  return (
    <Context.Provider
      value={{
        id: containerId,
        defaultTab,
        params,
        hashs,
        getActiveTab,
        onChange: handleChange,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Provider
