import api from '@trinsly/common/src/services/api'
import {
  eachMonthOfInterval,
  eachWeekOfInterval,
  format,
  parseISO,
} from 'date-fns'

export type GetProjectReportStatsServiceProps = {
  startDate: string
  endDate: string
  period: 'week' | 'month'
  projectId: string | number
}

async function getProjectReportStatsService({
  startDate,
  endDate,
  projectId,
  period,
}: GetProjectReportStatsServiceProps) {
  const startDateAsDate = parseISO(startDate)
  const endDateAsDate = parseISO(endDate)
  try {
    const response = (await api(`/projects/${projectId}/reports/stats`, {
      method: 'GET',
      params: {
        start: format(startDateAsDate, 'yyyy-MM-dd'),
        end: format(endDateAsDate, 'yyyy-MM-dd'),
        group: period,
      },
    })) as any

    const intervalParser =
      period === 'month' ? eachMonthOfInterval : eachWeekOfInterval
    const formatter = period === 'month' ? 'MMM' : 'w'
    const structure = {
      labels:
        intervalParser({
          start: startDateAsDate,
          end: endDateAsDate,
        }).map((date) => format(date, formatter)) || [],
      aggregate: {
        reachOutCount: response?.data?.aggregate?.reachOutCount || 0,
        responsesCount: response?.data?.aggregate?.responsesCount || 0,
        openCount: response?.data?.aggregate?.openCount || 0,
        interestedCount: response?.data?.aggregate?.interestedCount || 0,
      },
      data: {
        interested: response?.data?.interested || [],
        opened: response?.data?.opened || [],
        reachOuts: response?.data?.reachOuts || [],
        responded: response?.data?.responded || [],
      },
    }

    return structure
  } catch (error) {
    throw error
  }
}

export default getProjectReportStatsService
