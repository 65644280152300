import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation, useQueryClient } from 'react-query'
import createBatchService from 'services/create-batch-service'

type UseCreateBatchProps = {} & MutateApiHookType<any>

function useCreateBatch({ onSuccess, onError }: UseCreateBatchProps) {
  const queryClient = useQueryClient()
  const { mutate: fetch, isLoading: pending } = useMutation(
    createBatchService,
    {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries('/batches')
        onSuccess?.(data)
      },
      onError,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useCreateBatch
