import api from '@trinsly/common/src/services/api'
import ProspectType from 'types/prospect-type'

export type GetProspectEmploymentsServiceProps = {
  prospectId: ProspectType['id']
}

async function getProspectEmploymentsService({
  prospectId,
}: GetProspectEmploymentsServiceProps) {
  const response = (await api(`/prospects/${prospectId}/employments`, {
    method: 'GET',
  })) as any

  const employments = (response?.employments ||
    []) as ProspectType['employments']

  return employments
}

export default getProspectEmploymentsService
