import Card from '@trinsly/common/src/components/atoms/card'
import Clickable from '@trinsly/common/src/components/atoms/clickable'
import Column from '@trinsly/common/src/components/atoms/column'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import Modal from '@trinsly/common/src/components/molecules/modal'
import React from 'react'
import { useHistory } from 'react-router'
import CampaignType from 'types/campaign-type'

export type StagesLockedModalWrapperProps = {
  campaignId: CampaignType['id']
  children: React.ReactElement
  disabled: boolean
}

function StagesLockedModalWrapper({
  campaignId,
  disabled,
  children,
}: StagesLockedModalWrapperProps) {
  const history = useHistory()
  const [visible, setVisible] = React.useState(false)

  if (disabled) {
    return <>{children}</>
  }

  return (
    <React.Fragment>
      <Clickable onClick={() => setVisible(true)}>{children}</Clickable>
      <Modal
        visible={visible}
        onRequestClose={() => {
          setVisible(false)
        }}
      >
        <Card className="p-md">
          <Heading fontWeight="bold">Stages locked</Heading>
          <Separator size="sm" />
          <Text>
            If you want to add or remove stages you must duplicate this campaign
            to preserve your statistics.
          </Text>
          <Separator />
          <Row className="items-center justify-end">
            <Button size="sm" onClick={() => setVisible(false)}>
              Cancel
            </Button>
            <Separator />
            <Column className="min-w-56">
              <Button
                size="sm"
                intent="success"
                onClick={() =>
                  history.push(
                    `/campaigns/new?duplicating-campaign-id=${campaignId}`
                  )
                }
              >
                Duplicate Campaign
              </Button>
            </Column>
          </Row>
        </Card>
      </Modal>
    </React.Fragment>
  )
}

export default StagesLockedModalWrapper
