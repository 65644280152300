import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation, useQueryClient } from 'react-query'
import LinkProspectsToHotlistService from 'services/link-prospects-to-hotlist-service'
import ProspectType from 'types/prospect-type'

type UseLinkProspectsToHotlistProps = {} & MutateApiHookType<ProspectType>

function useLinkProspectsToHotlist({
  onSuccess,
  onError,
}: UseLinkProspectsToHotlistProps) {
  const queryClient = useQueryClient()
  const { mutate: fetch, isLoading: pending } = useMutation(
    LinkProspectsToHotlistService,
    {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries('/hotlists')
        onSuccess?.(data)
      },
      onError,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useLinkProspectsToHotlist
