import Column from '@trinsly/common/src/components/atoms/column'
import Row from '@trinsly/common/src/components/atoms/row'
import Text from '@trinsly/common/src/components/atoms/text'
import { format, parseISO } from 'date-fns'
import ThreadType from 'types/thread-type'
import StatusRenderer from './status-renderer'

export type DeliveredEmailListItemProps = {
  email: ThreadType['emails'][number]
}

function DeliveredEmailListItem({ email }: DeliveredEmailListItemProps) {
  return (
    <Row className="border-2 border-dashed border-gray-400 bg-gray-100 rounded-lg py-sm px-md gap-md flex-wrap items-center">
      <Text size="xs" fontWeight="bold" className="uppercase">
        Sent
      </Text>
      <Column className="flex-1 min-w-40">
        <Text size="xs" fontWeight="bold" className="uppercase">
          Stage #{email?.position || '#'}
        </Text>
        <Text size="sm">{email?.subject || '--'}</Text>
      </Column>
      <Text size="xs" fontWeight="bold">
        {email?.deliverAt
          ? format(parseISO(email.deliverAt), 'EEEE, L/dd')
          : '--'}
      </Text>
      <Text size="xs" fontWeight="bold">
        {email?.deliverAt
          ? format(parseISO(email.deliverAt), 'hh:mm aa')
          : '--'}
      </Text>
      <StatusRenderer email={email} />
    </Row>
  )
}

export default DeliveredEmailListItem
