import { ReactComponent as CheckSvg } from '@trinsly/common/src/assets/icons/check.svg'
import { ReactComponent as DoubleCheckSvg } from '@trinsly/common/src/assets/icons/double-check.svg'
import Row from '@trinsly/common/src/components/atoms/row'
import Text from '@trinsly/common/src/components/atoms/text'
import ThreadStateEnum from '@trinsly/common/src/types/thread-state-enum-type'
import ThreadType from 'types/thread-type'

type OpenedCellProps = {
  thread: ThreadType
}

function OpenedCell({ thread }: OpenedCellProps) {
  const openCount = thread?.openCount || 0
  const status = thread?.state

  let icon: any = <CheckSvg className="w-md h-md text-gray-300" />
  if (status === ThreadStateEnum.RESPONDED) {
    icon = <DoubleCheckSvg className="w-md h-md text-success-500" />
  } else if (openCount > 0) {
    icon = <CheckSvg className="w-md h-md text-success-500" />
  }

  if (
    status === ThreadStateEnum.CANCELED ||
    status === ThreadStateEnum.HARD_BOUNCED
  ) {
    icon = null
  }

  return (
    <Row className="items-center gap-sm">
      {icon}
      {openCount > 0 && <Text size="sm">({openCount})</Text>}
    </Row>
  )
}

export default OpenedCell
