import api from '@trinsly/common/src/services/api'
import HotlistType from 'types/hotlist-type'
import ProspectType from 'types/prospect-type'

type UnlinkProspectsToHotlistServiceProps = {
  hotlistId: HotlistType['id']
  prospectIds: ProspectType['id'][]
}

async function UnlinkProspectsToHotlistService({
  hotlistId,
  prospectIds,
}: UnlinkProspectsToHotlistServiceProps) {
  //TODO: bulk delete
  const response = await api(
    `/hotlists/${hotlistId}/prospects/${prospectIds?.[0]}`,
    {
      method: 'DELETE',
      // params: {
      //   ids: compact(prospectIds)?.join(','),
      // },
    }
  )
  return response as any
}

export default UnlinkProspectsToHotlistService
