import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation, useQueryClient } from 'react-query'
import linkProspectToProjectService from 'services/link-prospect-to-project-service'
import ProspectType from 'types/prospect-type'

type UseLinkProspectToProjectProps = {} & MutateApiHookType<ProspectType>

function useLinkProspectToProject({
  onSuccess,
  onError,
}: UseLinkProspectToProjectProps) {
  const queryClient = useQueryClient()
  const { mutate: fetch, isLoading: pending } = useMutation(
    linkProspectToProjectService,
    {
      onSuccess: async (data) => {
        queryClient.invalidateQueries('/projects/{id}')
        queryClient.invalidateQueries('/projects/{id}/reports/stats')
        queryClient.invalidateQueries('/prospects')
        onSuccess?.(data)
      },
      onError,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useLinkProspectToProject
