import * as Sentry from '@sentry/react'
import {
  EXTENSION_IMPERSONATE_USER_ID_KEY,
  TRINSLY_ACCESS_TOKEN,
  TRINSLY_EXPIRATION,
} from '@trinsly/common/src/services/api'
import useDeleteUserSession from 'api-hooks/use-delete-user-session'
import useGetUserSession from 'api-hooks/use-get-user-session'
import FullScreenSpinner from 'components/full-screen-spinner'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import {
  clearCookies,
  clearCredentials,
} from 'services/auth/destroy-user-session-service'
import {
  logoutOfChromeExtension,
  sendTokenToChromeExtension,
} from 'services/chrome/send-token-to-extension'
import UserType from 'types/user-type'
import * as LogRocketUtil from 'utils/logrocket'

export function needsToLogIn(): boolean {
  const accessToken = localStorage.getItem(TRINSLY_ACCESS_TOKEN)

  if (!accessToken) return true

  const expiry = localStorage.getItem(TRINSLY_EXPIRATION)

  if (!expiry) return true

  // NOTE: 1000 is for the conversion from milliseconds to seconds.
  return !expiry || new Date(parseInt(expiry) * 1000) < new Date()
}

function setSentryUser(user: UserType) {
  try {
    Sentry.setUser({
      id: user?.id.toString(),
      email: user.email,
    })
  } catch (error) {
    console.warn('Trinsly: Failed to set Sentry User in application.')
  }
}

export type AuthContextProps = {
  children: React.ReactNode
}

export type UseAuthProps = {
  isAuthenticated: boolean
  user?: UserType
  setUser: (newUser: UserType) => void
  accessToken?: string | null
  updateAccessToken: ({
    newToken,
    updateUserInfo,
  }: {
    newToken: string
    updateUserInfo?: boolean
  }) => void
  refetchUserSession: () => Promise<void>
  logOut: ({}) => void
  loggingOut: boolean
  //impersonateUserId?: number
}

export const AuthContext = React.createContext<UseAuthProps>({
  isAuthenticated: !needsToLogIn(),
  updateAccessToken: () => {},
  refetchUserSession: (() => {}) as any,
  setUser: () => {},
  logOut: () => {},
  loggingOut: false,
})

export default function ({ children }: AuthContextProps) {
  const accessToken = localStorage.getItem(TRINSLY_ACCESS_TOKEN)
  const signingOutRef = React.useRef(false)

  const [user, setUser] = useState<UserType>()
  const [authState, setAuthState] = React.useState<
    'authenticating' | 'not-authenticated' | 'authenticated'
  >(accessToken ? 'authenticating' : 'not-authenticated')

  const history = useHistory()
  const { data: userSession, refetch } = useGetUserSession({
    enabled: Boolean(accessToken),
    onMutate: () => {
      setAuthState('authenticating')
    },
    onSuccess: (user: UserType) => {
      setUser(user)
      sendTokenToChromeExtension({
        jwt: localStorage.getItem(TRINSLY_ACCESS_TOKEN) || '',
        impersonateUserId:
          localStorage.getItem(EXTENSION_IMPERSONATE_USER_ID_KEY) || '',
      })
      setSentryUser(userSession)
      identifyLogRocketUser(userSession)
      identifyInHeap(userSession)
      setAuthState('authenticated')
    },
    onError: (error: any) => {
      console.log('User Session - Unauthorized - Please Log in', error)
      handleSignOut()
    },
  })

  const { fetch: logOut } = useDeleteUserSession({
    onSuccess: () => {
      handleSignOut()
    },
    onError: () => {
      handleSignOut()
    },
  })

  function handleUpdateAccessToken({
    newToken,
    updateUserInfo,
  }: {
    newToken: string
    updateUserInfo?: boolean
  }) {
    localStorage.setItem(TRINSLY_ACCESS_TOKEN, newToken)

    if (updateUserInfo) {
      refetch()
    }
  }

  function handleSignOut() {
    setUser(undefined)
    clearCredentials()
    clearCookies()
    logoutOfChromeExtension()
    setAuthState('not-authenticated')
    try {
      //@ts-ignore
      window.Beacon('logout')
    } catch (error) {}

    signingOutRef.current = false
    history.push('/log-in')
  }

  // React.useEffect(() => {
  //   if (localStorage.getItem(TRINSLY_ACCESS_TOKEN)) {
  //     refetch()
  //   }
  // }, [])

  //logout message handler
  React.useEffect(() => {
    const handleMessage = (event: any) => {
      const eventType = String(event?.data)
      switch (eventType) {
        case 'trinsly/log-out':
          if (!signingOutRef.current) {
            signingOutRef.current = true
            logOut({})
          }
          break
      }
    }
    window.addEventListener('message', handleMessage)
    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [logOut])

  if (authState === 'authenticating' || signingOutRef.current) {
    return <FullScreenSpinner />
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: authState === 'authenticated',
        user,
        setUser,
        accessToken: localStorage.getItem(TRINSLY_ACCESS_TOKEN),
        updateAccessToken: handleUpdateAccessToken,
        refetchUserSession: refetch as any,
        logOut: () => {
          if (!signingOutRef.current) {
            signingOutRef.current = true
            logOut({})
          }
        },
        loggingOut: signingOutRef.current,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => React.useContext(AuthContext)

function identifyLogRocketUser(user: UserType) {
  try {
    LogRocketUtil.identify(user.id, {
      name: user.name,
      email: user.email,
      email_auth: user.emailAuth || 'No Email Authorized',
      organization_id: user.organizationId,
      organization_name: user?.organizationName,
      two_factor_auth_status: user.twoFactorAuthStatus,
      paywall_info: user?.paywallInfo,
    })
  } catch (error) {}
}

function identifyInHeap(user: UserType) {
  //@ts-ignore
  if (!window.heap) {
    //TODO: Check process env and heap env id
    console.info('Unable to Track in Heap, no install found in window object')
    return
  }
  try {
    //@ts-ignore
    window.heap.identify(user.id.toString())

    //@ts-ignore
    window.heap.addUserProperties({
      email: user.email,
      userName: user.name,
      organizationId: user.organizationId,
      organizationRole: user.organizationRole,
      organizationName: user.organizationName,
      joinedAt: user.createdAt,
      emailProvider: user.email,
    })

    //TODO: fix paywall flags in payloads
    //@ts-ignore
    window.heap.addUserProperties({
      isPaywalled: (user.paywallInfo.status === 'paywalled').toString(),
    })
  } catch (error) {
    console.info('Heap unable to track session')
    //swallow - don't wanna mess up
  }
}
