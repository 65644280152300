//@ts-ignore
import ImageUploader from 'quill-image-uploader'
import { Quill } from 'react-quill'
//@ts-ignore
import S3Upload from 'react-s3-uploader/s3upload'
import getSignedImageUrlFromS3Service from '../../../../services/upload-image-to-s3-service'

Quill.register('modules/imageUploader', ImageUploader)

const s3BucketName = process.env.REACT_APP_S3_BUCKET_NAME || ''
const S3_URL = `https://${s3BucketName}.s3.amazonaws.com`

interface S3FileInfo {
  file: {
    name: string
  }
  fileUrl: string
  filename: string
  signedUrl: string
}

function upload(file: File) {
  return new Promise((resolve, reject) => {
    const uploadOptions = {
      contentDisposition: 'auto',
      files: [file],
      getSignedUrl: (file: File, callback: Function) => {
        getSignedImageUrlFromS3Service({
          filename: file.name,
          mimetype: file.type,
        }).then((props: any) => {
          callback(props)
        })
      },
      onError: console.log,
      onFinishS3Put: (info: S3FileInfo, file: File) => {
        resolve(info.fileUrl)
      },
      s3path: '',
      signingUrl: '/s3/sign',
      uploadRequestHeaders: {},
    }

    const s3Upload = new S3Upload(uploadOptions)
  })
}

const s3ImageModule = {
  imageUploader: {
    upload,
  },
}

export default s3ImageModule
