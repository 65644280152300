import api from '@trinsly/common/src/services/api'
import IntegrationType from 'types/integration-type'

type GetIntegrationsServiceProps = {}

async function getIntegrationsService({}: GetIntegrationsServiceProps) {
  const response = (await api(`/integrations`, {
    method: 'GET',
  })) as any as IntegrationType[]

  return response
}

export default getIntegrationsService
