import api from '@trinsly/common/src/services/api'
import ProspectType from 'types/prospect-type'

type UploadProspectResumeServiceProps = {
  prospectId: ProspectType['id']
  file: File
}

async function uploadProspectResumeService({
  prospectId,
  file,
}: UploadProspectResumeServiceProps) {
  let formData = new FormData()
  formData.append('prospect[resume]', file)
  formData.append('prospect[file_name]', file.name)
  const response = await api(`/prospects/${prospectId}/resume`, {
    method: 'POST',
    body: formData,
  })
  return response as ProspectType
}

export default uploadProspectResumeService
