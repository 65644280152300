import Column from '@trinsly/common/src/components/atoms/column'
import BarChart from '@trinsly/common/src/components/molecules/bar-chart'
import useGetProjectReportStats from 'api-hooks/use-get-project-report-stats'
import { endOfDay } from 'date-fns'
import { useFormContext } from '../form-provider'
import colors from './kpi-list/colors'

type KPIBarChartProps = {
  period: 'week' | 'month'
}

function KPIBarChart({ period }: KPIBarChartProps) {
  const { project } = useFormContext()
  const { data: report, pending: pendingReport } = useGetProjectReportStats({
    projectId: project?.id || 0,
    period,
    startDate: project?.startDate || project?.createdAt || ``,
    endDate: project?.endDate || endOfDay(new Date()).toISOString(),
  })

  return (
    <Column className="-mx-px px-md" style={{ maxHeight: 160 }}>
      <BarChart
        pending={pendingReport}
        labels={report?.labels || []}
        datasets={[
          {
            label: 'Reach Outs',
            backgroundColor: colors.TEAL,
            borderColor: colors.TEAL,
            data: report?.data?.reachOuts || [],
          },
          {
            label: 'Responded',
            backgroundColor: colors.SALMON,
            borderColor: colors.SALMON,
            data: report?.data?.responded || [],
          },
          {
            label: 'Interested',
            backgroundColor: colors.YELLOW,
            borderColor: colors.YELLOW,
            data: report?.data?.interested || [],
          },
          {
            label: 'Opened',
            backgroundColor: colors.BLUE,
            borderColor: colors.BLUE,
            data: report?.data?.opened || [],
          },
        ]}
      />
    </Column>
  )
}

export default KPIBarChart
