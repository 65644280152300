import { LabelProps } from '@trinsly/common/src/components/atoms/label'
import { SelectProps } from '@trinsly/common/src/components/molecules/select'
import SelectInput from '@trinsly/common/src/components/molecules/select-input'
import usePageableState from '@trinsly/common/src/hooks/use-pageable-state'
import useGetUniversities from 'api-hooks/use-get-universities'
import ProspectType from 'types/prospect-type'

type SelectUniversityProps = {
  label: LabelProps['children']
  error?: string
  required?: boolean
} & Omit<
  SelectProps<ProspectType['educations'][number]['universityAttributes']>,
  'itemRender' | 'options' | 'pending'
>

function SelectUniversity(props: SelectUniversityProps) {
  const { pageable, setSearch } = usePageableState({
    page: 1,
    perPage: 999,
  })
  const { data: universities, pending: pendingUniversities } =
    useGetUniversities(pageable)

  return (
    <SelectInput
      {...props}
      options={universities}
      pending={pendingUniversities}
      itemRenderer={(item) => item.name}
      onInputChange={(value) => setSearch(value)}
      filterWith={({
        inputValue,
        option,
      }: {
        inputValue: string
        option: ProspectType['educations'][number]['universityAttributes']
      }) => {
        return option?.name
          ?.toLowerCase?.()
          .includes?.(inputValue?.toLowerCase?.())
      }}
    />
  )
}

export default SelectUniversity
