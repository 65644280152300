import SelectInput from '@trinsly/common/src/components/molecules/select-input'
import api from '@trinsly/common/src/services/api'
import useGetUserSession from 'api-hooks/use-get-user-session'
import React from 'react'
import { Membership } from 'types/settings-type'

type OwnerFilterProps = {
  value: Id | null
  onChange: (value: Id) => void
}

function OwnerFilter({ onChange, value }: OwnerFilterProps) {
  const [memberships, setMemberships] = React.useState<Membership[]>([])

  const { data: userSession, pending: pendingUserSession } = useGetUserSession(
    {}
  )

  React.useEffect(() => {
    ;(async () => {
      try {
        const memberships = (await api(`/memberships`, {
          method: 'GET',
        })) as Membership[]
        setMemberships(memberships)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])

  const options =
    memberships.sort((membership) =>
      membership.userId === userSession?.id ? -1 : 1
    ) || []

  return (
    <SelectInput
      label="Which are"
      placeholder="Select an owner"
      pending={pendingUserSession}
      onChange={(item) => onChange((item?.userId as any) || null)}
      value={options.find((option) => String(option.userId) === String(value))}
      options={options}
      itemRenderer={(item: any) =>
        item.userId === userSession?.id
          ? `Me${userSession ? ` - ${userSession?.name}` : ''}`
          : item.userName || item.userEmail
      }
      valueRenderer={(value: any) =>
        `Owned by ${value?.userName || value?.userEmail}`
      }
    />
  )
}

export default OwnerFilter
