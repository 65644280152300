import Card from '@trinsly/common/src/components/atoms/card'
import Clickable from '@trinsly/common/src/components/atoms/clickable'
import Column from '@trinsly/common/src/components/atoms/column'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import Modal from '@trinsly/common/src/components/molecules/modal'
import React from 'react'
import { useHistory } from 'react-router'
import CampaignType from 'types/campaign-type'

type DuplicateCampaignWithAttachmentsConfirmationModalWrapperProps = {
  campaign: CampaignType | undefined
  children: React.ReactElement
  disabled: boolean
}

function DuplicateCampaignWithAttachmentsConfirmationModalWrapper({
  campaign,
  disabled,
  children,
}: DuplicateCampaignWithAttachmentsConfirmationModalWrapperProps) {
  const history = useHistory()
  const [visible, setVisible] = React.useState(false)

  if (disabled) {
    return <>{children}</>
  }

  return (
    <React.Fragment>
      <Clickable
        onClick={(event) => {
          setVisible(true)
        }}
      >
        {children}
      </Clickable>
      <Modal
        size="xs"
        visible={visible}
        onRequestClose={() => {
          setVisible(false)
        }}
      >
        <Card className="p-md">
          <Heading fontWeight="bold">
            Duplicating campaign with attachments
          </Heading>
          <Separator size="sm" />
          <Text>
            Be aware when duplicating this campaign you will also duplicate the
            attachments from each email in the original campaign
          </Text>
          <Separator />
          <Row className="items-center justify-end">
            <Button size="sm" onClick={() => setVisible(false)}>
              Cancel
            </Button>
            <Separator size="sm" />
            <Column className="min-w-56">
              <Button
                size="sm"
                intent="warning"
                onClick={() =>
                  history.push(
                    `/campaigns/new?duplicating-campaign-id=${campaign?.id}`
                  )
                }
              >
                Duplicate Campaign
              </Button>
            </Column>
          </Row>
        </Card>
      </Modal>
    </React.Fragment>
  )
}

export default DuplicateCampaignWithAttachmentsConfirmationModalWrapper
