import { ReactComponent as BronzeTrophySvg } from '@trinsly/common/src/assets/icons/bronze-trophy.svg'
import { ReactComponent as GoldenTrophySvg } from '@trinsly/common/src/assets/icons/golden-trophy.svg'
import { ReactComponent as SilverTrophySvg } from '@trinsly/common/src/assets/icons/silver-trophy.svg'

import Card from '@trinsly/common/src/components/atoms/card'
import Column from '@trinsly/common/src/components/atoms/column'
import DateRangeInput from '@trinsly/common/src/components/atoms/date-range-input'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Link from '@trinsly/common/src/components/atoms/link'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Table from '@trinsly/common/src/components/atoms/table'
import Text from '@trinsly/common/src/components/atoms/text'
import Select from '@trinsly/common/src/components/molecules/select'
import NonIdealState from '@trinsly/common/src/components/organisms/non-ideal-state'

import { useCallback, useState } from 'react'

import useGetLeaderboardStats from 'api-hooks/use-get-leaderboard-stats'
import PrivateLayout from 'components/private-layout'
import Tooltip from 'components/tooltip'

import {
  calcDates,
  LeaderboardDateRangeEnum,
  leaderboardDateRangeOptions,
} from './helpers'

const initialDateRange = leaderboardDateRangeOptions[0].id

/**
 * On client - take UI selected dates +> conver to UTC timestamps based on offsets
 * */

const LeaderboardScreen = ({}) => {
  const [offSet, setOffSet] = useState<number>(0)
  const [dateRange, setDateRange] =
    useState<LeaderboardDateRangeEnum>(initialDateRange)

  const [startDate, setStartDate] = useState<moment.Moment>(
    calcDates(initialDateRange, offSet).startDate
  )
  const [endDate, setEndDate] = useState<moment.Moment>(
    calcDates(initialDateRange, offSet).endDate
  )

  const handleDateRangeChange = useCallback(
    (dateRange: LeaderboardDateRangeEnum) => {
      setDateRange(dateRange)
      if (dateRange === LeaderboardDateRangeEnum.CUSTOM) return

      const { endDate, startDate } = calcDates(dateRange, offSet)
      setStartDate(startDate)
      setEndDate(endDate)
    },
    [offSet]
  )

  const { data: leaderboard, pending: leaderboardPending } =
    useGetLeaderboardStats({
      startDate: startDate?.format('YYYY-MM-DD'),
      endDate: endDate?.format('YYYY-MM-DD'),
      tags: [],
    })

  const isNonIdealState = !leaderboardPending && !leaderboard?.length

  return (
    <PrivateLayout>
      <Column className="py-md px-lg bg-white shadow">
        <Row className="items-start gap-md justify-between">
          <Row className="items-center gap-xs">
            <Heading size="xl" fontWeight="bold">
              Leaderboard
            </Heading>
            <Tooltip>
              <span className="w-24 h-auto">
                <Text size="xs">
                  Your Leaderboard is ranked based on net-new reach outs in the
                  period selected. Learn more{' '}
                  <Link
                    size="xs"
                    intent="info"
                    href="https://help.trinsly.com/article/26-the-trinsly-leaderboard"
                  >
                    here
                  </Link>
                  .
                </Text>
              </span>
            </Tooltip>
          </Row>
          <Row className="items-center gap-md justify-end">
            {dateRange === LeaderboardDateRangeEnum.CUSTOM && (
              <Column>
                <DateRangeInput
                  dateRange={dateRange}
                  endDate={endDate}
                  setDateRange={handleDateRangeChange}
                  setEndDate={setEndDate}
                  setStartDate={setStartDate}
                  startDate={startDate}
                />
              </Column>
            )}
            <Column className="min-w-52">
              <Select
                value={dateRange}
                onChange={(value) =>
                  handleDateRangeChange(value || LeaderboardDateRangeEnum.ALL)
                }
                options={leaderboardDateRangeOptions.map((d) => d.id)}
                itemRenderer={(item) =>
                  leaderboardDateRangeOptions.find((d) => d.id === item)
                    ?.name || ''
                }
                clearable={false}
              />
            </Column>
          </Row>
        </Row>
      </Column>
      <Separator />
      <Column className="px-lg">
        <Text className="text-right" size="xs">
          All Stats Calculated Based on UTC
        </Text>
        <Separator size="xs" />
        <Card className="overflow-hidden">
          {isNonIdealState ? (
            <Column className="p-md">
              <NonIdealState message="No leaderboard stats yet" />
            </Column>
          ) : (
            <Table
              pending={leaderboardPending}
              data={leaderboard || []}
              pageSize={50}
              rows={(item, row) => {
                const isTop3 = row.index <= 2
                const leaderColors = ['#FDA137', '#575757', '#D9B48B']
                const leaderGradientColors = [
                  'linear-gradient(90.02deg, #FDA1372b, #FDA13710)',
                  'linear-gradient(90.02deg, #5757572b, #57575710)',
                  'linear-gradient(90.02deg, #D9B48B2b, #D9B48B10)',
                ]
                return {
                  style: {
                    borderLeft: `6px solid ${
                      isTop3 ? leaderColors[row.index] : 'transparent'
                    }`,
                    background: leaderGradientColors[row.index],
                  },
                }
              }}
              columns={[
                {
                  width: 400,
                  header: 'Member name',
                  cell: (item, data) => {
                    const place = data.row.index
                    const badgeLeaderColors = ['#FD9F37', '#BABABA', '#D9B48B']
                    const isTop3 = data.row.index <= 2
                    return (
                      <Row className="items-center">
                        <Column
                          className={`items-center justify-center rounded-full ${
                            isTop3
                              ? 'text-white'
                              : 'border border-gray-200 text-gray-900'
                          }`}
                          style={{
                            minWidth: 40,
                            minHeight: 40,
                            maxWidth: 40,
                            maxHeight: 40,
                            backgroundColor:
                              badgeLeaderColors[place] || 'transparent',
                          }}
                        >
                          <Text
                            color={isTop3 ? 'white' : 'gray-900'}
                            fontWeight="bold"
                          >
                            {data.row.index + 1}
                          </Text>
                        </Column>
                        <Separator size="sm" />
                        {`${item.userName} <${item.userEmail}>`}
                      </Row>
                    )
                  },
                },
                {
                  width: 180,
                  header: 'New reach outs',
                  cell: (item) => (
                    <Text fontWeight="bold">{item.reachOutsLabel}</Text>
                  ),
                },
                {
                  width: 150,
                  header: 'Responded',
                  cell: (item) => (
                    <Text fontWeight="bold">{item.respondedLabel}</Text>
                  ),
                },
                {
                  width: 150,
                  header: 'Interested',
                  cell: (item) => (
                    <Text fontWeight="bold">{item.interestedLabel}</Text>
                  ),
                },
                {
                  width: 150,
                  header: 'Opened',
                  cell: (item) => (
                    <Text fontWeight="bold">{item.openedLabel}</Text>
                  ),
                },
                {
                  width: 150,
                  header: 'Hard bounced',
                  cell: (item) => (
                    <Text fontWeight="bold">{item.hardBouncedLabel}</Text>
                  ),
                },
                {
                  cell: (item, data) => (
                    <Row className="items-center justify-end">
                      {
                        {
                          '0': <GoldenTrophySvg className="w-lg h-lg" />,
                          '1': <SilverTrophySvg className="w-lg h-lg" />,
                          '2': <BronzeTrophySvg className="w-lg h-lg" />,
                        }[String(data.row.index)]
                      }
                    </Row>
                  ),
                },
              ]}
            />
          )}
        </Card>
      </Column>
      <Separator size="xl" />
    </PrivateLayout>
  )
}

export default LeaderboardScreen
