import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation, useQueryClient } from 'react-query'
import deleteCalendlyIntegrationService from 'services/settings/delete-calendly-integration-service'

export type UseDeleteCalendlyIntegrationProps = {} & MutateApiHookType<void>

function useDeleteCalendlyIntegration({
  onSuccess,
  onError,
  onMutate,
}: UseDeleteCalendlyIntegrationProps) {
  const queryClient = useQueryClient()

  const { mutate: fetch, isLoading: pending } = useMutation(
    deleteCalendlyIntegrationService,
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('/settings')
        onSuccess?.()
      },
      onError,
      onMutate,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useDeleteCalendlyIntegration
