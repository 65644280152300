import Column from '@trinsly/common/src/components/atoms/column'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Spinner from '@trinsly/common/src/components/atoms/spinner'
import useDebounce from '@trinsly/common/src/hooks/use-debounce'
import useForm from '@trinsly/common/src/hooks/use-form'
import useUpdateUserSettings from 'api-hooks/use-update-user-settings'
import { toast } from 'react-toastify'
import TimeZoneSelect from './time-zone-select'

export type TimeZoneSectionProps = {
  settingsPending: boolean
  userSettings: any
}

const TimeZoneSection = ({
  settingsPending,
  userSettings,
}: TimeZoneSectionProps) => {
  const { fetch: updateTimezone, pending: updateTimezonePending } =
    useUpdateUserSettings({
      onSuccess: (data) => {
        toast('Time zone updated successfully!', {
          type: 'success',
        })
      },
    })

  const { setValue, getValue, submit } = useForm({
    initialValues: {
      timeZone: userSettings?.timeZone?.name,
    },
    onSubmit: ({ timeZone }) => {
      updateTimezone({ timezone: timeZone })
    },
  })

  useDebounce({
    enabled: !settingsPending && getValue('timeZone') ? true : false,
    value: getValue('timeZone'),
    onDebounce: (debouncedValue) => {
      submit({ timeZone: debouncedValue })
    },
  })
  return (
    <>
      <Column className="flex-1 justify-center items-start">
        <Heading
          size="sm"
          fontWeight="bold"
          color="gray-500"
          className="uppercase"
        >
          Time Zone
        </Heading>
        <Separator size="sm" />
        <Row style={{ width: '26rem' }} className="justify-end items-end">
          <Column className="w-96">
            <TimeZoneSelect
              label=""
              placeholder="Select a time zone"
              value={getValue('timeZone')}
              onChange={(value) => setValue('timeZone', value)}
              disabled={updateTimezonePending}
            />
          </Column>
          <Column className="flex-1 h-10 justify-center items-center">
            {updateTimezonePending && <Spinner />}
          </Column>
        </Row>
      </Column>
    </>
  )
}

export default TimeZoneSection
