import { useQuery } from 'react-query'
import {
  calculateOpenedCount,
  campaignResponseRate,
  zeroNotNaN,
} from 'screens/dashboard-screen/campaign-section'

import getLeaderboardStatsService, {
  GetLeaderboardStatsServiceProps,
} from 'services/get-leaderboard-stats-service'
import LeaderboardMemberStatsType from 'types/leaderboard-member-stats-type'
import useGetUserSession from './use-get-user-session'

type UseGetLeaderboardStatsProps = {} & GetLeaderboardStatsServiceProps

function useGetLeaderboardStats({
  startDate,
  endDate,
  tags,
}: UseGetLeaderboardStatsProps) {
  const { data: currentUser, pending: userPending } = useGetUserSession({})
  const shouldFetch = !userPending
  const { data, error, status } = useQuery(
    ['/stats/leaderboard_stats', startDate, endDate, tags],
    () =>
      getLeaderboardStatsService({
        startDate,
        endDate,
        tags,
      }),
    {
      enabled: shouldFetch,
      staleTime: shouldFetch ? 1800000 : 0,
    }
  )

  const reduced = data?.map?.((stats, index) =>
    leaderboardRowData(currentUser, index, stats)
  )
  const sorted = reduced?.sort?.((a, b) => {
    return b.reachOutCount - a.reachOutCount
  })

  return {
    data: sorted || [],
    pending: status === 'loading' || userPending,
    error,
  }
}

export default useGetLeaderboardStats

const leaderboardRowData = (
  currentUser: User,
  i: number,
  member: LeaderboardMemberStatsType
) => {
  const {
    hardBouncedCount,
    inProgressCount,
    interestedCount,
    openCount,
    reachOutCount,
    responsesCount,
    userEmail,
    userId,
    userName: origUserName,
  } = member

  // reach outs
  const reachOutsLabel = `${zeroNotNaN(
    reachOutCount
  )} (${inProgressCount} in progress)`

  // responded
  const respondedLabel = `${responsesCount} (${campaignResponseRate(
    responsesCount,
    reachOutCount
  )})`

  // interested
  const interestedLabel = `${interestedCount} (${campaignResponseRate(
    interestedCount,
    reachOutCount
  )})`

  // opened
  const opens = calculateOpenedCount(openCount, responsesCount)
  const openedLabel = `${opens} (${campaignResponseRate(opens, reachOutCount)})`

  // hard bounced
  const hardBouncedLabel = `${hardBouncedCount} (${campaignResponseRate(
    zeroNotNaN(hardBouncedCount),
    zeroNotNaN(reachOutCount)
  )})`

  // other
  const position = i + 1

  const userName = translateUserName({
    currentUser,
    userId,
    userName: origUserName,
  })

  return {
    hardBouncedCount,
    hardBouncedLabel,
    interestedCount,
    interestedLabel,
    openCount: opens,
    openedLabel,
    position,
    reachOutCount,
    reachOutsLabel,
    respondedLabel,
    responsesCount,
    userEmail,
    userId,
    userName,
  }
}

const translateUserName = ({
  currentUser,
  userId,
  userName,
}: {
  currentUser: User
  userId: number
  userName: string
}) => {
  return currentUser.id === userId ? `${userName} (you)` : userName
}
