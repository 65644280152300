import api from '@trinsly/common/src/services/api'

export type CreateSignatureServiceProps = {
  signature: string
}

export default async function updateUserSignatureService({
  signature,
}: CreateSignatureServiceProps) {
  const response = await api('/signature', {
    body: { signature: { signature } },
    method: 'PUT',
  })

  return response
}
