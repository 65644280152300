import api from '@trinsly/common/src/services/api'

export type CreateCheckoutSessionServiceProps = {
  planId: string
  seatCount: number
}

async function createCheckoutSessionService({
  planId,
  seatCount = 1,
}: CreateCheckoutSessionServiceProps) {
  const response = (await api(`/checkout`, {
    method: 'POST',
    body: { planId, seats: seatCount },
  })) as any
  return response?.url
}

export default createCheckoutSessionService
