import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation, useQueryClient } from 'react-query'
import updateProspectService from 'services/update-prospect-service'
import ProspectType from 'types/prospect-type'

export type UseUpdateProspectProps = {} & MutateApiHookType<ProspectType>

function useUpdateProspect({ onSuccess, onError }: UseUpdateProspectProps) {
  const queryClient = useQueryClient()
  const { mutate: fetch, isLoading: pending } = useMutation(
    updateProspectService,
    {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries('/prospects/{prospectId}')
        onSuccess?.(data)
      },
      onError,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useUpdateProspect
