import Card from '@trinsly/common/src/components/atoms/card'
import Column from '@trinsly/common/src/components/atoms/column'
import Form from '@trinsly/common/src/components/atoms/form'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Button from '@trinsly/common/src/components/molecules/button'
import Modal from '@trinsly/common/src/components/molecules/modal'
import TextInput from '@trinsly/common/src/components/molecules/text-input'
import useForm from '@trinsly/common/src/hooks/use-form'
import required from '@trinsly/common/src/validations/required'
import useCreateProspectEmployment from 'api-hooks/use-create-prospect-employment'
import React from 'react'
import { toast } from 'react-toastify'
import ProspectType from 'types/prospect-type'
import SelectCompany from './select-company'

type EmploymentFormModalProps = {
  prospectId: ProspectType['id'] | undefined
  visible: boolean
  onRequestClose: () => void
}

function EmploymentFormModal({
  prospectId,
  visible,
  onRequestClose,
}: EmploymentFormModalProps) {
  const { fetch: createEmployment, pending: pendingCreateEmployment } =
    useCreateProspectEmployment({
      onSuccess: () => {
        toast.success('Employment created!')
        onRequestClose?.()
      },
      onError: () => {
        toast.error('Error creating Employment')
      },
    })

  const { getValue, setValue, getError, submit, reset } = useForm({
    onSubmit: (employment: ProspectType['employments'][number]) => {
      createEmployment({ employment, prospectId: prospectId! })
    },
    validations: {
      companyAttributes: [required],
    },
  })

  React.useEffect(() => {
    if (visible) {
      reset()
    }
  }, [visible])

  return (
    <Modal visible={visible} onRequestClose={onRequestClose}>
      <Card>
        <Form onSubmit={submit}>
          <Column className="px-md py-xl">
            <Heading size="xl" fontWeight="bold">
              New Employment
            </Heading>
            <Separator />
            <SelectCompany
              creatable={true}
              label="Company"
              value={getValue('companyAttributes') || null}
              onCreate={(value) => {
                setValue('companyAttributes', { name: value })
              }}
              onChange={(value) => {
                setValue('companyAttributes', value)
              }}
              error={getError('companyAttributes')}
            />
            <Separator />
            <TextInput
              label="Role"
              value={getValue('role')}
              onChange={(value) => setValue('role', value)}
              error={getError('role')}
            />
            <Separator />
            <Row>
              <TextInput
                label="Start Date"
                type="date"
                value={getValue('startTime')}
                onChange={(value) => setValue('startTime', value)}
                error={getError('startTime')}
              />
              <Separator />
              <TextInput
                type="date"
                label="End Date"
                value={getValue('endTime')}
                onChange={(value) => setValue('endTime', value)}
                error={getError('endTime')}
              />
            </Row>
          </Column>
          <Modal.Footer>
            <Button className="min-w-28" onClick={onRequestClose}>
              Cancel
            </Button>
            <Separator size="sm" />
            <Button
              type="submit"
              className="min-w-40"
              intent="success"
              pending={pendingCreateEmployment}
            >
              Create
            </Button>
          </Modal.Footer>
        </Form>
      </Card>
    </Modal>
  )
}

export default EmploymentFormModal
