import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation, useQueryClient } from 'react-query'
import updateProjectService from 'services/update-project-service'
import ProjectType from 'types/project-type'

type UseUpdateProjectProps = {} & MutateApiHookType<ProjectType>

function useUpdateProject({ onSuccess, onError }: UseUpdateProjectProps) {
  const queryClient = useQueryClient()
  const { mutate: fetch, isLoading: pending } = useMutation(
    updateProjectService,
    {
      onSuccess: async (data) => {
        queryClient.invalidateQueries('/projects')
        queryClient.invalidateQueries('/projects/{id}/reports/stats')
        await queryClient.invalidateQueries('/projects/{id}')
        onSuccess?.(data)
      },
      onError,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useUpdateProject
