import React from 'react'
import NotificationType from './notification-type'

type ContextProps = {
  notifications: NotificationType[]
  addNotification: (notification: NotificationType) => void
  removeNotification: (notification: NotificationType) => void
  clearAllNotifications: () => void
}

const Context = React.createContext<ContextProps>({} as any)

function NotificationsProvider({
  children,
}: {
  children: React.ReactNode | React.ReactNode[]
}) {
  const [notifications, setNotifications] = React.useState<NotificationType[]>(
    []
  )

  return (
    <Context.Provider
      value={{
        notifications,
        addNotification: (notification) => {
          if (!notifications?.some?.(({ key }) => notification.key === key)) {
            setNotifications([...notifications, notification])
          }
        },
        removeNotification: (notification) => {
          setNotifications(
            notifications?.filter(
              (currentNotification) =>
                currentNotification.key !== notification?.key
            )
          )
        },
        clearAllNotifications: () => {
          setNotifications([])
        },
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default NotificationsProvider

export const useNotificationsContext = () => React.useContext(Context)
