import api from '@trinsly/common/src/services/api'

export default async function startMicrosoftAuthService({
  email,
}: {
  email: string
}): Promise<string> {
  const response = (await api('/outlook', { method: 'GET' })) as any
  const url = `${response.redirect}&prompt=select_account&state=${email}`
  return url
}
