import { ReactComponent as ChevronLeftSvg } from '@trinsly/common/src/assets/icons/chevron-left.svg'
import { ReactComponent as ChevronRightSvg } from '@trinsly/common/src/assets/icons/chevron-right.svg'
import Column from '@trinsly/common/src/components/atoms/column'
import Row from '@trinsly/common/src/components/atoms/row'
import Text from '@trinsly/common/src/components/atoms/text'
import IconButton from '@trinsly/common/src/components/molecules/icon-button'
import moment from 'moment'

type DatePickerProps = {
  daysAfter: number
  minDaysAfter: number
  onChange: (value: number) => void
  disabled?: boolean
}

function DatePicker({
  daysAfter,
  disabled,
  minDaysAfter,
  onChange,
}: DatePickerProps) {
  const dateCopy =
    daysAfter === 0
      ? `Today, ${moment().format('dddd, M/D')}`
      : moment().add(daysAfter, 'd').format('dddd, M/D')

  return (
    <Row className="items-center">
      <IconButton
        variant="outline"
        disabled={daysAfter <= minDaysAfter + 1 || disabled}
        onClick={() => onChange(daysAfter - 1)}
      >
        <ChevronLeftSvg />
      </IconButton>
      <Column style={{ minWidth: 210 }}>
        <Text color="primary-500" fontWeight="bold" className="text-center">
          {dateCopy}
        </Text>
      </Column>
      <IconButton
        variant="outline"
        onClick={() => onChange(daysAfter + 1)}
        disabled={disabled}
      >
        <ChevronRightSvg />
      </IconButton>
    </Row>
  )
}

export default DatePicker
