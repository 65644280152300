import api from '@trinsly/common/src/services/api'
import PageableType from '@trinsly/common/src/types/pageable-type'
import { pickBy } from 'lodash'
import ProspectType from 'types/prospect-type'

export type GetUniversitiesServiceProps = {} & PageableType

async function getUniversitiesService(pageable: GetUniversitiesServiceProps) {
  const params = pickBy({
    q: pageable?.search,
    page: pageable?.page,
    per_page: pageable?.perPage,
  }) as any

  const response = await api(`/universities/autocomplete`, {
    method: 'GET',
    params,
  })

  const { total = 0, results: universities } = response as any

  return {
    total,
    universities,
  } as {
    universities: ProspectType['educations'][number]['universityAttributes'][]
    total: number
  }
}

export default getUniversitiesService
