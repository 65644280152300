import Column from '@trinsly/common/src/components/atoms/column'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Spinner from '@trinsly/common/src/components/atoms/spinner'
import PrivateLayout from 'components/private-layout'
import { useNotificationsContext } from 'providers/notifications-provider'
import {
  INFO_TYPE,
  SUCCESS_TYPE,
} from 'providers/notifications-provider/notification-list-item'
import React from 'react'
import ActivityLogSection from './activity-log-section'
import CampaignsSection from './campaign-section'
import OverallStatsSection from './overall-stats-section'
import ReachOutsSection from './reach-outs-section'
import UnsentReachoutsSection from './unsent-reach-outs-section'

type DashboardScreenProps = {}
function DashboardScreen({}: DashboardScreenProps) {
  const { addNotification } = useNotificationsContext()

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    if (params.get('entry') === 'chrome') {
      addNotification({
        key: 'chrome-logged-in',
        message:
          'You are now logged into the Talent CRM Chrome extension. You can close this tab.',
        persist: 1,
        type: SUCCESS_TYPE,
        variant: 'translucent',
      })

      addNotification({
        key: 'chrome-logging-in',
        message: (
          <Row className="items-center">
            Please wait. You're being logged into the Talent CRM Chrome
            extension.
            <Separator size="sm" />
            <Spinner color="white" />
          </Row>
        ),
        persist: 1,
        type: INFO_TYPE,
        variant: 'translucent',
      })
    }
  }, [])

  return (
    <PrivateLayout>
      <UnsentReachoutsSection />
      <OverallStatsSection />

      <Column className="gap-xl pt-sm pb-xl px-lg">
        <CampaignsSection />
        <ReachOutsSection />
        <ActivityLogSection />
      </Column>
    </PrivateLayout>
  )
}

export default DashboardScreen
