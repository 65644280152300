import { ReactComponent as InfoOutlineSvg } from '@trinsly/common/src/assets/icons/info-outline.svg'
import Card from '@trinsly/common/src/components/atoms/card'
import Column from '@trinsly/common/src/components/atoms/column'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Text from '@trinsly/common/src/components/atoms/text'
import Alert from '@trinsly/common/src/components/molecules/alert'
import Button from '@trinsly/common/src/components/molecules/button'
import Modal from '@trinsly/common/src/components/molecules/modal'
import useFormConcurrent from '@trinsly/common/src/hooks/use-form-concurrent'
import useQueryParams from '@trinsly/common/src/hooks/use-query-params'
import required from '@trinsly/common/src/validations/required'
import useCreateBatch from 'api-hooks/use-create-batch'
import useDeleteBatchDraft from 'api-hooks/use-delete-batch-draft'
import React from 'react'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'

type FormProviderProps = {
  children: React.ReactNode | React.ReactNode[]
}

type ContextProps = {
  setValue: (path: string, value: any) => void
  getValue: (path: string) => any
  setValues: (values: any) => void
  getValues: () => any
  getError: (path: string) => any
  getErrors: () => any
  setValidations: (validations: {
    [index: string]: ((value: any) => boolean)[]
  }) => void
  reset: () => void
  submit: () => void
  creating: boolean
}

const Context = React.createContext<ContextProps>({} as any)
export const useFormContext = () => React.useContext(Context)
const emptyState = {
  excludeContactedWithin: 0,
  campaign: {
    templates: [{}],
  },
  variables: {},
  schedules: [],
  custom: {},
}

function FormProvider({ children }: FormProviderProps) {
  const [showSendBatchConfirmationModal, setShowSendBatchConfirmationModal] =
    React.useState(false)
  const [validations, setValidations] = React.useState<{
    [index: string]: [Function]
  }>()

  const history = useHistory()
  const { params: queryParams } = useQueryParams()

  const { fetch: deleteDraft } = useDeleteBatchDraft({
    onSuccess: (data) => {},
    onError: (error) => {
      console.error('Error deleting Draft')
    },
  })
  const { fetch: createBatch, pending: pendingCreateBatch } = useCreateBatch({
    onSuccess: (data) => {
      history.block(() => {})
      const draftId = queryParams?.['batch-draft-id']
      if (draftId) {
        deleteDraft({ draftId })
      }
      toast.success('Batch sent!')
      history.push('/batches')
    },
    onError: (error: any) => {
      const errorMessage =
        error?.message || error?.errors?.base?.[0] || 'Error sending batch'
      toast.error(errorMessage)
    },
  })

  const {
    getValue,
    getValues,
    setValue,
    getError,
    setValues,
    getErrors,
    submit,
    reset,
  } = useFormConcurrent({
    initialValues: emptyState,
    validations,
    onSubmit: (values) => {
      createBatch({
        batch: {
          name: values?.name,
          excludeContactedWithin: values?.excludeContactedWithin,
        },
        campaign: {
          id: values?.campaign?.id,
          templates: values?.campaign?.templates,
          tagList: values?.campaign?.tagList,
        },
        schedules: values?.schedules,
        custom: Object.keys(values?.custom || {})?.reduce?.(
          (acc, emailKeyWithoutDots) => {
            const email = emailKeyWithoutDots?.replaceAll('@@', '.')
            return {
              ...acc,
              [email]: values?.custom?.[emailKeyWithoutDots],
            }
          },
          {}
        ),
      })
    },
  })

  const custom = getValue('custom')
  const campaignTemplates = getValue('campaign.templates')
  const prospectsBeingCustomized = Object.keys(custom || {})
  const prospectsEmailAsString = prospectsBeingCustomized?.reduce?.(
    (acc, email) => `${acc} ${email}`,
    ''
  )
  const variables = getValue('variables')
  const variableKeys = Object.keys(variables || {})
  const variableKeysAsString = variableKeys.reduce?.(
    (acc, key) => `${acc} ${key}`,
    ''
  )

  //autoset form validations
  //on any new/remove prospect data, based in email key
  //on followup length changes
  React.useEffect(() => {
    setValidations({
      name: [required],
      disclaimer: [required],
      ...variableKeys.reduce((acc, value) => {
        return {
          ...acc,
          [`variables[${value}]`]: [required],
        }
      }, {}),
      ...prospectsBeingCustomized?.reduce?.((acc, email) => {
        const threads = (getValue(`custom[${email}].threads`) || []) as any[]
        return {
          ...acc,
          ...threads?.reduce?.((acc, thread, index) => {
            return {
              ...acc,
              [`custom[${email}].threads[${index}].subject`]: [required],
              [`custom[${email}].threads[${index}].body`]: [required],
            }
          }, {}),
        }
      }, {}),
    })
  }, [prospectsEmailAsString, variableKeysAsString, campaignTemplates?.length])

  const templateCount = getValue('campaign.templates')?.length || 0
  const prospectCount = Object.keys(getValue('custom') || {})?.length || 0
  const emailCount = prospectCount * templateCount
  const prospectCsvEmailList =
    getValue('csv')
      ?.slice?.(1)
      ?.map?.((row: any) => {
        const emailIndex = getValue(`variables[Email]`)
        const email = row?.[emailIndex]
        return email
      }) || []
  const shouldShowEmptyRowWarning = prospectCsvEmailList?.length > prospectCount

  return (
    <Context.Provider
      value={{
        getValue,
        setValue,
        getValues,
        setValues,
        getError,
        getErrors,
        reset,
        submit: () => {
          setShowSendBatchConfirmationModal(true)
        },
        setValidations: setValidations as any,
        creating: pendingCreateBatch,
      }}
    >
      {children}
      <Modal
        visible={showSendBatchConfirmationModal}
        onRequestClose={() => {
          setShowSendBatchConfirmationModal(false)
        }}
      >
        <Card className="overflow-hidden">
          <Column className="p-md gap-xs">
            <Heading size="xl" fontWeight="bold">
              Send Batch to Prospects
            </Heading>
            <Text size="sm">
              You are about to schedule {emailCount} email
              {emailCount > 1 ? 's' : ''} to {prospectCount} prospect
              {prospectCount > 1 ? 's' : ''}. Are you sure?
            </Text>
          </Column>
          {shouldShowEmptyRowWarning && (
            <Column className="px-md">
              <Alert intent="warning" icon={<InfoOutlineSvg />}>
                Some prospect records have empty emails and will be skipped
              </Alert>
            </Column>
          )}
          <Modal.Footer>
            <Button
              variant="ghost"
              className="min-w-28"
              onClick={() => {
                setShowSendBatchConfirmationModal(false)
              }}
            >
              Close
            </Button>
            <Separator size="sm" />
            <Button
              className="min-w-48"
              intent="success"
              pending={pendingCreateBatch}
              onClick={() => {
                submit?.()
              }}
            >
              Schedule Batch
            </Button>
          </Modal.Footer>
        </Card>
      </Modal>
    </Context.Provider>
  )
}

export default FormProvider
