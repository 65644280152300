import Column from '@trinsly/common/src/components/atoms/column'
import Spinner from '@trinsly/common/src/components/atoms/spinner'

const FullScreenSpinner = () => (
  <Column className="h-screen w-screen bg-gray-100">
    <Column className="p-xl items-center justify-center">
      <Spinner size="xl" />
    </Column>
  </Column>
)

export default FullScreenSpinner
