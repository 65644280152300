import { AccountBillingState } from 'screens/settings-screen/billing-tab'
import CrmEnum from 'types/crm-enum'
import { Subscription } from 'types/paywall-info-type'

/**
 * Backend Enum:
 *   enum status: {
      trialing: 0,
      active: 1,
      incomplete: 2,
      incomplete_expired: 3,
      past_due: 4,
      canceled: 5,
      unpaid: 6,
  }
 * @param paywallInfo 
 * @returns 
 */

/**
 * Aug 4 2022 - this is the primary way we should evaluate the billing state.
 * @param paywallInfo
 * @returns
 */
export function getAccountBillingState(
  paywallInfo: PaywallInfo
): AccountBillingState {
  if (
    paywallInfo.planType === 'Trial' &&
    new Date(paywallInfo.through) >= new Date()
  ) {
    return AccountBillingState.ActiveTrial
  }

  if (
    paywallInfo.planType === 'Trial' &&
    new Date(paywallInfo.through) < new Date()
  ) {
    return AccountBillingState.ExpiredTrial
  }

  if (
    paywallInfo.planType === 'Contract' &&
    new Date(paywallInfo.through) >= new Date()
  ) {
    if (paywallInfo.subscriptions.length < 1) {
      return AccountBillingState.ActiveEnterprise
    }
    return AccountBillingState.ActiveSubscriptionAnnual
  }

  if (
    paywallInfo.planType === 'Tiered' &&
    new Date(paywallInfo.through) >= new Date()
  ) {
    return AccountBillingState.ActiveSubscriptionMonthly
  }

  return AccountBillingState.Inactive
}

/**
 * True = delinquent payment or unconverted trial
 * False = active account (either trial or paid)
 * @param paywallInfo
 * @returns
 */
export function isAccountFrozen(
  paywallInfo: PaywallInfo,
  subscriptions: Subscription[]
): boolean {
  try {
    if (
      subscriptions.length < 1 &&
      paywallInfo.status !== 'free_trial' &&
      paywallInfo.planType !== 'Contract'
    ) {
      //no sub and not legacy
      return true
    }

    if (
      paywallInfo.status === 'paywalled' ||
      paywallInfo.status === 'unconverted_free_trial'
    ) {
      return true
    }

    return false
  } catch (error) {
    return true
  }
}

/**
 * True = currently on trial plan
 * False = not on trial, expired, or currently paid
 * @param paywallInfo
 * @returns boolean
 */
export function isTrialActive(paywallInfo: PaywallInfo): boolean {
  try {
    if (paywallInfo.status === 'free_trial') {
      const through = new Date(paywallInfo?.through || '')

      if (through > new Date()) {
        return true
      } else {
        return false
      }
    }

    return false
  } catch (error) {
    return false
  }
}

export function daysLeftInTrial(paywallInfo: PaywallInfo): number {
  try {
    if (paywallInfo.status !== 'free_trial') {
      return -1
    }

    if (paywallInfo.through) {
      const until = new Date(paywallInfo.through)
      const now = new Date()

      const diff = until.getTime() - now.getTime()
      const days = Math.ceil(diff / (1000 * 3600 * 24))

      return days
    }

    return 0
  } catch (error) {
    return -1
  }
}

/**
 * Validates if active and paid
 * @param paywallInfo
 * @returns
 */
export function isAccountPaid(paywallInfo: PaywallInfo): boolean {
  try {
    if (paywallInfo.status === 'paid') return true

    return false
  } catch (error) {
    return false
  }
}

/**
 * Not the same as isAccountPaid - can be active, but not paid (e.g. delinquent)
 * @param subscriptions
 */
export function hasActiveSubscriptions(subscriptions: Subscription[]): boolean {
  try {
    if (subscriptions.length < 1) {
      return false
    }

    if (subscriptions.some((sub) => sub.status === 'active')) {
      return true
    }

    return false
  } catch (error) {
    return false
  }
}

export function canAddUser(
  paywallInfo: PaywallInfo,
  currentUserCount: number
): boolean {
  try {
    if (paywallInfo.status === 'paywalled') return false

    if (paywallInfo.status === 'free_trial' && currentUserCount > 2) {
      return false
    }

    if (currentUserCount >= (paywallInfo?.seatLimit || 3)) return false

    return true
  } catch (error) {
    return false
  }
}

export function canUseIntegration(
  paywallInfo: PaywallInfo,
  integrationType: CrmEnum
): boolean {
  try {
    if (paywallInfo.status === 'paywalled') return false

    return paywallInfo.availableIntegrations.includes(integrationType.valueOf())
  } catch (error) {
    return false
  }
}

export function canUseNylasInboxes(
  paywallInfo: PaywallInfo,
  subscriptions: Subscription[]
): boolean {
  try {
    const isFrozen = paywallInfo.status === 'paywalled'

    //e.g. trial
    if (subscriptions.length < 1) {
      return false
    }

    if (isFrozen) return false

    if (paywallInfo.availableInboxes.includes('nylas')) return true

    return true
  } catch (error) {
    return false
  }
}

export function cleanSubscriptionNames(
  subscriptions: Subscription[]
): string[] {
  return subscriptions.map((sub) => {
    return StripePlanMap[sub.priceRef]
  })
}

export function isOnLegacyPlan(planName: string): boolean {
  if (StripePlanMap[planName].includes('Legacy')) {
    return true
  }

  return false
}

export const StripePlanMap: { [key: string]: string } = {
  //new - 5-2022
  // price_1Kw4yB2er0bMS9OxvjTI3of9: 'Prospect - Startup Plan (per seat/monthly)',
  // price_1Kw4yB2er0bMS9OxNij7Btp9: 'Prospect - Startup Plan (per seat/annual)',
  price_1Kw4y42er0bMS9OxLhOBqqvS: 'Prospect - Growth Plan (per seat/monthly)',
  price_1Kw4y42er0bMS9OxxWNv7oC0: 'Prospect - Growth Plan (per seat/annual)',
  //new - staging - 5-2022
  // 'prospect-startup-monthly-v1': 'Prospect - Startup Plan (per seat/monthly)',
  // 'prospect-startup-annual-v1': 'Prospect - Startup Plan (per seat/annual)',
  'prospect-growth-monthly-v1': 'Prospect - Growth Plan (per seat/monthly)',
  'prospect-growth-annual-v1': 'Prospect - Growth Plan (per seat/annual)',
  //active
  'metered-email-lookups': 'Email Finder Credits Usage Plan',
  //to be deprecated
  'v5-starter-monthly': 'Starter - Monthly',
  'v5-starter-yearly': 'Starter - Annual',
  'v5-business-monthly': 'Business - Monthly',
  'v5-business-yearly': 'Business - Annual',
  'legacy-per-user-discounted': 'Per User - Legacy Price ($49 /user)',
  'legacy-per-user': 'Per User - Legacy Price ($59 /user)',
  //discontinued
  'v2-solo-monthly': 'Solo - Monthly (Legacy)',
  'v2-solo-annual': 'Solo - Annual (Legacy)',
  'v2-team-monthly': 'Teams - Monthly (Legacy)',
  'v2-team-annual': 'Teams - Annual (Legacy)',
  'v2-business-annual': 'Business - Annual (Legacy)',
  'v2-business-monthly': 'Business - Monthly (Legacy)',
  'v3-solo-monthly': 'Solo - Monthly (Legacy)',
  'v3-solo-annual': 'Solo - Annual (Legacy)',
  'v3-team-monthly': 'Team - Monthly (Legacy)',
  'v3-team-annual': 'Team - Annual (Legacy)',
  'business-monthly': 'Business - Monthly (Legacy)',
  'v3-business-monthly': 'Business - Monthly (Legacy)',
  'pro-annual': 'Pro - Annual (Legacy)',
  'pro-monthly': 'Pro - Monthly (Legacy)',
}
