import Checkbox from '@trinsly/common/src/components/atoms/checkbox'
import Column from '@trinsly/common/src/components/atoms/column'
import Divider from '@trinsly/common/src/components/atoms/divider'
import Label from '@trinsly/common/src/components/atoms/label'
import Row from '@trinsly/common/src/components/atoms/row'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import useGetUserSession from 'api-hooks/use-get-user-session'
import { useDraftContext } from '../draft-provider'
import { useFormContext } from '../form-provider'

type FooterSectionProps = {}

function FooterSection({}: FooterSectionProps) {
  const { getValue, setValue, getError, getErrors, creating } = useFormContext()
  const { pending: pendingDraft } = useDraftContext()
  const { data: userSession, pending: pendingUserSession } = useGetUserSession(
    {}
  )

  const shouldDisableForm = pendingDraft
  const isPaywalled =
    pendingUserSession || userSession?.paywallInfo?.status === 'paywalled'
  const isDisclaimerChecked = Boolean(getValue('disclaimer'))
  const prospectCount = Object.keys(getValue('custom') || {})?.length || 0

  return (
    <Column className="gap-md">
      <Column className="px-lg">
        <Row className="gap-sm">
          <Checkbox
            id="disclaimer"
            checked={getValue('disclaimer')}
            onChange={() => setValue('disclaimer', !getValue('disclaimer'))}
            disabled={shouldDisableForm}
          />
          <Label htmlFor="disclaimer">
            I understand that my e-mail provider has daily message limits and by
            sending this mail merge, I am contributing to this limit.{' '}
            <Text size="sm" fontWeight="bold" color="gray-900">
              We strongly suggest batching e-mails to less than 100 prospects.
            </Text>
          </Label>
        </Row>
        <Column style={{ height: 0 }}>
          <Column className="min-h-1" />
          {getError('disclaimer') && (
            <span className="text-danger-500" style={{ fontSize: '0.65rem' }}>
              {getError('disclaimer')}
            </span>
          )}
        </Column>
      </Column>
      <Divider />
      <Row className="px-xl items-center justify-end gap-md">
        {Boolean(getErrors()) && (
          <span className="text-danger-500" style={{ fontSize: '0.65rem' }}>
            Please fill all the required fields
          </span>
        )}
        <Column className="min-w-48">
          <Button
            type="submit"
            intent="success"
            pending={creating}
            disabled={
              isPaywalled ||
              !isDisclaimerChecked ||
              prospectCount === 0 ||
              shouldDisableForm
            }
          >
            Schedule Batch
          </Button>
        </Column>
      </Row>
    </Column>
  )
}

export default FooterSection
