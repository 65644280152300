import Column from '@trinsly/common/src/components/atoms/column'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Text from '@trinsly/common/src/components/atoms/text'
import useUpdateProspect from 'api-hooks/use-update-prospect'
import SelectMember from 'components/select-member'
import { format, parseISO } from 'date-fns'
import { OrganizationMember } from 'types/settings-type'
import { useFormContext } from '../../form-provider'
import ProjectsField from './projects-field'
import StatusField from './status-field'

type SubHeaderProps = {}

function SubHeader({}: SubHeaderProps) {
  const { prospect } = useFormContext()
  const { fetch: updateProspect, pending: pendingUpdate } = useUpdateProspect(
    {}
  )
  return (
    <Column className="min-h-24 bg-gray-100 justify-center">
      <Row
        className="items-center justify-between flex-wrap py-sm px-md gap-sm"
        style={{ maxWidth: '50rem' }}
      >
        <Column className="gap-0.5">
          <Text size="xs" fontWeight="bold" className="uppercase">
            Owner
          </Text>
          <Column style={{ width: '12rem', height: '2rem' }}>
            <SelectMember
              pending={pendingUpdate}
              value={
                ({
                  userId: prospect?.owner?.id,
                  userEmail: prospect?.owner?.email,
                  userName: prospect?.owner?.name,
                } as OrganizationMember) ?? {}
              }
              onChange={(value) => {
                if (prospect) {
                  prospect.owner = {
                    id: value?.userId ?? prospect.owner.id,
                    name: value?.userName ?? prospect.owner.name,
                    email: value?.userEmail ?? prospect.owner.email,
                  }
                  updateProspect({ prospect })
                }
              }}
            />
          </Column>
        </Column>
        <Column className="gap-0.5">
          <Text size="xs" fontWeight="bold" className="uppercase">
            Created By
          </Text>
          <Separator size="xs" />
          <Text size="sm">{prospect?.creator?.name || '--'}</Text>
        </Column>
        <Column className="gap-0.5">
          <Text size="xs" fontWeight="bold" className="uppercase">
            Last Contacted
          </Text>
          <Separator size="xs" />
          <Text size="sm">
            {prospect?.lastContactedAt
              ? format(
                  parseISO(prospect?.lastContactedAt),
                  'MM/dd/yyyy - hh:mma'
                )
              : '--' || '--'}
          </Text>
        </Column>
        <Column className="gap-0.5 items-start">
          <Text size="xs" fontWeight="bold" className="uppercase">
            Status
          </Text>
          <Separator size="xs" />
          {prospect?.status ? (
            <StatusField status={prospect?.status} />
          ) : (
            <Text size="sm">--</Text>
          )}
        </Column>
        <Column className="gap-0.5">
          <Text size="xs" fontWeight="bold" className="uppercase">
            Projects
          </Text>
          <Separator size="xs" />
          <ProjectsField prospect={prospect} />
        </Column>
      </Row>
    </Column>
  )
}

export default SubHeader
