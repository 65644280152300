import useGetProspect from 'api-hooks/use-get-prospect'
import React from 'react'
import { useParams } from 'react-router'
import ProspectType from 'types/prospect-type'

type ContextProps = {
  prospect: ProspectType | undefined
  pending: boolean
}

const Context = React.createContext<ContextProps>({} as any)
export const useFormContext = () => React.useContext(Context)

function FormProvider({ children }: React.PropsWithChildren<any>) {
  const { id }: any = useParams()
  const { data: prospect, pending } = useGetProspect({ prospectId: id })
  return (
    <Context.Provider
      value={{
        prospect,
        pending,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default FormProvider
