import React from 'react'
import Column from './column'

export type DualBackgroundProps = {
  children: React.ReactNode | React.ReactNodeArray
  size?: 'md' | 'lg'
}

function DualBackground({ size = 'md', children }: DualBackgroundProps) {
  return (
    <Column className="relative">
      <Column
        className={`absolute top-0 left-0 right-0 bg-primary-900 ${sizeClassMap[size]}`}
      />
      <Column className="relative">{children}</Column>
    </Column>
  )
}

export default DualBackground

const sizeClassMap = {
  md: 'min-h-40',
  lg: 'min-h-64',
}
