import useGetProject from 'api-hooks/use-get-project'
import React, { Dispatch, SetStateAction } from 'react'
import { useParams } from 'react-router'
import ProjectType from 'types/project-type'

type ContextProps = {
  project: ProjectType | undefined
  pending: boolean
  viewState: 'form' | 'detail'
  setViewState: Dispatch<SetStateAction<'form' | 'detail'>>
}

const Context = React.createContext<ContextProps>({} as any)
export const useFormContext = () => React.useContext(Context)

function FormProvider({ children }: React.PropsWithChildren<any>) {
  const { id }: any = useParams()
  const [viewState, setViewState] = React.useState<'form' | 'detail'>('detail')

  const { data: project, pending } = useGetProject({ projectId: id })
  return (
    <Context.Provider
      value={{
        project,
        pending,
        viewState,
        setViewState,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default FormProvider
