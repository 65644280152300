import { Dialog, Transition } from '@headlessui/react'
import React, { CSSProperties, ReactNode } from 'react'
import { Theme } from '../../types/theme-type'
import Column from '../atoms/column'
import Row from '../atoms/row'

export interface ModalProps {
  children: React.ReactNode | React.ReactNodeArray
  visible: boolean
  onRequestClose: () => void
  size?: Theme['size']
  unmount?: boolean
}

function Modal({
  children,
  size = 'md',
  visible = false,
  unmount,
  onRequestClose,
}: ModalProps) {
  return (
    <Transition appear show={visible} as={React.Fragment} unmount={unmount}>
      <Dialog
        as="div"
        open={true}
        onClose={onRequestClose}
        className="fixed inset-0 flex z-10"
      >
        {/* Inner <FocusTrap> must have an focusable node */}
        <button className="h-0 w-0" />
        <Transition.Child
          as={React.Fragment}
          unmount={unmount}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Column
            className="transition z-10 mx-auto overflow-y-auto rounded-t-lg self-end md:rounded-lg md:self-center"
            style={modalSizeStyleMap[size]}
          >
            {children}
          </Column>
        </Transition.Child>
        <Transition.Child
          unmount={unmount}
          as={Dialog.Overlay}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className="fixed inset-0 bg-black bg-opacity-50"
        />
      </Dialog>
    </Transition>
  )
}

function ModalFooter({
  children,
}: {
  children: ReactNode | React.ReactNodeArray
}) {
  return <Row className="justify-end bg-gray-50 py-sm px-md">{children}</Row>
}

Modal.Footer = ModalFooter
ModalFooter.displayName = 'Modal.Footer'
export default Modal

const modalSizeStyleMap = {
  xs: {
    width: '100%',
    maxWidth: '25rem',
    // minHeight: '12.5rem',
    maxHeight: '25rem',
  } as CSSProperties,
  sm: {
    width: '100%',
    maxWidth: '35rem',
    // minHeight: '17.5rem',
    maxHeight: '35rem',
  } as CSSProperties,
  md: {
    width: '100%',
    maxWidth: '40rem',
    // minHeight: '20rem',
    maxHeight: '40rem',
  } as CSSProperties,
  lg: {
    width: '100%',
    maxWidth: '55rem',
    // minHeight: '27.5rem',
    maxHeight: '85%',
  } as CSSProperties,
  xl: {
    width: '100%',
    maxWidth: '70rem',
    // minHeight: '35rem',
    maxHeight: '95%',
  } as CSSProperties,
}
