import Card from '@trinsly/common/src/components/atoms/card'
import Column from '@trinsly/common/src/components/atoms/column'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import Modal from '@trinsly/common/src/components/molecules/modal'
import useCancelAllUnsentThreads from 'api-hooks/use-cancel-all-unsent-threads'

export type CancelAllPausedReachOutsConfirmationModalProps = {
  visible: boolean
  onRequestClose: () => void
}

function CancelAllPausedReachOutsConfirmationModal({
  visible,
  onRequestClose,
}: CancelAllPausedReachOutsConfirmationModalProps) {
  const { fetch: cancelAllUnsentThreads, pending: pendingCancel } =
    useCancelAllUnsentThreads({
      onSuccess: (data) => {
        onRequestClose()
      },
      onError: () => alert('Failed to Cancel All - Please Try Again'),
    })

  return (
    <Modal size="sm" visible={visible} onRequestClose={onRequestClose}>
      <Card className="overflow-hidden">
        <Column className="p-md">
          <Heading size="xl" fontWeight="bold">
            Cancel all Paused Reach-Outs
          </Heading>
          <Separator size="sm" />
          <Text>Are you sure you want to cancel all paused Reach-Outs?</Text>
        </Column>
        <Modal.Footer>
          <Button className="min-w-28" onClick={onRequestClose}>
            Close
          </Button>
          <Separator size="sm" />
          <Button
            className="min-w-48"
            intent="danger"
            pending={pendingCancel}
            onClick={() => {
              cancelAllUnsentThreads({})
            }}
          >
            Cancel All
          </Button>
        </Modal.Footer>
      </Card>
    </Modal>
  )
}

export default CancelAllPausedReachOutsConfirmationModal
