type BatchType = {
  campaignId: number
  campaignName: string
  campaignTemplatesCount: number
  createdAt: string
  creatorId: number
  emailThreadsCount: number
  id: number
  name: string
  status: BatchStatusEnum
}

export default BatchType

export enum BatchStatusEnum {
  CANCELED = 'canceled',
  CANCELING = 'canceling',
  COMPLETED = 'completed',
  IN_PROGRESS = 'in_progress',
}

export const translateBatchStatusEnum: Record<BatchStatusEnum, string> = {
  [BatchStatusEnum.CANCELED]: 'Canceled',
  [BatchStatusEnum.CANCELING]: 'Canceling',
  [BatchStatusEnum.COMPLETED]: 'Completed',
  [BatchStatusEnum.IN_PROGRESS]: 'In Progress',
}
