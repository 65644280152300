import api from '@trinsly/common/src/services/api'
import { ThreadEmailType } from 'types/thread-type'

type UpdateThreadEmailServiceProps = {
  threadEmail: ThreadEmailType
}

async function updateThreadEmailService({
  threadEmail,
}: UpdateThreadEmailServiceProps) {
  const response = await api(`/email_messages/${threadEmail?.id}`, {
    method: 'PUT',
    body: {
      email_message: {
        attachments: threadEmail?.attachments,
        body: threadEmail?.body,
        subject: threadEmail?.subject,
      },
    },
  })
  return response as any as ThreadEmailType
}

export default updateThreadEmailService
