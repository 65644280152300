import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation, useQueryClient } from 'react-query'
import UnlinkProspectsToHotlistService from 'services/unlink-prospects-to-hotlist-service'
import ProspectType from 'types/prospect-type'

type UseUnlinkProspectsToHotlistProps = {} & MutateApiHookType<ProspectType>

function useUnlinkProspectsToHotlist({
  onSuccess,
  onError,
}: UseUnlinkProspectsToHotlistProps) {
  const queryClient = useQueryClient()
  const { mutate: fetch, isLoading: pending } = useMutation(
    UnlinkProspectsToHotlistService,
    {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries('/hotlists')
        onSuccess?.(data)
      },
      onError,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useUnlinkProspectsToHotlist
