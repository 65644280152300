import { storeAccessTokenFromHeader } from '@trinsly/common/src/services/api'
import { camelizeKeys } from 'humps'

export type SignUpServiceProps = {
  firstName: string
  lastName: string
  email: string
  password: string
  recaptcha: string
  inviteToken?: string
  referralCode?: string
  hpId?: string
  referrer?: string
}

async function signUpService(values: SignUpServiceProps): Promise<User> {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/auth`, {
    body: JSON.stringify({
      email: values.email,
      first_name: values.firstName,
      last_name: values.lastName,
      password: values.password,
      password_confirmation: values.password,
      recaptcha: values.recaptcha,
      referral_code: values.referralCode,
      invite_token: values.inviteToken,
      hp_id: values.hpId,
      referrer: values.referrer,
    }),
    headers: {
      'Content-Type': 'application/json',
      'X-Trinsly-Client': 'web',
    },
    method: 'POST',
  })

  const data = await response.json()

  if (response.status > 299) {
    throw { error: 'Unable to Register', status: response.status }
  }

  // Save our JWT!
  storeAccessTokenFromHeader(response.headers)
  const user = camelizeKeys(data.data) as any as User
  return user
}

export default signUpService
