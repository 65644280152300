//updates and caches user session info from user endpoint
/**
 *
 * GET with credentials -  https://trinsly-staging.herokuapp.com/api/v1/users/me
 *
 *
 */
import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useQuery } from 'react-query'

import { getUserSessionService } from 'services/auth/get-user-session-service'
import UserType from 'types/user-type'

export type UseGetUserSessionProps = {
  enabled?: boolean
} & MutateApiHookType<UserType>

function useGetUserSession({
  enabled = true,
  onError,
  onSuccess,
}: UseGetUserSessionProps) {
  const {
    data,
    error,
    status,
    refetch,
    isRefetching: refetching,
  } = useQuery('/user/me', getUserSessionService, {
    enabled,
    staleTime: 60000,
    onSuccess,
    onError,
  })

  return {
    data: (data as UserType) || {},
    pending: status === 'loading',
    error,
    refetch,
    refetching,
  }
}

export default useGetUserSession
