import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useAuth } from 'providers/auth-provider'
import { useMutation } from 'react-query'
import signUpService from '../services/sign-up-service'

type UseFetchSignUpProps = {} & MutateApiHookType<User>

function useFetchSignUp({ onSuccess, onError }: UseFetchSignUpProps) {
  const { refetchUserSession } = useAuth()
  const { mutate: fetch, isLoading: pending } = useMutation(signUpService, {
    onSuccess: async (data) => {
      await refetchUserSession()
      onSuccess?.(data)
    },
    onError,
  })

  return {
    fetch,
    pending,
  }
}

export default useFetchSignUp
