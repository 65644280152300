import React from 'react'
import Column from './column'

export type DividerProps = {}

function Divider({}: DividerProps) {
  return <Column className="w-full bg-gray-200" style={{ minHeight: 1 }} />
}

export default Divider
