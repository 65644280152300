import { Popover as HeadlessPopover, Transition } from '@headlessui/react'
import React from 'react'
import { usePopper } from 'react-popper'

type Placement =
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'left'
  | 'left-start'
  | 'left-end'

type PopoverProps = {
  placement?: Placement
  target?: React.ReactNode | ((props: { open: boolean }) => React.ReactNode)
  children?: React.ReactChild
  trigger?: 'hover' | 'click'
}

function Popover({
  children,
  target,
  trigger = 'click',
  placement = 'bottom',
}: PopoverProps) {
  const [referenceElement, setReferenceElement] = React.useState<any>()
  const [popperElement, setPopperElement] = React.useState<any>()
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    strategy: 'fixed',
  })

  function openPopover(isOpen: boolean) {
    if (!isOpen && trigger === 'hover') {
      referenceElement?.click()
    }
  }

  const marginClass = React.useMemo(() => {
    if (placement?.includes('bottom')) {
      return 'mt-2'
    } else if (placement?.includes('left')) {
      return 'mr-2'
    } else if (placement?.includes('right')) {
      return 'ml-2'
    } else {
      return 'mb-2'
    }
  }, [placement])

  return (
    <HeadlessPopover>
      {({ open }) => (
        <>
          <HeadlessPopover.Button
            ref={setReferenceElement}
            onMouseEnter={() => openPopover(open)}
            className={[
              `flex flex-col rounded focus:ring ring-inset focus:ring-gray-300 focus:outline-none transition`,
            ].join(' ')}
          >
            {target}
          </HeadlessPopover.Button>
          <Transition
            as={React.Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <HeadlessPopover.Panel className="absolute z-10">
              <div
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}
                className={[
                  'z-10 bg-white rounded-lg drop-shadow-xl shadow-xl ring-1 ring-black ring-opacity-5',
                  marginClass,
                ].join(' ')}
              >
                {children}
              </div>
            </HeadlessPopover.Panel>
          </Transition>
        </>
      )}
    </HeadlessPopover>
  )
}

export default Popover
