import React from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import Spinner from '../spinner'
import './styles.css'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export type PdfViewProps = {
  src: string | Blob
  maxPages?: number
  options?: {
    width?: number
    scale?: number
  }
}

function PdfViewer({ src, maxPages, options }: PdfViewProps) {
  const [numPages, setNumPages] = React.useState(0)

  function onLoadSuccess({ numPages }: any) {
    setNumPages(numPages || 0)
  }

  let totalPages = maxPages ?? numPages
  totalPages = totalPages > numPages ? numPages : totalPages

  return (
    <Document
      file={src}
      onLoadSuccess={onLoadSuccess}
      noData=""
      error={
        <div className="text-xs text-gray-500 min-h-5">
          Failed to load PDF file.
        </div>
      }
      loading={
        <div className="flex flex-col min-h-5">
          <Spinner size="sm" />
        </div>
      }
    >
      <div className="flex flex-col gap-xs border-gray-200">
        {Array.from({ length: totalPages }, (v, k) => k).map((pageNumber) => (
          <Page key={pageNumber} pageNumber={pageNumber + 1} {...options} />
        ))}
      </div>
    </Document>
  )
}

export default PdfViewer
