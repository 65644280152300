import { ReactComponent as ProjectSvg } from '@trinsly/common/src/assets/icons/project.svg'
import Column from '@trinsly/common/src/components/atoms/column'
import Popover from '@trinsly/common/src/components/atoms/popover'
import Row from '@trinsly/common/src/components/atoms/row'
import Spinner from '@trinsly/common/src/components/atoms/spinner'
import Tag from '@trinsly/common/src/components/atoms/tag'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import usePageableState from '@trinsly/common/src/hooks/use-pageable-state'
import useGetProspectProjects from 'api-hooks/use-get-prospect-projects'
import { useHistory } from 'react-router'
import ProjectStatusEnum from 'types/project-status-enum'
import ProspectType from 'types/prospect-type'

type ProjectsFieldProps = {
  prospect: ProspectType | undefined
}

function ProjectsField({ prospect }: ProjectsFieldProps) {
  const history = useHistory()
  const { pageable } = usePageableState({
    page: 1,
    perPage: 20, //TODO: Implement pagination, fetching only the first 20 ones for now
  })
  const { data: projects, pending: pendingProjects } = useGetProspectProjects({
    prospectId: prospect?.id,
    ...pageable,
  })

  const projectsCount = projects?.length || 0
  const hasProjects = projectsCount > 0
  const hasMoreThanOneProject = projectsCount > 1
  const firstProjectName = projects?.[0]?.name || 'Project'

  if (pendingProjects) {
    return (
      <Column className="min-h-5 justify-center">
        <Spinner size="xs" />
      </Column>
    )
  }

  if (!hasProjects) {
    return (
      <Column className="min-h-5 justify-center">
        <Text size="sm">--</Text>
      </Column>
    )
  }

  return (
    <Popover
      trigger="hover"
      target={
        <Column className="relative">
          <Text size="sm">
            {`${firstProjectName} ${
              hasMoreThanOneProject ? `+${projectsCount - 1} more` : ''
            }`}
          </Text>
          <Column className="absolute left-0 right-0 -bottom-1 border-b border-dashed border-gray-300" />
        </Column>
      }
    >
      <Column>
        <table cellSpacing="0" cellPadding="0">
          <thead>
            <tr>
              <th className="text-xs font-bold min-w-56 pl-md">Project</th>
              <th className="text-xs font-bold min-w-40 pl-md">Owner</th>
              <th className="text-xs font-bold min-w-36 pl-md">Status</th>
              <th className="text-xs font-bold pl-md"></th>
            </tr>
          </thead>
          <tbody>
            {projects?.map((project, index) => {
              const { textColor, backgroundColor } =
                projectStatusStyleMap?.[project?.status] || {}

              return (
                <tr key={index}>
                  <td className="pl-md">{project?.name}</td>
                  <td className="pl-md">{project?.owner?.name}</td>
                  <td className="pl-md">
                    <Tag
                      textColor={textColor}
                      backgroundColor={backgroundColor}
                    >
                      {project.status}
                    </Tag>
                  </td>
                  <td className="px-md">
                    <Column>
                      <Button
                        size="xs"
                        shape="rounded"
                        intent="primary"
                        onClick={() => {
                          history.push(`/projects/${project?.id}`)
                        }}
                      >
                        <Row className="items-center gap-xs whitespace-nowrap">
                          <ProjectSvg className="w-3.5 h-3.5" />
                          View Project
                        </Row>
                      </Button>
                    </Column>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </Column>
    </Popover>
  )
}

export default ProjectsField

const projectStatusStyleMap: Record<
  ProjectStatusEnum,
  { textColor: string; backgroundColor: string }
> = {
  [ProjectStatusEnum.ACTIVE]: {
    textColor: 'white',
    backgroundColor: '#00C853',
  },
  [ProjectStatusEnum.COMPLETE]: {
    textColor: 'white',
    backgroundColor: '#696969',
  },
  [ProjectStatusEnum.INACTIVE]: {
    textColor: 'white',
    backgroundColor: '#F44336',
  },
}
