import Column from '@trinsly/common/src/components/atoms/column'
import Form from '@trinsly/common/src/components/atoms/form'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import useForm from '@trinsly/common/src/hooks/use-form'
import required from '@trinsly/common/src/validations/required'
import { useEffect, useState } from 'react'
import TwoFactorOtpInput from 'screens/sign-in-screen/two-factor-otp-input'

export type Verify2faOtpSetupFormProps = {
  disabled: boolean
  verify: (otp: any) => void
  restart: () => void
  pending: boolean
}

const Verify2faOtpSetupForm = ({
  disabled,
  verify,
  restart,
  pending,
}: Verify2faOtpSetupFormProps) => {
  const { submit, getValue, setValue } = useForm({
    onSubmit: ({ otp }) => {
      verify({ otp })
    },
    validations: {
      otp: [required],
    },
  })

  const [disableResend, setDisableResend] = useState(true)

  // On componentDidMount set the timer
  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setDisableResend(false)
    }, 15000)

    return () => {
      clearTimeout(timeId)
    }
  }, [])

  return (
    <>
      <Column className="w-96 py-md">
        <Column className="bg-info-100 rounded-md p-md border-l-4 border-info-500">
          <Column>
            <Text>
              We sent a 2FA code to the phone number you provided. If it doesn't
              arrive or if it has been over 5 minutes (expired), you can resend
              another 2FA code.
            </Text>
          </Column>
        </Column>
        <Separator />
        <Form onSubmit={submit}>
          <TwoFactorOtpInput
            onChange={(value) => setValue('otp', value)}
            value={getValue('otp')}
          />
          <Separator />
          <Row>
            <Button
              intent="info"
              variant="outline"
              onClick={restart}
              disabled={disabled || disableResend}
              type="button"
            >
              Resend 2FA code
            </Button>
            <Separator size="sm" />
            <Button
              intent="success"
              type="submit"
              disabled={disabled || getValue('otp').length < 5}
              pending={pending}
            >
              Verify 2-Factor OTP
            </Button>
          </Row>
        </Form>
      </Column>
    </>
  )
}

export default Verify2faOtpSetupForm
