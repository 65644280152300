/**AKA: Log in */

import { storeAccessTokenFromHeader } from '@trinsly/common/src/services/api'
import ErrorType from '@trinsly/common/src/types/error-type'
import { camelizeKeys } from 'humps'

export type CreateUserSessionServiceProps = {
  email: string
  password: string
  otpAttempt?: string
}

//validates and sets user session
export async function createUserSessionService({
  email,
  password,
  otpAttempt,
}: CreateUserSessionServiceProps): Promise<User> {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/auth/sign_in`,
    {
      body: JSON.stringify({
        api_v1_user: {
          email: email.toLowerCase(),
          password: password,
          otp_attempt: otpAttempt,
        },
      }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  )

  if (response.status > 299) {
    const error = await response.json()
    debugger
    if (error.error === 'Your account is locked.') {
      throw {
        type: 'AuthenticationError',
        message:
          'Your account has been locked due to too many failed attempts - please contact support@trinsly.com',
        status: response.status,
      } as ErrorType
    }

    throw {
      type: 'AuthenticationError',
      message: 'Failed to Authenticate - Please try again',
      status: response.status,
    } as ErrorType
  }

  storeAccessTokenFromHeader(response.headers)
  const user = camelizeKeys(await response.json()) as any as User

  return user
}
