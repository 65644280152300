import Column from '@trinsly/common/src/components/atoms/column'
import Label from '@trinsly/common/src/components/atoms/label'
import FileUploadZone from '@trinsly/common/src/components/molecules/file-upload-zone'
import useDeleteProspectResume from 'api-hooks/use-delete-prospect-resume'
import useUploadProspectResume from 'api-hooks/use-upload-prospect-resume'
import React from 'react'
import { useFormContext } from '../../form-provider'

export type ResumeFieldProps = {
  resumeUrl: string | undefined
  onChange: (resumeUrl: string) => void
}

function ResumeField({ resumeUrl, onChange }: ResumeFieldProps) {
  const { prospect } = useFormContext()

  const { fetch: uploadResume, pending: pendingUploadResume } =
    useUploadProspectResume({
      onSuccess: (data) => {
        onChange(data?.resumeUrl || '')
      },
    })

  const { fetch: deleteResume, pending: pendingDeleteResume } =
    useDeleteProspectResume({
      onSuccess: () => {
        onChange('')
      },
    })

  return (
    <React.Fragment>
      <Column className="gap-xs">
        <Label>Resume</Label>
        {Boolean(resumeUrl) && (
          <FileUploadZone.Item
            name="resume.pdf"
            pending={pendingDeleteResume}
            onDelete={() => {
              deleteResume({ prospectId: prospect?.id || '' })
            }}
          />
        )}
        <FileUploadZone
          pending={pendingUploadResume}
          options={{
            maxFiles: 1,
            accept: 'application/pdf',
          }}
          onFiles={(values) => {
            const file = values?.[0]
            if (file) {
              uploadResume({ file, prospectId: prospect?.id || '' })
            }
          }}
        />
      </Column>
    </React.Fragment>
  )
}

export default ResumeField
