import api from '@trinsly/common/src/services/api'
import { useQuery } from 'react-query'

export type UseGetIntegrationProps = {
  integrationId: string
}

function useGetIntegration({ integrationId }: UseGetIntegrationProps) {
  const {
    data,
    error,
    status,
    refetch,
    isRefetching: refetching,
  } = useQuery(
    ['/integration/{id}', integrationId],
    async () => {
      const response = (await api(`/integrations/${integrationId}/users`, {
        method: 'GET',
      })) as any
      return response
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  )

  const { atsResults, integration, users } = (data || {}) as any

  return {
    data: integration || {},
    users: users || [],
    ats: atsResults || [],
    pending: status === 'loading',
    error,
    refetch,
    refetching,
  }
}

export default useGetIntegration
