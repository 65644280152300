import api from '@trinsly/common/src/services/api'
import TrackerDomainType from 'types/tracker-domain-type'

export type DeleteTrackerDomainServiceProps = {
  id: number
}

export default async function deleteTrackerDomainService({
  id,
}: DeleteTrackerDomainServiceProps) {
  const response = await api(`/tracker_domains/${id}`, {
    method: 'DELETE',
  })
  return response as any as TrackerDomainType
}
