import Column from '@trinsly/common/src/components/atoms/column'
import Form from '@trinsly/common/src/components/atoms/form'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Link from '@trinsly/common/src/components/atoms/link'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Text from '@trinsly/common/src/components/atoms/text'
import Alert from '@trinsly/common/src/components/molecules/alert'
import Button from '@trinsly/common/src/components/molecules/button'
import TextInput from '@trinsly/common/src/components/molecules/text-input'
import useForm from '@trinsly/common/src/hooks/use-form'
import useQueryParams from '@trinsly/common/src/hooks/use-query-params'
import required from '@trinsly/common/src/validations/required'
import useCompletePasswordReset from 'api-hooks/use-complete-reset-password'
import { ReactComponent as TrinslyLogoSvg } from 'assets/logo.svg'
import PublicLayout from 'components/public-layout'
import React, { useState } from 'react'

export type ForgotPasswordScreenProps = {}
//step 2 - https://staging-app.trinsly.com/reset-password-step2?email=reid%40chatkick.com&token=476339a8-612b-4db8-9753-3a67350e366d
function CompletePasswordResetScreen({}: ForgotPasswordScreenProps) {
  const { params }: any = useQueryParams()
  const [errors, setErrors] = useState()
  const [success, setSuccess] = useState(false)
  const { fetch: completeReset, pending: pendingCompleteReset } =
    useCompletePasswordReset({
      onError: (error: any) => {
        setErrors(error)
      },
      onSuccess: () => {
        setSuccess(true)
      },
    })
  const { getValue, setValue, getError, submit } = useForm({
    initialValues: {},
    onSubmit: ({ password }) => {
      completeReset({
        password: password,
        email: params.email,
        token: params.token,
      })
    },
    validations: {
      password: [required],
    },
  })

  return (
    <PublicLayout>
      <Column className="flex-1">
        <Form onSubmit={submit}>
          <TrinslyLogoSvg
            height={80}
            width={200}
            style={{ alignSelf: 'left' }}
          />
          <Column style={{ minHeight: 60 }} />
          <Heading size="xl" fontWeight="bold">
            Reset your password
          </Heading>
          <Separator size="lg" />
          {errors && (
            <React.Fragment>
              <Alert intent="danger">
                This link has expired. Please submit another password reset.
              </Alert>
              <Separator size="lg" />
            </React.Fragment>
          )}

          {success && (
            <React.Fragment>
              <Alert intent="success">
                Your password has been reset - <Separator size="xs" />
                <Link href="/log-in" tabIndex={1}>
                  <Text size="sm" color="primary-900" fontWeight="bold">
                    Sign In
                  </Text>
                </Link>
                .
              </Alert>
              <Separator size="lg" />
            </React.Fragment>
          )}
          {params.email && (
            <React.Fragment>
              <TextInput
                type="email"
                label="E-mail"
                value={params.email}
                disabled={true}
              />
              <Separator />
            </React.Fragment>
          )}
          <TextInput
            type="password"
            label="Password"
            placeholder="Please enter a new password"
            value={getValue('password')}
            onChange={(value) => setValue('password', value)}
            error={getError('password')}
            disabled={pendingCompleteReset || success}
          />
          <Separator size="lg" />
          <Button
            type="submit"
            intent="success"
            className="w-full"
            pending={pendingCompleteReset}
          >
            Change Password
          </Button>
        </Form>
      </Column>
      <Row>
        <Text size="sm">Back to</Text>
        <Column style={{ minWidth: 4 }} />
        <Link href="/log-in" tabIndex={1}>
          <Text size="sm" color="current" fontWeight="bold">
            Sign In
          </Text>
        </Link>
      </Row>
    </PublicLayout>
  )
}

export default CompletePasswordResetScreen
