import { ReactComponent as DeleteSvg } from '@trinsly/common/src/assets/icons/delete.svg'
import Column from '@trinsly/common/src/components/atoms/column'
import Form from '@trinsly/common/src/components/atoms/form'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Input from '@trinsly/common/src/components/atoms/input'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Table from '@trinsly/common/src/components/atoms/table'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import useForm from '@trinsly/common/src/hooks/use-form'
import required from '@trinsly/common/src/validations/required'
import useCheckTrackerDomainStatus from 'api-hooks/use-check-tracker-domain-status'
import useCreateTrackerDomain from 'api-hooks/use-create-tracker-domains'
import useDeleteTrackerDomain from 'api-hooks/use-delete-tracker-domain'
import useGetTrackerDomains from 'api-hooks/use-get-tracker-domains'
import useGetUserSession from 'api-hooks/use-get-user-session'
import React, { useState } from 'react'
import TrackerDomainType from 'types/tracker-domain-type'

export type TrackingDomainSubtabProps = {}

const TrackingDomainSubtab = ({}: TrackingDomainSubtabProps) => {
  const [isEnterprise, setIsEnterprise] = React.useState(false)
  const [reCheckTrackerMap, setReCheckTrackerMap] = React.useState<any>({})
  const [toggleDomainTrackerMap, setToggleDomainTrackerMap] = useState<any>({})

  const { data: domains, pending: pendingDomains } = useGetTrackerDomains()
  const { data: userSession, pending: pendingUserSession } = useGetUserSession(
    {}
  )
  const { fetch: recheck } = useCheckTrackerDomainStatus({
    onSuccess: (domain) => {
      setReCheckTrackerMap({
        ...reCheckTrackerMap,
        [domain.id]: false,
      })
    },
    onMutate: (data) => {
      setReCheckTrackerMap({
        ...reCheckTrackerMap,
        [data.id]: true,
      })
    },
  })
  const { fetch: createTrackerDomain, pending: createTrackerDomainPending } =
    useCreateTrackerDomain({})
  const { fetch: deleteTrackerDomain } = useDeleteTrackerDomain({
    onSuccess: (domain) => {
      setToggleDomainTrackerMap({
        ...toggleDomainTrackerMap,
        [domain.id]: false,
      })
    },
    onMutate: (data) => {
      setToggleDomainTrackerMap({
        ...toggleDomainTrackerMap,
        [data.id]: true,
      })
    },
  })
  const { submit, getValue, setValue, reset } = useForm({
    onSubmit: ({ domain }) => {
      createTrackerDomain({ domain: domain })
      reset()
    },
    validations: {
      domain: [required],
    },
  })

  React.useEffect(() => {
    if (userSession && !pendingUserSession) {
      setIsEnterprise(userSession?.paywallInfo?.planType === 'Contract')
    }
  }, [userSession, pendingUserSession])
  const hasDomains = domains && domains.length > 0
  return (
    <Column>
      <Heading
        size="sm"
        fontWeight="bold"
        color="gray-500"
        className="uppercase"
      >
        Email Tracking Domains
      </Heading>
      <Separator size="sm" />
      <Text size="sm">
        In order to track email opens and not be marked as spam, you need to
        setup one or more domains as a CNAME pointing to{' '}
        <code>{'customer.trinsly.com'}</code> . For example, if members of your
        organization use @acme.org and @acme.com email addresses, you would need
        to setup two coresponding domains. After you set it up in your DNS,
        please add it here.
      </Text>
      <Separator size="sm" />
      {!isEnterprise && (
        <Text size="sm" fontWeight="semibold">
          There is a 10 domain limit per organization on your current plan.
        </Text>
      )}
      <Separator size="sm" />
      <>
        <Form onSubmit={submit}>
          <Input
            type="text"
            disabled={!isEnterprise && domains.length >= 10}
            placeholder="imgs.yourdomain.com"
            value={getValue('domain')}
            onChange={(value) => {
              setValue('domain', value)
            }}
          />
          <Separator />
          <Button
            type="submit"
            intent="primary"
            variant="solid"
            pending={createTrackerDomainPending}
            disabled={!getValue('domain')}
          >
            Add Domain
          </Button>
        </Form>
        <Separator />
        {hasDomains && (
          <Column className="overflow-auto shadow-sm border rounded-lg">
            <Table
              data={domains as TrackerDomainType[]}
              pending={pendingDomains}
              pageSize={isEnterprise ? 50 : 10}
              columns={[
                {
                  width: 140,
                  header: 'Domain',
                  cell: (item) => (
                    <Column>
                      <Row>
                        <Text fontWeight="bold">{item.domain} </Text>
                      </Row>
                    </Column>
                  ),
                },
                {
                  width: 140,
                  header: 'Status',
                  align: 'left',
                  cell: (item) => (
                    <Text
                      fontWeight="bold"
                      color={
                        item.status === 'bad' ? 'warning-500' : 'success-800'
                      }
                    >
                      {item.status}
                    </Text>
                  ),
                },
                {
                  header: 'Action',
                  width: 80,
                  cell: (item) => {
                    return (
                      <Column className="min-w-36">
                        <Row className="items-center space-x-sm">
                          {item.status !== 'good' && (
                            <Column className="min-w-36">
                              <Button
                                size="sm"
                                variant="solid"
                                intent={'info'}
                                pending={reCheckTrackerMap[item.id]}
                                onClick={() => {
                                  recheck({ id: parseInt(item.id.toString()) })
                                }}
                              >
                                <Row className="items-center justify-center">
                                  Re-Check
                                </Row>
                              </Button>
                            </Column>
                          )}
                          <Column className="min-w-36">
                            <Button
                              size="sm"
                              variant="outline"
                              intent="danger"
                              pending={toggleDomainTrackerMap[item.id]}
                              onClick={() => {
                                deleteTrackerDomain({
                                  id: parseInt(item.id.toString()),
                                })
                              }}
                            >
                              <Row className="items-center">
                                <DeleteSvg className="w-sm" />
                                <Separator size="xs" />
                                Delete
                              </Row>
                            </Button>
                          </Column>
                        </Row>
                      </Column>
                    )
                  },
                },
              ]}
            />
          </Column>
        )}
      </>
    </Column>
  )
}

export default TrackingDomainSubtab
