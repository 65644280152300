import MultiSelectInput, {
  MultiSelectInputProps,
} from '@trinsly/common/src/components/molecules/multi-select-input'
import usePageableState from '@trinsly/common/src/hooks/use-pageable-state'
import useGetTags from 'api-hooks/use-get-tags'

type MultiSelectTagsProps = {} & Omit<
  MultiSelectInputProps<string>,
  'itemRender' | 'options' | 'pending'
>

function MultiSelectTags(props: MultiSelectTagsProps) {
  const { pageable } = usePageableState({})
  const { data: tags, pending } = useGetTags(pageable)
  return (
    <MultiSelectInput
      {...props}
      pending={pending}
      options={tags}
      itemRenderer={(item) => item}
    />
  )
}

export default MultiSelectTags
