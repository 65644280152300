import api from '@trinsly/common/src/services/api'
import PageableType from '@trinsly/common/src/types/pageable-type'
import ProjectType from 'types/project-type'
import ProspectType from 'types/prospect-type'

export type GetProspectProjectsServiceProps = {
  prospectId: ProspectType['id']
} & PageableType

async function getProspectProjectsService({
  prospectId,
  ...pageable
}: GetProspectProjectsServiceProps) {
  const response = (await api(`/prospects/${prospectId}/projects`, {
    method: 'GET',
    params: {
      page: pageable?.page,
      per_page: pageable?.perPage,
    },
  })) as any

  return response as {
    projects: ProjectType[]
    meta: { pagination: Pagination }
  }
}

export default getProspectProjectsService
