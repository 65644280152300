import Select from '@trinsly/common/src/components/molecules/select'
import moment from 'moment'
import ScheduleTimeSelectEnum, {
  buildTimeSelectOptions,
} from '../../../types/schedule-time-select-enum'

type TimeSelectProps = {
  daysAfter: number
  onChange: (value: number) => void
  time: number
  disabled?: boolean
}

function TimeSelect({ daysAfter, disabled, onChange, time }: TimeSelectProps) {
  const allOptions = buildTimeSelectOptions()
  const options =
    daysAfter === 0
      ? allOptions.reduce(
          function (firstEmailOptions, option) {
            const isLaterInTheDay = option.value >= moment().get('hours')

            if (isLaterInTheDay) firstEmailOptions.push(option)

            return firstEmailOptions
          },
          [
            {
              label: 'Immediately',
              value: ScheduleTimeSelectEnum.UNMODIFIED_VALUE,
            },
          ]
        )
      : allOptions

  const mappedOptions = options.reduce(
    (acc, value) => ({ ...acc, [value.value]: value.label }),
    {}
  ) as any

  return (
    <Select
      clearable={false}
      value={time}
      disabled={disabled}
      options={Object.keys(mappedOptions).map((key) => Number(key))}
      onChange={(value) => onChange(value!)}
      itemRenderer={(item) => mappedOptions[item]}
    />
  )
}

export default TimeSelect
