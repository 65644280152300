import { ReactComponent as FileSvg } from '@trinsly/common/src/assets/icons/file.svg'
import React from 'react'
import { DropzoneOptions, useDropzone } from 'react-dropzone'
import Column from '../../atoms/column'
import Separator from '../../atoms/separator'
import Text from '../../atoms/text'
import Button from '../button'
import FileListItem from './file-list-item'

type Mimetype =
  | 'application/octet-stream'
  | 'text/csv'
  | 'image/*'
  | 'application/pdf'

export type FileUploadZoneProps = {
  onFiles: (files: File[]) => void
  className?: string
  pending?: boolean
  options?: {
    accept: Mimetype | Mimetype[]
  } & Omit<DropzoneOptions, 'onDrop' | 'accept'>
}

function FileUploadZone({
  onFiles,
  className = '',
  pending,
  options,
}: FileUploadZoneProps) {
  const onDrop = React.useCallback((files) => onFiles(files), [onFiles])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    ...(options || {}),
  })

  return (
    <Column
      {...getRootProps()}
      className={[
        containerClasses,
        isDragActive ? containerActiveClasses : containerDefaultClasses,
        className,
      ].join(' ')}
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drag 'n' drop some files here, or click to select files</p>
      ) : (
        <Column className="items-center">
          <Column className="bg-primary-50 rounded-full p-3">
            <FileSvg className="w-md h-md text-primary-500" />
          </Column>
          <Separator size="sm" />
          <Column>
            <Text size="sm" className="text-center">
              Drag and drop files to upload
            </Text>
            <Text size="sm" className="text-center">
              or
            </Text>
          </Column>
          <Separator size="xs" />
          <Column className="w-48">
            <Button
              size="sm"
              variant="outline"
              intent="primary"
              shape="rounded"
              className="w-full"
              pending={pending}
            >
              Browser file
            </Button>
          </Column>
        </Column>
      )}
    </Column>
  )
}

FileUploadZone.Item = FileListItem

export default FileUploadZone

const containerClasses = `items-center justify-center p-sm border-2 border-dashed rounded focus:outline-none focus:ring focus:ring-primary-200 focus:ring-offset-4 focus:ring-inset transition`
const containerActiveClasses = `border-primary-300`
const containerDefaultClasses = `border-primary-50`
