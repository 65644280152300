import ThreadStateEnum from '@trinsly/common/src/types/thread-state-enum-type'

type ProspectHistoryType = {
  campaignName: string
  createdAt: string
  email: string
  emailClientId?: number
  id: Id
  lastSentAt?: string
  responseType: ResponseType
  responseVia: ResponseViaEnum
  state: ThreadStateEnum
  sourceUrl: string
  userId: Id
  userName: string
}

export default ProspectHistoryType

export enum ResponseViaEnum {
  EMAIL = 'email',
  LINKEDIN = 'linkedin',
  SMS = 'text',
  OTHER = 'other',
  CALL = 'call',
}

export enum ResponseType {
  DO_NOT_CONTACT = 'do_not_contact',
  INTERESTED = 'interested',
  INTERESTED_LATER = 'interested_later',
  NOT_AVAILABLE = 'not_available',
  NOT_INTERESTED = 'not_interested',
}
