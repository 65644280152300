import api from '@trinsly/common/src/services/api'
import ThreadType from 'types/thread-type'

type CancelThreadServiceProps = {
  threadId: ThreadType['id']
}

async function cancelThreadService({ threadId }: CancelThreadServiceProps) {
  const response = await api(`/email_threads/${threadId}/cancel`, {
    method: 'POST',
  })
  return response as any as ThreadType
}

export default cancelThreadService
