import Card from '@trinsly/common/src/components/atoms/card'
import Column from '@trinsly/common/src/components/atoms/column'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Tabs from '@trinsly/common/src/components/atoms/tabs'
import useQueryParams from '@trinsly/common/src/hooks/use-query-params'
import PrivateLayout from 'components/private-layout'
import { useNotificationsContext } from 'providers/notifications-provider'
import {
  INFO_TYPE,
  WARNING_TYPE,
} from 'providers/notifications-provider/notification-list-item'
import NotificationType from 'providers/notifications-provider/notification-type'
import React from 'react'
import AdvancedTab from './advanced-tab'
import DraftProvider from './draft-provider'
import DraftSection from './draft-provider/draft-section'
import FormProvider, { useFormContext } from './form-provider'
import GeneralTab from './general-tab'
import ReachOutsTab from './reach-outs-tab'
import ReportingTab from './reporting-tab'

export type CreateCampaignScreenProps = {}

function CampaignFormScreen(props: CreateCampaignScreenProps) {
  const isNewCampaignRoute =
    window?.location?.pathname?.includes('/campaigns/new')
  return (
    <FormProvider>
      {isNewCampaignRoute ? (
        <DraftProvider>
          <Screen {...(props || {})} />
        </DraftProvider>
      ) : (
        <Screen {...(props || {})} />
      )}
    </FormProvider>
  )
}

function Screen({}: CreateCampaignScreenProps) {
  const { isEdit, campaign, pending } = useFormContext()
  const { params: queryParams } = useQueryParams()
  const { addNotification, removeNotification } = useNotificationsContext()

  const [currentTab, setCurrentTab] = React.useState<
    'general' | 'advanced' | 'reach-outs' | 'reporting'
  >(queryParams.tabs || 'general')
  const shouldShowDuplicatedCampaignNotification = Boolean(
    queryParams?.['duplicating-campaign-id']
  )

  const shouldShowArchivedCampaignNotification =
    !pending && Boolean(campaign?.isArchived)

  React.useEffect(() => {
    const notification = {
      key: 'duplicated-campaign',
      variant: 'translucent',
      message:
        'You just duplicated this campaign. Please make sure that you update the signature and other campaign pieces as needed.',
      persist: 1,
      type: INFO_TYPE,
    } as NotificationType
    if (shouldShowDuplicatedCampaignNotification) {
      addNotification(notification)
    }

    return () => {
      removeNotification(notification)
    }
  }, [shouldShowDuplicatedCampaignNotification])

  React.useEffect(() => {
    const notification = {
      key: 'archived-campaign',
      variant: 'translucent',
      message: 'This campaign is archived.',
      persist: 1,
      type: WARNING_TYPE,
    } as NotificationType
    if (shouldShowArchivedCampaignNotification) {
      addNotification(notification)
    }
    return () => {
      removeNotification(notification)
    }
  }, [shouldShowArchivedCampaignNotification])

  return (
    <PrivateLayout>
      <Row className="px-lg py-sm gap-md bg-white items-center justify-between shadow">
        <Heading size="xl" fontWeight="bold">
          {isEdit ? campaign?.name || 'Edit Campaign' : 'New Campaign'}
        </Heading>
        <DraftSection />
      </Row>
      <Separator />
      <Column className="px-lg">
        <Card>
          <Tabs
            id="tabs"
            variant="query-string"
            defaultTab={currentTab}
            onChange={setCurrentTab as any}
            className="px-xl"
          >
            <Tabs.Tab id="general">General</Tabs.Tab>
            {isEdit && <Tabs.Tab id="reporting">Reporting</Tabs.Tab>}
            <Tabs.Tab id="advanced">Advanced</Tabs.Tab>
            {isEdit && <Tabs.Tab id="reach-outs">Reach Outs</Tabs.Tab>}
          </Tabs>
          {
            {
              general: <GeneralTab />,
              advanced: <AdvancedTab />,
              'reach-outs': <ReachOutsTab campaignId={campaign?.id || 0} />,
              reporting: <ReportingTab campaignId={campaign?.id || 0} />,
            }[currentTab]
          }
        </Card>
        <Separator size="xl" />
        <Separator size="xl" />
      </Column>
      <Separator size="xl" />
    </PrivateLayout>
  )
}

export default CampaignFormScreen
