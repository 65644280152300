import { debounce } from 'lodash'
import React from 'react'
import PageableType from '../types/pageable-type'
import useQueryParams from './use-query-params'

type UsePageableProps = Partial<PageableType>

function usePageable(initialValues: UsePageableProps = {}) {
  const { params, updateParams } = useQueryParams()

  //sort
  const hasSortInParams = params['orderBy'] && params['orderDirection']
  const sort = hasSortInParams
    ? {
        [params['orderBy']]: params['orderDirection'],
      }
    : initialValues?.sort || {}

  //filters
  const knownKeys = ['search', 'page', 'perPage', 'orderBy', 'orderDirection']
  const paramsFiltersKeys = Object.keys(params || {}).filter(
    (key) => !knownKeys.includes(key)
  )
  const initialFiltersKeys = Object.keys(initialValues?.filters || {}).filter(
    (key) => !knownKeys.includes(key)
  )
  const hasParamFilters = paramsFiltersKeys.length > 0
  const filtersKeys = hasParamFilters ? paramsFiltersKeys : initialFiltersKeys
  const filters = filtersKeys.reduce(
    (acc, key) => ({ ...acc, [key]: params[key] }),
    {}
  )

  let pageable: Partial<PageableType> = {
    search: params['search'] || initialValues?.search,
    page: params['page'] ? Number(params['page']) : initialValues?.page,
    perPage: params['perPage']
      ? Number(params['perPage'])
      : initialValues?.perPage,
    sort,
    filters,
  }

  const setSearch = React.useCallback(
    debounce((value) => updateParams({ search: value, page: 1 }), 500),
    []
  )

  function setFilters(
    path:
      | string
      | ((filters: PageableType['filters']) => PageableType['filters']),
    value?: any
  ) {
    if (typeof path === 'function') {
      const newFilters = path(filters)
      updateParams({ ...newFilters, page: 1 })
      return
    }
    updateParams({ [path]: value, page: 1 })
  }

  function setPageSize(value: number) {
    updateParams({ perPage: Number(value), page: 1 })
  }

  function nextPage() {
    const currentPage = Number(params['page'] || 1)
    updateParams({ page: currentPage + 1 })
  }

  function prevPage() {
    const currentPage = Number(params['page'] || 2)
    updateParams({ page: currentPage - 1 })
  }

  function setSort(
    orderBy: string,
    orderDirection: PageableType['sort'][string]
  ) {
    updateParams({ orderBy, orderDirection, page: 1 })
  }

  return {
    pageable: pageable as PageableType,
    nextPage,
    prevPage,
    setPageSize,
    setSort,
    setSearch,
    setFilters,
  }
}

export default usePageable
