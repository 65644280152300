import api from '@trinsly/common/src/services/api'

type ConvertNylasTokenServiceProps = {
  token: string
}

async function convertNylasTokenService({
  token,
}: ConvertNylasTokenServiceProps) {
  const response = (await api('/nylas_auth', {
    method: 'POST',
    body: { code: token },
  })) as any
  return response
}

export default convertNylasTokenService
