import { ReactComponent as DuplicateSvg } from '@trinsly/common/src/assets/icons/duplicate.svg'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Button from '@trinsly/common/src/components/molecules/button'
import { useHistory } from 'react-router'
import CampaignType from 'types/campaign-type'

type DuplicateCampaignButtonProps = {
  campaign: CampaignType
  disabled: boolean
}

function DuplicateCampaignButton({
  campaign,
  disabled,
}: DuplicateCampaignButtonProps) {
  const history = useHistory()

  return (
    <Button
      testId="duplicate-button"
      size="sm"
      variant="outline"
      disabled={disabled}
      onClick={() => {
        history.push(`/campaigns/new?duplicating-campaign-id=${campaign?.id}`)
      }}
    >
      <Row className="items-center">
        <DuplicateSvg className="w-sm h-sm" />
        <Separator size="xs" />
        Duplicate
      </Row>
    </Button>
  )
}

export default DuplicateCampaignButton
