import { ReactComponent as ChevronDown } from '@trinsly/common/src/assets/icons/chevron-down.svg'
import { ReactComponent as ChevronUp } from '@trinsly/common/src/assets/icons/chevron-up.svg'
import Column from '@trinsly/common/src/components/atoms/column'
import ProgressBar from '@trinsly/common/src/components/atoms/progress-bar'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Spinner from '@trinsly/common/src/components/atoms/spinner'
import Text from '@trinsly/common/src/components/atoms/text'
import {
  campaignResponseRate,
  zeroNotNaN,
} from 'screens/dashboard-screen/campaign-section'

export type NewReachOutsStatsItemProps = {
  title: string
  pending: boolean
  currentValue: number
  previousValue: number
  inProgress: number
}

export const NewReachOutsStatsItem = ({
  title,
  currentValue = 0,
  previousValue = 0,
  inProgress,
  pending,
}: NewReachOutsStatsItemProps) => {
  const isPositive = currentValue > previousValue
  const isNegative = currentValue < previousValue
  const currentValueRate = (inProgress / currentValue) * 100
  const rate = campaignResponseRate(currentValue, previousValue + 1)
  return (
    <>
      <Column className="min-w-36 h-full justify-between">
        <Text fontWeight="bold" className="uppercase">
          {title}
        </Text>
        {pending ? (
          <>
            <Row className="justify-center items-center py-md">
              <Spinner />
            </Row>
          </>
        ) : (
          <>
            <Separator size="xs" />
            <Row className="justify-between items-baseline">
              <Text className="text-4xl " fontWeight="bold">
                {currentValue || 0}
              </Text>
              <Row>
                {isPositive ? (
                  <Text color="success-400" fontWeight="bold" size="sm">
                    <Row className="items-center justify-center">
                      (<ChevronUp className="h-3 w-3" />) {rate}
                    </Row>
                  </Text>
                ) : (
                  isNegative && (
                    <Text color="danger-400" fontWeight="bold" size="sm">
                      <Row className="items-center">
                        (<ChevronDown className="h-3 w-3" />) {rate}
                      </Row>
                    </Text>
                  )
                )}
              </Row>
            </Row>
            <Text
              fontWeight="bold"
              size="xs"
              color="gray-400"
              className="uppercase"
            >
              {inProgress} in Progress
            </Text>
            <Text fontWeight="bold">
              {zeroNotNaN(currentValueRate.toFixed(1))}%
            </Text>
            <Separator size="xs" />
            <ProgressBar
              intent="success"
              value={zeroNotNaN(currentValueRate)}
              total={100}
            />
          </>
        )}
      </Column>
    </>
  )
}
export default NewReachOutsStatsItem
