import Row from '@trinsly/common/src/components/atoms/row'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import { EXTENSION_IMPERSONATE_USER_ID_KEY } from '@trinsly/common/src/services/api'
import useImpersonate from 'api-hooks/use-impersonate'
import { ReactComponent as CheckSvg } from 'icons/check.svg'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import UserType from 'types/user-type'

type ImpersonateButtonProps = {
  user: UserType
}

function ImpersonateButton({ user }: ImpersonateButtonProps) {
  const history = useHistory()
  const { fetch: impersonate, pending: pendingImpersonate } = useImpersonate({
    onSuccess: () => {
      toast.success('Impersonated successfully!')
      history.push('/')
    },
  })

  const isImpersonatingAlready =
    !pendingImpersonate &&
    localStorage?.getItem?.(EXTENSION_IMPERSONATE_USER_ID_KEY) ===
      String(user?.id)

  if (isImpersonatingAlready) {
    return (
      <Row className="items-center gap-xs">
        <CheckSvg className="w-5 h-5 text-success-500" />
        <Text size="sm" fontWeight="bold">
          Impersonating!
        </Text>
      </Row>
    )
  }
  return (
    <Button
      size="sm"
      pending={pendingImpersonate}
      onClick={() => {
        impersonate({ userId: user?.id })
      }}
    >
      <Row className="items-center gap-xs">
        <svg viewBox="0 0 48 48" className="w-5 h-5 text-success-500">
          <path d="M34.2 28.95q-1.95 0-3.325-1.375Q29.5 26.2 29.5 24.25q0-1.95 1.375-3.325Q32.25 19.55 34.2 19.55q1.95 0 3.325 1.375Q38.9 22.3 38.9 24.25q0 1.95-1.375 3.325Q36.15 28.95 34.2 28.95ZM24.4 40v-2.55q0-1.3.55-2.225T26.5 33.8q1.85-.95 3.75-1.4 1.9-.45 3.95-.45 2.05 0 3.95.425 1.9.425 3.75 1.425 1 .45 1.55 1.4t.55 2.25V40ZM20 23.95q-3.3 0-5.4-2.1-2.1-2.1-2.1-5.4 0-3.3 2.1-5.4 2.1-2.1 5.4-2.1 3.3 0 5.4 2.1 2.1 2.1 2.1 5.4 0 3.3-2.1 5.4-2.1 2.1-5.4 2.1ZM4 40v-4.7q0-1.7.85-3.125T7.4 30q3.35-1.5 6.425-2.25Q16.9 27 20 27q1.6 0 2.85.15t2.7.45L23 30.15q-.65-.1-1.4-.125Q20.85 30 20 30q-2.85 0-5.55.575-2.7.575-5.85 2.125-.7.35-1.15 1.1-.45.75-.45 1.5V37h14.4v3Zm17.4-3ZM20 20.95q1.95 0 3.225-1.275Q24.5 18.4 24.5 16.45q0-1.95-1.275-3.225Q21.95 11.95 20 11.95q-1.95 0-3.225 1.275Q15.5 14.5 15.5 16.45q0 1.95 1.275 3.225Q18.05 20.95 20 20.95Zm0-4.5Z" />
        </svg>
        Impersonate
      </Row>
    </Button>
  )
}

export default ImpersonateButton
