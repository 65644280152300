import api from '@trinsly/common/src/services/api'

export type VerifyGoogleAuthCodeServiceProps = {
  code: string
  email: string
}

export async function verifyGoogleAuthCodeService({
  code,
  email,
}: VerifyGoogleAuthCodeServiceProps): Promise<any> {
  const response = await api(
    `${process.env.REACT_APP_API_URL}/omniauth/google_oauth2/callback`,
    {
      method: 'POST',
      body: {
        code: code,
        email: email.toLowerCase(),
      },
    }
  )

  console.log('Auth Code Response,', response)

  return response as any
}
