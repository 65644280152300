import { Quill } from 'react-quill'

var AlignStyle = Quill.import('attributors/style/align')
var BackgroundStyle = Quill.import('attributors/style/background')
var ColorStyle = Quill.import('attributors/style/color')
var DirectionStyle = Quill.import('attributors/style/direction')
var FontStyle = Quill.import('attributors/style/font')
var SizeStyle = Quill.import('attributors/style/size')

Quill.register(AlignStyle, true)
Quill.register(BackgroundStyle, true)
Quill.register(ColorStyle, true)
Quill.register(DirectionStyle, true)
Quill.register(FontStyle, true)
Quill.register(SizeStyle, true)

export default {}
