import Column from '@trinsly/common/src/components/atoms/column'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Text from '@trinsly/common/src/components/atoms/text'
import SelectInput from '@trinsly/common/src/components/molecules/select-input'
import useQueryParams from '@trinsly/common/src/hooks/use-query-params'
import useGetCampaignReportStats from 'api-hooks/use-get-campaign-report-stats'
import { subDays } from 'date-fns'
import React from 'react'
import CampaignSummaryLineChart from './campaign-summary-line-chart'
import StatsSection from './stats-section'

export type ReportingTabProps = {
  campaignId: Id
}

function ReportingTab({ campaignId }: ReportingTabProps) {
  const [period, setPeriod] = React.useState<
    'last7Days' | 'last30Days' | 'last60Days'
  >('last7Days')
  const { params, updateParams } = useQueryParams()

  const { data: campaignReportStats, pending } = useGetCampaignReportStats({
    ...params,
    campaignId,
    startDate: params.startDate,
    endDate: params.endDate,
  })
  const getNow = () => new Date().toISOString()

  React.useEffect(() => {
    updateParams({
      startDate: subDays(new Date(), 7).toISOString(),
      endDate: getNow(),
    })
  }, [])

  return (
    <React.Fragment>
      <Column className="py-md px-xl">
        <Row className="flex-1 justify-between items-center">
          <Text fontWeight="bold" color="primary-500" size="lg">
            Campaign Stats
          </Text>
          <Column className="min-w-72">
            <SelectInput
              label=""
              value={period}
              onChange={(value) => {
                setPeriod(value as any)
                let startDate
                const endDate = new Date() as any
                switch (value) {
                  case 'last7Days':
                    startDate = subDays(endDate, 7)
                    break
                  case 'last30Days':
                    startDate = subDays(endDate, 30)
                    break
                  case 'last60Days':
                    startDate = subDays(endDate, 60)
                    break
                  default:
                    startDate = subDays(endDate, 7)
                    break
                }

                updateParams({
                  startDate: startDate?.toISOString(),
                  endDate: endDate?.toISOString(),
                })
              }}
              options={['last7Days', 'last30Days', 'last60Days']}
              itemRenderer={(value) =>
                ((
                  {
                    last7Days: 'Last 7 days',
                    last30Days: 'Last 30 days',
                    last60Days: 'Last 60 days',
                  } as any
                )[value])
              }
            />
          </Column>
        </Row>
        <Separator size="sm" />
        <StatsSection
          pending={pending}
          campaignReportStats={campaignReportStats}
        />
        <Separator size="xl" />
        <Column className="grid grid-cols-1 xl:grid-cols-2 gap-md">
          <CampaignSummaryLineChart
            title="Reach out counts"
            period={period}
            startDate={campaignReportStats?.current?.startDate}
            endDate={campaignReportStats?.current?.endDate}
            currentData={campaignReportStats?.current?.reachOuts || []}
            previousData={campaignReportStats?.previous?.reachOuts || []}
            pending={pending}
          />
          <CampaignSummaryLineChart
            title="Opened"
            period={period}
            startDate={campaignReportStats?.current?.startDate}
            endDate={campaignReportStats?.current?.endDate}
            currentData={campaignReportStats?.current?.opened || []}
            previousData={campaignReportStats?.previous?.opened || []}
            pending={pending}
          />
          <CampaignSummaryLineChart
            title="Responded"
            period={period}
            startDate={campaignReportStats?.current?.startDate}
            endDate={campaignReportStats?.current?.endDate}
            currentData={campaignReportStats?.current?.responded || []}
            previousData={campaignReportStats?.previous?.responded || []}
            pending={pending}
          />
          <CampaignSummaryLineChart
            title="Interested"
            period={period}
            startDate={campaignReportStats?.current?.startDate}
            endDate={campaignReportStats?.current?.endDate}
            currentData={campaignReportStats?.current?.interested || []}
            previousData={campaignReportStats?.previous?.interested || []}
            pending={pending}
          />
        </Column>
      </Column>
    </React.Fragment>
  )
}

export default ReportingTab
