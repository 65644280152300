import React from 'react'
import Clickable from '../atoms/clickable'
import Column from '../atoms/column'
import Row from '../atoms/row'
import Separator from '../atoms/separator'
import Text from '../atoms/text'

export type StepperProps = {
  steps: string[]
  current: number
  onClick?: (index: number, step: string) => void
}

function Stepper({ steps, current, onClick }: StepperProps) {
  return (
    <Row className="flex-1 items-center">
      {steps.map((step, index) => {
        const active = index === current
        return (
          <React.Fragment key={index}>
            <Column
              className={`min-w-24 pr-xs ${index === 0 ? '' : 'pl-4'} ${
                active ? 'bg-primary-50' : 'bg-gray-100'
              }`}
            >
              <Clickable
                onClick={() => onClick?.(index, steps[index])}
                className="min-h-6 items-center px-xs"
              >
                <Row className="items-center">
                  <Column
                    className={`rounded-full items-center justify-center min-w-4 min-h-4 ${
                      active ? 'bg-primary-100' : 'bg-gray-200'
                    }`}
                  >
                    <Text
                      size="xs"
                      fontWeight="bold"
                      color={active ? `primary-500` : 'gray-400'}
                    >
                      {index + 1}
                    </Text>
                  </Column>
                  <Separator size="xs" />
                  <Text
                    size="xs"
                    fontWeight="bold"
                    color={active ? `primary-500` : 'gray-400'}
                    className="uppercase whitespace-nowrap"
                  >
                    {step}
                  </Text>
                </Row>
              </Clickable>
            </Column>
            <Arrow color={active ? 'text-primary-50' : 'text-gray-100'} />
          </React.Fragment>
        )
      })}
    </Row>
  )
}

export default Stepper

function Arrow({ color }: { color: 'text-primary-50' | 'text-gray-100' }) {
  return (
    <Row className="items-center w-0 h-0 z-10">
      <Column
        className={`w-0 h-0 ${color}`}
        style={{
          zIndex: 1,
          borderWidth: '0.75rem',
          borderColor: 'transparent transparent transparent currentColor',
        }}
      />
      <Column
        className="w-0 h-0"
        style={{
          marginLeft: '-1.5rem',
          borderWidth: '0.938rem',
          borderColor: 'transparent transparent transparent white',
        }}
      />
    </Row>
  )
}
