import { ReactComponent as ArchiveSvg } from '@trinsly/common/src/assets/icons/archive.svg'
import Column from '@trinsly/common/src/components/atoms/column'
import Tooltip from '@trinsly/common/src/components/atoms/tooltip'
import IconButton from '@trinsly/common/src/components/molecules/icon-button'
import useArchiveProject from 'api-hooks/use-archive-project'
import { toast } from 'react-toastify'
import ProjectType from 'types/project-type'

type ArchiveButtonProps = {
  project: ProjectType
}

function ArchiveButton({ project }: ArchiveButtonProps) {
  const { fetch: archiveProject, pending: pendingArchive } = useArchiveProject({
    onSuccess: () => {
      toast.success('Project archived!')
    },
    onError: () => {
      toast.error('Error archiving Project')
    },
  })

  return (
    <Column>
      <Tooltip content="Archive Project">
        <IconButton
          size="sm"
          variant="ghost"
          shape="circle"
          intent="danger"
          pending={pendingArchive}
          onClick={() => {
            archiveProject({ project })
          }}
        >
          <ArchiveSvg />
        </IconButton>
      </Tooltip>
    </Column>
  )
}

export default ArchiveButton
