import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation } from 'react-query'
import Settings from 'types/settings-type'

import updateUserSettingsService from 'services/settings/update-user-settings-service'
import useGetUserSettings from './use-get-user-settings'

export type UseUpdateUserSettingsProps = {} & MutateApiHookType<Settings>

function useUpdateUserSettings({
  onSuccess,
  onError,
}: UseUpdateUserSettingsProps) {
  const { refetch } = useGetUserSettings()

  const { mutate: fetch, isLoading: pending } = useMutation(
    updateUserSettingsService,
    {
      onSuccess: async (data) => {
        await refetch()
        onSuccess?.(data)
      },
      onError,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useUpdateUserSettings
