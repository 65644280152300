import React from 'react'
import ReactQuill from 'react-quill'
import { QuillEditorProps } from '.'

export type ToolbarProps = {
  reactQuillRef: React.RefObject<ReactQuill>
} & Pick<QuillEditorProps, 'id' | 'modules' | 'disabled'>

function Toolbar({ id, disabled, reactQuillRef }: ToolbarProps) {
  React.useEffect(() => {
    const quillEditorElement = document.getElementById(id || '')
    if (quillEditorElement) {
      const buttons = quillEditorElement.getElementsByTagName('button')
      const focusable = quillEditorElement.querySelectorAll('[tabIndex="0"]')
      Array.from(buttons).forEach((element) => {
        element.setAttribute('tabIndex', disabled ? '-1' : '0')
      })
      Array.from(focusable).forEach((element) => {
        element.setAttribute('tabIndex', disabled ? '-1' : '0')
      })
    }
  }, [id, disabled])

  return (
    <div id={id} className="flex flex-col">
      <div
        className={[
          'flex flex-row flex-wrap',
          disabled ? disabledClasses : '',
        ].join(' ')}
      >
        <span className="ql-formats">
          <select className="ql-header" defaultValue="">
            <option value="1"></option>
            <option value="2"></option>
            <option value="3"></option>
            <option value="4"></option>
            <option value="5"></option>
            <option value="6"></option>
            <option value=""></option>
          </select>
          <select className="ql-font" defaultValue=""></select>
        </span>
        <span className="ql-formats">
          <button className="ql-bold"></button>
          <button className="ql-italic"></button>
          <button className="ql-underline"></button>
          <button className="ql-strike"></button>
          <button className="ql-blockquote"></button>
        </span>
        <span className="ql-formats">
          <select className="ql-color"></select>
          <select className="ql-background"></select>
        </span>
        <span className="ql-formats">
          <button className="ql-list" value="ordered"></button>
          <button className="ql-list" value="bullet"></button>
          <button className="ql-indent" value="-1"></button>
          <button className="ql-indent" value="+1"></button>
        </span>
        <span className="ql-formats">
          <select className="ql-align"></select>
          <button className="ql-direction" value="rtl"></button>
        </span>
        <span className="ql-formats">
          <button className="ql-link"></button>
          <button className="ql-image"></button>
        </span>
        <span className="ql-formats">
          <button className="ql-clean"></button>
        </span>
        <span className="ql-formats">
          <button className="ql-emoji"></button>
        </span>
      </div>
    </div>
  )
}

export default Toolbar

const disabledClasses = `opacity-50 pointer-events-none`
