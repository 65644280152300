import api from '@trinsly/common/src/services/api'
import PageableType from '@trinsly/common/src/types/pageable-type'
import { pickBy } from 'lodash'
import UserType from 'types/user-type'

type GetUsersServiceProps = {} & Pick<PageableType, 'search'>

async function getUsersService({ search }: GetUsersServiceProps) {
  const response = await api(`/users`, {
    method: 'GET',
    params: pickBy({
      search,
    }),
  })

  return response as UserType[]
}

export default getUsersService
