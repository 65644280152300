import { useQuery } from 'react-query'
import getActivityLogsService, {
  GetActivityLogsServiceProps,
} from 'services/get-activitiy-logs-service'
import ActivityLogType from 'types/activity-log-type'
type UseGetActivityLogsProps = {} & GetActivityLogsServiceProps
function useGetActivityLogs({ userId }: UseGetActivityLogsProps) {
  const {
    data,
    error,
    status,
    refetch,
    isRefetching: refetching,
  } = useQuery(
    ['/activity_logs', userId],
    () => getActivityLogsService({ userId }),
    {
      staleTime: 3600000,
      cacheTime: 3600000,
      refetchOnWindowFocus: false,
    }
  )

  return {
    data: data || ([] as ActivityLogType[]),
    pending: status === 'loading',
    error,
    refetch,
    refetching,
  }
}

export default useGetActivityLogs
