import React from 'react'
import { Theme } from '../../types/theme-type'

export type LinkProps = {
  intent?: Theme['intent']
  disabled?: boolean
  size?: Theme['size'] | '2xl' | '3xl'
} & React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>

function Link({
  children,
  intent = 'primary',
  className,
  disabled = false,
  href,
  size = 'sm',
  tabIndex = 0,
  ...rest
}: LinkProps) {
  return (
    <a
      tabIndex={disabled ? -1 : tabIndex}
      href={disabled ? undefined : href}
      className={[
        classes,
        `text-${size}`,
        disabled ? disabledClasses : activeClasses,
        className,
      ].join(' ')}
      style={{ textAlign: 'inherit' }}
      {...rest}
    >
      {children}
    </a>
  )
}

export default Link

const classes = `inline-flex transition focus:outline-none text-primary-500`
const activeClasses =
  'cursor-pointer hover:text-primary-600 focus:ring focus:ring-primary-200'
const disabledClasses = `cursor-default`
