import Select, {
  SelectProps,
} from '@trinsly/common/src/components/molecules/select'
import React from 'react'

const translatePeriodEnum: Record<string, string> = {
  week: 'Week',
  month: 'Month',
}

const PERIODS = ['week', 'month'] as ('week' | 'month')[]

type SelectPeriodProps = {} & Omit<
  SelectProps<'week' | 'month'>,
  'itemRenderer' | 'pending' | 'options'
>

function SelectPeriod(props: SelectPeriodProps) {
  return (
    <Select
      {...props}
      options={PERIODS}
      itemRenderer={(item) => translatePeriodEnum[item]}
    />
  )
}

export default SelectPeriod
