import { useQuery } from 'react-query'
import getProjectCampaignsService, {
  GetProjectCampaignsServiceProps,
} from 'services/get-project-campaigns-service'

type UseGetCampaignsProps = {} & GetProjectCampaignsServiceProps

function useGetProjectCampaigns({
  projectId,
  ...pageable
}: UseGetCampaignsProps) {
  const { data, error, status } = useQuery(
    ['/campaigns', projectId, pageable],
    () => getProjectCampaignsService({ projectId, ...pageable }),
    {
      enabled: Boolean(projectId),
    }
  )

  const campaigns = data?.campaigns || []

  return {
    data: campaigns,
    total: data?.meta?.pagination?.totalObjects || 0,
    pending: status === 'loading',
    error,
  }
}

export default useGetProjectCampaigns
