import Column from '@trinsly/common/src/components/atoms/column'
import { InputProps } from '@trinsly/common/src/components/atoms/input'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Text from '@trinsly/common/src/components/atoms/text'
import TextInput from '@trinsly/common/src/components/molecules/text-input'

export type EmailDurationInputProps = {
  id: InputProps['id']
  value: InputProps['value']
  error: InputProps['error']
  onChange: InputProps['onChange']
  disabled?: boolean
}

function EmailDurationInput({
  id,
  value,
  onChange,
  error,
  disabled,
}: EmailDurationInputProps) {
  return (
    <Column>
      <Row className="items-center">
        <Text size="sm">After</Text>
        <Separator size="xs" />
        <Column className="w-20 whitespace-nowrap">
          <TextInput
            id={id}
            type="number"
            min={0}
            max={120}
            step={1}
            value={value}
            onChange={onChange}
            error={error}
            required
            disabled={disabled}
          />
        </Column>
        <Separator size="xs" />
        <Text size="sm">
          days, if there is no reply, send a follow-up email.
        </Text>
      </Row>
    </Column>
  )
}

export default EmailDurationInput
