import api from './api'

const API_URL = process.env.REACT_APP_API_URL || ''
const S3_BASE_API_URL = `${API_URL}/s3/sign`

export type GetSignedImageUrlFromS3ServiceProps = {
  filename: string
  mimetype: string
}

async function getSignedImageUrlFromS3Service({
  filename,
  mimetype,
}: GetSignedImageUrlFromS3ServiceProps) {
  try {
    const response = await api(`${S3_BASE_API_URL}`, {
      method: 'GET',
      params: {
        content_type: mimetype,
        object_name: filename,
      },
    })
    return response as any
  } catch (error: any) {
    throw error
  }
}

export default getSignedImageUrlFromS3Service
