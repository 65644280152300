import Card from '@trinsly/common/src/components/atoms/card'
import PdfViewer from '@trinsly/common/src/components/atoms/pdf-viewer'
import Modal from '@trinsly/common/src/components/molecules/modal'
import React from 'react'

export type ResumeModalProps = {
  src: string | Blob
  visible: boolean
  onRequestClose: () => void
}

function ResumeModal({ src, visible, onRequestClose }: ResumeModalProps) {
  return (
    <Modal size="lg" visible={visible} onRequestClose={onRequestClose}>
      <Card className="flex-1 p-md bg-gray-100">
        <PdfViewer src={src} options={{ scale: 1.2 }} />
      </Card>
    </Modal>
  )
}

export default ResumeModal
