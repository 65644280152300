import React from 'react'
import { Theme } from '../../types/theme-type'

export type ProgressBarProps = {
  value: number
  total: number
  intent: 'primary' | 'success'
  size?: Theme['size']
}

function ProgressBar({
  intent = 'primary',
  size = 'md',
  value = 0,
  total = 0,
}: ProgressBarProps) {
  const percent =
    (!total || total <= 0 ? 0 : value > total ? 1 : value / total) * 100

  return (
    <div className={`${containerClasses} ${sizeClassMap[size]}`}>
      <div
        className={`${barClasses} ${intentClassMap[intent]}`}
        style={{ maxWidth: `${percent}%` }}
      />
    </div>
  )
}
export default ProgressBar

const containerClasses = `flex rounded-full bg-gray-200`
const barClasses = `flex-1 transition-all duration-300 linear rounded-full`
const intentClassMap = {
  primary: `bg-primary-500`,
  success: `bg-success-500`,
}
const sizeClassMap = {
  xs: `min-h-0.5`,
  sm: `min-h-1`,
  md: `min-h-2`,
  lg: `min-h-3`,
  xl: `min-h-4`,
}
