/**
 * on trinsly page load, we send most up to date jwt to extension
 * @param param0
 */
export const sendTokenToChromeExtension = ({
  jwt = '',
  impersonateUserId,
}: {
  jwt: string
  impersonateUserId: string
}) => {
  if (!jwt) {
    console.warn('Trinsly: JWT to Chrome - no JWT', jwt)
    return
  }

  try {
    //New Extension - v3
    chrome.runtime.sendMessage('efpmfikikojpigfhfgjjmbmkapboliio', {
      jwt,
      impersonateUserId,
    })
    //v2 - to be deprecated and switched out
    chrome.runtime.sendMessage(
      process.env.REACT_APP_CHROME_EXTENSION_ID || '',
      {
        jwt,
        impersonateUserId,
      }
    )
  } catch (error) {
    console.warn('Trinsly - cannot sendMessage through chrome')
  }
}

export const logoutOfChromeExtension = () => {
  try {
    //New Extension - v3
    chrome.runtime.sendMessage('efpmfikikojpigfhfgjjmbmkapboliio', {
      logout: true,
    })
    //v2 - to be deprecated and switched out
    chrome.runtime.sendMessage(
      process.env.REACT_APP_CHROME_EXTENSION_ID || '',
      {
        logout: true,
      }
    )
  } catch (error) {
    console.warn('Trinsly - cannot logout of extension')
  }
}
