import { ReactComponent as MicrosoftSvg } from '@trinsly/common/src/assets/icons/microsoft.svg'
import { useState } from 'react'

import Separator from '@trinsly/common/src/components/atoms/separator'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'

import startMicrosoftAuthService from 'services/microsoft/start-microsoft-auth-service'

/**
 * Uses Nylas as provider
 * @param param0
 */
export function MicrosoftAuthV1({
  email,
  disabled = false,
}: {
  email: string
  disabled?: boolean
}) {
  const [pending, setPending] = useState(false)

  return (
    <Button
      size="md"
      onClick={async () => {
        setPending(true)
        window.location.href = await startMicrosoftAuthService({ email })
      }}
      disabled={disabled}
      pending={pending}
      variant="outline"
      intent="info"
    >
      <>
        <MicrosoftSvg className="w-6 h-6" />
        <Separator />
        <Text fontWeight="semibold"> Connect Outlook </Text>
      </>
    </Button>
  )
}

export default MicrosoftAuthV1
