import Column from '@trinsly/common/src/components/atoms/column'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Text from '@trinsly/common/src/components/atoms/text'

export type SubtabItemProps = {
  title?: string
  icon?: React.ReactNode | React.ReactNodeArray
  currentTab?: string
}

const SubtabItem = ({ title, icon, currentTab }: SubtabItemProps) => {
  const active = currentTab === title?.toLowerCase()
  return (
    <Column className="flex-1">
      <Row
        key={title}
        className={`p-sm rounded-lg ${active && currentTabClasses}`}
      >
        <Column className={active ? 'text-primary-500' : 'text-gray-500'}>
          {icon}
        </Column>
        <Separator size="sm" />
        <Text
          size="sm"
          fontWeight={active ? 'bold' : undefined}
          color={active ? 'primary-500' : undefined}
        >
          {title}
        </Text>
      </Row>
    </Column>
  )
}
export default SubtabItem

const currentTabClasses =
  'transition-all duration-500 bg-gradient-to-r from-primary-100'
