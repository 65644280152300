import bullhornLogoSrc from '@trinsly/common/src/assets/images/bullhorn.png'
import greenhouseLogoSrc from '@trinsly/common/src/assets/images/greenhouse.png'
import leverLogoSrc from '@trinsly/common/src/assets/images/lever.png'
import {
  default as clockworkLogoSrc,
  default as workableLogoSrc,
} from '@trinsly/common/src/assets/images/workable.png'
import Card from '@trinsly/common/src/components/atoms/card'
import Column from '@trinsly/common/src/components/atoms/column'
import Form from '@trinsly/common/src/components/atoms/form'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Label from '@trinsly/common/src/components/atoms/label'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Switch from '@trinsly/common/src/components/atoms/switch'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import Modal from '@trinsly/common/src/components/molecules/modal'
import TextInput from '@trinsly/common/src/components/molecules/text-input'
import useForm from '@trinsly/common/src/hooks/use-form'
import api from '@trinsly/common/src/services/api'
import required from '@trinsly/common/src/validations/required'
import useCreateIntegration from 'api-hooks/use-create-integration'
import React from 'react'
import CrmEnum from 'types/crm-enum'

export type IntegrationFormModalProps = {
  integration?: Integration
  integrationType: CrmEnum
  visible: boolean
  onRequestClose: () => void
}

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

function IntegrationFormModal({
  integration,
  integrationType,
  visible,
  onRequestClose,
}: IntegrationFormModalProps) {
  const [modalState, setModalState] = React.useState<
    'form-step' | 'success-step'
  >('form-step')

  const { fetch, pending } = useCreateIntegration({
    onSuccess: async (data) => {
      if (integrationType === CrmEnum.BULLHORN) {
        const response = (await api(`/bullhorn`, { method: 'GET' })) as any
        window.location = response.redirect
      } else {
        setModalState('success-step')
      }
    },
    onError: console.error,
  })

  const { getValue, setValue, setValues, getError, submit, reset } = useForm({
    onSubmit: ({ isMaster, ...rest }) => {
      fetch({
        ...rest,
        is_master: isMaster,
        crm_type: integrationType,
      })
    },
    validations: integrationValidationMap[integrationType] || {},
  })

  React.useEffect(() => {
    if (visible) {
      setModalState('form-step')
      reset()
      setValues({
        isMaster: true,
        ...(integration || {}),
        ...integrationPropsMap[integrationType],
      })
    }
  }, [visible])

  return (
    <Modal visible={visible} onRequestClose={onRequestClose}>
      {modalState === 'success-step' && (
        <Card className="p-xl">
          <Column className="items-center">
            <Column
              className="relative"
              style={{
                width: 160,
                height: 160,
                border: '4px solid #00C8531a',
                borderRadius: '100%',
              }}
            >
              <Column className="absolute top-0 right-0">
                <svg
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="24"
                    cy="24"
                    r="22"
                    fill="#00C853"
                    stroke="white"
                    stroke-width="4"
                  />
                  <path
                    d="M20.9174 30.9037C20.7243 30.9037 20.5311 30.8302 20.3842 30.6822L14.2211 24.5191C13.9263 24.2243 13.9263 23.7464 14.2211 23.4516C14.5159 23.1568 14.9938 23.1568 15.2888 23.4516L20.9185 29.0813L32.7114 17.2885C33.0062 16.9937 33.4841 16.9937 33.7789 17.2885C34.0737 17.5833 34.0737 18.0614 33.7789 18.3562L21.4528 30.6822C21.3038 30.8302 21.1107 30.9037 20.9174 30.9037Z"
                    fill="white"
                  />
                </svg>
              </Column>
              <Column
                className="items-center justify-center w-full h-full rounded-full"
                style={{
                  border: '1px solid #00C853',
                }}
              >
                <img
                  src={logoSrcMap[integrationType]}
                  width={120}
                  style={{ objectFit: 'contain' }}
                />
              </Column>
            </Column>
            <Separator />
            <Heading
              size="lg"
              fontWeight="bold"
              color="primary-500"
              className="text-center"
            >{`${integrationNameMap[integrationType]} Successful!`}</Heading>
            <Separator />
            <Text className="text-center">
              With your new {integrationNameMap[integrationType]} you can add
              prospects straight from Talent CRM into{' '}
              {capitalizeFirstLetter(integrationType)}. If you have any
              questions about how to use this specific integration please reach
              out to support@chatkick.com
            </Text>
            <Separator size="xl" />
            <Column className="min-w-40">
              <Button intent="primary" onClick={onRequestClose}>
                Close
              </Button>
            </Column>
          </Column>
        </Card>
      )}
      {modalState === 'form-step' && (
        <Card className="px-xl py-md">
          <Column className="justify-center" style={{ width: 130, height: 53 }}>
            <img
              src={logoSrcMap[integrationType]}
              width="100%"
              height="100%"
              style={{ objectFit: 'contain' }}
            />
          </Column>
          <Separator size="lg" />
          <Heading size="lg" fontWeight="bold" color="primary-500">
            {integrationNameMap[integrationType]}
          </Heading>
          <Separator size="lg" />
          <Form onSubmit={submit}>
            {integrationType === CrmEnum.GREENHOUSE && (
              <React.Fragment>
                <TextInput
                  label="API Key"
                  value={getValue('apiKey')}
                  onChange={(value) => setValue('apiKey', value)}
                  error={getError('apiKey')}
                />
              </React.Fragment>
            )}
            {integrationType === CrmEnum.WORKABLE && (
              <React.Fragment>
                <TextInput
                  label="API Key"
                  value={getValue('apiKey')}
                  onChange={(value) => setValue('apiKey', value)}
                  error={getError('apiKey')}
                />
                <Separator />
                <TextInput
                  label="Subdomain"
                  value={getValue('workableSubdomain')}
                  onChange={(value) => setValue('workableSubdomain', value)}
                  error={getError('workableSubdomain')}
                />
              </React.Fragment>
            )}
            {integrationType === CrmEnum.BULLHORN && (
              <React.Fragment>
                <TextInput
                  label="Client Id"
                  value={getValue('clientId')}
                  onChange={(value) => setValue('clientId', value)}
                  error={getError('clientId')}
                />
                <Separator />
                <TextInput
                  label="Client Secret"
                  value={getValue('clientSecret')}
                  onChange={(value) => setValue('clientSecret', value)}
                  error={getError('clientSecret')}
                />
              </React.Fragment>
            )}
            <Separator />
            <Label>Is this an admin-level integration?</Label>
            <Separator size="xs" />
            <Switch
              checked={getValue('isMaster')}
              onChange={(value) => setValue('isMaster', value)}
            />
            <Row className="justify-end">
              <Column className="min-w-40">
                <Button type="submit" intent="primary" pending={pending}>
                  Continue
                </Button>
              </Column>
            </Row>
          </Form>
        </Card>
      )}
    </Modal>
  )
}

export default IntegrationFormModal

const logoSrcMap = {
  lever: leverLogoSrc,
  bullhorn: bullhornLogoSrc,
  workable: workableLogoSrc,
  greenhouse: greenhouseLogoSrc,
  cats: greenhouseLogoSrc,
  crelate: greenhouseLogoSrc,
  pcrecruiter: greenhouseLogoSrc,
  chatkick: greenhouseLogoSrc,
  clockwork: clockworkLogoSrc,
}

const integrationNameMap = {
  lever: 'Lever Integration',
  bullhorn: 'Bullhorn Integration',
  workable: 'Workable Integration',
  greenhouse: 'Greenhouse Integration',
  cats: 'Cats Integration',
  crelate: 'Crelate Integration',
  pcrecruiter: 'Pcrecruiter Integration',
  chatkick: 'Chatkick Integration',
  clockwork: 'Clockwork Integration',
}

const integrationPropsMap: any = {
  bullhorn: {
    apiDataCenter: 'https://api-emea.bullhornstaffing.com',
    authDataCenter: 'https://auth-emea.bullhornstaffing.com',
    restDataCenter: 'https://rest-emea.bullhornstaffing.com',
    clientId: '',
    clientSecret: '',
  },
}

const integrationValidationMap: any = {
  [CrmEnum.GREENHOUSE]: {
    apiKey: [required],
  },
  [CrmEnum.WORKABLE]: {
    apiKey: [required],
    workableSubdomain: [required],
  },
  [CrmEnum.BULLHORN]: {
    clientId: [required],
    clientSecret: [required],
  },
}
