import Column from '@trinsly/common/src/components/atoms/column'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import ModalV2 from '@trinsly/common/src/components/molecules/modal-v2'
import useCreateEmailMagic from 'api-hooks/use-request-access'
import { useAuth } from 'providers/auth-provider'
import React from 'react'
import { toast } from 'react-toastify'
import Typist from 'react-typist'

type EmailMagicModalProps = { visible: boolean; onRequestClose: () => void }

function EmailMagicModal({ visible, onRequestClose }: EmailMagicModalProps) {
  const { user } = useAuth()
  const { fetch: requestAccess, pending: pendingRequest } = useCreateEmailMagic(
    {
      onSuccess: (data) => {
        toast.success("You're on the list - we will be in touch soon.", {
          position: 'top-center',
        })

        setTimeout(() => onRequestClose?.(), 1500)
      },
      onError: (error) => {
        toast.error('Failed to request access - try again later')
        onRequestClose?.()
      },
    }
  )
  React.useEffect(() => {
    if (visible) {
      //reset()
    }
  }, [visible])

  return (
    <ModalV2 visible={visible} onRequestClose={onRequestClose}>
      <Column className="p-md gap-md" style={{ minWidth: 600 }}>
        <Typist avgTypingDelay={35}>
          <Column className="gap-xs">
            <Heading size="xl" color="primary-800" fontWeight="semibold">
              Recruiting emails will never be the same again...
            </Heading>
            <Text>
              With Chatkick Email Magic you can write and remix emails in record
              time.
            </Text>
            <Button
              disabled={!user}
              pending={pendingRequest}
              onClick={async () => {
                await requestAccess({
                  email: user?.email,
                  id: user?.id,
                  name: user?.name,
                })
              }}
              shape="rounded"
              variant="outline"
              intent="primary"
            >
              Request Access
            </Button>
          </Column>
        </Typist>
      </Column>
    </ModalV2>
  )
}

export default EmailMagicModal
