import Badge from '@trinsly/common/src/components/atoms/badge'
import Column from '@trinsly/common/src/components/atoms/column'
import Row from '@trinsly/common/src/components/atoms/row'
import Breadcrumbs from '@trinsly/common/src/components/molecules/breadcrumbs'
import React from 'react'
import { useHistory } from 'react-router'
import { useFormContext } from '../form-provider'
import KPIBarChart from './kpi-bar-chart'
import KPIList from './kpi-list'
import ProjectInlineForm from './project-inline-form'

type HeaderProps = {}

function Header({}: HeaderProps) {
  const [period, setPeriod] = React.useState<'week' | 'month'>('week')
  const history = useHistory()
  const { project, viewState } = useFormContext()
  return (
    <Column className="bg-white shadow px-lg pt-sm pb-lg gap-md">
      <Column className="gap-md lg:flex-row lg:flex-wrap">
        <Column className="gap-3 w-52">
          <Breadcrumbs
            steps={['Projects', project?.name || '--']}
            onBack={() => {
              history.push('/projects')
            }}
          />
          {viewState === 'detail' && (
            <Row className="pl-10 items-center gap-xs">
              {project?.tagList?.map((tag) => (
                <Badge size="xs" intent="primary">
                  {tag}
                </Badge>
              ))}
            </Row>
          )}
        </Column>
        <ProjectInlineForm />
      </Column>
      <Column className="border rounded-lg shadow-lg pt-md pb-md gap-sm">
        <KPIList period={period} onPeriodChange={setPeriod} />
        <KPIBarChart period={period} />
      </Column>
    </Column>
  )
}

export default Header
