import Column from '@trinsly/common/src/components/atoms/column'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Spinner from '@trinsly/common/src/components/atoms/spinner'
import useQueryParams from '@trinsly/common/src/hooks/use-query-params'
import useConvertNylasToken from 'api-hooks/use-convert-nylas-token'
import { EmailAuthState } from 'components/email-auth-modal'
import { get } from 'lodash'
import { useEffect } from 'react'
import { Redirect, useHistory } from 'react-router'
import { toast } from 'react-toastify'

function EmailProviderAuthorizationCallbackScreen() {
  const history = useHistory()
  const { params } = useQueryParams()
  const { fetch: convertNylasToken } = useConvertNylasToken({
    onSuccess: () => {
      history.push(`/?email-auth-state=${EmailAuthState.SUCCESS}`)
    },
    onError: (error) => {
      const message =
        get(error, 'errors') ||
        error?.message ||
        JSON.stringify(error || 'Error converting token')
      console.log(message, error)
      toast.error(message)
      history.push(`/?email-auth-state=${EmailAuthState.ERROR}`)
    },
  })

  const code = params?.['code']
  useEffect(() => {
    if (code) {
      convertNylasToken(code)
    }
  }, [code])

  if (!code) {
    return <Redirect to="/" />
  }

  return (
    <Column className="p-xl items-center gap-md">
      <Heading size="2xl" fontWeight="bold">
        You're almost there. We're wrapping up your email provider
        authorization.
      </Heading>
      <Spinner size="xl" />
    </Column>
  )
}

export default EmailProviderAuthorizationCallbackScreen
