//https://be-viralcopy.herokuapp.com/emailrewriter/:tone

import axios from 'axios'
import UserType from 'types/user-type'

type CreateEmailMagicServiceProps = {
  tone: 'friendly' | 'fun' | 'convincing'
  subject?: string
  body: string
}
/**
 * Currently in alpha - incomplete
 */
const EMAIL_MAGIC_URL = 'https://be-viralcopy.herokuapp.com/emailrewriter/'
const REQUEST_FEATURE = 'https://hooks.zapier.com/hooks/catch/10973370/bnw0ule/'
async function createEmailMagicService({
  tone,
  subject,
  body,
}: CreateEmailMagicServiceProps) {
  const response = await axios.request({
    method: 'POST',
    //todo
    url: `${EMAIL_MAGIC_URL}${tone}`,
    data: { subject, body },
  })

  return response?.data as any
}

export default createEmailMagicService
export type RequestMagicServiceProps = {
  id?: UserType['id']
  email?: UserType['email']
  name?: UserType['name']
}
export async function requestMagic({
  id,
  email,
  name,
}: RequestMagicServiceProps) {
  try {
    const response = await axios.post(
      REQUEST_FEATURE,
      JSON.stringify({ email, id, name })
      // {
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json',
      //   },
      // }
    )
    return response?.data as any
  } catch (error) {
    console.error('Failed to request feature')
    throw error
  }
}
