import Column from '@trinsly/common/src/components/atoms/column'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Row from '@trinsly/common/src/components/atoms/row'
import Text from '@trinsly/common/src/components/atoms/text'
import React from 'react'
import CampaignType from 'types/campaign-type'
import ScheduleTimeSelectEnum from 'types/schedule-time-select-enum'
import { useDraftContext } from '../draft-provider'
import { useFormContext } from '../form-provider'
import DatePicker from './date-picker'
import ScheduleType from './schedule-type'
import TimeSelect from './time-select'

type ScheduleSectionProps = {}

function ScheduleSection({}: ScheduleSectionProps) {
  const { getValue, setValue } = useFormContext()
  const { pending: pendingDraft } = useDraftContext()

  const shouldDisableForm = pendingDraft
  const campaign = getValue('campaign') as CampaignType | undefined
  const templatesDurationAsString = campaign?.templates?.reduce?.(
    (acc, template) => `${acc}${template?.duration || 0}`,
    ''
  )

  //autofill schedules using campaign template duration
  //when any template duration changes
  React.useEffect(() => {
    const newSchedules = campaign?.templates?.map((template, index) => {
      return {
        daysAfter: Number(template?.duration || index),
        time: ScheduleTimeSelectEnum.UNMODIFIED_VALUE,
      }
    }) as ScheduleType[]
    setTimeout(() => {
      setValue('schedules', newSchedules)
    }, 0)
  }, [templatesDurationAsString])

  const schedules = (getValue('schedules') || []) as ScheduleType[]

  return (
    <Column className="px-lg gap-md">
      <Heading size="lg" fontWeight="bold">
        Schedule
      </Heading>
      <Column className="space-y-sm">
        {schedules?.map((schedule, index) => {
          const { daysAfter, time } = schedule
          const minDaysAfter = index === 0 ? -1 : index - 1

          return (
            <Row className="items-center gap-md" key={index}>
              <Column className="px-xl py-xs rounded-full bg-primary-50 min-w-40">
                <Text
                  size="sm"
                  fontWeight="bold"
                  color="primary-500"
                  className="uppercase text-center"
                >{`Email ${index + 1}`}</Text>
              </Column>
              <DatePicker
                daysAfter={daysAfter}
                minDaysAfter={minDaysAfter}
                disabled={shouldDisableForm}
                onChange={(value) => {
                  const newSchedulesAfterBump = bumpSubsequentDaysAfterIfNeeded(
                    schedules,
                    index,
                    value
                  )
                  setValue('schedules', newSchedulesAfterBump)
                }}
              />
              <Column className="min-w-64">
                <TimeSelect
                  time={time}
                  daysAfter={daysAfter}
                  disabled={shouldDisableForm}
                  onChange={(value) =>
                    setValue(`schedules[${index}].time`, value)
                  }
                />
              </Column>
            </Row>
          )
        })}
      </Column>
    </Column>
  )
}

export default ScheduleSection

/**
 * Here we check to see if we need to bump up or down any of the dates to
 * maintain at least 1 day apart between each email. While bumping down, we
 * shouldn't have to worry about the going past today, because that logic is
 * built into the disable.
 */
export function bumpSubsequentDaysAfterIfNeeded(
  schedules: ScheduleType[],
  emailIndex: number,
  newDaysAfter: number
): ScheduleType[] {
  const isSchedulingLater = newDaysAfter > schedules[emailIndex].daysAfter

  if (isSchedulingLater) {
    let currentLatestDaysAfter = schedules[0].daysAfter

    return schedules.map((email, i) => {
      const daysAfter =
        i < emailIndex
          ? email.daysAfter
          : i === emailIndex
          ? newDaysAfter
          : email.daysAfter <= currentLatestDaysAfter
          ? currentLatestDaysAfter + 1
          : email.daysAfter

      currentLatestDaysAfter = daysAfter

      return {
        ...email,
        daysAfter,
      }
    })
  }

  const reversedEmailIndex = schedules.length - 1 - emailIndex
  let currentEarliestDaysAfter =
    emailIndex === schedules.length - 1
      ? newDaysAfter
      : schedules[schedules.length - 1].daysAfter

  return schedules
    .reverse()
    .map((email, i) => {
      const daysAfter =
        i < reversedEmailIndex
          ? email.daysAfter
          : i === reversedEmailIndex
          ? newDaysAfter
          : email.daysAfter >= currentEarliestDaysAfter
          ? currentEarliestDaysAfter - 1
          : email.daysAfter

      currentEarliestDaysAfter = daysAfter

      return {
        ...email,
        daysAfter,
      }
    })
    .reverse()
}
