import { LabelProps } from '@trinsly/common/src/components/atoms/label'
import { SelectProps } from '@trinsly/common/src/components/molecules/select'
import SelectInput from '@trinsly/common/src/components/molecules/select-input'
import React from 'react'

export type TimeZoneSelectProps = {
  label: LabelProps['children']
  error?: string
  required?: boolean
} & Omit<SelectProps, 'itemRender' | 'options' | 'pending'>

const TimeZoneSelect = (props: TimeZoneSelectProps) => {
  return <SelectInput {...props} options={timeZones} clearable={false} />
}

export default TimeZoneSelect

export const timeZones = [
  'Eastern Time (US \u0026 Canada)',
  'Pacific Time (US \u0026 Canada)',
  'Central Time (US \u0026 Canada)',
  'Mountain Time (US \u0026 Canada)',
  'America/Adak',
  'Hawaii',
  'Alaska',
  'America/Anchorage',
  'America/Metlakatla',
  'America/Nome',
  'America/Sitka',
  'America/Yakutat',
  'America/Boise',
  'Arizona',
  'America/Indiana/Knox',
  'America/Indiana/Tell_City',
  'America/Menominee',
  'America/North_Dakota/Beulah',
  'America/North_Dakota/Center',
  'America/North_Dakota/New_Salem',
  'America/Detroit',
  'America/Indiana/Marengo',
  'America/Indiana/Petersburg',
  'America/Indiana/Vevay',
  'America/Indiana/Vincennes',
  'America/Indiana/Winamac',
  'America/Kentucky/Louisville',
  'America/Kentucky/Monticello',
  'Indiana (East)',
  'American Samoa',
  'International Date Line West',
  'Midway Island',
  'Tijuana',
  'Chihuahua',
  'Mazatlan',
  'Central America',
  'Guadalajara',
  'Mexico City',
  'Monterrey',
  'Saskatchewan',
  'Bogota',
  'Lima',
  'Quito',
  'Atlantic Time (Canada)',
  'Caracas',
  'Georgetown',
  'La Paz',
  'Santiago',
  'Newfoundland',
  'Brasilia',
  'Buenos Aires',
  'Greenland',
  'Montevideo',
  'Mid-Atlantic',
  'Azores',
  'Cape Verde Is.',
  'Casablanca',
  'Dublin',
  'Edinburgh',
  'Lisbon',
  'London',
  'Monrovia',
  'UTC',
  'Amsterdam',
  'Belgrade',
  'Berlin',
  'Bern',
  'Bratislava',
  'Brussels',
  'Budapest',
  'Copenhagen',
  'Ljubljana',
  'Madrid',
  'Paris',
  'Prague',
  'Rome',
  'Sarajevo',
  'Skopje',
  'Stockholm',
  'Vienna',
  'Warsaw',
  'West Central Africa',
  'Zagreb',
  'Zurich',
  'Athens',
  'Bucharest',
  'Cairo',
  'Harare',
  'Helsinki',
  'Jerusalem',
  'Kaliningrad',
  'Kyiv',
  'Pretoria',
  'Riga',
  'Sofia',
  'Tallinn',
  'Vilnius',
  'Baghdad',
  'Istanbul',
  'Kuwait',
  'Minsk',
  'Moscow',
  'Nairobi',
  'Riyadh',
  'St. Petersburg',
  'Volgograd',
  'Tehran',
  'Abu Dhabi',
  'Baku',
  'Muscat',
  'Samara',
  'Tbilisi',
  'Yerevan',
  'Kabul',
  'Ekaterinburg',
  'Islamabad',
  'Karachi',
  'Tashkent',
  'Chennai',
  'Kolkata',
  'Mumbai',
  'New Delhi',
  'Sri Jayawardenepura',
  'Kathmandu',
  'Almaty',
  'Astana',
  'Dhaka',
  'Urumqi',
  'Rangoon',
  'Bangkok',
  'Hanoi',
  'Jakarta',
  'Krasnoyarsk',
  'Novosibirsk',
  'Beijing',
  'Chongqing',
  'Hong Kong',
  'Irkutsk',
  'Kuala Lumpur',
  'Perth',
  'Singapore',
  'Taipei',
  'Ulaanbaatar',
  'Osaka',
  'Sapporo',
  'Seoul',
  'Tokyo',
  'Yakutsk',
  'Adelaide',
  'Darwin',
  'Brisbane',
  'Canberra',
  'Guam',
  'Hobart',
  'Melbourne',
  'Port Moresby',
  'Sydney',
  'Vladivostok',
  'Magadan',
  'New Caledonia',
  'Solomon Is.',
  'Srednekolymsk',
  'Auckland',
  'Fiji',
  'Kamchatka',
  'Marshall Is.',
  'Wellington',
  'Chatham Is.',
  "Nuku'alofa",
  'Samoa',
  'Tokelau Is.',
]
