import Card from '@trinsly/common/src/components/atoms/card'
import Column from '@trinsly/common/src/components/atoms/column'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Label from '@trinsly/common/src/components/atoms/label'
import ProgressBar from '@trinsly/common/src/components/atoms/progress-bar'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Table from '@trinsly/common/src/components/atoms/table'
import Text from '@trinsly/common/src/components/atoms/text'
import Select from '@trinsly/common/src/components/molecules/select'
import useGetFindCreditUsage from 'api-hooks/use-get-find-credit-usage'
import useGetOrganization from 'api-hooks/use-get-organization'
import useGetUserSettings from 'api-hooks/use-get-user-settings'
import moment from 'moment'
import { useAuth } from 'providers/auth-provider'
import { useMemo, useState } from 'react'
import { UserCreditUsage } from 'types/billing-type'

/**
 * TODO: set dynamically based on plan value/trial values
 */
const ALLOWED = 300

function CreditUsage() {
  const [billingCycle, setBillingCycle] = useState(moment().format('YYYY-MM'))
  const { data: usage, pending: pendingUsage } = useGetFindCreditUsage({
    billingCycle: billingCycle,
  })
  const { user } = useAuth()
  const { data: organization, pending: pendingOrganization } =
    useGetOrganization({ organizationId: user?.organizationId ?? 0 })

  //const { data: settings, pending: settingsPending } = useGetUserSettings()
  const allowedCreditsForOrg = useMemo(() => {
    const members =
      organization?.memberships.filter((m) => !m.disabledAt).length ?? 1
    return ALLOWED * members
  }, [ALLOWED, organization?.memberships])

  const usageByMember = useMemo(() => {
    debugger
    return organization?.memberships
      .filter((m) => !m.disabledAt)
      .map((m) => {
        const hasUsage = usage?.users.find((u) => u.user === m.userId)
        return {
          user: m.userId,
          emailFindingCount: !!hasUsage?.emailFindingCount
            ? hasUsage?.emailFindingCount
            : 0,
          emailValidationCount: hasUsage?.emailValidationCount ?? 0,
          billingCycle: billingCycle,
        } as UserCreditUsage
      })
  }, [
    organization?.memberships,
    usage,
    pendingUsage,
    pendingOrganization,
    billingCycle,
  ])

  const periodRange = useMemo(() => {
    const start = moment('2022-09-01') //when we started counting
    const end = moment()
    let timeValues = []

    while (end > start || start.format('M') === end.format('M')) {
      timeValues.push(start.format('YYYY-MM'))
      start.add(1, 'month')
    }
    return timeValues
  }, [])

  return (
    <Column className="p-6 w-full">
      <Heading size="xl" fontWeight="semibold">
        Contact Find Credit Usage
      </Heading>
      <Text size="xs">
        To learn more about contact credits check out our{' '}
        <a href="https://docs.chatkick.com/en/articles/6763239-how-do-contact-finding-credits-work">
          <Text size="xs" color="primary-700" className="underline">
            help article
          </Text>
        </a>
        .
      </Text>
      <Row className="justify-between p-6">
        <Column>
          <Heading fontWeight="semibold">
            Total Used: {usage?.emailFindingCount || 0} / {allowedCreditsForOrg}
          </Heading>
          <Separator size="xs" />
          <ProgressBar
            value={usage?.emailFindingCount || 0}
            total={allowedCreditsForOrg}
            intent="success"
          />
        </Column>

        <Row className="text-center">
          <Heading className="text-center mt-2" fontWeight="semibold">
            Billing Period:{' '}
          </Heading>
          <Separator size="sm" />
          <Column className="min-w-48">
            <Select
              placeholder="Billing Period"
              clearable={false}
              creatable={false}
              onChange={(value) => {
                setBillingCycle(value!)
              }}
              value={billingCycle}
              itemRenderer={(value, selected) => {
                return <Text>{value}</Text>
              }}
              options={[...periodRange]}
            />
          </Column>
        </Row>
      </Row>
      <Card className="shadow-md">
        <Separator />
        <Table
          pending={pendingUsage || pendingOrganization}
          data={usageByMember || []}
          columns={[
            {
              header: 'Team Member',
              cell: (item) => {
                const member = organization?.memberships.find(
                  (m) => m.userId === item?.user
                )
                return <Text>{member?.userEmail}</Text>
              },
            },
            {
              header: 'Credits Used',
              width: 160,
              cell: (item) => item.emailFindingCount,
            },
            {
              header: 'Allotted',
              width: 140,
              cell: () => ALLOWED,
            },
            {
              header: 'Percentage Used',
              width: 220,
              cell: (item) => {
                return (
                  <>
                    <Label color="primary-700">
                      {Number((item.emailFindingCount / ALLOWED) * 100).toFixed(
                        2
                      )}
                      % used
                    </Label>
                    <ProgressBar
                      value={item.emailFindingCount}
                      total={ALLOWED}
                      intent="success"
                    />
                  </>
                )
              },
            },
          ]}
        ></Table>{' '}
      </Card>
    </Column>
  )
}

export default CreditUsage
