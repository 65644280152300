/**
 * Utilizes Trinsly Integration Service
 */

import { retrieveCredentials } from '@trinsly/common/src/services/api'
import axios from 'axios'
import { camelizeKeys } from 'humps'
import CurrentStripeSubscription from 'types/current-stripe-subscription-type'

async function getCurrentSubscriptionsService() {
  const { accessToken } = retrieveCredentials()
  const response = await axios.request({
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${process.env.REACT_APP_MERGE_API_URL}/billing/current-subscriptions`,
  })

  return camelizeKeys(response?.data) as any as CurrentStripeSubscription[]
}

export default getCurrentSubscriptionsService

const sample = [
  {
    id: 'sub_1K5xeK2er0bMS9OxEX3QpirJ',
    currentPeriodStart: 1657653836,
    currentPeriodEnd: 1660332236,
    billingAnchor: 1639337036,
    defaultPayment: null,
    items: [
      {
        id: 'si_KlUdOzQ5BU2Tlm',
        price: {
          id: 'v5-starter-monthly',
          object: 'price',
          active: true,
          billing_scheme: 'per_unit',
          created: 1632759927,
          currency: 'usd',
          custom_unit_amount: null,
          livemode: false,
          lookup_key: null,
          metadata: {},
          nickname: null,
          product: 'prod_KIybRErC0iGfWq',
          recurring: {
            aggregate_usage: null,
            interval: 'month',
            interval_count: 1,
            trial_period_days: null,
            usage_type: 'licensed',
          },
          tax_behavior: 'unspecified',
          tiers_mode: null,
          transform_quantity: null,
          type: 'recurring',
          unit_amount: 9900,
          unit_amount_decimal: '9900',
        },
        quantity: 1,
        plan: {
          id: 'v5-starter-monthly',
          object: 'plan',
          active: true,
          aggregate_usage: null,
          amount: 9900,
          amount_decimal: '9900',
          billing_scheme: 'per_unit',
          created: 1632759927,
          currency: 'usd',
          interval: 'month',
          interval_count: 1,
          livemode: false,
          metadata: {},
          nickname: null,
          product: 'prod_KIybRErC0iGfWq',
          tiers_mode: null,
          transform_usage: null,
          trial_period_days: null,
          usage_type: 'licensed',
        },
      },
      {
        id: 'si_KlUd5Aw58tWwul',
        price: {
          id: 'metered-email-lookups',
          object: 'price',
          active: true,
          billing_scheme: 'per_unit',
          created: 1631872618,
          currency: 'usd',
          custom_unit_amount: null,
          livemode: false,
          lookup_key: null,
          metadata: {},
          nickname: null,
          product: 'prod_KF84s43CiexjQu',
          recurring: {
            aggregate_usage: 'sum',
            interval: 'month',
            interval_count: 1,
            trial_period_days: null,
            usage_type: 'metered',
          },
          tax_behavior: 'unspecified',
          tiers_mode: null,
          transform_quantity: null,
          type: 'recurring',
          unit_amount: 20,
          unit_amount_decimal: '20',
        },
        plan: {
          id: 'metered-email-lookups',
          object: 'plan',
          active: true,
          aggregate_usage: 'sum',
          amount: 20,
          amount_decimal: '20',
          billing_scheme: 'per_unit',
          created: 1631872618,
          currency: 'usd',
          interval: 'month',
          interval_count: 1,
          livemode: false,
          metadata: {},
          nickname: null,
          product: 'prod_KF84s43CiexjQu',
          tiers_mode: null,
          transform_usage: null,
          trial_period_days: null,
          usage_type: 'metered',
        },
      },
    ],
  },
]
