import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation, useQueryClient } from 'react-query'
import deleteCampaignFromProjectService from 'services/delete-campaign-from-project-service'

export type UseDeleteCampaignFromProjectProps = {} & MutateApiHookType<void>

function useDeleteCampaignFromProject({
  onMutate,
  onSuccess,
  onError,
}: UseDeleteCampaignFromProjectProps) {
  const queryClient = useQueryClient()

  const { mutate: fetch, isLoading: pending } = useMutation(
    deleteCampaignFromProjectService,
    {
      onSuccess: async (...args) => {
        queryClient.invalidateQueries('/projects/{id}')
        queryClient.invalidateQueries('/projects/{id}/reports/stats')
        queryClient.invalidateQueries('/campaigns')
        onSuccess?.(...args)
      },
      onError,
      onMutate,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useDeleteCampaignFromProject
