import api from '@trinsly/common/src/services/api'
import DraftType from 'types/draft-type'

type SaveCampaignDraftServiceProps = {
  draft: Partial<DraftType>
}

async function SaveCampaignDraftService({
  draft,
}: SaveCampaignDraftServiceProps) {
  const hasId = Boolean(draft?.id)
  const response = (await api(hasId ? `/drafts/${draft?.id}` : '/drafts', {
    method: hasId ? 'PUT' : 'POST',
    body: {
      draft: {
        resource_type: 'campaign',
        payload: draft?.payload,
      },
    },
  })) as DraftType
  return response
}

export default SaveCampaignDraftService
