import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation, useQueryClient } from 'react-query'
import { destroyUserSessionService } from 'services/auth/destroy-user-session-service'

export type UseDeleteUserSessionProps = {} & MutateApiHookType<void>

function useDeleteUserSession({
  onSuccess,
  onError,
}: UseDeleteUserSessionProps) {
  const queryClient = useQueryClient()
  const { mutate: fetch, isLoading: pending } = useMutation(
    () => destroyUserSessionService(),
    {
      onSuccess,
      onError,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useDeleteUserSession
