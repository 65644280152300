import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation } from 'react-query'
import createAccountDeletionRequestService from 'services/create-account-deletion-request'
import useGetUserSession from './use-get-user-session'

export type UseRequestAccountDeletionProps = {} & MutateApiHookType<void>

function useRequestAccountDeletion({
  onSuccess,
  onError,
}: UseRequestAccountDeletionProps) {
  const { refetch } = useGetUserSession({})
  const { mutate: fetch, isLoading: pending } = useMutation(
    createAccountDeletionRequestService,
    {
      onSuccess: async () => {
        await refetch()
        onSuccess?.()
      },
      onError,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useRequestAccountDeletion
