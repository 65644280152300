import { ReactComponent as CloseSvg } from '@trinsly/common/src/assets/icons/close.svg'
import Card from '@trinsly/common/src/components/atoms/card'
import Column from '@trinsly/common/src/components/atoms/column'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Spinner from '@trinsly/common/src/components/atoms/spinner'
import Table from '@trinsly/common/src/components/atoms/table'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import useGetIntegration from 'api-hooks/use-get-integration'
import useUpdateSyncedUsersByIntegration from 'api-hooks/use-update-synced-users-integration'
import NotFound404 from 'components/not-found-404'
import PrivateLayout from 'components/private-layout'
import { startCase } from 'lodash'
import React, { useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import CrmEnum from 'types/crm-enum'
import { convertAtsResults, matchToUser } from './ats-ops'
import SyncUserSelect from './sync-user-select'

function SyncUsersIntegrationScreen() {
  const [values, setValues] = useState<Record<Id, any>>({})

  const match = useRouteMatch()
  const { integrationId } = match.params as any

  const {
    data: integration,
    users,
    ats: atsResults,
    pending,
  } = useGetIntegration({
    integrationId,
  })

  React.useEffect(() => {
    if (!pending) {
      const values = users.reduce(
        (acc: Record<Id, string | undefined>, user: any) => {
          const convertedAts =
            convertAtsResults?.[integration.crmType as CrmEnum]?.(atsResults) ||
            []
          const match = matchToUser[integration.crmType as CrmEnum]?.(
            convertedAts,
            user
          )
          return {
            ...acc,
            [user.id]: match,
          }
        },
        {}
      )
      setValues(values)
    }
  }, [pending])

  const { fetch: updateSyncedUsers, pending: pendingUpdateSyncedUsers } =
    useUpdateSyncedUsersByIntegration({
      integrationId,
    })

  function handleChange(value: any, userId: Id) {
    setValues({ ...values, [userId]: value })
  }

  async function handleSubmit() {
    const parsedValues = Object.keys(values).reduce(
      (acc: Record<Id, string>, userId: Id) => {
        acc[userId] = values[userId]?.id || null
        return acc
      },
      {}
    )
    updateSyncedUsers({
      values: parsedValues,
    })
  }

  const hasIntegration = !pending && Boolean(integration?.id)
  const integrationName = startCase(integration?.crmType || '')
  const hasData = !pending && atsResults.length > 0 && users.length > 0

  if (!pending && !integration && !integration?.id) {
    return <NotFound404 />
  }

  return (
    <PrivateLayout>
      <Column className="px-lg py-sm bg-white shadow">
        <Heading size="xl" fontWeight="bold">
          Integration - Sync users from {startCase(integration?.crmType || '')}
        </Heading>
      </Column>
      <Separator />
      <Column className="px-lg">
        <Card>
          {pending ? (
            <Column className="p-md items-start justify-center">
              <Spinner />
            </Column>
          ) : !hasData ? (
            <Column>
              <Text size="sm" color="gray-400">
                Results not found.
              </Text>
            </Column>
          ) : (
            <Table
              data={users}
              pending={pending}
              columns={[
                {
                  width: 400,
                  header: 'Trinsly User',
                  cell: (user: any) => `${user.name} <${user.email}>`,
                },
                {
                  width: 400,
                  header: `${integrationName} User`,
                  cell: (user: any) => (
                    <SyncUserSelect
                      atsResults={atsResults}
                      crmType={integration.crmType}
                      disabled={pendingUpdateSyncedUsers}
                      onChange={handleChange}
                      userId={user.id}
                      value={values[user.id]}
                    />
                  ),
                },
                {
                  width: 80,
                  header: 'Actions',
                  cell: (user: any) => (
                    <Row className="items-center">
                      <Button
                        size="sm"
                        variant="outline"
                        onClick={() => {
                          handleChange(undefined, user.id)
                        }}
                      >
                        <Row className="items-center">
                          <CloseSvg className="w-4 h-4" />
                          <Separator size="xs" />
                          Clear
                        </Row>
                      </Button>
                    </Row>
                  ),
                },
              ]}
            />
          )}
        </Card>
        <Separator />
        <Column className="min-w-32 self-start">
          <Button
            size="sm"
            intent="success"
            pending={pendingUpdateSyncedUsers}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Column>
      </Column>
      <Separator size="xl" />
    </PrivateLayout>
  )
}

export default SyncUsersIntegrationScreen
