import { ReactComponent as CloseSvg } from '@trinsly/common/src/assets/icons/close.svg'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Button from '@trinsly/common/src/components/molecules/button'
import React from 'react'
import CancelAllPausedReachOutsConfirmationModal from './cancel-all-paused-reach-outs-confirmation-modal'

export type CancelAllPausedReachOutsButtonProps = {}

function CancelAllPausedReachOutsButton({}: CancelAllPausedReachOutsButtonProps) {
  const [visible, setVisible] = React.useState(false)

  return (
    <React.Fragment>
      <Button
        size="sm"
        intent="danger"
        onClick={() => {
          setVisible(true)
        }}
      >
        <Row className="items-center whitespace-nowrap">
          <CloseSvg className="w-4 h-4" />
          <Separator size="xs" />
          Cancel All
        </Row>
      </Button>
      <CancelAllPausedReachOutsConfirmationModal
        visible={visible}
        onRequestClose={() => setVisible(false)}
      />
    </React.Fragment>
  )
}

export default CancelAllPausedReachOutsButton
