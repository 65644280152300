import slugify from '../../utils/slugify'
import Column from '../atoms/column'
import Label, { LabelProps } from '../atoms/label'
import Separator from '../atoms/separator'
import Select, { SelectProps } from './select'

export type SelectInputProps<T> = {
  label: LabelProps['children']
  error?: string
  required?: boolean
} & SelectProps<T>

function SelectInput<T>({
  label,
  id = slugify(label, { suffix: 'select' }),
  required = false,
  error,
  ...selectProps
}: SelectInputProps<T>) {
  return (
    <Column>
      <Label htmlFor={id} color={error ? 'danger-500' : undefined}>
        {label}
        {required ? ' *' : undefined}
      </Label>
      <Separator size="xs" />
      <Select {...selectProps} id={id} error={error} />
      <Column style={{ height: 0 }}>
        <Column className="min-h-1" />
        {error && (
          <span className="text-danger-500" style={{ fontSize: '0.65rem' }}>
            {error}
          </span>
        )}
      </Column>
    </Column>
  )
}

export default SelectInput
