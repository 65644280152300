//@ts-ignore
import Attachments from '@trinsly/attachments'
import api from '@trinsly/common/src/services/api'
import { pickBy } from 'lodash'

var qs = require('qs')

type AttachmentsInputProps = {
  disabled: boolean
  value: Array<string>
  onChange: (type: string, values: string[]) => void
}

function AttachmentsInput({
  disabled,
  value,
  onChange,
}: AttachmentsInputProps) {
  function handleChange(files: { fileUrl: string }[]) {
    onChange?.('attachments', files?.map((file) => file.fileUrl) || [])
  }

  return (
    <Attachments
      disabled={disabled}
      fileUrls={value}
      onChange={handleChange}
      s3Url={`https://${process.env.REACT_APP_S3_BUCKET_NAME}.s3.amazonaws.com`}
      uploadOptions={{ getSignedUrl }}
      containerClassName="flex flex-row flex-wrap gap-sm"
    />
  )
}

export default AttachmentsInput

const getSignedUrl = async (file: any, callback: any) => {
  const searchParams = qs.stringify(
    pickBy({
      content_type: file.type,
      object_name: file.name,
    }) as any
  )

  const response = await api(
    `${process.env.REACT_APP_API_URL}/s3/sign?${searchParams}`,
    { method: 'GET' }
  )
  callback?.(response)
}
