import React from 'react'
import Provider, { useContext } from './provider'

export type TabsProps = {
  id: string
  onChange?: (buttonId: string) => void
  children: React.ReactNode | React.ReactNodeArray
  defaultTab?: string
  className?: string
  variant?: 'query-string' | 'hash-anchor' | 'state'
}

function Tabs({
  children,
  className = '',
  variant = 'query-string',
  ...props
}: TabsProps) {
  return (
    <div className={`${containerClasses} ${className}`}>
      <Provider {...props} variant={variant}>
        {children}
      </Provider>
    </div>
  )
}

export default Tabs

export type TabProps = {
  id: string
  children: React.ReactNode | React.ReactNodeArray
  disabled?: boolean
}

function Tab({ id, children, disabled = false }: TabProps) {
  const { id: containerId, onChange, getActiveTab } = useContext()
  const activeTab = getActiveTab()
  const active = activeTab === id
  return (
    <button
      id={`${containerId}-${id}`}
      className={`${tabClasses} ${
        disabled
          ? tabDisabledClasses
          : active
          ? tabActiveClasses
          : tabDefaultClasses
      }`}
      onClick={() => onChange?.(id)}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

Tabs.Tab = Tab
Tab.displayName = 'Tabs.Tab'

const containerClasses = `inline-flex flex-row bg-primary-50 bg-opacity-30`
const tabClasses = `min-h-16 min-w-24 px-lg text-sm uppercase font-bold border-b-2 ring-inset focus:ring focus:ring-primary-200 transition focus:outline-none`
const tabDefaultClasses = `text-gray-400 hover:bg-primary-50 focus:bg-primary-50 active:bg-primary-100 border-transparent`
const tabActiveClasses = `text-primary-500 border-b-2 border-primary-500 cursor-default`
const tabDisabledClasses = `text-gray-300 border-transparent cursor-not-allowed`
