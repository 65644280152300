import Column from '@trinsly/common/src/components/atoms/column'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import SelectCampaign from 'components/select-campaign'
import { compact, uniq } from 'lodash'
import ScheduleType from 'screens/create-batch-screen/schedule-section/schedule-type'
import { fillEmailVariablesWithProspectData } from 'types/email-template-type'
import ProspectType from 'types/prospect-type'
import ScheduleTimeSelectEnum from 'types/schedule-time-select-enum'

type CampaignStepProps = {
  prospect: ProspectType | undefined
  onNext: () => void
  getValue: (path: string) => any
  getError: (path: string) => any
  setValue: (path: string, value: any) => void
}

function CampaignStep({
  prospect,
  onNext,
  getValue,
  getError,
  setValue,
}: CampaignStepProps) {
  return (
    <Column className="gap-md pt-sm">
      <Column>
        <Text size="xs" fontWeight="bold" className="uppercase">
          Email (current stage)
        </Text>
        <Text size="sm">{prospect?.email || '--'}</Text>
      </Column>
      <SelectCampaign
        placeholder="Select Campaign..."
        value={getValue('campaign')}
        onChange={(value) => {
          setValue('campaign', value)

          //variables
          const templateValuesAsString = value?.templates?.reduce?.(
            (acc, template) => `${acc} ${template?.subject} ${template?.body}`,
            ''
          )
          const result = templateValuesAsString?.match(/{{\w+}}/gm)
          const variables = uniq(compact([...(result || [])]))
          const emailsFilled = fillEmailVariablesWithProspectData({
            prospect,
            emails: value?.templates,
            variables: variables.map((variable) =>
              variable?.replace('{{', '').replace('}}', '')
            ),
          })
          setValue('custom.emails', emailsFilled)

          //schedules
          const newSchedules = value?.templates?.map?.((template, index) => {
            return {
              daysAfter: Number(template?.duration || index),
              time: ScheduleTimeSelectEnum.UNMODIFIED_VALUE,
            }
          }) as ScheduleType[]
          setValue('schedules', newSchedules)
        }}
      />
      <Column className="items-end">
        <Column className="min-w-24">
          <Button
            intent="primary"
            onClick={onNext}
            disabled={!getValue('campaign.id')}
          >
            Next
          </Button>
        </Column>
      </Column>
    </Column>
  )
}

export default CampaignStep
