import Card from '@trinsly/common/src/components/atoms/card'
import Column from '@trinsly/common/src/components/atoms/column'
import Form from '@trinsly/common/src/components/atoms/form'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Button from '@trinsly/common/src/components/molecules/button'
import Modal from '@trinsly/common/src/components/molecules/modal'
import TextInput from '@trinsly/common/src/components/molecules/text-input'
import useForm from '@trinsly/common/src/hooks/use-form'
import required from '@trinsly/common/src/validations/required'
import minLength from '@trinsly/common/src/validations/min-length'
import validEmail from '@trinsly/common/src/validations/valid-email'
import useCreateProspect from 'api-hooks/use-create-prospect'
import React from 'react'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import EducationsSection from './educations-section'
import EmploymentsSection from './employments-section'

type CreateProspectFormModalProps = {
  visible: boolean
  onRequestClose: () => void
}

function CreateProspectFormModal({
  visible,
  onRequestClose,
}: CreateProspectFormModalProps) {
  const history = useHistory()
  const { fetch: createProspect, pending: pendingCreateProspect } =
    useCreateProspect({
      onSuccess: (data) => {
        toast.success('Prospect created!')
        onRequestClose?.()
        history.push(`/prospects/${data?.id}`)
      },
      onError: (error) => {
        const errorAsString = JSON.stringify(error || {})
        const errorMessage = errorAsString.includes('email already taken')
          ? 'Email already taken'
          : 'Error creating prospect'
        toast.error(errorMessage)
      },
    })

  const { getValue, setValue, getError, submit, reset, isDirty } = useForm({
    validations: {
      email: [validEmail],
      firstName: [(value: string) => minLength(value, 1)],
    },
    onSubmit: (values) => {
      createProspect({
        prospect: {
          email: values?.email,
          firstName: values?.firstName,
          lastName: values?.lastName,
          linkedin: values?.linkedin,
          city: values?.city,
          state: values?.state,
          zip: values?.zip,
        },
        educations: values?.educations,
        employments: values?.employments,
      })
    },
  })

  React.useEffect(() => {
    if (visible) {
      reset()
    }
  }, [visible])

  return (
    <Modal visible={visible} onRequestClose={onRequestClose}>
      <Form onSubmit={submit}>
        <Card>
          <Column className="p-md">
            <Column className="gap-md">
              <Heading size="xl" fontWeight="bold">
                Add a Prospect
              </Heading>
              <Row className="gap-sm">
                <TextInput
                  label="First Name"
                  value={getValue('firstName')}
                  onChange={(value) => setValue('firstName', value)}
                  error={getError('firstName')}
                />
                <TextInput
                  label="Last Name"
                  value={getValue('lastName')}
                  onChange={(value) => setValue('lastName', value)}
                  error={getError('lastName')}
                />
              </Row>
              <Row className="gap-sm">
                <TextInput
                  type="email"
                  required
                  label="Email"
                  value={getValue('email')}
                  onChange={(value) => setValue('email', value)}
                  error={getError('email')}
                />
                <TextInput
                  label="Linkedin name"
                  value={getValue('linkedinName')}
                  onChange={(value) => setValue('linkedinName', value)}
                  error={getError('linkedinName')}
                />
              </Row>
              <Row className="gap-sm">
                <TextInput
                  label="City"
                  value={getValue('city')}
                  onChange={(value) => setValue('city', value)}
                  error={getError('city')}
                />
                <TextInput
                  label="State"
                  value={getValue('state')}
                  onChange={(value) => setValue('state', value)}
                  error={getError('state')}
                />
                <TextInput
                  label="Zip"
                  value={getValue('zip')}
                  onChange={(value) => setValue('zip', value)}
                  error={getError('zip')}
                />
              </Row>
            </Column>
            <Separator size="xl" />
            <EmploymentsSection
              getError={getError}
              getValue={getValue}
              setValue={setValue}
            />
            <Separator size="xl" />
            <EducationsSection
              getError={getError}
              getValue={getValue}
              setValue={setValue}
            />
          </Column>
          <Row className="items-center justify-end py-sm px-md bg-gray-100">
            <Column className="min-w-28">
              <Button variant="outline" onClick={onRequestClose}>
                Cancel
              </Button>
            </Column>
            <Separator size="xs" />
            <Column className="min-w-36">
              <Button
                type="submit"
                intent="success"
                disabled={!isDirty}
                pending={pendingCreateProspect}
              >
                Create
              </Button>
            </Column>
          </Row>
        </Card>
      </Form>
    </Modal>
  )
}

export default CreateProspectFormModal
