import { ReactComponent as CollapseSvg } from '@trinsly/common/src/assets/icons/collapse.svg'
import { ReactComponent as ExpandSvg } from '@trinsly/common/src/assets/icons/expand.svg'
import Column from '@trinsly/common/src/components/atoms/column'
import Divider from '@trinsly/common/src/components/atoms/divider'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Text from '@trinsly/common/src/components/atoms/text'
import Expandable from '@trinsly/common/src/components/molecules/expandable'
import IconButton from '@trinsly/common/src/components/molecules/icon-button'
import EmailTemplateInput from '@trinsly/common/src/components/molecules/quill-editor/email-template-input'
import AttachmentsInput from 'components/attachments-input'
import React from 'react'
import { bumpSubsequentDaysAfterIfNeeded } from 'screens/create-batch-screen/schedule-section'
import DatePicker from 'screens/create-batch-screen/schedule-section/date-picker'
import ScheduleType from 'screens/create-batch-screen/schedule-section/schedule-type'
import TimeSelect from 'screens/create-batch-screen/schedule-section/time-select'

type EmailListItemProps = {
  index: number
  getValue: (path: string) => any
  getError: (path: string) => any
  setValue: (path: string, value: any) => void
}

function EmailListItem({
  index,
  getValue,
  setValue,
  getError,
}: EmailListItemProps) {
  const [expanded, setExpanded] = React.useState(false)
  const path = `custom.emails[${index}]`
  const schedule = getValue(`schedules[${index}]`) as ScheduleType | undefined
  const minDaysAfter = index === 0 ? -1 : index - 1

  return (
    <Column className="bg-gray-100 rounded-lg">
      <Row className="flex-wrap gap-md items-center p-sm">
        <Column className="flex-1">
          <Text size="xs" fontWeight="bold" className="uppercase">
            Stage #{getValue(`${path}.position`) || '#'}
          </Text>
          <Text size="sm">{getValue(`${path}.subject`) || '--'}</Text>
        </Column>
        <Column>
          <DatePicker
            daysAfter={schedule?.daysAfter as any}
            minDaysAfter={minDaysAfter}
            onChange={(value) => {
              const newSchedulesAfterBump = bumpSubsequentDaysAfterIfNeeded(
                getValue('schedules') || [],
                index,
                value
              )
              setValue('schedules', newSchedulesAfterBump)
            }}
          />
        </Column>
        <Column className="min-w-56">
          <TimeSelect
            time={schedule?.time as any}
            daysAfter={schedule?.daysAfter as any}
            onChange={(value) => setValue(`schedules[${index}].time`, value)}
          />
        </Column>
        <IconButton variant="ghost" onClick={() => setExpanded(!expanded)}>
          {expanded ? <CollapseSvg /> : <ExpandSvg />}
        </IconButton>
      </Row>
      <Expandable expanded={expanded} maxHeight="40rem">
        <Column className="p-sm pt-1">
          <EmailTemplateInput
            subject={{
              value: getValue(`${path}.subject`),
              error: getError(`${path}.subject`),
              onChange: (value) => {
                setValue(`${path}.subject`, value)
              },
            }}
            body={{
              value: getValue(`${path}.body`),
              error: getError(`${path}.body`),
              onChange: ({ html }) => {
                setValue(`${path}.body`, html)
              },
              modules: [],
            }}
          />
          <Separator />
          <Divider />
          <Separator />
          <Column>
            <Text
              size="sm"
              fontWeight="bold"
              className="uppercase"
              color="gray-900"
            >
              Attachments
            </Text>
            <AttachmentsInput
              disabled={false}
              value={getValue(`${path}.attachments`) || []}
              onChange={(values) => {
                setValue(`${path}.attachments`, values)
              }}
            />
          </Column>
        </Column>
      </Expandable>
    </Column>
  )
}

export default EmailListItem
