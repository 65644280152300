//@ts-ignore
import ImageResize from 'quill-image-resize-module-react'
import { Quill } from 'react-quill'

Quill.register('modules/imageResize', ImageResize)
//@ts-ignore
window.Quill = Quill

const imageResizeModule = {
  imageResize: {
    parchment: Quill.import('parchment'),
    modules: ['Resize', 'DisplaySize'],
  },
}

export default imageResizeModule
