import api from '@trinsly/common/src/services/api'
import ProjectStatusEnum from 'types/project-status-enum'
import ProjectType from 'types/project-type'

type ArchiveProjectServiceProps = {
  project: ProjectType
}

async function archiveProjectService({ project }: ArchiveProjectServiceProps) {
  const response = await api(`/projects/${project.id}`, {
    method: 'PUT',
    body: {
      ...project,
      status: ProjectStatusEnum.ARCHIVED,
    },
  })
  return response as ProjectType
}

export default archiveProjectService
