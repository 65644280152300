import ThreadStateEnum from '@trinsly/common/src/types/thread-state-enum-type'
import { ResponseType } from './prospect-history-type'

export interface ThreadType {
  campaignId: number
  campaignName: string
  cc?: any
  email: string
  emails: ThreadEmailType[]
  tasks: any[]
  id: number
  lastDeliveredAt: Date
  lastOpenedAt?: any
  linkedinMemberId?: any
  linkedinName?: any
  linkedinTalentHash?: any
  openCount: number
  openLinkCount: number
  prospectName: string
  prospectId: number
  responseType: ResponseType
  responseVia?: any
  sourceUrl: string
  state: ThreadStateEnum
  userId: number
  userName: string
}

export type ThreadEmailType = {
  id: number
  emailThreadId: number
  templateId: number
  emailMessageTemplateId: number
  userId: number
  state: ThreadEmailStateEnum
  position: number
  subject: string
  body: string
  attachments: any[]
  modified: boolean
  deliverAt: string
  openCount: number
  openLinkCount: number
}

export default ThreadType

export enum ThreadEmailStateEnum {
  SCHEDULED = 'scheduled',
  CANCELED = 'canceled',
  DELIVERED = 'delivered',
  RESPONDED = 'responded',
  HARD_BOUNCED = 'hard_bounced',
  PREVENTED = 'prevented',
  PAUSED = 'paused',
  UNDONE = 'undone',
  PAUSED_BY_AUTH_LOSS = 'paused_by_auth_loss',
  DELIVERING = 'delivering',
  PENDING = 'pending',
}

//States from API
// scheduled: 0,
// canceled: 1,
// delivered: 2,
// responded: 3,
// hard_bounced: 4,
// prevented: 5,
// paused: 6,
// undone: 7,
// paused_by_auth_loss: 8,
// delivering: 9,
// pending: 10
