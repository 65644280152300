import { ReactComponent as PlusSvg } from '@trinsly/common/src/assets/icons/plus.svg'
import Card from '@trinsly/common/src/components/atoms/card'
import Column from '@trinsly/common/src/components/atoms/column'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Table from '@trinsly/common/src/components/atoms/table'
import Button from '@trinsly/common/src/components/molecules/button'
import Pagination from '@trinsly/common/src/components/molecules/pagination'
import SearchInput from '@trinsly/common/src/components/molecules/search-input'
import NonIdealState from '@trinsly/common/src/components/organisms/non-ideal-state'
import usePageable from '@trinsly/common/src/hooks/use-pageable'
import useGetBatches from 'api-hooks/use-get-batches'
import LocalizedDate from 'components/localized-date'
import PrivateLayout from 'components/private-layout'
import React from 'react'
import { useHistory } from 'react-router'
import { BatchStatusEnum } from 'types/batch-type'
import CancelBatchButton from './cancel-batch-button'
import SeeDraftsButton from './drafts-section/see-drafts-button'
import StatusCell from './status-cell'

type BatchesScreenProps = {}

function BatchesScreen({}: BatchesScreenProps) {
  const history = useHistory()
  const { pageable, setSearch, setSort, nextPage, prevPage } = usePageable({
    page: 1,
    perPage: 10,
    sort: { created_at: 'DESC' },
  })

  const {
    data: batches,
    pending: pendingBatches,
    total,
  } = useGetBatches(pageable)

  const isNonIdealState = !pendingBatches && !batches?.length

  return (
    <PrivateLayout>
      <Column className="py-md px-lg bg-white shadow">
        <Heading size="xl" fontWeight="bold">
          Batches
        </Heading>
        <Separator />
        <Row className="items-center justify-between">
          <Column className="min-w-64">
            <SearchInput
              placeholder="Search for Batches..."
              value={pageable?.search}
              onChange={(value) => setSearch(value)}
            />
          </Column>
          <Separator />
          <Row className="items-center gap-md">
            <SeeDraftsButton />
            <Button
              intent="primary"
              shape="rounded"
              onClick={() => history.push('/batches/new')}
            >
              <Row className="items-center">
                <PlusSvg className="w-md h-md" />
                <Separator size="xs" />
                New Batch
              </Row>
            </Button>
          </Row>
        </Row>
      </Column>
      <Separator size="lg" />
      <Column className="px-lg">
        <Card>
          {isNonIdealState ? (
            <Column className="p-md">
              <NonIdealState message="No batches yet" />
            </Column>
          ) : (
            <React.Fragment>
              <Table
                data={batches}
                pending={pendingBatches}
                pageSize={pageable?.perPage}
                columns={[
                  {
                    id: 'name',
                    width: 400,
                    header: 'Name (# of Stages)',
                    cell: (item) =>
                      `${item.name} (${item.campaignTemplatesCount} Stages)`,
                    sort: pageable?.sort?.['name'],
                    onSort: (direction) => setSort('name', direction as any),
                  },
                  {
                    width: 180,
                    header: 'Status',
                    cell: (item) => {
                      return <StatusCell batch={item} />
                    },
                    sort: pageable?.sort?.['status'],
                    onSort: (direction) => setSort('status', direction as any),
                  },
                  {
                    width: 180,
                    header: 'Created',
                    cell: (item) => <LocalizedDate utcDate={item.createdAt} />,
                    sort: pageable?.sort?.['createdAt'],
                    onSort: (direction) =>
                      setSort('createdAt', direction as any),
                  },
                  {
                    id: 'emailThreadsCount',
                    width: 120,
                    header: 'Prospects In Batch (#)',
                    cell: (item) => item.emailThreadsCount,
                  },
                  {
                    header: 'Actions',
                    cell: (item) => {
                      return (
                        <Row className="items-center justify-end">
                          {item.status === BatchStatusEnum.IN_PROGRESS && (
                            <CancelBatchButton batch={item} />
                          )}
                        </Row>
                      )
                    },
                  },
                ]}
              />
              <Row className="items-center py-sm px-md justify-end">
                <Pagination
                  total={total}
                  page={pageable?.page}
                  perPage={pageable?.perPage}
                  onNext={nextPage}
                  onPrev={prevPage}
                />
              </Row>
            </React.Fragment>
          )}
        </Card>
        <Separator size="xl" />
        <Separator size="xl" />
      </Column>
    </PrivateLayout>
  )
}

export default BatchesScreen
