import { ReactComponent as CheckSvg } from 'icons/check.svg'
import React from 'react'

import Column from '@trinsly/common/src/components/atoms/column'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Spinner from '@trinsly/common/src/components/atoms/spinner'
import Text from '@trinsly/common/src/components/atoms/text'
import useQueryParams from '@trinsly/common/src/hooks/use-query-params'
import useUnsubscribe from 'api-hooks/use-unsubscribe'
import PublicLayout from 'components/public-layout'
import { Redirect } from 'react-router-dom'
import { toast } from 'react-toastify'

function UnsubscribeScreen() {
  const { params } = useQueryParams()
  const emailThreadToken = params?.['ett']

  const { fetch: unsubscribe, pending: pendingUnsubscribe } = useUnsubscribe({
    onSuccess: () => {},
    onError: (error) => {
      console.log('Error unsubscribing', error)
      toast.error('Error unsubscribing')
    },
  })

  React.useEffect(() => {
    unsubscribe({ emailThreadToken })
  }, [emailThreadToken])

  if (!emailThreadToken) {
    return <Redirect to="/" />
  }

  return (
    <PublicLayout>
      <Column>
        {pendingUnsubscribe ? (
          <Column className="justify-center items-center gap-md p-xl">
            <Heading size="2xl" fontWeight="bold">
              Unsubscribing... Sorry to see you go!
            </Heading>
            <Spinner size="xl" />
          </Column>
        ) : (
          <Column className="justify-center items-center gap-md p-xl">
            <Heading size="2xl" fontWeight="bold">
              Sorry to see you go!
            </Heading>
            <Column className="min-w-96 items-center justify-center gap-sm">
              <Column className="p-sm rounded-full bg-success-100">
                <CheckSvg className="w-12 h-12 text-success-500" />
              </Column>
              <Text>You have successfully unsubscribed.</Text>
            </Column>
          </Column>
        )}
      </Column>
    </PublicLayout>
  )
}

export default UnsubscribeScreen
