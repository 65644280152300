import { useQuery } from 'react-query'
import getCampaignReportStatsService, {
  GetCampaignReportStatsServiceProps,
} from 'services/get-campaign-report-stats'

export type UseGetCampaignReportStatsProps =
  {} & GetCampaignReportStatsServiceProps

function useGetCampaignReportStats({
  campaignId,
  startDate,
  endDate,
}: UseGetCampaignReportStatsProps) {
  const {
    data,
    error,
    status,
    refetch,
    isRefetching: refetching,
  } = useQuery(
    ['/campaigns/{campaignId}/reports/stats', campaignId, startDate, endDate],
    () => getCampaignReportStatsService({ campaignId, startDate, endDate })
  )
  return {
    data: data || [],
    pending: status === 'loading',
    error,
    refetch,
    refetching,
  }
}

export default useGetCampaignReportStats
