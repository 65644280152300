import { ReactComponent as PlusSvg } from '@trinsly/common/src/assets/icons/plus.svg'
import Column from '@trinsly/common/src/components/atoms/column'
import Form from '@trinsly/common/src/components/atoms/form'
import Input from '@trinsly/common/src/components/atoms/input'
import Spinner from '@trinsly/common/src/components/atoms/spinner'
import Text from '@trinsly/common/src/components/atoms/text'
import IconButton from '@trinsly/common/src/components/molecules/icon-button'
import useForm from '@trinsly/common/src/hooks/use-form'
import usePageableState from '@trinsly/common/src/hooks/use-pageable-state'
import required from '@trinsly/common/src/validations/required'
import useCreateHotlist from 'api-hooks/use-create-hotlist'
import useGetHotlists from 'api-hooks/use-get-hotlists'
import { toast } from 'react-toastify'
import ProspectType from 'types/prospect-type'
import HotlistListItem from './hotlist-list-item'

type HotlistsPopoverSectionProps = {
  prospectIds: ProspectType['id'][]
}

function HotlistsSection({ prospectIds }: HotlistsPopoverSectionProps) {
  const { pageable } = usePageableState({
    page: 1,
    perPage: 99,
  })
  const { data: hotlists, pending } = useGetHotlists(pageable)
  const isNonIdealState = !pending && !hotlists?.length
  const hasData = pending || hotlists?.length > 0

  const { fetch: createHotlist, pending: pendingCreateHotlist } =
    useCreateHotlist({
      onSuccess: () => {
        toast.success('Hotlist created!')
        reset()
      },
      onError: (error) => {
        const errorMessage = error?.message || 'Error creating hotlist'
        toast.error(errorMessage)
      },
    })
  const { getValue, setValue, getError, submit, reset } = useForm({
    onSubmit: (values) => {
      createHotlist({
        hotlist: values,
      })
    },
    validations: {
      name: [required],
    },
  })

  return (
    <Column className="min-w-60 py-sm gap-xs">
      {!isNonIdealState && (
        <Column className="px-lg">
          <Text
            size="xs"
            fontWeight="bold"
            color="gray-400"
            className="uppercase"
          >
            Hotlists
          </Text>
        </Column>
      )}
      {pending && (
        <Column className="px-lg">
          <Spinner size="sm" />
        </Column>
      )}
      {hasData && (
        <Column>
          {hotlists?.map?.((hotlist) => (
            <HotlistListItem
              key={hotlist?.id}
              hotlist={hotlist}
              prospectIds={prospectIds}
            />
          ))}
        </Column>
      )}
      <Form onSubmit={submit}>
        <Column className="px-sm relative">
          <Input
            value={getValue('name')}
            onChange={(value) => setValue('name', value)}
            error={getError('name')}
            placeholder="New hotlist"
            required
          />
          <Column className="absolute top-1 right-5">
            <IconButton
              size="sm"
              type="submit"
              variant="transparent"
              intent="primary"
              pending={pendingCreateHotlist}
            >
              <PlusSvg />
            </IconButton>
          </Column>
        </Column>
      </Form>
    </Column>
  )
}

export default HotlistsSection
