import Input from '@trinsly/common/src/components/atoms/input'
import { ChangeEvent, useCallback } from 'react'

const OTP_LENGTH = 6

function TwoFactorOtpInput({
  onChange,
  value,
}: {
  onChange: (value: string) => void
  value: string
}) {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value
        .trim()
        .substring(0, OTP_LENGTH)
        .toLowerCase()
      onChange(value)
    },
    [onChange]
  )

  return (
    <Input
      autoComplete="off"
      onChange={(value, event) => handleChange(event as any)}
      placeholder="Enter your 2FA code"
      value={value}
      required
    />
  )
}

export default TwoFactorOtpInput
