import { ReactComponent as ActivityLogSvg } from '@trinsly/common/src/assets/icons/activity-log.svg'
import Card from '@trinsly/common/src/components/atoms/card'
import Column from '@trinsly/common/src/components/atoms/column'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Text from '@trinsly/common/src/components/atoms/text'
import Tooltip from '@trinsly/common/src/components/atoms/tooltip'
import NonIdealState from '@trinsly/common/src/components/organisms/non-ideal-state'
import useGetActivityLogs from 'api-hooks/use-get-activity-logs'
import cx from 'classnames'
import SelectMember from 'components/select-member'
import moment from 'moment'
import { useAuth } from 'providers/auth-provider'
import { useEffect, useState } from 'react'
import { AutoSizer, List } from 'react-virtualized'
import ActivityLogEventEnum from 'types/activity-log-event-enum'
import { OrganizationMember } from 'types/settings-type'

function ActivityLogSection() {
  const [listHeight, setListHeight] = useState(0)
  const [hasScroll, setHasScroll] = useState(false)
  const { user } = useAuth()
  const [selectedUser, setSelectedUser] = useState({} as OrganizationMember)
  const { data: logs, pending: activityLogsPending } = useGetActivityLogs({
    userId: selectedUser?.userId,
  })

  useEffect(() => {
    if (!activityLogsPending && logs) {
      let tempHeight = logs.length * LIST_ITEM_HEIGHT

      const scrollable = tempHeight > MAX_LIST_CONTAINER_HEIGHT

      setHasScroll(scrollable)
      setListHeight(
        tempHeight > MAX_LIST_CONTAINER_HEIGHT
          ? MAX_LIST_CONTAINER_HEIGHT
          : tempHeight
      )
    }
  }, [logs, activityLogsPending])

  return (
    <Column className="align-center">
      <Row className="justify-between items-center">
        <Tooltip
          children={
            <span className="w-36 h-auto px-xs py-xs">
              <Heading size="xl" fontWeight="bold">
                Activity Log
              </Heading>
            </span>
          }
          placement="right-end"
          content="Activity Logs are available for the past 30 days and show email
        sends, opens, and replies."
        />
        <Separator />
        {user && user.organizationRole === 'admin' && (
          <Column style={{ width: '12rem' }}>
            <Text size="sm">Other Team Member's Activity</Text>
            <SelectMember
              placeholder="View Team Member"
              value={
                selectedUser ?? {
                  userId: user.id,
                  userName: user.name,
                  userEmail: user.email,
                }
              }
              onChange={(value) => {
                setSelectedUser(value!)
              }}
            />
          </Column>
        )}
      </Row>
      <Separator size="sm" />
      <Card>
        {logs.length === 0 && (
          <Column className="p-md">
            <NonIdealState
              icon={<ActivityLogSvg className="w-md h-md text-primary-500" />}
              message="No activity in the last 30 days"
            />
          </Column>
        )}
        {logs.length > 0 && (
          <AutoSizer disableHeight>
            {({ width }) => (
              // @ts-ignore
              <List
                width={width}
                style={{ paddingBottom: '1.5rem' }}
                height={listHeight}
                rowHeight={LIST_ITEM_HEIGHT}
                rowCount={logs.length}
                rowRenderer={({ index, key, style }) => {
                  const log = logs[index]
                  const activityLogEventTranslation =
                    translateActivityLogEvent(log)
                  if (!activityLogEventTranslation) {
                    return null
                  }

                  const {
                    formattedTimestamp,
                    iconOuterClass,
                    iconType,
                    subtitle,
                    title,
                  } = activityLogEventTranslation
                  return (
                    <Column key={key} style={style} className="px-md">
                      <Separator />
                      <Column className="space-y-1 relative pl-lg">
                        <Text fontWeight="bold" color="gray-900">
                          {title}
                        </Text>
                        <Text>{subtitle}</Text>
                        <Text size="sm" color="gray-400">
                          {formattedTimestamp}
                        </Text>
                        <Column className="absolute left-0 -top-0.5">
                          <Icon
                            iconOuterClass={iconOuterClass}
                            type={iconType as any}
                          />
                        </Column>
                      </Column>
                    </Column>
                  )
                }}
              />
            )}
          </AutoSizer>
        )}
        {!hasScroll && <Separator />}
      </Card>
    </Column>
  )
}

export default ActivityLogSection

const MAX_LIST_CONTAINER_HEIGHT = 600
const LIST_ITEM_HEIGHT = 100

//TODO: correctly type this
type ActivityLogEvent = {
  event: any
  payload: any
  timestamp: any
}

function translateActivityLogEvent(activityLogEvent: ActivityLogEvent) {
  const { event, payload, timestamp } = activityLogEvent
  const baseTranslation = {
    formattedTimestamp: moment(timestamp).format('dddd, M/D, h:mm:ss a'),
  }

  switch (event) {
    case ActivityLogEventEnum.EMAIL_DELIVERED: {
      const { email, emailMessage } = payload
      const { subject } = emailMessage

      return {
        ...baseTranslation,
        iconOuterClass: 'text-info-500',
        iconType: 'paper-plane',
        subtitle: 'has been delivered',
        title: `${subject} to ${email}`,
      }
    }

    case ActivityLogEventEnum.EMAIL_OPENED: {
      const { email } = payload
      return {
        ...baseTranslation,
        iconOuterClass: 'text-success-500',
        iconType: 'envelope-open',
        subtitle: 'opened your email',
        title: email,
      }
    }

    case ActivityLogEventEnum.THREAD_RESPONDED: {
      const { email } = payload.emailThread

      return {
        ...baseTranslation,
        iconOuterClass: 'text-success-500',
        iconType: 'reply',
        subtitle: 'responded to your email',
        title: email,
      }
    }

    default:
      return null
  }
}

function Icon({
  iconInnerClass = '',
  iconOuterClass = '',
  type,
}: {
  iconInnerClass?: string
  iconOuterClass?: string
  type: any
}) {
  return (
    <span className={cx('icon', iconOuterClass)}>
      <i className={cx('fas', `fa-${type}`, iconInnerClass)} />
    </span>
  )
}
