import PageableType from '@trinsly/common/src/types/pageable-type'
import { useQuery } from 'react-query'
import getBatchDraftsService from 'services/get-batch-drafts-service'

type UseGetBatchDraftsProps = {} & PageableType

function useGetBatchDrafts(pageable: UseGetBatchDraftsProps) {
  const { data, error, status } = useQuery(['/batches/drafts', pageable], () =>
    getBatchDraftsService(pageable)
  )

  return {
    data: data?.drafts || [],
    total: data?.meta?.pagination?.totalObjects || 0,
    pending: status === 'loading',
    error,
  }
}

export default useGetBatchDrafts
