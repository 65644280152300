import { ReactComponent as CollapseSvg } from '@trinsly/common/src/assets/icons/collapse.svg'
import { ReactComponent as ExpandSvg } from '@trinsly/common/src/assets/icons/expand.svg'
import Column from '@trinsly/common/src/components/atoms/column'
import Divider from '@trinsly/common/src/components/atoms/divider'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Text from '@trinsly/common/src/components/atoms/text'
import Expandable from '@trinsly/common/src/components/molecules/expandable'
import IconButton from '@trinsly/common/src/components/molecules/icon-button'
import EmailTemplateInput from '@trinsly/common/src/components/molecules/quill-editor/email-template-input'
import AttachmentsInput from 'components/attachments-input'
import { isToday, parseISO } from 'date-fns'
import React from 'react'
import ThreadDeliverTimeEnum from 'types/thread-deliver-time-enum'
import ThreadType from 'types/thread-type'
import DateSelector from './date-selector'
import SelectDeliverTime from './select-deliver-time'

export type UnsentEmailListItemProps = {
  email: ThreadType['emails'][number]
}

function UnsentEmailListItem({ email }: UnsentEmailListItemProps) {
  const [expanded, setExpanded] = React.useState(false)
  const date = email?.deliverAt ? parseISO(email?.deliverAt) : undefined
  const isDateToday = date ? isToday(date) : false
  return (
    <Column className="bg-gray-100 rounded-lg py-sm px-md">
      <Row className="flex-wrap gap-md items-center">
        <Column className="flex-1">
          <Text size="xs" fontWeight="bold" className="uppercase">
            Stage #{email?.position || '#'}
          </Text>
          <Text size="sm">{email?.subject || '--'}</Text>
        </Column>
        <DateSelector dateISO={email?.deliverAt} readOnly={true} />
        <Column className="min-w-56">
          <SelectDeliverTime
            value={
              isDateToday
                ? ThreadDeliverTimeEnum.IMMEDIATELY
                : ThreadDeliverTimeEnum.EARLY_MORNING
            }
            disabled={true}
          />
        </Column>
        <IconButton variant="ghost" onClick={() => setExpanded(!expanded)}>
          {expanded ? <CollapseSvg /> : <ExpandSvg />}
        </IconButton>
      </Row>
      <Expandable expanded={expanded} maxHeight="40rem">
        <Column className="pt-sm pb-md">
          <EmailTemplateInput
            subject={{
              value: email?.subject,
              disabled: true,
            }}
            body={{
              value: email?.body,
              disabled: true,
              modules: [],
            }}
          />
          <Separator />
          <Divider />
          <Separator />
          <Column>
            <Text
              size="sm"
              fontWeight="bold"
              className="uppercase"
              color="gray-900"
            >
              Attachments
            </Text>
            {!email?.attachments?.length ? (
              <React.Fragment>
                <Separator size="xxs" />
                <Text size="sm" color="gray-400">
                  No attachments.
                </Text>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Separator size="sm" />
                <AttachmentsInput
                  disabled={true}
                  value={email?.attachments || []}
                  onChange={() => {}}
                />
              </React.Fragment>
            )}
          </Column>
        </Column>
      </Expandable>
    </Column>
  )
}

export default UnsentEmailListItem
