import moment from 'moment'

enum DateRangeEnum {
  ALL = 'ALL',
  LAST_MONTH = 'LAST_MONTH',
  LAST_WEEK = 'LAST_WEEK',
  THIS_MONTH = 'THIS_MONTH',
  THIS_WEEK = 'THIS_WEEK',
  CUSTOM = 'CUSTOM',
}

export default DateRangeEnum

export const translateDateRangeEnum: Record<DateRangeEnum, string> = {
  [DateRangeEnum.THIS_WEEK]: 'This Week',
  [DateRangeEnum.THIS_MONTH]: 'This Month',
  [DateRangeEnum.LAST_WEEK]: 'Last Week',
  [DateRangeEnum.LAST_MONTH]: 'Last Month',
  [DateRangeEnum.ALL]: 'All',
  [DateRangeEnum.CUSTOM]: 'Custom',
}

export const getDatesByDateRangeEnum = ({
  dateRange,
  localizedDate,
}: {
  dateRange: DateRangeEnum
  localizedDate: moment.Moment
}): { endDate: moment.Moment | null; startDate: moment.Moment | null } => {
  switch (dateRange) {
    case DateRangeEnum.LAST_MONTH: {
      const endDate = localizedDate
        .clone()
        .startOf('month')
        .subtract(1, 'second')
      const startDate = endDate.clone().startOf('month')

      return { endDate, startDate }
    }
    case DateRangeEnum.LAST_WEEK: {
      const endDate = localizedDate
        .clone()
        .startOf('week')
        .subtract(1, 'second')
      const startDate = endDate.clone().startOf('week')

      return { endDate, startDate }
    }
    case DateRangeEnum.THIS_MONTH:
      return {
        endDate: null,
        startDate: localizedDate.clone().startOf('month'),
      }
    case DateRangeEnum.THIS_WEEK:
      return {
        endDate: null,
        startDate: localizedDate.clone().startOf('week'),
      }

    case DateRangeEnum.ALL:
    default:
      return {
        endDate: null,
        startDate: null,
      }
  }
}

export function dateToTimeZone({
  date = new Date(),
  timezoneOffset,
}: {
  date?: string | Date
  timezoneOffset: number
}): moment.Moment {
  return moment.utc(date).add(timezoneOffset, 'seconds')
}
