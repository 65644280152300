import Separator from '@trinsly/common/src/components/atoms/separator'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import useDeleteInboxIntegration from 'api-hooks/use-delete-inbox-integration'

function InboxIntegrationSection() {
  const { fetch: deleteInboxIntegration, pending } = useDeleteInboxIntegration({
    onError: (error) => {
      alert(error)
    },
    onSuccess: () => {
      console.log('Success')
      window.location.reload()
    },
  })
  return (
    <>
      <Text size="sm" fontWeight="semibold">
        Warning: You will lose all access to sending, reply rates, and open
        rates. Scheduled messages and batches will be canceled and unable to be
        rescheduled.
      </Text>
      <Separator size="xxs" />
      <Button
        pending={pending}
        onClick={deleteInboxIntegration}
        type="button"
        shape="rounded"
        variant="ghost"
        intent="danger"
      >
        Disconnect Email Inbox
      </Button>
    </>
  )
}

export default InboxIntegrationSection
