import Column from '@trinsly/common/src/components/atoms/column'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Row from '@trinsly/common/src/components/atoms/row'
import Text from '@trinsly/common/src/components/atoms/text'
import SelectInput from '@trinsly/common/src/components/molecules/select-input'
import { compact, uniq } from 'lodash'
import React from 'react'
import CampaignType from 'types/campaign-type'
import { useDraftContext } from '../draft-provider'
import { useFormContext } from '../form-provider'

type VariablesMapSectionProps = {}

function VariablesMapSection({}: VariablesMapSectionProps) {
  const { getValue, setValue, getError } = useFormContext()
  const { pending: pendingDraft } = useDraftContext()

  const shouldDisableForm = pendingDraft
  const campaign = getValue('campaign') as CampaignType | undefined
  const templateValuesAsString = campaign?.templates?.reduce?.(
    (acc, template) => `${acc} ${template?.subject} ${template?.body}`,
    ''
  )
  const campaignVariables = React.useMemo(() => {
    const result = templateValuesAsString?.match(/{{\w+}}/gm)
    const variables = uniq(compact(['{{Email}}', ...(result || [])]))
    const variableNames = variables.map((variable) =>
      variable?.replace('{{', '').replace('}}', '')
    )
    return variableNames
  }, [templateValuesAsString])

  function fillMatchedVariableMap() {
    const csvColumns = (getValue('csv')?.[0] || []) as string[]
    const matchedVariables = campaignVariables?.reduce(
      (acc, variableAsLabel) => {
        let matchVariableByColumnIndex = csvColumns?.findIndex(
          (column) =>
            column?.toLowerCase?.() === variableAsLabel?.toLowerCase?.()
        ) as number | undefined
        matchVariableByColumnIndex =
          matchVariableByColumnIndex === -1
            ? undefined
            : matchVariableByColumnIndex
        return {
          ...acc,
          [variableAsLabel]: matchVariableByColumnIndex,
        }
      },
      {}
    )

    setValue('variables', matchedVariables)
  }

  const csvColumns = (getValue('csv')?.[0] || []) as string[]
  const csvFileName = getValue('csvFileName')
  React.useEffect(() => {
    fillMatchedVariableMap()
  }, [csvFileName, templateValuesAsString])

  const placeholder = Boolean(getValue('csv'))
    ? 'Select a column'
    : 'upload a csv'

  return (
    <Column className="px-lg gap-md">
      <Heading size="lg" fontWeight="bold">
        Map the variables
      </Heading>
      <Row className="gap-xl">
        <Column className="gap-sm">
          <Text
            size="xs"
            fontWeight="bold"
            className="uppercase"
            color="gray-900"
          >
            Required
          </Text>
          {campaignVariables?.map((variable) => (
            <Column className="min-w-60" key={variable}>
              <SelectInput
                required
                label={variable}
                placeholder={placeholder}
                clearable={false}
                value={getValue(`variables[${variable}]`)}
                onChange={(value) => {
                  setValue(`variables[${variable}]`, value)
                }}
                itemRenderer={(item) => csvColumns?.[item] || '--'}
                options={csvColumns?.map?.((column, index) => index)}
                error={getError(`variables[${variable}]`)}
                disabled={shouldDisableForm}
              />
            </Column>
          ))}
        </Column>
        <Column className="gap-sm">
          <Text
            size="xs"
            fontWeight="bold"
            className="uppercase"
            color="gray-900"
          >
            Optional
          </Text>
          <Column className="min-w-60">
            <SelectInput
              label="SourceUrl"
              placeholder={placeholder}
              value={getValue(`variables[SourceUrl]`)}
              onChange={(value) => {
                setValue(`variables[SourceUrl]`, value)
              }}
              itemRenderer={(item) => csvColumns?.[item] || '--'}
              options={csvColumns?.map?.((column, index) => index)}
              disabled={shouldDisableForm}
            />
          </Column>
        </Column>
      </Row>
    </Column>
  )
}

export default VariablesMapSection
