import api from '@trinsly/common/src/services/api'
import CampaignType from 'types/campaign-type'
import DraftType from 'types/draft-type'

export type GetCampaignDraftServiceProps = {
  draftId: CampaignType['id']
}

async function getCampaignDraftService({
  draftId,
}: GetCampaignDraftServiceProps) {
  const response = (await api(`/drafts/${draftId}`, {
    method: 'GET',
  })) as any

  return response as DraftType
}

export default getCampaignDraftService
