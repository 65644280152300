import { ReactComponent as GlobeIcon } from '@trinsly/common/src/assets/icons/globe.svg'
import { ReactComponent as LocationPointerIcon } from '@trinsly/common/src/assets/icons/location-pointer.svg'
import Clickable from '@trinsly/common/src/components/atoms/clickable'
import Column from '@trinsly/common/src/components/atoms/column'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import React from 'react'
import OrganizationSubtab from './organization-subtab'
import SubtabItem from './subtab-item'
import TrackingDomainSubtab from './tracking-domain-subtab'

export type OrganizationTabProps = {}

const OrganizationTab = ({}: OrganizationTabProps) => {
  const [currentTab, setCurrentTab] = React.useState('organization')
  return (
    <Column>
      <Row>
        <Column className="flex-1 p-xl">
          {
            {
              organization: <OrganizationSubtab />,
              'tracking domain': <TrackingDomainSubtab />,
            }[currentTab]
          }
        </Column>
        <Column className="min-w-72 py-xl">
          <>
            <Clickable
              className="rounded-lg"
              onClick={() => setCurrentTab('organization')}
            >
              <SubtabItem
                title={'Organization'}
                icon={<GlobeIcon className="w-5 h-5 text-current" />}
                currentTab={currentTab}
              />
            </Clickable>
            <Separator size="xs" />
            <Clickable
              className="rounded-lg"
              onClick={() => setCurrentTab('tracking domain')}
            >
              <SubtabItem
                title={'Tracking Domain'}
                icon={<LocationPointerIcon className="w-5 h-5 text-current" />}
                currentTab={currentTab}
              />
            </Clickable>
            <Separator size="xs" />
          </>
        </Column>
      </Row>
    </Column>
  )
}

export default OrganizationTab
