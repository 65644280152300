import PageableType from '@trinsly/common/src/types/pageable-type'
import { useQuery } from 'react-query'
import getUniversitiesService from 'services/get-universities-service'

type UseGetUniversitiesProps = {} & PageableType

function useGetUniversities(pageable: UseGetUniversitiesProps) {
  const { data, error, status } = useQuery(['/universities', pageable], () =>
    getUniversitiesService(pageable)
  )

  return {
    data: data?.universities || [],
    total: data?.total || 0,
    pending: status === 'loading',
    error,
  }
}

export default useGetUniversities
