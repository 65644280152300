import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation, useQueryClient } from 'react-query'
import archiveCampaignService from 'services/archive-campaign-service'

type UseArchiveCampaignProps = {} & MutateApiHookType<Campaign>

function useArchiveCampaign({
  onSuccess,
  onError,
  onMutate,
}: UseArchiveCampaignProps) {
  const queryClient = useQueryClient()
  const { mutate: fetch, isLoading: pending } = useMutation(
    archiveCampaignService,
    {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries('/campaigns')
        onSuccess?.(data)
      },
      onError,
      onMutate,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useArchiveCampaign
