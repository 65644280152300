import { useQuery } from 'react-query'
import getCampaignTemplatesService, {
  GetCampaignTemplatesServiceProps,
} from 'services/get-campaign-templates-service'

export type UseGetCampaignTemplatesProps = {} & GetCampaignTemplatesServiceProps

function useGetCampaignTemplates({ campaignId }: UseGetCampaignTemplatesProps) {
  const {
    data,
    error,
    status,
    refetch,
    isRefetching: refetching,
  } = useQuery(['/campaigns/{campaignId}/templates', campaignId], () =>
    getCampaignTemplatesService({ campaignId })
  )

  return {
    data: data || [],
    pending: status === 'loading',
    error,
    refetch,
    refetching,
  }
}

export default useGetCampaignTemplates
