import React from 'react'
import Column from '../atoms/column'

export type ExpandableProps = {
  children: React.ReactNode | React.ReactNodeArray
  expanded?: boolean
  maxHeight: number | string
}

function Expandable({
  children,
  expanded = false,
  maxHeight,
}: ExpandableProps) {
  return (
    <Column
      style={{
        maxHeight: expanded ? maxHeight : 0,
        overflow: expanded ? 'auto' : 'hidden',
        opacity: expanded ? 1 : 0,
        transition: 'all 0.3s ease-in-out',
      }}
    >
      {children}
    </Column>
  )
}

export default Expandable
