import api from '@trinsly/common/src/services/api'
import ProspectType from 'types/prospect-type'

type DeleteProspectEmploymentServiceProps = {
  employmentId: ProspectType['employments'][number]['id']
}

async function deleteProspectEmploymentService({
  employmentId,
}: DeleteProspectEmploymentServiceProps) {
  const response = (await api(`/employments/${employmentId}`, {
    method: 'DELETE',
  })) as any
  return
}

export default deleteProspectEmploymentService
