import api from '@trinsly/common/src/services/api'
import InviteType from 'types/invite-type'

export type CreateOrganizationMemberInviteProps = {
  organizationId: number
  email: string
}

export default async function createOrganizationMemberInviteService({
  organizationId,
  email,
}: CreateOrganizationMemberInviteProps) {
  const response = await api('/invites', {
    method: 'POST',
    body: {
      invite: { organization_id: organizationId, email: email },
    },
  })

  return response as any as InviteType
}
