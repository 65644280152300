import api from '@trinsly/common/src/services/api'
import { toInteger } from 'lodash'
import CampaignType from 'types/campaign-type'

type CreateCampaignServiceProps = {
  campaign: CampaignType
}

async function createCampaignService({ campaign }: CreateCampaignServiceProps) {
  const response = (await api(`/campaigns`, {
    method: 'POST',
    body: {
      campaign: {
        bcc: campaign?.bcc,
        crm_job_id: campaign?.crmJobId,
        is_for_candidate: campaign?.isForCandidate,
        name: campaign?.name,
        tag_list: campaign?.tagList,
        templates: campaign?.templates?.map((template, index) => ({
          duration: toInteger(Number(template?.duration)) * 24, //Days to Hours
          position: index + 1,
          template: {
            subject: template?.subject,
            body: template?.body,
            attachments: template?.attachments,
          },
        })),
      },
    },
  })) as CampaignType
  return response
}

export default createCampaignService
