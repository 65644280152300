import { Quill } from 'react-quill'

var Block = Quill.import('blots/block')
Block.tagName = 'DIV'
Quill.register(Block, true)

export default {}

const Parchment = Quill.import('parchment')
const IDAttribute = new Parchment.Attributor.Attribute()
IDAttribute.scope = Parchment.Scope.BLOCK
IDAttribute.attrName = 'id'
IDAttribute.keyName = 'id-attribute'

Quill.register(
  {
    'attributors/attribute/id': IDAttribute,
  },
  true
)

Quill.register(
  {
    'formats/id': IDAttribute,
  },
  true
)

const BlockId = Quill.import('blots/block')

class BlockBlot extends BlockId {
  constructor(domNode: any) {
    super(domNode)
    domNode.setAttribute('id', '')
    this.cache = {}
  }
}

BlockBlot.blotName = 'block'
