import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation, useQueryClient } from 'react-query'
import cancelThreadEmailService from 'services/cancel-thread-email-service'
import { ThreadEmailType } from 'types/thread-type'

export type UseCancelThreadEmailProps = {} & MutateApiHookType<ThreadEmailType>
function useCancelThreadEmail({
  onMutate,
  onSuccess,
  onError,
}: UseCancelThreadEmailProps) {
  const queryClient = useQueryClient()

  const { mutate: fetch, isLoading: pending } = useMutation(
    cancelThreadEmailService,
    {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries('/threads')
        onSuccess?.(data)
      },
      onError,
      onMutate,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useCancelThreadEmail
