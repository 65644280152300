import api from '@trinsly/common/src/services/api'
import PageableType from '@trinsly/common/src/types/pageable-type'
import { pickBy } from 'lodash'
import ProjectType from 'types/project-type'

type GetProjectsServiceProps = {} & PageableType

async function getProjectsService({ ...pageable }: GetProjectsServiceProps) {
  const orderBy = Object.keys(pageable?.sort || {})?.[0]
  const orderDirection = Object.values(pageable?.sort || {})?.[0]
  const hasSort = orderBy && orderDirection
  const response = await api(`/projects`, {
    method: 'GET',
    params: pickBy({
      page: pageable?.page,
      per_page: pageable?.perPage,
      query: pageable?.search,
      status: pageable?.filters?.status,
      start_date_before: pageable?.filters?.startDateBefore,
      start_date_after: pageable?.filters?.startDateAfter,
      end_date_before: pageable?.filters?.endDateBefore,
      end_date_after: pageable?.filters?.endDateAfter,
      tags: pageable?.filters?.tags,
      sort: hasSort ? `${orderBy} ${orderDirection}` : '',
    }),
  })

  const { meta, projects: remoteProjects } = response as any

  return {
    meta,
    projects: remoteProjects || [],
  } as {
    projects: ProjectType[]
    meta: { pagination: Pagination }
  }
}

export default getProjectsService
