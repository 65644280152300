import Card from '@trinsly/common/src/components/atoms/card'
import Column from '@trinsly/common/src/components/atoms/column'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import Modal from '@trinsly/common/src/components/molecules/modal'
import useDeleteCampaignDraft from 'api-hooks/use-delete-campaign-draft'
import React from 'react'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import DraftType from 'types/draft-type'

type LeaveConfirmationModalProps = {
  draft: DraftType
  saving: boolean
}

function LeaveConfirmationModal({
  draft,
  saving,
}: LeaveConfirmationModalProps) {
  const [redirectPath, setRedirectPath] = React.useState('')
  const [showConfirmationModal, setShowConfirmationModal] =
    React.useState(false)
  const history = useHistory()
  const { fetch: deleteDraft, pending: pendingDelete } = useDeleteCampaignDraft(
    {
      onSuccess: (data) => {
        retry?.()
      },
      onError: (error) => {
        toast.error('Error deleting Draft')
      },
    }
  )

  function retry() {
    setShowConfirmationModal(false)
    history.block(() => {})
    history.push(redirectPath)
  }

  React.useEffect(() => {
    if (draft?.id) {
      history.block((prompt) => {
        const isUserLeaving = prompt?.pathname !== window?.location?.pathname
        if (isUserLeaving) {
          setRedirectPath(prompt.pathname)
          setShowConfirmationModal(true)
          return 'true'
        }
      })
    } else {
      history.block(() => {})
    }

    return () => {
      history.block(() => {})
    }
  }, [history, draft?.id])

  return (
    <Modal
      size="sm"
      visible={showConfirmationModal}
      onRequestClose={() => setShowConfirmationModal(false)}
    >
      <Card className="overflow-hidden">
        <Column className="p-md">
          <Heading size="xl" fontWeight="bold">
            Campaign Draft
          </Heading>
          <Separator size="sm" />
          <Text>What should we do with this campaign draft?</Text>
        </Column>
        <Modal.Footer>
          <Button
            className="min-w-28"
            variant="ghost"
            pending={saving}
            onClick={() => {
              retry?.()
            }}
          >
            Keep
          </Button>
          <Separator size="sm" />
          <Button
            className="min-w-28"
            intent="danger"
            pending={pendingDelete}
            onClick={() => {
              deleteDraft({ draftId: draft?.id })
            }}
          >
            Discard
          </Button>
        </Modal.Footer>
      </Card>
    </Modal>
  )
}

export default LeaveConfirmationModal
