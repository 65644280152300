import QueryApiHookType from '@trinsly/common/src/types/query-api-hook-type'
import { useQuery } from 'react-query'
import getCampaignDraftService, {
  GetCampaignDraftServiceProps,
} from 'services/get-campaign-draft-service'
import DraftType from 'types/draft-type'

type UseGetCampaignDraftProps = {
  enabled: boolean
} & GetCampaignDraftServiceProps &
  QueryApiHookType<DraftType>

function useGetCampaignDraft({
  draftId,
  enabled,
  onError,
  onSuccess,
}: UseGetCampaignDraftProps) {
  const { data, error, status } = useQuery(
    ['/campaigns/drafts/{id}', draftId],
    () => getCampaignDraftService({ draftId }),
    {
      enabled,
      staleTime: !enabled ? 0 : undefined,
      refetchOnWindowFocus: false,
      onError,
      onSuccess,
    }
  )

  return {
    data,
    pending: status === 'loading',
    error,
  }
}

export default useGetCampaignDraft
