import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation, useQueryClient } from 'react-query'
import convertNylasTokenService from 'services/convert-nylas-token-service'

type UseConvertNylasTokenProps = {} & MutateApiHookType<any>

function useConvertNylasToken({
  onSuccess,
  onError,
}: UseConvertNylasTokenProps) {
  const queryClient = useQueryClient()
  const { mutate: fetch, isLoading: pending } = useMutation(
    convertNylasTokenService,
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries()
        onSuccess?.()
      },
      onError,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useConvertNylasToken
