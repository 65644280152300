import Checkbox from '@trinsly/common/src/components/atoms/checkbox'
import Label from '@trinsly/common/src/components/atoms/label'
import Row from '@trinsly/common/src/components/atoms/row'
import Spinner from '@trinsly/common/src/components/atoms/spinner'
import Text from '@trinsly/common/src/components/atoms/text'
import Animated from '@trinsly/common/src/components/molecules/animated'
import usePageableState from '@trinsly/common/src/hooks/use-pageable-state'
import useGetHotlists from 'api-hooks/use-get-hotlists'
import { compact } from 'lodash'

interface HotlistFilterProps {
  values: string[]
  onChange: (values: string[] | undefined) => void
}

function HotlistFilter({ onChange, values }: HotlistFilterProps) {
  const { pageable } = usePageableState({
    page: 1,
    perPage: 999,
  })
  const { data: hotlists, pending: pendingHotlists } = useGetHotlists(pageable)
  const isNonIdealState = !pendingHotlists && !hotlists?.length

  return (
    <Animated className="gap-xs">
      {pendingHotlists && <Spinner size="xs" />}
      {isNonIdealState && (
        <Text size="xs" color="gray-400">
          No Hotlists yet.
        </Text>
      )}
      {hotlists?.map?.((hotlist) => {
        const checked = values?.includes?.(String(hotlist?.id))
        return (
          <Row className="items-center gap-xs">
            <Checkbox
              checked={checked}
              onChange={() => {
                if (checked) {
                  onChange?.(
                    values?.filter?.(
                      (value) => String(value) !== String(hotlist?.id)
                    )
                  )
                  return
                }
                onChange?.(compact([...values, String(hotlist?.id)]))
              }}
            />
            <Label>{hotlist?.name || '--'}</Label>
          </Row>
        )
      })}
    </Animated>
  )
}

export default HotlistFilter
