import { retrieveCredentials } from '@trinsly/common/src/services/api'
import axios from 'axios'

type DeleteMergeSingleIntegrationServiceProps = {
  integrationId: Integration['id']
}

async function deleteMergeSingleIntegrationService({
  integrationId,
}: DeleteMergeSingleIntegrationServiceProps) {
  const { accessToken } = retrieveCredentials()
  const response = await axios.request({
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${process.env.REACT_APP_MERGE_API_URL}/connection/${integrationId}`,
  })

  return response?.data as any
}

export default deleteMergeSingleIntegrationService
