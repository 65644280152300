import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation, useQueryClient } from 'react-query'
import rescheduleAllUnsentThreadsService from 'services/reschedule-all-unsent-threads-service'
import ThreadType from 'types/thread-type'

export type UseRescheduleAllUnsentThreadsProps = {} & MutateApiHookType<
  ThreadType[]
>
function useRescheduleAllUnsentThreads({
  onMutate,
  onSuccess,
  onError,
}: UseRescheduleAllUnsentThreadsProps) {
  const queryClient = useQueryClient()

  const { mutate: fetch, isLoading: pending } = useMutation(
    rescheduleAllUnsentThreadsService,
    {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries('/threads')
        onSuccess?.(data)
      },
      onError,
      onMutate,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useRescheduleAllUnsentThreads
