import Column from '@trinsly/common/src/components/atoms/column'
import Row from '@trinsly/common/src/components/atoms/row'
import React from 'react'
import { ReactComponent as PublicBackgroundSvg } from 'assets/public-background.svg'

export type PublicLayoutProps = {
  children: React.ReactNode | React.ReactNodeArray
}

function PublicLayout({ children }: PublicLayoutProps) {
  return (
    <Row
      className="relative flex-1 bg-primary-500"
      style={{ minHeight: '100vh' }}
    >
      <Column
        className="bg-white pl-20 pt-16 pr-20 pb-8"
        style={{ minWidth: 580, flex: 0.3 }}
      >
        {children}
      </Column>
      <Column className="relative" style={{ flex: 0.7 }}>
        <Column className="absolute inset-0">
          <PublicBackgroundSvg className="w-full h-full" />
        </Column>
      </Column>
    </Row>
  )
}

export default PublicLayout
