import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation, useQueryClient } from 'react-query'
import deleteProspectEmploymentService from 'services/delete-prospect-employment-service'

export type UseDeleteProspectEmploymentProps = {} & MutateApiHookType<void>

function useDeleteProspectEmployment({
  onMutate,
  onSuccess,
  onError,
}: UseDeleteProspectEmploymentProps) {
  const queryClient = useQueryClient()

  const { mutate: fetch, isLoading: pending } = useMutation(
    deleteProspectEmploymentService,
    {
      onSuccess: async (...args) => {
        await queryClient.invalidateQueries(
          '/prospects/{prospectId}/employments'
        )
        onSuccess?.(...args)
      },
      onError,
      onMutate,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useDeleteProspectEmployment
