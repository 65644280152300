import { useQuery } from 'react-query'
import { getTrackerDomainsService } from 'services/settings/get-tracker-domains-service'

function useGetTrackerDomains() {
  const {
    data,
    error,
    status,
    refetch,
    isRefetching: refetching,
  } = useQuery('/tracker_domains', getTrackerDomainsService)

  return {
    data: data || [],
    pending: status === 'loading',
    error,
    refetch,
    refetching,
  }
}

export default useGetTrackerDomains
