import Card from '@trinsly/common/src/components/atoms/card'
import Column from '@trinsly/common/src/components/atoms/column'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Table from '@trinsly/common/src/components/atoms/table'
import SearchInput from '@trinsly/common/src/components/molecules/search-input'
import usePageable from '@trinsly/common/src/hooks/use-pageable'
import useGetUsers from 'api-hooks/use-get-users'
import PrivateLayout from 'components/private-layout'
import DisimpersonateButton from './disimpersonate-button'
import ImpersonateButton from './impersonate-button'

function UsersScreen({}) {
  const { pageable, setSearch } = usePageable()
  const { data: users, pending: pendingUsers } = useGetUsers(pageable)

  return (
    <PrivateLayout>
      <Column className="py-md px-lg bg-white shadow">
        <Heading size="xl" fontWeight="bold">
          Users
        </Heading>
        <Separator />
        <Row className="justify-between gap-md">
          <Column className="min-w-64 w-96">
            <SearchInput
              placeholder="Search for Users..."
              value={pageable?.search}
              onChange={(value) => setSearch(value)}
            />
          </Column>
          <DisimpersonateButton />
        </Row>
      </Column>
      <Separator size="lg" />
      <Column className="px-lg">
        <Card className="overflow-hidden">
          <Table
            data={users}
            pending={pendingUsers}
            pageSize={users?.length || 25}
            columns={[
              {
                id: 'name',
                width: 400,
                header: 'Name',
                cell: (item) => item?.name,
              },
              {
                header: 'Email',
                width: 400,
                cell: (item) => item?.email,
              },
              {
                header: 'Actions',
                width: 100,
                cell: (item) => <ImpersonateButton user={item} />,
              },
            ]}
          />
        </Card>
        <Separator size="xl" />
        <Separator size="xl" />
      </Column>
    </PrivateLayout>
  )
}

export default UsersScreen
