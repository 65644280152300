import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation } from 'react-query'
import { requestMagic } from 'services/integrations/create-email-magic-service'

export type UseCreateEmailMagicProps = {} & MutateApiHookType<void>

function useCreateEmailMagic({
  onSuccess,
  onError,
  onMutate,
}: UseCreateEmailMagicProps) {
  const { mutate: fetch, isLoading: pending } = useMutation(
    requestMagic, //todo replace with real service when live
    {
      onSuccess: async (user) => {
        onSuccess?.(user)
      },
      onError,
      onMutate,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useCreateEmailMagic
