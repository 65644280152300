import PageableType from '@trinsly/common/src/types/pageable-type'
import { useQuery } from 'react-query'
import getHotlistsService from 'services/get-hotlists-service'

type UseGetHotlistsProps = {} & PageableType

function useGetHotlists(pageable: UseGetHotlistsProps) {
  const { data, error, status } = useQuery(['/hotlists', pageable], () =>
    getHotlistsService(pageable)
  )

  const hotlists = data?.hotlists || []

  return {
    data: hotlists,
    total: data?.meta?.pagination?.totalObjects || 0,
    pending: status === 'loading',
    error,
  }
}

export default useGetHotlists
