import Column from '@trinsly/common/src/components/atoms/column'
import MultiSelectInput, {
  MultiSelectInputProps,
} from '@trinsly/common/src/components/molecules/multi-select-input'

import usePageableState from '@trinsly/common/src/hooks/use-pageable-state'
import useGetProspects from 'api-hooks/use-get-prospects'
import { useAuth } from 'providers/auth-provider'
import ProspectType from 'types/prospect-type'

interface MultiSelectProspectsProps
  extends Omit<
    MultiSelectInputProps<ProspectType>,
    'itemRender' | 'options' | 'pending'
  > {}

function MultiSelectProspects(props: MultiSelectProspectsProps) {
  const { user } = useAuth()
  const { pageable, setSearch } = usePageableState({
    page: 1,
    perPage: 25,
    search: '',
  })

  const {
    data: prospects,
    pending,
    refetching,
  } = useGetProspects({
    ...pageable,
    filters: {
      creator: user?.id,
    },
  })

  return (
    <MultiSelectInput
      {...props}
      options={prospects}
      //onInputChange={(inputValue) => setSearch(inputValue)}
      itemRenderer={(item) => (
        <Column>
          <span className="text-sm">{item?.name || '--'}</span>
          {Boolean(item?.email) && (
            <span className="text-xs opacity-70">{item?.email}</span>
          )}
        </Column>
      )}
      pending={pending || refetching}
      filterWith={({
        inputValue,
        option,
      }: {
        inputValue: string
        option: ProspectType
      }) => {
        return option?.name
          ?.toLowerCase?.()
          .includes?.(inputValue?.toLowerCase?.())
      }}
    />
  )
}

export default MultiSelectProspects
