import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation } from 'react-query'
import deleteTrackerDomainService from 'services/settings/delete-tracker-domain-service'
import TrackerDomainType from 'types/tracker-domain-type'
import useGetTrackerDomains from './use-get-tracker-domains'

export type UseDeleteTrackerDomainSettingsProps =
  {} & MutateApiHookType<TrackerDomainType>

function useDeleteTrackerDomainSettings({
  onSuccess,
  onError,
  onMutate,
}: UseDeleteTrackerDomainSettingsProps) {
  const { refetch } = useGetTrackerDomains()
  const { mutate: fetch, isLoading: pending } = useMutation(
    deleteTrackerDomainService,
    {
      onSuccess: async (data) => {
        await refetch()
        onSuccess?.(data)
      },
      onError,
      onMutate,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useDeleteTrackerDomainSettings
