import { ReactComponent as DeleteSvg } from '@trinsly/common/src/assets/icons/delete.svg'
import Badge from '@trinsly/common/src/components/atoms/badge'
import Card from '@trinsly/common/src/components/atoms/card'
import Column from '@trinsly/common/src/components/atoms/column'
import Link from '@trinsly/common/src/components/atoms/link'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Table from '@trinsly/common/src/components/atoms/table'
import ButtonGroup from '@trinsly/common/src/components/atoms/tabs/button-group'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import IconButton from '@trinsly/common/src/components/molecules/icon-button'
import Pagination from '@trinsly/common/src/components/molecules/pagination'
import SearchInput from '@trinsly/common/src/components/molecules/search-input'
import NonIdealState from '@trinsly/common/src/components/organisms/non-ideal-state'
import usePageableState from '@trinsly/common/src/hooks/use-pageable-state'
import useDeleteCampaignFromProject from 'api-hooks/use-delete-campaigns-from-project'
import useGetProjectCampaigns from 'api-hooks/use-get-project-campaigns'
import moment from 'moment'
import React from 'react'
import { toast } from 'react-toastify'
import CampaignType from 'types/campaign-type'
import {
  calculateOpenedCount,
  campaignResponseRate,
} from '../../dashboard-screen/campaign-section'
import { useFormContext } from '../form-provider'
import TableViewModeEnum from '../table-view-mode-enum'
import LinkCampaignToProjectModal from './link-campaigns-to-project-modal'

type CampaignsSectionProps = {
  onViewModeChange: (tableViewMode: TableViewModeEnum) => void
}

function CampaignsSection({ onViewModeChange }: CampaignsSectionProps) {
  const [showAddCampaignModal, setShowAddCampaignModal] = React.useState(false)

  const { project } = useFormContext()
  const { pageable, setSearch, setSort, nextPage, prevPage } = usePageableState(
    {
      page: 1,
      perPage: 10,
    }
  )

  const {
    data: campaigns,
    total,
    pending,
  } = useGetProjectCampaigns({ projectId: project?.id || 0, ...pageable })

  const isNonIdealState = !pending && !campaigns.length

  const { fetch: deleteCampaign, pending: pendingDeleteCampaign } =
    useDeleteCampaignFromProject({
      onError: (error) => {
        toast.error(
          'Unable to delete campaign from project - please try again later'
        )
      },
      onSuccess: () => {
        toast.success('Deleted campaign from project')
      },
    })

  return (
    <Column className="gap-sm">
      <Row className="justify-between gap-sm">
        <Column className="w-full max-w-md">
          <SearchInput
            placeholder="Search"
            value={pageable?.search}
            onChange={(value) => setSearch(value)}
          />
        </Column>
        <Row className="items-center gap-sm whitespace-nowrap">
          <Button
            size="sm"
            variant="outline"
            intent="primary"
            onClick={() => {
              setShowAddCampaignModal(true)
            }}
          >
            Add Campaigns to Project
          </Button>
          <ButtonGroup
            variant="state"
            id="table-view-mode"
            defaultTab={TableViewModeEnum.CAMPAIGNS}
            onChange={(tabId) => onViewModeChange(tabId as TableViewModeEnum)}
          >
            <ButtonGroup.Button id={TableViewModeEnum.PROSPECTS}>
              Prospects
            </ButtonGroup.Button>
            <ButtonGroup.Button id={TableViewModeEnum.CAMPAIGNS}>
              Campaigns
            </ButtonGroup.Button>
          </ButtonGroup>
        </Row>
      </Row>
      <Card className="overflow-hidden">
        {isNonIdealState ? (
          <Column className="p-md">
            <NonIdealState message="No campaigns yet" />
          </Column>
        ) : (
          <React.Fragment>
            <Table
              pending={pending || pendingDeleteCampaign}
              data={campaigns as (CampaignType & { [index: string]: any })[]}
              columns={[
                {
                  width: 220,
                  header: <Column className="">Name (#of Stages)</Column>,
                  sort: pageable?.sort?.name,
                  onSort: (direction) => setSort('name', direction as any),
                  cell: (item) => {
                    return (
                      <Row>
                        <Link href={`/campaigns/${item?.id}`}>
                          <Text size="xs">
                            {item.templates
                              ? `${item.name} (${item.templates.length})`
                              : item.name}
                          </Text>
                        </Link>
                        <Separator size="xs" />
                        {item.isArchived && (
                          <Badge intent="warning">Archived</Badge>
                        )}
                      </Row>
                    )
                  },
                },
                {
                  width: 90,
                  header: 'Created',
                  align: 'right',
                  sort: pageable?.sort['created_at'],
                  onSort: (direction) =>
                    setSort('created_at', direction as any),
                  cell: (item) => (
                    <Text size="xs" fontWeight="light">
                      {moment(item.createdAt, moment.ISO_8601).format('M/D/YY')}
                    </Text>
                  ),
                },
                {
                  width: 90,
                  sort: pageable?.sort['stats.reach_out_count'],
                  onSort: (direction) =>
                    setSort('stats.reach_out_count', direction as any),
                  header: '# Reach outs',
                  align: 'right',
                  cell: (item) => (
                    <Text fontWeight="bold">
                      {item.stats?.reachOutCount ?? 0}
                    </Text>
                  ),
                },
                {
                  width: 100,
                  sort: pageable?.sort['stats.responses_count'],
                  onSort: (direction) =>
                    setSort('stats.responses_count', direction as any),
                  header: 'Responded',
                  align: 'right',
                  cell: (item) => (
                    <>
                      <Text fontWeight="bold">
                        {item.stats?.responsesCount ?? 0}
                      </Text>
                      &nbsp; &nbsp;
                      <Text size="xs" fontWeight="light">
                        (
                        {campaignResponseRate(
                          item.stats?.responsesCount,
                          item.stats?.reachOutCount
                        )}
                        )
                      </Text>
                    </>
                  ),
                },
                {
                  width: 100,
                  sort: pageable?.sort['stats.open_count'],
                  onSort: (direction) =>
                    setSort('stats.open_count', direction as any),
                  header: 'Opened',
                  align: 'right',
                  cell: (item) => (
                    <>
                      <Text fontWeight="bold">
                        {calculateOpenedCount(
                          item.stats?.openCount ?? 0,
                          item.stats?.responsesCount ?? 0
                        )}
                      </Text>
                      &nbsp; &nbsp;
                      <Text size="xs" fontWeight="light">
                        (
                        {campaignResponseRate(
                          calculateOpenedCount(
                            item.stats?.openCount ?? 0,
                            item.stats?.responsesCount ?? 0
                          ),
                          item.stats?.reachOutCount
                        )}
                        )
                      </Text>
                    </>
                  ),
                },
                {
                  width: 80,
                  sort: pageable?.sort['stats.in_progress_count'],
                  onSort: (direction) =>
                    setSort('stats.in_progress_count', direction as any),
                  header: 'In Progress',
                  align: 'right',
                  cell: (item) => (
                    <Text fontWeight="bold">
                      {item.stats?.inProgressCount ?? 0}
                    </Text>
                  ),
                },
                {
                  width: 100,
                  header: 'Actions',
                  cell: ({ id }) => {
                    return (
                      <Row className="items-center space-x-sm">
                        <IconButton
                          intent="danger"
                          onClick={() =>
                            deleteCampaign({
                              projectId: project?.id || -1,
                              campaignId: id || -1,
                            })
                          }
                          pending={pendingDeleteCampaign || pending}
                          variant="outline"
                        >
                          <DeleteSvg />
                        </IconButton>
                      </Row>
                    )
                  },
                },
              ]}
            />
            <Row className="items-center py-sm px-md justify-end">
              <Pagination
                page={pageable?.page}
                total={total}
                perPage={pageable?.perPage}
                onNext={nextPage}
                onPrev={prevPage}
              />
            </Row>
          </React.Fragment>
        )}
      </Card>
      <LinkCampaignToProjectModal
        visible={showAddCampaignModal}
        onRequestClose={() => setShowAddCampaignModal(false)}
        project={project}
      />
    </Column>
  )
}

export default CampaignsSection
