import Tag from '@trinsly/common/src/components/atoms/tag'
import React from 'react'
import ProjectStatusEnum, {
  translateProjectStatusEnum,
} from 'types/project-status-enum'
import ProjectType from 'types/project-type'

type StatusFieldProps = {
  status: ProjectType['status']
}

function StatusField({ status }: StatusFieldProps) {
  return (
    <React.Fragment>
      {
        {
          [ProjectStatusEnum.ACTIVE]: (
            <Tag textColor="white" backgroundColor="#00C853">
              {translateProjectStatusEnum[status]}
            </Tag>
          ),
          [ProjectStatusEnum.INACTIVE]: (
            <Tag textColor="white" backgroundColor="#F44336">
              {translateProjectStatusEnum[status]}
            </Tag>
          ),
          [ProjectStatusEnum.ARCHIVED]: (
            <Tag textColor="#7A7A7A" backgroundColor="#E3E3E3">
              {translateProjectStatusEnum[status]}
            </Tag>
          ),
          [ProjectStatusEnum.COMPLETE]: (
            <Tag textColor="white" backgroundColor="#696969">
              {translateProjectStatusEnum[status]}
            </Tag>
          ),
        }[status]
      }
    </React.Fragment>
  )
}

export default StatusField
