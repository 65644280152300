import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation } from 'react-query'
import updateSyncedUsersByIntegrationService, {
  UpdateSyncedUsersByIntegrationServiceProps,
} from 'services/update-synced-users-by-integration-service'
import useGetIntegration from './use-get-integration'

export type UseUpdateSyncedUsersByIntegrationProps = {
  integrationId: string
} & MutateApiHookType<any>

/**
 * Used for non-merge integrations
 * @param param0
 * @returns
 */
function useUpdateSyncedUsersByIntegration({
  integrationId,
  onSuccess,
  onError,
}: UseUpdateSyncedUsersByIntegrationProps) {
  const { refetch } = useGetIntegration({ integrationId })

  const { mutate: fetch, isLoading: pending } = useMutation(
    ({
      values,
    }: Omit<UpdateSyncedUsersByIntegrationServiceProps, 'integrationId'>) =>
      updateSyncedUsersByIntegrationService({ integrationId, values }),
    {
      onSuccess: async (data) => {
        await refetch()
        onSuccess?.(data)
      },
      onError,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useUpdateSyncedUsersByIntegration
