import cx from 'classnames'
import intlTelInput from 'intl-tel-input'
import 'intl-tel-input/build/css/intlTelInput.css'
import { ChangeEvent, FC, HTMLProps, useEffect, useRef, useState } from 'react'

const intlTelInputOptions = {
  customContainer: 'w-full',
  initialCountry: 'auto',
  onlyCountries: ['us', 'uk', 'ca', 'mx', 'de'],
  preferredCountries: [],
  utilsScript:
    '//cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.13/js/utils.min.js',
}

interface TelInputProps
  extends Omit<HTMLProps<HTMLInputElement>, 'value' | 'onChange' | 'disabled'> {
  disabled?: boolean
  value: string
  onChange: (value: string) => void
  onSetNumber: (value?: string) => void
  overrideDefaultCountries?: string[]
}

const TelInput: FC<TelInputProps> = ({
  disabled,
  value,
  onChange,
  onSetNumber,
  overrideDefaultCountries,
  ...props
}) => {
  const [isValid, setIsValid] = useState<boolean>()
  const inputRef = useRef<HTMLInputElement>(null)
  const itiRef = useRef<intlTelInput.Plugin>()

  function handleNumber() {
    const isValidNumber = itiRef.current?.isValidNumber() || false
    const number = itiRef.current?.getNumber() || ''
    setIsValid(isValidNumber)
    onSetNumber(isValidNumber ? number : '')
  }

  function handleCountryChange() {
    handleNumber()
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    if (onChange) onChange(event.target.value || '')
  }

  function checkUtils() {
    // using private info here, couldn't use iti.promise.then(callback) as documented
    // https://github.com/jackocnr/intl-tel-input/blob/master/src/js/intlTelInput.js#L1266
    if (window.intlTelInputUtils) {
      handleNumber()
    } else {
      window.setTimeout(checkUtils, 250)
    }
  }

  useEffect(() => {
    if (!inputRef.current) return

    const input = inputRef.current
    input.addEventListener('countrychange', handleCountryChange)

    itiRef.current = intlTelInput(inputRef.current, intlTelInputOptions)
    // itiRef.current.promise.then(callback) // couldn't get this to work, using a timeout instead:
    const timeoutId = window.setTimeout(checkUtils, 250)

    return () => {
      window.clearTimeout(timeoutId)
      itiRef.current?.destroy()
      input.removeEventListener('countrychange', handleCountryChange)
    }
  }, [])

  return (
    <input
      className={cx(
        'w-full sm:text-sm border-gray-300 min-h-xl py-0 text-sm text-gray-900 placeholder-gray-300 rounded-md shadow-sm transition',
        {
          'bg-success-50 bg-opacity-33 border-success-300 focus:border-success-300 focus:ring focus:ring-success-200':
            isValid && value.length > 0,
          'focus:border-primary-300 focus:ring focus:ring-primary-200 transition-all':
            value.length === 0,
          'bg-danger-50 bg-opacity-33 border-danger-300 focus:border-danger-300 focus:ring focus:ring-danger-200':
            !isValid && value.length > 0,
        }
      )}
      disabled={disabled}
      ref={inputRef}
      title={value}
      type="tel"
      value={value}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      onChange={(event) => {
        handleNumber()
        handleChange?.(event)
      }}
    />
  )
}

export default TelInput
