import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation, useQueryClient } from 'react-query'
import createCampaignService from 'services/create-campaign-service'
import CampaignType from 'types/campaign-type'

export type UseCreateCampaignProps = {} & MutateApiHookType<CampaignType>

function useCreateCampaign({ onSuccess, onError }: UseCreateCampaignProps) {
  const queryClient = useQueryClient()
  const { mutate: fetch, isLoading: pending } = useMutation(
    createCampaignService,
    {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries('/campaigns')
        onSuccess?.(data)
      },
      onError,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useCreateCampaign
