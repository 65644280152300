import Column from '@trinsly/common/src/components/atoms/column'
import Row from '@trinsly/common/src/components/atoms/row'
import Text from '@trinsly/common/src/components/atoms/text'
import React from 'react'
import { useFormContext } from '../form-provider'
import ResumePreview from './resume-preview'

export type ProfileDetailProps = {}

function ProfileDetail({}: ProfileDetailProps) {
  const { prospect } = useFormContext()
  let secondaryEmails = prospect?.emails || []
  secondaryEmails = secondaryEmails?.filter(
    (email) => email !== prospect?.email
  )
  const hasSecondaryEmails = secondaryEmails.length > 0

  return (
    <Column className="px-md gap-md">
      <Row className="flex-wrap gap-md">
        <Column className="min-w-28">
          <Text size="xs">First Name</Text>
          <Text size="sm" color="gray-900">
            {prospect?.firstName || '--'}
          </Text>
        </Column>
        <Column>
          <Text size="xs">Last Name</Text>
          <Text size="sm" color="gray-900">
            {prospect?.lastName || '--'}
          </Text>
        </Column>
      </Row>
      <Column>
        <Column className="gap-xs">
          <Text size="xs">E-mail</Text>
          {hasSecondaryEmails && (
            <span
              className="uppercase h-2 text-gray-500 tracking-wide"
              style={{ fontSize: '9px' }}
            >
              Primary
            </span>
          )}
          <Text size="sm" color="gray-900" className="leading-3">
            {prospect?.email || '--'}
          </Text>
        </Column>
        {hasSecondaryEmails && (
          <Column className="pt-sm gap-xs">
            <span
              className="uppercase h-2 text-gray-500 tracking-wide"
              style={{ fontSize: '9px' }}
            >
              Secondary
            </span>
            {secondaryEmails.map((email) => (
              <Text size="sm" color="gray-900" className="leading-3">
                {email || '--'}
              </Text>
            ))}
          </Column>
        )}
      </Column>
      <Column>
        <Text size="xs">Linkedin Name</Text>
        <Text size="sm" color="gray-900">
          {decodeURI(prospect?.linkedinName || '--')}
        </Text>
      </Column>
      <Column>
        <Text size="xs">Location</Text>
        <Text size="sm" color="gray-900">
          {[prospect?.city || '', prospect?.state || '', prospect?.zip || '']
            .filter(Boolean)
            .join(', ') || '--'}
        </Text>
      </Column>
      <ResumePreview />
    </Column>
  )
}

export default ProfileDetail
