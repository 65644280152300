import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation, useQueryClient } from 'react-query'

import deleteProspectFromProjectService from 'services/delete-prospect-from-project-service'

export type UseDeleteProspectFromProjectProps = {} & MutateApiHookType<void>

function useDeleteProspectFromProject({
  onMutate,
  onSuccess,
  onError,
}: UseDeleteProspectFromProjectProps) {
  const queryClient = useQueryClient()

  const { mutate: fetch, isLoading: pending } = useMutation(
    deleteProspectFromProjectService,
    {
      onSuccess: async (...args) => {
        queryClient.invalidateQueries('/projects/{id}')
        queryClient.invalidateQueries('/projects/{id}/reports/stats')
        queryClient.invalidateQueries('/prospects')
        onSuccess?.(...args)
      },
      onError,
      onMutate,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useDeleteProspectFromProject
