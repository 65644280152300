import useGetUserSettings from 'api-hooks/use-get-user-settings'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { FC, useEffect, useMemo, useState } from 'react'

interface LocalizedDateProps {
  utcDate: string | Date
  format?: string
}

const LocalizedDate: FC<LocalizedDateProps> = ({
  utcDate,
  format = 'ddd, M/D, h:mm a',
}) => {
  const [timeZoneOffset, setTimeZoneOffset] = useState(0)

  const { data: settings, pending: pendingSettings } = useGetUserSettings()

  useEffect(() => {
    if (!pendingSettings && !isEmpty(settings)) {
      setTimeZoneOffset(settings.timeZone.utcTotalOffset)
    }
  }, [settings, pendingSettings])

  const result = useMemo(() => {
    if (!timeZoneOffset) return moment.utc(utcDate).format(format)

    return dateToTimeZone({
      date: utcDate,
      timezoneOffset: timeZoneOffset,
    }).format(format)
  }, [utcDate, format, timeZoneOffset])

  return <span>{result}</span>
}

export default LocalizedDate

export function dateToTimeZone({
  date = new Date(),
  timezoneOffset,
}: {
  date?: string | Date
  timezoneOffset: number
}): moment.Moment {
  return moment.utc(date).add(timezoneOffset, 'seconds')
}
