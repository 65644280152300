import { Transition } from '@headlessui/react'
import React from 'react'
import Column from '../atoms/column'

export type SidebarProps = {
  children: React.ReactNode | React.ReactNodeArray
}

function Sidebar({ children }: SidebarProps) {
  const [visible, setVisible] = React.useState(false)

  return (
    <React.Fragment>
      <Column className="hidden md:flex md:flex-shrink-0 flex-1 max-w-[240px]">
        {children}
      </Column>
      <div className="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
        <button
          aria-label="Open sidebar"
          className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150"
          onClick={() => setVisible(true)}
        >
          <svg
            className="h-6 w-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              d="M4 6h16M4 12h16M4 18h16"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
          </svg>
        </button>
      </div>
      <Transition show={visible}>
        {(ref) => (
          <div className="md:hidden" ref={ref}>
            <div
              className="fixed inset-0 flex z-40"
              style={{ maxHeight: '100vh' }}
            >
              <Transition.Child
                as={React.Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                {(ref) => (
                  <div
                    className="fixed inset-0 bg-gray-600 opacity-75"
                    ref={ref}
                  />
                )}
              </Transition.Child>
              <Transition.Child
                as={React.Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                {(ref) => (
                  <div className="relative h-screen">
                    <div className="absolute top-0 right-0 -mr-14 p-1">
                      <button
                        aria-label="Close sidebar"
                        className="flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-gray-600"
                        onClick={() => setVisible(false)}
                      >
                        <svg
                          className="h-6 w-6 text-white"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            d="M6 18L18 6M6 6l12 12"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                          />
                        </svg>
                      </button>
                    </div>
                    <div
                      className="flex flex-col h-screen max-w-xs bg-white overflow-auto"
                      ref={ref}
                    >
                      {children}
                    </div>
                  </div>
                )}
              </Transition.Child>
              <div className="flex-shrink-0 w-14">
                {/* Force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </div>
        )}
      </Transition>
    </React.Fragment>
  )
}

export default Sidebar
