import api from '@trinsly/common/src/services/api'
import ProspectType from 'types/prospect-type'

export type GetProspectServiceProps = {
  prospectId: ProspectType['id']
}

async function getProspectService({ prospectId }: GetProspectServiceProps) {
  const response = await api(`/prospects/${prospectId}`, { method: 'GET' })
  return response as ProspectType
}

export default getProspectService
