import { useVirtualizer } from '@tanstack/react-virtual'
import { ReactComponent as InfoOutlineSvg } from '@trinsly/common/src/assets/icons/info-outline.svg'
import Clickable from '@trinsly/common/src/components/atoms/clickable'
import Column from '@trinsly/common/src/components/atoms/column'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Row from '@trinsly/common/src/components/atoms/row'
import Text from '@trinsly/common/src/components/atoms/text'
import Alert from '@trinsly/common/src/components/molecules/alert'
import { compact } from 'lodash'
import React from 'react'
import CampaignType from 'types/campaign-type'
import { useDraftContext } from '../draft-provider'
import { useFormContext } from '../form-provider'
import EmailFormListItem from './email-form-list-item'

type CustomizeEmailsSectionProps = {}

function CustomizeEmailsSection({}: CustomizeEmailsSectionProps) {
  const { getValue, getValues, setValue, setValues, getError } =
    useFormContext()

  const [selectedProspectRow, setSelectedProspectRow] =
    React.useState<string[]>()

  const csv = getValue('csv') as string[][] | undefined
  const emailKeyIndex = getValue('variables[Email]')
  const prospectsRows = csv
    ?.slice(1)
    ?.filter?.((prospectRow) => Boolean(prospectRow?.[emailKeyIndex]))
  const campaign = getValue('campaign') as CampaignType | undefined
  const variablesMapAsString = Object.keys(
    getValue('variables') || {}
  )?.reduce?.((acc, key) => `${acc} ${key}:${getValue('variables')?.[key]}`, '')
  const csvSize = prospectsRows?.reduce?.(
    (acc, prospectRow) =>
      acc +
      (prospectRow?.reduce?.((acc, cell) => acc + (cell?.length || 0), 0) || 0),
    0
  )
  const templateValuesAsString = campaign?.templates?.reduce?.(
    (acc, template) => `${acc} ${template?.subject} ${template?.body}`,
    ''
  )

  // The scrollable element for your list
  const parentRef = React.useRef<HTMLDivElement>(null)

  // The virtualizer
  const rowVirtualizer = useVirtualizer({
    count: prospectsRows?.length || 0,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 38,
    overscan: 20,
  })

  function replaceAllCustomThreadsWithCampaignTemplatesFilledWithVariables() {
    const emailKeyIndex = getValue('variables[Email]')
    const custom = prospectsRows?.reduce?.((acc, prospectRow) => {
      const email = prospectRow?.[emailKeyIndex]
      const emailWithoutDots = email?.replaceAll?.('.', '@@')
      const mappedVariableKeys = Object.keys(getValue('variables') || {})
      const templatesFilledWithVariables = campaign?.templates?.map?.(
        (template, index) => {
          const filledSubject: string = mappedVariableKeys?.reduce?.(
            (acc, mappedVariable) => {
              const mappedCsvIndex = getValue(`variables[${mappedVariable}]`)
              const newAcc = acc?.replaceAll?.(
                `{{${mappedVariable}}}`,
                prospectRow[mappedCsvIndex] || ''
              )
              return newAcc
            },
            template?.subject || ''
          )
          const filledBody = mappedVariableKeys?.reduce?.(
            (acc, mappedVariable) => {
              const mappedCsvIndex = getValue(`variables[${mappedVariable}]`)
              const newAcc = acc?.replaceAll?.(
                `{{${mappedVariable}}}`,
                prospectRow[mappedCsvIndex] || ''
              )
              return newAcc
            },
            template?.body || ''
          )

          return {
            ...template,
            initial: {
              subject: filledSubject,
              body: filledBody,
            },
            subject: filledSubject,
            body: filledBody,
            expanded: index === 0,
          }
        }
      )

      return {
        ...acc,
        [emailWithoutDots]: {
          threads: templatesFilledWithVariables,
          variables: compact(
            mappedVariableKeys?.map((mappedVariable) => {
              const mappedCsvIndex = getValue(`variables[${mappedVariable}]`)
              const hasValue = Boolean(prospectRow?.[mappedCsvIndex])
              if (!hasValue) {
                return
              }

              return {
                key: mappedVariable,
                value: prospectRow?.[mappedCsvIndex],
              }
            })
          ),
        },
      }
    }, {})

    setValue('custom', custom)
  }

  const { status: draftStatus } = useDraftContext()
  React.useEffect(() => {
    if (draftStatus === 'fetched') {
      setTimeout(() => {
        replaceAllCustomThreadsWithCampaignTemplatesFilledWithVariables()
      }, 100)
    }
  }, [draftStatus])

  //autoreplace all custom threads with campaign templates and fill variables for each prospect in csv
  //when any campaign subject or body changes
  //when any mapped variable changes
  //when any csv data changes
  React.useEffect(() => {
    replaceAllCustomThreadsWithCampaignTemplatesFilledWithVariables()
  }, [templateValuesAsString, variablesMapAsString, csvSize])

  //autoselects first prospect in csv
  //when any csv data changes
  //when mapped variable changes
  React.useEffect(() => {
    setSelectedProspectRow(prospectsRows?.[0])
  }, [csvSize, variablesMapAsString])

  const allowCustomization = Boolean(csv) && getValue('variables[Email]') >= 0

  return (
    <Column className="px-lg gap-md">
      <Heading size="lg" fontWeight="bold">
        Finalize
      </Heading>
      {allowCustomization ? (
        <Row className="gap-md">
          <Column className="w-96 gap-sm">
            <Text size="sm">Select an email</Text>
            <Column
              ref={parentRef}
              className="overflow-auto gap-sm border border-gray-200"
              style={{ maxHeight: 660 }}
            >
              <Column
                style={{
                  height: rowVirtualizer.getTotalSize(),
                }}
              >
                <Column
                  style={{
                    height: rowVirtualizer.getTotalSize(),
                    position: 'relative',
                  }}
                >
                  {rowVirtualizer.getVirtualItems().map((virtualItem) => {
                    const prospectRow = prospectsRows?.[virtualItem.index]
                    const email = prospectRow?.[emailKeyIndex]
                    const classes =
                      'px-sm py-1 items-center text-sm truncate border-b-2 border-t-2 border-white focus:ring-inset'
                    const defaultClasses = 'bg-gray-50 text-gray-500'
                    const activeClasses =
                      'bg-primary-500 text-white hover:bg-primary-900'
                    const isActive =
                      selectedProspectRow?.[emailKeyIndex] === email
                    return (
                      <Clickable
                        key={virtualItem.key}
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: virtualItem.size,
                          transform: `translateY(${virtualItem.start}px)`,
                        }}
                        onClick={() => setSelectedProspectRow(prospectRow)}
                        className={[
                          classes,
                          isActive ? activeClasses : defaultClasses,
                        ].join(' ')}
                      >
                        {email?.trim?.() || '--'}
                      </Clickable>
                    )
                  })}
                </Column>
              </Column>
            </Column>
          </Column>
          {selectedProspectRow && (
            <EmailFormListItem
              getValue={getValue}
              setValue={setValue}
              getValues={getValues}
              setValues={setValues}
              getError={getError}
              prospectRow={selectedProspectRow}
            />
          )}
        </Row>
      ) : (
        <Alert intent="warning" icon={<InfoOutlineSvg />}>
          <Text>
            Upload a csv and make sure the Email variable is mapped to a column.
          </Text>
        </Alert>
      )}
    </Column>
  )
}

export default CustomizeEmailsSection
