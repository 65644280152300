import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation, useQueryClient } from 'react-query'
import deleteProspectResumeService from 'services/delete-prospect-resume-service'

type UseDeleteProspectResumeProps = {} & MutateApiHookType<void>
function useDeleteProspectResume({
  onMutate,
  onSuccess,
  onError,
}: UseDeleteProspectResumeProps) {
  const queryClient = useQueryClient()

  const { mutate: fetch, isLoading: pending } = useMutation(
    deleteProspectResumeService,
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('/prospects/{prospectId}')
        await queryClient.invalidateQueries('/prospects/{prospectId}/resume')
        onSuccess?.()
      },
      onError,
      onMutate,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useDeleteProspectResume
