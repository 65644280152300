import api from '@trinsly/common/src/services/api'
import PageableType from '@trinsly/common/src/types/pageable-type'
import { pickBy } from 'lodash'

type GetTagsServiceProps = {} & PageableType

async function getTagsService({ ...pageable }: GetTagsServiceProps) {
  const response = await api(`/tags`, {
    method: 'GET',
    params: pickBy({
      criteria: pageable?.search,
    }),
  })

  const remoteTags = response as { id: number; name: string }[]
  const tags = remoteTags?.map((tag) => tag?.name)
  return tags
}

export default getTagsService
