import { ReactComponent as CloseSvg } from '@trinsly/common/src/assets/icons/close.svg'
import Badge from '@trinsly/common/src/components/atoms/badge'
import Card from '@trinsly/common/src/components/atoms/card'
import Clickable from '@trinsly/common/src/components/atoms/clickable'
import Column from '@trinsly/common/src/components/atoms/column'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Link from '@trinsly/common/src/components/atoms/link'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Table from '@trinsly/common/src/components/atoms/table'
import ButtonGroup from '@trinsly/common/src/components/atoms/tabs/button-group'
import Text from '@trinsly/common/src/components/atoms/text'
import Pagination from '@trinsly/common/src/components/molecules/pagination'
import SearchInput from '@trinsly/common/src/components/molecules/search-input'
import NonIdealState from '@trinsly/common/src/components/organisms/non-ideal-state'
import usePageableState from '@trinsly/common/src/hooks/use-pageable-state'
import useGetCampaigns from 'api-hooks/use-get-campaigns'
import useGetUserSession from 'api-hooks/use-get-user-session'
import { compact } from 'lodash'
import moment from 'moment'
import { useAuth } from 'providers/auth-provider'
import React from 'react'
import { CampaignFilterScopeEnum } from 'services/get-campaigns-service'
import CampaignType from 'types/campaign-type'
import ArchiveCampaignButton from '../campaigns-screen/archive-campaign-button'
import CampaignExpandedRow from '../campaigns-screen/campaign-expanded-row'
import DuplicateCampaignButton from '../campaigns-screen/duplicate-campaign-button'
import ReportingsCampaignButton from '../campaigns-screen/reportings-campaign-button'
import ShareCampaignButton from '../campaigns-screen/share-campaign-button'

type CampaignsSectionProps = {}

function CampaignsSection({}: CampaignsSectionProps) {
  const { user } = useAuth()

  const { pageable, setFilters, setSort, setSearch, nextPage, prevPage } =
    usePageableState({
      page: 1,
      perPage: 10,
      sort: { created_at: 'DESC' },
    })

  const { data: currentUser, pending: userPending } = useGetUserSession({})
  const { data: campaigns, total, pending } = useGetCampaigns(pageable)

  const tags = compact(pageable?.filters?.tags?.split(',') || []) as string[]
  const hasData = pending || (!pending && campaigns.length > 0)
  const isSharedState =
    pageable?.filters?.state === CampaignFilterScopeEnum.SHARED

  return (
    <Column>
      <Row className="flex-1 items-center">
        <Heading size="xl" fontWeight="bold">
          Campaigns
        </Heading>
        <Row className="flex-1 justify-end">
          <Separator />
          <Column className="flex-1">
            <Column className="flex-1" style={{ maxWidth: 360 }}>
              <SearchInput
                value={pageable.search}
                placeholder="Search your campaigns..."
                onChange={(value) => setSearch(value)}
              />
            </Column>
          </Column>
          {tags.length > 0 && (
            <React.Fragment>
              <Separator />
              <Row className="items-center space-x-xs">
                <Text size="sm" fontWeight="bold">
                  Filter tags:
                </Text>
                {tags.map((tag: any, index: number) => (
                  <Clickable
                    key={index}
                    onClick={() => {
                      const currentTags: string[] =
                        pageable?.filters?.tags?.split(',') || []
                      setFilters(
                        'tags',
                        currentTags
                          .filter((currentTag) => currentTag !== tag)
                          .join(',')
                      )
                    }}
                  >
                    <Badge size="xs" intent="primary">
                      <Row className="items-center">
                        {tag}
                        <Separator size="xs" />
                        <CloseSvg className="w-4 h-4" />
                      </Row>
                    </Badge>
                  </Clickable>
                ))}
              </Row>
            </React.Fragment>
          )}
          <Separator />
          <ButtonGroup
            variant="state"
            id="last-campaigns-sent-state"
            defaultTab={CampaignFilterScopeEnum.MINE}
            onChange={(tabId) => {
              setFilters('state', tabId)
            }}
          >
            <ButtonGroup.Button id={CampaignFilterScopeEnum.MINE}>
              Mine
            </ButtonGroup.Button>
            <ButtonGroup.Button id={CampaignFilterScopeEnum.SHARED}>
              Shared
            </ButtonGroup.Button>
            <ButtonGroup.Button id={CampaignFilterScopeEnum.ARCHIVED}>
              Archived
            </ButtonGroup.Button>
          </ButtonGroup>
        </Row>
      </Row>

      <Separator />
      <Card className="overflow-hidden">
        {!hasData ? (
          <Column className="p-md">
            <NonIdealState message="No campaigns sent yet" />
          </Column>
        ) : (
          <React.Fragment>
            <Table
              pageSize={pageable?.perPage}
              pending={pending || userPending}
              data={campaigns as (CampaignType & { [index: string]: any })[]}
              columns={[
                {
                  width: 220,
                  header: <Column className="">Name (#of Stages)</Column>,
                  sort: pageable?.sort?.name,
                  onSort: (direction) => setSort('name', direction as any),
                  cell: (item) => {
                    return (
                      <Row className="items-center flex-wrap-reverse gap-xs">
                        <Link href={`/campaigns/${item.id}`}>
                          <Text size="xs">
                            {item.templates
                              ? `${item.name} (${item.templates.length})`
                              : item.name}
                          </Text>
                        </Link>
                        {item.isArchived && (
                          <Badge size="xs" intent="warning">
                            Archived
                          </Badge>
                        )}
                      </Row>
                    )
                  },
                },
                {
                  width: 90,
                  header: 'Created',
                  align: 'right',
                  sort: pageable?.sort?.['created_at'],
                  onSort: (direction) =>
                    setSort('created_at', direction as any),
                  cell: (item) => (
                    <Text size="xs" fontWeight="light">
                      {moment(item.createdAt, moment.ISO_8601).format('M/D/YY')}
                    </Text>
                  ),
                },
                isSharedState
                  ? {
                      width: 150,
                      sort: pageable?.sort?.ownerName,
                      onSort: (direction) =>
                        setSort('ownerName', direction as any),
                      header: 'Author',
                      cell: (item) => item.ownerName,
                    }
                  : null,
                {
                  width: 90,
                  sort: pageable?.sort?.['stats.reach_out_count'],
                  onSort: (direction) =>
                    setSort('stats.reach_out_count', direction as any),
                  header: '# Reach outs',
                  align: 'right',
                  cell: (item) => (
                    <Text fontWeight="bold">
                      {item.stats?.reachOutCount ?? 0}
                    </Text>
                  ),
                },
                {
                  width: 100,
                  sort: pageable?.sort?.['stats.responses_count'],
                  onSort: (direction) =>
                    setSort('stats.responses_count', direction as any),
                  header: 'Responded',
                  align: 'right',
                  cell: (item) => (
                    <>
                      <Text fontWeight="bold">
                        {item.stats?.responsesCount ?? 0}
                      </Text>
                      &nbsp; &nbsp;
                      <Text size="xs" fontWeight="light">
                        (
                        {campaignResponseRate(
                          item.stats?.responsesCount,
                          item.stats?.reachOutCount
                        )}
                        )
                      </Text>
                    </>
                  ),
                },
                {
                  width: 100,
                  sort: pageable?.sort?.['stats.open_count'],
                  onSort: (direction) =>
                    setSort('stats.open_count', direction as any),
                  header: 'Opened',
                  align: 'right',
                  cell: (item) => (
                    <>
                      <Text fontWeight="bold">
                        {calculateOpenedCount(
                          item.stats?.openCount ?? 0,
                          item.stats?.responsesCount ?? 0
                        )}
                      </Text>
                      &nbsp; &nbsp;
                      <Text size="xs" fontWeight="light">
                        (
                        {campaignResponseRate(
                          calculateOpenedCount(
                            item.stats?.openCount ?? 0,
                            item.stats?.responsesCount ?? 0
                          ),
                          item.stats?.reachOutCount
                        )}
                        )
                      </Text>
                    </>
                  ),
                },
                {
                  width: 80,
                  sort: pageable?.sort?.['stats.in_progress_count'],
                  onSort: (direction) =>
                    setSort('stats.in_progress_count', direction as any),
                  header: 'In Progress',
                  align: 'right',
                  cell: (item) => (
                    <Text fontWeight="bold">
                      {item.stats?.inProgressCount ?? 0}
                    </Text>
                  ),
                },
                {
                  cell: (item) => (
                    <Row className="space-x-xs">
                      {(item.tagList || []).map((tag, index) => (
                        <Clickable
                          key={index}
                          onClick={() => {
                            const currentTags: string[] =
                              pageable?.filters?.tags?.split(',') || []
                            const exists = currentTags.some(
                              (existingTag: string) => existingTag === tag
                            )
                            if (!exists) {
                              setFilters(
                                'tags',
                                [...currentTags, tag].join(',')
                              )
                            }
                          }}
                        >
                          <Badge size="xs" intent="primary">
                            {tag}
                          </Badge>
                        </Clickable>
                      ))}
                    </Row>
                  ),
                },
                {
                  cell: (item) => {
                    const isMine = item.ownerId === currentUser.id
                    return (
                      <Row className="items-center space-x-sm">
                        <ReportingsCampaignButton
                          campaign={item}
                          disabled={false}
                        />
                        {isMine && (
                          <ShareCampaignButton
                            disabled={!Boolean(user?.organizationId)}
                            campaign={item}
                          />
                        )}
                        {isSharedState && !isMine && (
                          <DuplicateCampaignButton
                            campaign={item}
                            disabled={false}
                          />
                        )}
                        {!isSharedState && (
                          <ArchiveCampaignButton
                            campaign={item}
                            disabled={false}
                          />
                        )}
                      </Row>
                    )
                  },
                },
              ]}
              expandedRow={(item) => (
                <Column>
                  <CampaignExpandedRow campaign={item} />
                </Column>
              )}
            />
            <Row className="items-center py-sm px-md justify-end">
              <Pagination
                total={total || 0}
                page={pageable?.page}
                perPage={pageable?.perPage}
                onPrev={prevPage}
                onNext={nextPage}
              />
            </Row>
          </React.Fragment>
        )}
      </Card>
    </Column>
  )
}

export default CampaignsSection

/**
 * We cannot allow open rate to be less than response rate
 * @param stats
 * @returns
 */
export const calculateOpenedCount = (
  opens: number,
  responses: number
): number => {
  if (responses > opens) return responses

  return opens
}

export const campaignResponseRate = (
  count: number | undefined,
  totalReachOuts: number | undefined
): string => {
  if (!count || !totalReachOuts) return '0%'
  if (totalReachOuts <= 0) return '0%'
  return `${((count / totalReachOuts) * 100).toFixed(0)}%`
}

export const zeroNotNaN = (value: any) => {
  if (isNaN(value)) return 0
  else return value
}
