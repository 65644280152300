import { ReactComponent as EditSvg } from '@trinsly/common/src/assets/icons/edit.svg'
import AvatarLetter from '@trinsly/common/src/components/atoms/avatar-letter'
import Column from '@trinsly/common/src/components/atoms/column'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Text from '@trinsly/common/src/components/atoms/text'
import IconButton from '@trinsly/common/src/components/molecules/icon-button'
import React from 'react'
import { useFormContext } from '../form-provider'
import ProfileDetail from './profile-detail'
import ProfileForm from './profile-form'
import ProfileLinks from './profile-links'

export type ProfileSectionProps = {}

function ProfileSection() {
  const [viewState, setViewState] = React.useState<'detail' | 'form'>('detail')
  const { prospect } = useFormContext()
  const initials = prospect?.firstName?.[0] || ''

  return (
    <Column>
      <Column className="min-h-24 bg-gray-100 justify-center items-center">
        <Row className="items-center px-md gap-3">
          <AvatarLetter initials={initials} />
          <Column>
            <Text
              fontWeight="bold"
              color="gray-900"
              className="leading-5 text-ellipsis overflow-hidden"
            >
              {prospect?.name || '--'}
            </Text>
            <Text size="xs">{prospect?.email || '--'}</Text>
          </Column>
        </Row>
      </Column>
      <Column className="-mt-3">
        <ProfileLinks />
      </Column>
      <Separator />
      <Row className="px-md items-center justify-between min-h-md">
        <Text size="xs" fontWeight="bold" className="uppercase">
          About
        </Text>
        {viewState === 'detail' && (
          <React.Fragment>
            <Separator />
            <IconButton
              size="xs"
              shape="circle"
              variant="transparent"
              onClick={() => setViewState('form')}
            >
              <EditSvg />
            </IconButton>
          </React.Fragment>
        )}
      </Row>
      <Separator />
      {
        {
          form: <ProfileForm onRequestClose={() => setViewState('detail')} />,
          detail: <ProfileDetail />,
        }[viewState]
      }
      <Separator size="xl" />
    </Column>
  )
}

export default ProfileSection
