import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation, useQueryClient } from 'react-query'
import createProspectEducationService from 'services/create-prospect-education-service'
import ProspectType from 'types/prospect-type'

type UseCreateProspectEducationProps = {} & MutateApiHookType<
  ProspectType['educations'][number]
>

function useCreateProspectEducation({
  onMutate,
  onSuccess,
  onError,
}: UseCreateProspectEducationProps) {
  const queryClient = useQueryClient()

  const { mutate: fetch, isLoading: pending } = useMutation(
    createProspectEducationService,
    {
      onSuccess: async (...args) => {
        await queryClient.invalidateQueries(
          '/prospects/{prospectId}/educations'
        )
        onSuccess?.(...args)
      },
      onError,
      onMutate,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useCreateProspectEducation
