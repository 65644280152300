import api from '@trinsly/common/src/services/api'
import { decamelizeKeys } from 'humps'
import IntegrationType from 'types/integration-type'

type CreateIntegrationServiceProps = {
  integration: IntegrationType
}

async function createIntegrationService({
  integration,
}: CreateIntegrationServiceProps) {
  const response = (await api(`/integrations`, {
    body: { integration: decamelizeKeys(integration) },
    method: 'POST',
  })) as any
  return response
}

export default createIntegrationService
