const colors = {
  TEAL: '#00BCD4',
  BLUE: '#407BFF',
  INDIGO: '#5946C8',
  SALMON: '#FF6565',
  YELLOW: '#F4B71A',
  RED: '#F44336',
  GREEN: '#00C853',
}

export default colors
