import { retrieveCredentials } from '@trinsly/common/src/services/api'
import axios from 'axios'

type SyncCandidatesFromMergeSingleIntegrationServiceProps = {
  integrationId: Integration['id']
  prospectId: number
}

async function saveProspectToMergeIntegrationService({
  integrationId,
  prospectId,
}: SyncCandidatesFromMergeSingleIntegrationServiceProps) {
  const { accessToken } = retrieveCredentials()
  const response = await axios.request({
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${process.env.REACT_APP_MERGE_API_URL}/candidates/${prospectId}/sync`,
    data: { integration: integrationId },
  })

  return response?.data as any
}

export default saveProspectToMergeIntegrationService
