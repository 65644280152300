import { ReactComponent as PhoneIcon } from '@trinsly/common/src/assets/icons/phone.svg'
import Clickable from '@trinsly/common/src/components/atoms/clickable'
import Column from '@trinsly/common/src/components/atoms/column'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import useGetUserSettings from 'api-hooks/use-get-user-settings' /*
import { ReactComponent as EmailIcon } from '@trinsly/common/src/assets/icons/e-mail.svg' */
import React from 'react'
import SetupOrganizationFormModal from './setup-organization-form-modal'
export type OrganizationDetailsSectionProps = {}

const OrganizationDetailsSection = ({}: OrganizationDetailsSectionProps) => {
  const [hasOrganization, setHasOrganization] = React.useState(false)
  const [showOrgSetupModal, setShowOrgSetupModal] = React.useState(false)
  const { data: settings, pending: pendingSettings } = useGetUserSettings()

  React.useEffect(() => {
    if (!pendingSettings && settings) {
      settings.organization?.autoCreated
        ? setHasOrganization(false)
        : setHasOrganization(true)
    }
  }, [pendingSettings, settings])

  return (
    <>
      <Column>
        <Heading
          size="sm"
          fontWeight="bold"
          color="gray-500"
          className="uppercase"
        >
          Organization
        </Heading>
        <Separator size="xs" />
        {hasOrganization ? (
          <Column>
            <Text size="sm">
              You are part of {settings.organization?.name || ''}.
            </Text>
            {/* <Row className="items-center space-x-3"> // TODO: Add email
                  <EmailIcon className="w-sm h-sm" />
                  <Text>{`{settings.organization?.email || ' '}`}</Text>
                </Row> */}
            <Separator size="xs" />
            <Row className="items-center justify-between">
              <Row className="items-center space-x-3">
                <PhoneIcon className="w-md h-md" />
                <Text size="sm">
                  {settings.organization?.phone || (
                    <Clickable onClick={() => setShowOrgSetupModal(true)}>
                      <Text className="text-primary-700 hover:text-primary-300 underline">
                        add phone number
                      </Text>
                    </Clickable>
                  )}
                </Text>
              </Row>
              <Column className="min-w-72">
                <Button
                  size="sm"
                  shape="rounded"
                  variant="outline"
                  intent="primary"
                  onClick={() => setShowOrgSetupModal(true)}
                >
                  UPDATE ORGANIZATION DETAILS
                </Button>
              </Column>
            </Row>
          </Column>
        ) : (
          <Column className="items-start">
            <Text size="sm">
              You currently are not part of an organization.
            </Text>
            <Separator size="xs" />
            <Column className="min-w-64">
              <Button
                size="sm"
                shape="rounded"
                variant="outline"
                intent="primary"
                onClick={() => setShowOrgSetupModal(true)}
              >
                CREATE AN ORGANIZATION
              </Button>
            </Column>
          </Column>
        )}
      </Column>
      <SetupOrganizationFormModal
        visible={showOrgSetupModal}
        onRequestClose={() => setShowOrgSetupModal(false)}
        organization={settings.organization}
        isEdit={hasOrganization}
      />
    </>
  )
}

export default OrganizationDetailsSection
