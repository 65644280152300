import api from '@trinsly/common/src/services/api'

export type CreateUser2faServiceProps = {
  phoneNumber: string
}

export default async function createUser2faService({
  phoneNumber,
}: CreateUser2faServiceProps) {
  const response = await api('/two_factor_auths', {
    method: 'POST',
    body: {
      phone_number: phoneNumber,
    },
  })

  return response
}
