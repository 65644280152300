import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation, useQueryClient } from 'react-query'
import stopAllReachOutsService from 'services/stop-all-reach-outs-service'
import CampaignType from 'types/campaign-type'

type UseStopAllReachOutsProps = {} & MutateApiHookType<CampaignType[]>

function useStopAllReachOuts({
  onMutate,
  onSuccess,
  onError,
}: UseStopAllReachOutsProps) {
  const queryClient = useQueryClient()

  const { mutate: fetch, isLoading: pending } = useMutation(
    stopAllReachOutsService,
    {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries('/threads')
        onSuccess?.(data)
      },
      onError,
      onMutate,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useStopAllReachOuts
