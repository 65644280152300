import Column from '@trinsly/common/src/components/atoms/column'
import Divider from '@trinsly/common/src/components/atoms/divider'
import Separator from '@trinsly/common/src/components/atoms/separator'
import React from 'react'
import OrganizationDetailsSection from './organization-details-section'
import TeamMembersSection from './team-members-section'

export type OrganizationSubTabContentProps = {}

const OrganizationSubtab = ({}: OrganizationSubTabContentProps) => {
  return (
    <>
      <Column>
        <OrganizationDetailsSection />
        <Separator size="lg" />
        <Divider />
        <Separator size="lg" />
        <TeamMembersSection />
      </Column>
    </>
  )
}
export default OrganizationSubtab
