import api from '@trinsly/common/src/services/api'
import IntegrationType from 'types/integration-type'

type DeleteIntegrationServiceProps = {
  integrationId: IntegrationType['id']
}

async function deleteIntegrationService({
  integrationId,
}: DeleteIntegrationServiceProps) {
  const response = (await api(`/integrations/${integrationId}`, {
    method: 'DELETE',
  })) as any
  return response
}

export default deleteIntegrationService
