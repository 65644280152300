import SelectInput from '@trinsly/common/src/components/molecules/select-input'
import { ProspectStatusEnum } from 'types/prospect-type'

type StatusFilter = {
  onChange: (value: ProspectStatusEnum | null) => void
  value: ProspectStatusEnum | null
}

function StatusFilter({ onChange, value }: StatusFilter) {
  return (
    <SelectInput
      label="I want to look at prospects that are"
      placeholder="Select a status"
      onChange={onChange}
      value={value}
      options={options}
      itemRenderer={(item) => translate[item]}
    />
  )
}

export default StatusFilter

const options = [
  ProspectStatusEnum.ACTIVE,
  ProspectStatusEnum.DO_NOT_CONTACT,
  ProspectStatusEnum.HARD_BOUNCED,
  ProspectStatusEnum.INACTIVE,
]

const translate: any = {
  [ProspectStatusEnum.ACTIVE]: 'Status active',
  [ProspectStatusEnum.HARD_BOUNCED]: 'Status hard-bounced',
  [ProspectStatusEnum.DO_NOT_CONTACT]: 'Status do not contact',
  [ProspectStatusEnum.INACTIVE]: 'Status inactive',
}
