import React from 'react'
import { Theme } from '../../types/theme-type'

export interface BadgeProps {
  intent?: Theme['intent']
  children?: React.ReactText | React.ReactNode
  size?: Theme['size']
}
function Badge({ size = 'md', intent, children }: BadgeProps) {
  return (
    <span
      className={`${badgeClasses} ${intentMap[intent || 'undefined']} ${
        textSizeClassMap[size]
      }`}
    >
      {children}
    </span>
  )
}

const badgeClasses = `inline-flex text-center px-2.5 py-0.5 rounded-full font-bold whitespace-nowrap`

const textSizeClassMap = {
  xs: `text-xs`,
  sm: `text-xs`,
  md: `text-sm`,
  lg: `text-md`,
  xl: `text-lg`,
}

const intentMap = {
  primary: 'bg-primary-50 text-primary-400',
  warning: 'bg-warning-50 text-warning-500',
  success: 'bg-success-50 text-success-500',
  danger: 'bg-danger-50 text-danger-500',
  info: 'bg-info-50 text-info-500',
  undefined: 'bg-gray-200 text-gray-500',
}

export default Badge
