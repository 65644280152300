import api from '@trinsly/common/src/services/api'

export type CreateApiKeyServiceProps = {
  userId: number
}

async function createApiKeyService({ userId }: CreateApiKeyServiceProps) {
  try {
    const response = await api(`/users/${userId}/reset_api_key`, {
      method: 'POST',
    })
    return response
  } catch (error) {
    throw error
  }
}
export default createApiKeyService
