export interface SeparatorProps {
  size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl'
}

function Separator({ size = 'md' }: SeparatorProps) {
  return <div className={`${sizeClassMap[size]}`} />
}

export default Separator

const sizeClassMap = {
  xxs: `min-w-1 min-h-1`,
  xs: `min-w-xs min-h-xs`,
  sm: `min-w-sm min-h-sm`,
  md: `min-w-md min-h-md`,
  lg: `min-w-lg min-h-lg`,
  xl: `min-w-xl min-h-xl`,
}
