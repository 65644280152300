import { ReactComponent as ArchiveSvg } from '@trinsly/common/src/assets/icons/archive.svg'
import { ReactComponent as UnarchiveSvg } from '@trinsly/common/src/assets/icons/unarchive.svg'
import Column from '@trinsly/common/src/components/atoms/column'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Spinner from '@trinsly/common/src/components/atoms/spinner'
import Table from '@trinsly/common/src/components/atoms/table'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import Select from '@trinsly/common/src/components/molecules/select'
import useDeactivateOrganizationMember from 'api-hooks/use-deactivate-organization-member'
import useGetUserSession from 'api-hooks/use-get-user-session'
import useGetUserSettings from 'api-hooks/use-get-user-settings'
import useUpdateMembership from 'api-hooks/use-update-membership'
import React from 'react'
import { OrganizationMember } from 'types/settings-type'
import AddNewUserModal from './add-new-user-modal'

export type TeamMembersTableProps = {}

const TeamMembersTable = ({}: TeamMembersTableProps) => {
  const [toggleMemberStatusMap, setToggleMemberStatusMap] = React.useState<any>(
    {}
  )
  const [seats, setSeats] = React.useState({ used: 0, allowed: 0 })
  const [showAddNewUserModal, setShowAddNewUserModal] = React.useState(false)
  const { data: currentUser, pending: currentUserPending } = useGetUserSession(
    {}
  )
  const { data: settings, pending: settingsPending } = useGetUserSettings()
  const { fetch: toggleActive } = useDeactivateOrganizationMember({
    onMutate: (member) => {
      setToggleMemberStatusMap({
        ...toggleMemberStatusMap,
        [member.memberId]: true,
      })
    },
    onSuccess: (member) => {
      setToggleMemberStatusMap({
        ...toggleMemberStatusMap,
        [member.id]: false,
      })
    },
  })
  const { fetch: updateMemberRole, pending: pendingUpdateMember } =
    useUpdateMembership({
      onMutate: (member) => {
        setToggleMemberStatusMap({
          ...toggleMemberStatusMap,
          [member.userId]: true,
        })
      },
      onSuccess: (member) => {
        setToggleMemberStatusMap({
          ...toggleMemberStatusMap,
          [member.id]: false,
        })
      },
    })
  React.useEffect(() => {
    if (settings && !settingsPending && currentUser && !currentUserPending) {
      setSeats({
        used: settings.organization.memberships.filter((m) => !m.disabledAt)
          .length,
        allowed: currentUser.paywallInfo.seatLimit || 7,
      })
    }
  }, [settings, currentUser, currentUserPending, settingsPending])

  return (
    <Column>
      <Row className="justify-between">
        <Heading
          size="sm"
          fontWeight="bold"
          color="gray-500"
          className="uppercase"
        >
          Team Members{' '}
          <Text size="sm">
            ({seats.used} out of {seats.allowed} seats used)
          </Text>
        </Heading>
        {/* {seats.used >= seats.allowed && (
          <Clickable
            onClick={() => setShowAddNewUserModal(true)}
            className="items-center justify-center space-x-1"
          >
            <Row className="items-center justify-center space-x-1">
              <AttentionIcon className="w-5 h-5 text-primary-500" />
              <Text
                className="uppercase"
                fontWeight="bold"
                size="xs"
                color="primary-500"
              >
                Need More Licenses?
              </Text>
            </Row>
          </Clickable>
        )} */}
      </Row>
      <Separator />
      {settingsPending || currentUserPending ? (
        <Column className="items-center justify-center">
          <Spinner />
        </Column>
      ) : (
        <Column className="overflow-auto shadow-sm border rounded-lg">
          <Table
            data={settings.organization.memberships as OrganizationMember[]}
            columns={[
              {
                width: 100,
                header: 'Name',
                cell: (item) => (
                  <Column>
                    <Row>
                      <Text size="sm" fontWeight="bold">
                        {item.userName}{' '}
                      </Text>
                      {item.userId === currentUser.id && (
                        <>
                          <Separator size="xxs" />
                          <Text size="xs" fontWeight="thin" color="gray-400">
                            (me)
                          </Text>
                        </>
                      )}
                    </Row>
                  </Column>
                ),
              },
              {
                width: 100,
                header: 'Email',
                align: 'left',
                cell: (item) => <Text size="sm">{item.userEmail}</Text>,
              },
              {
                width: 160,
                header: 'Role',
                align: 'left',
                cell: (item) => {
                  return (
                    <Select
                      id={item.userEmail}
                      options={['admin', 'member']}
                      itemRenderer={(opt) => (
                        <Text fontWeight="bold">{opt}</Text>
                      )}
                      pending={
                        pendingUpdateMember ||
                        toggleMemberStatusMap[item.userId]
                      }
                      value={item.role}
                      clearable={false}
                      onChange={(value) => {
                        updateMemberRole({
                          memberId: item.id,
                          userId: item.userId,
                          role: value ?? 'member',
                        })
                      }}
                      disabled={currentUser.organizationRole === 'member'}
                    />
                  )
                },
              },
              {
                width: 80,
                cell: (item) => {
                  return (
                    <Row className="items-center space-x-sm">
                      <Separator />
                      <Column className="min-w-28">
                        <Button
                          size="sm"
                          shape="rounded"
                          variant="outline"
                          intent={!item.disabledAt ? 'danger' : 'info'}
                          disabled={
                            item.userId === currentUser.id ||
                            currentUser.organizationRole !== 'admin'
                          }
                          pending={toggleMemberStatusMap[item.userId]}
                          onClick={() => {
                            toggleActive({ memberId: item.userId })
                          }}
                        >
                          <Row className="items-center">
                            {!item.disabledAt ? (
                              <ArchiveSvg className="w-4" />
                            ) : (
                              <UnarchiveSvg className="w-4" />
                            )}
                            <Separator size="xs" />
                            {!item.disabledAt ? 'Deactivate' : 'Re-activate'}
                          </Row>
                        </Button>
                      </Column>
                    </Row>
                  )
                },
              },
            ]}
            pageSize={50}
            pending={settingsPending || currentUserPending}
          />
        </Column>
      )}
      <AddNewUserModal
        visible={showAddNewUserModal}
        onRequestClose={() => setShowAddNewUserModal(false)}
      />
    </Column>
  )
}

export default TeamMembersTable
