import api from '@trinsly/common/src/services/api'
import { toInteger } from 'lodash'
import moment from 'moment'
import CampaignType from 'types/campaign-type'
import ScheduleTimeSelectEnum from 'types/schedule-time-select-enum'

type CreateBatchServiceProps = {
  batch: {
    name: string
    excludeContactedWithin: string | number
  }
  campaign: {
    id: CampaignType['id'] | undefined
    templates: CampaignType['templates']
    tagList: CampaignType['tagList']
  }
  schedules: {
    daysAfter: number | string
    time: ScheduleTimeSelectEnum
  }[]
  custom: {
    [index: string]: {
      threads: {
        attachments: string[]
        subject: string
        body: string
        initial: {
          subject: string
          body: string
        }
      }[]
      variables: {
        key: string
        value: string
      }[]
    }
  }
}

async function createBatchService({
  batch,
  campaign,
  custom,
  schedules,
}: CreateBatchServiceProps) {
  const response = (await api(`/batches`, {
    method: 'POST',
    body: {
      batch: {
        name: batch?.name,
        exclude_contacted_within: batch?.excludeContactedWithin,

        campaign_attributes: {
          id: campaign?.id,
          tag_list: campaign?.id ? undefined : campaign?.tagList,
          templates: campaign?.id
            ? undefined
            : campaign?.templates?.map?.((template, index) => {
                return {
                  duration: toInteger(Number(template?.duration || 0)) * 24, //in hours instead of days
                  position: Number(template?.position || index) + 1,
                  template: {
                    attachments: template?.attachments,
                    body: template?.body,
                    subject: template?.subject,
                  },
                }
              }),
        },

        email_threads_attributes: Object.keys(custom || {})?.map?.((email) => {
          const data = custom[email]
          return {
            email,
            sourceUrl: data?.variables?.find?.(
              (variable) => variable?.key === 'SourceUrl'
            )?.value,
            //filter source url out from template variables
            variables: data?.variables?.filter(
              (variable) => variable.key !== 'SourceUrl'
            ),
            emails: data?.threads?.map?.((thread, index) => {
              return {
                position: index + 1,
                subject: thread?.subject,
                body: thread?.body,
                attachments: thread?.attachments,
                modified:
                  thread?.subject !== thread?.initial?.subject ||
                  thread?.body !== thread?.initial?.body,
                deliver_at: constructDeliverAtForApi({
                  daysAfter: schedules?.[index]?.daysAfter as any,
                  emailPosition: index + 1,
                  prospectEmailsCount: data?.threads?.length,
                  time: schedules?.[index]?.time,
                }),
              }
            }),
          }
        }),
      },
    },
  })) as any
  return response
}

export default createBatchService

function constructDeliverAtForApi({
  daysAfter,
  emailPosition,
  prospectEmailsCount,
  time,
}: {
  daysAfter: number
  emailPosition: number
  prospectEmailsCount: number
  time: ScheduleTimeSelectEnum
}): string {
  // if it is the first email and it is delivered at today immediately, stagger
  // the deliver at times
  if (
    emailPosition === 1 &&
    time === ScheduleTimeSelectEnum.UNMODIFIED_VALUE &&
    daysAfter === 0
  ) {
    const bufferInSeconds = calcBufferForBackendInSeconds(prospectEmailsCount)
    return staggerImmediatelyDeliverAt(bufferInSeconds)
  }

  const hoursOffset =
    time === ScheduleTimeSelectEnum.UNMODIFIED_VALUE
      ? getRandomTimeWithinWindow(moment().get('hour'))
      : getRandomTimeWithinWindow(time)

  return moment()
    .startOf('day')
    .add(daysAfter, 'd')
    .add(hoursOffset, 'h')
    .toISOString()
}

// This is a low LOE fix for the following problem: The `deliver_at` for emails that
// are scheduled for immediate delivery may be in the past already by the time
// the workers get to it. This time it takes for email thread creation and scheduling
// contributes to this. As a result, workers will try to deliver them ASAP, potentially
// violating any necessary spacing or throttle. Here, we'll try to give the backend
// more time for email thread creation and scheduling.
function calcBufferForBackendInSeconds(prospectEmailsCount: number): number {
  return prospectEmailsCount * ((2 * 60) / 100) // 2 minutes per 100 prospects
}

/**
 * Staggers it to randomly deliver at within an hour
 */
function staggerImmediatelyDeliverAt(bufferInSeconds = 0): string {
  const randomMinutesWithinHour = getRandomInt(60)

  return moment()
    .add(bufferInSeconds, 'seconds')
    .add(randomMinutesWithinHour, 'minutes')
    .toISOString()
}

// returns random int between 0 and max, inclusive
function getRandomInt(max: number) {
  return Math.floor(Math.random() * Math.floor(max + 1))
}

// We aren't worried about overflowing into the next day.
function getRandomTimeWithinWindow(startTime: number, hoursWindow = 2) {
  const endTime = startTime + hoursWindow
  return Math.random() * (endTime - startTime) + startTime
}
