import Card from '@trinsly/common/src/components/atoms/card'
import Column from '@trinsly/common/src/components/atoms/column'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import Modal from '@trinsly/common/src/components/molecules/modal'

export type AccountDeletionConfirmationModalProps = {
  visible: boolean
  onRequestClose: () => void
  onConfirm: () => void
  pending: boolean
}

export const AccountDeletionConfirmationModal = ({
  visible,
  onRequestClose,
  onConfirm,
  pending,
}: AccountDeletionConfirmationModalProps) => {
  return (
    <>
      <Modal size="sm" visible={visible} onRequestClose={onRequestClose}>
        <Card className="overflow-hidden">
          <Column className="p-md">
            <Heading size="xl" fontWeight="bold">
              Account deletion request
            </Heading>
            <Separator size="sm" />
            <Text>Are you sure you want to delete your account?</Text>
          </Column>
          <Modal.Footer>
            <Button className="min-w-28" onClick={onRequestClose}>
              close
            </Button>
            <Separator size="sm" />
            <Button
              className="min-w-48"
              intent="danger"
              pending={pending}
              onClick={onConfirm}
            >
              Send request
            </Button>
          </Modal.Footer>
        </Card>
      </Modal>
    </>
  )
}

export default AccountDeletionConfirmationModal
