import React from 'react'
import { Theme } from '../../types/theme-type'

export interface ClickableProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  intent?: Theme['intent'] | 'transparent'
}

function Clickable({
  children,
  intent,
  className,
  onClick,
  disabled = false,
  ...rest
}: ClickableProps) {
  return (
    <button
      type="button"
      disabled={disabled}
      onClick={onClick}
      className={[
        classes,
        disabled ? disabledClasses : defaultClasses,
        !disabled && intentClassMap[intent || 'undefined'],
        className,
      ].join(' ')}
      style={{ textAlign: 'inherit' }}
      {...rest}
    >
      {children}
    </button>
  )
}

export default Clickable

const classes = `flex focus:outline-none transition`
const defaultClasses = `focus-visible:ring`
const disabledClasses = `cursor-default`

const intentClassMap: any = {
  primary: 'hover:bg-primary-50 focus:bg-primary-50 focus:ring-primary-200',
  danger:
    'hover:bg-danger-50 focus:bg-danger-50 active:bg-danger-100 focus:ring-danger-200',
  undefined:
    'hover:bg-gray-50 active:bg-gray-100 focus-visible:ring-primary-200',
  transparent: 'focus-visible:ring-primary-200',
}
