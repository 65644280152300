import api from '@trinsly/common/src/services/api'

type UnsubscribeServiceProps = {
  emailThreadToken: string
}

async function unsubscribeService({
  emailThreadToken,
}: UnsubscribeServiceProps) {
  const response = await api('/unsubscribes', {
    method: 'POST',
    params: {
      email_thread_token: emailThreadToken,
    },
  })
  return
}

export default unsubscribeService
