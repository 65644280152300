import api from '@trinsly/common/src/services/api'

export type CreateBillingSessionServiceProps = {}

async function createBillingSessionService() {
  const response = (await api(`/billing`, { method: 'POST' })) as any
  return response?.url
}

export default createBillingSessionService
