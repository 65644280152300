import api from '@trinsly/common/src/services/api'

export type DeleteInboxIntegrationServiceProps = {}

export default async function deleteInboxIntegrationService({}: DeleteInboxIntegrationServiceProps) {
  const response = await api(`/inbox`, {
    method: 'DELETE',
  })
  return response as any
}
