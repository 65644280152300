import { ReactComponent as PlusSvg } from '@trinsly/common/src/assets/icons/plus.svg'
import Column from '@trinsly/common/src/components/atoms/column'
import Divider from '@trinsly/common/src/components/atoms/divider'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Label from '@trinsly/common/src/components/atoms/label'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Switch from '@trinsly/common/src/components/atoms/switch'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import TextInput from '@trinsly/common/src/components/molecules/text-input'
import useGetUserSignature from 'api-hooks/use-get-user-signature'
import MultiSelectTags from 'components/multi-select-tags'
import SelectCampaign from 'components/select-campaign'
import React from 'react'
import CampaignType from 'types/campaign-type'
import { useDraftContext } from '../draft-provider'
import { useFormContext } from '../form-provider'
import EmailTemplateListItem from './email-template-list-item'

type CampaignFormSectionProps = {}

function CampaignFormSection({}: CampaignFormSectionProps) {
  const { getValue, getValues, setValue, setValues, getError } =
    useFormContext()
  const { draft, pending: pendingDraft } = useDraftContext()

  const { data: signature, pending: pendingSignature } = useGetUserSignature({})
  const isLinked =
    Boolean(getValue('campaign.id')) && Boolean(getValue('campaign.linked'))
  const shouldDisableForm = pendingSignature || pendingDraft

  const templates = (getValue('campaign.templates') ||
    []) as CampaignFormTemplate[]

  const shouldSetSignature =
    templates?.length === 1 && !draft?.id && Boolean(signature?.signature)
  React.useEffect(() => {
    if (shouldSetSignature) {
      setTimeout(() => {
        //this needs to happens in second+ render otherwise signature get wipeout by draft provider
        setValue('campaign.templates[0].body', `<br>${signature?.signature}`)
      }, 0)
    }
  }, [shouldSetSignature, signature?.signature])

  return (
    <Column className="gap-md">
      <Column className="px-lg gap-sm">
        <Heading size="lg" fontWeight="bold">
          Create a campaign for this batch
        </Heading>
        <Row className="items-end justify-between gap-md">
          <Column className="min-w-64 w-1/4">
            <TextInput
              label="Name of Campaign"
              value={getValue('name')}
              onChange={(value) => setValue('name', value)}
              error={getError('name')}
              required
              disabled={shouldDisableForm || isLinked}
            />
          </Column>
          <Row className="items-center gap-sm">
            <Row className="items-center whitespace-nowrap gap-xs">
              <Label>Copy values from:</Label>
              <Column className="min-w-80 w-1/3">
                <SelectCampaign
                  id="copy-values-from-select"
                  value={getValue('campaign.id') ? getValue('campaign') : null}
                  onChange={(value) => {
                    setValue('name', value?.name || '')
                    setValue('campaign', {
                      id: value?.id,
                      name: value?.name,
                      linked: Boolean(value),
                      tags: value?.tagList,
                      templates: value?.templates || [{}],
                    })
                  }}
                  disabled={shouldDisableForm || isLinked}
                />
              </Column>
            </Row>
            <Row className="items-center">
              <Switch
                id="linked"
                disabled={!getValue('campaign.id') || shouldDisableForm}
                checked={getValue('campaign.linked')}
                onChange={() => {
                  setValue('campaign.linked', !getValue('campaign.linked'))
                }}
              />
              <Separator size="xs" />
              <Label>Linked</Label>
            </Row>
          </Row>
        </Row>
      </Column>
      <Column>
        {templates?.map((template, index) => (
          <EmailTemplateListItem
            key={index}
            index={index}
            getError={getError}
            getValue={getValue}
            getValues={getValues}
            setValue={setValue}
            setValues={setValues}
            onRemoveFollowUp={() => {
              setValue(
                'campaign.templates',
                templates?.filter((template, i) => index !== i)
              )
            }}
          />
        ))}
        <Divider />
      </Column>
      {!shouldDisableForm && (
        <Row className="px-xl items-center gap-md">
          <Button
            testId="add-a-follow-up-button"
            variant="outline"
            intent="primary"
            disabled={pendingSignature}
            onClick={() => {
              const firstEmailSubject =
                getValue('campaign.templates[0].subject') || ''
              const isInThread = Boolean(firstEmailSubject)
              setValue('campaign.templates', [
                ...templates,
                {
                  duration: '1',
                  isInThread,
                  subject: isInThread ? `Re: ${firstEmailSubject}` : undefined,
                  body: signature?.signature
                    ? `<br>${signature?.signature}`
                    : '',
                } as Partial<CampaignType['templates'][number]>,
              ])
            }}
          >
            <Row className="items-center whitespace-nowrap">
              <PlusSvg className="w-5 h-5" />
              <Separator size="xs" />
              Add a Follow-Up
            </Row>
          </Button>
          {templates?.length <= 1 && (
            <Column className="px-sm py-3 bg-primary-50 rounded-lg relative">
              <Column className="absolute left-0 top-0 bottom-0 justify-center">
                <Column
                  className="w-0 h-0 text-primary-50"
                  style={{
                    marginLeft: -16,
                    borderLeft: '8px solid transparent',
                    borderRight: '8px solid currentColor',
                    borderTop: '8px solid transparent',
                    borderBottom: '8px solid transparent',
                  }}
                />
              </Column>
              <Text size="sm" color="primary-500">
                Having 2 follow-ups in a 2-week period yields 90%+ of positive
                responses.
              </Text>
            </Column>
          )}
        </Row>
      )}
      <Column className="px-xl">
        <MultiSelectTags
          label="Tags"
          creatable={true}
          disabled={shouldDisableForm}
          values={getValue('campaign.tagList')}
          onChange={(values) => setValue('campaign.tagList', values)}
          onCreate={(value) => {
            if (value) {
              setValue('campaign.tagList', [
                ...(getValue('campaign.tagList') || []),
                value,
              ])
            }
          }}
        />
      </Column>
    </Column>
  )
}

export default CampaignFormSection
