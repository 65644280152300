import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation, useQueryClient } from 'react-query'
import createProspectNoteService from 'services/create-prospect-note-service'
import ProspectNoteType from 'types/prospect-note-type'

export type UseCreateProspectNoteProps =
  {} & MutateApiHookType<ProspectNoteType>

function useCreateProspectNote({
  onMutate,
  onSuccess,
  onError,
}: UseCreateProspectNoteProps) {
  const queryClient = useQueryClient()

  const { mutate: fetch, isLoading: pending } = useMutation(
    createProspectNoteService,
    {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries('/prospects/{prospectId}/notes')
        onSuccess?.(data)
      },
      onError,
      onMutate,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useCreateProspectNote
