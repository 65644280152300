import React, { DetailedHTMLProps, TextareaHTMLAttributes } from 'react'

export interface TextareaProps
  extends Omit<
    DetailedHTMLProps<
      TextareaHTMLAttributes<HTMLTextAreaElement>,
      HTMLTextAreaElement
    >,
    'onChange' | 'size'
  > {
  error?: any
  onChange?: (
    value: string,
    event?: React.ChangeEvent<HTMLTextAreaElement>
  ) => void
}

function _Textarea(
  {
    className = '',
    rows = 7,
    disabled = false,
    value,
    onChange,
    error,
    ...rest
  }: TextareaProps,
  ref: React.Ref<HTMLTextAreaElement>
) {
  return (
    <textarea
      ref={ref}
      rows={rows}
      value={value}
      disabled={disabled}
      className={`
        ${baseClasses}
        ${disabledClasses}
        ${error ? errorClasses : ''}
        ${!error ? defaultClasses : ''}
        ${className}
      `}
      onChange={event => onChange?.(event.target.value, event)}
      {...rest}
    />
  )
}

const Textarea = React.forwardRef(_Textarea)
export default Textarea

const baseClasses = `w-full min-h-20 text-sm text-gray-900 placeholder-gray-400 rounded-md shadow-sm transition`
const defaultClasses = `bg-gray-50 border-gray-300 focus:border-primary-300 focus:ring focus:ring-primary-200`
const errorClasses = `bg-danger-50 bg-opacity-33 border-danger-300 focus:border-danger-300 focus:ring focus:ring-danger-200`
const disabledClasses = `disabled:border-dashed disabled:border-gray-200 disabled:bg-white`
