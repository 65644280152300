enum CrmEnum {
  BULLHORN = 'bullhorn',
  CATS = 'cats',
  CRELATE = 'crelate',
  GREENHOUSE = 'greenhouse',
  LEVER = 'lever',
  PC_RECRUITER = 'pcrecruiter',
  WORKABLE = 'workable',
  CHATKICK = 'chatkick',
  CLOCKWORK = 'clockwork',
}

export default CrmEnum

export const translateCrmEnum: Record<CrmEnum, string> = {
  [CrmEnum.BULLHORN]: 'Bullhorn',
  [CrmEnum.CATS]: 'CATS',
  [CrmEnum.CRELATE]: 'Crelate',
  [CrmEnum.GREENHOUSE]: 'Greenhouse',
  [CrmEnum.LEVER]: 'Lever',
  [CrmEnum.PC_RECRUITER]: 'PC Recruiter',
  [CrmEnum.WORKABLE]: 'Workable',
  [CrmEnum.CHATKICK]: 'Chatkick',
  [CrmEnum.CLOCKWORK]: 'Clockwork',
}
