import { ReactComponent as FileSvg } from '@trinsly/common/src/assets/icons/file.svg'
import Column from '@trinsly/common/src/components/atoms/column'
import ProgressBar from '@trinsly/common/src/components/atoms/progress-bar'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import PropTypes from 'prop-types'
import React from 'react'
import Dropzone from 'react-dropzone'
import S3Upload from 'react-s3-uploader/s3upload'

const transformIntoRows = (array, size) => {
  const newArray = []
  for (let i = 0, l = array.length; i < l; i += size) {
    newArray.push({ index: i, slice: array.slice(i, i + size) })
  }
  return newArray
}

const isImage = (filename) =>
  filename && filename.match(/\.(jpeg|jpg|gif|png|svg|webp)/i)

const fileUrl = (s3Url, filename) =>
  `${s3Url.endsWith('/') ? s3Url.slice(0, -1) : s3Url}/${filename}`

// react-dropzone options
const maxSize = 1024 * 1024 * 5 // 5MB
const style = {
  border: `dashed 2px #e5e7eb`,
  borderRadius: 5,
  overflow: 'hidden',
  position: 'relative',
}
const activeStyle = {
  backgroundColor: '#eee',
  border: 'dashed 2px #4734ae',
}
const rejectStyle = {
  backgroundColor: '#ffdddd',
  border: 'dashed 2px #999',
}

const Attachment = ({ attachment, className, disabled, onRemoveClick }) => {
  const stringFileName = (attachment.filename || attachment.file.name || "file")?.replace?.(/^.*[\\/]/, '')
  const attachmentFileName = attachment.progress ? <ProgressBar intent='success' total={100} value={attachment?.progress} /> : <Text size='sm'>{stringFileName}</Text>
  const attachmentPreview = isImage(attachment.fileUrl) ? <img style={{width: 110, height: 86, objectFit: "cover"}} className="rounded shadow" alt={stringFileName} src={attachment.fileUrl} /> : <div style={{width: 110, height: 86}} className="rounded shadow flex items-center justify-center"><FileSvg className="text-gray-700 w-20 h-20" /></div>
  const attachmentError = attachment?.error ? (<Text size='xs' color='danger-500'>{attachment?.error}</Text>) : null
  
  return(
  <div className={className}>
    <div
    className='flex flex-col gap-1'
      style={{maxWidth: 160}}
    >
      <span className='cursor-pointer' onClick={(event) => {
        event.stopPropagation()
        if (attachment.fileUrl) window.open(attachment.fileUrl, '_blank')
      }}>
      {attachmentPreview}
      </span>
      <Column className='items-center min-h-5'>
      {attachmentFileName}
      </Column>
      {attachmentError}
      <Button
        size='xs'
        intent='danger'
        variant='outline'
        disabled={!attachment.fileUrl || disabled}
        onClick={onRemoveClick}
      >
        Remove
      </Button>
    </div>
  </div>
  )
}

Attachment.propTypes = {
  attachment: PropTypes.object.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onRemoveClick: PropTypes.func.isRequired,
}

Attachment.defaultProps = {
  className: null,
  disabled: false,
}

const ATTACHMENT_COUNT_PER_ROW = 4

const DropZoneContent = ({
  attachmentClassName,
  attachments,
  containerClassName,
  disabled,
  onRemoveAttachment,
}) => (
  <div className="flex flex-col p-sm gap-sm">
    {attachments?.length > 0 && <div className={["flex flex-row flex-wrap gap-sm", containerClassName].join(" ")}>
      {attachments?.map((attachment) => (
        <Attachment
          key={attachment.filename || attachment.file.name}
          attachment={attachment}
          className={attachmentClassName}
          disabled={disabled}
          onRemoveClick={(event) => {
            event.stopPropagation()
            disabled || onRemoveAttachment(attachment)
          }}
        />
      ))}
      </div> }
    {disabled || (
      <Column>
      <Text size="sm">Drop files here or click to select files (Max 5MB per file).
      </Text>
      </Column>
    )}
  </div>
)

DropZoneContent.propTypes = {
  attachmentClassName: PropTypes.string,
  attachments: PropTypes.array.isRequired,
  containerClassName: PropTypes.string,
  disabled: PropTypes.bool,
  onRemoveAttachment: PropTypes.func.isRequired,
}

DropZoneContent.defaultProps = {
  attachmentClassName: null,
  containerClassName: null,
  disabled: false,
}

export default class Attachments extends React.Component {
  static propTypes = {
    attachmentClassName: PropTypes.string,
    containerClassName: PropTypes.string,
    disabled: PropTypes.bool,
    fileUrls: PropTypes.array,
    onChange: PropTypes.func,
    s3Url: PropTypes.string.isRequired,
    uploadOptions: PropTypes.object.isRequired,
  }

  static defaultProps = {
    attachmentClassName: null,
    containerClassName: null,
    disabled: false,
    fileUrls: [],
    onChange: () => {},
  }

  constructor() {
    super()

    this.state = {
      attachments: [],
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.fileUrls !== this.props.fileUrls) {
      const oldAttachments = this.state?.attachments || []
      const newAttachments = nextProps?.fileUrls?.map?.((fileUrl) => {
        const existingAttachment = oldAttachments?.find?.(attachment => attachment?.fileUrl === fileUrl)
        if(existingAttachment) {
          return existingAttachment;
        }
        return {
          error: null,
          file: {},
          filename: fileUrl,
          fileUrl: fileUrl,
          progress: null,
          textState: null,
        }
      }) || []
  
      // eslint-disable-next-line
      this.setState({
        attachments: newAttachments,
      })
    }
  }

  componentDidMount() {
    const { fileUrls } = this.props

    const attachments = fileUrls?.map?.((fileUrl) => ({
      error: null,
      file: {},
      filename: fileUrl,
      fileUrl: fileUrl,
      progress: null,
      textState: null,
    })) || []

    // eslint-disable-next-line
    this.setState({
      attachments,
    })
  }

  updateAttachment = (attachmentFileName, props) => {
    const { onChange } = this.props
    const { attachments } = this.state
    const newAttachments = attachments?.map(attachment => {
      const isCurrent = attachment?.file?.name === attachmentFileName;
      if(!isCurrent) {
        return attachment;
      }

      return {...(attachment || {}), ...props}
    }) || []
    
    this.setState({ attachments: newAttachments }, () => {
      onChange?.(newAttachments)
    })
  }

  handleProgress = (progress, textState, file) => {
    this.updateAttachment(file.name, { progress, textState })
  }

  handleError = (error, file) => {
    this.updateAttachment(file.name, { error, progress: null })
  }

  handleFinish = (info, file) => {
    const { onChange, s3Url } = this.props
    const props = {
      ...info,
      fileUrl: fileUrl(s3Url, info.filename),
      progress: null,
      textState: null,
    }
    this.updateAttachment(file.name, props)
  }

  handleDrop = (files, rejectedFiles) => {
    const { attachments } = this.state
    const newAttachments = files?.map(attachment => ({
      file: attachment,
      progress: 0,
      textState: 'uploading...',
    })) || []
    
    const { uploadOptions } = this.props
    const uploaderOptions = Object.assign(
      {
        contentDisposition: 'auto',
        onError: this.handleError,
        onFinishS3Put: this.handleFinish,
        onProgress: this.handleProgress,
        s3path: '',
        signingUrl: '/s3/sign',
        uploadRequestHeaders: {},
      },
      uploadOptions
    )

    this.setState({ attachments: [...attachments, ...newAttachments] }, () => {
      const s3Upload = new S3Upload({
        files,
        ...uploaderOptions,
      })
    })
  }

  handleRemoveAttachment = (attachment) => {
    const { attachments } = this.state
    const { onChange } = this.props
    const newAttachments = attachments.filter(
      (o) => o.filename !== attachment.filename
    )
    this.setState({ attachments: newAttachments }, () => {
      onChange(newAttachments)
    })
  }

  render() {
    const { attachments } = this.state
    const { attachmentClassName, disabled, containerClassName } = this.props

    return (
      <Dropzone
        activeStyle={activeStyle}
        disabled={disabled}
        disableClick={disabled}
        maxSize={maxSize}
        onDrop={this.handleDrop}
        preventDropOnDocument={false}
        rejectStyle={rejectStyle}
        style={style}
      >
        <DropZoneContent
          attachmentClassName={attachmentClassName}
          attachments={attachments}
          containerClassName={containerClassName}
          disabled={disabled}
          onRemoveAttachment={this.handleRemoveAttachment}
        />
      </Dropzone>
    )
  }
}
