import Column from '@trinsly/common/src/components/atoms/column'
import Separator from '@trinsly/common/src/components/atoms/separator'
import { useFormContext } from '../form-provider'
import EducationsSection from './educations-section'
import EmploymentsSection from './employments-section'
import SubHeader from './header-section'
import NotesSection from './notes-section'
import ReachOutsSection from './reach-outs-section'

type DashboardSectionProps = {}

function DashboardSection({}: DashboardSectionProps) {
  const { prospect } = useFormContext()

  return (
    <Column>
      <SubHeader />
      <Separator size="xl" />

      <ReachOutsSection prospectId={prospect?.id} />
      <Separator />

      <Column className="mx-md border-b border-dashed border-gray-300" />
      <Separator size="lg" />

      <NotesSection />
      <Separator />

      <Column className="mx-md border-b border-dashed border-gray-300" />
      <Separator size="lg" />

      <EmploymentsSection prospectId={prospect?.id} />
      <Separator />
      <Column className="mx-md border-b border-dashed border-gray-300" />
      <Separator size="lg" />
      <EducationsSection prospectId={prospect?.id} />
      <Separator />
    </Column>
  )
}

export default DashboardSection
