import Select, {
  SelectProps,
} from '@trinsly/common/src/components/molecules/select'
import usePageableState from '@trinsly/common/src/hooks/use-pageable-state'
import useGetCampaigns from 'api-hooks/use-get-campaigns'
import { CampaignFilterScopeEnum } from 'services/get-campaigns-service'
import CampaignType from 'types/campaign-type'

interface SelectCampaignProps
  extends Omit<
    SelectProps<CampaignType>,
    'itemRender' | 'options' | 'pending'
  > {}

function SelectCampaign(props: SelectCampaignProps) {
  const { pageable } = usePageableState({
    page: 1,
    perPage: 999,
  })
  const { data: mineCampaigns, pending: pendingMineCampaigns } =
    useGetCampaigns({
      ...pageable,
      filters: { state: CampaignFilterScopeEnum.MINE },
    })
  const { data: sharedCampaigns, pending: pendingSharedCampaigns } =
    useGetCampaigns({
      ...pageable,
      filters: { state: CampaignFilterScopeEnum.SHARED },
    })

  const campaigns = [...mineCampaigns, ...sharedCampaigns]

  return (
    <Select
      {...props}
      options={campaigns}
      itemRenderer={(item) => item.name}
      pending={pendingMineCampaigns || pendingSharedCampaigns}
      filterWith={({
        inputValue,
        option,
      }: {
        inputValue: string
        option: CampaignType
      }) => {
        return option?.name
          ?.toLowerCase?.()
          .includes?.(inputValue?.toLowerCase?.())
      }}
    />
  )
}

export default SelectCampaign
