import { useQuery } from 'react-query'

import getOrganizationService, {
  GetOrganizationServiceProps,
} from 'services/settings/get-organization-service'

export type UseGetOrganizationProps = {} & GetOrganizationServiceProps

function useGetOrganization({ organizationId }: UseGetOrganizationProps) {
  const { data, error, status } = useQuery(
    ['/organizations/{organizationId}', organizationId],
    () => getOrganizationService({ organizationId })
  )

  return {
    data,
    pending: status === 'loading',
    error,
  }
}

export default useGetOrganization
