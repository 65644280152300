import Card from '@trinsly/common/src/components/atoms/card'
import Column from '@trinsly/common/src/components/atoms/column'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import Modal from '@trinsly/common/src/components/molecules/modal'
import useStopAllReachOuts from 'api-hooks/use-stop-all-reach-outs'
import CampaignType from 'types/campaign-type'

type StopAllReachOutsConfirmationModalProps = {
  campaignId: CampaignType['id']
  visible: boolean
  onRequestClose: () => void
}

function StopAllReachOutsConfirmationModal({
  campaignId,
  visible,
  onRequestClose,
}: StopAllReachOutsConfirmationModalProps) {
  const { fetch: stopAllReachOuts, pending: pendingStop } = useStopAllReachOuts(
    {
      onSuccess: (data) => {
        onRequestClose()
      },
      onError: () => alert('Failed to Stop All - Please Try Again'),
    }
  )

  return (
    <Modal size="sm" visible={visible} onRequestClose={onRequestClose}>
      <Card className="overflow-hidden">
        <Column className="p-md">
          <Heading size="xl" fontWeight="bold">
            Stop all Reach-Outs
          </Heading>
          <Separator size="sm" />
          <Text>Are you sure you want to stop all Reach-Outs?</Text>
        </Column>
        <Modal.Footer>
          <Button className="min-w-28" onClick={onRequestClose}>
            Close
          </Button>
          <Separator size="sm" />
          <Button
            className="min-w-48"
            intent="danger"
            pending={pendingStop}
            onClick={() => {
              stopAllReachOuts({ campaignId })
            }}
          >
            Stop All
          </Button>
        </Modal.Footer>
      </Card>
    </Modal>
  )
}

export default StopAllReachOutsConfirmationModal
