import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation } from 'react-query'
import completePasswordResetService from 'services/auth/complete-password-reset-service'

export type UseCompletePasswordResetProps = {} & MutateApiHookType<void>

function useCompletePasswordReset({
  onSuccess,
  onError,
}: UseCompletePasswordResetProps) {
  const { mutate: fetch, isLoading: pending } = useMutation(
    completePasswordResetService,
    {
      onSuccess: async () => {
        onSuccess?.()
      },
      onError,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useCompletePasswordReset
