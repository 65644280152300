import api from '@trinsly/common/src/services/api'
import CampaignType from 'types/campaign-type'

type StopAllReachOutsServiceProps = {
  campaignId: CampaignType[`id`]
}

async function stopAllReachOutsService({
  campaignId,
}: StopAllReachOutsServiceProps) {
  const response = await api(`/campaigns/${campaignId}/stop_all`, {
    method: 'POST',
  })
  return response as any as CampaignType[]
}

export default stopAllReachOutsService
