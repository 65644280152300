import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation, useQueryClient } from 'react-query'
import saveProspectToMergeIntegrationService from 'services/integrations/save-prospect-to-merge-integration-service'

export type UseSaveProspectToMergeIntegrationProps =
  {} & MutateApiHookType<void>

function useSaveProspectToMergeIntegration({
  onSuccess,
  onError,
}: UseSaveProspectToMergeIntegrationProps) {
  const queryClient = useQueryClient()
  const { mutate: fetch, isLoading: pending } = useMutation(
    saveProspectToMergeIntegrationService,
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries('/prospects/{prospectId}')
        onSuccess?.(data)
      },
      onError,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useSaveProspectToMergeIntegration
