import React from 'react'
import { ReactComponent as BellRingSvg } from '../../assets/icons/bell-ring.svg'
import { ReactComponent as CloseSvg } from '../../assets/icons/close.svg'
import Column from '../atoms/column'
import Row from '../atoms/row'
import Separator from '../atoms/separator'

export type AlertProps = {
  icon?: React.ReactElement
  children: React.ReactNode | React.ReactNodeArray
  intent: 'success' | 'danger' | 'warning' | 'info'
  variant?: 'solid' | 'translucent'
  onRequestClose?: () => void
}

function Alert({
  variant = 'solid',
  children,
  icon = <BellRingSvg />,
  intent,
  onRequestClose,
}: AlertProps) {
  return (
    <Row className={`${classes} ${intentClassMap[variant][intent]}`}>
      <Row className="flex-1 items-center text-sm">
        {icon && (
          <React.Fragment>
            <Column
              className={`p-3 rounded-full ${iconIntentClassMap[intent]}`}
            >
              {React.cloneElement(icon, {
                className: `w-5 h-5`,
              })}
            </Column>
            <Separator size="sm" />
          </React.Fragment>
        )}
        {children}
      </Row>
      {Boolean(onRequestClose) && (
        <React.Fragment>
          <Separator size="sm" />
          <Column className="justify-center">
            <button
              onClick={() => onRequestClose?.()}
              className="flex items-center justify-center h-8 w-8 rounded-full bg-white bg-opacity-20 hover:bg-opacity-30 active:bg-opacity-50 focus:ring focus:ring-white focus:ring-opacity-80 focus:outline-none"
            >
              <CloseSvg className="w-5 h-5 text-white" />
            </button>
          </Column>
        </React.Fragment>
      )}
    </Row>
  )
}

export default Alert

const classes = `rounded-md border-l-4 min-h-16 px-sm text-md`
const intentClassMap = {
  solid: {
    success: `border-success-500 bg-success-50 text-gray-900`,
    danger: `border-danger-500 bg-danger-50 text-gray-900`,
    warning: `border-warning-500 bg-warning-50 text-gray-900`,
    info: `border-info-500 bg-info-50 text-gray-900`,
  },
  translucent: {
    success: `bg-white bg-opacity-15 border-success-500 text-white`,
    danger: `bg-white bg-opacity-15 border-danger-500 text-white`,
    warning: `bg-white bg-opacity-15 border-warning-500 text-white`,
    info: `bg-white bg-opacity-15 border-info-500 text-white`,
  },
}

const iconIntentClassMap = {
  success: 'bg-success-500 text-white',
  danger: 'bg-danger-500 text-white',
  warning: 'bg-warning-500 text-white',
  info: 'bg-info-500 text-white',
}
