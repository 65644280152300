import { ReactComponent as BatchesSvg } from '@trinsly/common/src/assets/icons/batches.svg'
import { ReactComponent as CampaignSvg } from '@trinsly/common/src/assets/icons/campaign.svg'
import { ReactComponent as ChromeColorizedSvg } from '@trinsly/common/src/assets/icons/chrome-colorized.svg'
import { ReactComponent as DashboardSvg } from '@trinsly/common/src/assets/icons/dashboard.svg'
import { ReactComponent as IntegrationSvg } from '@trinsly/common/src/assets/icons/integration.svg'
import { ReactComponent as LeaderboardSvg } from '@trinsly/common/src/assets/icons/leaderboard.svg'
import { ReactComponent as LogoutSvg } from '@trinsly/common/src/assets/icons/logout.svg'
import { ReactComponent as PlusSvg } from '@trinsly/common/src/assets/icons/plus.svg'
import { ReactComponent as ProjectSvg } from '@trinsly/common/src/assets/icons/project.svg'
import { ReactComponent as ProspectSvg } from '@trinsly/common/src/assets/icons/prospect.svg'
import { ReactComponent as ResourcesSvg } from '@trinsly/common/src/assets/icons/resources.svg'
import { ReactComponent as SettingsSvg } from '@trinsly/common/src/assets/icons/settings.svg'
import { ReactComponent as LogoInvertedSvg } from '@trinsly/common/src/assets/images/logo-inverted.svg'

import Column from '@trinsly/common/src/components/atoms/column'
import Divider from '@trinsly/common/src/components/atoms/divider'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import CommonSidebar from '@trinsly/common/src/components/organisms/sidebar'
import useGetUserSession from 'api-hooks/use-get-user-session'
import React from 'react'
import { NavLink } from 'react-router-dom'

import IconButton from '@trinsly/common/src/components/molecules/icon-button'
import slugify from '@trinsly/common/src/utils/slugify'
import { useAuth } from 'providers/auth-provider'
// import TaskListFeatureRequestButton from './task-list-feature-request-button'

export type SidebarProps = {
  impersonate: number | null
}

function Sidebar({ impersonate }: SidebarProps) {
  const { data: userSession, pending: userSessionPending } = useGetUserSession(
    {}
  )

  const { logOut, loggingOut } = useAuth()
  return (
    <CommonSidebar>
      <Column className="flex flex-col h-0 flex-1 bg-primary-500">
        <Column className="flex-1 flex flex-col overflow-x-hidden overflow-y-auto">
          <Column className="justify-center px-md" style={{ minHeight: 64 }}>
            <NavLink to="/">
              <LogoInvertedSvg className="w-36 h-12" />
            </NavLink>
          </Column>
          <Column className="justify-center px-sm py-xs">
            <NavLink to="/campaigns/new">
              <Button
                id="create-campaign-sidebar-button"
                size="sm"
                intent="white"
                shape="rounded"
                className="w-full"
              >
                <Row className="items-center">
                  <PlusSvg className="w-md h-md" />
                  <Separator size="xs" />
                  new campaign
                </Row>
              </Button>
            </NavLink>
            <Separator size="xs" />
            <NavLink to="/batches/new">
              <Button
                id="create-batch-sidebar-button"
                size="sm"
                intent="white"
                shape="rounded"
                className="w-full"
              >
                <Row className="items-center">
                  <PlusSvg className="w-md h-md" />
                  <Separator size="xs" />
                  new batch
                </Row>
              </Button>
            </NavLink>
          </Column>
          {/* <TaskListFeatureRequestButton /> */}
          <Separator size="xs" />
          <Divider />
          <nav className="flex-1">
            <SidebarItem exact={true} to="/">
              <DashboardSvg className="w-md h-md" />
              <Separator size="xs" />
              Dashboard
            </SidebarItem>
            <SidebarItem exact={true} to="/campaigns">
              <CampaignSvg className="w-md h-md" />
              <Separator size="xs" />
              Campaigns
            </SidebarItem>
            <SidebarItem exact={true} to="/prospects">
              <ProspectSvg className="w-md h-md" />
              <Separator size="xs" />
              Prospects
            </SidebarItem>
            <SidebarItem exact={true} to="/projects">
              <ProjectSvg className="w-md" />
              <Separator size="sm" />
              Projects
            </SidebarItem>

            <SidebarItem exact={true} to="/leaderboard">
              <LeaderboardSvg className="w-md h-md" />
              <Separator size="xs" />
              Leaderboard
            </SidebarItem>
            <SidebarItem exact={true} to="/batches">
              <BatchesSvg className="w-md h-md" />
              <Separator size="xs" />
              Batches
            </SidebarItem>
            <SidebarItem exact={true} to="/integrations">
              <IntegrationSvg className="w-md h-md" />
              <Separator size="xs" />
              Integrations
            </SidebarItem>
            <SidebarItem exact={true} to="/settings">
              <SettingsSvg className="w-md h-md" />
              <Separator size="xs" />
              Settings
            </SidebarItem>
            <SidebarExternalItem url="https://docs.chatkick.com">
              <ResourcesSvg className="w-md h-md" />
              <Separator size="xs" />
              Resources
            </SidebarExternalItem>
          </nav>
          <Separator size="md" />
          {!userSessionPending && userSession && (
            <SidebarUserInfo
              user={userSession}
              logOut={() => logOut({})}
              loggingOut={loggingOut}
            />
          )}
          <Divider />
          <Separator size="xl" />
          <Column
            className="px-md py-sm"
            style={{
              backgroundColor: '#5948B6',
              display: 'flex',
            }}
          >
            <Column className="-mt-12">
              <Column className="self-center rounded-full p-1 bg-primary-500">
                <ChromeColorizedSvg className="w-12 h-12" />
              </Column>
            </Column>
            <Separator size="xs" />
            <Text size="xs" color="white" className="text-center">
              Start sourcing with our <b>Talent CRM</b>
            </Text>
            <Text size="xs" color="white" className="text-center">
              Chrome Extension
            </Text>
            <Separator size="xs" />
            <a
              className="text-white flex flex-col justify-center items-center text-xs font-bold uppercase min-h-8 px-md rounded-full cursor-pointer focus:outline-none hover:text-white focus:ring focus:ring-primary-200"
              href="https://chrome.google.com/webstore/detail/talent-crm-source-chrome/efpmfikikojpigfhfgjjmbmkapboliio"
              target="_blank"
              rel="noopener noreferrer"
              style={{ backgroundColor: '#32247A' }}
            >
              Install now
            </a>
            <Separator size="xs" />
          </Column>
        </Column>
      </Column>
    </CommonSidebar>
  )
}

export default Sidebar

function SidebarItem({
  to,
  exact,
  children,
}: {
  to: string
  exact: boolean
  children: React.ReactNode | React.ReactNodeArray
}) {
  return (
    <NavLink
      id={slugify(to, { suffix: 'navlink' })}
      className="flex flex-col text-sm focus:outline-none transition hover:bg-primary-900"
      exact={exact}
      to={to}
      activeStyle={{
        cursor: 'default',
        fontWeight: 'bold',
        borderLeft: '4px solid white',
        color: 'white',
        background: 'linear-gradient(90deg, #3F2F9D 0%, #4734AD 94.83%)',
      }}
      style={{
        borderLeft: '4px solid rgba(0, 0, 0, 0)',
        color: 'rgba(255, 255, 255, 0.56)',
      }}
    >
      <Row className="min-h-12 items-center px-md">{children}</Row>
    </NavLink>
  )
}

function SidebarExternalItem({
  url,
  children,
}: {
  url: string
  children: React.ReactNode | React.ReactNodeArray
}) {
  return (
    <a
      className="flex flex-col text-sm focus:outline-none transition hover:bg-primary-900"
      href={`${url}`}
      style={{
        borderLeft: '4px solid rgba(0, 0, 0, 0)',
        color: 'rgba(255, 255, 255, 0.56)',
      }}
    >
      <Row className="min-h-12 items-center px-md">{children}</Row>
    </a>
  )
}

function SidebarUserInfo({
  user,
  logOut,
  loggingOut,
  impersonate,
}: {
  user: User
  logOut: () => void
  loggingOut: boolean
  impersonate?: any
}) {
  const title = impersonate ? 'Stop Impersonating' : 'Log Out'

  return (
    <>
      <Row className="flex px-md py-xs justify-between">
        <Column>
          <Separator size="xs" />

          <IconButton
            id="logout-button"
            variant="outline"
            intent="white"
            size="xs"
            shape="square"
            onClick={logOut}
            pending={loggingOut}
          >
            <LogoutSvg className="w-4" />
          </IconButton>
        </Column>
        <Separator size="xs" />

        <Column>
          <Text
            color="white"
            fontWeight="semibold"
            className="flex-grow"
            size="sm"
          >
            {user.name}
          </Text>
          <Row>
            <Text color="white" className="flex-grow truncate w-40" size="xs">
              {user.email}
            </Text>
          </Row>
        </Column>
      </Row>
    </>
  )
}
