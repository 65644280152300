import { useQuery } from 'react-query'
import getOverallStatsService, {
  Stats,
} from 'services/get-overall-stats-service'

type UseGetOverallStatsProps = {
  startDate: string
  endDate: string
}

function useGetOverallStats({ startDate, endDate }: UseGetOverallStatsProps) {
  const { data, error, status } = useQuery(['/stats', startDate, endDate], () =>
    getOverallStatsService({ startDate, endDate })
  )
  return {
    data: data || ({} as Stats),
    pending: status === 'loading',
    error,
  }
}

export default useGetOverallStats
