import { ReactComponent as ArchiveSvg } from '@trinsly/common/src/assets/icons/archive.svg'
import { ReactComponent as UnarchiveSvg } from '@trinsly/common/src/assets/icons/unarchive.svg'
import Row from '@trinsly/common/src/components/atoms/row'
import IconButton from '@trinsly/common/src/components/molecules/icon-button'
import useArchiveCampaign from 'api-hooks/use-archive-campaign'
import CampaignType from 'types/campaign-type'

type ArchiveCampaignButtonProps = {
  campaign: CampaignType
  disabled: boolean
}

function ArchiveCampaignButton({
  campaign,
  disabled,
}: ArchiveCampaignButtonProps) {
  const { fetch: archiveCampaign, pending } = useArchiveCampaign({
    onSuccess: (data) => {},
  })

  return (
    <IconButton
      testId="archive-button"
      size="sm"
      variant="ghost"
      shape="circle"
      intent={campaign?.isArchived ? 'info' : 'danger'}
      disabled={disabled}
      pending={pending}
      onClick={() =>
        archiveCampaign({
          archive: !campaign?.isArchived,
          campaignId: campaign?.id,
        })
      }
    >
      <Row className="items-center gap-xs">
        {campaign?.isArchived ? <UnarchiveSvg /> : <ArchiveSvg />}
      </Row>
    </IconButton>
  )
}

export default ArchiveCampaignButton
