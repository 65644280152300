import { ReactComponent as GoogleSvg } from '@trinsly/common/src/assets/icons/google.svg'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'

import useVerifyGoogleAuthCode from 'api-hooks/use-verify-google-auth-code'
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login' //we do not use nylas for google

type GoogleAuthButtonProps = {
  onSuccess: () => void
  onError: (error: any) => void
  email: string
  buttonText?: string
}

function GoogleAuthButton({
  onSuccess,
  onError,
  email,
  buttonText = 'Connect GMail',
}: GoogleAuthButtonProps) {
  const { fetch: verifyCode, pending: verificationPending } =
    useVerifyGoogleAuthCode({
      onSuccess: () => {
        console.log("Auth'd gmail successfully")
        onSuccess()
      },
      onError: onError,
    })

  return (
    <GoogleLogin
      //@ts-ignore
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
      cookiePolicy="single_host_origin"
      prompt="consent"
      responseType="code"
      onSuccess={(
        response: GoogleLoginResponse | GoogleLoginResponseOffline
      ) => {
        verifyCode({
          code: response.code || '',
          email: email || '',
        })
      }}
      onFailure={onError}
      render={(renderProps) => (
        <Button
          variant="outline"
          size="md"
          intent="info"
          onClick={renderProps.onClick}
          disabled={verificationPending}
          pending={verificationPending}
        >
          <>
            <GoogleSvg className="w-6 h-6" />
            <Separator />
            <Text fontWeight="semibold"> {buttonText ?? 'Connect GMail'}</Text>
          </>
        </Button>
      )}
      buttonText={buttonText}
      scope="email profile https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/gmail.readonly"
    />
  )
}
export default GoogleAuthButton
