import api from '@trinsly/common/src/services/api'
import ProspectHistoryType, { ResponseType } from 'types/prospect-history-type'

export type UpdateReachOutResponseTypeProps = {
  reachOutId: number
  responseType: ResponseType
}

async function updateReachOutResponseTypeService({
  reachOutId,
  responseType,
}: UpdateReachOutResponseTypeProps) {
  const response = await api(`/email_threads/${reachOutId}/mark_response`, {
    method: 'POST',
    body: { response_type: responseType },
  })
  return response as any as ProspectHistoryType
}

export default updateReachOutResponseTypeService
