import { ReactComponent as DownloadSvg } from '@trinsly/common/src/assets/icons/download.svg'
import { ReactComponent as PlusSvg } from '@trinsly/common/src/assets/icons/plus.svg'
import Badge from '@trinsly/common/src/components/atoms/badge'
import Card from '@trinsly/common/src/components/atoms/card'
import Clickable from '@trinsly/common/src/components/atoms/clickable'
import Column from '@trinsly/common/src/components/atoms/column'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Link from '@trinsly/common/src/components/atoms/link'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Table from '@trinsly/common/src/components/atoms/table'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import Pagination from '@trinsly/common/src/components/molecules/pagination'
import NonIdealState from '@trinsly/common/src/components/organisms/non-ideal-state'
import usePageable from '@trinsly/common/src/hooks/use-pageable'
import useGetCampaigns from 'api-hooks/use-get-campaigns'
import PrivateLayout from 'components/private-layout'
import moment from 'moment'
import { useAuth } from 'providers/auth-provider'
import React from 'react'
import { useHistory } from 'react-router'
import exportCampaignTableService from 'services/export-campaign-table-service'
import { CampaignFilterScopeEnum } from 'services/get-campaigns-service'
import CampaignType from 'types/campaign-type'
import {
  calculateOpenedCount,
  campaignResponseRate,
} from '../dashboard-screen/campaign-section'
import ArchiveCampaignButton from './archive-campaign-button'
import CampaignExpandedRow from './campaign-expanded-row'
import SeeDraftsButton from './drafts-section/see-drafts-button'
import DuplicateCampaignButton from './duplicate-campaign-button'
import Filters from './filters'
import ReportingsCampaignButton from './reportings-campaign-button'
import ShareCampaignButton from './share-campaign-button'

type CampaignsScreenProps = {}

function CampaignsScreen({}: CampaignsScreenProps) {
  const history = useHistory()
  const { user } = useAuth()
  const { pageable, setFilters, setSort, nextPage, prevPage } = usePageable({
    page: 1,
    perPage: 10,
    sort: { created_at: 'DESC' },
  })

  const { data: campaigns, total, pending } = useGetCampaigns(pageable)
  const hasData = pending || (!pending && campaigns.length > 0)
  const isSharedState =
    pageable?.filters?.state === CampaignFilterScopeEnum.SHARED

  return (
    <PrivateLayout>
      <Column className="py-md px-lg bg-white shadow">
        <Heading size="xl" fontWeight="bold">
          Campaigns
        </Heading>
        <Separator />
        <Row className="items-center">
          <Filters />
          <Separator />
          <Row className="items-center gap-md">
            <SeeDraftsButton />
            <Button
              id="create-campaign-button"
              intent="primary"
              shape="rounded"
              onClick={() => history.push('/campaigns/new')}
            >
              <Row className="items-center">
                <PlusSvg className="w-md h-md" />
                <Separator size="xs" />
                New Campaign
              </Row>
            </Button>
          </Row>
        </Row>
      </Column>
      <Separator size="lg" />
      <Column className="px-lg">
        <Card className="overflow-hidden">
          {!hasData ? (
            <Column className="p-md">
              <NonIdealState message="No campaigns yet" />
            </Column>
          ) : (
            <React.Fragment>
              <Table
                pending={pending}
                pageSize={pageable?.perPage}
                id="campaigns-table"
                data={campaigns as (CampaignType & { [index: string]: any })[]}
                columns={[
                  {
                    width: 210,
                    sort: pageable?.sort?.name,
                    onSort: (direction) => setSort('name', direction as any),
                    header: <Column className="">Name (#of Stages)</Column>,
                    cell: (item) => {
                      return (
                        <Row className="items-center flex-wrap-reverse gap-xs">
                          <Link href={`/campaigns/${item.id}`}>
                            <Text size="xs">
                              {item.templates
                                ? `${item.name} (${item.templates.length})`
                                : item.name}
                            </Text>
                          </Link>
                          {item.isArchived && (
                            <Badge size="xs" intent="warning">
                              Archived
                            </Badge>
                          )}
                        </Row>
                      )
                    },
                  },
                  {
                    width: 90,
                    header: 'Created',
                    align: 'right',
                    sort: pageable?.sort?.['created_at'],
                    onSort: (direction) =>
                      setSort('created_at', direction as any),
                    cell: (item) => (
                      <Text size="xs" fontWeight="light">
                        {moment(item.createdAt, moment.ISO_8601).format(
                          'M/D/YY'
                        )}
                      </Text>
                    ),
                  },
                  isSharedState
                    ? {
                        width: 150,
                        sort: pageable?.sort?.ownerName,
                        onSort: (direction) =>
                          setSort('ownerName', direction as any),
                        header: 'Author',
                        cell: (item) => item.ownerName,
                      }
                    : null,
                  {
                    width: 80,
                    sort: pageable?.sort?.['stats.reach_out_count'],
                    onSort: (direction) =>
                      setSort('stats.reach_out_count', direction as any),
                    header: 'Total Sent',
                    align: 'right',
                    cell: (item) => (
                      <Text fontWeight="bold">
                        {item.stats?.reachOutCount ?? 0}
                      </Text>
                    ),
                  },
                  {
                    width: 100,
                    sort: pageable?.sort?.['stats.responses_count'],
                    onSort: (direction) =>
                      setSort('stats.responses_count', direction as any),
                    header: 'Responded',
                    align: 'right',
                    cell: (item) => (
                      <>
                        <Text fontWeight="bold">
                          {item.stats?.responsesCount ?? 0}
                        </Text>
                        &nbsp; &nbsp;
                        <Text size="xs" fontWeight="light">
                          (
                          {campaignResponseRate(
                            item.stats?.responsesCount,
                            item.stats?.reachOutCount
                          )}
                          )
                        </Text>
                      </>
                    ),
                  },
                  {
                    width: 100,
                    sort: pageable?.sort?.['stats.open_count'],
                    onSort: (direction) =>
                      setSort('stats.open_count', direction as any),
                    header: 'Opened',
                    align: 'right',
                    cell: (item) => (
                      <>
                        <Text fontWeight="bold">
                          {calculateOpenedCount(
                            item.stats?.openCount ?? 0,
                            item.stats?.responsesCount ?? 0
                          )}
                        </Text>
                        &nbsp; &nbsp;
                        <Text size="xs" fontWeight="light">
                          (
                          {campaignResponseRate(
                            calculateOpenedCount(
                              item.stats?.openCount ?? 0,
                              item.stats?.responsesCount ?? 0
                            ),
                            item.stats?.reachOutCount
                          )}
                          )
                        </Text>
                      </>
                    ),
                  },
                  {
                    width: 100,
                    sort: pageable?.sort?.['stats.interested_count'],
                    onSort: (direction) =>
                      setSort('stats.interested_count', direction as any),
                    header: 'Interested',
                    align: 'right',
                    cell: (item) => (
                      <>
                        <Text fontWeight="bold">
                          {item.stats?.interestedCount ?? 0}
                        </Text>
                        &nbsp; &nbsp;
                        <Text size="xs" fontWeight="light">
                          (
                          {campaignResponseRate(
                            item.stats?.interestedCount,
                            item.stats?.reachOutCount
                          )}
                          )
                        </Text>
                      </>
                    ),
                  },
                  {
                    width: 80,
                    sort: pageable?.sort?.['stats.in_progress_count'],
                    onSort: (direction) =>
                      setSort('stats.in_progress_count', direction as any),
                    header: 'In Progress',
                    align: 'right',
                    cell: (item) => (
                      <Text fontWeight="bold">
                        {item.stats?.inProgressCount ?? 0}
                      </Text>
                    ),
                  },
                  {
                    cell: (item) => (
                      <Row className="space-x-xs">
                        {(item.tagList || []).map((tag, index) => (
                          <Clickable
                            key={index}
                            onClick={() => {
                              const currentTags: string[] =
                                pageable?.filters?.tags?.split(',') || []
                              const exists = currentTags.some(
                                (existingTag: string) => existingTag === tag
                              )
                              if (!exists) {
                                setFilters(
                                  'tags',
                                  [...currentTags, tag].join(',')
                                )
                              }
                            }}
                          >
                            <Badge size="xs" intent="primary">
                              {tag}
                            </Badge>
                          </Clickable>
                        ))}
                      </Row>
                    ),
                  },
                  {
                    header: (
                      <Clickable
                        id="export-csv-button"
                        onClick={() => exportCampaignTableService()}
                      >
                        <Row className="items-center gap-xs">
                          <DownloadSvg />
                          <Text size="sm" fontWeight="bold" color="primary-900">
                            EXPORT CSV
                          </Text>
                        </Row>
                      </Clickable>
                    ),
                    cell: (item) => {
                      const isMine = item.ownerId === user?.id
                      return (
                        <Row className="items-center space-x-sm">
                          <ReportingsCampaignButton
                            campaign={item}
                            disabled={false}
                          />
                          {isMine && (
                            <Column className="min-w-28">
                              <ShareCampaignButton
                                campaign={item}
                                disabled={!Boolean(user?.organizationId)}
                              />
                            </Column>
                          )}
                          {isSharedState && !isMine && (
                            <DuplicateCampaignButton
                              campaign={item}
                              disabled={false}
                            />
                          )}
                          {!isSharedState && (
                            <ArchiveCampaignButton
                              campaign={item}
                              disabled={false}
                            />
                          )}
                        </Row>
                      )
                    },
                  },
                ]}
                expandedRow={(item) => (
                  <Column>
                    <CampaignExpandedRow campaign={item} />
                  </Column>
                )}
              />
              <Row className="items-center py-sm px-md justify-end">
                <Pagination
                  page={pageable?.page}
                  total={total || 0}
                  perPage={pageable?.perPage}
                  onNext={nextPage}
                  onPrev={prevPage}
                />
              </Row>
            </React.Fragment>
          )}
        </Card>
        <Separator size="xl" />
        <Separator size="xl" />
      </Column>
    </PrivateLayout>
  )
}

export default CampaignsScreen
