import { ReactComponent as MailSvg } from '@trinsly/common/src/assets/icons/unread-email.svg'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import { useState } from 'react'

function CustomMailAuth({
  email: email,
  disabled = false,
}: {
  email: string
  disabled?: boolean
}) {
  const [pending, setPending] = useState(false)
  return (
    <>
      <Button
        intent="info"
        variant="outline"
        size="md"
        onClick={() => {
          setPending(true)
          window.location.href = nylasOAuthUrl({
            email: email,
          })
        }}
        pending={pending}
        disabled={disabled}
      >
        <MailSvg className="w-6 h-6" />
        <Separator />
        <Text fontWeight="semibold">Custom Server</Text>
      </Button>
      <Separator size="xxs" />
      <Text size="xs">*Includes custom IMAP/SMTP and Exchange servers</Text>
    </>
  )
}

export default CustomMailAuth

// https://docs.nylas.com/reference#oauth
function nylasOAuthUrl({ email }: { email: string }) {
  const url = appendQueryParams('https://api.nylas.com/oauth/authorize', {
    client_id: process.env.REACT_APP_NYLAS_CLIENT_ID,
    login_hint: email,
    redirect_uri: `${
      window.location.origin || ''
    }/email-provider-authorization`, //nylas callback route
    response_type: 'code',
    scopes: 'email.send,email.read_only',
  })

  return url
}

const appendQueryParams = (
  baseUrl: string,
  queryParams: { [index: string]: any }
) => {
  const queryParamsStr = Object.keys(queryParams).reduce(
    (str: string, key) =>
      queryParams[key]
        ? `${str}${str ? '&' : '?'}${key}=${encodeURIComponent(
            queryParams[key]
          )}`
        : str,
    ''
  )

  return baseUrl + queryParamsStr
}
