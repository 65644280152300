import api from '@trinsly/common/src/services/api'

export type CheckTrackerDomainServiceProps = {
  id: number
}

export async function checkTrackerDomainService({
  id,
}: CheckTrackerDomainServiceProps) {
  const response = await api(`/tracker_domains/${id}/check`, {
    method: 'POST',
  })

  return { id }
}
