import api from '@trinsly/common/src/services/api'
import ProjectType from 'types/project-type'
import ProspectType from 'types/prospect-type'

type DeleteProspectFromProjectServiceProps = {
  projectId: ProjectType['id']
  prospectId: ProspectType['id']
}

async function deleteProspectFromProjectService({
  projectId,
  prospectId,
}: DeleteProspectFromProjectServiceProps) {
  const response = await api(`/projects/${projectId}/prospects/${prospectId}`, {
    method: 'DELETE',
  })
  return
}

export default deleteProspectFromProjectService
