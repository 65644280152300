import { ReactComponent as SyncSvg } from '@trinsly/common/src/assets/icons/exchange.svg'
import { ReactComponent as LinkedinSvg } from '@trinsly/common/src/assets/icons/linkedin.svg'
import { ReactComponent as PlusSvg } from '@trinsly/common/src/assets/icons/plus.svg'
import { ReactComponent as TrinslySvg } from '@trinsly/common/src/assets/icons/trinsly.svg'

import { ReactComponent as WarningSvg } from '@trinsly/common/src/assets/icons/warning.svg'
import AvatarLetter from '@trinsly/common/src/components/atoms/avatar-letter'
import Badge from '@trinsly/common/src/components/atoms/badge'
import Card from '@trinsly/common/src/components/atoms/card'
import Checkbox from '@trinsly/common/src/components/atoms/checkbox'
import Clickable from '@trinsly/common/src/components/atoms/clickable'
import Column from '@trinsly/common/src/components/atoms/column'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Link from '@trinsly/common/src/components/atoms/link'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Spinner from '@trinsly/common/src/components/atoms/spinner'
import Table from '@trinsly/common/src/components/atoms/table'
import Text from '@trinsly/common/src/components/atoms/text'
import Tooltip from '@trinsly/common/src/components/atoms/tooltip'
import Alert from '@trinsly/common/src/components/molecules/alert'
import Animated from '@trinsly/common/src/components/molecules/animated'
import Button from '@trinsly/common/src/components/molecules/button'
import IconButton from '@trinsly/common/src/components/molecules/icon-button'
import Pagination from '@trinsly/common/src/components/molecules/pagination'
import SearchInput from '@trinsly/common/src/components/molecules/search-input'
import NonIdealState from '@trinsly/common/src/components/organisms/non-ideal-state'
import usePageable from '@trinsly/common/src/hooks/use-pageable'
import useGetIntegrations from 'api-hooks/use-get-integrations'
import useGetProspects from 'api-hooks/use-get-prospects'
import useSyncMergeIntegration from 'api-hooks/use-sync-merge-integration'
import PrivateLayout from 'components/private-layout'
import moment from 'moment'
import {
  BATCH_LIMIT,
  useProspectsSelectionContext,
} from 'providers/prospects-selection-provider'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import CrmEnum, { translateCrmEnum } from 'types/crm-enum'
import ProspectType from 'types/prospect-type'
import StatusField from '../prospect-detail-screen/dashboard-section/header-section/status-field'
import AdvancedFiltersSection from './advanced-filters-section'
import CreateProspectFormModal from './create-prospect-form-modal'
import FiltersSection from './filters-section'
import HotlistButton from './hotlists-section/hotlist-button'
import SendCampaignFormModal from './send-campaign-form-modal'

function ProspectsScreen() {
  const history = useHistory()
  const {
    pending: pendingAllProspects,
    allProspects,
    selectedProspects,
    toggleAll,
    toggleProspect,
    isAtLeastOneProspectSelected,
    areAllProspectsSelected,
  } = useProspectsSelectionContext()

  const { pageable, setSearch, prevPage, nextPage } = usePageable({
    page: 1,
    perPage: 10,
  })
  const { data: prospects, total, pending } = useGetProspects(pageable)

  const hasAdvancedFilters =
    Boolean(pageable?.filters?.status) ||
    Boolean(pageable?.filters?.creator) ||
    Boolean(pageable?.filters?.campaign_id)
  Boolean(pageable?.filters?.email_thread_state) ||
    Boolean(pageable?.filters?.last_contacted_at_after) ||
    Boolean(pageable?.filters?.email_thread_opened_state)

  const [advancedFiltersExpanded, setAdvancedFiltersExpanded] =
    React.useState(hasAdvancedFilters)
  const [showProspectFormModal, setShowProspectFormModal] = useState(false)
  const [showSendCampaign, setShowSendCampaign] = React.useState(false)
  const [showingProspect, setShowingProspect] = React.useState<ProspectType>()

  const isNonIdealState = !pending && !prospects?.length
  const selectedProspectsIds = selectedProspects.map((prospect) => prospect.id)

  return (
    <PrivateLayout>
      {allProspects.length > BATCH_LIMIT && (
        <Alert intent="info" variant="translucent">
          <Text fontWeight="semibold">
            Can only select all if you have filtered to less than {BATCH_LIMIT}{' '}
            prospects.
          </Text>
        </Alert>
      )}
      <Column className="bg-white shadow">
        <Column className="py-md px-lg">
          <Heading size="xl" fontWeight="bold">
            Prospects
          </Heading>
          <Separator />
          <Row className="items-start gap-md flex-wrap">
            <Row className="flex-1 gap-xs">
              <Column className="min-w-80 gap-xs">
                <SearchInput
                  id="search-for-prospect-search-input"
                  placeholder="Search email, name, tags etc."
                  value={pageable?.search}
                  onChange={(value) => setSearch(value)}
                />
                <Row>
                  <Clickable
                    className="gap-1 items-center min-h-5"
                    onClick={() =>
                      setAdvancedFiltersExpanded(!advancedFiltersExpanded)
                    }
                  >
                    <Text color="primary-500" size="xs">
                      {advancedFiltersExpanded
                        ? 'Hide Advanced Filters'
                        : 'Advanced Filters'}
                    </Text>
                    {hasAdvancedFilters && (
                      <Badge size="xs" intent="primary">
                        ...
                      </Badge>
                    )}
                  </Clickable>
                </Row>
              </Column>
              <FiltersSection />
            </Row>
            <Row className="items-center">
              {selectedProspects.length > 0 && (
                <Button
                  variant="outline"
                  intent="primary"
                  shape="rounded"
                  disabled={!isAtLeastOneProspectSelected}
                  onClick={() => {
                    history.push(`/batches/new?source=prospects`)
                  }}
                >
                  Create Batch from selected prospects (
                  {selectedProspects.length})
                </Button>
              )}
              <Separator size="sm" />
              <Button
                intent="primary"
                shape="rounded"
                onClick={() => setShowProspectFormModal(true)}
              >
                <Row className="items-center">
                  <PlusSvg className="w-md h-md" />
                  <Separator size="xs" />
                  Add Prospect
                </Row>
              </Button>
            </Row>
          </Row>
        </Column>
        <Animated>
          {advancedFiltersExpanded && (
            <Column className="py-xs pb-md px-lg">
              <AdvancedFiltersSection />
            </Column>
          )}
        </Animated>
      </Column>
      <Separator size="lg" />
      <Column className="px-lg">
        <Card className="overflow-hidden">
          {isNonIdealState ? (
            <Column className="p-md">
              <NonIdealState message="No prospects yet" />
            </Column>
          ) : (
            <React.Fragment>
              <Table
                pending={pending}
                data={prospects}
                pageSize={pageable?.perPage}
                columns={[
                  {
                    width: 32,
                    header: (
                      <Row className="items-center">
                        {pendingAllProspects ? (
                          <Spinner size="sm" />
                        ) : (
                          <Checkbox
                            disabled={allProspects.length > BATCH_LIMIT}
                            checked={
                              areAllProspectsSelected &&
                              isAtLeastOneProspectSelected
                            }
                            onChange={toggleAll}
                          />
                        )}
                      </Row>
                    ),
                    cell: (item) => {
                      const isChecked = selectedProspectsIds.includes(item.id)
                      return (
                        <Row className="items-center">
                          <Checkbox
                            checked={isChecked}
                            onChange={() => toggleProspect(item)}
                            disabled={!isChecked && areAllProspectsSelected}
                          />
                        </Row>
                      )
                    },
                  },
                  {
                    width: 20,
                    header: '',
                    className: 'p-0 m-0',
                    cell: (item) => {
                      if (Boolean(item?.email)) {
                        return null
                      }

                      return (
                        <Column className="justify-center items-center">
                          <Tooltip
                            content={
                              <Column>
                                <Text
                                  size="sm"
                                  fontWeight="bold"
                                  className="text-center"
                                >
                                  Missing contact info.
                                </Text>
                                <Text size="sm" className="text-center">
                                  Please add email address before attempting to
                                  send outreach.
                                </Text>
                              </Column>
                            }
                            children={
                              <WarningSvg
                                className={`"w-sm h-sm text-warning-500`}
                              />
                            }
                          />
                        </Column>
                      )
                    },
                  },
                  {
                    width: 200,
                    header: 'Prospect',
                    cell: (item) => {
                      const initials =
                        item?.firstName?.[0] ||
                        item?.lastName?.[0] ||
                        item?.email?.[0] ||
                        ''
                      return (
                        <Link href={`/prospects/${item?.id}`}>
                          <Row className="items-center gap-xs">
                            <AvatarLetter size="lg" initials={initials} />
                            <Column>
                              <Text
                                size="sm"
                                color="primary-900"
                                fontWeight="semibold"
                              >
                                {item?.name}
                              </Text>
                              <Text size="xs">{item?.email}</Text>
                            </Column>
                          </Row>
                        </Link>
                      )
                    },
                  },
                  {
                    width: 100,
                    header: 'Status',
                    cell: (item) => <StatusField status={item.status} />,
                  },

                  {
                    width: 100,
                    header: 'Owner',
                    cell: (item) => item.creator.name ?? '',
                  },
                  {
                    width: 100,
                    header: 'Last Contacted',
                    cell: ({ lastContactedAt }) =>
                      lastContactedAt
                        ? moment(lastContactedAt).format('l')
                        : 'N/A',
                  },
                  {
                    width: 100,
                    header: 'Actions',
                    cell: (prospect) => {
                      const hasLinkedin = Boolean(prospect?.linkedinName)
                      return (
                        <Row className="items-center gap-sm">
                          <Link
                            className="w-lg h-lg items-center justify-center"
                            disabled={!hasLinkedin}
                            href={`https://www.linkedin.com/in/${prospect?.linkedinName}/`}
                            title={`https://www.linkedin.com/in/${prospect?.linkedinName}/`}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            <LinkedinSvg
                              className={`"w-lg h-lg ${
                                hasLinkedin ? 'text-info-500' : 'text-gray-200'
                              }`}
                            />
                          </Link>
                          <Tooltip
                            content="Send a prospect a new campaign."
                            children={
                              <IconButton
                                size="sm"
                                intent="primary"
                                variant="transparent"
                                disabled={!prospect?.email}
                                onClick={() => {
                                  setShowSendCampaign(true)
                                  setShowingProspect(prospect)
                                }}
                              >
                                <TrinslySvg />
                              </IconButton>
                            }
                          />

                          <HotlistButton prospectIds={[prospect?.id]} />
                        </Row>
                      )
                    },
                  },
                  {
                    width: 50,
                    header: () => <SyncAtsCandidatesButton />,
                    cell: () => {
                      return <></>
                    },
                  },
                ]}
              />
              <Row className="items-center py-sm px-md justify-end">
                <Pagination
                  total={total}
                  page={pageable?.page}
                  perPage={pageable?.perPage}
                  onNext={nextPage}
                  onPrev={prevPage}
                />
              </Row>
            </React.Fragment>
          )}
        </Card>
        <Separator size="xl" />
        <Separator size="xl" />
      </Column>
      <SendCampaignFormModal
        prospect={showingProspect}
        visible={showSendCampaign}
        onRequestClose={() => {
          setShowSendCampaign(false)
        }}
      />
      <CreateProspectFormModal
        visible={showProspectFormModal}
        onRequestClose={() => setShowProspectFormModal(false)}
      />
    </PrivateLayout>
  )
}

export default ProspectsScreen

/**
 * Starts a full sync of ATS candidates/prospects
 * @returns
 */
function SyncAtsCandidatesButton() {
  const { data: integrations, pending: integrationsPending } =
    useGetIntegrations()
  const { fetch: startSync, pending: syncStartPending } =
    useSyncMergeIntegration({
      onError: () => {
        toast.error('Failed to start ATS sync - please try again later.')
      },
      onSuccess: () => {
        toast.success('ATS Sync Started - This may take a little bit.')
      },
    })
  return (
    <>
      {integrations.map((i) => {
        const integrationEnum = (i?.crmType?.replace?.('merge_', '') ||
          '') as CrmEnum
        const integrationName = translateCrmEnum[integrationEnum] || 'ATS'

        return (
          <Column className="w-12">
            <Tooltip
              children={
                <IconButton
                  variant="ghost"
                  intent="info"
                  shape="square"
                  pending={syncStartPending}
                  onClick={() => {
                    startSync({ integrationId: i.id })
                  }}
                >
                  <SyncSvg />
                </IconButton>
              }
              placement="bottom-end"
              content={`Last Sync: ${new Date(i.lastSync).toLocaleString()}`}
            ></Tooltip>
          </Column>
        )
      })}
    </>
  )
}
