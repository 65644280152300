import api from '@trinsly/common/src/services/api'
import CampaignType from 'types/campaign-type'

type ArchiveCampaignServiceProps = {
  campaignId: CampaignType['id']
  archive: boolean
}

async function archiveCampaignService({
  campaignId,
  archive,
}: ArchiveCampaignServiceProps) {
  const response = await api(
    `/campaigns/${campaignId}/${archive ? 'archive' : 'unarchive'}`,
    {
      method: 'POST',
    }
  )
  return response as CampaignType
}

export default archiveCampaignService
