//Step 1
async function requestPasswordResetService({ email }: { email: string }) {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/auth/password_reset`,
    {
      body: JSON.stringify({
        password_reset: {
          email: email,
        },
      }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Trinsly-Client': 'web',
      },
    }
  )

  if (response.status > 299) {
    throw new Error('Cannot handle password reset request')
  }
  return
}

export default requestPasswordResetService
