import Column from '@trinsly/common/src/components/atoms/column'
import Popover from '@trinsly/common/src/components/atoms/popover'
import Row from '@trinsly/common/src/components/atoms/row'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import useDeleteHotlist from 'api-hooks/use-delete-hotlist'
import React from 'react'
import { toast } from 'react-toastify'
import HotlistType from 'types/hotlist-type'

type DeleteHotlistConfirmationPopoverProps = {
  children: React.ReactNode
  hotlist: HotlistType | undefined
}

function DeleteHotlistConfirmationPopover({
  hotlist,
  children,
}: DeleteHotlistConfirmationPopoverProps) {
  const targetParentRef = React.useRef<HTMLDivElement>(null)
  const { fetch: deleteHotlist, pending: pendingDeleteHotlist } =
    useDeleteHotlist({
      onSuccess: () => {
        toast.success('Hotlist deleted!')
      },
      onError: (error) => {
        const errorMessage = error?.message || 'Error deleting hotlist'
        toast.error(errorMessage)
      },
    })

  return (
    <Popover
      target={<Column ref={targetParentRef}>{children}</Column>}
      placement="top-start"
    >
      <Column className="p-sm gap-sm">
        <Text size="sm">
          Are you sure you want to delete <b>{hotlist?.name}</b>?
        </Text>
        <Row className="items-center gap-xs">
          <Button
            size="xs"
            className="min-w-20"
            onClick={() => {
              targetParentRef?.current?.click?.()
            }}
          >
            Close
          </Button>
          <Button
            className="min-w-20"
            intent="danger"
            size="xs"
            pending={pendingDeleteHotlist}
            onClick={() => {
              deleteHotlist({ hotlistId: hotlist?.id as any })
            }}
          >
            Delete
          </Button>
        </Row>
      </Column>
    </Popover>
  )
}

export default DeleteHotlistConfirmationPopover
