import Column from '@trinsly/common/src/components/atoms/column'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Text from '@trinsly/common/src/components/atoms/text'
import React from 'react'
import LogoSection from './logo-section'

function SourcingContent() {
  return (
    <React.Fragment>
      <Column className="p-md" style={{ maxWidth: '72ch' }}>
        <Heading size="4xl" color="white" fontWeight="bold">
          Operationalize your sourcing with Talent CRM
        </Heading>
        <Separator size="xl" />
        <Text size="xl" color="white" fontWeight="bold">
          Increase efficiency with our All-in-one applicant sourcing Platform
        </Text>
        <Separator />
        <Column className="border-l-4 border-white min-h-12 py-xs">
          <Text color="white" className="pl-md">
            <b>Source from anywhere</b>: Using our chrome extension, Talent CRM
            finds contact information right from Linkedin, Github and other
            websites you already use to find prospects
          </Text>
        </Column>
        <Separator />
        <Column className="border-l-4 border-white min-h-12 py-xs">
          <Text color="white" className="pl-md">
            Automated personalized messages that{' '}
            <b>increase response rates by 2x!</b>
          </Text>
        </Column>
        <Separator />
        <Column className="border-l-4 border-white min-h-12 py-xs">
          <Text color="white" className="pl-md">
            Guarantee no overlapping outreach with your team using{' '}
            <b>Talent CRM’s candidate relationship management.</b>
          </Text>
        </Column>
        <Separator size="xl" />
        <Text size="xl" color="white">
          Trusted by <b>1000’s of recruiters</b> everywhere
        </Text>
        <Separator size="sm" />
        <LogoSection />
      </Column>
    </React.Fragment>
  )
}

export default SourcingContent
