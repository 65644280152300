import Column from '@trinsly/common/src/components/atoms/column'
import Row from '@trinsly/common/src/components/atoms/row'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import EmailTemplateType from 'types/email-template-type'
import ProspectType from 'types/prospect-type'
import EmailListItem from './email-list-item'

type CustomizeStepProps = {
  prospect: ProspectType | undefined
  getValue: (path: string) => any
  getError: (path: string) => any
  setValue: (path: string, value: any) => void
  pending: boolean
}

function CustomizeStep({
  prospect,
  getValue,
  getError,
  setValue,
  pending,
}: CustomizeStepProps) {
  const emails = (getValue('custom.emails') || []) as EmailTemplateType[]

  return (
    <Column className="gap-md pt-sm">
      <Row className="items-center gap-sm">
        <Column className="min-w-56">
          <Text size="xs" fontWeight="bold" className="uppercase">
            Email (current stage)
          </Text>
          <Text size="sm">{prospect?.email || '--'}</Text>
        </Column>
        <Column>
          <Text size="xs" fontWeight="bold" className="uppercase">
            Campaign
          </Text>
          <Text size="sm">{getValue('campaign.name') || '--'}</Text>
        </Column>
      </Row>
      <Column className="gap-3">
        {emails?.map((email, index) => (
          <EmailListItem
            index={index}
            getValue={getValue}
            getError={getError}
            setValue={setValue}
          />
        ))}
      </Column>
      <Column className="items-end">
        <Column className="min-w-52">
          <Button type="submit" intent="primary" pending={pending}>
            Send Campaign
          </Button>
        </Column>
      </Column>
    </Column>
  )
}

export default CustomizeStep
