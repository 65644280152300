import FullScreenSpinner from 'components/full-screen-spinner'
import { useAuth } from 'providers/auth-provider'
import React from 'react'

type LogoutScreenProps = {}
const LogoutScreen = ({}: LogoutScreenProps) => {
  const { logOut } = useAuth()

  React.useEffect(() => {
    logOut({})
  }, [])

  return <FullScreenSpinner />
}

export default LogoutScreen
