import React from 'react'
import ReactQuill from 'react-quill'

import 'react-quill/dist/quill.snow.css'
import QuillEditor, { QuillEditorProps } from '.'
import Column from '../../atoms/column'
import Label from '../../atoms/label'
import Row from '../../atoms/row'
import Separator from '../../atoms/separator'
import Switch, { SwitchProps } from '../../atoms/switch'
import TextInput, { TextInputProps } from '../text-input'
import './styles.css'
import Variables from './variables'

type EmailTemplateInputProps = {
  subject: Omit<TextInputProps, 'ref'>
  isInThread?: Omit<SwitchProps, 'ref'>
  body: Omit<QuillEditorProps, 'ref'> & { label?: string; required?: boolean }
}

function EmailTemplateInput({
  subject,
  body,
  isInThread,
}: EmailTemplateInputProps) {
  const lastFocusedRef = React.useRef<'body' | 'subject'>('body')
  const subjectRef = React.useRef<HTMLInputElement>(null)
  const reactQuillRef = React.useRef<ReactQuill>(null)
  const letterCountRef = React.useRef(0)
  const shortMessage =
    'Short, informative messages tend to get a better response rate!'
  const shouldShowWarningMessage = letterCountRef.current > 500

  return (
    <Column>
      <Row className="items-end gap-md">
        <TextInput
          ref={subjectRef}
          {...subject}
          onFocus={(event) => {
            subject?.onFocus?.(event)
            lastFocusedRef.current = 'subject'
          }}
        />
        {Boolean(isInThread) && (
          <Row className="items-center whitespace-nowrap min-h-xl gap-xs">
            <Switch
              id={isInThread?.id}
              testId={isInThread?.testId}
              disabled={isInThread?.disabled}
              checked={isInThread?.checked}
              onChange={isInThread?.onChange}
            />
            <Label>Is in thread</Label>
          </Row>
        )}
      </Row>
      <Separator />
      <Column className="gap-xs">
        {body?.label && (
          <Label
            htmlFor={body?.id}
            color={body?.error ? 'danger-500' : undefined}
          >
            {body?.label}
            {body?.required ? ' *' : undefined}
          </Label>
        )}
        <QuillEditor
          ref={reactQuillRef}
          disabled={body?.disabled}
          error={body?.error}
          id={body?.id}
          modules={body?.modules}
          onChange={(event) => {
            letterCountRef.current = event?.counter?.letters || 0
            body?.onChange?.(event)
          }}
          value={body?.value}
          style={body?.style}
          placeholder={body?.placeholder}
          testId={body?.testId}
          onFocus={() => {
            lastFocusedRef.current = 'body'
          }}
        />
      </Column>
      {(!body?.modules || body?.modules?.includes('variables')) && (
        <Variables
          disabled={body?.disabled}
          quillInstance={reactQuillRef?.current}
          onClick={(variableId) => {
            const variableValue = `{{${variableId}}}`
            if (lastFocusedRef.current === 'body') {
              const editor = reactQuillRef?.current?.getEditor()
              if (editor) {
                var selection = editor.getSelection(true)
                editor.insertText(selection.index, variableValue, 'user')
                editor.setSelection(
                  selection.index + variableValue.length,
                  0,
                  'api'
                )
              }
              return
            }

            if (subjectRef.current) {
              const { selectionStart: start } = subjectRef.current
              subjectRef.current.setRangeText(variableValue)
              const carretPosition = (start || 0) + variableValue.length
              subjectRef.current.selectionStart = carretPosition
              subjectRef.current.selectionEnd = carretPosition
              subject.onChange?.(subjectRef.current.value, undefined)
              subjectRef.current?.focus?.()
            }
          }}
        />
      )}
      <Column style={{ height: 0 }}>
        <Column className="min-h-1" />
        <Row className="items-start gap-md justify-between">
          <span className="text-danger-500" style={{ fontSize: '0.65rem' }}>
            {body?.error}
          </span>
          <Row
            className={`${
              shouldShowWarningMessage ? 'text-danger-500' : 'text-gray-500'
            } gap-xs`}
            style={{ fontSize: '0.65rem' }}
          >
            {shouldShowWarningMessage && <span>{shortMessage}</span>}
            <span>{`${letterCountRef.current}/500`}</span>
          </Row>
        </Row>
      </Column>
    </Column>
  )
}

export default EmailTemplateInput
