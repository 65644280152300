import PageableType from '@trinsly/common/src/types/pageable-type'
import { useQuery } from 'react-query'
import getCampaignDraftsService from 'services/get-campaign-drafts-service'

type UseGetCampaignDraftsProps = {} & PageableType

function useGetCampaignDrafts(pageable: UseGetCampaignDraftsProps) {
  const { data, error, status } = useQuery(
    ['/campaigns/drafts', pageable],
    () => getCampaignDraftsService(pageable)
  )

  return {
    data: data?.drafts || [],
    total: data?.meta?.pagination?.totalObjects || 0,
    pending: status === 'loading',
    error,
  }
}

export default useGetCampaignDrafts
