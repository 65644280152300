import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation, useQueryClient } from 'react-query'
import deleteIntegrationService from 'services/delete-integration-service'

export type UseDeleteIntegrationProps = {} & MutateApiHookType<void>

function useDeleteIntegration({
  onSuccess,
  onError,
}: UseDeleteIntegrationProps) {
  const queryClient = useQueryClient()
  const { mutate: fetch, isLoading: pending } = useMutation(
    deleteIntegrationService,
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('/integrations')
        onSuccess?.()
      },
      onError,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useDeleteIntegration
