import { retrieveCredentials } from '@trinsly/common/src/services/api'
import axios from 'axios'
import CrmEnum from 'types/crm-enum'

type FinishMergeSingleIntegrationServiceProps = {
  integration: CrmEnum
  publicTokenID: string
}

async function finishMergeSingleIntegrationService({
  integration,
  publicTokenID,
}: FinishMergeSingleIntegrationServiceProps) {
  const { accessToken } = retrieveCredentials()
  const response = await axios.request({
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${process.env.REACT_APP_MERGE_API_URL}/connection`,
    data: {
      ats: integration,
      token: publicTokenID,
    },
  })

  const data = response?.data as any
  return data
}

export default finishMergeSingleIntegrationService
