import Clickable from '@trinsly/common/src/components/atoms/clickable'
import Column from '@trinsly/common/src/components/atoms/column'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Text from '@trinsly/common/src/components/atoms/text'
import usePageable from '@trinsly/common/src/hooks/use-pageable'
import CampaignFilter from './campaign-filter'
import DateRangeFilter from './date-range-filter'
import EmailThreadOpenedFilter from './email-thread-opened-filter'
import EmailThreadStateFilter from './email-thread-state-filter'
import OwnerFilter from './owner-filter'
import StatusFilter from './status-filter'

type AdvancedFiltersSectionProps = {}

function AdvancedFiltersSection({}: AdvancedFiltersSectionProps) {
  const { pageable, setFilters } = usePageable({
    page: 1,
    perPage: 10,
  })

  return (
    <Column>
      <Row>
        <Column className="flex-1">
          <Text
            color="gray-900"
            fontWeight="bold"
            className="uppercase"
            size="xs"
          >
            Advanced Filter
          </Text>
        </Column>
        <Clickable
          onClick={() => {
            setFilters((filters) => ({
              ...filters,
              status: null,
              creator: null,
              campaign_id: null,
              email_thread_state: null,
              last_contacted_at_after: null,
              email_thread_opened_state: null,
            }))
          }}
        >
          <Text
            color="primary-500"
            fontWeight="bold"
            className="uppercase"
            size="sm"
          >
            Clear Filters
          </Text>
        </Clickable>
      </Row>
      <Separator />
      <Row className="flex-wrap gap-md">
        <Column className="min-w-80">
          <StatusFilter
            onChange={(value) => setFilters('status', value)}
            value={pageable?.filters?.status as any}
          />
        </Column>
        <Column className="min-w-80">
          <OwnerFilter
            value={pageable?.filters?.creator}
            onChange={(value) => {
              if (value) {
                setFilters('creator', value)
              } else {
                setFilters((filters) => ({
                  ...filters,
                  creator: null,
                  campaign_id: null,
                  email_thread_state: null,
                  email_thread_opened_state: null,
                }))
              }
            }}
          />
        </Column>
        <Column className="min-w-80">
          <CampaignFilter
            ownerId={pageable?.filters?.creator}
            value={pageable?.filters?.campaign_id}
            onChange={(value) => {
              if (value) {
                setFilters('campaign_id', value)
              } else {
                setFilters((filters) => ({
                  ...filters,
                  campaign_id: null,
                  email_thread_state: null,
                  email_thread_opened_state: null,
                }))
              }
            }}
          />
        </Column>
        <Column className="min-w-80">
          <EmailThreadStateFilter
            disabled={!pageable?.filters?.campaign_id}
            onChange={(value) => setFilters('email_thread_state', value)}
            value={pageable?.filters?.email_thread_state}
          />
        </Column>
        <Column className="min-w-80">
          <DateRangeFilter
            value={pageable?.filters?.last_contacted_at_after as any}
            onChange={(value) =>
              setFilters('last_contacted_at_after', value as string)
            }
          />
        </Column>
        <Column className="min-w-80">
          <EmailThreadOpenedFilter
            disabled={!pageable?.filters?.campaign_id}
            onChange={(value) =>
              setFilters('email_thread_opened_state', value as string)
            }
            value={pageable?.filters?.email_thread_opened_state as any}
          />
        </Column>
      </Row>
    </Column>
  )
}

export default AdvancedFiltersSection
