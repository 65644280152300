import { ReactComponent as ChevronLeftSvg } from '@trinsly/common/src/assets/icons/chevron-left.svg'
import { ReactComponent as ChevronRightSvg } from '@trinsly/common/src/assets/icons/chevron-right.svg'
import Column from '@trinsly/common/src/components/atoms/column'
import Row from '@trinsly/common/src/components/atoms/row'
import Text from '@trinsly/common/src/components/atoms/text'
import IconButton from '@trinsly/common/src/components/molecules/icon-button'
import { format, isToday, parseISO } from 'date-fns'

export type DateSelectorProps = {
  dateISO: string
  readOnly: boolean
  onNext?: () => void
  onPrev?: () => void
}

function DateSelector({
  dateISO,
  readOnly,
  onNext,
  onPrev,
}: DateSelectorProps) {
  const date = parseISO(dateISO)
  const isDateToday = isToday(date)
  let formattedDate = format(date, 'EEEE, L/dd')
  formattedDate = isDateToday ? `Today, ${formattedDate}` : formattedDate

  if (readOnly) {
    return (
      <Text size="sm" fontWeight="bold" className="text-center">
        {formattedDate}
      </Text>
    )
  }

  return (
    <Row className="items-center">
      <IconButton size="sm" variant="outline" onClick={onPrev}>
        <ChevronLeftSvg />
      </IconButton>
      <Column style={{ minWidth: 150 }}>
        <Text
          size="sm"
          color="primary-500"
          fontWeight="bold"
          className="text-center"
        >
          {formattedDate}
        </Text>
      </Column>
      <IconButton size="sm" variant="outline" onClick={onNext}>
        <ChevronRightSvg />
      </IconButton>
    </Row>
  )
}

export default DateSelector
