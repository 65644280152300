import Row from '@trinsly/common/src/components/atoms/row'
import Spinner from '@trinsly/common/src/components/atoms/spinner'
import Text from '@trinsly/common/src/components/atoms/text'
import moment from 'moment'
import React from 'react'
import { useDraftContext } from '.'

type DraftSectionProps = {}

function DraftSection({}: DraftSectionProps) {
  const {
    pending: pendingDraft,
    saving: savingDraft,
    draft,
  } = useDraftContext()

  const lastChangeAt = draft?.updatedAt || draft?.createdAt
  const hasDraft = Boolean(lastChangeAt)

  return (
    <Row className="items-center gap-xs">
      {pendingDraft || savingDraft ? (
        <React.Fragment>
          {(pendingDraft || savingDraft) && <Spinner size="xs" />}
          {pendingDraft && <Text size="xs">Fetching draft...</Text>}
          {savingDraft && <Text size="xs">saving draft...</Text>}
        </React.Fragment>
      ) : (
        hasDraft && (
          <Text size="xs">
            Draft last saved at {moment(lastChangeAt).fromNow()}
          </Text>
        )
      )}
    </Row>
  )
}

export default DraftSection
