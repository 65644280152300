import { ReactComponent as SearchSvg } from '@trinsly/common/src/assets/icons/search.svg'
import React from 'react'
import Column from '../atoms/column'
import Separator from '../atoms/separator'
import Text from '../atoms/text'

export type NonIdealStateProps = {
  icon?: React.ReactElement
  message: string
}

function NonIdealState({ icon, message }: NonIdealStateProps) {
  return (
    <Column className="flex-1 items-center">
      <Column className="p-md bg-primary-50 rounded-full">
        {icon ? icon : <SearchSvg className="w-md h-md text-primary-500" />}
      </Column>
      <Separator size="sm" />
      <Text size="sm" fontWeight="bold" color="gray-400">
        {message}
      </Text>
    </Column>
  )
}

export default NonIdealState
