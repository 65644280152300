import Column from '@trinsly/common/src/components/atoms/column'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Text from '@trinsly/common/src/components/atoms/text'
import PrivateLayout from 'components/private-layout'

function NotFound404() {
  return (
    <PrivateLayout>
      <Column className="px-xl py-md">
        <Heading size="lg" fontWeight="bold">
          404 - Not Found
        </Heading>
        <Separator />
        <Text>
          Oh no, page not found. Double-check that the page exists and the url
          is correct.
        </Text>
      </Column>
    </PrivateLayout>
  )
}

export default NotFound404
