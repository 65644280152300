import api from '@trinsly/common/src/services/api'
import ThreadType from 'types/thread-type'

type GetThreadServiceProps = {
  threadId: ThreadType['id']
}

async function getThreadService({ threadId }: GetThreadServiceProps) {
  const response = await api(`/email_threads/${threadId}`, {
    method: 'GET',
  })
  return response as any as ThreadType
}

export default getThreadService
