import ReactQuill, { Quill } from 'react-quill'
import 'quill-emoji/dist/quill-emoji.css'

import {
  EmojiBlot,
  ShortNameEmoji,
  ToolbarEmoji,
  TextAreaEmoji,
  //@ts-ignore
} from 'quill-emoji'

Quill.register(
  'modules/emojis',
  {
    'formats/emoji': EmojiBlot,
    'modules/emoji-shortname': ShortNameEmoji,
    'modules/emoji-toolbar': ToolbarEmoji,
    'modules/emoji-textarea': TextAreaEmoji,
  },
  true
)
const emojis = {
  'emoji-toolbar': true,

  'emoji-shortname': true,
}
//@ts-ignore
window.Quill = Quill
export default emojis
