import { ReactComponent as FilterSvg } from '@trinsly/common/src/assets/icons/filter.svg'
import Clickable from '@trinsly/common/src/components/atoms/clickable'
import Column from '@trinsly/common/src/components/atoms/column'
import Divider from '@trinsly/common/src/components/atoms/divider'
import Popover from '@trinsly/common/src/components/atoms/popover'
import Row from '@trinsly/common/src/components/atoms/row'
import Text from '@trinsly/common/src/components/atoms/text'
import ExpandableSection from '@trinsly/common/src/components/molecules/expandable-section'
import IconButton from '@trinsly/common/src/components/molecules/icon-button'
import usePageable from '@trinsly/common/src/hooks/use-pageable'
import { compact } from 'lodash'
import HotlistFilter from './hotlist-filter'
import TagsFilter from './tags-filter'

type AdvancedFiltersSectionV2Props = {}

function AdvancedFiltersSectionV2({}: AdvancedFiltersSectionV2Props) {
  const { pageable, setFilters } = usePageable({
    page: 1,
    perPage: 10,
  })

  const hasFilters =
    Boolean(pageable?.filters?.projects) ||
    Boolean(pageable?.filters?.hotlists) ||
    Boolean(pageable?.filters?.tags)

  const tags = compact([pageable?.filters?.tags]?.flat?.()) || []
  const hotlists = compact([pageable?.filters?.hotlists]?.flat?.()) || []
  const projects = compact([pageable?.filters?.projects]?.flat?.()) || []

  return (
    <Column>
      <Popover
        placement="right-start"
        target={
          <IconButton
            variant={hasFilters ? 'solid' : 'ghost'}
            shape="square"
            intent={hasFilters ? 'primary' : undefined}
          >
            <FilterSvg />
          </IconButton>
        }
      >
        <Column className="min-w-64">
          <Row className="px-sm py-3 items-center gap-sm justify-between">
            <Text size="sm" fontWeight="bold" color="gray-900">
              Filters
            </Text>
            <Clickable
              onClick={() => {
                setFilters((filters) => ({
                  ...filters,
                  projects: null,
                  hotlists: null,
                  tags: null,
                }))
              }}
            >
              <Text color="primary-500" size="sm">
                Clear Filters
              </Text>
            </Clickable>
          </Row>
          <Divider />
          <Column
            className="min-w-64 overflow-auto"
            style={{ maxHeight: '60vh' }}
          >
            {/* <ExpandableSection title="Projects" count={projects?.length || 0}>
              <Column className="px-sm pt-xs pb-md">
                <ProjectFilter
                  values={projects}
                  onChange={(values) => {
                    setFilters('projects', values)
                  }}
                />
              </Column>
            </ExpandableSection> */}
            <ExpandableSection title="Hotlists" count={hotlists?.length || 0}>
              <Column className="px-sm pt-xs pb-md">
                <HotlistFilter
                  values={hotlists}
                  onChange={(values) => setFilters('hotlists', values)}
                />
              </Column>
            </ExpandableSection>
            <ExpandableSection title="Tags" count={tags?.length || 0}>
              <Column className="px-sm pt-xs pb-md">
                <TagsFilter
                  values={tags}
                  onChange={(values) => setFilters('tags', values)}
                />
              </Column>
            </ExpandableSection>
          </Column>
        </Column>
      </Popover>
    </Column>
  )
}

export default AdvancedFiltersSectionV2
