import api from '@trinsly/common/src/services/api'

export type CreateAccountDeletionRequestServiceProps = {
  userId: number | string
}

async function createAccountDeletionRequestService({
  userId,
}: CreateAccountDeletionRequestServiceProps) {
  const response = await api(`/users/${userId}/request_account_deletion`, {
    method: 'POST',
  })

  return response as any
}

export default createAccountDeletionRequestService
