import api from '@trinsly/common/src/services/api'
import { pickBy } from 'lodash'
import ProspectType from 'types/prospect-type'

type CreateProspectEmploymentServiceProps = {
  prospectId: ProspectType['id']
  employment: ProspectType['employments'][number]
}

async function createProspectEmploymentService({
  prospectId,
  employment,
}: CreateProspectEmploymentServiceProps) {
  const response = (await api(`/prospects/${prospectId}/employments`, {
    method: 'POST',
    body: {
      employment: pickBy({
        role: employment?.role,
        start_time: employment?.startTime,
        end_time: employment?.endTime,
        company_attributes: employment?.companyAttributes,
      }),
    },
  })) as ProspectType['employments'][number]
  return response
}

export default createProspectEmploymentService
