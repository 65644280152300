import api from '@trinsly/common/src/services/api'

export type UpdateMemberStatusServiceProps = {
  memberId: number
}

//e.g. toggle active/inactive
export default async function updateMemberStatusService({
  memberId,
}: UpdateMemberStatusServiceProps) {
  const response = await api(`/users/${memberId}/toggle_disable`, {
    method: 'POST',
  })

  return response as any as User
}
