import { ReactComponent as CloseSvg } from '@trinsly/common/src/assets/icons/close.svg'
import Column from '@trinsly/common/src/components/atoms/column'
import Divider from '@trinsly/common/src/components/atoms/divider'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Text from '@trinsly/common/src/components/atoms/text'
import IconButton from '@trinsly/common/src/components/molecules/icon-button'
import EmailTemplateInput from '@trinsly/common/src/components/molecules/quill-editor/email-template-input'
import { signatureTemplateVariable } from '@trinsly/common/src/components/molecules/quill-editor/variables'
import TextInput from '@trinsly/common/src/components/molecules/text-input'
import useGetUserSignature from 'api-hooks/use-get-user-signature'
import AttachmentsInput from 'components/attachments-input'
import SendTestEmailButton from 'components/send-test-email-button'
import React from 'react'
import { useDraftContext } from '../draft-provider'

type EmailTemplateListItemProps = {
  index: number
  getValue: (path: string) => any
  getValues: () => any
  setValue: (path: string, value: any) => void
  setValues: (values: any) => void
  getError: (path: string) => any
  onRemoveFollowUp: () => void
}

function EmailTemplateListItem({
  index,
  getValue,
  getValues,
  setValue,
  setValues,
  getError,
  onRemoveFollowUp,
}: EmailTemplateListItemProps) {
  const { data: signature, pending: pendingSignature } = useGetUserSignature({})
  const { pending: pendingDraft } = useDraftContext()

  const templatePath = `campaign.templates[${index}]`
  const isFollowUp = index > 0

  const shouldDisableForm =
    pendingDraft ||
    (Boolean(getValue('campaign.id')) && Boolean(getValue('campaign.linked')))

  const noAttachmentsAndDisabled =
    shouldDisableForm && !getValue(`${templatePath}.attachments`)?.length

  return (
    <Column>
      <Divider />
      <Column className="px-xl py-md gap-md border-l-2 border-primary-500">
        <Row className="items-center justify-between gap-md">
          <Text fontWeight="bold" color="primary-500">
            {`Stage #${index + 1} Template`}
          </Text>
          <Row className="items-center gap-md">
            <SendTestEmailButton
              subject={getValue(`${templatePath}.subject`)}
              body={getValue(`${templatePath}.body`)}
            />
            {isFollowUp && (
              <IconButton
                testId="remove-follow-up-button"
                size="sm"
                intent="danger"
                shape="circle"
                variant="ghost"
                onClick={onRemoveFollowUp}
              >
                <CloseSvg />
              </IconButton>
            )}
          </Row>
        </Row>
        {isFollowUp && (
          <Row className="items-center gap-xs">
            <Text size="sm">After</Text>
            <Column className="w-20 whitespace-nowrap">
              <TextInput
                testId="email-duration-number-input"
                type="number"
                min={0}
                max={120}
                step={1}
                value={getValue(`${templatePath}.duration`)}
                onChange={(value) => {
                  setValue(`${templatePath}.duration`, value)
                }}
                error={getError(`${templatePath}.duration`)}
                required
                disabled={shouldDisableForm}
              />
            </Column>
            <Text size="sm">
              days, if there is no reply, send a follow-up email.
            </Text>
          </Row>
        )}
        <Column>
          <EmailTemplateInput
            subject={{
              id: `subject-${index + 1}-text-input`,
              testId: 'subject-text-input',
              label: 'Subject',
              value: getValue(`${templatePath}.subject`),
              onChange: (value) => {
                setValue(`${templatePath}.subject`, value)
              },
              error: getError(`${templatePath}.subject`),
              disabled:
                getValue(`${templatePath}.isInThread`) || shouldDisableForm,
              required: true,
            }}
            isInThread={
              isFollowUp
                ? {
                    id: `is-in-thread-${index + 1}-switch`,
                    testId: 'is-in-thread-switch',
                    checked: getValue(`${templatePath}.isInThread`),
                    onChange: (value) => {
                      const firstSubject =
                        getValue('campaign.templates[0].subject') || ''
                      setValues({
                        ...(getValues() || {}),
                        [`${templatePath}.subject`]: `RE: ${firstSubject}`,
                        [`${templatePath}.isInThread`]: !getValue(
                          `${templatePath}.isInThread`
                        ),
                      })
                    },
                    disabled: shouldDisableForm,
                  }
                : undefined
            }
            body={{
              id: `body-${index + 1}-editor`,
              testId: 'body-editor',
              label: 'Body',
              value: getValue(`${templatePath}.body`),
              onChange: ({ html, counter }) => {
                const htmlWithSignature =
                  html?.replaceAll(
                    signatureTemplateVariable,
                    `<br>${
                      signature?.signature ||
                      '-- Define an email Signature at Settings Page --'
                    }`
                  ) || ''
                setValue(`${templatePath}.body`, htmlWithSignature)
              },
              error: getError(`${templatePath}.body`),
              required: true,
              disabled: shouldDisableForm,
            }}
          />
        </Column>
        <Column>
          <Text
            size="sm"
            fontWeight="bold"
            className="uppercase"
            color="gray-900"
          >
            Attachments
          </Text>
          {noAttachmentsAndDisabled ? (
            <React.Fragment>
              <Separator size="xxs" />
              <Text size="sm" color="gray-400">
                No attachments.
              </Text>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Separator size="sm" />
              <AttachmentsInput
                disabled={shouldDisableForm}
                value={getValue(`${templatePath}.attachments`) || []}
                onChange={(name, values) => {
                  setValue(`${templatePath}.attachments`, values || [])
                }}
              />
            </React.Fragment>
          )}
        </Column>
      </Column>
    </Column>
  )
}

export default EmailTemplateListItem
