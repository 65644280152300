//leg 2 of reset
type CompletePasswordResetServiceProps = {
  password: string
  email: string
  token: string
}
//step 2
async function completePasswordResetService({
  password,
  email,
  token,
}: CompletePasswordResetServiceProps) {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/auth/password_reset`,
    {
      body: JSON.stringify({
        password_reset: {
          token,
          email,
          password,
        },
      }),
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-Trinsly-Client': 'web',
      },
    }
  )

  if (!response.ok || response.status > 299) {
    throw Error('Unable to reset password')
  }

  return
}

export default completePasswordResetService
