import { ReactComponent as CheckSvg } from '@trinsly/common/src/assets/icons/check.svg'
import { ReactComponent as CloseSvg } from '@trinsly/common/src/assets/icons/close.svg'
import { ReactComponent as EditSvg } from '@trinsly/common/src/assets/icons/edit.svg'
import Column from '@trinsly/common/src/components/atoms/column'
import Form from '@trinsly/common/src/components/atoms/form'
import Row from '@trinsly/common/src/components/atoms/row'
import Text from '@trinsly/common/src/components/atoms/text'
import IconButton from '@trinsly/common/src/components/molecules/icon-button'
import Select from '@trinsly/common/src/components/molecules/select'
import TextInput from '@trinsly/common/src/components/molecules/text-input'
import useForm from '@trinsly/common/src/hooks/use-form'
import required from '@trinsly/common/src/validations/required'
import useGetProjectProspects from 'api-hooks/use-get-project-prospects'
import useUpdateProject from 'api-hooks/use-update-project'
import { format, parse } from 'date-fns'
import React from 'react'
import { toast } from 'react-toastify'
import MultiSelectTags from 'screens/projects-screen/project-form-modal/multi-select-tags'
import StatusField from 'screens/projects-screen/status-field'
import ProjectStatusEnum from 'types/project-status-enum'
import ProjectType from 'types/project-type'
import { useFormContext } from '../form-provider'

type ProjectInlineFormProps = {}

function ProjectInlineForm({}: ProjectInlineFormProps) {
  const { project, viewState, setViewState } = useFormContext()

  const { total } = useGetProjectProspects({
    projectId: project?.id || 0,
    page: 1,
    perPage: 10,
  } as any)

  const { fetch: updateProject, pending: pendingUpdateProject } =
    useUpdateProject({
      onSuccess: () => {
        toast.success('Project updated!')
        setViewState('detail')
      },
      onError: (error) => {
        toast.error('Error updating project')
      },
    })

  const { getValue, setValue, getError, getValues, setValues, submit, reset } =
    useForm({
      onSubmit: (project: ProjectType) => {
        updateProject({ project })
      },
      validations: {
        name: [required],
      },
    })

  React.useEffect(() => {
    if (viewState === 'form') {
      reset()
      setValues(project || {})
    }
  }, [viewState])

  React.useEffect(() => {
    setValues(project || {})
  }, [project?.id])

  return (
    <Form onSubmit={submit}>
      <Row className="gap-md flex-1 py-1 items-start flex-wrap">
        {viewState === 'form' && (
          <Column className="gap-xs min-w-52">
            <Text size="xs" fontWeight="bold" className="uppercase">
              Name
            </Text>
            <TextInput
              required
              value={getValue('name')}
              onChange={(value) => setValue('name', value)}
              error={getError('name')}
              disabled={pendingUpdateProject}
            />
          </Column>
        )}
        <Column className="gap-xs min-w-32">
          <Text size="xs" fontWeight="bold" className="uppercase">
            Start
          </Text>
          {
            {
              form: (
                <TextInput
                  type="date"
                  value={getValue('startDate')}
                  onChange={(value) => setValue('startDate', value)}
                  error={getError('startDate')}
                  disabled={pendingUpdateProject}
                />
              ),
              detail: (
                <Text size="xs">
                  {getValue('startDate')
                    ? format(
                        parse(getValue('startDate'), `yyyy-MM-dd`, new Date()),
                        `MM/dd/yyyy`
                      )
                    : '--'}
                </Text>
              ),
            }[viewState]
          }
        </Column>
        <Column className="gap-xs min-w-28">
          <Text size="xs" fontWeight="bold" className="uppercase">
            End
          </Text>
          {
            {
              form: (
                <TextInput
                  type="date"
                  value={getValue('endDate')}
                  onChange={(value) => setValue('endDate', value)}
                  error={getError('endDate')}
                  disabled={pendingUpdateProject}
                />
              ),
              detail: (
                <Text size="xs">
                  {getValue('endDate')
                    ? format(
                        parse(getValue('endDate'), `yyyy-MM-dd`, new Date()),
                        `MM/dd/yyyy`
                      )
                    : '--'}
                </Text>
              ),
            }[viewState]
          }
        </Column>
        <Column className="gap-xs min-w-32">
          <Text size="xs" fontWeight="bold" className="uppercase">
            Status
          </Text>
          {
            {
              form: (
                <Column className="min-w-40">
                  <Select
                    value={getValue('status') as ProjectStatusEnum}
                    onChange={(value) => setValue('status', value)}
                    options={
                      Object.values(ProjectStatusEnum) as ProjectStatusEnum[]
                    }
                    clearable={false}
                    itemRenderer={(item) => <StatusField status={item} />}
                    disabled={pendingUpdateProject}
                  />
                </Column>
              ),
              detail: project?.status ? (
                <Column className="items-start">
                  <StatusField status={project?.status} />
                </Column>
              ) : (
                <Text size="sm">{project?.name || '--'}</Text>
              ),
            }[viewState]
          }
        </Column>
        {
          {
            form: (
              <Row className="items-center gap-xs flex-1">
                <Column className="gap-xs min-w-64 flex-1 max-w-sm">
                  <Text size="xs" fontWeight="bold" className="uppercase">
                    Tags
                  </Text>
                  <MultiSelectTags
                    values={getValue('tagList') as string[]}
                    creatable={true}
                    onChange={(value) => setValue('tagList', value)}
                    onCreate={(value) => {
                      const tagList = getValue('tagList') || []
                      setValue('tagList', [...tagList, value])
                    }}
                    disabled={pendingUpdateProject}
                  />
                </Column>
                <Row className="gap-xs pt-md">
                  <IconButton
                    type="submit"
                    size="sm"
                    iconSize="md"
                    intent="success"
                    variant="transparent"
                    pending={pendingUpdateProject}
                  >
                    <CheckSvg />
                  </IconButton>
                  <IconButton
                    size="sm"
                    iconSize="md"
                    intent="danger"
                    variant="transparent"
                    onClick={() => setViewState('detail')}
                  >
                    <CloseSvg />
                  </IconButton>
                </Row>
              </Row>
            ),
            detail: <></>,
          }[viewState]
        }
        <Row>
          {
            {
              form: <></>,
              detail: (
                <Row>
                  <Column className="gap-xs w-44">
                    <Text size="xs" fontWeight="bold" className="uppercase">
                      Total Prospects
                    </Text>
                    <Text size="sm">{total || 0}</Text>
                  </Column>
                  <IconButton
                    size="sm"
                    variant="transparent"
                    onClick={() => setViewState('form')}
                  >
                    <EditSvg />
                  </IconButton>
                </Row>
              ),
            }[viewState]
          }
        </Row>
      </Row>
    </Form>
  )
}

export default ProjectInlineForm
