import NotFound404 from 'components/not-found-404'
import { Redirect, Route, Switch } from 'react-router'
import BatchesScreen from 'screens/batches-screen'
import CampaignFormScreen from 'screens/campaign-form-screen'
import CampaignsScreen from 'screens/campaigns-screen'
import CreateBatchScreen from 'screens/create-batch-screen'
import DashboardScreen from 'screens/dashboard-screen'
import EditorTestScreen from 'screens/editor-test-screen'
import EmailProviderAuthorizationCallbackScreen from 'screens/email-provider-authorization-callback-screen'
import IntegrationsScreen from 'screens/integrations-screen'
import LeaderboardScreen from 'screens/leaderboard-screen'
import LogoutScreen from 'screens/logout-screen/index'
import ProjectDetailScreen from 'screens/project-detail-screen'
import ProjectsScreen from 'screens/projects-screen'
import ProspectDetailScreen from 'screens/prospect-detail-screen'
import ProspectsScreen from 'screens/prospects-screen'
import SettingsScreen from 'screens/settings-screen'
import SyncUsersIntegrationScreen from 'screens/sync-users-integration-screen'
import UnsubscribeScreen from 'screens/unsubscribe-screen'
import UsersScreen from 'screens/users-screen'

export type PrivateRoutesProps = {}

function PrivateRoutes({}: PrivateRoutesProps) {
  return (
    <Switch>
      <Route component={LogoutScreen} path="/log-out" />

      <Route component={EditorTestScreen} path="/editor" exact />
      <Route component={CreateBatchScreen} path="/batches/new" exact />
      <Route component={BatchesScreen} path="/batches" exact />

      <Route component={CampaignsScreen} path="/campaigns" exact />

      <Route component={CampaignFormScreen} path="/campaigns/new" exact />

      <Route component={CampaignFormScreen} path="/campaigns/:campaignId" />

      <Route
        component={EmailProviderAuthorizationCallbackScreen}
        path="/email-provider-authorization" //nylas callback
      />

      <Route
        component={SyncUsersIntegrationScreen}
        path="/integrations/:integrationId/sync-users"
      />

      <Route component={IntegrationsScreen} path="/integrations" exact />

      <Route component={LeaderboardScreen} path="/leaderboard" exact />

      <Route component={ProjectsScreen} path="/projects" exact />
      <Route component={ProjectDetailScreen} path="/projects/:id" />

      <Route
        component={ProspectDetailScreen}
        path="/prospects/:id/email-threads/:emailThreadId"
      />

      <Route component={ProspectDetailScreen} path="/prospects/:id" />

      <Route component={ProspectsScreen} path="/prospects" exact />

      <Route component={SettingsScreen} path="/settings" exact />
      {/* Admins only */}
      <Route component={UsersScreen} path="/users" exact />
      <Route component={DashboardScreen} path="/" exact />
      <Route component={UnsubscribeScreen} path="/unsubscribe" />
      <Route component={EditorTestScreen} path="/editor" />
      <Route path="/log-in">
        <Redirect
          to={{
            pathname: '/',
            search: window.location.search,
          }}
        />
      </Route>

      {/* If someone tries to hit registration while logged in, log them out, then forward to registration */}
      <Route path="/register">
        <Redirect
          to={{
            pathname: '/',
            search: window.location.search,
          }}
        />
      </Route>
      <Route component={NotFound404} path="*" />
    </Switch>
  )
}

export default PrivateRoutes
