import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation, useQueryClient } from 'react-query'
import deleteBatchDraftService from 'services/delete-batch-draft-service'
import DraftType from 'types/draft-type'

type UseDeleteBatchDraftProps = {} & MutateApiHookType<DraftType>

function useDeleteBatchDraft({ onError, onSuccess }: UseDeleteBatchDraftProps) {
  const queryClient = useQueryClient()
  const { mutate: fetch, status } = useMutation(deleteBatchDraftService, {
    onSuccess: async (data) => {
      await queryClient.invalidateQueries('/batches/drafts')
      onSuccess?.(data)
    },
    onError,
  })

  return {
    fetch,
    pending: status === 'loading',
  }
}

export default useDeleteBatchDraft
