enum EditorVariableEnum {
  FIRST_NAME = '{{FirstName}}',
  CITY = '{{City}}',
  COMPANY_NAME = '{{CompanyName}}',
  CURRENT_JOB_TITLE = '{{CurrentJobTitle}}',
  SCHOOL = '{{School}}',
  CUSTOM1 = '{{Custom1}}',
}

export default EditorVariableEnum
