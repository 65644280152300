import api from '@trinsly/common/src/services/api'
import PageableType from '@trinsly/common/src/types/pageable-type'
import { pickBy } from 'lodash'
import BatchType from 'types/batch-type'

export type GetBatchesServiceProps = PageableType

async function getBatchesService(pageable: GetBatchesServiceProps) {
  const orderBy = Object.keys(pageable?.sort || {})?.[0]
  const orderDirection = Object.values(pageable?.sort || {})?.[0]
  const hasSort = orderBy && orderDirection

  const response = await api(`/batches`, {
    method: 'GET',
    params: pickBy({
      query: pageable?.search,
      page: pageable?.page,
      per_page: pageable?.perPage,
      sort: hasSort ? `${orderBy} ${orderDirection}` : '',
    }),
  })
  return response as any as {
    batches: BatchType[]
    meta: { pagination: Pagination }
  }
}

export default getBatchesService
