import api from '@trinsly/common/src/services/api'
import PageableType from '@trinsly/common/src/types/pageable-type'
import { pickBy } from 'lodash'
import CampaignType from 'types/campaign-type'
import { remoteCampaignToCampaign } from './get-campaign-service'

export type GetCampaignsServiceProps = {} & PageableType

async function getCampaignsService(pageable: GetCampaignsServiceProps) {
  const path = urls[pageable?.filters?.state || CampaignFilterScopeEnum.MINE]
  const orderBy = Object.keys(pageable?.sort || {})?.[0]
  const orderDirection = Object.values(pageable?.sort || {})?.[0]
  const hasSort = orderBy && orderDirection

  const response = await api(`${path}`, {
    method: 'GET',
    params: pickBy({
      query: pageable?.search,
      page: pageable?.page,
      per_page: pageable?.perPage,
      tags: pageable?.filters?.tags,
      sort: hasSort ? `${orderBy} ${orderDirection}` : '',
    }),
  })

  const { meta, campaigns: remoteCampaigns } = response as any

  return {
    meta,
    campaigns: remoteCampaigns?.map(remoteCampaignToCampaign) || [],
  } as {
    campaigns: CampaignType[]
    meta: { pagination: Pagination }
  }
}

export default getCampaignsService

export enum CampaignFilterScopeEnum {
  ARCHIVED = 'ARCHIVED',
  MINE = 'MINE',
  ORG = 'ORG',
  SHARED = 'SHARED',
}

const urls: any = {
  [CampaignFilterScopeEnum.MINE]: '/campaigns',
  [CampaignFilterScopeEnum.SHARED]: `/campaigns/shared`,
  [CampaignFilterScopeEnum.ARCHIVED]: `/campaigns/archived`,
}
