import Column from '@trinsly/common/src/components/atoms/column'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Table from '@trinsly/common/src/components/atoms/table'
import Text from '@trinsly/common/src/components/atoms/text'
import ThreadStatusTag from '@trinsly/common/src/components/molecules/thread-status-tag'
import NonIdealState from '@trinsly/common/src/components/organisms/non-ideal-state'
import usePageableState from '@trinsly/common/src/hooks/use-pageable-state'
import useGetThreads from 'api-hooks/use-get-threads'
import moment from 'moment'
import React from 'react'
import ProspectType from 'types/prospect-type'
import RespondedTypeCell from './responded-type-cell'
import RespondedViaCell from './responded-via-cell'

type ReachOutsSectionProps = {
  prospectId: ProspectType['id'] | undefined
}

function ReachOutsSection({ prospectId }: ReachOutsSectionProps) {
  const { pageable, setFilters } = usePageableState({})
  const {
    data: history,
    total,
    pending: pendingHistory,
  } = useGetThreads({
    enabled: Boolean(pageable?.filters?.prospectId),
    ...pageable,
  })

  React.useEffect(() => {
    setFilters((filters) => ({
      ...filters,
      prospectId,
    }))
  }, [prospectId])

  const pending = !prospectId || pendingHistory
  const isNonIdealState = !pending && !history?.length
  return (
    <Column className="px-md">
      <Text size="xs">Reach-Out Activity</Text>
      <Separator size="xs" />
      <Column className="border rounded overflow-hidden">
        {isNonIdealState ? (
          <Column className="p-md">
            <NonIdealState message="No Prospect Data Available" />
          </Column>
        ) : (
          <Table
            pending={pending}
            data={history}
            columns={[
              {
                width: 170,
                header: 'Status',
                cell: (item) => {
                  return <ThreadStatusTag status={item.state} />
                },
              },
              {
                header: 'Sent At',
                width: 150,
                // cell: (item) => `--`,
                cell: (item) =>
                  item?.lastDeliveredAt
                    ? moment(item.lastDeliveredAt, moment.ISO_8601).format(
                        'M/D/YY h:mm a'
                      )
                    : '--',
              },
              {
                header: 'Sent to',
                width: 200,
                cell: (item) => item.email,
              },
              {
                header: 'Sent By',
                width: 200,
                cell: (item) => item.userName,
              },
              {
                width: 200,
                header: 'Campaign Used',
                cell: (item) => item.campaignName,
              },

              {
                width: 200,
                header: 'Responded Via',
                cell: (item) => {
                  return <RespondedViaCell history={item as any} />
                },
              },
              {
                width: 200,
                header: 'Response Type',
                cell: (item) => {
                  return <RespondedTypeCell history={item as any} />
                },
              },
            ]}
          />
        )}
      </Column>
    </Column>
  )
}

export default ReachOutsSection
