import api from '@trinsly/common/src/services/api'
import { pickBy } from 'lodash'
import HotlistType from 'types/hotlist-type'

type CreateHotlistServiceProps = {
  hotlist: HotlistType
}

async function createHotlistService({ hotlist }: CreateHotlistServiceProps) {
  const response = await api(`/hotlists`, {
    method: 'POST',
    body: {
      hotlist: pickBy({
        name: hotlist?.name,
      }),
    },
  })
  return response as HotlistType
}

export default createHotlistService
