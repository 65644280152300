import PageableType from '@trinsly/common/src/types/pageable-type'
import { useQuery } from 'react-query'
import getProspectProjectsService, {
  GetProspectProjectsServiceProps,
} from 'services/get-prospect-projects-service'

type UseGetProspectProjectsProps = {} & PageableType &
  Partial<GetProspectProjectsServiceProps>

function useGetProspectProjects({
  prospectId,
  ...pageable
}: UseGetProspectProjectsProps) {
  const shouldFetch = Boolean(prospectId)
  const { data, error, status } = useQuery(
    ['/prospects/{prospectId}/projects', prospectId, pageable],
    () => getProspectProjectsService({ prospectId: prospectId!, ...pageable }),
    {
      enabled: shouldFetch,
    }
  )

  return {
    data: data?.projects || [],
    total: data?.meta?.pagination?.totalObjects,
    pending: status === 'loading' || !shouldFetch,
    error,
  }
}

export default useGetProspectProjects
