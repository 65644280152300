import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation, useQueryClient } from 'react-query'
import updateThreadEmailService from 'services/update-thread-email-service'
import { ThreadEmailType } from 'types/thread-type'

type UseUpdateThreadEmailProps = {} & MutateApiHookType<ThreadEmailType>

function useUpdateThreadEmail({
  onError,
  onSuccess,
}: UseUpdateThreadEmailProps) {
  const queryClient = useQueryClient()
  const { mutate: fetch, status } = useMutation(updateThreadEmailService, {
    onError,
    onSuccess: async (data) => {
      await queryClient.invalidateQueries('/email_messages')
      await queryClient.invalidateQueries('/threads')
      onSuccess?.(data)
    },
  })

  return {
    fetch,
    pending: status === 'loading',
  }
}

export default useUpdateThreadEmail
