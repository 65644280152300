import React from 'react'
import { Theme } from '../../types/theme-type'

export interface HeadingProps {
  children?: React.ReactText | React.ReactNode | React.ReactNodeArray
  className?: string
  size?: Theme['size'] | '2xl' | '3xl' | '4xl'
  color?: Theme['color']
  fontWeight?: Theme['fontWeight']
}

function Heading({
  size = 'md',
  className = '',
  fontWeight = 'medium',
  color = 'gray-900',
  children,
}: HeadingProps) {
  const classNames = `inline-block text-${size} ${fontWeightMap[fontWeight]} font-sans text-${color} ${className}`
  return <h1 className={classNames}>{children}</h1>
}

export default Heading

const fontWeightMap = {
  thin: 'font-thin',
  extralight: 'font-extralight',
  light: 'font-light',
  normal: 'font-normal',
  medium: 'font-medium',
  semibold: 'font-semibold',
  bold: 'font-bold',
  extrabold: 'font-extrabold',
  black: 'font-black',
  undefined: '',
}
