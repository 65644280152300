import { useQuery } from 'react-query'
import getProspectService, {
  GetProspectServiceProps,
} from 'services/get-prospect-service'

export type UseGetProspectProps = {} & GetProspectServiceProps

function useGetProspect({ prospectId }: UseGetProspectProps) {
  const { data, error, status } = useQuery(
    ['/prospects/{prospectId}', prospectId],
    () => getProspectService({ prospectId })
  )

  return {
    data,
    pending: status === 'loading',
    error,
  }
}

export default useGetProspect
