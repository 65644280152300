import { useQuery } from 'react-query'
import getIntegrationsService from 'services/integrations/get-integrations-service'
import IntegrationType from 'types/integration-type'

function useGetIntegrations() {
  const { data, error, status } = useQuery(
    '/integrations',
    getIntegrationsService
  )

  return {
    data: data || ([] as IntegrationType[]),
    pending: status === 'loading',
    error,
  }
}

export default useGetIntegrations
