import { ReactComponent as CheckSvg } from '@trinsly/common/src/assets/icons/check.svg'
import Card from '@trinsly/common/src/components/atoms/card'
import Column from '@trinsly/common/src/components/atoms/column'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Spinner from '@trinsly/common/src/components/atoms/spinner'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import slugify from '@trinsly/common/src/utils/slugify'
import useMergeSingleIntegration from 'api-hooks/use-merge-single-integration'
import useSyncMergeIntegration from 'api-hooks/use-sync-merge-integration'
import { useAuth } from 'providers/auth-provider'
import React from 'react'
import { toast } from 'react-toastify'
import CrmEnum from 'types/crm-enum'
import DeleteMergeSingleIntegrationConfirmationModal from './delete-merge-single-integration-confirmation-modal'

type MergeIntegrationCardProps = {
  id?: string
  integrationType: CrmEnum
  logoSrc: string
  description: string
  pending: boolean
  integration?: Integration
  paywalled?: boolean
  disabled?: boolean
}

function MergeIntegrationCard({
  integrationType,
  id = slugify(integrationType),
  integration,
  paywalled,
  logoSrc,
  description,
  pending,
  disabled,
}: MergeIntegrationCardProps) {
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    React.useState(false)

  const { user } = useAuth()
  const isAdmin = Boolean(user?.organizationRole)

  const {
    fetch: startMergeSingleIntegration,
    pending: pendingMergeSingleIntegration,
  } = useMergeSingleIntegration({
    integration: integrationType,
    onSuccess: (data) => {
      toast.success('Integration created!')
    },
    onError: (error) => {
      const errorMessage = error?.message || 'Error creating integration'
      toast.error(errorMessage)
    },
  })

  const {
    fetch: syncUsersFromMergeSingleIntegration,
    pending: pendingSyncUsersFromMergeIntegration,
  } = useSyncMergeIntegration({
    onSuccess: (data) => {
      toast.success('Integration synced!')
    },
    onError: (error) => {
      const errorMessage = error?.message || 'Error syncing integration'
      toast.error(errorMessage)
    },
  })

  const connected = Boolean(integration)

  return (
    <React.Fragment>
      <Card
        className="p-md relative"
        style={{ border: `1px solid ${connected ? '#00C853' : 'transparent'}` }}
      >
        {connected && (
          <Column className="absolute top-sm right-0">
            <Row
              className="items-center min-h-lg px-sm"
              style={{
                color: '#00C853',
                backgroundColor: '#00C8531A',
                borderTopLeftRadius: 50,
                borderBottomLeftRadius: 50,
              }}
            >
              <CheckSvg className="w-sm h-sm" />
              <Separator size="xs" />
              <Text
                size="xs"
                fontWeight="bold"
                color="current"
                className="uppercase"
              >
                Connected
              </Text>
            </Row>
            <Column className="items-end justify-center pr-xs">
              <Separator size="xs" />
              <Button
                id={`${id}-sync-users-button`}
                size="xs"
                variant="outline"
                intent="primary"
                shape="rounded"
                disabled={!isAdmin}
                pending={pendingSyncUsersFromMergeIntegration}
                className="text-indigo-600 hover:text-indigo-900"
                onClick={() => {
                  syncUsersFromMergeSingleIntegration({
                    integrationId: integration?.id || '',
                  })
                }}
              >
                Sync users
              </Button>
            </Column>
          </Column>
        )}
        <Column className="justify-center" style={{ width: 130, height: 53 }}>
          <img
            src={logoSrc}
            width="100%"
            height="100%"
            style={{ objectFit: 'contain' }}
          />
        </Column>
        <Separator />
        <Text>{description}</Text>
        <Separator />
        <Row className="flex-1 min-w-40 items-end">
          {pending ? (
            <Spinner />
          ) : connected ? (
            <Row className="flex-1 items-center justify-between">
              <Button
                id={`${id}-disconnect-button`}
                variant="ghost"
                disabled={!isAdmin}
                shape="rounded"
                intent="warning"
                onClick={() => setShowDeleteConfirmationModal(true)}
              >
                Disconnect
              </Button>
            </Row>
          ) : (
            <Button
              id={`${id}-start-integration-button`}
              intent="primary"
              variant="outline"
              shape="rounded"
              disabled={paywalled || !isAdmin || disabled}
              pending={pendingMergeSingleIntegration}
              onClick={() =>
                startMergeSingleIntegration?.({ integration: integrationType })
              }
            >
              Start Integration
            </Button>
          )}
        </Row>
      </Card>
      <DeleteMergeSingleIntegrationConfirmationModal
        integration={integration}
        visible={showDeleteConfirmationModal}
        onRequestClose={() => setShowDeleteConfirmationModal(false)}
      />
    </React.Fragment>
  )
}

export default MergeIntegrationCard
