import Column from '@trinsly/common/src/components/atoms/column'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Spinner from '@trinsly/common/src/components/atoms/spinner'
import Text from '@trinsly/common/src/components/atoms/text'
import Select from '@trinsly/common/src/components/molecules/select'
import ThreadStateEnum from '@trinsly/common/src/types/thread-state-enum-type'
import useUpdateEmailThreadState from 'api-hooks/use-update-email-thread-state'
import ResponseTypeTag from 'components/response-type-tag'
import { toast } from 'react-toastify'
import ProspectHistoryType, { ResponseType } from 'types/prospect-history-type'

type RespondedTypeCellProps = {
  history: ProspectHistoryType
}

function RespondedTypeCell({ history }: RespondedTypeCellProps) {
  const { updateResponseType, pendingResponseTypeUpdate } =
    useUpdateEmailThreadState({
      onSuccess: () => {
        toast.success('Response Type updated!')
      },
      onError: (error) => {
        toast.error('Error updating Response Type')
      },
    })

  return (
    <Row className="items-center min-w-56">
      <Column className="min-w-48">
        <Select
          clearable={false}
          onChange={(value) => {
            updateResponseType({
              reachOutId: parseInt(history.id.toString()),
              responseType:
                (value as ResponseType) || ResponseType.NOT_INTERESTED,
            })
          }}
          itemRenderer={(value, selected) => (
            <Row className="items-center gap-xs">
              <ResponseTypeTag responseType={value as ResponseType} />
              <Text className="capitalize" size="xs">
                {value.replaceAll('_', ' ')}
              </Text>
            </Row>
          )}
          disabled={
            pendingResponseTypeUpdate ||
            history.state !== ThreadStateEnum.RESPONDED
          }
          value={history.responseType}
          placeholder="Not response"
          options={[...Object.values(ResponseType).map((value) => value)]}
        />
      </Column>
      <Separator size="xs" />
      {pendingResponseTypeUpdate && <Spinner />}
    </Row>
  )
}

export default RespondedTypeCell
