import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation } from 'react-query'

import createTrackerDomainService from 'services/settings/create-tracker-domain-service'
import TrackerDomainType from 'types/tracker-domain-type'
import useGetTrackerDomains from './use-get-tracker-domains'
import useGetUserSettings from './use-get-user-settings'

export type UseCreateTrackerDomainProps =
  {} & MutateApiHookType<TrackerDomainType>

function useCreateTrackerDomain({
  onSuccess,
  onError,
  onMutate,
}: UseCreateTrackerDomainProps) {
  const { refetch } = useGetTrackerDomains()
  const { refetch: refetchSettings } = useGetUserSettings()
  const { mutate: fetch, isLoading: pending } = useMutation(
    createTrackerDomainService,
    {
      onSuccess: async (data) => {
        await refetch()
        await refetchSettings()
        onSuccess?.(data)
      },
      onError,
      onMutate,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useCreateTrackerDomain
