import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation, useQueryClient } from 'react-query'
import createApiKeyService from 'services/settings/create-api-key'
export type UseCreateApiKeyProps = {} & MutateApiHookType<any>

export function useCreateApiKey({ onSuccess, onError }: UseCreateApiKeyProps) {
  const queryClient = useQueryClient()
  const { mutate: fetch, isLoading: pending } = useMutation(
    createApiKeyService,
    {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries('/user/me')
        onSuccess?.(data)
      },
      onError,
    }
  )
  return {
    fetch,
    pending,
  }
}
