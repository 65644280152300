import { Dialog, Transition } from '@headlessui/react'
import React from 'react'

export type ModalV2Props = {
  visible?: boolean
  onRequestClose?: () => void
  children?: React.ReactNode
}

function ModalV2({
  visible = false,
  children,
  onRequestClose = () => {},
}: ModalV2Props) {
  return (
    <React.Fragment>
      <Transition appear show={visible} as={React.Fragment}>
        <Dialog as="div" className="relative z-10" onClose={onRequestClose}>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="transform bg-white overflow-hidden rounded-2xl text-left align-middle shadow-xl transition-all">
                  {children}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </React.Fragment>
  )
}

export default ModalV2
