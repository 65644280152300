import api from '@trinsly/common/src/services/api'
import { pickBy } from 'lodash'
import ProspectType from 'types/prospect-type'

type UpdateProspectServiceProps = {
  prospect: ProspectType
}

//TODO: this is incomplete yet. Have to test better when endpoint becomes live
async function updateProspectService({ prospect }: UpdateProspectServiceProps) {
  const response = (await api(`/prospects/${prospect?.id}`, {
    method: 'PUT',
    body: {
      prospect: pickBy({
        first_name: prospect?.firstName,
        last_name: prospect?.lastName,
        email: prospect?.email,
        linkedin_name: prospect?.linkedinName,
        owner_id: prospect?.owner?.id,
        city: prospect?.city,
        state: prospect?.state,
        zip: prospect?.zip,
        tag_list: prospect?.tagList,
      }),
    },
  })) as ProspectType
  return response
}

export default updateProspectService
