import api from '@trinsly/common/src/services/api'
import { format, parseISO } from 'date-fns'

export type GetCampaignReportStatsServiceProps = {
  startDate: string
  endDate: string
  campaignId: string | number
}

async function getCampaignReportStatsService({
  startDate,
  endDate,
  campaignId,
}: GetCampaignReportStatsServiceProps): Promise<any> {
  try {
    const data = await api(`/campaigns/${campaignId}/reports/stats`, {
      method: 'GET',
      params: {
        start: format(parseISO(startDate), 'yyyy-MM-dd'),
        end: format(parseISO(endDate), 'yyyy-MM-dd'),
      },
    })
    return data || {}
  } catch (error) {
    throw error
  }
}

export default getCampaignReportStatsService
