import api from '@trinsly/common/src/services/api'
import HotlistType from 'types/hotlist-type'

type DeleteHotlistServiceProps = {
  hotlistId: HotlistType['id']
}

async function deleteHotlistService({ hotlistId }: DeleteHotlistServiceProps) {
  const response = (await api(`/hotlists/${hotlistId}`, {
    method: 'DELETE',
  })) as any

  return response as HotlistType
}

export default deleteHotlistService
