import { useQuery } from 'react-query'
import getProspectEducationsService from 'services/get-prospect-educations-service'
import ProspectType from 'types/prospect-type'

type UseGetProspectEducationsProps = {
  prospectId: ProspectType['id'] | undefined
}

function useGetProspectEducations({
  prospectId,
}: UseGetProspectEducationsProps) {
  const shouldFetch = Boolean(prospectId)
  const { data, error, status } = useQuery(
    ['/prospects/{prospectId}/educations', prospectId],
    () => getProspectEducationsService({ prospectId: prospectId! }),
    {
      enabled: shouldFetch,
    }
  )

  return {
    data: data || [],
    pending: status === 'loading' || !shouldFetch,
    error,
  }
}

export default useGetProspectEducations
