import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'

import { useMutation } from 'react-query'
import { checkTrackerDomainService } from 'services/settings/check-tracker-domain-service'
import useGetTrackerDomains from './use-get-tracker-domains'

export type UseCheckTrackerDomainStatusProps = {} & MutateApiHookType<{
  id: number
  success: boolean
}>

function useCheckTrackerDomainStatus({
  onSuccess,
  onError,
  onMutate,
}: UseCheckTrackerDomainStatusProps) {
  const { refetch } = useGetTrackerDomains()

  const { mutate: fetch, isLoading: pending } = useMutation(
    checkTrackerDomainService,
    {
      onSuccess: async (data) => {
        await refetch()
        onSuccess?.(data)
      },
      onError,
      onMutate,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useCheckTrackerDomainStatus
