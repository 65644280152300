import Column from '@trinsly/common/src/components/atoms/column'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Text from '@trinsly/common/src/components/atoms/text'
import { FC } from 'react'

export const matchToUser = {
  bullhorn: () => undefined,
  cats: (atsResults: any[], user: any) =>
    atsResults.find((e: any) => String(e.id) === user.catsRef),
  crelate: () => undefined,
  greenhouse: (atsResults: any[], user: any) =>
    atsResults.find((e: any) => String(e.id) === user.greenhouseRef),
  lever: (atsResults: any[], user: any) =>
    atsResults.find((e: any) => String(e.id) === user.leverRef),
  pcrecruiter: () => undefined,

  workable: (atsResults: any[], user: any) =>
    atsResults.find((e: any) => String(e.id) === user.workableRef),
  chatkick: () => undefined,
  clockwork: () => undefined,
}

// make sure we get id and name props on results, for Select to use
export const convertAtsResults = {
  bullhorn: () => [],
  cats: (atsResults: any) =>
    atsResults.map((e: any) => ({
      ...e,
      name: `${e.firstName} ${e.lastName}`,
    })),
  crelate: () => [],
  greenhouse: (atsResults: any[]) => atsResults,
  lever: (atsResults: any[]) => atsResults,
  pcrecruiter: () => [],
  workable: (atsResults: any[]) => atsResults,
  chatkick: () => [],
  clockwork: () => [],
}

interface AtsRenderItemProps {
  option: any
  isSelected: boolean
}

const CatsRenderItem: FC<AtsRenderItemProps> = ({ option, isSelected }) => {
  if (!option) {
    return <>Please select...</>
  }

  return (
    <Column>
      {option.name}
      <Text
        size="xs"
        color="current"
        className="opacity-60"
      >{`${option.title} <${option.username}>`}</Text>
    </Column>
  )
}

const LeverRenderItem: FC<AtsRenderItemProps> = ({ option, isSelected }) => {
  if (!option) {
    return <>Please select...</>
  }

  return (
    <Row className="items-center">
      <img
        alt="lever-img"
        className="w-8 h-8 rounded-full bg-gray-300"
        src={option.photo}
      />
      <Separator size="xs" />
      <Column>
        {option.name}
        <Text
          size="xs"
          color="current"
          className="opacity-60"
        >{`${option.accessRole} <${option.email}>`}</Text>
      </Column>
    </Row>
  )
}

const GreenhouseRenderItem: FC<AtsRenderItemProps> = ({
  option,
  isSelected,
}) => {
  if (!option) {
    return <>Please select...</>
  }

  return (
    <Column>
      {option.name}
      <Text
        size="xs"
        color="current"
        className="opacity-60"
      >{`<${option.primaryEmailAddress}>`}</Text>
    </Column>
  )
}

const WorkableRenderItem: FC<AtsRenderItemProps> = ({ option, isSelected }) => {
  if (!option) {
    return <>Please select...</>
  }

  return (
    <Column>
      {option.name}
      <Text size="xs" color="current" className="opacity-60">{`${
        option.role === 'simple' ? '' : option.role + ' '
      }<${option.email}>`}</Text>
    </Column>
  )
}

export const renderItems = {
  bullhorn: () => null,
  cats: CatsRenderItem,
  crelate: () => null,
  greenhouse: GreenhouseRenderItem,
  lever: LeverRenderItem,
  pcrecruiter: () => null,
  workable: WorkableRenderItem,
  chatkick: () => null,
  clockwork: () => null,
}
