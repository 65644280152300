/**
 * 24 Hours Based
 */
enum ThreadDeliverTimeEnum {
  IMMEDIATELY = -1,
  EARLY_MORNING = 7,
  MORNING = 9,
  AFTERNOON = 12,
  EVENING = 18,
}

export default ThreadDeliverTimeEnum

export const translateThreadDeliverTimeEnum: Record<
  ThreadDeliverTimeEnum,
  string
> = {
  [ThreadDeliverTimeEnum.IMMEDIATELY]: 'Immediately',
  [ThreadDeliverTimeEnum.EARLY_MORNING]: 'Early morning (after 7am)',
  [ThreadDeliverTimeEnum.MORNING]: 'Morning (after 9am)',
  [ThreadDeliverTimeEnum.AFTERNOON]: 'Afternoon (after 12pm)',
  [ThreadDeliverTimeEnum.EVENING]: 'Evening (after 6pm)',
}
