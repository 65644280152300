import api from '@trinsly/common/src/services/api'
import ProspectType from 'types/prospect-type'

type DeleteProspectEducationServiceProps = {
  educationId: ProspectType['educations'][number]['id']
}

async function deleteProspectEducationService({
  educationId,
}: DeleteProspectEducationServiceProps) {
  const response = (await api(`/educations/${educationId}`, {
    method: 'DELETE',
  })) as any
  return
}

export default deleteProspectEducationService
