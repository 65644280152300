import Column from '@trinsly/common/src/components/atoms/column'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Spinner from '@trinsly/common/src/components/atoms/spinner'
import Text from '@trinsly/common/src/components/atoms/text'
import Select from '@trinsly/common/src/components/molecules/select'
import ThreadStateEnum from '@trinsly/common/src/types/thread-state-enum-type'
import useUpdateEmailThreadState from 'api-hooks/use-update-email-thread-state'
import { toast } from 'react-toastify'
import ProspectHistoryType, {
  ResponseViaEnum,
} from 'types/prospect-history-type'

type RespondedViaCellProps = {
  history: ProspectHistoryType
}

function RespondedViaCell({ history }: RespondedViaCellProps) {
  const { updateStatus, pendingStatusUpdate } = useUpdateEmailThreadState({
    onSuccess: () => {
      toast.success('Responded Via updated!')
    },
    onError: (error) => {
      toast.error('Error updating Responded Via')
    },
  })

  return (
    <Row className="items-center min-w-44">
      <Column className="min-w-36">
        <Select
          clearable={false}
          disabled={pendingStatusUpdate}
          onChange={(value) => {
            updateStatus({
              reachOutId: parseInt(history.id.toString()),
              responseVia: !value ? undefined : value,
              state: ThreadStateEnum.RESPONDED,
            })
          }}
          itemRenderer={(value) => <Text className="capitalize"> {value}</Text>}
          value={history.responseVia}
          placeholder="No response"
          options={[...Object.values(ResponseViaEnum).map((value) => value)]}
        />
      </Column>
      <Separator size="xs" />
      {pendingStatusUpdate && <Spinner />}
    </Row>
  )
}

export default RespondedViaCell
