import { Switch as HUISwitch } from '@headlessui/react'

export type SwitchProps = {
  id?: string
  testId?: string
  checked?: boolean
  disabled?: boolean
  onChange?: (checked: boolean) => void
}

function Switch({
  id,
  testId = id,
  checked = false,
  disabled = false,
  onChange = () => {},
}: SwitchProps) {
  return (
    <HUISwitch
      id={id}
      {...{ 'data-test-id': testId }}
      disabled={disabled}
      checked={checked}
      onChange={() => onChange?.(!checked)}
      className={`
        ${containerClasses}
        ${
          disabled
            ? containerDisabledClasses
            : checked
            ? containerCheckedClasses
            : containerUncheckedClasses
        }
      `}
    >
      <span
        aria-hidden="true"
        className={`
          ${toggleClasses}
          ${checked ? toggleCheckedClasses : toggleUncheckedClasses}`}
      />
    </HUISwitch>
  )
}

export default Switch

const containerClasses = `relative h-8 w-12 inline-flex flex-shrink-0 rounded-full border-2 cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring focus:ring-primary-200`
const containerUncheckedClasses = `border-transparent bg-gray-200`
const containerCheckedClasses = `border-transparent bg-success-200`
const containerDisabledClasses = `border-gray-200 border-dashed bg-gray-100 cursor-not-allowed`
const toggleClasses = `pointer-events-none inline-block h-7 w-7 rounded-full shadow-lg transform ring-0 transition ease-in-out duration-200`
const toggleUncheckedClasses = `translate-x-0 bg-white`
const toggleCheckedClasses = `translate-x-4 bg-success-500`
