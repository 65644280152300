import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation, useQueryClient } from 'react-query'
import SaveCampaignDraftService from 'services/save-campaign-draft-service'
import DraftType from 'types/draft-type'

type UseSaveCampaignDraftProps = {} & MutateApiHookType<DraftType>

function useSaveCampaignDraft({
  onSuccess,
  onError,
}: UseSaveCampaignDraftProps) {
  const queryClient = useQueryClient()
  const { mutate: fetch, isLoading: pending } = useMutation(
    SaveCampaignDraftService,
    {
      onSuccess: async (data) => {
        queryClient.invalidateQueries('/campaigns/drafts')
        await queryClient.invalidateQueries('/campaigns/drafts/{id}')
        onSuccess?.(data)
      },
      onError,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useSaveCampaignDraft
