import React, { ReactElement } from 'react'
import { ReactComponent as TooltipQuestionMarkSVG } from '@trinsly/common/src/assets/icons/tooltip.svg'
import Row from '@trinsly/common/src/components/atoms/row'
import Card from '@trinsly/common/src/components/atoms/card'
import Separator from '@trinsly/common/src/components/atoms/separator'

export type TooltipProps = {
  children: ReactElement
  disabled?: boolean
}

function Tooltip({ children, disabled }: TooltipProps) {
  return (
    <Row className="items-center has-tooltip">
      <TooltipQuestionMarkSVG width={18} height={18} />
      <div className="pl-2">
        <Card className="object-right tooltip p-sm gap-xs rounded bg-primary-50 bg-opacity-90 ">
          {children}
        </Card>
      </div>
    </Row>
  )
}

export default Tooltip
