import api from '@trinsly/common/src/services/api'
import UserType from 'types/user-type'

export type GetUserSessionServiceProps = {}

//validates and sets user session
export async function getUserSessionService({}: GetUserSessionServiceProps) {
  const response = await api(`/users/me`, { method: 'GET' })

  return response as UserType
}
