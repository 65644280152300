import api from '@trinsly/common/src/services/api'
import PageableType from '@trinsly/common/src/types/pageable-type'
import { pickBy } from 'lodash'
import DraftType from 'types/draft-type'

export type GetBatchDraftsServiceProps = {} & PageableType

async function getBatchDraftsService(pageable: GetBatchDraftsServiceProps) {
  const orderBy = Object.keys(pageable?.sort || {})?.[0]
  const orderDirection = Object.values(pageable?.sort || {})?.[0]
  const hasSort = orderBy && orderDirection

  const response = await api(`/drafts`, {
    method: 'GET',
    params: pickBy({
      resource_type: 'batch',
      query: pageable?.search,
      page: pageable?.page,
      per_page: pageable?.perPage,
      sort: hasSort ? `${orderBy} ${orderDirection}` : '',
    }),
  })
  return response as any as {
    drafts: DraftType[]
    meta: { pagination: Pagination }
  }
}

export default getBatchDraftsService
