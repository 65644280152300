import React from 'react'

export type AvatarProps = {
  initials: string
  size?: 'md' | 'lg'
}

function AvatarLetter({ initials, size = 'md' }: AvatarProps) {
  const code = numberFromText(initials?.toUpperCase() || '')
  const { backgroundColor, color } = !initials
    ? colors[0]
    : colors[code % colors.length]
  return (
    <div
      className={[classes, sizeClassMap[size]].join(' ')}
      style={{ backgroundColor, color }}
    >
      {initials}
    </div>
  )
}
export default AvatarLetter

const classes = `flex flex-col rounded-full uppercase items-center justify-center font-bold min-w-lg min-h-lg`

const sizeClassMap = {
  md: 'text-sm min-w-lg min-h-lg',
  lg: 'text-md min-w-xl min-h-xl',
}

const colors: { backgroundColor: string; color: string }[] = [
  { backgroundColor: '#e25f51', color: 'white' },
  { backgroundColor: '#f26091', color: 'white' },
  { backgroundColor: '#bb65ca', color: 'white' },
  { backgroundColor: '#9572cf', color: 'white' },
  { backgroundColor: '#7884cd', color: 'white' },
  { backgroundColor: '#5b95f9', color: 'white' },
  { backgroundColor: '#48c2f9', color: 'rgb(17, 24, 39)' },
  { backgroundColor: '#45d0e2', color: 'rgb(17, 24, 39)' },
  { backgroundColor: '#48b6ac', color: 'white' },
  { backgroundColor: '#52bc89', color: 'white' },
  { backgroundColor: '#9bce5f', color: 'rgb(17, 24, 39)' },
  { backgroundColor: '#d4e34a', color: 'rgb(17, 24, 39)' },
  { backgroundColor: '#feda10', color: 'rgb(17, 24, 39)' },
  { backgroundColor: '#f7c000', color: 'rgb(17, 24, 39)' },
  { backgroundColor: '#ffa800', color: 'white' },
  { backgroundColor: '#ff8a60', color: 'white' },
  { backgroundColor: '#c2c2c2', color: 'rgb(17, 24, 39)' },
  { backgroundColor: '#8fa4af', color: 'white' },
  { backgroundColor: '#a2887e', color: 'white' },
  { backgroundColor: '#a3a3a3', color: 'white' },
  { backgroundColor: '#afb5e2', color: 'rgb(17, 24, 39)' },
  { backgroundColor: '#b39bdd', color: 'white' },
  { backgroundColor: '#c2c2c2', color: 'rgb(17, 24, 39)' },
  { backgroundColor: '#7cdeeb', color: 'rgb(17, 24, 39)' },
  { backgroundColor: '#bcaaa4', color: 'rgb(17, 24, 39)' },
  { backgroundColor: '#add67d', color: 'rgb(17, 24, 39)' },
]

function numberFromText(text: string) {
  // numberFromText("AA");
  const charCodes = text
    .split('') // => ["A", "A"]
    .map((char) => char.charCodeAt(0)) // => [65, 65]
    .join('') // => "6565"
  return parseInt(charCodes, 10)
}
