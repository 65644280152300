import { retrieveCredentials } from '@trinsly/common/src/services/api'
import axios from 'axios'
import CrmEnum from 'types/crm-enum'

type StartMergeSingleIntegrationServiceProps = {
  integration: CrmEnum
}

async function startMergeSingleIntegrationService({
  integration,
}: StartMergeSingleIntegrationServiceProps) {
  const { accessToken } = retrieveCredentials()
  const response = await axios.request({
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${process.env.REACT_APP_MERGE_API_URL}/connection`,
    data: {
      ats: integration,
    },
  })

  const linkToken = (response?.data?.linkToken || '') as string
  return { linkToken }
}

export default startMergeSingleIntegrationService
