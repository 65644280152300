import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useQuery } from 'react-query'

import getUserSignatureService from 'services/settings/get-user-signature-service'
import { Signature } from 'types/settings-type'

type UseGetUserSignatureProps = {} & MutateApiHookType<Signature>

function useGetUserSignature({ onSuccess, onError }: UseGetUserSignatureProps) {
  const {
    data,
    error,
    isLoading: pending,
  } = useQuery('/signature', getUserSignatureService, {
    onSuccess,
    onError,
  })

  return {
    data,
    error,
    pending,
  }
}

export default useGetUserSignature
