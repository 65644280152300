import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation } from 'react-query'
import unsubscribeService from 'services/unsubscribe-service'

type UseUnsubscribeProps = {} & MutateApiHookType<void>

function useUnsubscribe({ onSuccess, onError }: UseUnsubscribeProps) {
  const { mutate: fetch, isLoading: pending } = useMutation(
    unsubscribeService,
    {
      onSuccess,
      onError,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useUnsubscribe
