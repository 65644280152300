import Select from '@trinsly/common/src/components/molecules/select'
import { FC, useMemo } from 'react'
import CrmEnum from 'types/crm-enum'
import { convertAtsResults, renderItems } from './ats-ops'

interface SyncUserSelectProps {
  atsResults: any[]
  crmType: CrmEnum
  disabled?: boolean
  userId: Id
  onChange: (value: any, userId: Id) => void
  value: any
}

const SyncUserSelect: FC<SyncUserSelectProps> = ({
  atsResults,
  crmType,
  disabled,
  userId,
  onChange,
  value,
}) => {
  const options = useMemo(() => {
    return convertAtsResults[crmType]?.(atsResults)
  }, [atsResults, crmType])

  function handleChange(value: any) {
    onChange(value, userId)
  }

  return (
    <Select
      disabled={disabled}
      onChange={(value) => {
        handleChange(value || null)
      }}
      options={options}
      itemRenderer={(option) =>
        renderItems[crmType]({ option, isSelected: false })
      }
      value={value}
    />
  )
}

export default SyncUserSelect
