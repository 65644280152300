import api from '@trinsly/common/src/services/api'
import { pickBy } from 'lodash'
import ProspectType from 'types/prospect-type'

type CreateProspectServiceProps = {
  prospect: {
    email: string
    firstName: string
    lastName: string
    city: string
    state: string
    zip: string
    linkedin: string
  }
  employments: {
    startTime: string
    endTime: string
    role: string
    company: ProspectType['employments'][number]['companyAttributes']
  }[]
  educations: {
    startTime: string
    endTime: string
    degreeType: string
    major: string
    university: ProspectType['educations'][number]['universityAttributes']
  }[]
}

async function createProspectService({
  prospect,
  educations,
  employments,
}: CreateProspectServiceProps) {
  const response = (await api(`/prospects`, {
    method: 'POST',
    body: {
      prospect: pickBy({
        email: prospect?.email,
        first_name: prospect?.firstName,
        last_name: prospect?.lastName,
        linkedin_name: prospect?.linkedin,
        city: prospect?.city,
        state: prospect?.state,
        zip: prospect?.zip,

        educations_attributes: educations?.map?.((education) => ({
          start_date: education?.startTime,
          end_date: education?.endTime,
          degree_type: education?.degreeType,
          major: education?.major,
          university_attributes: education?.university,
        })),
        employments_attributes: employments?.map?.((employment) => ({
          start_date: employment?.startTime,
          end_date: employment?.endTime,
          role: employment?.role,
          company_attributes: employment?.company,
        })),
      }),
    },
  })) as ProspectType
  return response
}

export default createProspectService
