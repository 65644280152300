import Card from '@trinsly/common/src/components/atoms/card'
import Column from '@trinsly/common/src/components/atoms/column'
import Divider from '@trinsly/common/src/components/atoms/divider'
import DualBackground from '@trinsly/common/src/components/atoms/dual-background'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import usePageableState from '@trinsly/common/src/hooks/use-pageable-state'
import useGetThreads from 'api-hooks/use-get-threads'
import ReachOutsTable from '../reach-outs-section/reach-outs-table'
import CancelAllPausedReachOutsButton from './cancel-all-paused-reach-outs-button'
import RescheduleAllPausedReachOutsButton from './reschedule-all-paused-reach-outs-button'

/**
 * - Show Paused On Date - Pause reason?
 * - Banner notification with count and link to href + table below?
 * - Outline in warning color
 * - Reschedule or Cancel buttons
 * @returns
 */

const UnsentReachoutsSection = () => {
  const { pageable, nextPage, prevPage } = usePageableState({
    page: 1,
    perPage: 10,
    filters: {
      unsent: true,
    },
  })

  const {
    data: unsentThreads,
    total,
    pending: pendingUnsentThreads,
  } = useGetThreads(pageable)

  const unsentCount = unsentThreads?.length || 0

  if (!unsentCount) {
    return null
  }

  return (
    <DualBackground>
      <Column className="px-lg py-lg">
        <Card className="border-4 border-yellow-500">
          <Row className="p-md justify-between">
            <Heading size="sm" fontWeight="semibold">
              You currrently have {unsentCount} paused campaigns
            </Heading>
            <Row>
              <RescheduleAllPausedReachOutsButton />
              <Separator size="xs" />
              <CancelAllPausedReachOutsButton />
            </Row>
          </Row>
          <Divider />
          <ReachOutsTable
            threads={unsentThreads}
            pending={pendingUnsentThreads}
            pagination={{
              page: pageable?.page,
              perPage: pageable?.perPage,
              total,
            }}
            onNextPage={nextPage}
            onPrevPage={prevPage}
          />
        </Card>
      </Column>
    </DualBackground>
  )
}

export default UnsentReachoutsSection
