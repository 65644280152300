import { ReactComponent as DeleteSvg } from '@trinsly/common/src/assets/icons/delete.svg'
import { ReactComponent as LinkedinSvg } from '@trinsly/common/src/assets/icons/linkedin.svg'
import AvatarLetter from '@trinsly/common/src/components/atoms/avatar-letter'
import Card from '@trinsly/common/src/components/atoms/card'
import Column from '@trinsly/common/src/components/atoms/column'
import Link from '@trinsly/common/src/components/atoms/link'
import Row from '@trinsly/common/src/components/atoms/row'
import Table from '@trinsly/common/src/components/atoms/table'
import ButtonGroup from '@trinsly/common/src/components/atoms/tabs/button-group'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import IconButton from '@trinsly/common/src/components/molecules/icon-button'
import Pagination from '@trinsly/common/src/components/molecules/pagination'
import SearchInput from '@trinsly/common/src/components/molecules/search-input'
import NonIdealState from '@trinsly/common/src/components/organisms/non-ideal-state'
import usePageableState from '@trinsly/common/src/hooks/use-pageable-state'
import useDeleteProspectFromProject from 'api-hooks/use-delete-prospect-from-project'
import useGetProjectProspects from 'api-hooks/use-get-project-prospects'
import moment from 'moment'
import React from 'react'
import { toast } from 'react-toastify'
import StatusField from '../../prospect-detail-screen/dashboard-section/header-section/status-field'
import { useFormContext } from '../form-provider'
import TableViewModeEnum from '../table-view-mode-enum'
import LinkProspectsToProjectModal from './link-prospects-to-project-modal'

type ProspectsSectionProps = {
  onViewModeChange: (tableViewMode: TableViewModeEnum) => void
}

function ProspectsSection({ onViewModeChange }: ProspectsSectionProps) {
  const [showAddProspectModal, setShowAddProspectModal] = React.useState(false)
  const { project } = useFormContext()
  const { pageable, setSearch, nextPage, prevPage } = usePageableState({
    page: 1,
    perPage: 10,
  })

  const {
    data: prospects,
    total,
    pending,
  } = useGetProjectProspects({ projectId: project?.id || 0, ...pageable })
  const isNonIdealState = !pending && !prospects.length
  const { fetch: deleteProspect, pending: pendingDelete } =
    useDeleteProspectFromProject({
      onError: (error) => {
        toast.error('Unable to delete prospect - please try again later')
      },
      onSuccess: () => {
        toast.success('Deleted prospect')
      },
    })
  return (
    <Column className="gap-sm">
      <Row className="justify-between gap-sm">
        <Column className="w-full max-w-md">
          <SearchInput
            placeholder="Search"
            value={pageable?.search}
            onChange={(value) => setSearch(value)}
          />
        </Column>
        <Row className="items-center gap-sm whitespace-nowrap">
          <Button
            size="sm"
            variant="outline"
            intent="primary"
            onClick={() => {
              setShowAddProspectModal(true)
            }}
          >
            Add Prospects to Project
          </Button>
          <ButtonGroup
            variant="state"
            id="table-view-mode"
            defaultTab={TableViewModeEnum.PROSPECTS}
            onChange={(tabId) => onViewModeChange(tabId as TableViewModeEnum)}
          >
            <ButtonGroup.Button id={TableViewModeEnum.PROSPECTS}>
              Prospects
            </ButtonGroup.Button>
            <ButtonGroup.Button id={TableViewModeEnum.CAMPAIGNS}>
              Campaigns
            </ButtonGroup.Button>
          </ButtonGroup>
        </Row>
      </Row>
      <Card className="overflow-hidden">
        {isNonIdealState ? (
          <Column className="p-md">
            <NonIdealState message="No prospects yet" />
          </Column>
        ) : (
          <React.Fragment>
            <Table
              pending={pending}
              data={prospects}
              columns={[
                {
                  width: 200,
                  header: 'Prospect',
                  cell: (item) => {
                    const initials =
                      item?.firstName?.[0] ||
                      item?.lastName?.[0] ||
                      item?.email?.[0] ||
                      ''
                    return (
                      <Link href={`/prospects/${item?.id}`}>
                        <Row className="items-center gap-xs">
                          <AvatarLetter size="lg" initials={initials} />
                          <Column>
                            <Text size="sm" color="gray-900">
                              {item?.name}
                            </Text>
                            <Text size="xs">{item?.email}</Text>
                          </Column>
                        </Row>
                      </Link>
                    )
                  },
                },
                {
                  width: 100,
                  header: 'Status',
                  cell: (item) => <StatusField status={item.status} />,
                },
                {
                  width: 100,
                  header: 'Company',
                  cell: ({ employments }) =>
                    employments.length
                      ? employments[employments.length - 1]?.companyName ?? ''
                      : '',
                },
                {
                  width: 100,
                  header: 'Owner',
                  cell: (item) => item.creator.name ?? '',
                },
                {
                  width: 100,
                  header: 'Last Contacted',
                  cell: ({ lastContactedAt }) =>
                    lastContactedAt
                      ? moment(lastContactedAt).format('l')
                      : 'N/A',
                },
                {
                  width: 100,
                  header: 'Actions',
                  cell: ({ id, linkedinName }) => {
                    const hasLinkedin = Boolean(linkedinName)
                    return (
                      <Row className="items-center gap-sm">
                        <Link
                          className="w-lg h-lg items-center justify-center"
                          disabled={!hasLinkedin}
                          href={`https://www.linkedin.com/in/${linkedinName}/`}
                          title={`https://www.linkedin.com/in/${linkedinName}/`}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <LinkedinSvg
                            className={`"w-lg h-lg ${
                              hasLinkedin ? 'text-info-500' : 'text-gray-200'
                            }`}
                          />
                        </Link>
                        <IconButton
                          intent="danger"
                          onClick={() =>
                            deleteProspect({
                              projectId: project?.id || -1,
                              prospectId: id || -1,
                            })
                          }
                          pending={pendingDelete || pending}
                          variant="outline"
                        >
                          <DeleteSvg />
                        </IconButton>
                      </Row>
                    )
                  },
                },
              ]}
            />
            <Row className="items-center py-sm px-md justify-end">
              <Pagination
                page={pageable?.page}
                total={total}
                perPage={pageable?.perPage}
                onNext={nextPage}
                onPrev={prevPage}
              />
            </Row>
          </React.Fragment>
        )}
      </Card>
      <LinkProspectsToProjectModal
        project={project}
        visible={showAddProspectModal}
        onRequestClose={() => setShowAddProspectModal(false)}
      />
    </Column>
  )
}

export default ProspectsSection
