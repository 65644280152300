import moment from 'moment'

export enum LeaderboardDateRangeEnum {
  ALL = 'ALL',
  LAST_MONTH = 'LAST_MONTH',
  LAST_WEEK = 'LAST_WEEK',
  THIS_MONTH = 'THIS_MONTH',
  THIS_WEEK = 'THIS_WEEK',
  CUSTOM = 'CUSTOM',
}

export type LeaderboardDateRangeOption = {
  id: LeaderboardDateRangeEnum
  name: string
}

export const leaderboardDateRangeOptions: LeaderboardDateRangeOption[] = [
  {
    id: LeaderboardDateRangeEnum.THIS_WEEK,
    name: 'This Week',
  },
  {
    id: LeaderboardDateRangeEnum.THIS_MONTH,
    name: 'This Month',
  },
  {
    id: LeaderboardDateRangeEnum.LAST_WEEK,
    name: 'Last Week',
  },
  {
    id: LeaderboardDateRangeEnum.LAST_MONTH,
    name: 'Last Month',
  },
  {
    id: LeaderboardDateRangeEnum.ALL,
    name: 'All',
  },
  {
    id: LeaderboardDateRangeEnum.CUSTOM,
    name: 'Custom',
  },
]

export const translateDateRange = ({
  dateRange,
  localizedDate,
}: {
  dateRange: LeaderboardDateRangeEnum
  localizedDate: moment.Moment
}): { endDate: moment.Moment; startDate: moment.Moment } => {
  switch (dateRange) {
    case LeaderboardDateRangeEnum.LAST_MONTH: {
      const endDate = localizedDate
        .clone()
        .startOf('month')
        .subtract(1, 'second')
      const startDate = endDate.clone().startOf('month')

      return { endDate, startDate }
    }
    case LeaderboardDateRangeEnum.LAST_WEEK: {
      const endDate = localizedDate
        .clone()
        .startOf('week')
        .subtract(1, 'second')
      const startDate = endDate.clone().startOf('week')

      return { endDate, startDate }
    }
    case LeaderboardDateRangeEnum.THIS_MONTH:
      return {
        endDate: localizedDate,
        startDate: localizedDate.clone().startOf('month'),
      }
    case LeaderboardDateRangeEnum.THIS_WEEK:
      return {
        endDate: localizedDate,
        startDate: localizedDate.clone().startOf('week'),
      }

    case LeaderboardDateRangeEnum.ALL:
    default:
      return {
        endDate: localizedDate,
        startDate: localizedDate.clone().subtract(5, 'years'),
      }
  }
}

export const calcDates = (
  dateRange: LeaderboardDateRangeEnum,
  offset: number = 0
) => {
  const localizedDate = dateToTimeZone({ timezoneOffset: offset as number })
  return translateDateRange({
    dateRange,
    localizedDate,
  })
}

function dateToTimeZone({
  date = new Date(),
  timezoneOffset,
}: {
  date?: string | Date
  timezoneOffset: number
}): moment.Moment {
  return moment.utc(date).add(timezoneOffset, 'seconds')
}
