import Column from '@trinsly/common/src/components/atoms/column'
import Divider from '@trinsly/common/src/components/atoms/divider'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import TextInput from '@trinsly/common/src/components/molecules/text-input'
import React from 'react'
import SelectUniversity from 'screens/prospect-detail-screen/dashboard-section/educations-section/select-university'

type EducationsSectionProps = {
  getValue: (path: string) => any
  setValue: (path: string, value: any) => void
  getError: (path: string) => any
}

function EducationsSection({
  getValue,
  setValue,
  getError,
}: EducationsSectionProps) {
  const educations = getValue('educations') || []
  const isEducationsNonIdealState = !educations?.length
  return (
    <Column>
      <Row className="items-center justify-between">
        <Heading size="lg" fontWeight="bold">
          Educations
        </Heading>
        <Column className="min-w-36">
          <Button
            size="sm"
            variant="outline"
            intent="primary"
            onClick={() => {
              setValue(`educations`, [...(educations || []), {}])
            }}
          >
            Add education
          </Button>
        </Column>
      </Row>
      <Separator size="xs" />
      {isEducationsNonIdealState && (
        <Text size="sm" color="gray-400">
          No education history.
        </Text>
      )}
      <Column className="gap-lg">
        {educations?.map?.((education: any, index: number) => {
          const relativePath = `educations[${index}]`
          return (
            <React.Fragment key={index}>
              {index > 0 && <Divider />}
              <Column className="gap-md">
                <Row className="items-center gap-sm">
                  <Text size="sm">{`Education #${index + 1}`}</Text>
                  <Button
                    size="xs"
                    onClick={() => {
                      const newEducations = educations?.filter?.(
                        (education: any, i: number) => i !== index
                      )
                      setValue(`educations`, newEducations)
                    }}
                    intent="danger"
                    variant="outline"
                  >
                    Remove
                  </Button>
                </Row>
                <SelectUniversity
                  creatable={true}
                  label="University"
                  value={getValue(`${relativePath}.university`)}
                  onChange={(value) =>
                    setValue(`${relativePath}.university`, value)
                  }
                  onCreate={(value) => {
                    setValue(`${relativePath}.university`, { name: value })
                  }}
                  error={getError(`${relativePath}.university`)}
                />
                <Row className="gap-sm">
                  <TextInput
                    type="date"
                    label="Start Date"
                    value={getValue(`${relativePath}.startDate`)}
                    onChange={(value) =>
                      setValue(`${relativePath}.startDate`, value)
                    }
                    error={getError(`${relativePath}.startDate`)}
                  />
                  <TextInput
                    type="date"
                    label="End Date"
                    value={getValue(`${relativePath}.endDate`)}
                    onChange={(value) =>
                      setValue(`${relativePath}.endDate`, value)
                    }
                    error={getError(`${relativePath}.endDate`)}
                  />
                </Row>
                <Row className="gap-sm">
                  <Column className="w-1/3">
                    <TextInput
                      label="Degree"
                      value={getValue(`${relativePath}.degreeType`)}
                      onChange={(value) =>
                        setValue(`${relativePath}.degreeType`, value)
                      }
                      error={getError(`${relativePath}.degreeType`)}
                    />
                  </Column>
                  <TextInput
                    label="Major"
                    value={getValue(`${relativePath}.major`)}
                    onChange={(value) =>
                      setValue(`${relativePath}.major`, value)
                    }
                    error={getError(`${relativePath}.major`)}
                  />
                </Row>
              </Column>
            </React.Fragment>
          )
        })}
      </Column>
    </Column>
  )
}

export default EducationsSection
