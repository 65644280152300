import {
  EXTENSION_IMPERSONATE_USER_ID_KEY,
  TRINSLY_ACCESS_TOKEN,
} from '@trinsly/common/src/services/api'
import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation, useQueryClient } from 'react-query'
import { sendTokenToChromeExtension } from 'services/chrome/send-token-to-extension'
import UserType from 'types/user-type'

type UseImpersonateProps = {} & MutateApiHookType<void>

function useImpersonate({ onSuccess, onError }: UseImpersonateProps) {
  const queryClient = useQueryClient()
  const { mutate: fetch, isLoading: pending } = useMutation(
    async ({ userId }: { userId: UserType['id'] }) => {
      localStorage?.setItem?.(
        EXTENSION_IMPERSONATE_USER_ID_KEY,
        String(userId || '')
      )
      sendTokenToChromeExtension({
        jwt: localStorage.getItem(TRINSLY_ACCESS_TOKEN) || '',
        impersonateUserId:
          localStorage.getItem(EXTENSION_IMPERSONATE_USER_ID_KEY) || '',
      })
    },
    {
      onSuccess: async () => {
        queryClient.removeQueries()
        onSuccess?.()
      },
      onError,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useImpersonate
