import React from 'react'

export interface TagProps {
  children: React.ReactNode
  backgroundColor: string
  textColor: string
  shape?: 'pill' | 'rounded'
}

function Tag({
  children,
  backgroundColor,
  textColor,
  shape = 'pill',
}: TagProps) {
  return (
    <span
      className={[badgeClasses, shapeClassMap[shape]].join(' ')}
      style={{
        backgroundColor,
        color: textColor,
      }}
    >
      {children}
    </span>
  )
}

const badgeClasses = `text-center text-xs px-sm py-0.5 font-bold uppercase whitespace-nowrap`
const shapeClassMap = {
  pill: 'rounded-full',
  rounded: 'rounded-md',
}

export default Tag
