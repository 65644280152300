import Column from '@trinsly/common/src/components/atoms/column'
import Form from '@trinsly/common/src/components/atoms/form'
import Text from '@trinsly/common/src/components/atoms/text'
import ModalV2 from '@trinsly/common/src/components/molecules/modal-v2'
import Stepper from '@trinsly/common/src/components/molecules/stepper'
import useFormConcurrent from '@trinsly/common/src/hooks/use-form-concurrent'
import useSendCampaign from 'api-hooks/use-send-campaign'
import React from 'react'
import { toast } from 'react-toastify'
import ProspectType from 'types/prospect-type'
import CampaignStep from './campaign-step'
import CustomizeStep from './customize-step'

type SendCampaignFormModalProps = {
  visible: boolean
  onRequestClose: () => void
  prospect: ProspectType | undefined
}

function SendCampaignFormModal({
  visible,
  onRequestClose,
  prospect,
}: SendCampaignFormModalProps) {
  const [step, setStep] = React.useState(
    SendCampaignFormModalStepsEnum.CAMPAIGN
  )

  const { getValue, setValue, getError, reset, submit } = useFormConcurrent({
    onSubmit: (values) => {
      sendCampaign({
        campaign: values?.campaign,
        emails: values?.custom?.emails,
        prospect: prospect!,
        schedules: values?.schedules,
      })
    },
  })
  const { fetch: sendCampaign, pending: pendingSendCampaign } = useSendCampaign(
    {
      onSuccess: (data) => {
        toast.success('Campaign Sent!')
        onRequestClose?.()
      },
      onError: (error) => {
        const errorMessage = error?.message || 'Error sending campaign'
        console.log(errorMessage, error)
        toast.error(errorMessage)
      },
    }
  )

  React.useEffect(() => {
    if (visible) {
      reset()
      setStep(SendCampaignFormModalStepsEnum.CAMPAIGN)
    }
  }, [visible])

  return (
    <ModalV2 visible={visible} onRequestClose={onRequestClose}>
      <Column className="p-md gap-md" style={{ minWidth: 600 }}>
        <Form onSubmit={submit}>
          <Column className="gap-3">
            <Stepper
              steps={['Campaign', 'Customize']}
              current={step}
              onClick={(step) => {
                setStep(step as SendCampaignFormModalStepsEnum)
              }}
            />
            <Text size="xl" color="primary-500" fontWeight="bold">
              You’re about to send out a campaign
            </Text>
          </Column>
          {
            {
              [SendCampaignFormModalStepsEnum.CAMPAIGN]: (
                <CampaignStep
                  getValue={getValue}
                  setValue={setValue}
                  getError={getError}
                  prospect={prospect}
                  onNext={() => {
                    setStep(SendCampaignFormModalStepsEnum.CUSTOMIZE)
                  }}
                />
              ),
              [SendCampaignFormModalStepsEnum.CUSTOMIZE]: (
                <CustomizeStep
                  getValue={getValue}
                  setValue={setValue}
                  getError={getError}
                  prospect={prospect}
                  pending={pendingSendCampaign}
                />
              ),
            }[step]
          }
        </Form>
      </Column>
    </ModalV2>
  )
}

export default SendCampaignFormModal

export enum SendCampaignFormModalStepsEnum {
  CAMPAIGN = 0,
  CUSTOMIZE = 1,
}
