import { uniqueId } from 'lodash'
import React from 'react'
import ReactQuill from 'react-quill'

import 'react-quill/dist/quill.snow.css'
import { quillDecodeIndent, quillEncodeIndent } from './fixes/list-indent-fix'
import emojiModule from './modules/emoji-module'
import imageResizeModule from './modules/image-resize-module'
import inlineStyleModule from './modules/inline-style-module'
import paragraphModule from './modules/paragraph-module'
import s3ImageModule from './modules/s3-image-module'
import './styles.css'
import Toolbar from './toolbar'

export type QuillEditorProps = {
  id?: string
  testId?: string
  onChange?: (event: {
    html: string
    text: string
    counter: { letters: number; words: number }
  }) => void
  onFocus?: (event: any) => void
  value?: string
  placeholder?: string
  error?: string
  disabled?: boolean
  modules?: ('image-resize' | 'variables' | 's3-uploader')[]
  style?: {
    height: string | number
  }
}

function _QuillEditor(
  {
    id,
    testId = id,
    value,
    onChange,
    onFocus,
    placeholder,
    error,
    disabled,
    modules = ['image-resize', 'variables', 's3-uploader'],
    style,
  }: QuillEditorProps,
  ref: React.Ref<ReactQuill>
) {
  const [ready, setReady] = React.useState(false)
  const memoizedId = React.useMemo(() => id || uniqueId('quill-editor-'), [id])
  const memoizedToolbarId = `${memoizedId}-toolbar`

  const [memoizedQuillOptions, setMemoizedQuillOptions] = React.useState<any>()
  React.useLayoutEffect(() => {
    setMemoizedQuillOptions({
      modules: {
        toolbar: {
          container: `#${memoizedToolbarId}`,
        },

        ...paragraphModule,
        ...inlineStyleModule,
        ...emojiModule,
        ...(modules?.includes('s3-uploader') ? s3ImageModule : {}),
        ...(modules?.includes('image-resize') ? imageResizeModule : {}),
      },
    })
  }, [memoizedId, memoizedToolbarId, ...modules])

  //this solves autofocus issue from react quill
  //https://github.com/zenoamaro/react-quill/issues/317
  React.useEffect(() => {
    if (memoizedQuillOptions) {
      setReady(true)
    }
  }, [memoizedQuillOptions])

  return (
    <div
      className={`
        ${baseClasses} 
        ${disabled ? disabledClasses : ''} 
        ${error && !disabled ? errorClasses : ''}
        ${!error && !disabled ? defaultClasses : ''}
      `}
      style={{
        zIndex: 1,
      }}
    >
      <Toolbar
        id={memoizedToolbarId}
        disabled={disabled}
        modules={modules}
        reactQuillRef={ref as any}
      />

      <ReactQuill
        id={`${memoizedId}-input`}
        {...{ 'data-test-id': testId }}
        style={{
          ...{
            minHeight: style?.height ?? '20rem',
            maxHeight: style?.height ?? '20rem',
          },
          overflowY: 'auto',
        }}
        ref={ref}
        placeholder={placeholder}
        theme="snow"
        readOnly={!ready || disabled}
        onFocus={onFocus}
        value={quillEncodeIndent(value)}
        onChange={(html, delta, source, editor) => {
          if (source === 'user') {
            const text = editor.getText()
            onChange?.({
              html: quillDecodeIndent(html),
              text,
              counter: {
                letters: editor.getLength() - 1,
                words: text?.split(' ').length || 0,
              },
            })
          }
        }}
        {...memoizedQuillOptions}
      />
    </div>
  )
}

const QuillEditor = React.forwardRef(_QuillEditor)
export default QuillEditor

const baseClasses = `flex flex-col text-gray-900 placeholder-gray-300 rounded-t-md border focus-within:ring transition`
const defaultClasses = `bg-gray-50 border-gray-300 focus-within:border-primary-300 focus-within:ring-primary-200`
const errorClasses = `bg-danger-50 bg-opacity-33 border-danger-300 focus-within:border-danger-300 focus-within:ring-danger-200`
const disabledClasses = `border-dashed border-gray-300 bg-white`
