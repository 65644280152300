import { ReactComponent as DeleteSvg } from '@trinsly/common/src/assets/icons/delete.svg'
import Clickable from '@trinsly/common/src/components/atoms/clickable'
import Column from '@trinsly/common/src/components/atoms/column'
import Row from '@trinsly/common/src/components/atoms/row'
import Spinner from '@trinsly/common/src/components/atoms/spinner'
import Text from '@trinsly/common/src/components/atoms/text'
import IconButton from '@trinsly/common/src/components/molecules/icon-button'
import NonIdealState from '@trinsly/common/src/components/organisms/non-ideal-state'
import useDeleteProspectNote from 'api-hooks/use-delete-prospect-note'
import useGetProspectNotes from 'api-hooks/use-get-prospect-notes'
import { format, parseISO } from 'date-fns'
import React from 'react'
import { toast } from 'react-toastify'
import ProspectNoteType from 'types/prospect-note-type'
import { useFormContext } from '../../form-provider'

type NotesListProps = {
  onEdit: (note: ProspectNoteType) => void
}

function NotesList({ onEdit }: NotesListProps) {
  const [pendingDeleteMap, setPendingDeleteMap] = React.useState<{
    [index: string]: boolean
  }>({})
  const { prospect } = useFormContext()
  const { data: notes, pending: pendingNotes } = useGetProspectNotes({
    prospectId: prospect?.id || '',
  })

  const { fetch: deleteNote } = useDeleteProspectNote({
    onMutate: ({ noteId }) => {
      setPendingDeleteMap((state) => ({
        ...state,
        [noteId]: true,
      }))
    },
    onSuccess: (_, { noteId }) => {
      setPendingDeleteMap((state) => ({
        ...state,
        [noteId]: false,
      }))
      toast.success('Note deleted!')
    },
    onError: (_, { noteId }) => {
      setPendingDeleteMap((state) => ({
        ...state,
        [noteId]: false,
      }))
      toast.error('Error deleting note')
    },
  })

  const pending = !prospect?.id || pendingNotes
  const isNonIdealState = !pending && !notes?.length

  return (
    <Column className="min-h-6">
      {pending ? (
        <Column className="justify-center items-center">
          <Spinner />
        </Column>
      ) : isNonIdealState ? (
        <NonIdealState message="No Prospect Data Available" />
      ) : (
        <table cellSpacing="0" cellPadding="0">
          <tbody>
            {notes?.map((note, index) => {
              return (
                <tr key={index}>
                  <td className="p-0 min-w-56" style={{ maxWidth: '14rem' }}>
                    <Clickable onClick={() => onEdit?.(note)}>
                      <Text
                        size="xs"
                        color="info-500"
                        className="line-clamp-1 underline"
                      >
                        {note?.comment || '--'}
                      </Text>
                    </Clickable>
                  </td>
                  <td className="p-0 pl-md whitespace-nowrap">
                    <Text size="xs">
                      {note?.createdAt
                        ? format(
                            parseISO(note?.createdAt),
                            'MM/dd/yyyy, hh:mm a'
                          )
                        : note?.createdAt || '--'}
                    </Text>
                  </td>
                  <td className="p-0 pl-md whitespace-nowrap">
                    <Text size="sm">{note?.userName || '--'}</Text>
                  </td>
                  <td className="p-0 pl-md">
                    <Row className="gap-xs justify-end">
                      <IconButton
                        size="xs"
                        variant="transparent"
                        intent="danger"
                        pending={pendingDeleteMap[note?.id]}
                        onClick={() => {
                          deleteNote({ noteId: note?.id || 0 })
                        }}
                      >
                        <DeleteSvg />
                      </IconButton>
                    </Row>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      )}
    </Column>
  )
}

export default NotesList
