import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation, useQueryClient } from 'react-query'
import createCalendlyIntegrationService from 'services/settings/create-calendly-integration-service'
import { Calendly } from 'types/settings-type'

export type UseCreateCalendlyIntegrationProps = {} & MutateApiHookType<Calendly>

function useCreateCalendlyIntegration({
  onSuccess,
  onError,
}: UseCreateCalendlyIntegrationProps) {
  const queryClient = useQueryClient()

  const { mutate: fetch, isLoading: pending } = useMutation(
    createCalendlyIntegrationService,
    {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries('/settings')

        onSuccess?.(data)
      },
      onError,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useCreateCalendlyIntegration
