/* eslint-disable react/jsx-max-depth */
import Checkbox from '@trinsly/common/src/components/atoms/checkbox'
import Column from '@trinsly/common/src/components/atoms/column'
import Form from '@trinsly/common/src/components/atoms/form'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Label from '@trinsly/common/src/components/atoms/label'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Spinner from '@trinsly/common/src/components/atoms/spinner'
import Switch from '@trinsly/common/src/components/atoms/switch'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import SelectInput from '@trinsly/common/src/components/molecules/select-input'
import useForm from '@trinsly/common/src/hooks/use-form'
import useCreateThrottleSettings from 'api-hooks/use-create-throttle-setting'
import { toast } from 'react-toastify'

export type ThrottleSectionProps = {
  userSettings: any
  settingsPending: boolean
}

const ThrottleSection = ({
  userSettings,
  settingsPending,
}: ThrottleSectionProps) => {
  const { fetch: updateThrottle, pending: updatePending } =
    useCreateThrottleSettings({
      onSuccess: (data) => {
        toast('Throttle settings updated successfully', {
          type: 'success',
        })
      },
    })
  const { getValue, setValue, submit, getValues } = useForm({
    initialValues: {
      isActive: userSettings?.throttle?.isActive,
      emailsCount: userSettings?.throttle?.emailsCount,
      includeSundays: userSettings?.throttle?.includeSundays,
      startHour: userSettings?.throttle?.startHour,
      endHour: userSettings?.throttle?.endHour,
    },
    onSubmit: ({
      isActive,
      emailsCount,
      includeSundays,
      startHour,
      endHour,
    }) => {
      updateThrottle({
        isActive,
        emailsCount,
        includeSundays,
        startHour,
        endHour,
        //not in form
        id: userSettings.throttle.id,
        userId: userSettings.throttle.userId,
        createdAt: userSettings.throttle.createdAt,
        updatedAt:
          userSettings.throttle.updatedAt ?? Date.now().toLocaleString(),
        minimumInterval: 20, //doesnt do anything - API is weird
      })
    },
    validations: {},
  })

  const hours = [...Array(24).keys()]
  const maxEmailsPerDayCount = [100, 200, 300, 400, 500]
  const isActive = getValue('isActive')
  const values = getValues()

  return (
    <>
      <Form onSubmit={submit}>
        <Column className="flex-1 justify-center items-start">
          <Column className="max-w-lg">
            <Heading
              size="sm"
              fontWeight="bold"
              color="gray-500"
              className="uppercase"
            >
              Throttle
            </Heading>
            <Text color="gray-500" size="sm">
              Control how many e-mails Talent CRM sends per day for your account
              (includes follow-ups). If too many e-mails are scheduled for a
              given day, it will push the next e-mail to the next weekday
            </Text>
          </Column>
          <Separator />
          <Row className="items-center">
            <Switch
              disabled={updatePending || settingsPending}
              checked={isActive}
              onChange={() => {
                setValue('isActive', !isActive)
                updateThrottle({ ...values, isActive: !isActive })
              }}
            />
            <Separator size="xs" />
            <Text
              color={isActive ? 'success-500' : 'gray-600'}
              fontWeight="bold"
            >
              {isActive ? 'Enabled' : 'Disabled'}
            </Text>
            <Separator size="xs" />
            <Column className="flex-1 h-10 justify-center items-center">
              {updatePending || settingsPending ? <Spinner /> : ''}
            </Column>
          </Row>
          <Separator />
          {isActive && (
            <Column className="py-4 rounded-lg" style={{ width: '48.5rem' }}>
              <Column className="w-96">
                <SelectInput
                  label="Maximum E-mails Per Day"
                  value={getValue('emailsCount')}
                  onChange={(value) => setValue('emailsCount', value)}
                  options={maxEmailsPerDayCount}
                  clearable={false}
                />
              </Column>
              <Separator />
              <Column className="w-96">
                <Label>
                  Include Sundays For Rescheduled Throttle Messages?
                </Label>
                <Separator size="xs" />
                <Row className="items-center">
                  <Checkbox
                    checked={getValue('includeSundays')}
                    onChange={() =>
                      setValue('includeSundays', !getValue('includeSundays'))
                    }
                  />
                  <Separator size="sm" />
                  <Label>Yes</Label>
                </Row>
              </Column>
              <Separator />
              <Row className="w-96">
                <Column className="w-1/2">
                  <SelectInput
                    label="Start hour"
                    value={getValue('startHour')}
                    onChange={(value) => setValue('startHour', value)}
                    options={hours}
                    itemRenderer={(hour) => (
                      <option key={hour} value={hour}>
                        {hour === 0
                          ? `12:00 am`
                          : hour < 12
                          ? `${hour}:00 am`
                          : hour === 12
                          ? `${hour}:00 pm`
                          : `${hour - 12}:00 pm`}
                      </option>
                    )}
                    clearable={false}
                  />
                </Column>
                <Separator size="sm" />
                <Column className="w-1/2">
                  <SelectInput
                    label="End hour"
                    value={getValue('endHour')}
                    onChange={(value) => setValue('endHour', value)}
                    options={hours}
                    itemRenderer={(hour) => (
                      <option key={hour} value={hour}>
                        {hour === 0
                          ? `12:00 am`
                          : hour < 12
                          ? `${hour}:00 am`
                          : hour === 12
                          ? `${hour}:00 pm`
                          : `${hour - 12}:00 pm`}
                      </option>
                    )}
                    clearable={false}
                  />
                </Column>
              </Row>
              <Separator size="sm" />
              <Text fontWeight="light" size="xs">
                Talent CRM e-mails will be sent within these times (after start
                hour, before end hour). To send anytime, just choose 12am to
                12am.
              </Text>
              <Separator size="xs" />
              <Row>
                <Button pending={updatePending} type="submit" intent="success">
                  Update throttle
                </Button>
              </Row>
            </Column>
          )}
        </Column>
      </Form>
    </>
  )
}

export default ThrottleSection
