import api from '@trinsly/common/src/services/api'

import Settings from 'types/settings-type'

export type UpdateUserPasswordServiceProps = {
  currentPassword: string
  password: string
  passwordConfirmation: string
}

export default async function updateUserPasswordService({
  currentPassword,
  password,
  passwordConfirmation,
}: UpdateUserPasswordServiceProps) {
  const response = await api('/auth', {
    body: {
      current_password: currentPassword,
      password: password,
      password_confirmation: passwordConfirmation,
    },
    method: 'PUT',
  })

  return response as any as { data: Settings; status: string }
}
