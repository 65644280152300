import Column from '@trinsly/common/src/components/atoms/column'
import Form from '@trinsly/common/src/components/atoms/form'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Link from '@trinsly/common/src/components/atoms/link'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Text from '@trinsly/common/src/components/atoms/text'
import Alert from '@trinsly/common/src/components/molecules/alert'
import Button from '@trinsly/common/src/components/molecules/button'
import TextInput from '@trinsly/common/src/components/molecules/text-input'
import useForm from '@trinsly/common/src/hooks/use-form'
import ErrorType from '@trinsly/common/src/types/error-type'
import required from '@trinsly/common/src/validations/required'
import validEmail from '@trinsly/common/src/validations/valid-email'
import useCreateUserSession from 'api-hooks/use-create-user-session'
import { ReactComponent as TrinslyLogoSvg } from 'assets/logo.svg'
import PublicLayout from 'components/public-layout'
import { isEmpty } from 'lodash'
import { useAuth } from 'providers/auth-provider'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import getUser2faStatusService from 'services/auth/get-user-2fa-status'
import PasswordInput from './password-input'
import TwoFactorOtpInput from './two-factor-otp-input'

export type SignInScreenProps = {}

function SignInScreen({}: SignInScreenProps) {
  const [viewState, setViewState] = useState<'login' | '2fa_code'>('login')
  const [user2fa, setUser2fa] = useState({})
  const [attemptingLogin, setAttemptingLogin] = useState(false)
  const [error, setError] = useState<ErrorType>()
  const history = useHistory()

  const { setUser, refetchUserSession } = useAuth()

  const { fetch: logIn, pending: loggingIn } = useCreateUserSession({
    onMutate: (data) => {
      setAttemptingLogin(true)
    },
    onSuccess: (user) => {
      setUser(user)
      refetchUserSession()
      history.push('/')
    },
    onError: (error) => {
      //login failed
      setAttemptingLogin(false)
      setError(error)
    },
  })

  const {
    getValue,
    setValue,
    getError,
    submit: submitLoginForm,
  } = useForm({
    onSubmit: async ({ email, password, otpAttempt }) => {
      if (isEmpty(user2fa)) {
        const user2faSettings = await getUser2faStatusService({ email })
        setUser2fa(user2faSettings)
        if (user2faSettings.isTwoFactorEnabled) {
          setViewState('2fa_code')
          return
        } else {
          logIn({ email, password })
        }
      } else {
        logIn({ email, password, otpAttempt })
      }
    },

    validations: {
      email: [required, validEmail],
      password: [required],
    },
  })

  return (
    <PublicLayout>
      <Column className="flex-1">
        <Form onSubmit={submitLoginForm}>
          <TrinslyLogoSvg
            height={80}
            width={200}
            style={{ alignSelf: 'left' }}
          />
          <Column style={{ minHeight: 60 }} />
          <Heading size="xl" fontWeight="bold">
            {viewState === '2fa_code'
              ? `Confirm Code for ${getValue('email')}`
              : 'Welcome Back.'}
          </Heading>
          <Separator size="lg" />
          {error && (
            <>
              <Alert intent="danger">
                <Column className="py-xs">
                  <Text size="sm">{error.message}</Text>
                </Column>
              </Alert>
              <Separator size="sm" />
            </>
          )}
          {viewState === '2fa_code' ? (
            <React.Fragment>
              <TwoFactorAuthForm
                value={getValue('otpAttempt')}
                onChange={(value) => setValue('otpAttempt', value)}
                pending={loggingIn}
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <TextInput
                type="email"
                label="E-mail"
                placeholder="you@example.com"
                value={getValue('email')}
                onChange={(value) => setValue('email', value)}
                error={getError('email')}
                required
              />
              <Separator />
              <Column className="relative">
                <Column className="absolute right-0 top-0">
                  <Link href="/reset-password" tabIndex={1}>
                    <Text size="sm" color="current">
                      Forgot password?
                    </Text>
                  </Link>
                </Column>
                <PasswordInput
                  label="Password"
                  placeholder="Please enter your password"
                  value={getValue('password')}
                  onChange={(value) => setValue('password', value)}
                  error={getError('password')}
                  required
                />
              </Column>
              <Separator size="lg" />
              <Button
                type="submit"
                intent="primary"
                className="w-full"
                pending={attemptingLogin}
              >
                Sign In
              </Button>
            </React.Fragment>
          )}
          <Separator size="sm" />
          <Row className="justify-center">
            <Link
              size="xs"
              intent="primary"
              href="https://chatkick.com/privacy"
            >
              <Text size="xs" color="primary-800">
                Privacy Policy
              </Text>
            </Link>
            <Separator />
            <Link size="xs" intent="primary" href="https://chatkick.com/terms">
              <Text size="xs" color="primary-800">
                Terms of Service
              </Text>
            </Link>
          </Row>
        </Form>
      </Column>
      <Row>
        <Text size="sm">Don't have an account?</Text>
        <Column style={{ minWidth: 4 }} />
        <Link href="/register" tabIndex={1}>
          <Text size="sm" color="current" fontWeight="bold">
            Register
          </Text>
        </Link>
      </Row>
    </PublicLayout>
  )
}

export default SignInScreen

function TwoFactorAuthForm({
  value,
  onChange,
  pending,
}: {
  value: any
  onChange: (value: any) => void
  pending: boolean
}) {
  return (
    <>
      <Alert intent="info">
        <Column className="py-xs">
          <Text size="sm">
            We sent a 2FA code to the phone number you provided. If it doesn't
            arrive or if it has been over 5 minutes (expired), refresh this page
            to start over.
          </Text>
        </Column>
      </Alert>
      <Separator />
      <TwoFactorOtpInput value={value} onChange={onChange} />
      <Separator size="lg" />
      <Button
        type="submit"
        intent="primary"
        className="w-full"
        pending={pending}
      >
        Confirm Code
      </Button>
    </>
  )
}
