import Clickable from '@trinsly/common/src/components/atoms/clickable'
import Column from '@trinsly/common/src/components/atoms/column'
import Row from '@trinsly/common/src/components/atoms/row'
import Spinner from '@trinsly/common/src/components/atoms/spinner'
import Text from '@trinsly/common/src/components/atoms/text'
import useGetProspectNotes from 'api-hooks/use-get-prospect-notes'
import React from 'react'
import ProspectNoteType from 'types/prospect-note-type'
import { useFormContext } from '../../form-provider'
import NoteForm from './note-form'
import NotesList from './notes-list'

type NotesSectionProps = {}

function NotesSection({}: NotesSectionProps) {
  const [editingNote, setEditingNote] = React.useState<ProspectNoteType>()
  const [viewState, setViewState] = React.useState<'list' | 'form'>('list')
  const { prospect } = useFormContext()
  const { data: notes, pending: pendingNotes } = useGetProspectNotes({
    prospectId: prospect?.id || '',
  })

  const pending = !prospect?.id || pendingNotes

  return (
    <Column>
      <Row className="px-md items-center justify-between gap-sm">
        <Text size="xs">Notes</Text>
        <Clickable
          onClick={() => {
            setViewState((state) => (state === 'list' ? 'form' : 'list'))
          }}
        >
          <Row className="items-center gap-xs">
            {pending && <Spinner size="xs" />}
            <Text size="xs" color="info-500" className="underline">
              {viewState === 'list' ? '+ New Note' : 'View Notes'} (
              {notes?.length || 0})
            </Text>
          </Row>
        </Clickable>
      </Row>
      {
        {
          list: (
            <Column className="pt-sm px-md">
              <NotesList
                onEdit={(note) => {
                  setEditingNote(note)
                  setViewState('form')
                }}
              />
            </Column>
          ),
          form: (
            <Column className="px-md">
              <NoteForm
                note={editingNote}
                onClear={() => {
                  setEditingNote(undefined)
                }}
              />
            </Column>
          ),
        }[viewState]
      }
    </Column>
  )
}

export default NotesSection
