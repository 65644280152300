import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation, useQueryClient } from 'react-query'
import createProspectService from 'services/create-prospect-service'
import ProspectType from 'types/prospect-type'

type UseCreateProspectProps = {} & MutateApiHookType<ProspectType>

function useCreateProspect({ onSuccess, onError }: UseCreateProspectProps) {
  const queryClient = useQueryClient()
  const { mutate: fetch, isLoading: pending } = useMutation(
    createProspectService,
    {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries('/prospects')
        onSuccess?.(data)
      },
      onError,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useCreateProspect
