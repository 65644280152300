import {
  EXTENSION_IMPERSONATE_USER_ID_KEY,
  EXTENSION_USER_ID_KEY,
  EXTENSION_USER_KEY,
  TRINSLY_ACCESS_TOKEN,
  TRINSLY_EXPIRATION,
  TRINSLY_IMPERSONATED_USER_ID,
} from '@trinsly/common/src/services/api'
import ErrorType from '@trinsly/common/src/types/error-type'
//@ts-ignore
import Cookies from 'js-cookie'

export type DestroyUserSessionServiceProps = {}

export async function destroyUserSessionService() {
  const accessToken = localStorage.getItem(TRINSLY_ACCESS_TOKEN)

  const token = Cookies.get(CookiesEnum.ACCESS_TOKEN)
  const client = Cookies.get(CookiesEnum.CLIENT)
  const uid = Cookies.get(CookiesEnum.UID)

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/auth/sign_out`,
    {
      headers: {
        'access-token': token,
        client,
        uid,
        Authorization: `Bearer ${accessToken}` || '',
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  )

  if (response.status !== 204) {
    const error = await response.json()
    console.error('Trinsly: Failed to logout', error)
    throw {
      type: 'AuthenticationError',
      message:
        'Unable to properly logout - please try again or close the browser.',
      status: response.status,
    } as ErrorType
  }

  clearCredentials()
  clearCookies()

  return
}

/**
 * Wipe local storage - doesn't clear cookies or revoke on server
 * @returns
 */
export function clearCredentials(): void {
  if (!window.localStorage) {
    console.warn('Trinsly: There is no Local Storage compability.')
    return
  }

  const storageKeys = [
    TRINSLY_ACCESS_TOKEN,
    TRINSLY_EXPIRATION,
    TRINSLY_IMPERSONATED_USER_ID,
    EXTENSION_IMPERSONATE_USER_ID_KEY,
    EXTENSION_USER_KEY,
    EXTENSION_USER_ID_KEY,
  ]

  storageKeys.forEach((key) => window.localStorage.removeItem(key))
}

/**
 * Clear cookies - todo: deprecate once jwt complete.
 */
export function clearCookies(): void {
  Cookies.remove(CookiesEnum.ACCESS_TOKEN)
  Cookies.remove(CookiesEnum.CLIENT)
  Cookies.remove(CookiesEnum.EXPIRY)
  Cookies.remove(CookiesEnum.UID)
}

//TODO: deprecate once api fixed
export enum CookiesEnum {
  ACCESS_TOKEN = 'access-token',
  CLIENT = 'client',
  EXPIRY = 'expiry',
  UID = 'uid',
}
