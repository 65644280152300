import { pickBy } from 'lodash'
import queryString from 'query-string'
import { useHistory, useLocation } from 'react-router'
import useForceUpdate from './use-force-update'

function useQueryParams() {
  const history = useHistory()
  const location = useLocation()
  const params = queryString.parse(location.search) as any
  const { rerender } = useForceUpdate()

  function updateParams(newQueryParams: Object) {
    const newQueryString = queryString.stringify(
      {
        ...params,
        ...newQueryParams,
      },
      { skipNull: true, skipEmptyString: true }
    )
    history.replace(
      pickBy({
        pathname: location.pathname,
        search: newQueryString,
      })
    )
    rerender()
  }

  return { params, updateParams }
}

export default useQueryParams
