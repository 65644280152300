import api from '@trinsly/common/src/services/api'
import ProspectType from 'types/prospect-type'

export type GetProspectEducationsServiceProps = {
  prospectId: ProspectType['id']
}

async function getProspectEducationsService({
  prospectId,
}: GetProspectEducationsServiceProps) {
  const response = (await api(`/prospects/${prospectId}/educations`, {
    method: 'GET',
  })) as any

  const educations = (response?.educations || []) as ProspectType['educations']

  return educations
}

export default getProspectEducationsService
