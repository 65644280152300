import Card from '@trinsly/common/src/components/atoms/card'
import Column from '@trinsly/common/src/components/atoms/column'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import Modal from '@trinsly/common/src/components/molecules/modal'
import useCancelThreadEmail from 'api-hooks/use-cancel-thread-email'
import ThreadType from 'types/thread-type'

export type CancelThreadEmailConfirmationModalProps = {
  email: ThreadType['emails'][number] | undefined
  visible: boolean
  onRequestClose: () => void
}

function CancelThreadEmailConfirmationModal({
  email,
  visible,
  onRequestClose,
}: CancelThreadEmailConfirmationModalProps) {
  const { fetch: cancelThreadEmail, pending: pendingCancel } =
    useCancelThreadEmail({
      onSuccess: (data) => {
        onRequestClose()
      },
      onError: () => alert('Failed to Cancel - Please Try Again'),
    })

  return (
    <Modal size="sm" visible={visible} onRequestClose={onRequestClose}>
      <Card className="overflow-hidden">
        <Column className="p-md">
          <Heading size="xl" fontWeight="bold">
            Cancel scheduled email
          </Heading>
          <Separator size="sm" />
          <Text>
            Are you sure you want to cancel the scheduled email for this
            reach-out?
          </Text>
        </Column>
        <Modal.Footer>
          <Button className="min-w-28" onClick={onRequestClose}>
            Close
          </Button>
          <Separator size="sm" />
          <Button
            className="min-w-48"
            intent="danger"
            pending={pendingCancel}
            onClick={() => {
              cancelThreadEmail({ emailId: email?.id || -1 })
            }}
          >
            Cancel Email
          </Button>
        </Modal.Footer>
      </Card>
    </Modal>
  )
}

export default CancelThreadEmailConfirmationModal
