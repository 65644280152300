import React from 'react'
import { Theme } from '../../types/theme-type'

export interface LabelProps {
  children?: React.ReactText | React.ReactNode | React.ReactNodeArray
  htmlFor?: string
  color?: Theme['color']
}

function Label({ htmlFor, children, color = 'gray-500' }: LabelProps) {
  return (
    <label
      htmlFor={htmlFor}
      className={`text-sm text-${color}`}
      style={{ cursor: 'inherit' }}
    >
      {children}
    </label>
  )
}

export default Label
