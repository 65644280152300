import api from '@trinsly/common/src/services/api'
import InviteType from 'types/invite-type'

export type DeleteOrganizationMemberInviteProps = {
  inviteId: number
}

export default async function deleteOrganizationMemberInviteService({
  inviteId,
}: DeleteOrganizationMemberInviteProps) {
  const response = await api(`/invites/${inviteId}/cancel`, {
    method: 'POST',
  })

  return response as any as InviteType
}
