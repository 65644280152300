import { useMergeLink } from '@mergeapi/react-merge-link'
import ErrorType from '@trinsly/common/src/types/error-type'
import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import React from 'react'
import { useMutation, useQueryClient } from 'react-query'
import finishMergeSingleIntegrationService from 'services/integrations/finish-merge-single-integration-service'
import startMergeSingleIntegrationService from 'services/integrations/start-merge-single-integration-service'
import CrmEnum from 'types/crm-enum'

type UseMergeSingleIntegrationProps = {
  integration: CrmEnum
} & MutateApiHookType<any>

function useMergeSingleIntegration({
  integration,
  onSuccess,
  onError,
}: UseMergeSingleIntegrationProps) {
  const [linkToken, setLinkToken] = React.useState('')
  const queryClient = useQueryClient()

  const { open, error } = useMergeLink({
    linkToken,
    onSuccess: (publicTokenID: string) => {
      fetchFinishSingleIntegration({ integration, publicTokenID })
    },
  })

  const { mutate: fetch, isLoading: pendingStartSingleIntegration } =
    useMutation(startMergeSingleIntegrationService, {
      onSuccess: (data) => {
        setLinkToken(data?.linkToken || '')
      },
      onError: (error: any) => {
        console.log(error)
        setLinkToken('')
        onError?.(
          error?.response?.data ||
            ({
              message: 'Error starting integration',
              type: 'STARTING',
            } as ErrorType)
        )
      },
    })

  const {
    mutate: fetchFinishSingleIntegration,
    isLoading: pendingFinishSingleIntegration,
  } = useMutation(finishMergeSingleIntegrationService, {
    onSuccess: async (data) => {
      await queryClient.invalidateQueries('/integrations')
      setLinkToken('')
      onSuccess?.(data)
    },
    onError: (error: any) => {
      console.log(error)
      setLinkToken('')
      onError?.(
        error?.response?.data ||
          ({
            message: 'Error finishing integration',
            type: 'FINISHING',
          } as ErrorType)
      )
    },
  })

  React.useEffect(() => {
    if (error) {
      console.log(error)
      setLinkToken('')
      onError?.({
        message: 'Error connecting with merge',
        type: 'CONNECTING',
      } as ErrorType)
    }
  }, [error])

  React.useEffect(() => {
    if (linkToken) {
      open()
    }
  }, [linkToken])

  return {
    fetch,
    pending: pendingStartSingleIntegration || pendingFinishSingleIntegration,
  }
}

export default useMergeSingleIntegration
