import { ReactComponent as DeleteSvg } from '@trinsly/common/src/assets/icons/delete.svg'
import Checkbox from '@trinsly/common/src/components/atoms/checkbox'
import Column from '@trinsly/common/src/components/atoms/column'
import Row from '@trinsly/common/src/components/atoms/row'
import Spinner from '@trinsly/common/src/components/atoms/spinner'
import Text from '@trinsly/common/src/components/atoms/text'
import IconButton from '@trinsly/common/src/components/molecules/icon-button'
import useLinkProspectsToHotlist from 'api-hooks/use-link-prospects-to-hotlist'
import useUnlinkProspectsToHotlist from 'api-hooks/use-unlink-prospects-to-hotlist'
import React from 'react'
import { toast } from 'react-toastify'
import HotlistType from 'types/hotlist-type'
import ProspectType from 'types/prospect-type'
import DeleteHotlistConfirmationPopover from './delete-hotlist-confirmation-popover'

type HotlistListItemProps = {
  hotlist: HotlistType
  prospectIds: ProspectType['id'][]
}

function HotlistListItem({ hotlist, prospectIds }: HotlistListItemProps) {
  const [checked, setChecked] = React.useState(
    prospectIds?.every?.((prospectId) =>
      hotlist?.prospectIds?.includes?.(prospectId)
    )
  )
  const { fetch: linkProspectToHotlist, pending: pendingLink } =
    useLinkProspectsToHotlist({
      onError: (error) => {
        setChecked((state) => !state)
        toast.error(error?.message || 'Error linking prospect to hotlist')
      },
    })
  const { fetch: unlinkProspectToHotlist, pending: pendingUnlink } =
    useUnlinkProspectsToHotlist({
      onError: (error) => {
        setChecked((state) => !state)
        toast.error(error?.message || 'Error unlinking prospect to hotlist')
      },
    })
  const count =
    hotlist?.prospectsCount > 99
      ? '99+'
      : hotlist?.prospectsCount
      ? String(hotlist?.prospectsCount)
      : undefined

  return (
    <Row className="pl-xs pr-sm items-center min-h-8 gap-1">
      <DeleteHotlistConfirmationPopover hotlist={hotlist}>
        <IconButton
          size="xxs"
          iconSize="xs"
          intent="danger"
          variant="transparent"
        >
          <DeleteSvg />
        </IconButton>
      </DeleteHotlistConfirmationPopover>
      <Row className="flex-1 items-center justify-between gap-xs">
        <Row className="items-center gap-1">
          <Text size="sm" className="font-semibold">
            {hotlist?.name}
          </Text>
          {count && (
            <span className="mt-0.5" style={{ fontSize: 10 }}>
              ({count})
            </span>
          )}
        </Row>
        <Column className="min-w-md items-center">
          {pendingLink || pendingUnlink ? (
            <Spinner size="xs" />
          ) : (
            <Checkbox
              checked={checked}
              onChange={(value) => {
                const newChecked = !checked
                setChecked(newChecked)
                if (newChecked) {
                  linkProspectToHotlist({
                    hotlistId: hotlist?.id,
                    prospectIds,
                  })
                  return
                }

                unlinkProspectToHotlist({
                  hotlistId: hotlist?.id,
                  prospectIds,
                })
              }}
            />
          )}
        </Column>
      </Row>
    </Row>
  )
}

export default HotlistListItem
