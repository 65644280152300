import React from 'react'
import { Config, usePopperTooltip } from 'react-popper-tooltip'
import 'react-popper-tooltip/dist/styles.css'

export type TooltipProps = {
  children: React.ReactElement
  content: React.ReactNode
  placement?: Config['placement']
}

function Tooltip({ children, placement, content }: TooltipProps) {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    delayHide: 150,
    delayShow: 150,
    placement,
  })

  return (
    <React.Fragment>
      {React.cloneElement(children, { ref: setTriggerRef })}
      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className:
              'tooltip-container text-sm text-gray-500 whitespace-nowrap',
          })}
        >
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
          {content}
        </div>
      )}
    </React.Fragment>
  )
}

export default Tooltip
