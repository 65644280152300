import Column from '@trinsly/common/src/components/atoms/column'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import useGetUserSession from 'api-hooks/use-get-user-session'
import useRequestAccountDeletion from 'api-hooks/use-request-account-deletion'
import { useState } from 'react'
import { toast } from 'react-toastify'
import AccountDeletionConfirmationModal from './account-deletion-confirmation-modal'

export type AccountDeletionSectionProps = {}

const AccountDeletionSection = ({}: AccountDeletionSectionProps) => {
  const [hasRequest, setHasRequested] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const { data: userSession, pending: pendingUserSession } = useGetUserSession(
    {}
  )
  const { fetch: requestDeletion, pending: deletionRequestPending } =
    useRequestAccountDeletion({
      onSuccess: () => {
        setHasRequested(true)
        toast('Account deletion request submitted.', { type: 'success' })
      },
    })

  return (
    <>
      <Column className="flex-1 justify-center items-start">
        <Column className="max-w-lg">
          <Heading
            size="sm"
            fontWeight="bold"
            color="gray-500"
            className="uppercase"
          >
            Account Deletion
          </Heading>
          <Text color="gray-500" size="sm">
            If you'd like to delete your account and associated data, you can
            request an account deletion by clicking the button below.
          </Text>
        </Column>
        <Separator />
        <Column style={{ width: '26rem' }}>
          {hasRequest && (
            <Column className="bg-info-100 rounded-md p-md border-l-4 border-info-500">
              <Column>
                <Text>
                  We've received your account deletion request. We will reach
                  out within 1-2 business days.
                </Text>
              </Column>
            </Column>
          )}
          <Separator />
          <Button
            shape="rounded"
            variant="outline"
            intent="danger"
            disabled={
              hasRequest || deletionRequestPending || pendingUserSession
            }
            onClick={() => setShowConfirmationModal(true)}
          >
            {hasRequest
              ? 'Requested account deletion'
              : 'Request account deletion'}
          </Button>
        </Column>
      </Column>
      <AccountDeletionConfirmationModal
        visible={showConfirmationModal}
        onRequestClose={() => {
          setShowConfirmationModal(false)
        }}
        onConfirm={() => {
          requestDeletion({ userId: userSession.id })
          setShowConfirmationModal(false)
        }}
        pending={deletionRequestPending}
      />
    </>
  )
}

export default AccountDeletionSection
