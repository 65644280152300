import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation, useQueryClient } from 'react-query'
import createProspectEmploymentService from 'services/create-prospect-employment-service'
import ProspectType from 'types/prospect-type'

type UseCreateProspectEmploymentProps = {} & MutateApiHookType<
  ProspectType['employments'][number]
>

function useCreateProspectEmployment({
  onMutate,
  onSuccess,
  onError,
}: UseCreateProspectEmploymentProps) {
  const queryClient = useQueryClient()

  const { mutate: fetch, isLoading: pending } = useMutation(
    createProspectEmploymentService,
    {
      onSuccess: async (...args) => {
        await queryClient.invalidateQueries(
          '/prospects/{prospectId}/employments'
        )
        onSuccess?.(...args)
      },
      onError,
      onMutate,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useCreateProspectEmployment
