import PageableType from '@trinsly/common/src/types/pageable-type'
import { useQuery } from 'react-query'
import getTagsService from 'services/get-tags-service'

type UseGetProjectsProps = {} & PageableType

function useGetTags({ ...pageable }: UseGetProjectsProps) {
  const { data, error, status } = useQuery(['/tags', pageable], () =>
    getTagsService(pageable)
  )

  return {
    data: data || [],
    pending: status === 'loading',
    error,
  }
}

export default useGetTags
