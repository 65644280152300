import api from '@trinsly/common/src/services/api'
import ProspectType from 'types/prospect-type'

type DeleteProspectResumeServiceProps = {
  prospectId: ProspectType['id']
}

async function deleteProspectResumeService({
  prospectId,
}: DeleteProspectResumeServiceProps) {
  const response = await api(`/prospects/${prospectId}/resume`, {
    method: 'DELETE',
  })
  return
}

export default deleteProspectResumeService
