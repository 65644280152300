import { ReactComponent as CheckSvg } from '@trinsly/common/src/assets/icons/check.svg'
import { ReactComponent as DoubleCheckSvg } from '@trinsly/common/src/assets/icons/double-check.svg'
import Row from '@trinsly/common/src/components/atoms/row'
import Text from '@trinsly/common/src/components/atoms/text'
import ThreadType from 'types/thread-type'

type StatusRendererProps = {
  email: ThreadType['emails'][number]
}

function StatusRenderer({ email }: StatusRendererProps) {
  const openCount = email?.openCount || 0
  const status = email?.state

  let icon = <CheckSvg className="w-md h-md text-gray-300" />
  if (status === 'responded') {
    icon = <DoubleCheckSvg className="w-md h-md text-success-500" />
  } else if (status === 'delivered' && openCount > 0) {
    icon = <CheckSvg className="w-md h-md text-success-500" />
  }

  return (
    <Row className="items-center gap-sm">
      {icon}
      <Text size="sm">({openCount})</Text>
      <Text size="xs" fontWeight="bold" className="uppercase">
        {email?.state}
      </Text>
    </Row>
  )
}

export default StatusRenderer
