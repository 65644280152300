import { ReactComponent as AddHotlistSvg } from '@trinsly/common/src/assets/icons/add-hotlist.svg'
import Popover from '@trinsly/common/src/components/atoms/popover'
import IconButton from '@trinsly/common/src/components/molecules/icon-button'
import ProspectType from 'types/prospect-type'
import HotlistsSection from '.'

type HotlistButtonProps = {
  prospectIds: ProspectType['id'][]
}

function HotlistButton({ prospectIds }: HotlistButtonProps) {
  return (
    <Popover
      target={
        <IconButton
          size="sm"
          iconSize="lg"
          intent="danger"
          variant="transparent"
        >
          <AddHotlistSvg />
        </IconButton>
      }
    >
      <HotlistsSection prospectIds={prospectIds} />
    </Popover>
  )
}

export default HotlistButton
