import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation, useQueryClient } from 'react-query'
import deleteInboxIntegrationService from 'services/settings/delete-inbox-integration-service'

export type UseDeleteInboxIntegrationProps = {} & MutateApiHookType<void>

function useDeleteInboxIntegration({
  onSuccess,
  onError,
  onMutate,
}: UseDeleteInboxIntegrationProps) {
  const queryClient = useQueryClient()

  const { mutate: fetch, isLoading: pending } = useMutation(
    deleteInboxIntegrationService,
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('/settings')
        onSuccess?.()
      },
      onError,
      onMutate,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useDeleteInboxIntegration
