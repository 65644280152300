import useGetUserSession from 'api-hooks/use-get-user-session'
import { useNotificationsContext } from 'providers/notifications-provider'
import {
  ERROR_TYPE,
  INFO_TYPE,
  WARNING_TYPE,
} from 'providers/notifications-provider/notification-list-item'
import React from 'react'
import { daysLeftInTrial, isTrialActive } from 'validators/paywall'

function SubscriptionNotifications() {
  const { addNotification } = useNotificationsContext()
  const { data: userSession, pending } = useGetUserSession({})

  React.useEffect(() => {
    if (!pending && userSession) {
      const isTrialExpired =
        !isTrialActive(userSession.paywallInfo) &&
        userSession.paywallInfo.status === 'unconverted_free_trial'

      const delinquentAccount = userSession.paywallInfo.status === 'paywalled'

      if (isTrialActive(userSession.paywallInfo)) {
        const daysLeft = daysLeftInTrial(userSession.paywallInfo)

        //TODO - known issue with stripe billing and conversions, want to avoid notification in these states
        if (daysLeft > 21) {
          return
        }

        addNotification({
          key: 'trial-active-info',
          message: `${daysLeft} days left on your free trial. `,
          persist: -1,
          type: INFO_TYPE,
          variant: 'translucent',
        })
      }

      if (isTrialExpired) {
        addNotification({
          key: 'trial-subscription-expired',
          message:
            'Your Talent CRM trial has expired. To continue using the platform please head over to Settings > Subscriptions to select your plan.',
          persist: -1,
          type: WARNING_TYPE,
          variant: 'translucent',
        })
      }

      if (delinquentAccount) {
        addNotification({
          key: 'subscription-expired-or-delinquent',
          message:
            'Your Talent CRM account is frozen. To continue using the platform please head over to Settings > Subscriptions to update your payments.',
          persist: -1,
          type: ERROR_TYPE,
          variant: 'translucent',
        })
      }
    }
  }, [pending, userSession])
  return null
}

export default SubscriptionNotifications
