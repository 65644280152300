//TODO: remove sagas/helpers for these routes/screens:
import unsubscribeNew from 'screens/unsubscribe-screen'

import { Redirect, Route, Switch } from 'react-router'
import ForgotPasswordScreen from 'screens/forgot-password-screen'
import RegistrationScreen from 'screens/registration-screen'
import ResetPasswordScreen from 'screens/reset-password-screen'
import SignInScreen from 'screens/sign-in-screen'

export type PublicRoutesProps = {}

function PublicRoutes({}: PublicRoutesProps) {
  return (
    <Switch>
      <Route component={RegistrationScreen} exact path="/register" />
      <Route component={SignInScreen} exact path="/log-in" />

      <Route component={ForgotPasswordScreen} exact path="/reset-password" />
      <Route
        component={ResetPasswordScreen}
        exact
        path="/reset-password-step2"
      />
      <Route component={unsubscribeNew} path="/unsubscribe" />
      <Redirect to={{ pathname: '/log-in', search: window.location.search }} />
    </Switch>
  )
}

export default PublicRoutes
