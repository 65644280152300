import api from '@trinsly/common/src/services/api'

export type DeleteCalendlyIntegrationServiceProps = {}

export default async function deleteCalendlyIntegrationService({}: DeleteCalendlyIntegrationServiceProps) {
  const response = await api('/calendly', {
    method: 'DELETE',
  })
  return response as any
}
