import { ReactComponent as DeleteSvg } from '@trinsly/common/src/assets/icons/delete.svg'
import Column from '@trinsly/common/src/components/atoms/column'
import Form from '@trinsly/common/src/components/atoms/form'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Spinner from '@trinsly/common/src/components/atoms/spinner'
import Text from '@trinsly/common/src/components/atoms/text'
import IconButton from '@trinsly/common/src/components/molecules/icon-button'
import TextInput from '@trinsly/common/src/components/molecules/text-input'
import useDebounce from '@trinsly/common/src/hooks/use-debounce'
import useForm from '@trinsly/common/src/hooks/use-form'
import validEmail from '@trinsly/common/src/validations/valid-email'
import useUpdateUserSettings from 'api-hooks/use-update-user-settings'
import { toast } from 'react-toastify'

export type BccTextInput = {
  settingsPending: boolean
  userSettings: any
}

const BccSection = ({ settingsPending, userSettings }: BccTextInput) => {
  const { fetch: updateBcc, pending: bccUpdatePending } = useUpdateUserSettings(
    {
      onSuccess: () => {
        toast('BCC updated successfully!', {
          type: 'success',
        })
      },
    }
  )
  const { submit, getValue, getError, setValue } = useForm({
    initialValues: {
      bcc: userSettings?.bcc || '',
    },
    onSubmit: ({ bcc }) => {
      updateBcc({ bcc })
    },
    validations: {
      bcc: [validEmail],
    },
  })

  useDebounce({
    enabled: !settingsPending && getValue('bcc') ? true : false,
    value: getValue('bcc'),
    onDebounce: (debouncedValue) => {
      submit({ bcc: debouncedValue })
    },
    delay: 1000,
  })

  return (
    <Column className="flex-1 justify-center items-start">
      <Column className="max-w-lg">
        <Heading
          size="sm"
          fontWeight="bold"
          color="gray-500"
          className="uppercase"
        >
          BCC
        </Heading>
        <Text color="gray-500" size="sm">
          Sets this e-mail as the BCC address for every email sent from Talent
          CRM.
        </Text>
      </Column>
      <Separator />
      <Row style={{ width: '26rem' }}>
        <Form onSubmit={submit}>
          <Column className="w-96">
            <Row>
              <TextInput
                onChange={(value) => {
                  setValue('bcc', value)
                }}
                value={getValue('bcc')}
                error={getError('bcc')}
                disabled={bccUpdatePending}
                placeholder="tim@apple.com"
              />
              <Separator />
              {bccUpdatePending && (
                <Column className="w-4 flex-1 justify-center items-end">
                  <Spinner />
                </Column>
              )}
              {!!getValue('bcc') && (
                <IconButton
                  onClick={() => submit({ bcc: null })}
                  iconSize="md"
                  shape="square"
                  size="md"
                  variant="outline"
                  intent="danger"
                  pending={bccUpdatePending}
                  children={<DeleteSvg />}
                />
              )}
            </Row>
          </Column>
        </Form>
      </Row>
    </Column>
  )
}

export default BccSection
