import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { Organization } from 'types/settings-type'

import { useMutation, useQueryClient } from 'react-query'

import updateOrganizationDetailsService from 'services/settings/update-organization-details-service'

export type UseUpdateOrganizationDetailsProps =
  {} & MutateApiHookType<Organization>

function useUpdateOrganizationDetails({
  onSuccess,
  onError,
}: UseUpdateOrganizationDetailsProps) {
  const queryClient = useQueryClient()
  const { mutate: fetch, isLoading: pending } = useMutation(
    updateOrganizationDetailsService,
    {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries('/user/me')
        await queryClient.invalidateQueries('/settings')
        onSuccess?.(data)
      },
      onError,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useUpdateOrganizationDetails
