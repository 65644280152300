import { ReactComponent as ChevronRightSvg } from '@trinsly/common/src/assets/icons/chevron-right.svg'
import Clickable from '@trinsly/common/src/components/atoms/clickable'
import Column from '@trinsly/common/src/components/atoms/column'
import Row from '@trinsly/common/src/components/atoms/row'
import Text from '@trinsly/common/src/components/atoms/text'
import Animated from '@trinsly/common/src/components/molecules/animated'
import React from 'react'
import Badge from '../atoms/badge'

type ExpandableSectionProps = {
  title: string
  count?: number
  children: React.ReactNode | React.ReactNodeArray
}

function ExpandableSection({
  title,
  count = 0,
  children,
}: ExpandableSectionProps) {
  const [expanded, setExpanded] = React.useState(true)
  return (
    <Column>
      <Clickable
        className="ring-inset"
        onClick={() => {
          setExpanded(!expanded)
        }}
      >
        <Row className="flex-1 items-center justify-between px-sm py-3">
          <Text
            size="xs"
            fontWeight="bold"
            color="gray-900"
            className="uppercase"
          >
            {title}
          </Text>
          <Row className="items-center gap-1">
            {Boolean(count) && (
              <Badge size="xs" intent="primary">
                {count}
              </Badge>
            )}
            <ChevronRightSvg
              className={`w-md h-md text-gray-900 transition-all ${
                expanded ? 'rotate-90' : ''
              }`}
            />
          </Row>
        </Row>
      </Clickable>
      <Animated>{expanded ? children : null}</Animated>
    </Column>
  )
}

export default ExpandableSection
