import api from '@trinsly/common/src/services/api'
import ThreadStateEnum from '@trinsly/common/src/types/thread-state-enum-type'
import ProspectHistoryType, {
  ResponseViaEnum,
} from 'types/prospect-history-type'

export type UpdateReachOutStatusServiceProps = {
  reachOutId: number
  responseVia?: ResponseViaEnum
  state: ThreadStateEnum
}

async function updateReachOutStatusService({
  reachOutId,
  responseVia,
  state,
}: UpdateReachOutStatusServiceProps) {
  const response = await api(`/email_threads/${reachOutId}/mark_state`, {
    body: { response_via: responseVia, state: state },
    method: 'POST',
  })
  return response as any as ProspectHistoryType
}

export default updateReachOutStatusService
