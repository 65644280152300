import { retrieveCredentials } from '@trinsly/common/src/services/api'
import axios from 'axios'
import CreditUsageResponse from 'types/billing-type'

export type GetFindCreditUsageServiceProps = {
  billingCycle?: CreditUsageResponse['billingCycle']
}

async function getFindCreditUsageService({
  billingCycle,
}: GetFindCreditUsageServiceProps) {
  const { accessToken } = retrieveCredentials()

  const response = await axios.request({
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${process.env.REACT_APP_EMAIL_API}/usage?billingCycle=${billingCycle}`,
  })

  return response?.data as any as CreditUsageResponse
}

export default getFindCreditUsageService
