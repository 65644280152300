import api from '@trinsly/common/src/services/api'
import { pickBy } from 'lodash'
import ProjectType from 'types/project-type'

type CreateProjectServiceProps = {
  project: ProjectType
}

async function createProjectService({ project }: CreateProjectServiceProps) {
  const response = (await api(`/projects`, {
    method: 'POST',
    body: {
      project: pickBy({
        name: project?.name,
        status: project?.status,
        start_date: project?.startDate,
        end_date: project?.endDate,
        tag_list: project?.tagList,
      }),
    },
  })) as ProjectType
  return response
}

export default createProjectService
