import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation, useQueryClient } from 'react-query'
import saveBatchDraftService from 'services/save-batch-draft-service'
import DraftType from 'types/draft-type'

type UseSaveBatchDraftProps = {} & MutateApiHookType<DraftType>

function useSaveBatchDraft({ onSuccess, onError }: UseSaveBatchDraftProps) {
  const queryClient = useQueryClient()
  const { mutate: fetch, isLoading: pending } = useMutation(
    saveBatchDraftService,
    {
      onSuccess: async (data) => {
        queryClient.invalidateQueries('/batches/drafts')
        await queryClient.invalidateQueries('/batches/drafts/{id}')
        onSuccess?.(data)
      },
      onError,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useSaveBatchDraft
