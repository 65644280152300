import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation, useQueryClient } from 'react-query'
import cancelBatchService from 'services/cancel-batch-service'
import BatchType from 'types/batch-type'

type UseCancelBatchProps = {} & MutateApiHookType<BatchType>

function useCancelBatch({ onMutate, onSuccess, onError }: UseCancelBatchProps) {
  const queryClient = useQueryClient()
  const { mutate: fetch, isLoading: pending } = useMutation(
    cancelBatchService,
    {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries('/batches')
        onSuccess?.(data)
      },
      onError,
      onMutate,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useCancelBatch
