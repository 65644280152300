import { ReactComponent as DeleteSvg } from '@trinsly/common/src/assets/icons/delete.svg'
import Column from '@trinsly/common/src/components/atoms/column'
import Form from '@trinsly/common/src/components/atoms/form'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Link from '@trinsly/common/src/components/atoms/link'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Spinner from '@trinsly/common/src/components/atoms/spinner'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import IconButton from '@trinsly/common/src/components/molecules/icon-button'
import TextInput from '@trinsly/common/src/components/molecules/text-input'

import useForm from '@trinsly/common/src/hooks/use-form'
import required from '@trinsly/common/src/validations/required'
import useCreateCalendlyIntegration from 'api-hooks/use-create-calendly-integration'
import useDeleteCalendlyIntegration from 'api-hooks/use-delete-calendly-integration'
import { ReactComponent as CheckIcon } from 'icons/check.svg'
import React from 'react'
import { toast } from 'react-toastify'
import Settings from 'types/settings-type'

export type CalendlyIntegrationSectionProps = {
  settingsPending: boolean
  userSettings: Settings
}

const CalendlyIntegrationSection = ({
  settingsPending,
  userSettings,
}: CalendlyIntegrationSectionProps) => {
  const [calendlyIntegrationStatusFailed, setCalendlyIntegrationStatusFailed] =
    React.useState(false)

  const {
    fetch: createCalendlyIntegration,
    pending: creatingCalendlyIntegration,
  } = useCreateCalendlyIntegration({
    onError: (error: any) => {
      toast('The Calendly access token is invalid', { type: 'error' })
      setCalendlyIntegrationStatusFailed(true)
    },
    onSuccess: (data) => {
      toast('Calendly integration success!', { type: 'success' })
      setCalendlyIntegrationStatusFailed(false)
    },
  })

  const { fetch: deleteCalendly, pending: deletingCalendly } =
    useDeleteCalendlyIntegration({
      onError: () => {
        toast('Faild to delete Calendly integration.', { type: 'error' })
      },
      onMutate: () => {},
      onSuccess: () => {
        setValue('calendlyKey', null)
        toast('Calendly integration deleted successfully.', { type: 'success' })
      },
    })

  const { getValue, setValue, getError, submit } = useForm({
    initialValues: {
      calendlyKey: userSettings?.calendly?.calendlyKey || '',
    },
    onSubmit: ({ calendlyKey }) => {
      createCalendlyIntegration({ calendlyKey: calendlyKey })
    },

    validations: {
      calendlyKey: [required],
    },
  })

  const hasCalendly = userSettings?.calendly?.hasCalendly
  return (
    <Column className="flex-1 justify-center items-start">
      <Column className="max-w-lg">
        <Heading
          size="sm"
          fontWeight="bold"
          color="gray-500"
          className="uppercase"
        >
          Calendly Integration
        </Heading>
        <Text color="gray-500" size="sm">
          Integrate Calendly by using a Personal Access Token (
          <Link href="https://www.loom.com/share/eb21817210014b7fb2e46d3bc94eb5fd">
            learn how here
          </Link>
          ).
        </Text>
        <Separator size="xxs" />
        <Text color="gray-500" size="sm">
          {' '}
          When someone creates an invite using your Calendly invite links, any
          existing outreach gets marked as responded (and follow-ups stop).
        </Text>
        <Separator size="xxs" />
        <Text size="sm" color="gray-500">
          You will need to be on a Professional Calendly plan or higher to
          utilize this integration.
        </Text>
      </Column>
      <Separator />
      {hasCalendly && (
        <Text color="success-600" fontWeight="semibold">
          Connected{' '}
        </Text>
      )}
      <Form onSubmit={submit}>
        <Row style={{ width: '28rem' }}>
          <Row className="items-center justify-between flex-1">
            <Column className="w-96">
              <TextInput
                value={getValue('calendlyKey')}
                onChange={(value) => {
                  setValue('calendlyKey', value)
                }}
                error={getError('calendlyKey')}
                type="text"
                placeholder={`${
                  hasCalendly
                    ? 'Your Calendly Personal Access Token already been set'
                    : 'Enter your Calendly Personal Access Token'
                } `}
                disabled={
                  creatingCalendlyIntegration || settingsPending || hasCalendly
                }
              />
            </Column>
            <Column
              className={`items-center justify-center w-10 h-10 ${
                creatingCalendlyIntegration || settingsPending
                  ? 'bg-gray-100'
                  : hasCalendly && 'bg-primary-100'
              } rounded-full `}
            >
              {creatingCalendlyIntegration || settingsPending ? (
                <Spinner color="gray-500" />
              ) : hasCalendly ? (
                <CheckIcon className="w-md text-success-500" />
              ) : (
                ' '
              )}
            </Column>
            <Separator />

            {!!userSettings && userSettings.calendly?.hasCalendly ? (
              <IconButton
                onClick={deleteCalendly}
                iconSize="md"
                shape="square"
                size="md"
                variant="outline"
                intent="danger"
                pending={creatingCalendlyIntegration || deletingCalendly}
                children={<DeleteSvg />}
              />
            ) : (
              <Button
                pending={creatingCalendlyIntegration}
                shape="rounded"
                intent="success"
                size="sm"
                type="submit"
              >
                Link Calendly
              </Button>
            )}
          </Row>
        </Row>
      </Form>
    </Column>
  )
}

export default CalendlyIntegrationSection
