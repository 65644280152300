import { useQuery } from 'react-query'
import getProspectResumeService, {
  GetProspectResumeServiceProps,
} from 'services/get-prospect-resume-service'

type UseGetProspectResumeProps = {
  resumeUrl: string
} & GetProspectResumeServiceProps

function useGetProspectResume({
  prospectId,
  resumeUrl,
}: UseGetProspectResumeProps) {
  const { data, error, status } = useQuery(
    ['/prospects/{prospectId}/resume', prospectId],
    () => getProspectResumeService({ prospectId }),
    {
      enabled: Boolean(prospectId) && Boolean(resumeUrl),
      cacheTime: 1,
      staleTime: 1,
    }
  )

  return {
    data,
    pending: status === 'loading',
    error,
  }
}

export default useGetProspectResume
