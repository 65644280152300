import api from '@trinsly/common/src/services/api'
import DraftType from 'types/draft-type'

export type GetBatchDraftServiceProps = {
  draftId: DraftType['id']
}

async function getBatchDraftService({ draftId }: GetBatchDraftServiceProps) {
  const response = (await api(`/drafts/${draftId}`, {
    method: 'GET',
  })) as any as DraftType

  const csvPayload = response?.payload?.csv
    ? JSON.parse(response?.payload?.csv)
    : undefined
  return {
    ...response,
    payload: response?.payload
      ? {
          ...response?.payload,
          csv: csvPayload,
        }
      : undefined,
  } as DraftType
}

export default getBatchDraftService
