import Card from '@trinsly/common/src/components/atoms/card'
import Column from '@trinsly/common/src/components/atoms/column'
import Form from '@trinsly/common/src/components/atoms/form'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Button from '@trinsly/common/src/components/molecules/button'
import Modal from '@trinsly/common/src/components/molecules/modal'
import TextInput from '@trinsly/common/src/components/molecules/text-input'
import useForm from '@trinsly/common/src/hooks/use-form'
import required from '@trinsly/common/src/validations/required'
import useCreateProspectEducation from 'api-hooks/use-create-prospect-education'
import React from 'react'
import { toast } from 'react-toastify'
import ProspectType from 'types/prospect-type'
import SelectUniversity from './select-university'

type EducationFormModalProps = {
  prospectId: ProspectType['id'] | undefined
  visible: boolean
  onRequestClose: () => void
}

function EducationFormModal({
  prospectId,
  visible,
  onRequestClose,
}: EducationFormModalProps) {
  const { fetch: createEducation, pending: pendingCreateEducation } =
    useCreateProspectEducation({
      onSuccess: () => {
        toast.success('Education created!')
        onRequestClose?.()
      },
      onError: () => {
        toast.error('Error creating Education')
      },
    })

  const { getValue, setValue, getError, submit, reset } = useForm({
    onSubmit: (education: ProspectType['educations'][number]) => {
      createEducation({ education, prospectId: prospectId! })
    },
    validations: {
      universityAttributes: [required],
    },
  })

  React.useEffect(() => {
    if (visible) {
      reset()
    }
  }, [visible])

  return (
    <Modal visible={visible} onRequestClose={onRequestClose}>
      <Card>
        <Form onSubmit={submit}>
          <Column className="px-md py-xl">
            <Heading size="xl" fontWeight="bold">
              New Education
            </Heading>
            <Separator />
            <SelectUniversity
              creatable={true}
              label="University"
              value={getValue('universityAttributes') || null}
              error={getError('universityAttributes')}
              onCreate={(value) => {
                setValue('universityAttributes', { name: value })
              }}
              onChange={(value) => {
                setValue('universityAttributes', value)
              }}
            />
            <Separator />
            <TextInput
              label="Degree"
              value={getValue('degreeType')}
              onChange={(value) => setValue('degreeType', value)}
              error={getError('degreeType')}
            />
            <Separator />
            <TextInput
              label="Major"
              value={getValue('major')}
              onChange={(value) => setValue('major', value)}
              error={getError('major')}
            />
            <Separator />
            <Row>
              <TextInput
                label="Start Date"
                type="date"
                value={getValue('startDate')}
                onChange={(value) => setValue('startDate', value)}
                error={getError('startDate')}
              />
              <Separator />
              <TextInput
                type="date"
                label="End Date"
                value={getValue('endDate')}
                onChange={(value) => setValue('endDate', value)}
                error={getError('endDate')}
              />
            </Row>
          </Column>
          <Modal.Footer>
            <Button className="min-w-28" onClick={onRequestClose}>
              Cancel
            </Button>
            <Separator size="sm" />
            <Button
              type="submit"
              className="min-w-40"
              intent="success"
              pending={pendingCreateEducation}
            >
              Create
            </Button>
          </Modal.Footer>
        </Form>
      </Card>
    </Modal>
  )
}

export default EducationFormModal
