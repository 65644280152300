import { useVirtualizer } from '@tanstack/react-virtual'
import { ReactComponent as MinusSvg } from '@trinsly/common/src/assets/icons/minus.svg'
import { ReactComponent as PlusSvg } from '@trinsly/common/src/assets/icons/plus.svg'
import Column from '@trinsly/common/src/components/atoms/column'
import Row from '@trinsly/common/src/components/atoms/row'
import IconButton from '@trinsly/common/src/components/molecules/icon-button'
import React from 'react'

type ProspectsCsvTableProps = {
  csv: string[][]
  onChange: (newCsv: string[][]) => void
}

function ProspectsCsvTable({ csv, onChange }: ProspectsCsvTableProps) {
  const columnHeaders = csv?.[0]
  const rows = csv?.filter((data, index) => index > 0)

  // The scrollable element for your list
  const parentRef = React.useRef<HTMLDivElement>(null)

  // The virtualizer
  const rowVirtualizer = useVirtualizer({
    count: rows?.length + 1,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 38,
    overscan: 20,
  })

  return (
    <Column>
      <Column
        ref={parentRef}
        className="overflow-auto border-b border-gray-200"
        style={{ maxHeight: 400 }}
      >
        <Row className="sticky top-0 z-10 flex-1">
          <Column className={headerClasses} style={{ minWidth: 80 }} />
          {columnHeaders?.map((header, i) => {
            return (
              <Column
                key={i}
                contentEditable
                onBlur={(e) => {
                  columnHeaders[i] = e.target.innerText
                  onChange?.([columnHeaders, ...rows])
                }}
                placeholder="Click to Edit"
                suppressContentEditableWarning
                className={`${headerClasses} flex-1`}
                style={{
                  minWidth: 230,
                }}
              >
                {header}
              </Column>
            )
          })}
          <Column
            key="add-another-row"
            className={headerClasses}
            style={{ minWidth: 80 }}
          >
            <IconButton
              size="xs"
              shape="circle"
              variant="ghost"
              intent="success"
              onClick={() => {
                columnHeaders[columnHeaders.length] = ''
                onChange?.([columnHeaders, ...rows])
              }}
            >
              <PlusSvg />
            </IconButton>
          </Column>
        </Row>
        <Column
          style={{
            height: rowVirtualizer.getTotalSize(),
          }}
        >
          <Column
            style={{
              height: rowVirtualizer.getTotalSize(),
              position: 'relative',
            }}
          >
            {rowVirtualizer.getVirtualItems().map((virtualItem) => {
              const isLast = virtualItem.index === rows?.length
              if (isLast) {
                return (
                  <Row
                    key={virtualItem.key}
                    ref={virtualItem.measureElement}
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: virtualItem.size,
                      transform: `translateY(${virtualItem.start}px)`,
                    }}
                  >
                    <Column className="px-sm py-1" style={{ minWidth: 80 }}>
                      <IconButton
                        size="xs"
                        shape="circle"
                        variant="ghost"
                        intent="success"
                        onClick={() => {
                          const newRow = columnHeaders.map(() => '')
                          onChange?.([columnHeaders, ...rows, newRow])
                        }}
                      >
                        <PlusSvg />
                      </IconButton>
                    </Column>
                  </Row>
                )
              }
              return (
                <Row
                  key={virtualItem.key}
                  ref={virtualItem.measureElement}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    minHeight: virtualItem.size,
                    maxHeight: virtualItem.size,
                    transform: `translateY(${virtualItem.start}px)`,
                  }}
                >
                  <Row
                    className="text-sm px-sm items-center gap-xs"
                    style={{ minWidth: 80 }}
                  >
                    <Column className="min-w-6">
                      <IconButton
                        size="xs"
                        shape="circle"
                        variant="ghost"
                        intent="danger"
                        onClick={() => {
                          const newRows = rows.filter(
                            (row, j) => j !== virtualItem.index
                          )
                          onChange?.([columnHeaders, ...newRows])
                        }}
                      >
                        <MinusSvg />
                      </IconButton>
                    </Column>
                    {virtualItem.index + 1}
                  </Row>
                  {columnHeaders?.map((header, j) => (
                    <Column
                      key={`td-${virtualItem.index}-${j}`}
                      className="text-sm px-sm flex-1 py-1 justify-center truncate focus:ring focus:ring-inset focus:ring-primary-200 focus:outline-none"
                      contentEditable
                      placeholder="Click to Edit"
                      suppressContentEditableWarning
                      onBlur={(e) => {
                        rows[virtualItem.index][j] = e.target.innerText
                        onChange?.([columnHeaders, ...rows])
                      }}
                      style={{
                        minWidth: 230,
                      }}
                    >
                      {rows?.[virtualItem.index]?.[j]}
                    </Column>
                  ))}
                  <Column style={{ minWidth: 80 }} />
                </Row>
              )
            })}
          </Column>
        </Column>
      </Column>
    </Column>
  )
}

export default ProspectsCsvTable

const headerClasses = `text-xs px-sm py-1 h-10 uppercase justify-center font-bold text-gray-500 bg-white border-b border-gray-200 focus:ring focus:ring-inset focus:ring-primary-200 focus:outline-none`
