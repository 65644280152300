enum ThreadStateEnum {
  IN_PROGRESS = 'in_progress',
  HARD_BOUNCED = 'hard_bounced',
  RESPONDED = 'responded',
  COMPLETED = 'completed',
  PAUSED = 'paused',
  CANCELED = 'canceled',
  UNDONE = 'undone',
  PAUSED_BY_AUTH_LOSS = 'paused_by_auth_loss',
  PREVENTED = 'prevented',
  PENDING = 'pending',
}

export default ThreadStateEnum
export const translateThreadStateEnum: Record<ThreadStateEnum, string> = {
  [ThreadStateEnum.CANCELED]: 'Canceled',
  [ThreadStateEnum.COMPLETED]: 'Completed',
  [ThreadStateEnum.HARD_BOUNCED]: 'Hard Bounced',
  [ThreadStateEnum.IN_PROGRESS]: 'In Progress',
  [ThreadStateEnum.PAUSED]: 'Paused',
  [ThreadStateEnum.PAUSED_BY_AUTH_LOSS]: 'Paused by Auth loss',
  [ThreadStateEnum.PENDING]: 'Pending',
  [ThreadStateEnum.PREVENTED]: 'Prevented',
  [ThreadStateEnum.RESPONDED]: 'Responded',
  [ThreadStateEnum.UNDONE]: 'Undone',
}

// States from API

// in_progress: 0,
// hard_bounced: 1,
// responded: 2,
// completed: 3,
// paused: 4,
// canceled: 5,
// undone: 6,
// paused_by_auth_loss: 7,
// prevented: 8,
// pending: 9
