import { ReactComponent as ShareSvg } from '@trinsly/common/src/assets/icons/share.svg'
import Column from '@trinsly/common/src/components/atoms/column'
import Row from '@trinsly/common/src/components/atoms/row'
import Button from '@trinsly/common/src/components/molecules/button'
import useShareCampaign from 'api-hooks/use-share-campaign'
import CampaignType from 'types/campaign-type'

type ShareCampaignButtonProps = {
  campaign: CampaignType
  disabled: boolean
}

function ShareCampaignButton({ campaign, disabled }: ShareCampaignButtonProps) {
  const { fetch: shareCampaign, pending } = useShareCampaign({
    onSuccess: (data) => {},
  })

  return (
    <Column className="min-w-28">
      <Button
        testId="share-button"
        size="sm"
        variant="outline"
        intent={campaign?.shared ? 'danger' : undefined}
        disabled={disabled}
        pending={pending}
        onClick={() =>
          shareCampaign({ shared: !campaign?.shared, campaignId: campaign?.id })
        }
      >
        <Row className="items-center gap-xs">
          <ShareSvg className="w-sm h-sm" />
          {campaign?.shared ? 'Unshare' : 'Share'}
        </Row>
      </Button>
    </Column>
  )
}

export default ShareCampaignButton
