import api from '@trinsly/common/src/services/api'
import CampaignType from 'types/campaign-type'
import EmailTemplateType from 'types/email-template-type'

export type GetCampaignServiceProps = {
  campaignId: CampaignType['id']
}

async function getCampaignService({ campaignId }: GetCampaignServiceProps) {
  const response = (await api(`/campaigns/${campaignId}`, {
    method: 'GET',
  })) as any

  const campaign = remoteCampaignToCampaign(response)
  return campaign
}

export default getCampaignService

export function remoteCampaignToCampaign(data: any) {
  const campaign: CampaignType = {
    ...(data || {}),
    templates: data?.templates?.map?.((template: any, index: number) => {
      const firstEmailTemplateSubject =
        data?.templates?.[0]?.templatable?.subject || ''
      const subject = template?.templatable?.subject || ''
      const isInThread = subject === `Re: ${firstEmailTemplateSubject}`
      const emailTemplate: EmailTemplateType = {
        id: template?.templatable?.id,
        position: index + 1,
        duration: String(Number(template?.duration || 0) / 24), //convert hours to days
        attachments: template?.templatable?.attachments || [],
        body: template?.templatable?.body || '',
        subject,
        isInThread,
      }
      return emailTemplate
    }),
  }

  return campaign
}
