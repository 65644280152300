import api from '@trinsly/common/src/services/api'
import ProjectType from 'types/project-type'
import ProspectType from 'types/prospect-type'

type linkProspectToProjectServiceProps = {
  projectId: ProjectType['id']
  prospectIds: ProspectType['id'][]
}

async function linkProspectToProjectService({
  projectId,
  prospectIds,
}: linkProspectToProjectServiceProps) {
  const response = (await api(`/projects/${projectId}/prospects`, {
    method: 'POST',
    body: {
      prospect: {
        id: prospectIds,
      },
    },
  })) as ProspectType
  return response
}

export default linkProspectToProjectService
