import { ReactComponent as EyeOffSvg } from '@trinsly/common/src/assets/icons/eye-off.svg'
import { ReactComponent as EyeOnSvg } from '@trinsly/common/src/assets/icons/eye-on.svg'
import Clickable from '@trinsly/common/src/components/atoms/clickable'
import Column from '@trinsly/common/src/components/atoms/column'
import Input, { InputProps } from '@trinsly/common/src/components/atoms/input'
import Label, { LabelProps } from '@trinsly/common/src/components/atoms/label'
import Separator from '@trinsly/common/src/components/atoms/separator'
import React from 'react'

export type PasswordInputProps = {
  label: LabelProps['children']
} & Omit<InputProps, 'ref'>

function PasswordInput({
  id,
  label,
  required,
  error,
  ...inputProps
}: PasswordInputProps) {
  const [visibility, setVisibility] = React.useState<'obfuscated' | 'visible'>(
    'obfuscated'
  )

  return (
    <Column>
      <Label htmlFor={id} color={error ? 'danger-500' : undefined}>
        {label}
        {required ? ' *' : undefined}
      </Label>
      <Separator size="xs" />
      <Column className="relative">
        <Input
          {...inputProps}
          id={id}
          error={error}
          type={visibility === 'obfuscated' ? 'password' : 'text'}
          className="pr-10"
        />
        <Column className="absolute top-0 right-xs bottom-0 justify-center">
          <Clickable
            className="rounded-sm"
            onClick={() =>
              setVisibility(
                visibility === 'obfuscated' ? 'visible' : 'obfuscated'
              )
            }
          >
            <React.Fragment>
              {visibility === 'obfuscated' && <EyeOffSvg className="w-md" />}
              {visibility === 'visible' && <EyeOnSvg className="w-md" />}
            </React.Fragment>
          </Clickable>
        </Column>
      </Column>
      <Column style={{ height: 0 }}>
        <div style={{ minHeight: 4 }} />
        {error && (
          <span className="text-danger-500" style={{ fontSize: '0.65rem' }}>
            {error}
          </span>
        )}
      </Column>
    </Column>
  )
}

export default PasswordInput
