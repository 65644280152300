import CrmEnum from './crm-enum'

type ProspectType = {
  id: number
  firstName?: string
  lastName?: string
  email: string
  emails: string[]
  linkedinName?: string
  name: string

  employments: Employment[]
  educations: Education[]
  lastContactedAt: string
  resumeUrl?: string
  city: string
  state: string
  zip: string
  role: string
  companyName: string
  creator: {
    email: string
    id: number
    name: string
  }
  owner: {
    email: string
    id: number
    name: string
  }
  status: ProspectStatusEnum
  tagList: string[]
  mergeAts?: CrmEnum
  mergeRemoteId?: string
  applications?: any
  urls?: any
  links: { [index: string]: string; self: string }
}

export default ProspectType

export enum ProspectStatusEnum {
  ACTIVE = 'active',
  DO_NOT_CONTACT = 'do_not_contact',
  HARD_BOUNCED = 'hard_bounced',
  INACTIVE = 'inactive',
}

export const translateProspectStatusEnum: Record<ProspectStatusEnum, string> = {
  [ProspectStatusEnum.ACTIVE]: 'Active',
  [ProspectStatusEnum.DO_NOT_CONTACT]: 'Do not contact',
  [ProspectStatusEnum.HARD_BOUNCED]: 'Hard bounced',
  [ProspectStatusEnum.INACTIVE]: 'Inactive',
}

type Education = {
  id?: Id //TODO: remove all references to Id type - all id's are numbers.
  _destroy?: boolean
  degreeType: string
  major: string
  startDate: string
  endDate: string
  universityId: Id
  universityName: string
  universityAttributes: University
}

type University = {
  id: Id
  name: string
}

type Company = {
  id: Id
  name: string
}

type Employment = {
  id?: Id
  role: string
  startTime: string
  endTime: string
  companyId: Id
  companyName: string
  companyAttributes: Company
}
