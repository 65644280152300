import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation } from 'react-query'
import syncMergeIntegrationService from 'services/integrations/sync-merge-integration-service'

type UseSyncMergeIntegrationProps = {} & MutateApiHookType<any>

function useSyncMergeIntegration({
  onSuccess,
  onError,
}: UseSyncMergeIntegrationProps) {
  const { mutate: fetch, status } = useMutation(syncMergeIntegrationService, {
    onSuccess: async (data) => {
      onSuccess?.(data)
    },
    onError,
  })

  return {
    fetch,
    pending: status === 'loading',
  }
}

export default useSyncMergeIntegration
