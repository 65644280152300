import api from '@trinsly/common/src/services/api'

export type GetCampaignTemplatesServiceProps = {
  campaignId: Campaign['id']
}

async function getCampaignTemplatesService({
  campaignId,
}: GetCampaignTemplatesServiceProps) {
  const response = await api(`/stats/campaigns/${campaignId}/templates`, {
    method: 'GET',
  })
  return response as any as {
    hardBouncedCount: number
    id: Id
    position: number
    respondedCount: number
    sentCount: number
  }[]
}

export default getCampaignTemplatesService
