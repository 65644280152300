import api from '@trinsly/common/src/services/api'
import PageableType from '@trinsly/common/src/types/pageable-type'
import ThreadStateEnum from '@trinsly/common/src/types/thread-state-enum-type'
import { pickBy } from 'lodash'
import CampaignType from 'types/campaign-type'
import ProspectType from 'types/prospect-type'
import ThreadType from 'types/thread-type'

type GetThreadsServiceProps = {
  filters?: {
    state?: ThreadStateEnum
    campaignId?: CampaignType['id']
    prospectId?: ProspectType['id']
    email?: string
    unsent?: boolean
  }
} & PageableType

async function getThreadsService(pageable: GetThreadsServiceProps) {
  const response = await api(`/email_threads`, {
    method: 'GET',
    params: pickBy({
      search: pageable?.search,
      page: pageable?.page,
      per_page: pageable?.perPage,
      filter: pickBy({
        state: pageable?.filters?.state,
        campaign_id: pageable?.filters?.campaignId,
        unsent: pageable?.filters?.unsent,
        prospect_id: pageable?.filters?.prospectId,
        email: pageable?.filters?.email,
      }),
    }),
  })

  return response as any as {
    emailThreads: ThreadType[]
    meta: { pagination: any }
  }
}

export default getThreadsService
