import React from 'react'
import Tag from '../../components/atoms/tag'
import ThreadStateEnum, {
  translateThreadStateEnum,
} from '../../types/thread-state-enum-type'
import Column from '../atoms/column'

interface ThreadStatusTagProps {
  status: ThreadStateEnum
}

function ThreadStatusTag({ status }: ThreadStatusTagProps) {
  const { textColor, backgroundColor } = React.useMemo(() => {
    const { textColor, backgroundColor } = intentStyleMap[status] || {
      textColor: '#334155',
      backgroundColor: '#e2e8f0',
    }

    return { textColor, backgroundColor }
  }, [status])
  return (
    <Column className="w-48">
      <Tag textColor={textColor} backgroundColor={backgroundColor}>
        {status === ThreadStateEnum.COMPLETED
          ? 'Unresponded'
          : translateThreadStateEnum[status] || 'Unknown'}
      </Tag>
    </Column>
  )
}

export default ThreadStatusTag

//@ts-ignore
const intentStyleMap: Record<
  ThreadStateEnum,
  { textColor: string; backgroundColor: string }
> = {
  [ThreadStateEnum.CANCELED]: {
    textColor: '#334155',
    backgroundColor: '#fde047',
  },
  [ThreadStateEnum.COMPLETED]: {
    textColor: '#334155',
    backgroundColor: '#e2e8f0',
  },
  [ThreadStateEnum.HARD_BOUNCED]: {
    textColor: 'white',
    backgroundColor: '#ef4444',
  },
  [ThreadStateEnum.IN_PROGRESS]: {
    textColor: 'white',
    backgroundColor: '#3b82f6',
  },
  [ThreadStateEnum.RESPONDED]: {
    textColor: 'white',
    backgroundColor: '#22c55e',
  },
}
