import { ReactComponent as CloseSvg } from '@trinsly/common/src/assets/icons/close.svg'
import Badge from '@trinsly/common/src/components/atoms/badge'
import Clickable from '@trinsly/common/src/components/atoms/clickable'
import Column from '@trinsly/common/src/components/atoms/column'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import ButtonGroup from '@trinsly/common/src/components/atoms/tabs/button-group'
import Text from '@trinsly/common/src/components/atoms/text'
import SearchInput from '@trinsly/common/src/components/molecules/search-input'
import usePageable from '@trinsly/common/src/hooks/use-pageable'
import { compact } from 'lodash'
import React from 'react'
import { CampaignFilterScopeEnum } from 'services/get-campaigns-service'

function Filters() {
  const { pageable, setFilters, setSearch } = usePageable({})
  const tags = compact(pageable?.filters?.tags?.split(',') || []) as string[]
  return (
    <Row className="items-center flex-1">
      <ButtonGroup
        id="state"
        defaultTab={CampaignFilterScopeEnum.MINE}
        onChange={(value) => {
          setFilters('state', value)
        }}
      >
        <ButtonGroup.Button id={CampaignFilterScopeEnum.MINE}>
          Mine
        </ButtonGroup.Button>
        <ButtonGroup.Button id={CampaignFilterScopeEnum.SHARED}>
          Shared
        </ButtonGroup.Button>
        <ButtonGroup.Button id={CampaignFilterScopeEnum.ARCHIVED}>
          Archived
        </ButtonGroup.Button>
      </ButtonGroup>
      <Separator />
      <Column className="flex-1">
        <Column className="flex-1" style={{ maxWidth: 360 }}>
          <SearchInput
            value={pageable?.search}
            placeholder="Search for Campaigns"
            onChange={(value) => {
              setSearch(value)
            }}
          />
        </Column>
      </Column>
      {tags.length > 0 && (
        <React.Fragment>
          <Separator />
          <Row className="items-center space-x-xs">
            <Text size="xs" fontWeight="bold">
              Filter tags:
            </Text>
            {tags.map((tag, index) => (
              <Clickable
                key={index}
                onClick={() => {
                  const currentTags: string[] =
                    pageable?.filters?.tags?.split(',') || []
                  setFilters(
                    'tags',
                    currentTags
                      .filter((currentTag) => currentTag !== tag)
                      .join(',')
                  )
                }}
              >
                <Badge size="xs" intent="primary">
                  <Row className="items-center">
                    {tag}
                    <Separator size="xs" />
                    <CloseSvg className="w-4 h-4" />
                  </Row>
                </Badge>
              </Clickable>
            ))}
          </Row>
        </React.Fragment>
      )}
    </Row>
  )
}

export default Filters
