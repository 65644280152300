import { useAuth } from 'providers/auth-provider'
import PrivateRoutes from './private-routes'
import PublicRoutes from './public-routes'

function Routes() {
  const { isAuthenticated, user } = useAuth()

  if (isAuthenticated && user) {
    try {
      //@ts-ignore
      if (window.Beacon) {
        //@ts-ignore
        window.Beacon('identify', {
          name: user.name,
          email: user.email,
          company: user.organizationName || '',
          id: user.id,
          orgId: user.organizationId,
        })
      }
    } catch (error) {
      console.error('Failed to load Help Scout')
    }
  }

  return isAuthenticated ? <PrivateRoutes /> : <PublicRoutes />
}

export default Routes
