import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation } from 'react-query'
import updateThrottleSettingsService from 'services/settings/create-throttle-service'
import { Throttle } from 'types/settings-type'
import useGetUserSettings from './use-get-user-settings'

export type UseCreateThrottleSettingsProps = {} & MutateApiHookType<Throttle>

function useCreateThrottleSettings({
  onSuccess,
  onError,
}: UseCreateThrottleSettingsProps) {
  const { refetch } = useGetUserSettings()

  const { mutate: fetch, isLoading: pending } = useMutation(
    updateThrottleSettingsService,
    {
      onSuccess: async (data) => {
        await refetch()
        onSuccess?.(data)
      },
      onError,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useCreateThrottleSettings
