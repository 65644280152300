import api from '@trinsly/common/src/services/api'
import ThreadType from 'types/thread-type'

type RescheduleThreadServiceProps = { threadId: number }

async function rescheduleThreadService({
  threadId,
}: RescheduleThreadServiceProps) {
  const response = await api(`/email_threads/${threadId}/reschedule`, {
    method: 'POST',
  })
  return response as any as ThreadType
}

export default rescheduleThreadService
