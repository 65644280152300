import { useQuery } from 'react-query'
import getProjectService, {
  GetProjectServiceProps,
} from 'services/get-project-service'

export type UseGetProjectProps = {} & GetProjectServiceProps

function useGetProject({ projectId }: UseGetProjectProps) {
  const { data, error, status } = useQuery(['/projects/{id}', projectId], () =>
    getProjectService({ projectId })
  )

  return {
    data,
    pending: status === 'loading',
    error,
  }
}

export default useGetProject
