import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation, useQueryClient } from 'react-query'
import updateSubscriptionSeatService from 'services/billing/update-subscription-seat-service'

export type UseUpdateSubscriptionSeatsProps = {} & MutateApiHookType<any>

function useUpdateSubscriptionSeats({
  onSuccess,
  onError,
}: UseUpdateSubscriptionSeatsProps) {
  const queryClient = useQueryClient()
  const { mutate: fetch, isLoading: pending } = useMutation(
    updateSubscriptionSeatService,
    {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries('/billing/current-subscriptions')
        onSuccess?.(data)
      },
      onError,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useUpdateSubscriptionSeats
