import api from '@trinsly/common/src/services/api'
import ThreadType from 'types/thread-type'

type CancelAllUnsentThreadsServiceProps = {}

async function cancelAllUnsentThreadsService({}: CancelAllUnsentThreadsServiceProps) {
  const response = await api(`/email_threads/cancel`, {
    method: 'POST',
  })
  return response as any as ThreadType[]
}

export default cancelAllUnsentThreadsService
