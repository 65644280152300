import Column from '@trinsly/common/src/components/atoms/column'
import Divider from '@trinsly/common/src/components/atoms/divider'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import TextInput from '@trinsly/common/src/components/molecules/text-input'
import React from 'react'
import SelectCompany from 'screens/prospect-detail-screen/dashboard-section/employments-section/select-company'

type EmploymentsSectionProps = {
  getValue: (path: string) => any
  setValue: (path: string, value: any) => void
  getError: (path: string) => any
}

function EmploymentsSection({
  getValue,
  setValue,
  getError,
}: EmploymentsSectionProps) {
  const employments = getValue('employments') || []
  const isEmploymentsNonIdealState = !employments?.length
  return (
    <Column>
      <Row className="items-center justify-between">
        <Heading size="lg" fontWeight="bold">
          Employments
        </Heading>
        <Column className="min-w-36">
          <Button
            size="sm"
            variant="outline"
            intent="primary"
            onClick={() => {
              setValue(`employments`, [...(employments || []), {}])
            }}
          >
            Add employment
          </Button>
        </Column>
      </Row>
      <Separator size="xs" />
      {isEmploymentsNonIdealState && (
        <Text size="sm" color="gray-400">
          No employment history.
        </Text>
      )}
      <Column className="gap-lg">
        {employments?.map?.((employment: any, index: number) => {
          const relativePath = `employments[${index}]`
          return (
            <React.Fragment key={index}>
              {index > 0 && <Divider />}
              <Column className="gap-md">
                <Row className="items-center gap-sm">
                  <Text size="sm">{`Employment #${index + 1}`}</Text>
                  <Button
                    size="xs"
                    onClick={() => {
                      const newEmployments = employments?.filter?.(
                        (employment: any, i: number) => i !== index
                      )
                      setValue(`employments`, newEmployments)
                    }}
                    intent="danger"
                    variant="outline"
                  >
                    Remove
                  </Button>
                </Row>
                <SelectCompany
                  creatable={true}
                  label="Company"
                  value={getValue(`${relativePath}.company`) || null}
                  onCreate={(value) => {
                    setValue(`${relativePath}.company`, { name: value })
                  }}
                  onChange={(value) => {
                    setValue(`${relativePath}.company`, value)
                  }}
                  error={getError(`${relativePath}.company`)}
                />
                <TextInput
                  label="Role"
                  value={getValue(`${relativePath}.role`)}
                  onChange={(value) => setValue(`${relativePath}.role`, value)}
                  error={getError(`${relativePath}.role`)}
                />
                <Row className="gap-sm">
                  <TextInput
                    label="Start Date"
                    type="date"
                    value={getValue(`${relativePath}.startTime`)}
                    onChange={(value) =>
                      setValue(`${relativePath}.startTime`, value)
                    }
                    error={getError(`${relativePath}.startTime`)}
                  />
                  <TextInput
                    type="date"
                    label="End Date"
                    value={getValue(`${relativePath}.endTime`)}
                    onChange={(value) =>
                      setValue(`${relativePath}.endTime`, value)
                    }
                    error={getError(`${relativePath}.endTime`)}
                  />
                </Row>
              </Column>
            </React.Fragment>
          )
        })}
      </Column>
    </Column>
  )
}

export default EmploymentsSection
