import Column from '@trinsly/common/src/components/atoms/column'
import Row from '@trinsly/common/src/components/atoms/row'
import { useAuth } from 'providers/auth-provider'
import NotificationsRenderer from 'providers/notifications-provider/notifications-renderer'
import React, { useEffect } from 'react'
import TagManager from 'react-gtm-module'

import EmailAuthModal from './email-auth-modal'
import Sidebar from './sidebar'
import SubscriptionNotifications from './subscription-notifications'

export type PrivateLayoutProps = {
  children: React.ReactNode | React.ReactNodeArray
}

function PrivateLayout({ children }: PrivateLayoutProps) {
  const { user } = useAuth()

  useEffect(() => {
    try {
      TagManager.initialize({
        gtmId: 'GTM-MRX7XVJ',
        dataLayer: { userId: user?.id },
      })
    } catch (error) {}
  }, [])

  return (
    <Row className="flex-1 w-screen h-screen overflow-hidden">
      <Sidebar impersonate={null} />
      <Column
        className="flex-1 overflow-auto"
        style={{ backgroundColor: '#E9E9E9' }}
      >
        <EmailAuthModal />
        <NotificationsRenderer />
        <SubscriptionNotifications />
        {children}
      </Column>

      {/* <SurveyPrompt /> */}
    </Row>
  )
}

export default PrivateLayout
