import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation, useQueryClient } from 'react-query'
import updateCampaignService from 'services/update-campaign-service'
import CampaignType from 'types/campaign-type'

export type UseUpdateCampaignProps = {} & MutateApiHookType<CampaignType>

function useUpdateCampaign({ onSuccess, onError }: UseUpdateCampaignProps) {
  const queryClient = useQueryClient()
  const { mutate: fetch, isLoading: pending } = useMutation(
    updateCampaignService,
    {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries('/campaigns')
        onSuccess?.(data)
      },
      onError,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useUpdateCampaign
