import React from 'react'
import ReactSelect, { InputActionMeta } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { Option } from 'react-select/src/filters'
import Column from '../atoms/column'
import { getSelectStyles } from './select'

export type MultiSelectProps<T = any> = {
  id?: string
  placeholder?: string
  values?: T[]
  options?: T[]
  error?: string
  pending?: boolean
  disabled?: boolean
  creatable?: boolean
  clearable?: boolean
  onBlur?: () => void
  onFocus?: () => void
  onChange?: (values: T[] | null) => void
  onCreate?: (value: string) => void
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void
  itemRenderer?: (value: T, selected?: boolean) => React.ReactNode
  formatCreateLabel?: (value: string) => React.ReactNode
  filterWith?: ({
    inputValue,
    option,
  }: {
    inputValue: string
    option: T
  }) => boolean
}

function MultiSelect<T>({
  id,
  values,
  error,
  placeholder = 'Select...',
  options = [],
  pending = false,
  disabled = false,
  creatable = false,
  clearable = true,
  onCreate,
  onChange,
  onInputChange,
  onBlur,
  onFocus,
  filterWith,
  itemRenderer = (value) => value,
}: MultiSelectProps<T>) {
  const portal =
    typeof document !== 'undefined'
      ? document?.getElementById('portal')
      : undefined

  function handleCustomFilter(option: Option, rawInput: string) {
    return filterWith!({ inputValue: rawInput, option: option?.data?.value })
  }

  const selectedValues =
    (values || []).map((value) => ({
      value,
      label: itemRenderer ? itemRenderer(value) : value,
    })) || []

  if (creatable) {
    return (
      <Column
        className={`
          ${baseClasses} 
          ${disabled ? disabledClasses : ''} 
          ${error && !disabled ? errorClasses : ''}
          ${!error && !disabled ? defaultClasses : ''}
        `}
      >
        <CreatableSelect
          id={id ? `${id}-container` : undefined}
          inputId={id ? `${id}-input` : undefined}
          instanceId={id ? `${id}-instance` : undefined}
          blurInputOnSelect={true}
          isMulti={true}
          menuPortalTarget={portal}
          className="w-full text-sm shadow-sm"
          classNamePrefix="select"
          isLoading={pending}
          isDisabled={disabled}
          isClearable={clearable}
          placeholder={placeholder}
          onInputChange={onInputChange}
          onBlur={onBlur}
          onFocus={onFocus}
          styles={getSelectStyles({ error })}
          filterOption={filterWith ? handleCustomFilter : undefined}
          value={selectedValues}
          options={options.map((option) => ({
            value: option,
            label: itemRenderer ? itemRenderer(option) : option,
          }))}
          onChange={(selection: any | any[] = []) => {
            const lastSelection = [...selection].pop()
            const isCreation = lastSelection?.__isNew__
            if (isCreation) {
              onCreate?.(lastSelection?.value || null)
            } else {
              onChange?.(selection?.map((select: any) => select.value) || [])
            }
          }}
        />
      </Column>
    )
  }

  return (
    <Column
      className={`
        ${baseClasses} 
        ${disabled ? disabledClasses : ''} 
        ${error && !disabled ? errorClasses : ''}
        ${!error && !disabled ? defaultClasses : ''}
      `}
    >
      <ReactSelect
        id={id ? `${id}-container` : undefined}
        inputId={id ? `${id}-input` : undefined}
        instanceId={id ? `${id}-instance` : undefined}
        blurInputOnSelect={true}
        isMulti={true}
        menuPortalTarget={portal}
        menuPlacement="auto"
        className="w-full text-sm shadow-sm"
        classNamePrefix="select"
        loadingMessage={() => 'Loading...'}
        noOptionsMessage={() => 'No data found.'}
        isLoading={pending}
        isDisabled={disabled}
        isClearable={clearable}
        placeholder={placeholder}
        onInputChange={onInputChange}
        onBlur={onBlur}
        onFocus={onFocus}
        styles={getSelectStyles({ error })}
        theme={(theme: any) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: '#e6f3fd', //accent-50
            primary50: '#c0e0fa', //accent-100
            primary75: '#6db6f3', //accent-300
            primary: '#2e97ee', //accent-500
          },
        })}
        filterOption={filterWith ? handleCustomFilter : undefined}
        value={selectedValues}
        options={options.map((option) => ({
          value: option,
          label: itemRenderer ? itemRenderer(option) : option,
        }))}
        onChange={(selection: any = []) => {
          const values = selection.map((select: any) => select.value)
          onChange?.(values)
        }}
      />
    </Column>
  )
}

export default MultiSelect

const baseClasses = `fix-select-internal-input text-sm text-gray-900 placeholder-gray-300 rounded-md border focus-within:ring focus-within:ring-accent-200 transition`
const defaultClasses = `bg-gray-50 border-gray-300 focus-within:border-accent-300 `
const errorClasses = `bg-danger-50 bg-opacity-33 border-danger-300 focus-within:border-danger-300 focus-within:ring-danger-200`
const disabledClasses = `border-dashed border-gray-300 bg-white`
