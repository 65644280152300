import SelectInput from '@trinsly/common/src/components/molecules/select-input'

type DateRangeFilterProps = {
  value: RangeEnum | null
  onChange: (value: RangeEnum | null) => void
}

function DateRangeFilter({ onChange, value }: DateRangeFilterProps) {
  return (
    <SelectInput
      label="Last contacted at"
      placeholder="Select a period"
      onChange={(item) => onChange(item ?? null)}
      options={OPTIONS}
      value={value}
      itemRenderer={(item) => (item ? translate[item] : '')}
      valueRenderer={(value) => (value ? `Date Range ${translate[value]}` : '')}
    />
  )
}

export default DateRangeFilter

enum RangeEnum {
  OVER_30_DAYS_AGO = 'OVER_30_DAYS_AGO',
  OVER_60_DAYS_AGO = 'OVER_60_DAYS_AGO',
  OVER_90_DAYS_AGO = 'OVER_90_DAYS_AGO',
  WITHIN_30_DAYS = 'WITHIN_30_DAYS',
}

const translate: Record<RangeEnum, string> = {
  [RangeEnum.WITHIN_30_DAYS]: 'Last 30 days',
  [RangeEnum.OVER_30_DAYS_AGO]: '30+ days ago',
  [RangeEnum.OVER_60_DAYS_AGO]: '60+ days ago',
  [RangeEnum.OVER_90_DAYS_AGO]: '90+ days ago',
}

const OPTIONS = Object.values(RangeEnum) as RangeEnum[]
