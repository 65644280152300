import Column from '@trinsly/common/src/components/atoms/column'
import Table from '@trinsly/common/src/components/atoms/table'
import Text from '@trinsly/common/src/components/atoms/text'
import NonIdealState from '@trinsly/common/src/components/organisms/non-ideal-state'
import useGetCampaignTemplates from 'api-hooks/use-get-campaign-templates'
import CampaignType from 'types/campaign-type'

type CampaignExpandedRowProps = {
  campaign: CampaignType
} & { [index: string]: any }

function CampaignExpandedRow({ campaign }: CampaignExpandedRowProps) {
  const { data: stats, pending: pendingStats } = useGetCampaignTemplates({
    campaignId: campaign?.id,
  })

  const { totalReachOuts, totalResponded } = stats.reduce(
    (acc: any, item: any) => {
      let totalReachOuts = 0
      let totalResponded = 0
      if (item.position === 1) {
        totalReachOuts += item.sentCount - item.hardBouncedCount
      }
      totalResponded += item.respondedCount
      return {
        totalReachOuts: acc.totalReachOuts + totalReachOuts,
        totalResponded: acc.totalResponded + totalResponded,
      }
    },
    {
      totalReachOuts: 0,
      totalResponded: 0,
    }
  )

  const hasData = pendingStats || stats?.length > 0

  return (
    <Column>
      {hasData ? (
        <Table
          data={stats}
          pending={pendingStats}
          columns={[
            {
              width: 100,
              header: <Column className="pl-xl">Email Template</Column>,

              cell: ({ position = 0 }) => (
                <Column className="pl-xl">#{position}</Column>
              ),
            },
            {
              width: 400,
              header: <Column className="pl-xl">Subject</Column>,

              cell: ({ position }) => {
                const template = campaign?.templates?.find?.(
                  (template) => template.position === position
                )
                return (
                  <Column className="pl-lg">
                    <Text size="xs">{template?.subject || '--'}</Text>
                  </Column>
                )
              },
            },
            {
              width: 140,
              header: 'Responded',
              cell: ({ respondedCount = 0 }) => respondedCount,
            },
            {
              width: 140,
              header: 'Sent',
              cell: ({ sentCount = 0, hardBouncedCount = 0 }) =>
                sentCount - hardBouncedCount,
            },
            {
              width: 200,
              header: 'Stage Response Rate',
              cell: ({
                sentCount = 0,
                hardBouncedCount = 0,
                respondedCount = 0,
              }) => {
                const respondableCount = sentCount - hardBouncedCount
                const emailResponseRate = calcResponseRate(
                  respondedCount,
                  respondableCount
                )
                return emailResponseRate === 'N/A'
                  ? 'N/A'
                  : `${emailResponseRate}%`
              },
            },
            {
              header: 'Overall Response Rate',
              cell: ({ sentCount = 0 }) =>
                sentCount === 0
                  ? 'N/A'
                  : `${calcResponseRate(totalResponded, totalReachOuts)}%`,
            },
          ]}
        />
      ) : (
        <Column className="p-md">
          <NonIdealState message="No statistics yet" />
        </Column>
      )}
    </Column>
  )
}

export default CampaignExpandedRow

function calcResponseRate(
  responsesCount: number,
  respondableThreadCount: number
) {
  if (respondableThreadCount <= 0) return 'N/A'
  return ((responsesCount / respondableThreadCount) * 100).toFixed(0)
}
