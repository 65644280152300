import Select, {
  SelectProps,
} from '@trinsly/common/src/components/molecules/select'
import ThreadDeliverTimeEnum, {
  translateThreadDeliverTimeEnum,
} from 'types/thread-deliver-time-enum'

export type SelectDeliverTimeProps = Omit<
  SelectProps<ThreadDeliverTimeEnum>,
  'options' | 'itemRenderer'
>

function SelectDeliverTime(props: SelectDeliverTimeProps) {
  return (
    <Select
      {...props}
      options={Object.values(ThreadDeliverTimeEnum) as ThreadDeliverTimeEnum[]}
      itemRenderer={(item) => translateThreadDeliverTimeEnum[item]}
    />
  )
}

export default SelectDeliverTime
