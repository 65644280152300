/**
 * Utilizes Trinsly Integration Service
 */

import { retrieveCredentials } from '@trinsly/common/src/services/api'
import axios from 'axios'
import { camelizeKeys } from 'humps'

async function updateSubscriptionSeatService({
  subscriptionId,
  quantity,
}: {
  subscriptionId: string
  quantity: number
}) {
  const { accessToken } = retrieveCredentials()
  debugger
  const response = await axios.patch(
    `${process.env.REACT_APP_MERGE_API_URL}/billing/subscription/${subscriptionId}/seats`,
    { change: quantity },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  )

  return camelizeKeys(response?.data) as any
}

export default updateSubscriptionSeatService
