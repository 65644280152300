import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation, useQueryClient } from 'react-query'
import deleteIntegrationService from 'services/create-integration-service'
import IntegrationType from 'types/integration-type'

export type UseCreateIntegrationProps = {} & MutateApiHookType<IntegrationType>

function useCreateIntegration({
  onSuccess,
  onError,
}: UseCreateIntegrationProps) {
  const queryClient = useQueryClient()
  const { mutate: fetch, isLoading: pending } = useMutation(
    deleteIntegrationService,
    {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries('/integrations')
        onSuccess?.(data)
      },
      onError,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useCreateIntegration
