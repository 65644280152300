import api from '@trinsly/common/src/services/api'
import ProjectType from 'types/project-type'

export type GetProjectServiceProps = {
  projectId: ProjectType['id']
}

async function getProjectService({ projectId }: GetProjectServiceProps) {
  const response = await api(`/projects/${projectId}`, { method: 'GET' })
  return response as ProjectType
}

export default getProjectService
