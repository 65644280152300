import Column from '@trinsly/common/src/components/atoms/column'
import Row from '@trinsly/common/src/components/atoms/row'
import ButtonGroup from '@trinsly/common/src/components/atoms/tabs/button-group'
import SearchInput from '@trinsly/common/src/components/molecules/search-input'
import usePageableState from '@trinsly/common/src/hooks/use-pageable-state'
import ThreadStateEnum from '@trinsly/common/src/types/thread-state-enum-type'
import useGetThreads from 'api-hooks/use-get-threads'
import CampaignType from 'types/campaign-type'
import ReachOutsTable from '../../dashboard-screen/reach-outs-section/reach-outs-table'
import StopAllReachOutsButton from './stop-all-reach-outs-button'

type ReachOutsTabProps = {
  campaignId: CampaignType['id']
}

function ReachOutsTab({ campaignId }: ReachOutsTabProps) {
  const { pageable, setSearch, setFilters, nextPage, prevPage } =
    usePageableState({
      page: 1,
      perPage: 10,
      filters: { campaignId },
    })

  const { data: threads, total, pending } = useGetThreads(pageable)
  const hasData = threads?.length > 0 && !pending

  return (
    <Column className="py-md gap-sm">
      <Row className="px-md gap-sm items-center justify-between">
        <Column className="w-full" style={{ maxWidth: 360 }}>
          <SearchInput
            id="search"
            placeholder="Search prospect reach outs..."
            value={pageable?.search}
            onChange={setSearch}
          />
        </Column>
        <Column className="whitespace-nowrap">
          <ButtonGroup
            id="thread-state"
            variant="state"
            defaultTab="all"
            onChange={(tabId) => {
              setFilters('state', tabId === 'all' ? undefined : tabId)
            }}
          >
            <ButtonGroup.Button id="all">All</ButtonGroup.Button>
            <ButtonGroup.Button id={ThreadStateEnum.IN_PROGRESS}>
              In Progress
            </ButtonGroup.Button>
            <ButtonGroup.Button id={ThreadStateEnum.HARD_BOUNCED}>
              Hard Bounced
            </ButtonGroup.Button>
            <ButtonGroup.Button id={ThreadStateEnum.RESPONDED}>
              Responded
            </ButtonGroup.Button>
            <ButtonGroup.Button id={ThreadStateEnum.COMPLETED}>
              Unresponded
            </ButtonGroup.Button>
          </ButtonGroup>
        </Column>
        <Column className="flex-1 items-end">
          <StopAllReachOutsButton
            disabled={!hasData}
            campaignId={campaignId!}
          />
        </Column>
      </Row>
      <ReachOutsTable
        campaignId={campaignId}
        threads={threads}
        pending={pending}
        pagination={{
          page: pageable?.page,
          perPage: pageable?.perPage,
          total,
        }}
        onNextPage={nextPage}
        onPrevPage={prevPage}
      />
    </Column>
  )
}

export default ReachOutsTab
