import api from '@trinsly/common/src/services/api'

import { Membership } from 'types/settings-type'

export type UpdateMembershipServiceProps = {
  memberId: Membership['id']
  userId: Membership['userId']
  role: 'admin' | 'member' | string
}

export default async function updateMembershipService({
  memberId,
  role,
}: UpdateMembershipServiceProps): Promise<Membership> {
  const response = await api(`/memberships/${memberId}`, {
    method: 'PUT',
    body: {
      membership: {
        role: role,
      },
    },
  })

  return response as any as Membership
}
