import Column from '@trinsly/common/src/components/atoms/column'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Spinner from '@trinsly/common/src/components/atoms/spinner'
import Switch from '@trinsly/common/src/components/atoms/switch'
import Text from '@trinsly/common/src/components/atoms/text'
import useUpdateUserSettings from 'api-hooks/use-update-user-settings'
import React from 'react'
import { toast } from 'react-toastify'

export type UnsubscribeSectionProps = {
  userSettings: any
  settingsPending?: boolean
}

const UnsubscribeSection = ({
  userSettings,
  settingsPending = false,
}: UnsubscribeSectionProps) => {
  const [active, setActive] = React.useState(false)

  const { fetch: toggleUnsubscribeLink, pending: unsubscribeLinkPending } =
    useUpdateUserSettings({
      onSuccess: (data) => {
        toast(`Unsubscribe link ${!active ? 'enabled' : 'disabled'}`, {
          type: 'success',
        })
      },
    })

  React.useEffect(() => {
    if (!settingsPending && userSettings) {
      setActive(userSettings.unsubscribeLinkEnabled)
    }
  }, [userSettings, settingsPending])

  return (
    <>
      <Column className="flex-1 justify-center items-start">
        <Column className="max-w-lg">
          <Heading
            size="sm"
            fontWeight="bold"
            color="gray-500"
            className="uppercase"
          >
            Unsubscribe Link
          </Heading>
          <Text color="gray-500" size="sm">
            If active, Talent CRM will include an unsubscribe link at the bottom
            of your emails. If the prospect unsubscribes, your organization will
            no longer be able to reach out to the prospect.
          </Text>
        </Column>
        <Separator />
        <Row className="items-center">
          <Switch
            checked={active}
            onChange={() => {
              toggleUnsubscribeLink({ unsubscribeLinkActive: !active })
            }}
          />
          <Separator size="xs" />
          <Text color={active ? 'success-500' : 'gray-600'} fontWeight="bold">
            {active ? 'Enabled' : 'Disabled'}
          </Text>
          <Separator size="xs" />
          <Column className="flex-1 h-10 justify-center items-center">
            {unsubscribeLinkPending && <Spinner />}
          </Column>
        </Row>
      </Column>
    </>
  )
}

export default UnsubscribeSection
