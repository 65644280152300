import {
  EXTENSION_IMPERSONATE_USER_ID_KEY,
  TRINSLY_ACCESS_TOKEN,
} from '@trinsly/common/src/services/api'
import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation, useQueryClient } from 'react-query'
import { sendTokenToChromeExtension } from 'services/chrome/send-token-to-extension'

type UseDisimpersonateProps = {} & MutateApiHookType<void>

function useDisimpersonate({ onSuccess, onError }: UseDisimpersonateProps) {
  const queryClient = useQueryClient()
  const { mutate: fetch, isLoading: pending } = useMutation(
    async () => {
      localStorage?.removeItem?.(EXTENSION_IMPERSONATE_USER_ID_KEY)
      sendTokenToChromeExtension({
        jwt: localStorage.getItem(TRINSLY_ACCESS_TOKEN) || '',
        impersonateUserId: '',
      })
    },
    {
      onSuccess: async () => {
        queryClient.removeQueries()
        onSuccess?.()
      },
      onError,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useDisimpersonate
