//TODO: get correct status
enum ProjectStatusEnum {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  COMPLETE = 'complete',
  ARCHIVED = 'archived',
}

export default ProjectStatusEnum

export const translateProjectStatusEnum: Record<ProjectStatusEnum, string> = {
  [ProjectStatusEnum.ACTIVE]: 'Active',
  [ProjectStatusEnum.INACTIVE]: 'Inactive',
  [ProjectStatusEnum.COMPLETE]: 'Complete',
  [ProjectStatusEnum.ARCHIVED]: 'Archived',
}
