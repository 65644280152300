import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation, useQueryClient } from 'react-query'
import uploadProspectResumeService from 'services/upload-prospect-resume-service'
import ProspectType from 'types/prospect-type'

type UseUploadProspectResumeProps = {} & MutateApiHookType<ProspectType>
function useUploadProspectResume({
  onMutate,
  onSuccess,
  onError,
}: UseUploadProspectResumeProps) {
  const queryClient = useQueryClient()

  const { mutate: fetch, isLoading: pending } = useMutation(
    uploadProspectResumeService,
    {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries('/prospects/{prospectId}')
        await queryClient.invalidateQueries('/prospects/{prospectId}/resume')
        onSuccess?.(data)
      },
      onError,
      onMutate,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useUploadProspectResume
