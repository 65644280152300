import Column from '@trinsly/common/src/components/atoms/column'
import Separator from '@trinsly/common/src/components/atoms/separator'
import React from 'react'
import InviteNewMembersSection from './invite-new-members-section'
import TeamMembersTable from './team-members-table'
export type TeamMembersSection = {}

const TeamMembersSection = ({}: TeamMembersSection) => {
  return (
    <Column>
      <TeamMembersTable />
      <Separator size='xl'/>
      <InviteNewMembersSection />
    </Column>
  )
}

export default TeamMembersSection
