import Card from '@trinsly/common/src/components/atoms/card'
import Column from '@trinsly/common/src/components/atoms/column'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import Modal from '@trinsly/common/src/components/molecules/modal'
import useDeleteMergeSingleIntegration from 'api-hooks/use-delete-merge-single-integration'
import { toast } from 'react-toastify'
import CrmEnum, { translateCrmEnum } from 'types/crm-enum'

type DeletMergeSingleIntegrationConfirmationModalProps = {
  visible: boolean
  onRequestClose: () => void
  integration: Integration | undefined
}

function DeleteMergeSingleIntegrationConfirmationModal({
  integration,
  visible,
  onRequestClose,
}: DeletMergeSingleIntegrationConfirmationModalProps) {
  const {
    fetch: deleteMergeSingleIntegration,
    pending: pendingDeleteMergeSingleIntegration,
  } = useDeleteMergeSingleIntegration({
    onSuccess: (data) => {
      toast.success('Integration disconnected!')
      onRequestClose?.()
    },
    onError: (error) => {
      const errorMessage = error?.message || 'Error disconnecting integration'
      toast.error(errorMessage)
    },
  })

  const integrationEnum = (integration?.crmType?.replace?.('merge_', '') ||
    '') as CrmEnum
  const integrationName = translateCrmEnum[integrationEnum] || 'Integration'

  return (
    <Modal size="sm" visible={visible} onRequestClose={onRequestClose}>
      <Card className="overflow-hidden">
        <Column className="p-md">
          <Heading size="xl" fontWeight="bold">
            Disconnect Integration?
          </Heading>
          <Separator size="sm" />
          <Text>
            Are you sure you want to disconnect <b>{integrationName}</b>?
          </Text>
        </Column>
        <Modal.Footer>
          <Button className="min-w-28" onClick={onRequestClose}>
            Go Back
          </Button>
          <Separator size="sm" />
          <Button
            className="min-w-48"
            intent="danger"
            pending={pendingDeleteMergeSingleIntegration}
            onClick={() =>
              deleteMergeSingleIntegration({ integrationId: integration!.id })
            }
          >
            Disconnect Integration
          </Button>
        </Modal.Footer>
      </Card>
    </Modal>
  )
}

export default DeleteMergeSingleIntegrationConfirmationModal
