import api from '@trinsly/common/src/services/api'
import Settings from 'types/settings-type'

export type GetUserSettingsServiceProps = {}

//validates and sets user session
export async function getUserSettingsService({}: GetUserSettingsServiceProps) {
  const response = await api('/settings', { method: 'GET' })

  return response as any as Settings
}
