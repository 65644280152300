const SIGNATURE_OPEN_NODE_TAG = '<div id="signature">'
const SIGNATURE_CLOSE_NODE_TAG = '</div>'

export function addSignatureNodeTag(html: string) {
  const includesNodeTag = html?.includes(SIGNATURE_OPEN_NODE_TAG)
  if (includesNodeTag) {
    return html
  }

  return [SIGNATURE_OPEN_NODE_TAG, html || '', SIGNATURE_CLOSE_NODE_TAG].join(
    ''
  )
}

export function removeSignatureNodeTag(html: string) {
  const includesNodeTag = html?.includes(SIGNATURE_OPEN_NODE_TAG)
  if (!includesNodeTag) {
    return html
  }

  if (!html) {
    return ''
  }

  const startIndex = SIGNATURE_OPEN_NODE_TAG.length
  const endIndex = html?.length - SIGNATURE_CLOSE_NODE_TAG.length
  const signature = html?.substring(startIndex, endIndex)
  return signature
}
