import PageableType from '@trinsly/common/src/types/pageable-type'
import { useQuery } from 'react-query'
import getCampaignsService from 'services/get-campaigns-service'

type UseGetCampaignsProps = {} & PageableType

function useGetCampaigns(pageable: UseGetCampaignsProps) {
  const { data, error, status } = useQuery(
    ['/campaigns', pageable],
    () => getCampaignsService(pageable),
    {
      staleTime: 60000,
    }
  )

  const campaigns = data?.campaigns || []

  return {
    data: campaigns,
    total: data?.meta?.pagination?.totalObjects,
    pending: status === 'loading',
    error,
  }
}

export default useGetCampaigns
