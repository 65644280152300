import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation, useQueryClient } from 'react-query'
import archiveProjectService from 'services/archive-project-service'
import ProjectType from 'types/project-type'

type UseArchiveProjectProps = {} & MutateApiHookType<ProjectType>

function useArchiveProject({
  onSuccess,
  onError,
  onMutate,
}: UseArchiveProjectProps) {
  const queryClient = useQueryClient()
  const { mutate: fetch, isLoading: pending } = useMutation(
    archiveProjectService,
    {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries('/projects')
        onSuccess?.(data)
      },
      onError,
      onMutate,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useArchiveProject
