import { ReactComponent as DeleteSvg } from '@trinsly/common/src/assets/icons/delete.svg'
import Column from '@trinsly/common/src/components/atoms/column'
import Form from '@trinsly/common/src/components/atoms/form'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Button from '@trinsly/common/src/components/molecules/button'
import IconButton from '@trinsly/common/src/components/molecules/icon-button'
import TextareaInput from '@trinsly/common/src/components/molecules/textarea-input'
import useForm from '@trinsly/common/src/hooks/use-form'
import required from '@trinsly/common/src/validations/required'
import useCreateProspectNote from 'api-hooks/use-create-prospect-note'
import useDeleteProspectNote from 'api-hooks/use-delete-prospect-note'
import React from 'react'
import { toast } from 'react-toastify'
import ProspectNoteType from 'types/prospect-note-type'
import { useFormContext } from '../../form-provider'

type NoteFormProps = {
  note: ProspectNoteType | undefined
  onClear: () => void
}

function NoteForm({ note, onClear }: NoteFormProps) {
  const { prospect } = useFormContext()

  const { fetch: createNote, pending: pendingCreateNote } =
    useCreateProspectNote({
      onSuccess: () => {
        toast.success('Note created!')
        clear()
        onClear?.()
      },
      onError: () => {
        toast.error('Error creating note')
      },
    })

  const { fetch: deleteNote, pending: pendingDeleteNote } =
    useDeleteProspectNote({
      onSuccess: () => {
        toast.success('Note deleted!')
        clear()
        onClear?.()
      },
      onError: () => {
        toast.error('Error deleting note')
      },
    })

  const { getValue, setValue, getError, setValues, submit, clear } = useForm({
    onSubmit: (note: ProspectNoteType) => {
      createNote({ prospectId: prospect?.id || '', note: note?.comment })
    },
    validations: {
      comment: [required],
    },
  })

  React.useEffect(() => {
    setValues(note || {})
  }, [note?.id])

  const isEdit = Boolean(note?.id)

  return (
    <Column>
      <Form onSubmit={submit}>
        <Separator size="xs" />
        <TextareaInput
          required
          rows={5}
          disabled={isEdit}
          value={getValue('comment')}
          onChange={(value) => setValue('comment', value)}
          error={getError('comment')}
        />
        <Separator size="sm" />
        <Row className="items-center justify-end gap-sm">
          {isEdit && (
            <IconButton
              size="sm"
              variant="transparent"
              intent="danger"
              pending={pendingDeleteNote}
              onClick={() => {
                deleteNote({ noteId: note?.id || 0 })
              }}
            >
              <DeleteSvg />
            </IconButton>
          )}
          <Row className="items-center gap-sm">
            {isEdit && (
              <Button
                size="sm"
                shape="rounded"
                variant="ghost"
                className="min-w-24"
                onClick={() => onClear?.()}
              >
                Cancel
              </Button>
            )}
            {!isEdit && (
              <Column className="min-w-32">
                <Button
                  size="sm"
                  type="submit"
                  shape="rounded"
                  intent="info"
                  pending={pendingCreateNote}
                >
                  Add Note
                </Button>
              </Column>
            )}
          </Row>
        </Row>
      </Form>
    </Column>
  )
}

export default NoteForm
