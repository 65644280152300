import api from '@trinsly/common/src/services/api'
import PageableType from '@trinsly/common/src/types/pageable-type'
import { compact, pickBy } from 'lodash'
import moment from 'moment'
import ProspectType from 'types/prospect-type'

export type GetProspectsServiceProps = {} & PageableType

async function getProspectsService({
  filters,
  search,
  page,
  perPage,
}: GetProspectsServiceProps) {
  const response = await api(`/prospects`, {
    method: 'GET',
    params: pickBy({
      project_id: compact([filters?.projects]?.flat?.())?.join(','),
      hotlist_id: compact([filters?.hotlists]?.flat?.())?.join(','),
      filter: pickBy({
        tags: compact([filters?.tags]?.flat?.())?.join(','),
        status: filters?.status,
        creator: filters?.creator,
        campaign_id: filters?.campaign_id,
        email_thread_state: filters?.email_thread_state,
        email_thread_opened_state:
          filters?.email_thread_opened_state?.toLowerCase?.(),
        last_contacted_at_after: calculateLastContactedAtAfter(
          filters?.last_contacted_at_after as any
        ),
      }),
      search,
      page,
      per_page: perPage,
    }),
  })
  return response as {
    prospects: ProspectType[]
    meta: { pagination: Pagination }
  }
}

export default getProspectsService

function convertMomentToDateRangeFilter(momentObj: moment.Moment) {
  return momentObj.toISOString().substring(0, 10)
}

function calculateLastContactedAtAfter(
  dateRange: ProspectFilterDateRangeEnum | null
) {
  switch (dateRange) {
    case ProspectFilterDateRangeEnum.WITHIN_30_DAYS:
      return convertMomentToDateRangeFilter(moment().subtract(30, 'days'))

    case ProspectFilterDateRangeEnum.OVER_30_DAYS_AGO:
    case ProspectFilterDateRangeEnum.OVER_60_DAYS_AGO:
    case ProspectFilterDateRangeEnum.OVER_90_DAYS_AGO:
    default:
      return null
  }
}

enum ProspectFilterDateRangeEnum {
  OVER_30_DAYS_AGO = 'OVER_30_DAYS_AGO',
  OVER_60_DAYS_AGO = 'OVER_60_DAYS_AGO',
  OVER_90_DAYS_AGO = 'OVER_90_DAYS_AGO',
  WITHIN_30_DAYS = 'WITHIN_30_DAYS',
}
