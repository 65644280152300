import Column from '@trinsly/common/src/components/atoms/column'
import Form from '@trinsly/common/src/components/atoms/form'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import QuillEditor from '@trinsly/common/src/components/molecules/quill-editor'
import useForm from '@trinsly/common/src/hooks/use-form'
import useUpdateEmailSignature from 'api-hooks/use-update-email-signature'
import React from 'react'
import { toast } from 'react-toastify'
import * as SignatureUtils from 'utils/signature-utils'
export type SignatureSectionProps = {
  settingsPending: boolean
  userSettings: any
}

const SignatureSection = ({
  settingsPending,
  userSettings,
}: SignatureSectionProps) => {
  const signatureHtml = React.useMemo(() => {
    let html = userSettings?.signature?.signature || ''
    html = SignatureUtils.removeSignatureNodeTag(html) || html
    return html
  }, [userSettings?.signature?.signature])

  const { fetch: updateEmailSignature, pending: updatePending } =
    useUpdateEmailSignature({
      onMutate: () => {},
      onError: () => {},
      onSuccess: () => {
        toast('Email signature updated successfully!', {
          type: 'success',
        })
      },
    })
  const { getValue, setValue, submit } = useForm({
    initialValues: {
      signature: signatureHtml,
    },
    onSubmit: ({ signature }) => {
      const signatureWithNodeTag = SignatureUtils.addSignatureNodeTag(signature)
      updateEmailSignature({ signature: signatureWithNodeTag })
    },
  })
  const theresNoChange =
    getValue('signature') === userSettings?.signature?.signature

  return (
    <Form onSubmit={submit}>
      <Column className="flex-1 justify-center items-start">
        <Column className="max-w-lg">
          <Heading
            size="sm"
            fontWeight="bold"
            color="gray-500"
            className="uppercase"
          >
            Signature
          </Heading>
          <Text color="gray-500" size="sm">
            When you update your signature, Talent CRM will update it across
            your campaigns.
          </Text>
        </Column>
        <Separator />
        <Column>
          <QuillEditor
            disabled={updatePending || settingsPending}
            value={getValue('signature')}
            onChange={({ html }) => {
              setValue('signature', html)
            }}
            modules={['image-resize', 's3-uploader']}
            style={{
              height: '16rem',
            }}
          />
          <Column className="w-full justify-center">
            <Row className="py-2">
              <Button
                type="submit"
                shape="rounded"
                intent="success"
                disabled={theresNoChange}
                pending={updatePending}
              >
                Update signature
              </Button>
            </Row>
          </Column>
        </Column>
      </Column>
    </Form>
  )
}

export default SignatureSection
