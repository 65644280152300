import api from '@trinsly/common/src/services/api'

export type CreateCalendlyIntegrationServiceProps = {
  calendlyKey: string
}

async function createCalendlyIntegrationService({
  calendlyKey,
}: CreateCalendlyIntegrationServiceProps) {
  const response = await api('/calendly', {
    method: 'POST',
    body: { calendly_key: calendlyKey },
  })
  return response as any
}

export default createCalendlyIntegrationService
