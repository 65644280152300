import Card from '@trinsly/common/src/components/atoms/card'
import Column from '@trinsly/common/src/components/atoms/column'
import Form from '@trinsly/common/src/components/atoms/form'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import Modal from '@trinsly/common/src/components/molecules/modal'
import EmailTemplateInput from '@trinsly/common/src/components/molecules/quill-editor/email-template-input'
import { signatureTemplateVariable } from '@trinsly/common/src/components/molecules/quill-editor/variables'
import useForm from '@trinsly/common/src/hooks/use-form'
import useGetThreadEmail from 'api-hooks/use-get-thread-email'
import useGetUserSignature from 'api-hooks/use-get-user-signature'
import useUpdateThreadEmail from 'api-hooks/use-update-thread-email'
import AttachmentsInput from 'components/attachments-input'
import SendTestEmailButton from 'components/send-test-email-button'
import React from 'react'
import { toast } from 'react-toastify'
import { ThreadEmailStateEnum, ThreadEmailType } from 'types/thread-type'

type ThreadEmailFormModalProps = {
  visible: boolean
  onRequestClose: () => void
  threadEmail: ThreadEmailType | undefined
}

function ThreadEmailFormModal({
  threadEmail: email,
  visible,
  onRequestClose,
}: ThreadEmailFormModalProps) {
  const { data, pending: pendingThreadEmail } = useGetThreadEmail({
    threadEmailId: email?.id,
  })
  const threadEmail = data || email
  const { fetch: updateThreadEmail, pending: pendingUpdateThreadEmail } =
    useUpdateThreadEmail({
      onSuccess: () => {
        toast.success('Scheduled email updated!')
        onRequestClose()
      },
      onError: () => {
        toast.success('Error updating scheduled email')
      },
    })

  const { data: signature, pending: pendingSignature } = useGetUserSignature({})
  const { getValue, setValue, getValues, setValues, getError, submit } =
    useForm({
      onSubmit: (threadEmail: ThreadEmailType) => {
        updateThreadEmail({ threadEmail })
      },
    })

  const IsEmailInPreviousThread = threadEmail?.subject
    ?.toLowerCase?.()
    ?.includes?.('re: ')
  React.useEffect(() => {
    setValues({
      ...(threadEmail || {}),
      isInThread: IsEmailInPreviousThread,
    })
  }, [threadEmail?.id])

  const index = threadEmail?.position || 1
  const isFollowUp = index > 1
  const shouldDisableFormChange = false //TODO: fix
  const hasAttachments = (getValue('attachments') || []).length > 0
  const noAttachmentsAndDisabled = !hasAttachments

  const readOnly =
    !threadEmail?.state ||
    ![
      ThreadEmailStateEnum.SCHEDULED,
      ThreadEmailStateEnum.PAUSED,
      ThreadEmailStateEnum.PAUSED_BY_AUTH_LOSS,
    ].includes(threadEmail?.state)

  const title = readOnly ? 'E-mail template details' : 'Edit e-mail template'

  return (
    <Modal size="lg" visible={visible} onRequestClose={onRequestClose}>
      <Form onSubmit={submit}>
        <Card className="p-md">
          <Heading>{title}</Heading>
          <Column className="gap-md">
            <Row className="items-center justify-between gap-md">
              <Text fontWeight="bold" color="primary-500">
                {`Stage #${index + 1} Template`}
              </Text>
              <SendTestEmailButton
                subject={getValue('subject')}
                body={getValue('body')}
              />
            </Row>
            <Column>
              <EmailTemplateInput
                subject={{
                  id: `subject-${index + 1}-text-input`,
                  label: 'Subject',
                  value: getValue(`subject`),
                  onChange: (value) => {
                    setValue(`subject`, value)
                  },
                  error: getError(`subject`),
                  disabled: getValue(`isInThread`) || shouldDisableFormChange,
                  required: true,
                }}
                isInThread={
                  isFollowUp && IsEmailInPreviousThread
                    ? {
                        id: `is-in-thread-${index + 1}-switch`,
                        disabled: shouldDisableFormChange,
                        checked: getValue(`isInThread`),
                        onChange: (value) => {
                          setValues({
                            ...(getValues() || {}),
                            subject: threadEmail?.subject || '',
                            isInThread: !getValue(`isInThread`),
                          })
                        },
                      }
                    : undefined
                }
                body={{
                  id: `body-${index + 1}-editor`,
                  label: 'Body',
                  disabled: shouldDisableFormChange,
                  value: getValue(`body`),
                  onChange: ({ html, counter }) => {
                    const htmlWithSignature =
                      html?.replaceAll(
                        signatureTemplateVariable,
                        `<br>${
                          signature?.signature ||
                          '-- Define an email Signature at Settings Page --'
                        }`
                      ) || ''
                    setValue(`body`, htmlWithSignature)
                  },
                  error: getError(`body`),
                  required: true,
                }}
              />
            </Column>
            <Column>
              <Text
                size="sm"
                fontWeight="bold"
                className="uppercase"
                color="gray-900"
              >
                Attachments
              </Text>
              {noAttachmentsAndDisabled ? (
                <React.Fragment>
                  <Separator size="xxs" />
                  <Text size="sm" color="gray-400">
                    No attachments.
                  </Text>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Separator size="sm" />
                  <AttachmentsInput
                    disabled={shouldDisableFormChange}
                    value={getValue(`attachments`) || []}
                    onChange={(name, values) => {
                      setValue(`attachments`, values || [])
                    }}
                  />
                </React.Fragment>
              )}
            </Column>
            <Row className="items-center justify-end gap-xs">
              <Column className="min-w-24">
                <Button size="sm" variant="ghost" onClick={onRequestClose}>
                  Close
                </Button>
              </Column>
              <Column className="min-w-28">
                <Button
                  size="sm"
                  intent="success"
                  type="submit"
                  pending={pendingUpdateThreadEmail}
                  disabled={pendingThreadEmail}
                >
                  Save
                </Button>
              </Column>
            </Row>
          </Column>
        </Card>
      </Form>
    </Modal>
  )
}

export default ThreadEmailFormModal
