import React from 'react'
import slugify from '../../utils/slugify'
import Column from '../atoms/column'
import Input, { InputProps } from '../atoms/input'
import Label, { LabelProps } from '../atoms/label'
import Separator from '../atoms/separator'

export type TextInputProps = {
  label?: LabelProps['children']
  error?: string
} & Omit<InputProps, 'ref'>

function _TextInput(
  {
    label,
    id = slugify(label, { suffix: 'text-input' }),
    required,
    error,
    ...inputProps
  }: TextInputProps,
  ref: React.Ref<HTMLInputElement>
) {
  return (
    <Column className="flex-1">
      {label && (
        <React.Fragment>
          <Label htmlFor={id} color={error ? 'danger-500' : undefined}>
            {label}
            {required ? ' *' : undefined}
          </Label>
          <Separator size="xs" />
        </React.Fragment>
      )}
      <Input ref={ref} id={id} error={error} {...inputProps} />
      <Column style={{ height: 0 }}>
        <Column className="min-h-1" />
        {error && (
          <span className="text-danger-500" style={{ fontSize: '0.65rem' }}>
            {error}
          </span>
        )}
      </Column>
    </Column>
  )
}

const TextInput = React.forwardRef(_TextInput)
export default TextInput
