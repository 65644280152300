import Card from '@trinsly/common/src/components/atoms/card'
import Column from '@trinsly/common/src/components/atoms/column'
import Form from '@trinsly/common/src/components/atoms/form'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Button from '@trinsly/common/src/components/molecules/button'
import Modal from '@trinsly/common/src/components/molecules/modal'
import TextInput from '@trinsly/common/src/components/molecules/text-input'
import useForm from '@trinsly/common/src/hooks/use-form'
import required from '@trinsly/common/src/validations/required'

import useCreateProject from 'api-hooks/use-create-project'
import moment from 'moment'
import React from 'react'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import ProjectStatusEnum from 'types/project-status-enum'
import ProjectType from 'types/project-type'
import MultiSelectTags from './multi-select-tags'

type ProjectFormModalProps = {
  visible: boolean
  onRequestClose: () => void
}

function ProjectFormModal({ visible, onRequestClose }: ProjectFormModalProps) {
  const history = useHistory()
  const { fetch: createProject, pending: pendingCreateProject } =
    useCreateProject({
      onSuccess: (data) => {
        toast.success('Project created!')
        history.push(`/projects/${data?.id}`)
        onRequestClose?.()
      },
      onError: (error) => {
        toast.error('Error creating project')
      },
    })

  const { getValue, setValue, getError, getValues, setValues, submit, reset } =
    useForm({
      initialValues: {
        startDate: moment().startOf('month').format('YYYY-MM-DD'),
        status: ProjectStatusEnum.ACTIVE,
      },
      onSubmit: (project: ProjectType) => {
        createProject({ project })
      },
      validations: {
        name: [required],
        startDate: [
          required,
          (val: string) => {
            if (!getValue('endDate')) return false
            return (
              moment(val) > moment(getValue('endDate')) &&
              'End date cannot be before start date.'
            )
          },
        ],
      },
    })

  React.useEffect(() => {
    if (visible) {
      reset()
    }
  }, [visible])

  return (
    <Modal visible={visible} onRequestClose={onRequestClose}>
      <Card>
        <Form onSubmit={submit}>
          <Column className="p-md gap-lg">
            <Heading size="xl" fontWeight="bold">
              New Project
            </Heading>
            <TextInput
              label="Name"
              placeholder="Project name"
              value={getValue('name')}
              onChange={(value) => setValue('name', value)}
              error={getError('name')}
              required
            />
            {/* <SelectInput
              label="Status"
              placeholder="Select a status"
              value={getValue('status') as ProjectStatusEnum}
              onChange={(value) => setValue('status', value)}
              options={Object.values(ProjectStatusEnum) as ProjectStatusEnum[]}
              itemRenderer={(item) => <StatusField status={item} />}
            /> */}
            <Row className="gap-md">
              <TextInput
                type="date"
                required
                label="Start Date"
                placeholder="Project start date"
                value={getValue('startDate')}
                onChange={(value) => {
                  setValue('startDate', value)
                }}
                error={getError('startDate')}
              />
              <TextInput
                type="date"
                label="End Date"
                placeholder="Project end date"
                value={getValue('endDate')}
                onChange={(value) => setValue('endDate', value)}
                error={getError('endDate')}
              />
            </Row>
            <MultiSelectTags
              label="Tags"
              creatable={true}
              placeholder="Select tags for project"
              values={getValue('tagList')}
              onChange={(value) => {
                setValue('tagList', value)
              }}
              onCreate={(value) => {
                const tagList = getValue('tagList') || []
                setValue('tagList', [...tagList, value])
              }}
              error={getError('tagList')}
            />
          </Column>
          <Modal.Footer>
            <Button className="min-w-28" onClick={onRequestClose}>
              Cancel
            </Button>
            <Separator size="sm" />
            <Button
              type="submit"
              className="min-w-52"
              intent="primary"
              pending={pendingCreateProject}
            >
              Create Project
            </Button>
          </Modal.Footer>
        </Form>
      </Card>
    </Modal>
  )
}

export default ProjectFormModal
