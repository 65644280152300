import Column from '@trinsly/common/src/components/atoms/column'
import MultiSelectInput, {
  MultiSelectInputProps,
} from '@trinsly/common/src/components/molecules/multi-select-input'

import usePageableState from '@trinsly/common/src/hooks/use-pageable-state'
import useGetCampaigns from 'api-hooks/use-get-campaigns'

import { CampaignFilterScopeEnum } from 'services/get-campaigns-service'
import CampaignType from 'types/campaign-type'

interface MultiSelectCampaignsProps
  extends Omit<
    MultiSelectInputProps<CampaignType>,
    'itemRender' | 'options' | 'pending'
  > {}

function MultiSelectCampaigns(props: MultiSelectCampaignsProps) {
  const { pageable } = usePageableState({
    page: 1,
    perPage: 999,
  })
  const { data: mineCampaigns, pending: pendingMineCampaigns } =
    useGetCampaigns({
      ...pageable,
      filters: { state: CampaignFilterScopeEnum.MINE },
    })
  const { data: sharedCampaigns, pending: pendingSharedCampaigns } =
    useGetCampaigns({
      ...pageable,
      filters: { state: CampaignFilterScopeEnum.SHARED },
    })

  const campaigns = [...mineCampaigns, ...sharedCampaigns]

  return (
    <MultiSelectInput
      {...props}
      options={campaigns}
      //onInputChange={(inputValue) => setSearch(inputValue)}
      itemRenderer={(item) => (
        <Column>
          <span className="text-sm">{item?.name || '--'}</span>

          <span className="text-xs opacity-70">
            {item?.templates.length} stages
          </span>
        </Column>
      )}
      pending={pendingMineCampaigns || pendingSharedCampaigns}
      filterWith={({
        inputValue,
        option,
      }: {
        inputValue: string
        option: CampaignType
      }) => {
        return option?.name
          ?.toLowerCase?.()
          .includes?.(inputValue?.toLowerCase?.())
      }}
    />
  )
}

export default MultiSelectCampaigns
