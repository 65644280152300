import Column from '@trinsly/common/src/components/atoms/column'
import Divider from '@trinsly/common/src/components/atoms/divider'
import Separator from '@trinsly/common/src/components/atoms/separator'
import useGetUserSettings from 'api-hooks/use-get-user-settings'
import AccountDeletionSection from './account-deletion-section'
import BccSection from './bcc-section'
import CalendlyIntegrationSection from './calendly-integration-section'
import ChangePasswordSection from './change-password-section'
import InboxIntegrationSection from './inbox-integration-section'
import SignatureSection from './signature-section'
import ThrottleSection from './throttle-section'
import TimeZoneSection from './time-zone-section'
import TwoFactorAuthSection from './two-factor-auth-section'
import UnsubscribeSection from './unsubscribe-section'
export type MyAccountTabProps = {}

const MyAccountTab = ({}: MyAccountTabProps) => {
  const { data: userSettings, pending: settingsPending } = useGetUserSettings()
  return (
    <Column className="flex-1 p-xl justify-center items-start">
      <TimeZoneSection
        settingsPending={settingsPending}
        userSettings={userSettings}
      />
      <Separator />
      <Divider />
      <Separator />

      <SignatureSection
        settingsPending={settingsPending}
        userSettings={userSettings}
      />

      <Separator />
      <Divider />
      <Separator />
      <BccSection
        settingsPending={settingsPending}
        userSettings={userSettings}
      />
      <Separator />
      <Divider />
      <Separator />
      <ThrottleSection
        settingsPending={settingsPending}
        userSettings={userSettings}
      />
      <Separator />
      <Divider />
      <Separator />
      <UnsubscribeSection
        settingsPending={settingsPending}
        userSettings={userSettings}
      />

      <Separator />
      <Divider />
      <Separator />

      <CalendlyIntegrationSection
        settingsPending={settingsPending}
        userSettings={userSettings}
      />
      <Separator />
      <Divider />
      <Separator />
      <ChangePasswordSection />
      <Separator />
      <Divider />
      <Separator />
      <TwoFactorAuthSection />
      <Separator />
      <Divider />
      <Separator />
      <InboxIntegrationSection />
      <Separator />
      <Divider />
      <Separator />
      <AccountDeletionSection />
    </Column>
  )
}

export default MyAccountTab
