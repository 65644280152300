import { forwardRef } from 'react'

export type CardProps = {} & React.HTMLAttributes<HTMLDivElement>

function _Card(props: CardProps, ref: React.LegacyRef<HTMLDivElement>) {
  return (
    <div
      ref={ref}
      {...props}
      className={`flex flex-col bg-white rounded-lg shadow-md ${
        props.className || ''
      }`}
    />
  )
}

const Card = forwardRef(_Card)
export default Card
