import { camelizeKeys } from 'humps'
import jwt_decode, { JwtPayload } from 'jwt-decode'
import { get } from 'lodash'
import ErrorType from '../types/error-type'

const qs = require('qs')
const API_URL = process.env.REACT_APP_API_URL || ''
const BASE_API = `${API_URL}/api/v1`

export type ApiOptions = {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'
  params?: Object
  body?: any
}

async function api(path: string, { method, params, body }: ApiOptions) {
  const isExternalUrl = path?.includes('://')
  const requestUrl = isExternalUrl ? path : `${BASE_API}${path}`
  const queryParams = params ? `?${qs.stringify(params)}` : ''
  const isApplicationJson = !(body instanceof FormData)
  const parsedBody = isApplicationJson ? JSON.stringify(body) : body
  const response = await fetch(`${requestUrl}${queryParams}`, {
    headers: {
      ...parseCredentialsHeaders(),
      ...(isApplicationJson ? { 'Content-Type': 'application/json' } : {}),
    },
    body: parsedBody,
    method,
  })

  if (response.status === 401) {
    window?.postMessage?.('trinsly/log-out')
    throw new Error('Unauthorized or session expired, please sign in again')
  }

  let responseData = null

  try {
    responseData = camelizeKeys(
      await response.clone().json(),
      function (key, convert, options) {
        return /^[A-Z0-9_]+/.test(key) ? key : convert(key, options)
      }
    )
  } catch (error) {
    responseData = await response.clone().blob()
  }

  if (!response.ok) {
    throw responseData
  }

  const NO_CONTENT_STATUS_CODE = 204
  if (response.status === NO_CONTENT_STATUS_CODE) {
    return {}
  }

  return responseData
}

export default api

export function retrieveCredentials() {
  const credentials = {
    accessToken: localStorage.getItem('tr__accessToken') as string,
  }
  return credentials
}

export function parseCredentialsHeaders(
  options?: Object
): Record<string, string> {
  const { accessToken } = retrieveCredentials()
  const impersonationId = retrieveImpersonateFromLocalStorage()
  const impersonatedHeader = !!impersonationId
    ? { 'X-Impersonate-User': impersonationId?.toString() }
    : {}

  const baseHeaders = {
    Authorization: `Bearer ${accessToken}`,
    'X-Trinsly-Client': 'web',
  }
  const headers = { ...baseHeaders, ...impersonatedHeader }
  //@ts-ignore
  return headers
}

export function retrieveImpersonateFromLocalStorage(): number | null {
  if (!window.localStorage) {
    console.warn('Trinsly: There is no Local Storage compability.')
    return null
  }

  const impersonateUserId = localStorage.getItem(
    EXTENSION_IMPERSONATE_USER_ID_KEY
  )

  return impersonateUserId != null && impersonateUserId.length > 0
    ? Number(impersonateUserId)
    : null
}

/**
 * Session key values must be identical with the following.
 * {@link core/chrome/public/content/tab-update-handler.js}
 * {@link core/chrome/src/features/auth.js}
 *
 * These must be in local storage for user to be logged in.
 */
export const EXTENSION_IMPERSONATE_USER_ID_KEY = 'session-impersonateUserId'

export const TRINSLY_ACCESS_TOKEN = 'tr__accessToken'
export const TRINSLY_EXPIRATION = 'tr__expiresAt'
export const TRINSLY_IMPERSONATED_USER_ID = 'tr__impersonateUserId'
export const EXTENSION_USER_KEY = 'session-user'
export const EXTENSION_USER_ID_KEY = 'session-userId'
export function storeAccessTokenFromHeader(headers: Headers) {
  const accessToken = headers.get('Authorization')

  if (!accessToken) {
    //todo: redirect logout
    return
  }
  const token = accessToken?.split(' ')[1]
  const expiresAt = jwt_decode<JwtPayload>(token).exp
  localStorage.setItem(TRINSLY_ACCESS_TOKEN, token || '')
  localStorage.setItem(TRINSLY_EXPIRATION, expiresAt?.toString() || '')
  return
}

export function getAPIError(response: any) {
  let message = undefined
  let type = 'unknown_error'
  if (typeof get(response, 'errors') === 'string') {
    message = get(response, 'errors')
    return { message, type }
  }

  if (get(response, 'type') && get(response, 'message')) {
    message = get(response, 'message')
    type = get(response, 'type') || type
    return { message, type }
  }

  message = get(response, 'errors.base')?.join?.(', ')
  return { message, type } as ErrorType
}
