import api from '@trinsly/common/src/services/api'
import ProspectType from 'types/prospect-type'

export type GetProspectResumeServiceProps = {
  prospectId: ProspectType['id']
}

async function getProspectResumeService({
  prospectId,
}: GetProspectResumeServiceProps) {
  const response = (await api(`/prospects/${prospectId}/resume`, {
    method: 'GET',
  })) as any

  return response
}

export default getProspectResumeService
