import Column from '@trinsly/common/src/components/atoms/column'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Link from '@trinsly/common/src/components/atoms/link'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Spinner from '@trinsly/common/src/components/atoms/spinner'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import { useCreateApiKey } from 'api-hooks/use-create-api-key'
import useGetUserSession from 'api-hooks/use-get-user-session'
import CopyInput from 'components/copy-input'
import React from 'react'
import { toast } from 'react-toastify'
export type ApiTabProps = {}

const ApiTab = ({}: ApiTabProps) => {
  const [isAPIAllowed, setIsAPIAllowed] = React.useState(false)

  const { data: userSession, pending: gettingUserSession } = useGetUserSession(
    {}
  )
  const { fetch: createApiKey, pending: creatingNewApiKey } = useCreateApiKey({
    onSuccess: () => {
      toast('API key created successfully', {
        type: 'success',
      })
    },
  })

  React.useEffect(() => {
    if (!gettingUserSession) {
      const allowed =
        userSession.paywallInfo.planType === 'Contract' &&
        userSession.paywallInfo.status !== 'paywalled'
      setIsAPIAllowed(allowed)
    }
  }, [gettingUserSession, userSession, isAPIAllowed])

  const hasApiKey = userSession.apiKey != null

  return (
    <Column className="flex-1 p-xl justify-center items-start">
      <Column className="max-w-lg">
        <Heading size="sm" fontWeight="bold" color="gray-500">
          API
        </Heading>
        <Text color="gray-500" size="sm">
          Talent CRM has its own API now. You can check the API docs at{' '}
          <Link intent="primary" href="https://api-docs.trinsly.com/">
            <Text className="text-sm text-primary-500 hover:text-primary-300 ">
              https://api-docs.trinsly.com
            </Text>
          </Link>{' '}
          {!hasApiKey
            ? '. To start using the API you must be on an Enterprise plan. Please contact support@chatkick.com.'
            : 'To start using it, generate an api key below.'}
        </Text>
      </Column>
      <Separator />
      {gettingUserSession ? (
        <Spinner />
      ) : (
        <Column style={{ width: '26rem' }}>
          <Separator size="xs" />
          {hasApiKey && (
            <>
              <CopyInput
                disabled={userSession.apiKey == null}
                label="Your Personal API Key"
                name="api_key"
                value={userSession.apiKey || ''}
              />
              <Separator size="xs" />
              <Button
                variant="outline"
                shape="rounded"
                intent="primary"
                disabled={!isAPIAllowed || creatingNewApiKey}
                pending={!isAPIAllowed || creatingNewApiKey}
                onClick={() => createApiKey({ userId: userSession.id })}
              >
                {hasApiKey ? 'Create new' : 'Generate'} API Key
              </Button>
            </>
          )}
        </Column>
      )}
    </Column>
  )
}

export default ApiTab
