import PageableType from '@trinsly/common/src/types/pageable-type'
import { useQuery } from 'react-query'
import getProspectsService from 'services/get-prospects-service'

export type UseGetProspectsProps = {
  enabled?: boolean
} & PageableType

function useGetProspects({
  enabled = true,
  ...pageable
}: UseGetProspectsProps) {
  const {
    data,
    error,
    status,
    isRefetching: refetching,
  } = useQuery(['/prospects', pageable], () => getProspectsService(pageable), {
    enabled,
    staleTime: 0,
  })

  const prospects = data?.prospects || []

  return {
    data: prospects,
    total: data?.meta?.pagination?.totalObjects || 0,
    pending: status === 'loading',
    error,
    refetching,
  }
}

export default useGetProspects
