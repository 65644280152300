import Column from '@trinsly/common/src/components/atoms/column'
import PdfViewer from '@trinsly/common/src/components/atoms/pdf-viewer'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Spinner from '@trinsly/common/src/components/atoms/spinner'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import useGetProspectResume from 'api-hooks/use-get-prospect-resume'
import React from 'react'
import { useFormContext } from '../form-provider'
import ResumeModal from './resume-modal'

export type ResumePreviewProps = {}

function ResumePreview() {
  const [showModal, setShowModal] = React.useState(false)
  const { prospect } = useFormContext()
  const { data: resume, pending: pendingResume } = useGetProspectResume({
    prospectId: prospect?.id || '',
    resumeUrl: prospect?.resumeUrl || '',
  })
  const hasResume = Boolean(prospect?.resumeUrl)

  return (
    <Column>
      <Row className="items-center justify-between min-h-md">
        <Text size="xs">Resume</Text>
        {hasResume && (
          <React.Fragment>
            <Separator />
            <Button
              size="xs"
              intent="dark"
              shape="rounded"
              onClick={() => {
                setShowModal(true)
              }}
              className="min-w-24"
            >
              Expand
            </Button>
          </React.Fragment>
        )}
      </Row>
      <Separator size="xs" />
      {pendingResume ? (
        <Spinner size="sm" />
      ) : hasResume ? (
        <PdfViewer
          src={resume?.resumeUrl || ''}
          maxPages={1}
          options={{ width: 238 }}
        />
      ) : (
        <Text size="sm" color="gray-900">
          --
        </Text>
      )}
      <ResumeModal
        src={resume?.resumeUrl || ''}
        visible={showModal}
        onRequestClose={() => setShowModal(false)}
      />
    </Column>
  )
}

export default ResumePreview
