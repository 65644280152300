import React, { DetailedHTMLProps, InputHTMLAttributes } from 'react'

export type CheckboxProps = {
  checked?: boolean
  onChange?: (checked: boolean) => void
} & Omit<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'type' | 'checked' | 'onChange'
>

function _Checkbox(
  { checked = false, onChange, ...props }: CheckboxProps,
  ref: React.Ref<HTMLInputElement>
) {
  return (
    <input
      ref={ref}
      type="checkbox"
      className="h-5 w-5 text-primary-500 border-gray-300 rounded focus:ring focus:ring-primary-200"
      checked={checked}
      onChange={() => onChange?.(!checked)}
      {...props}
    />
  )
}

const Checkbox = React.forwardRef(_Checkbox)
export default Checkbox
