import { ReactComponent as ArrowDownSvg } from '@trinsly/common/src/assets/icons/arrow-down.svg'
import { ReactComponent as ArrowUpSvg } from '@trinsly/common/src/assets/icons/arrow-up.svg'
import Column from '@trinsly/common/src/components/atoms/column'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Text from '@trinsly/common/src/components/atoms/text'
import { round } from 'lodash'
import React from 'react'
import colors from './colors'

type KPIListItemProps = {
  title: string
  color: string
  kpiType: 'percent' | 'integer' | 'fraction'
  valueOrPercent: string
  gainOrLossPercentage: number
  previousTitle: string
  previousValueOrPercent: string
}

function KPIListItem({
  title,
  color,
  kpiType,
  valueOrPercent = `0`,
  gainOrLossPercentage = 0,
  previousTitle,
  previousValueOrPercent = `0`,
}: KPIListItemProps) {
  const isGain = gainOrLossPercentage > 0
  const percentage = isGain ? gainOrLossPercentage : gainOrLossPercentage * -1
  return (
    <Column className="w-40">
      <Text size="xs" fontWeight="bold" className="uppercase" color="gray-900">
        {title}
      </Text>
      <Column className="min-h-xs lg:min-h-sm" />
      <Row className="items-end gap-1">
        <div
          className="font-bold font-sans"
          style={{ fontSize: '1.8rem', lineHeight: '1.8rem', color }}
        >
          {kpiType === 'percent'
            ? `${round(parseFloat(valueOrPercent), 2)}%`
            : valueOrPercent}
        </div>
        {Boolean(gainOrLossPercentage) && (
          <React.Fragment>
            <Row
              className="whitespace-nowrap text-xs font-bold items-center pb-0.5 mb-px"
              style={{
                color: isGain ? colors.GREEN : colors.RED,
              }}
            >
              (
              {isGain ? (
                <ArrowUpSvg className="w-2 h-2 text-current" />
              ) : (
                <ArrowDownSvg className="w-2 h-2 text-current" />
              )}
              {round(percentage, 2)}%)
            </Row>
          </React.Fragment>
        )}
      </Row>
      <Separator size="xxs" />
      <div
        className="uppercase pb-0.5 font-bold"
        style={{ fontSize: 10, color: '#AFAFAF' }}
      >
        {previousTitle}
      </div>
      <Text size="sm" fontWeight="bold" className="uppercase" color="gray-900">
        {kpiType === 'percent'
          ? `${round(parseFloat(previousValueOrPercent), 2)}%`
          : previousValueOrPercent}
      </Text>
    </Column>
  )
}

export default KPIListItem
