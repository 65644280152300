import { ReactComponent as PlusSvg } from '@trinsly/common/src/assets/icons/plus.svg'
import Column from '@trinsly/common/src/components/atoms/column'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import React from 'react'

export type AddFollowUpButtonProps = {
  templateCounter: number
  onClick: () => void
  disabled?: boolean
}

function AddFollowUpButton({
  templateCounter,
  onClick,
  disabled,
}: AddFollowUpButtonProps) {
  return (
    <Row className="px-xl items-center">
      <Button
        id="add-a-follow-up-button"
        variant="outline"
        intent="primary"
        onClick={onClick}
        disabled={disabled}
      >
        <Row className="items-center whitespace-nowrap">
          <PlusSvg className="w-5 h-5" />
          <Separator size="xs" />
          Add a Follow-Up
        </Row>
      </Button>
      {templateCounter <= 1 && (
        <React.Fragment>
          <Separator />
          <Column className="px-sm py-3 bg-primary-50 rounded-lg relative">
            <Column className="absolute left-0 top-0 bottom-0 justify-center">
              <Column
                className="w-0 h-0 text-primary-50"
                style={{
                  marginLeft: -16,
                  borderLeft: '8px solid transparent',
                  borderRight: '8px solid currentColor',
                  borderTop: '8px solid transparent',
                  borderBottom: '8px solid transparent',
                }}
              />
            </Column>
            <Text size="sm" color="primary-500">
              Having 2 follow-ups in a 2-week period yields 90%+ of positive
              responses.
            </Text>
          </Column>
        </React.Fragment>
      )}
    </Row>
  )
}

export default AddFollowUpButton
