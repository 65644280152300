import { ReactComponent as EmailSvg } from '@trinsly/common/src/assets/icons/email.svg'
import { ReactComponent as GithubSimpleSvg } from '@trinsly/common/src/assets/icons/github-simple.svg'
import { ReactComponent as LinkedinSimpleSvg } from '@trinsly/common/src/assets/icons/linkedin-simple.svg'
import { ReactComponent as PhoneSvg } from '@trinsly/common/src/assets/icons/phone.svg'
import { ReactComponent as TrinslySvg } from '@trinsly/common/src/assets/icons/trinsly.svg'
import Row from '@trinsly/common/src/components/atoms/row'
import IconButton from '@trinsly/common/src/components/molecules/icon-button'
import { useFormContext } from '../form-provider'

export type ProfileLinksProps = {}

function ProfileLinks({}: ProfileLinksProps) {
  const { prospect } = useFormContext()
  const email = prospect?.email ? `mailto:${prospect?.email}` : undefined
  const linkedIn = prospect?.linkedinName
    ? `https://www.linkedin.com/in/${prospect?.linkedinName}`
    : undefined

  //TODO: add missing links
  const trinsly = false
  const phone = false
  const github = false

  return (
    <Row className="justify-center gap-xs min-h-6">
      {email && (
        <a tabIndex={-1} href={email}>
          <IconButton size="xs" iconSize="sm" shape="circle" intent="primary">
            <EmailSvg />
          </IconButton>
        </a>
      )}
      {phone && (
        <a tabIndex={-1} href={phone}>
          <IconButton size="xs" iconSize="md" shape="circle" intent="primary">
            <PhoneSvg />
          </IconButton>
        </a>
      )}
      {linkedIn && (
        <a tabIndex={-1} href={linkedIn}>
          <IconButton size="xs" iconSize="xxs" shape="circle" intent="primary">
            <LinkedinSimpleSvg />
          </IconButton>
        </a>
      )}
      {trinsly && (
        <a tabIndex={-1} href={trinsly}>
          <IconButton size="xs" iconSize="xxs" shape="circle" intent="primary">
            <TrinslySvg />
          </IconButton>
        </a>
      )}
      {github && (
        <a tabIndex={-1} href={github}>
          <IconButton size="xs" shape="circle" intent="primary">
            <GithubSimpleSvg />
          </IconButton>
        </a>
      )}
    </Row>
  )
}

export default ProfileLinks
