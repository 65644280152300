import Card from '@trinsly/common/src/components/atoms/card'
import Column from '@trinsly/common/src/components/atoms/column'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import Modal from '@trinsly/common/src/components/molecules/modal'
import useCancelThread from 'api-hooks/use-cancel-thread'
import ThreadType from 'types/thread-type'

export type CancelThreadConfirmationModalProps = {
  visible: boolean
  onRequestClose: () => void
  thread: ThreadType | undefined
}

function CancelThreadConfirmationModal({
  thread,
  visible,
  onRequestClose,
}: CancelThreadConfirmationModalProps) {
  const { fetch: cancelThread, pending: pendingCancel } = useCancelThread({
    onSuccess: (data) => {
      onRequestClose()
    },
    onError: () => alert('Failed to Cancel - Please Try Again'),
  })

  return (
    <Modal size="sm" visible={visible} onRequestClose={onRequestClose}>
      <Card className="overflow-hidden">
        <Column className="p-md">
          <Heading size="xl" fontWeight="bold">
            Cancel reach out?
          </Heading>
          <Separator size="sm" />
          <Text>
            Are you sure you want to cancel all scheduled emails for this
            reach-out?
          </Text>
        </Column>
        <Modal.Footer>
          <Button className="min-w-28" onClick={onRequestClose}>
            Go Back
          </Button>
          <Separator size="sm" />
          <Button
            className="min-w-48"
            intent="danger"
            pending={pendingCancel}
            onClick={() => {
              cancelThread({ threadId: thread?.id || -1 })
            }}
          >
            Cancel Reach Out
          </Button>
        </Modal.Footer>
      </Card>
    </Modal>
  )
}

export default CancelThreadConfirmationModal
