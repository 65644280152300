import validator from 'validator'

const noPersonalEmail = (value: string) => {
  const options = {
    host_blacklist: [
      'gmail.com',
      'outlook.com',
      'yahoo.com',
      'icloud.com',
      'protonmail.com',
      'mail.ru',
      'mailinator.com',
      'zoho.com',
      'yandex.com',
      'yandex.ru',
      'tutanota.com',
    ],
  }

  return (
    //@ts-ignore
    value && !validator.isEmail(value, options) && 'Not a valid work E-mail'
  )
}
export default noPersonalEmail
