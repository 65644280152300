import api from '@trinsly/common/src/services/api'
import DraftType from 'types/draft-type'

type SaveBatchDraftServiceProps = {
  draft: Partial<DraftType>
}

async function saveBatchDraftService({ draft }: SaveBatchDraftServiceProps) {
  const hasId = Boolean(draft?.id)
  const csvAsJson = draft?.payload?.csv
    ? JSON.stringify(draft?.payload?.csv)
    : undefined
  const response = (await api(hasId ? `/drafts/${draft?.id}` : '/drafts', {
    method: hasId ? 'PUT' : 'POST',
    body: {
      draft: {
        resource_type: 'batch',
        payload: draft?.payload
          ? {
              ...draft?.payload,
              csv: csvAsJson,
            }
          : undefined,
      },
    },
  })) as DraftType
  return response
}

export default saveBatchDraftService
