import { useQuery } from 'react-query'
import getThreadService from 'services/get-thread-service'
import ThreadType from 'types/thread-type'

type UseGetThreadProps = {
  threadId?: ThreadType['id']
}

function useGetThread({ threadId }: UseGetThreadProps) {
  const { data, error, status } = useQuery(
    '/threads/{id}',
    () =>
      getThreadService({
        threadId: threadId!,
      }),
    {
      enabled: Boolean(threadId),
    }
  )

  return {
    data,
    pending: status === 'loading',
    error,
  }
}

export default useGetThread
