import bullhornLogoSrc from '@trinsly/common/src/assets/images/bullhorn.png'
import catsLogoSrc from '@trinsly/common/src/assets/images/cats.png'
import chatkickLogoSrc from '@trinsly/common/src/assets/images/chatkick.png'
import chatkickInsightsLogoSrc from '@trinsly/common/src/assets/images/chatkick-interview-insights.png'

import clockworkLogoSrc from '@trinsly/common/src/assets/images/clockwork.webp'
import crelateLogoSrc from '@trinsly/common/src/assets/images/crelate.png'
import greenhouseLogoSrc from '@trinsly/common/src/assets/images/greenhouse.png'
import leverLogoSrc from '@trinsly/common/src/assets/images/lever.png'
import pcrecruiterLogoSrc from '@trinsly/common/src/assets/images/pcrecruiter.png'
import workableLogoSrc from '@trinsly/common/src/assets/images/workable.png'
import Card from '@trinsly/common/src/components/atoms/card'
import Column from '@trinsly/common/src/components/atoms/column'
import DualBackground from '@trinsly/common/src/components/atoms/dual-background'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Link from '@trinsly/common/src/components/atoms/link'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import useGetIntegrations from 'api-hooks/use-get-integrations'
import useGetUserSession from 'api-hooks/use-get-user-session'
import PrivateLayout from 'components/private-layout'
import Tooltip from 'components/tooltip'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import CrmEnum from 'types/crm-enum'
import { canUseIntegration } from 'validators/paywall'
import IntegrationCard from './integration-card'
import IntegrationFormModal from './integration-form-modal'
import MergeIntegrationCard from './merge-integration-card'
import IntegrationType from 'types/integration-type'

export type IntegrationScreenProps = {}

function IntegrationsScreen({}: IntegrationScreenProps) {
  const history = useHistory()
  const [disable, setDisable] = useState(true)
  const { data: integrations, pending: pendingIntegrations } =
    useGetIntegrations()
  const { data: userSession, pending: userPending } = useGetUserSession({})

  const [showIntegrationModal, setShowIntegrationModal] = useState(false)

  const [integratingType, setIntegratingType] = useState<CrmEnum>()
  const [integratingEntity, setIntegratingEntity] = useState<IntegrationType>()

  //TODO: remove flag when multiple integrations become available
  const dontAllowMultipleIntegrations = integrations?.length > 0

  useEffect(() => {
    if (!userPending && !!userSession) {
      if (!!userSession?.organizationName) {
        setDisable(false)
      }
    }
  }, [userSession, userPending])
  return (
    <PrivateLayout>
      <DualBackground>
        <Column className="py-md px-lg">
          <Row>
            <Heading size="xl" fontWeight="bold" color="white">
              Integrations
            </Heading>
            <Separator size="sm" />
            <Tooltip>
              <span className="w-28 h-auto px-xs py-xs">
                <Text size="xs">
                  Click{' '}
                  <Link
                    size="xs"
                    intent="info"
                    href="https://help.trinsly.com/article/10-trinsly-integrations-101"
                  >
                    here
                  </Link>{' '}
                  to learn more about using ATS/CRM integrations with Talent
                  CRM.
                </Text>
              </span>
            </Tooltip>
          </Row>
          <Separator size="lg" />
          {disable && (
            <Card className="p-8 text-center align-center">
              <Text color="black" fontWeight="semibold" className="">
                Please setup your Organization before connecting an Integration
              </Text>
              <Separator />
              <Link href="/settings" className="justify-center">
                <Button
                  size="md"
                  intent="warning"
                  shape="rounded"
                  variant="outline"
                >
                  Head to Settings Now
                </Button>
              </Link>
            </Card>
          )}
          <Column
            className={`grid grid-cols-2 gap-md ${disable ? 'blur-sm' : ''}`}
          >
            <IntegrationCard
              disabled={disable}
              loggedUser={userSession}
              integrationType={CrmEnum.CHATKICK}
              logoSrc={chatkickInsightsLogoSrc}
              description="Chatkick's Interview Insights allows teams to scale their interviewing process and make hiring decisions up to 3x faster."
            />
            <MergeIntegrationCard
              disabled={dontAllowMultipleIntegrations || disable}
              integrationType={CrmEnum.GREENHOUSE}
              integration={integrations.find((integration) =>
                integration.crmType?.includes(CrmEnum.GREENHOUSE)
              )}
              logoSrc={greenhouseLogoSrc}
              description="Greenhouse's applicant tracking system and recruiting software
              is designed to help make companies great at hiring and hire for
              what's next."
              pending={pendingIntegrations}
              paywalled={
                userPending ||
                !canUseIntegration(userSession.paywallInfo, CrmEnum.GREENHOUSE)
              }
            />
            <MergeIntegrationCard
              disabled={dontAllowMultipleIntegrations || disable}
              integrationType={CrmEnum.LEVER}
              integration={integrations.find((integration) =>
                integration.crmType?.includes(CrmEnum.LEVER)
              )}
              logoSrc={leverLogoSrc}
              description="Lever is the only platform that provides talent acquisition leaders 
                from all sized companies with a complete applicant tracking system (ATS) and 
                robust candidate relationship management (CRM) platform in a single solution."
              pending={pendingIntegrations}
              paywalled={
                userPending ||
                !canUseIntegration(userSession.paywallInfo, CrmEnum.LEVER)
              }
            />
            {/* Below all Integrations have some paywall restrictions based on user info */}
            <MergeIntegrationCard
              disabled={dontAllowMultipleIntegrations || disable}
              integrationType={CrmEnum.CATS}
              integration={integrations.find((integration) =>
                integration.crmType?.includes(CrmEnum.CATS)
              )}
              logoSrc={catsLogoSrc}
              description="CATS helps thousands of companies from all over the world fill their hiring needs. From one person recruitment shops to the Fortune 500 operations, our customers rely on us to help them grow."
              pending={pendingIntegrations}
              paywalled={
                userPending ||
                !canUseIntegration(userSession.paywallInfo, CrmEnum.CATS)
              }
            />
            <IntegrationCard
              disabled={disable}
              loggedUser={userSession}
              integrationType={CrmEnum.CRELATE}
              integration={integrations.find(
                (integration) => integration.crmType === CrmEnum.CRELATE
              )}
              logoSrc={crelateLogoSrc}
              description="Crelate helps you make more placements with an all-in-one modern staffing & recruiting agency platform."
              onStartIntegration={() => {
                setIntegratingEntity(
                  integrations.find(
                    (integration) => integration.crmType === CrmEnum.CRELATE
                  )
                )
                setIntegratingType(CrmEnum.CRELATE)
                setShowIntegrationModal(true)
              }}
              paywalled={
                userPending ||
                !canUseIntegration(userSession.paywallInfo, CrmEnum.CRELATE)
              }
            />
            <IntegrationCard
              disabled={disable}
              loggedUser={userSession}
              integrationType={CrmEnum.BULLHORN}
              integration={integrations.find(
                (integration) => integration.crmType === CrmEnum.BULLHORN
              )}
              logoSrc={bullhornLogoSrc}
              description="Bullhorn provides cloud-based Applicant Tracking CRM solutions for relationship-driven businesses."
              paywalled={
                userPending ||
                !canUseIntegration(userSession.paywallInfo, CrmEnum.BULLHORN)
              }
            />
            <MergeIntegrationCard
              disabled={dontAllowMultipleIntegrations || disable}
              integrationType={CrmEnum.WORKABLE}
              integration={integrations.find((integration) =>
                integration.crmType?.includes(CrmEnum.WORKABLE)
              )}
              logoSrc={workableLogoSrc}
              description="Workable is all-in-one recruiting software: a single system for teams to find, track and evaluate candidates."
              pending={pendingIntegrations}
              paywalled={
                userPending ||
                !canUseIntegration(userSession.paywallInfo, CrmEnum.WORKABLE)
              }
            />
            <IntegrationCard
              disabled={disable}
              loggedUser={userSession}
              integrationType={CrmEnum.PC_RECRUITER}
              integration={integrations.find(
                (integration) => integration.crmType === CrmEnum.PC_RECRUITER
              )}
              logoSrc={pcrecruiterLogoSrc}
              description="PCRecruiter is the only major recruitment / applicant tracking SaaS platform used by both leading corporations and leading third-party recruiters."
              paywalled={
                userPending ||
                !canUseIntegration(
                  userSession.paywallInfo,
                  CrmEnum.PC_RECRUITER
                )
              }
            />
            <MergeIntegrationCard
              disabled={dontAllowMultipleIntegrations || disable}
              integrationType={CrmEnum.CLOCKWORK}
              integration={integrations.find((integration) =>
                integration.crmType?.includes(CrmEnum.CLOCKWORK)
              )}
              logoSrc={clockworkLogoSrc}
              description="Clockwork provides innovative and highly flexible software and services for retained search firms, corporate executive recruiting teams, and institutional investors to accelerate executive talent acquisition."
              pending={pendingIntegrations}
              paywalled={
                userPending
                // !canUseIntegration(userSession.paywallInfo, CrmEnum.WORKABLE)
              }
            />
            <Card
              className="p-md relative gap-md"
              style={{ border: `1px solid  transparent'}` }}
            >
              <Heading fontWeight="semibold">
                Don't see an integration you need?
              </Heading>
              <Separator size="sm" />
              <Column className="align-center">
                <Text>
                  We offer over 35+ ATS integrations. Get in touch if you want
                  to see the others.
                </Text>
                <Separator size="xs" />
                <Text className="">
                  Contact{' '}
                  <a
                    href="mailto:support@chatkick.com;subject=Integration Request"
                    target={'_blank'}
                  >
                    <Text className="underline" color="primary-700">
                      support@chatkick.com
                    </Text>
                  </a>{' '}
                  to get setup.
                </Text>
              </Column>
            </Card>
          </Column>
        </Column>
      </DualBackground>
      <IntegrationFormModal
        integration={integratingEntity}
        integrationType={integratingType!}
        visible={showIntegrationModal}
        onRequestClose={() => {
          setShowIntegrationModal(false)
        }}
      />
    </PrivateLayout>
  )
}

export default IntegrationsScreen
