import Badge from '@trinsly/common/src/components/atoms/badge'
import Column from '@trinsly/common/src/components/atoms/column'
import Row from '@trinsly/common/src/components/atoms/row'
import Table from '@trinsly/common/src/components/atoms/table'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import Pagination from '@trinsly/common/src/components/molecules/pagination'
import usePageable from '@trinsly/common/src/hooks/use-pageable'
import useGetProjects from 'api-hooks/use-get-projects'
import PrivateLayout from 'components/private-layout'
import { format, parse } from 'date-fns'
import React from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import ProjectStatusEnum from 'types/project-status-enum'
import ArchiveButton from './archive-button'
import Header from './header'
import ProjectFormModal from './project-form-modal'
import StatusField from './status-field'

type ProjectsScreenProps = {}

function ProjectsScreen({}: ProjectsScreenProps) {
  const [showFormModal, setShowFormModal] = React.useState(false)

  const history = useHistory()
  const { pageable, setSort, prevPage, nextPage } = usePageable({
    page: 1,
    perPage: 10,
  })
  const { data: projects, total, pending } = useGetProjects(pageable)
  const isNonIdealState = !pending && !projects?.length

  return (
    <PrivateLayout>
      <Header
        onNewProject={() => {
          setShowFormModal(true)
        }}
      />
      <Column className="px-lg py-md">
        <Column className="rounded-md shadow-sm overflow-hidden bg-white">
          {isNonIdealState ? (
            <Column className="p-xl">
              <div
                style={{
                  position: 'relative',
                  paddingBottom: '56.25%',
                  height: 0,
                }}
              >
                <iframe
                  src="https://www.loom.com/embed/109bf860d00c4f2792dafbac13f73465"
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                  }}
                ></iframe>
              </div>
            </Column>
          ) : (
            <React.Fragment>
              <Table
                pageSize={pageable?.perPage}
                pending={pending}
                data={projects}
                columns={[
                  {
                    header: 'Name',
                    sort: pageable?.sort?.name,
                    width: 200,
                    onSort: (direction) => setSort('name', direction as any),
                    cell: (item) => (
                      <Link to={`/projects/${item?.id}`}>
                        <Row className="items-center gap-xs">
                          <Column>
                            <Text
                              size="md"
                              color="primary-900"
                              fontWeight="semibold"
                            >
                              {item?.name}
                            </Text>
                          </Column>
                        </Row>
                      </Link>
                    ),
                  },
                  {
                    header: 'Status',
                    width: 140,
                    cell: (item) =>
                      item?.status ? (
                        <StatusField status={item.status} />
                      ) : (
                        '--'
                      ),
                    sort: pageable?.sort?.status,
                    onSort: (direction) => setSort('status', direction as any),
                  },
                  {
                    width: 110,
                    header: 'Total Prospects',
                    cell: (item) =>
                      item?.prospectsCount ? item.prospectsCount : '--',
                  },
                  {
                    width: 110,
                    header: 'Campaigns Used',
                    cell: (item) =>
                      item?.campaignsCount ? item.campaignsCount : '--',
                  },
                  {
                    width: 110,
                    header: 'Start Date',
                    cell: (item) =>
                      item?.startDate
                        ? format(
                            parse(item?.startDate, `yyyy-MM-dd`, new Date()),
                            `MM/dd/yyyy`
                          )
                        : '--',
                    sort: pageable?.sort?.start_date,
                    onSort: (direction) =>
                      setSort('start_date', direction as any),
                  },
                  {
                    width: 110,
                    header: 'End Date',
                    cell: (item) =>
                      item?.endDate
                        ? format(
                            parse(item?.endDate, `yyyy-MM-dd`, new Date()),
                            `MM/dd/yyyy`
                          )
                        : '--',
                    sort: pageable?.sort?.end_date,
                    onSort: (direction) =>
                      setSort('end_date', direction as any),
                  },

                  {
                    cell: (item) => {
                      return (
                        <Row className="items-center gap-xs">
                          {item?.tagList?.map?.((tag) => (
                            <Badge size="sm" intent="primary">
                              {tag}
                            </Badge>
                          ))}
                        </Row>
                      )
                    },
                  },
                  {
                    cell: (item) => {
                      const isArchived =
                        item?.status === ProjectStatusEnum.INACTIVE
                      if (isArchived) {
                        return null
                      }

                      return (
                        <Row className="items-center gap-sm">
                          <Link to={`/projects/${item?.id}`}>
                            <Button
                              className="w-28"
                              shape="rounded"
                              size="sm"
                              variant="solid"
                              intent="info"
                            >
                              View Details
                            </Button>
                          </Link>
                          <ArchiveButton project={item} />
                        </Row>
                      )
                    },
                  },
                ]}
              />
              <Row className="items-center py-sm px-md justify-end">
                <Pagination
                  total={total || 0}
                  page={pageable?.page}
                  perPage={pageable?.perPage}
                  onNext={nextPage}
                  onPrev={prevPage}
                />
              </Row>
            </React.Fragment>
          )}
        </Column>
      </Column>
      <ProjectFormModal
        visible={showFormModal}
        onRequestClose={() => {
          setShowFormModal(false)
        }}
      />
    </PrivateLayout>
  )
}

export default ProjectsScreen
