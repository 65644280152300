import { ReactComponent as FilterSvg } from '@trinsly/common/src/assets/icons/filter.svg'
import { ReactComponent as PlusSvg } from '@trinsly/common/src/assets/icons/plus.svg'
import Clickable from '@trinsly/common/src/components/atoms/clickable'
import Column from '@trinsly/common/src/components/atoms/column'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import Expandable from '@trinsly/common/src/components/molecules/expandable'
import IconButton from '@trinsly/common/src/components/molecules/icon-button'
import SearchInput from '@trinsly/common/src/components/molecules/search-input'
import SelectInput from '@trinsly/common/src/components/molecules/select-input'
import TextInput from '@trinsly/common/src/components/molecules/text-input'
import usePageable from '@trinsly/common/src/hooks/use-pageable'
import React from 'react'
import ProjectStatusEnum, {
  translateProjectStatusEnum,
} from 'types/project-status-enum'
import MultiSelectTags from './project-form-modal/multi-select-tags'

type HeaderProps = {
  onNewProject: () => void
}

function Header({ onNewProject }: HeaderProps) {
  const { pageable, setSearch, setFilters } = usePageable({
    page: 1,
    perPage: 10,
  })
  const hasAdvancedFilters = Object.values(pageable?.filters || {}).length > 0
  const [showAdvancedFilters, setShowAdvancedFilters] =
    React.useState(hasAdvancedFilters)

  return (
    <Column className="bg-white shadow">
      <Column className="py-md px-lg">
        <Heading size="xl" fontWeight="bold">
          Projects
        </Heading>
        <Separator />
        <Row className="items-center">
          <Row className="items-center flex-1">
            <Column className="flex-1" style={{ maxWidth: 360 }}>
              <SearchInput
                placeholder="Search for projects"
                value={pageable?.search}
                onChange={setSearch}
              />
            </Column>
            <Separator size="sm" />
            <IconButton
              variant={hasAdvancedFilters ? 'solid' : 'ghost'}
              shape="square"
              intent={hasAdvancedFilters ? 'primary' : undefined}
              onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
            >
              <FilterSvg />
            </IconButton>
          </Row>
          <Separator />
          <Button intent="primary" shape="rounded" onClick={onNewProject}>
            <Row className="items-center">
              <PlusSvg className="w-md h-md" />
              <Separator size="xs" />
              New Project
            </Row>
          </Button>
        </Row>
      </Column>
      <Expandable expanded={showAdvancedFilters} maxHeight={340}>
        <Column className="py-xs pb-lg px-lg gap-md">
          <Row>
            <Column className="flex-1">
              <Text
                color="gray-900"
                fontWeight="bold"
                className="uppercase"
                size="sm"
              >
                Advanced Filter
              </Text>
            </Column>
            <Clickable
              onClick={() => {
                setFilters((filters) => ({
                  ...filters,
                  status: '',
                  startDateBefore: '',
                  startDateAfter: '',
                  endDateBefore: '',
                  endDateAfter: '',
                  tags: '',
                }))
              }}
            >
              <Text
                color="primary-500"
                fontWeight="bold"
                className="uppercase"
                size="sm"
              >
                Clear Filters
              </Text>
            </Clickable>
          </Row>
          <Row className="gap-sm flex-wrap">
            <Row className="gap-sm">
              <Column className="min-w-44">
                <TextInput
                  type="date"
                  label="Start Date must be before"
                  value={pageable?.filters?.startDateBefore}
                  onChange={(value) => setFilters('startDateBefore', value)}
                />
              </Column>
              <Column className="min-w-44">
                <TextInput
                  type="date"
                  label="Start Date must be after"
                  value={pageable?.filters?.startDateAfter}
                  onChange={(value) => setFilters('startDateAfter', value)}
                />
              </Column>
            </Row>
            <Row className="gap-sm">
              <Column className="min-w-44">
                <TextInput
                  type="date"
                  label="End Date must be before"
                  value={pageable?.filters?.endDateBefore}
                  onChange={(value) => setFilters('endDateBefore', value)}
                />
              </Column>
              <Column className="min-w-44">
                <TextInput
                  type="date"
                  label="End Date must be after"
                  value={pageable?.filters?.endDateAfter}
                  onChange={(value) => setFilters('endDateAfter', value)}
                />
              </Column>
            </Row>
            <Column className="w-64">
              <SelectInput
                label="Status"
                placeholder="Select a status"
                value={pageable?.filters?.status as ProjectStatusEnum}
                onChange={(value) => setFilters('status', value)}
                options={
                  Object.values(ProjectStatusEnum) as ProjectStatusEnum[]
                }
                itemRenderer={(item) => translateProjectStatusEnum[item]}
              />
            </Column>
            <Column className="w-64">
              <MultiSelectTags
                label="Tags"
                placeholder="Select tags for project"
                values={
                  pageable?.filters?.tags
                    ? pageable?.filters?.tags?.split(',')
                    : undefined
                }
                onChange={(values) => setFilters('tags', values?.join(','))}
              />
            </Column>
          </Row>
        </Column>
      </Expandable>
    </Column>
  )
}

export default Header
