import api from '@trinsly/common/src/services/api'
import { ThreadEmailType } from 'types/thread-type'

type GetThreadEmailServiceProps = {
  threadEmailId: ThreadEmailType['id']
}

async function getThreadEmailService({
  threadEmailId,
}: GetThreadEmailServiceProps) {
  const response = await api(`/email_messages/${threadEmailId}`, {
    method: 'GET',
  })
  return response as any as ThreadEmailType
}

export default getThreadEmailService
