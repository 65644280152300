import api from '@trinsly/common/src/services/api'
import { Organization } from 'types/settings-type'

export type GetOrganizationServiceProps = { organizationId: Organization['id'] }

export default async function getOrganizationService({
  organizationId,
}: GetOrganizationServiceProps) {
  const response = await api(`/organizations/${organizationId}`, {
    method: 'GET',
  })

  return response as any as Organization
}
