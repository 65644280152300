import Card from '@trinsly/common/src/components/atoms/card'
import Column from '@trinsly/common/src/components/atoms/column'
import Row from '@trinsly/common/src/components/atoms/row'
import PrivateLayout from 'components/private-layout'
import React from 'react'
import { useParams } from 'react-router'
import DashboardSection from './dashboard-section'
import FormProvider from './form-provider'
import Header from './header'
import MarkEmailThreadResponseTypeModal from './mark-email-thread-response-type-modal'
import ProfileSection from './profile-section'

export type ProspectDetailScreenProps = {}

function ProspectDetailScreen({}: ProspectDetailScreenProps) {
  const { id, emailThreadId }: any = useParams()
  const [showEmailThreadModal, setShowEmailThreadModal] = React.useState(
    Boolean(emailThreadId)
  )

  return (
    <FormProvider>
      <PrivateLayout>
        <Header />
        <Column className="p-md bg-gray-200 flex-1">
          <Card className="overflow-hidden">
            <Row className="flex-1">
              <Column className="w-72 min-w-72">
                <ProfileSection />
              </Column>
              <Column className="border-r border-gray-200" />
              <Column className="w-full overflow-auto">
                <DashboardSection />
              </Column>
            </Row>
          </Card>
        </Column>
        <MarkEmailThreadResponseTypeModal
          threadId={emailThreadId}
          visible={showEmailThreadModal}
          onRequestClose={() => {
            setShowEmailThreadModal(false)
          }}
        />
      </PrivateLayout>
    </FormProvider>
  )
}

export default ProspectDetailScreen
