import api from '@trinsly/common/src/services/api'
import TrackerDomainType from 'types/tracker-domain-type'

export type CreateTrackerDomainServiceProps = {
  domain: string
}

export default async function createTrackerDomainService({
  domain,
}: CreateTrackerDomainServiceProps) {
  const response = await api('/tracker_domains', {
    method: 'POST',
    body: { tracker_domain: { domain: domain } },
  })
  return response as any as TrackerDomainType
}
