import React from 'react'
import slugify from '../../utils/slugify'
import Column from '../atoms/column'
import Label, { LabelProps } from '../atoms/label'
import Separator from '../atoms/separator'
import MultiSelect, { MultiSelectProps } from './multi-select'

export type MultiSelectInputProps<T> = {
  label?: LabelProps['children']
  error?: string
  required?: boolean
} & MultiSelectProps<T>

function MultiSelectInput<T>({
  label,
  id = slugify(label, { suffix: 'multi-select' }),
  required = false,
  error,
  ...multiSelectProps
}: MultiSelectInputProps<T>) {
  return (
    <Column>
      {label && (
        <React.Fragment>
          <Label htmlFor={id} color={error ? 'danger-500' : undefined}>
            {label}
            {required ? ' *' : undefined}
          </Label>
          <Separator size="xs" />
        </React.Fragment>
      )}
      <MultiSelect {...multiSelectProps} id={id} error={error} />
      <Column style={{ height: 0 }}>
        <Column className="min-h-1" />
        {error && (
          <span className="text-danger-500" style={{ fontSize: '0.65rem' }}>
            {error}
          </span>
        )}
      </Column>
    </Column>
  )
}

export default MultiSelectInput
