import React, { ReactElement } from 'react'
import { Theme } from '../../types/theme-type'
import Spinner from '../atoms/spinner'

export type IconButtonProps = {
  size?: 'xxs' | Theme['size']
  iconSize?: 'xxs' | Theme['size']
  intent?: Theme['intent'] | 'white'
  shape?: 'square' | 'circle'
  variant?: 'solid' | 'outline' | 'ghost' | 'transparent'
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type']
  pending?: boolean
  disabled?: boolean
  id?: string
  testId?: string
  children: ReactElement
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

function _IconButton(
  {
    children,
    size = 'md',
    iconSize,
    intent,

    type = 'button',
    variant = 'solid',
    shape = 'square',
    pending = false,
    disabled = false,
    id,
    testId,
    onClick,
  }: IconButtonProps,
  ref: React.Ref<HTMLButtonElement>
) {
  return (
    <button
      id={id}
      {...{ 'data-test-id': testId }}
      ref={ref}
      type={type}
      className={`
        ${baseIconButtonClasses}
        ${sizeClassMap[size]}
        ${shapeClassMap[shape]}
        ${baseIconButtonVariantClassMap[variant][intent || 'undefined']} 
        ${
          pending
            ? pendingBaseButtonClasses
            : disabled
            ? disabledBaseButtonClasses
            : baseIconButtonActiveVariantClassMap[variant][
                intent || 'undefined'
              ]
        } 
      `}
      onClick={onClick}
      disabled={disabled}
    >
      {pending ? (
        <Spinner size={size} />
      ) : (
        React.cloneElement(children, {
          className: `${iconSizeClassMap[iconSize || size]}`,
        })
      )}
    </button>
  )
}

const IconButton = React.forwardRef(_IconButton)
export default IconButton

const baseIconButtonClasses = `flex justify-center items-center focus:outline-none transition`
const disabledBaseButtonClasses = `cursor-not-allowed opacity-50`
const pendingBaseButtonClasses = `cursor-wait`

const sizeClassMap = {
  xl: `w-14 h-14`,
  lg: `w-12 h-12`,
  md: `w-10 h-10`,
  sm: `w-8 h-8`,
  xs: `w-6 h-6`,
  xxs: `w-5 h-5`,
}

const iconSizeClassMap = {
  xl: `w-12 h-12`,
  lg: `w-9 h-9`,
  md: `w-7 h-7`,
  sm: `w-5 h-5`,
  xs: `w-4 h-4`,
  xxs: `w-3 h-3`,
}

const shapeClassMap = {
  square: 'rounded-md',
  circle: 'rounded-full',
}

export const baseIconButtonVariantClassMap: any = {
  solid: {
    primary: `text-white bg-primary-500`,
    danger: `text-white bg-danger-500`,
    warning: `text-white bg-warning-400`,
    success: `text-white bg-success-400`,
    info: `text-white bg-info-400`,
    white: `text-primary-500 bg-white`,
    undefined: `text-gray-500 bg-gray-100`,
  },
  outline: {
    primary: `border border-primary-500 text-primary-500`,
    danger: `border border-danger-500 text-danger-500`,
    warning: `border border-warning-500 text-warning-500`,
    success: `border border-success-500 text-success-500`,
    info: `border border-info-500 text-info-500`,
    white: `border border-white text-white`,
    undefined: `border border-gray-300 text-gray-900`,
  },
  ghost: {
    primary: `text-primary-500 bg-primary-50`,
    danger: `text-danger-500`,
    warning: `text-warning-500`,
    success: `text-success-500`,
    info: `text-info-500`,
    white: 'text-white',
    undefined: `text-gray-500`,
  },
  transparent: {
    primary: `text-primary-500`,
    danger: `text-danger-500`,
    warning: `text-warning-500`,
    success: `text-success-500`,
    info: `text-info-500`,
    white: 'text-white',
    undefined: `text-gray-500`,
  },
}

export const baseIconButtonActiveVariantClassMap: any = {
  solid: {
    primary: `hover:bg-primary-600 focus:bg-primary-600 active:bg-primary-800 focus:ring focus:ring-primary-200 shadow`,
    danger: `hover:bg-danger-600 focus:bg-danger-600 active:bg-danger-800 focus:ring focus:ring-danger-200 shadow`,
    warning: `hover:bg-warning-500 focus:bg-warning-500 active:bg-warning-600 focus:ring focus:ring-warning-200 shadow`,
    success: `hover:bg-success-500 focus:bg-success-500 active:bg-success-600 focus:ring focus:ring-success-200 shadow`,
    info: `hover:bg-info-500 focus:bg-info-500 active:bg-info-600 focus:ring focus:ring-info-200 shadow`,
    white: `hover:bg-opacity-80 focus:bg-opacity-80 active:bg-opacity-50 focus:ring focus:ring-white shadow`,
    undefined: `hover:bg-gray-200 focus:bg-gray-200 active:bg-gray-300 focus:ring focus:ring-gray-400 shadow`,
  },
  outline: {
    primary: `hover:bg-primary-50 focus:bg-primary-50 active:bg-primary-100 focus:ring focus:ring-primary-200`,
    danger: `hover:bg-danger-50 focus:bg-danger-50 active:bg-danger-100 focus:ring focus:ring-danger-200`,
    warning: `hover:bg-warning-50 focus:bg-warning-50 active:bg-warning-100 focus:ring focus:ring-warning-200`,
    success: `hover:bg-success-50 focus:bg-success-50 active:bg-success-100 focus:ring focus:ring-success-200`,
    info: `hover:bg-info-50 focus:bg-info-50 active:bg-info-100 focus:ring focus:ring-info-200`,
    white: `hover:bg-white hover:bg-opacity-10 focus:bg-opacity-10 focus:bg-white active:bg-white active:bg-opacity-30 focus:ring focus:ring-primary-200`,
    undefined: `hover:bg-gray-100 hover:bg-opacity-30 focus:bg-opacity-30 focus:bg-gray-100 active:bg-gray-200 focus:ring focus:ring-gray-300`,
  },
  ghost: {
    primary: `bg-primary-50 active:bg-primary-100 focus:ring focus:ring-primary-200 focus:ring-offset-2`,
    danger: `bg-danger-100 active:bg-danger-200 focus:ring focus:ring-danger-200 focus:ring-offset-2`,
    warning: `bg-warning-100 active:bg-warning-200 focus:ring focus:ring-warning-200 focus:ring-offset-2`,
    success: `bg-success-100 active:bg-success-200 focus:ring focus:ring-success-200 focus:ring-offset-2`,
    info: `bg-info-100 active:bg-info-200 focus:ring focus:ring-info-200 focus:ring-offset-2`,
    undefined: `bg-gray-100 active:bg-gray-200 focus:ring focus:ring-gray-200 focus:ring-offset-2`,
    white: `active:bg-white active:bg-opacity-10 focus:ring focus:ring-white focus:ring-opacity-50 focus:ring-offset-2`,
  },
  transparent: {
    primary: `active:bg-primary-100 focus:ring focus:ring-primary-200 focus:ring-offset-2`,
    danger: `active:bg-danger-200 focus:ring focus:ring-danger-200 focus:ring-offset-2`,
    warning: `active:bg-warning-200 focus:ring focus:ring-warning-200 focus:ring-offset-2`,
    success: `active:bg-success-200 focus:ring focus:ring-success-200 focus:ring-offset-2`,
    info: `active:bg-info-200 focus:ring focus:ring-info-200 focus:ring-offset-2`,
    undefined: `active:bg-gray-200 focus:ring focus:ring-gray-200 focus:ring-offset-2`,
    white: `active:bg-white active:bg-opacity-10 focus:ring focus:ring-white focus:ring-opacity-50 focus:ring-offset-2`,
  },
}
