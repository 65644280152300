import { ReactComponent as MinusSvg } from '@trinsly/common/src/assets/icons/minus.svg'
import { ReactComponent as PlusSvg } from '@trinsly/common/src/assets/icons/plus.svg'
import Card from '@trinsly/common/src/components/atoms/card'
import Column from '@trinsly/common/src/components/atoms/column'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Label from '@trinsly/common/src/components/atoms/label'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Spinner from '@trinsly/common/src/components/atoms/spinner'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import IconButton from '@trinsly/common/src/components/molecules/icon-button'
import TextInput from '@trinsly/common/src/components/molecules/text-input'
import useGetUserSession from 'api-hooks/use-get-user-session'
import { useEffect, useState } from 'react'
import createCheckoutSessionService from 'services/create-checkout-session-service'
import { PRICE_MAP, PricingReviewCard } from './add-user-form'

const perUserCost = () => {
  return (PRICE_MAP['monthly'] / 100).toFixed(2)
}
//plan.usageType = 'licensed' vs 'metered' ()
//licensed 'quantity' vs. user plan.seat_count_limit (precedent)
//if plan.id === our growth tier already then we increase quantity
//if no plan.id is our growth tier, then we add new item to subscription
function NewSubscriptionCheckout() {
  const [seatCount, setSeatCount] = useState(0)
  const [costEstimate, setCostEstimate] = useState('')

  const { data: userSession, pending: userPending } = useGetUserSession({})

  //added cost
  useEffect(() => {
    const price = PRICE_MAP['monthly']

    if (price) {
      const totalInCents = price * seatCount

      const cost = (totalInCents / 100).toFixed(2)
      setCostEstimate(cost)
    }
  }, [seatCount])

  //TODO: we just hardcode planId for growth + metered
  const checkout = async () => {
    window.location.href = await createCheckoutSessionService({
      planId: '', //monthly or annual per seat
      seatCount: seatCount,
    })
  }

  return (
    <Column>
      <Column className="text-center">
        <Heading size="2xl" fontWeight="bold">
          Ready to accelerate your recruiting?
        </Heading>
      </Column>
      <Separator />
      <Row className="justify-center flex">
        <PricingReviewCard />
        <Separator />
        <Card className="p-8 drop-shadow-lg">
          <Column className="gap-y-sm">
            {!!seatCount && (
              <Heading size="2xl" fontWeight="bold">
                {' '}
                {`Your cost today: $${costEstimate}`}
              </Heading>
            )}
            {userPending ? (
              <Spinner />
            ) : (
              <>
                <Separator size="sm" />
                <Column>
                  <Text fontWeight="semibold">
                    Licenses: {seatCount} x ${perUserCost()} = ${costEstimate}
                  </Text>
                  <Separator size="xxs" />
                  <Text className="italic" size="xs">
                    Billed Monthly
                  </Text>
                </Column>

                <Row className="max-w-17">
                  <IconButton
                    children={<MinusSvg />}
                    onClick={() => {
                      const seats = seatCount - 1
                      const noNegatives = seats > -1 ? seats : 0
                      setSeatCount(noNegatives)
                    }}
                  />{' '}
                  <Separator size="xxs" />
                  <TextInput
                    id="seat-count"
                    type="number"
                    value={seatCount}
                    disabled
                    color="black"
                    style={{
                      fontWeight: 'bold',
                      color: 'black',
                      fontSize: '14px',
                      textAlign: 'center',
                    }}
                  ></TextInput>
                  <Separator size="xxs" />
                  <IconButton
                    children={<PlusSvg />}
                    onClick={() => {
                      setSeatCount(seatCount + 1)
                    }}
                  />
                </Row>
                <Separator />
              </>
            )}
            <Separator size="lg" />
            <Button
              //should create a session + stripe user
              onClick={async () => checkout()}
              shape="rounded"
              intent="primary"
              variant="ghost"
              size="lg"
              className="w-full"
              disabled={true}
            >
              Checkout
            </Button>
            <Label>
              Get in touch -{' '}
              <a href="mailto:hello@chatkick.com" target={'_blank'}>
                <Text
                  fontWeight="semibold"
                  color="primary-700"
                  className="underline"
                >
                  hello@chatkick.com
                </Text>
              </a>
            </Label>
          </Column>
        </Card>
      </Row>
    </Column>
  )
}

export default NewSubscriptionCheckout
