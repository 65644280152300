import Checkbox from '@trinsly/common/src/components/atoms/checkbox'
import Column from '@trinsly/common/src/components/atoms/column'
import Label from '@trinsly/common/src/components/atoms/label'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import TextInput from '@trinsly/common/src/components/molecules/text-input'
import React from 'react'
import { useFormContext } from './form-provider'

export type AdvancedTabProps = {}

function AdvancedTab({}: AdvancedTabProps) {
  const { getValue, setValue, campaign, pending, creating, updating } =
    useFormContext()
  const shouldDisableFormChange = pending || creating || updating
  const isReadonly = !pending && Boolean(campaign?.isArchived)

  return (
    <React.Fragment>
      <Separator />
      <Column className="px-xl">
        <Column>
          <Row>
            <Checkbox
              id="is-for-candidate-checkbox"
              checked={getValue('campaign.isForCandidate')}
              onChange={(value) => {
                setValue('campaign.isForCandidate', value)
              }}
              disabled={shouldDisableFormChange || isReadonly}
            />
            <Separator size="xs" />
            <Label>Sync to CRM as a candidate</Label>
          </Row>
          <Separator />
          <TextInput
            label="CRM Job Id"
            value={getValue('campaign.crmJobId')}
            onChange={(value) => {
              setValue('campaign.crmJobId', value)
            }}
            disabled={shouldDisableFormChange || isReadonly}
          />
          <Separator />
          <TextInput
            label="BCC Override"
            value={getValue('campaign.bcc')}
            onChange={(value) => {
              setValue('campaign.bcc', value)
            }}
            disabled={shouldDisableFormChange || isReadonly}
          />
        </Column>
      </Column>
      <Separator size="xl" />
    </React.Fragment>
  )
}

export default AdvancedTab
