import Column from '@trinsly/common/src/components/atoms/column'
import Form from '@trinsly/common/src/components/atoms/form'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Text from '@trinsly/common/src/components/atoms/text'
import Alert from '@trinsly/common/src/components/molecules/alert'
import Button from '@trinsly/common/src/components/molecules/button'
import TextInput from '@trinsly/common/src/components/molecules/text-input'
import useForm from '@trinsly/common/src/hooks/use-form'
import required from '@trinsly/common/src/validations/required'
import { ReactComponent as TrinslyLogoSvg } from 'assets/logo.svg'
import PublicLayout from 'components/public-layout'

import Link from '@trinsly/common/src/components/atoms/link'
import Row from '@trinsly/common/src/components/atoms/row'
import validEmail from '@trinsly/common/src/validations/valid-email'
import useRequestPasswordReset from 'api-hooks/use-request-reset-password'
import React, { useState } from 'react'

export type ForgotPasswordScreenProps = {}

//step 1
function ForgotPasswordScreen({}: ForgotPasswordScreenProps) {
  const [errors, setErrors] = useState()
  const [success, setSuccess] = useState(false)
  const { fetch: requestReset, pending: pendingRequestReset } =
    useRequestPasswordReset({
      onError: (error: any) => {
        setErrors(error)
      },
      onSuccess: () => {
        setSuccess(true)
      },
    })

  const { getValue, setValue, getError, submit } = useForm({
    initialValues: {}, //todo: use params to load form for user
    onSubmit: ({ email }) => {
      requestReset({ email })
    },
    validations: {
      email: [required, validEmail],
    },
  })
  return (
    <PublicLayout>
      <Column className="flex-1">
        <Form onSubmit={submit}>
          <TrinslyLogoSvg
            height={80}
            width={200}
            style={{ alignSelf: 'left' }}
          />
          <Column style={{ minHeight: 60 }} />
          <Heading size="xl" fontWeight="bold">
            Forgot your password?
          </Heading>
          <Separator size="lg" />
          {success && (
            <React.Fragment>
              <Alert intent="success">
                Please check your email for further instructions.
              </Alert>
              <Separator size="lg" />
            </React.Fragment>
          )}
          {errors && (
            <React.Fragment>
              <Alert intent="danger">
                {`We're unable to complete your request. Please check your email address or contact support.`}
              </Alert>
              <Separator size="lg" />
            </React.Fragment>
          )}
          {!success && (
            <React.Fragment>
              <TextInput
                type="email"
                label="E-mail"
                placeholder="you@example.com"
                value={getValue('email')}
                onChange={(value) => setValue('email', value)}
                error={getError('email')}
              />
              <Separator size="lg" />
              <Button
                type="submit"
                intent="success"
                className="w-full"
                pending={pendingRequestReset}
              >
                Request a password reset
              </Button>
            </React.Fragment>
          )}
        </Form>
      </Column>
      <Separator size="xl" />
      <Row>
        <Text size="sm">Back to</Text>
        <Column style={{ minWidth: 4 }} />
        <Link href="/log-in" tabIndex={1}>
          <Text size="sm" color="current" fontWeight="bold">
            Sign In
          </Text>
        </Link>
      </Row>
    </PublicLayout>
  )
}

export default ForgotPasswordScreen
