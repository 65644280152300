import { ReactComponent as CloseSvg } from '@trinsly/common/src/assets/icons/close.svg'
import Row from '@trinsly/common/src/components/atoms/row'
import Button from '@trinsly/common/src/components/molecules/button'
import { EXTENSION_IMPERSONATE_USER_ID_KEY } from '@trinsly/common/src/services/api'
import useDisimpersonate from 'api-hooks/use-disimpersonate'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'

type DisimpersonateButtonProps = {}

function DisimpersonateButton({}: DisimpersonateButtonProps) {
  const history = useHistory()
  const { fetch: disimpersonate, pending: pendingDismpersonate } =
    useDisimpersonate({
      onSuccess: () => {
        toast.success('Disimpersonated successfully!')
        history.push('/')
      },
    })

  const isImpersonating = Boolean(
    localStorage?.getItem?.(EXTENSION_IMPERSONATE_USER_ID_KEY)
  )

  if (!isImpersonating && !pendingDismpersonate) {
    return null
  }

  return (
    <Button
      size="sm"
      variant="outline"
      intent="danger"
      pending={pendingDismpersonate}
      onClick={disimpersonate}
    >
      <Row className="items-center gap-1">
        <CloseSvg className="w-5 h-5" />
        Disimpersonate
      </Row>
    </Button>
  )
}

export default DisimpersonateButton
