import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation } from 'react-query'
import requestPasswordResetService from 'services/auth/request-password-reset-services'

export type UseRequestPasswordResetProps = {} & MutateApiHookType<void>

function useRequestPasswordReset({
  onSuccess,
  onError,
}: UseRequestPasswordResetProps) {
  const { mutate: fetch, isLoading: pending } = useMutation(
    requestPasswordResetService,
    {
      onSuccess: async () => {
        onSuccess?.()
      },
      onError,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useRequestPasswordReset
