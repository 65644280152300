import api from '@trinsly/common/src/services/api'
import { ThreadEmailType } from 'types/thread-type'

type CancelThreadEmailServiceProps = {
  emailId: number
}

async function cancelThreadEmailService({
  emailId,
}: CancelThreadEmailServiceProps) {
  const response = await api(`/email_messages/${emailId}/cancel`, {
    method: 'POST',
  })
  return response as any as ThreadEmailType
}

export default cancelThreadEmailService
