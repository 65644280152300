import { getAPIError } from '@trinsly/common/src/services/api'
import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation } from 'react-query'
import sendCampaignService from 'services/send-campaign-service'

type UseSendCampaignProps = {} & MutateApiHookType<void>

function useSendCampaign({ onSuccess, onError }: UseSendCampaignProps) {
  // const queryClient = useQueryClient()
  const { status, mutate } = useMutation(sendCampaignService, {
    onSuccess,
    onError: (error) => {
      onError?.(getAPIError(error))
    },
  })

  return { fetch: mutate, pending: status === 'loading' }
}

export default useSendCampaign
