import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation } from 'react-query'
import sendEmailService from '../services/send-email-service'

export type UseSendEmailProps = {} & MutateApiHookType<any>

function useSendEmail({ onSuccess, onError }: UseSendEmailProps) {
  const { mutate: fetch, status } = useMutation(sendEmailService, {
    onSuccess,
    onError,
  })

  return {
    fetch,
    pending: status === 'loading',
  }
}

export default useSendEmail
