import Column from '@trinsly/common/src/components/atoms/column'
import PrivateLayout from 'components/private-layout'
import React from 'react'
import CampaignsSection from './campaigns-section'
import FormProvider from './form-provider'
import Header from './header'
import ProspectsSection from './prospects-section'
import TableViewModeEnum from './table-view-mode-enum'

type ProjectDetailScreenProps = {}

function ProjectDetailScreen({}: ProjectDetailScreenProps) {
  const [tableViewMode, setTableViewMode] = React.useState<TableViewModeEnum>(
    TableViewModeEnum.PROSPECTS
  )
  return (
    <FormProvider>
      <PrivateLayout>
        <Header />
        <Column className="p-md bg-gray-200 flex-1">
          {
            {
              [TableViewModeEnum.PROSPECTS]: (
                <ProspectsSection onViewModeChange={setTableViewMode} />
              ),
              [TableViewModeEnum.CAMPAIGNS]: (
                <CampaignsSection onViewModeChange={setTableViewMode} />
              ),
            }[tableViewMode]
          }
        </Column>
      </PrivateLayout>
    </FormProvider>
  )
}

export default ProjectDetailScreen
