import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation, useQueryClient } from 'react-query'
import createHotlistService from 'services/create-hotlist-service'
import HotlistType from 'types/hotlist-type'

type UseCreateHotlistProps = {} & MutateApiHookType<HotlistType>

function useCreateHotlist({ onSuccess, onError }: UseCreateHotlistProps) {
  const queryClient = useQueryClient()
  const { mutate: fetch, isLoading: pending } = useMutation(
    createHotlistService,
    {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries('/hotlists')
        onSuccess?.(data)
      },
      onError,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useCreateHotlist
