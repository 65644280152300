import { useQuery } from 'react-query'

import { getUserSettingsService } from 'services/settings/get-user-settings-service'
import Settings from 'types/settings-type'

function useGetUserSettings() {
  const {
    data,
    error,
    status,
    refetch,
    isRefetching: refetching,
  } = useQuery('/settings', getUserSettingsService, { staleTime: 60000 })

  return {
    data: (data as Settings) || {},
    pending: status === 'loading',
    error,
    refetch,
    refetching,
  }
}

export default useGetUserSettings
