import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation } from 'react-query'
import { createUserSessionService } from 'services/auth/create-user-session-service'

export type UseCreateUserSessionProps = {} & MutateApiHookType<User>

function useCreateUserSession({
  onSuccess,
  onError,
  onMutate,
}: UseCreateUserSessionProps) {
  const { mutate: fetch, isLoading: pending } = useMutation(
    createUserSessionService,
    {
      onSuccess: async (user) => {
        onSuccess?.(user)
      },
      onError,
      onMutate,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useCreateUserSession
