import { useAutoAnimate } from '@formkit/auto-animate/react'
import { ColumnProps } from '../atoms/column'

type AnimatedProps = {} & ColumnProps

function Animated({ className, ...props }: AnimatedProps) {
  const [parent] = useAutoAnimate()
  const hasDirection =
    className?.includes('flex-row') ||
    className?.includes('flex-col') ||
    className?.includes('flex-row-reverse') ||
    className?.includes('flex-col-reverse')

  return (
    <div className="flex flex-col">
      <div
        ref={parent as any}
        className={[
          'flex',
          hasDirection ? '' : 'flex-col',
          className || '',
        ].join(' ')}
        {...props}
      />
    </div>
  )
}

export default Animated
