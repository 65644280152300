import Column from '@trinsly/common/src/components/atoms/column'
import Form from '@trinsly/common/src/components/atoms/form'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Button from '@trinsly/common/src/components/molecules/button'
import TextInput from '@trinsly/common/src/components/molecules/text-input'
import useForm from '@trinsly/common/src/hooks/use-form'
import required from '@trinsly/common/src/validations/required'
import useUpdateProspect from 'api-hooks/use-update-prospect'
import { toast } from 'react-toastify'
import ProspectType from 'types/prospect-type'
import { useFormContext } from '../../form-provider'
import ResumeField from './resume-field'

export type ProfileFormProps = {
  onRequestClose: () => void
}

function ProfileForm({ onRequestClose }: ProfileFormProps) {
  const { prospect } = useFormContext()

  const { fetch: updateProspect, pending: pendingUpdateProspect } =
    useUpdateProspect({
      onSuccess: () => {
        toast.success('Prospect updated!')
        onRequestClose?.()
      },
      onError: (error) => {
        toast.error('Error updating prospect')
      },
    })

  const { getValue, setValue, getError, submit } = useForm({
    initialValues: prospect,
    onSubmit: (prospect: ProspectType) => {
      updateProspect({ prospect })
    },
    validations: {
      email: [required],
    },
  })

  return (
    <Form onSubmit={submit}>
      <Column className="px-md gap-sm">
        <TextInput
          label="First Name"
          value={getValue('firstName')}
          onChange={(value) => setValue('firstName', value)}
          error={getError('firstName')}
        />
        <TextInput
          label="Last Name"
          value={getValue('lastName')}
          onChange={(value) => setValue('lastName', value)}
          error={getError('lastName')}
        />
        <TextInput
          required
          label="E-mail"
          value={getValue('email')}
          onChange={(value) => setValue('email', value)}
          error={getError('email')}
        />
        <TextInput
          label="Linkedin Name"
          value={getValue('linkedinName')}
          onChange={(value) => setValue('linkedinName', value)}
          error={getError('linkedinName')}
        />
        <Row className="gap-xs">
          <TextInput
            label="City"
            value={getValue('city')}
            onChange={(value) => setValue('city', value)}
            error={getError('city')}
            className="min-w-36"
          />
          <TextInput
            label="State"
            value={getValue('state')}
            onChange={(value) => setValue('state', value)}
            error={getError('state')}
          />
        </Row>
        <TextInput
          label="Zip"
          value={getValue('zip')}
          onChange={(value) => setValue('zip', value)}
          error={getError('zip')}
        />
        <ResumeField
          resumeUrl={getValue('resumeUrl')}
          onChange={(value) => setValue('resumeUrl', value)}
        />
      </Column>
      <Separator size="xl" />
      <Row className="px-md gap-sm">
        <Button
          size="sm"
          intent="danger"
          variant="outline"
          shape="rounded"
          onClick={onRequestClose}
          className="flex-1"
        >
          Cancel
        </Button>
        <Button
          size="sm"
          type="submit"
          intent="success"
          shape="rounded"
          className="flex-1 whitespace-nowrap"
          pending={pendingUpdateProspect}
        >
          Save Prospect
        </Button>
      </Row>
    </Form>
  )
}

export default ProfileForm
