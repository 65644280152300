import { ReactComponent as ApiIcon } from '@trinsly/common/src/assets/icons/api.svg'
import { ReactComponent as BillingIcon } from '@trinsly/common/src/assets/icons/billing.svg'
import { ReactComponent as GlobeIcon } from '@trinsly/common/src/assets/icons/globe.svg'
import { ReactComponent as UserIcon } from '@trinsly/common/src/assets/icons/user-v2.svg'
import Card from '@trinsly/common/src/components/atoms/card'
import Column from '@trinsly/common/src/components/atoms/column'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Tabs from '@trinsly/common/src/components/atoms/tabs'
import useQueryParams from '@trinsly/common/src/hooks/use-query-params'
import useGetUserSession from 'api-hooks/use-get-user-session'
import PrivateLayout from 'components/private-layout'
import React, { useEffect, useState } from 'react'
import ApiTab from './api-tab'
//import BillingTab from './billing-tab'
import BillingTab from './billing-tab'
import MyAccountTab from './my-account-tab'
import OrganizationTab from './organization-tab'

type SettingsScreenProps = {}

function SettingsScreen({}: SettingsScreenProps) {
  const [isAdmin, setIsAdmin] = useState(false)
  const { params: queryParams } = useQueryParams()
  const [currentTab, setCurrentTab] = React.useState<
    'organization' | 'billing' | 'api' | 'my-account'
  >(queryParams.tabs || 'organization')

  const { data: userSession, pending } = useGetUserSession({})
  useEffect(() => {
    if (!pending && userSession) {
      if (userSession.organizationRole === 'admin') {
        setIsAdmin(true)
      }
    }
  }, [userSession, pending])
  return (
    <PrivateLayout>
      <Column className="py-md px-lg bg-white shadow">
        <Heading size="xl" fontWeight="bold">
          Settings
        </Heading>
      </Column>
      <Separator size="xs" />
      <Column className="p-sm">
        <Card>
          <Tabs
            id="tabs"
            variant="query-string"
            defaultTab={currentTab}
            onChange={setCurrentTab as any}
            className="px-xl "
          >
            <Tabs.Tab id="organization">
              <Row className="justify-center items-center">
                <GlobeIcon className="w-md h-md" />
                <Separator size="sm" />
                Organization
              </Row>
            </Tabs.Tab>
            <Tabs.Tab id="my-account">
              <Row className="justify-center items-center">
                <UserIcon className="w-md h-md" />
                <Separator size="sm" />
                My Account
              </Row>
            </Tabs.Tab>
            {isAdmin && (
              <Tabs.Tab id="billing">
                <Row className="justify-center items-center">
                  <BillingIcon className="w-md h-md" />
                  <Separator size="sm" />
                  Billing
                </Row>
              </Tabs.Tab>
            )}
            <Tabs.Tab id="api">
              <Row className="justify-center items-center">
                <ApiIcon className="w-md h-md" />
                <Separator size="sm" />
                API
              </Row>
            </Tabs.Tab>
          </Tabs>
          {
            {
              organization: <OrganizationTab />,
              'my-account': <MyAccountTab />,
              billing: <BillingTab />,
              api: <ApiTab />,
            }[currentTab]
          }
        </Card>
      </Column>
      <Separator size="xl" />
    </PrivateLayout>
  )
}

export default SettingsScreen
