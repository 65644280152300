import * as Sentry from '@sentry/react'
import Column from '@trinsly/common/src/components/atoms/column'
import Heading from '@trinsly/common/src/components/atoms/heading'
import Row from '@trinsly/common/src/components/atoms/row'
import Separator from '@trinsly/common/src/components/atoms/separator'
import Text from '@trinsly/common/src/components/atoms/text'
import Button from '@trinsly/common/src/components/molecules/button'
import PaperPlaneError from 'assets/error-paper-plane.png'
type ErrorBoundaryProps = {
  children: React.ReactNode | React.ReactNode[]
}

function ErrorBoundary({ children }: ErrorBoundaryProps) {
  return (
    // @ts-ignore
    <Sentry.ErrorBoundary
      fallback={({ error }) => {
        return <ErrorBoundaryLayout error={error} />
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}

export default ErrorBoundary

export type ErrorboundaryLayoutProps = {
  error: any
}

export const ErrorBoundaryLayout = ({ error }: ErrorboundaryLayoutProps) => {
  return (
    <>
      <Column className="items-center h-screen justify-center bg-primary-50">
        <Row className="space-x-28">
          <Column className="justify-center">
            <Column className="">
              <Heading size="4xl">Oops!</Heading>
              <Separator />
              <Text size="xl" fontWeight="bold" color="black">
                Something has gone wrong.
              </Text>
            </Column>
            <Separator size="xs" />
            <Column className="w-96">
              <Text size="lg" fontWeight="bold" color="black">
                Error details:
              </Text>
              <Text>
                Error Name: <Text fontWeight="bold">{error.name}</Text>
              </Text>
              <Text>
                Error Message: <Text fontWeight="bold">{error.message}</Text>
              </Text>
            </Column>
            <Separator size="xl" />
            <Row>
              <Button
                onClick={() => {
                  history.go(-1)
                }}
                intent="primary"
                size="lg"
              >
                Go back
              </Button>
            </Row>
          </Column>
          <Column className="shadow-md rounded-full w-fit h-fit  overflow-hidden rotate-12">
            <img src={PaperPlaneError} />
          </Column>
        </Row>
      </Column>
    </>
  )
}
