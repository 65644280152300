import MutateApiHookType from '@trinsly/common/src/types/mutate-api-hook-type'
import { useMutation, useQueryClient } from 'react-query'
import createProjectService from 'services/create-project-service'
import ProjectType from 'types/project-type'

type UseCreateProjectProps = {} & MutateApiHookType<ProjectType>

function useCreateProject({ onSuccess, onError }: UseCreateProjectProps) {
  const queryClient = useQueryClient()
  const { mutate: fetch, isLoading: pending } = useMutation(
    createProjectService,
    {
      onSuccess: async (data) => {
        queryClient.invalidateQueries('/projects')
        onSuccess?.(data)
      },
      onError,
    }
  )

  return {
    fetch,
    pending,
  }
}

export default useCreateProject
