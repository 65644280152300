import api from '@trinsly/common/src/services/api'

export type GetUser2faStatusServiceProps = {
  email: string
}

async function getUser2faStatusService({
  email,
}: GetUser2faStatusServiceProps) {
  const response = (await api(`/two_factor_auths/enabled?email=${email}`, {
    method: 'GET',
  })) as any

  return response as {
    email: string
    isTwoFactorEnabled: boolean
  }
}

export default getUser2faStatusService
